import { Typography } from "@material-ui/core";
import { STORAGE_PATHS } from "../../constants";
import formImagesModal from "./formImagesModal";
import React from "react";
import TAButton from "../taButton/taButton";
import { pdfjs } from "react-pdf/dist/umd/entry.webpack";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SelectableUploadedImage from "../uploaderImage/selectedableUploadedImage";
import FilesPicker from "../filePicker/filePicker";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class FormPDFImagesSelector extends formImagesModal {
  state = {
    selectedPDFImages: [],
    PDFImages: [],
    ...this.state,
  };
  //dont fetch for pdf images
  fetchEnterpriseImages = () => [];

  getDownloadURL = async (result) => {
    let path = result.metadata.fullPath;
    try {
      let downloadURL = await window.FortisForma.database.getDownloadURL(path);
      return downloadURL;
    } catch (e) {
      throw e;
    }
  };

  uploadSelectedImages = async () => {
    try {
      let images = [...this.state.selectedPDFImages].sort(
        (a, b) => a.pageNumber - b.pageNumber
      );
      let URLs = [];
      for (let i = 0; i < images.length; i++) {
        let result = await window.FortisForma.database.uploadAttachments(
          images[i].image,
          STORAGE_PATHS.FORM_IMAGES
        );
        const url = await this.getDownloadURL(result);
        window.NotificationUtils.showConfirm(
          `Page ${i + 1} of ${images.length} uploaded`
        );
        URLs.push(url);
      }
      return URLs;
    } catch (e) {
      throw e;
    }
  };

  onSave = async () => {
    try {
      if (!this.state.selectedPDFImages.length) {
        return window.NotificationUtils.showError("No page selected");
      }
      this.setState({ saving: true, disableButtons: true }, () =>
        window.NotificationUtils.showConfirm("Uploading, please wait.")
      );
      let backgroundImageURLs = await this.uploadSelectedImages();
      this.props.handleSave(backgroundImageURLs);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
    this.setState({ saving: false, disableButtons: false });
  };

  onSelect = (image) => {
    let images = [...this.state.selectedPDFImages];
    let selectedImageIndex = images.findIndex(
      (f) => f.pageNumber === image.pageNumber
    );
    if (selectedImageIndex >= 0) {
      images.splice(selectedImageIndex, 1);
    } else {
      images.push(image);
    }
    this.setState({
      selectedPDFImages: images,
    });
  };

  getFileName = (docName, pageIndex) => {
    let regex = /\.pdf$/g;
    let filename = `${docName.replace(regex, "")}_${pageIndex + 1}.png`;
    return filename;
  };

  readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file[0]);
    });
  };

  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64
  convertPdfToImages = async (file) => {
    try {
      const docName = file[0].name;
      const images = [];
      const data = await this.readFileData(file);
      const pdf = await pdfjs.getDocument(data).promise;
      const canvas = document.createElement("canvas");
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport })
          .promise;
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const image = new File([blob], this.getFileName(docName, i), {
          type: "image/png",
        });
        const imageData = canvas.toDataURL();
        const imageObject = {
          image,
          imageData,
          pageNumber: i + 1,
        };
        images.push(imageObject);
      }
      canvas.remove();
      return images;
    } catch (e) {
      throw e;
    }
  };

  convertToImage = async (file) => {
    try {
      let images = await this.convertPdfToImages(file);
      images = images.sort((a, b) => a.pageNumber - b.pageNumber);
      this.setState({
        PDFImages: images,
        selectedPDFImages: images,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  getSelectedImage = (item) => {
    let index = this.state.selectedPDFImages.findIndex(
      (f) => f.pageNumber === item.pageNumber
    );
    if (index >= 0) {
      return this.state.selectedPDFImages[index].imageData;
    } else return "";
  };

  renderConvertedImages = () => {
    if (this.state.PDFImages.length) {
      return this.state.PDFImages.map((item, index) => {
        const selectedImage = this.getSelectedImage(item);
        return (
          <SelectableUploadedImage
            key={index}
            index={index}
            image={item.imageData}
            onSelect={() => this.onSelect(item)}
            hideRemove={true}
            setPreviewOpened={(value) => this.setState({ showPreview: value })}
            selectedImage={selectedImage}
          />
        );
      });
    }
  };

  renderDialogContent = () => {
    return (
      <div>
        {this.renderConvertedImages()}
        <FilesPicker
          isMultiple={true}
          onChange={this.convertToImage}
          extensions={["pdf"]}
        >
          <div className="addNotesUploadContainer" id="fileUploader">
            <CloudUploadIcon color="primary" />
            <Typography
              id="uploadImageLabelText"
              align="center"
              variant="body2"
            >
              Upload PDF
            </Typography>
          </div>
        </FilesPicker>
      </div>
    );
  };

  renderDialogActions = () => {
    return (
      <TAButton
        onClick={this.onSave}
        color="primary"
        disabled={Boolean(!this.state.selectedPDFImages.length)}
        isLoading={this.state.saving}
        loadingMessage={"Saving"}
      >
        Save
      </TAButton>
    );
  };

  getDialogTitle = () => "Add PDF";
}
