import React from "react";
import { defaults } from "underscore";

const StripeCancel = () => {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <p>
        <b>Stripe Payment</b>
      </p>
      <p>Unsuccessful / Cancelled</p>
    </div>
  );
};

export default StripeCancel;
