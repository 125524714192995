import React, { Component } from "react";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import Grid from "react-virtualized/dist/commonjs/Grid";
import { BubbleLoader } from "react-css-loaders";

export default class InfiniteList extends Component {
  constructor(props) {
    super(props);

    if (!props.pageSize) {
      throw new Error("pageSize is required prop");
    }

    this.state = {
      hasNextPage: props.hasNextPage || true,
      isNextPageLoading: false,
      listW: 0,
      listH: 0,
    };

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    this.rowCount = () => {
      return this.state.hasNextPage
        ? this.props.data.length + 1
        : this.props.data.length;
    };

    // Every row is loaded except for our loading indicator row.
    this._isRowLoaded = ({ index }) => {
      if (this.state.hasNextPage) {
        return !this.state.hasNextPage || index < this.props.data.length;
      }
      return !this.state.hasNextPage && index < this.props.data.length;
    };

    // Render a list item or a loading indicator.
    this._rowRenderer = ({
      key, // Unique key within array of rows
      rowIndex, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style,
      columnIndex,
    }) => {
      let index = this.numberOfColumns() * rowIndex + columnIndex;
      if (
        !this._isRowLoaded({
          index: index,
        })
      ) {
        return null;
      } else {
        return this.rowRenderer({
          key,
          index,
          isScrolling,
          isVisible,
          style,
        });
      }
    };

    this._infiniteLoaderChildFunction =
      this._infiniteLoaderChildFunction.bind(this);
    this._onSectionRendered = this._onSectionRendered.bind(this);

    this._loadNextPage = this._loadNextPage.bind(this);

    this.loaderRef = null;
  }

  componentDidMount() {
    if (this.props.autoSize) {
      this.onresize = this.onresize.bind(this);
      window.addEventListener("resize", this.onresize);
      requestAnimationFrame(this.onresize.bind(this));
    }
  }

  componentWillUnmount() {
    if (this.props.autoSize) {
      window.removeEventListener("resize", this.onresize);
    }
  }

  onresize() {
    window._.debounce(this.updateSize.bind(this), 500, true)();
  }

  updateSize() {
    let size = this.getContainerSize();

    if (size[0] === 0 || size[1] === 0) {
      requestAnimationFrame(() => {
        this.onresize();
      });
      return;
    }

    this.setState({
      listW: Math.abs(size[0]) || 1,
      listH: Math.abs(size[1] || 1),
    });
  }

  getContainerSize() {
    if (!this.root) {
      this.root = document.querySelector(`#${this.props.containerId}`);
    }

    if (!this.root) {
      return [0, 0];
    }

    let padding = this.props.padding || [8, 8];
    let width = this.root.clientWidth - padding[0];
    let height = this.root.clientHeight;
    if (!height && width) {
      height = window.screen.availHeight;
    }
    height = height - padding[1];
    return [width, height];
  }

  availableColumnSpace() {
    let width = this.props.autoSize ? this.state.listW : this.props.width;
    let columnWidth = this.props.columnWidth || width;
    return Math.floor(width / columnWidth) || 1;
  }

  numberOfRows() {
    return Math.ceil(this.rowCount() / this.availableColumnSpace());
  }

  numberOfColumns() {
    return Math.min(this.availableColumnSpace(), this.rowCount());
  }

  _loadNextPage() {
    this.setState({
      isNextPageLoading: true,
    });
    return this.loadNextPage();
  }

  onDataLoaded(newData) {
    this.setState({
      isNextPageLoading: false,
      hasNextPage: newData && newData.length >= this.props.pageSize,
    });

    return Promise.resolve();
  }

  reset(hasNextPage, reload = false) {
    this.setState(
      {
        hasNextPage: hasNextPage,
      },
      () => {
        this.loaderRef.resetLoadMoreRowsCache(reload);
      }
    );
  }

  loadNextPage() {
    throw new Error("loadNextPage should be overridden");
  }

  rowRenderer() {
    throw new Error("rowRenderer should be overridden");
  }

  loadingIndicator() {
    return (
      <div className="listMessage">
        <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
      </div>
    );
  }

  render() {
    let w, h;

    if (this.props.autoSize) {
      w = this.state.listW;
      h = this.state.listH;
    } else {
      w = this.props.width;
      h = this.props.height;
    }

    return (
      <div>
        {this.state.isNextPageLoading ? this.loadingIndicator() : null}

        <InfiniteLoader
          ref={(ref) => {
            this.loaderRef = ref;
          }}
          {...this.props}
          data={this.props.data}
          width={w}
          height={h}
          selectedMap={this.props.selectedMap}
          isRowLoaded={this._isRowLoaded}
          renderSignal={this.state.renderSignal}
          loadMoreRows={this._loadNextPage}
          rowCount={this.rowCount()}
        >
          {this._infiniteLoaderChildFunction}
        </InfiniteLoader>

        {this.state.isNextPageLoading || this.props.data.length ? null : (
          <div className="listMessage">{this.props.emptyPlaceholder}</div>
        )}
      </div>
    );
  }

  _infiniteLoaderChildFunction({ onRowsRendered, registerChild }) {
    this._onRowsRendered = onRowsRendered;

    let w, h;

    if (this.props.autoSize) {
      w = this.state.listW;
      h = this.state.listH;
    } else {
      w = this.props.width;
      h = this.props.height;
    }

    return (
      <Grid
        tabIndex={-1}
        ref={registerChild}
        onRowsRendered={onRowsRendered}
        useDynamicRowHeight={true}
        rowCount={this.numberOfRows()}
        cellRenderer={this._rowRenderer}
        {...this.props}
        renderSignal={this.state.renderSignal}
        width={w}
        height={h}
        columnWidth={this.props.columnWidth || w}
        columnCount={this.numberOfColumns() || 1}
        onSectionRendered={this._onSectionRendered}
      />
    );
  }

  _onSectionRendered({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) {
    let columnCount = this.numberOfColumns();
    const startIndex = rowStartIndex * columnCount + columnStartIndex;
    const stopIndex = rowStopIndex * columnCount + columnStopIndex;

    this._onRowsRendered({
      startIndex,
      stopIndex,
    });
  }
}
