import { set } from "lodash";
import * as Yup from "yup";
import { TYPES } from "./educationAndCertifications";

export const BASIC_DETAILS = ["name", "email"];

export const BIO_AND_EXPERIENCE = ["valueProposition", "bio", "experience"];

export const basicDetailSchema = Yup.object().shape({
  picURL: Yup.string(),
  name: Yup.string().required("name required"),
  email: Yup.string().required("email required"),
  phone: Yup.string(),
});

export const bioAndExperienceSchema = Yup.object().shape({
  valueProposition: Yup.string().required("value-proposition required"),
  bio: Yup.string().required("bio required"),
  experience: Yup.number().required("experience required"),
});

export const educationSchema = Yup.object().shape({
  link: Yup.string(),
  name: Yup.string(),
  shortCode: Yup.string(),
  reference: Yup.string(),
  type: Yup.string().required("type required"),
});

export const educationAndCertificationsSchema = Yup.array()
  .of(educationSchema)
  .min(2, "at-least 2 required");

export const documentsSchema = Yup.object().shape({
  insuranceURL: Yup.string().required("insurance url required"),
  criminalRecordURL: Yup.string(),
});

const documentsAndEducationSchema = Yup.object().shape({
  others: documentsSchema,
  educationAndCertification: educationAndCertificationsSchema,
});

export const packageSchema = Yup.object().shape({
  name: Yup.string().required("required").min(4, "too short"),
  price: Yup.number().required("required").min(5, "too low"),
  per: Yup.mixed().oneOf(["one-time", "month", "session", "week"]),
  extras: Yup.array().of(Yup.string()),
});

export const programSchema = Yup.object().shape({
  name: Yup.string().required("required").min(4, "too short"),
  description: Yup.string().required("required").min(10, "too short"),
  packages: Yup.array()
    .of(packageSchema)
    .required("Must have at-least one package"),
});

export const programsSchema = Yup.array()
  .of(programSchema)
  .required()
  .min(1, "Must have at-least 1 program");

export const profileSchema = Yup.object().shape({
  picURL: Yup.string(),
  name: Yup.string().required("name required"),
  email: Yup.string().required("email required"),
  phone: Yup.string(),
  valueProposition: Yup.string().required("value-proposition required"),
  bio: Yup.string().required("bio required"),
  //TODO: Address object
  experience: Yup.number().required("experience required"),
  documents: documentsAndEducationSchema,
  programs: programsSchema,
});

export function validateBasicDetails(data) {
  try {
    basicDetailSchema.validateSync(data, { abortEarly: false });
  } catch (err) {
    return err;
  }
}

export function validateBioAndExperience(data) {
  try {
    bioAndExperienceSchema.validateSync(data, { abortEarly: false });
  } catch (err) {
    return err;
  }
}

export function validateServices(data) {
  return missingServices(data);
}

export function validateDocuments(data) {
  try {
    documentsAndEducationSchema.validateSync(data, { abortEarly: false });
    return missingEducationOrCertification({
      documents: data,
    });
  } catch (err) {
    return err;
  }
}

export function validatePrograms(data) {
  try {
    programsSchema.validateSync(data, { abortEarly: false });
  } catch (err) {
    return err;
  }
}

export function validateProfile(data) {
  try {
    profileSchema.validateSync(data, { abortEarly: false });
    return (
      missingEducationOrCertification(data) || missingServices(data.services)
    );
  } catch (err) {
    return mapErrors(err.inner);
  }
}

function mapErrors(err) {
  if (!err) {
    return;
  }
  let errors = {};
  let basicDetails = [];
  let bio = [];
  let documents = [];
  for (let error of err) {
    if (BASIC_DETAILS.includes(error.path)) {
      basicDetails.push(error.message);
    } else if (BIO_AND_EXPERIENCE.includes(error.path)) {
      bio.push(error.message);
    } else {
      set(errors, error.path, error.message);
    }
  }

  if (basicDetails.length) {
    errors.basicDetails = basicDetails;
  }
  if (bio.length) {
    errors.bio = bio;
  }
  if (documents.length) {
    errors.documents = documents;
  }

  return errors;
}

function missingEducationOrCertification(data) {
  if (!data.documents) {
    return { documents: new Error("Documents missing") };
  }
  if (!data.documents.educationAndCertification) {
    return { documents: new Error("Education missing") };
  }
  let educationType = data.documents.educationAndCertification.findIndex(
    (singleItem) =>
      singleItem.type === TYPES.EDUCATION && singleItem.example !== true
  );
  let certificationType = data.documents.educationAndCertification.findIndex(
    (singleItem) =>
      singleItem.type === TYPES.CERTIFICATION && singleItem.example !== true
  );

  if (certificationType === -1 || educationType === -1) {
    let errors = {};
    if (certificationType === -1) {
      errors.educationAndCertification = "Certification type required";
    }
    if (educationType === -1) {
      errors.educationAndCertification = "Education type required";
    }
    console.log(errors);
    return errors;
  }
}

function missingServices(data) {
  if (!data) {
    return { services: new Error("Services missing") };
  }
  if (!data.mode || (data.mode && !data.mode.length)) {
    return { services: new Error("Service mode missing") };
  }
  if (!data.type || (data.type && !data.type.length)) {
    return { services: new Error("Service type missing") };
  }
}
