
import React from 'react';
import { FormGroup, InputGroup, Input, InputGroupAddon } from 'reactstrap';

export default class SearchInput extends React.Component {
    render() {
        return (
            <FormGroup>

                <InputGroup className="exerciseSearchInput">
                    <Input
                        id={this.props.inputId}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                    />
                    <InputGroupAddon addonType="append">
                        <div id="clearFilterButton" className="inputAddonIconButton" onClick={this.props.onClickClear}>
                            <i className="icon material-icons">close</i>
                        </div>
                    </InputGroupAddon>
                </InputGroup>

            </FormGroup>
        )
    }
}