import React from "react";

import { Container, Row, Col, CardBody } from "reactstrap";

import { FormControlLabel, Checkbox } from "@material-ui/core";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { isEmail } from "validator";

import { ERROR_CODES, ROLES, TIER } from "../../constants";

import Autosuggest from "react-autosuggest";

import SignupBase from "../../components/base/signupBase.js";
import Card from "@material-ui/core/Card";
import { isMobileOnly } from "react-device-detect";
import ProfileImageUploader from "../../components/profileImageUploader";
import moment from "moment";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

const ButtonStyled = styled.button`
  padding: 10px 20px;
  background: #6b6b6b;
  color: white;
  border: none;
  outline: none;
  transition: 0.3s all;
  font-weight: 600;
  cursor: pointer;

  & > p {
    margin: 0;
    color: #eee;
  }

  &:hover {
    background: #626262;
  }
  &:active {
    background: #4d4d4d;
  }
`;

export default class Profile extends SignupBase {
  constructor(props) {
    super(props);

    this.fields = [
      "name",
      "email",
      "phone",
      "address",
      "city",
      "postalCode",
      "province",
      "picURL",
      "sameInfo",
      "notifyByEmail",
    ];

    if (!this.props.user) {
      this.fields.push("role");
    }

    this.state = Object.assign(this.state, {
      saveButtonEnabled: true,
      showModal: false,
    });

    for (let field of this.fields) {
      this.state[field] = "";

      this["onChange_" + field] = this.onChange.bind(this, field);
    }

    this.state = Object.assign(this.state, this.props.user);
    let authUser = window.FortisForma.database.currentUser();
    if (!this.state.email) {
      this.state.email = authUser.email;
    }

    this.save = this.save.bind(this);

    this.isEnterpriseAdminRight =
      this.props.user &&
      (this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ENTERPRISE_ADMIN);

    this.isPrivateRight =
      this.props.user &&
      this.props.user.role === ROLES.TRAINER &&
      this.props.user.tier === TIER.PRIVATE;

    if (this.isPrivateRight) {
      this.state.businessName = this.props.user.businessName;
    }
  }

  checkSimilarInfo() {
    const { name, phone, address, city, postalCode, province } = this.state;
    const facility = this.props.user.enterprise
      ? this.props.user.enterprise
      : {};
    if (
      name === facility.name &&
      phone === facility.phone &&
      address === facility.address &&
      city === facility.city &&
      postalCode === facility.postalCode &&
      province === facility.province
    ) {
      this.setState({
        sameInfo: true,
      });
    } else {
      this.setState({
        sameInfo: false,
      });
    }
  }

  componentDidMount() {
    if (this.isEnterpriseAdminRight) this.checkSimilarInfo();
  }

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    if (this.isEnterpriseAdminRight) {
      this.setState(update, () => {
        this.checkSimilarInfo();
      });
    } else {
      this.setState(update);
    }
  }

  handleSameInfoChange = (event) => {
    this.setState({
      sameInfo: event.target.checked,
      oldState: {
        name: this.state.name,
        phone: this.getCellNumber(this.state.phone),
        address: this.state.address,
        city: this.state.city,
        postalCode: this.getPostal(this.state.postalCode),
        province: this.state.province,
      },
    });
    const {
      name = "",
      address = "",
      city = "",
      phone = "",
      postalCode = "",
      province = "",
    } = this.props.user.enterprise;
    if (event.target.checked) {
      this.setState({
        name,
        phone,
        address,
        city,
        postalCode,
        province,
      });
    } else {
      this.setState({
        ...this.state.oldState,
      });
    }
  };

  goToHome = () => {
    this.props.history.replace("/dashboard");
  };

  isProfileCompleted() {
    const { name, address, city, email, phone, postalCode, province } =
      this.props.user;
    if (this.props.user.role === "Admin") {
      return true;
    } else {
      return Boolean(
        name && address && city && email && phone && postalCode && province
      );
    }
  }

  save(e) {
    e.nativeEvent.stopPropagation();

    if (this.checkErrorsInForm()) {
      return;
    }

    this.setState({
      saveButtonEnabled: false,
    });

    let profile = {};

    for (let field of this.fields) {
      profile[field] = this.state[field];
    }

    profile["phone"] = this.getCellNumber(this.state.phone);
    profile["notifyByEmail"] = this.state.notifyByEmail
      ? this.state.notifyByEmail
      : false;
    profile["postalCode"] = this.getPostal(this.state.postalCode);

    profile.emailVerified = true;
    if (this.isPrivateRight) {
      profile["businessName"] = this.state.businessName;
    }
    console.log("profile update data ==", profile);
    window.FortisForma.database
      .storeUserProfile(profile, !this.props.user)
      .then(async (results) => {
        window.NotificationUtils.showSuccess("Profile Updated");
        try {
          await this.props.onUserChange(profile);
          this.goToHome();
          this.setState({
            saveButtonEnabled: true,
          });
        } catch (e) {
          throw e;
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          saveButtonEnabled: true,
        });
        window.NotificationUtils.showError("Could not update profile");
      });
  }

  checkErrorsInForm() {
    if (!this.state.name) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector(`#name`),
        "name",
        `Please enter valid name`
      );
      return true;
    }
    return false;
  }

  showError(error) {
    console.error(error);

    switch (error.code) {
      case ERROR_CODES.DENIED:
        window.NotificationUtils.showError("Action Denied");
        return;
      default:
        window.NotificationUtils.showError("Unknown error code during login");
        throw error;
    }
  }

  setImageUrl = (val) => {
    this.setState({
      picURL: val,
    });
  };
  handleNotificationEmailChange = (event) => {
    this.setState({
      notifyByEmail: event.target.checked,
    });
  };
  renderPopupModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal}
        style={{ maxWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          this.setState({
            showModal: !this.state.showModal,
          });
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ showModal: false });
            }}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#800520",
              fontWeight: "bold",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Update Profile
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            textAlign: "center",
            paddingBottom: "44px",
          }}
        >
          We collect this information so the system can personalize the forms
          and PDFs when you send them to clients.
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const inputProps = {
      placeholder: "Province",
      value: this.state.province,
      className: "form-control",
      onChange: this.onChange_province,
    };
    let enterpriseIdPresent = this.props.user && this.props.user.enterpriseId;
    let isAdmin = this.props.user && this.props.user.role === "Admin";
    return (
      <>
        {!this.isProfileCompleted() && (
          <div
            style={{
              height: "80px",
              width: "100%",
              backgroundColor: "#8005205e",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "18px",
              color: "white",
              letterSpacing: "1px",
              marginBottom: "15px",
            }}
          >
            <i
              className="material-icons"
              style={{
                color: "white",
                width: "31px",
                padding: "4px",
                marginRight: "10px",
                backgroundColor: "#800520",
                borderRadius: "50%",
              }}
            >
              notifications_icon
            </i>
            Complete your profile now!
          </div>
        )}
        <Container fluid className="signupBody profileBody profileContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <h1 style={{ marginBottom: 0 }}>Update Profile</h1>
            {!isAdmin && (
              <i
                className="material-icons"
                style={{
                  color: "#800520",
                  width: "20px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ showModal: true })}
              >
                info_icon
              </i>
            )}
          </div>
          <Card
            className="loginCard"
            style={{ height: "max-content", overflow: "scroll" }}
            elevation={0}
            variant={isMobileOnly ? "" : "outlined"}
          >
            <CardBody>
              {enterpriseIdPresent && (
                <div className="imageProfileContainer mgBottom16">
                  <div className="profileImageContainer personImageContainer mgBottom16">
                    <ProfileImageUploader
                      storageRef={"profilePictures"}
                      fileName={
                        this.props.user &&
                        `${this.props.user.id}_${moment().unix()}`
                      }
                      fileUrl={this.state.picURL || ""}
                      setFileUrl={this.setImageUrl}
                      containerStyle={{
                        width: 100,
                        height: 100,
                        paddingTop: 0,
                        boxShadow: "none",
                        border: "solid 1px darkgrey",
                      }}
                      labelStyle={{ top: "40%" }}
                    />
                  </div>
                </div>
              )}
              <Form>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.onChange_name}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        disabled={true}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.onChange_email}
                        valid={isEmail(this.state.email)}
                        invalid={
                          this.state.email !== "" &&
                          isEmail(this.state.email) === false
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <Label for="phone">Phone</Label>
                      <NumberFormat
                        type="tel"
                        customInput={Input}
                        format="+1 ### ### ####"
                        mask=" "
                        placeholder="e.g. +1 999 999 9999"
                        name="phone"
                        id="phone"
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                      />
                    </FormGroup>
                  </Col>

                  {this.isPrivateRight && (
                    <Col lg="4">
                      <FormGroup>
                        <Label for="name">Business Name</Label>
                        <Input
                          type="text"
                          name="businessName"
                          id="businessName"
                          placeholder="Business Name"
                          value={this.state.businessName}
                          onChange={(e) =>
                            this.setState({ businessName: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col lg="4">
                    <FormGroup>
                      <Label for="address">Address</Label>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={this.state.address}
                        onChange={this.onChange_address}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={this.state.city}
                        onChange={this.onChange_city}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <Label for="postalCode">Postal Code</Label>
                      <Input
                        name="postalCode"
                        id="postalCode"
                        placeholder="Ex. K2B 7W1"
                        value={this.parsePostalCode(this.state.postalCode)}
                        onChange={this.onChange_postalCode}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <Label for="province">Province</Label>
                      <Autosuggest
                        suggestions={this.state.provinceSuggestions}
                        onSuggestionsFetchRequested={
                          this.onProvinceSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onProvinceSuggestionsClearRequested
                        }
                        getSuggestionValue={window.getSuggestionValue}
                        renderSuggestion={window.renderSuggestion}
                        inputProps={inputProps}
                      />
                    </FormGroup>
                  </Col>

                  {!this.props.user ? (
                    <Col lg="4">
                      <FormGroup>
                        <Label for="role">Select Role</Label>
                        <Input
                          type="select"
                          name="role"
                          id="role"
                          value={this.state.role}
                          onChange={this.onChange_role}
                        >
                          <option value={""}>Select</option>
                          <option>{ROLES.TRAINER}</option>
                          <option>{ROLES.PRACTITIONER}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  ) : null}

                  {this.isEnterpriseAdminRight && (
                    <Col lg="12">
                      <Checkbox
                        disabled={!this.isEnterpriseAdminRight}
                        checked={this.state.sameInfo}
                        onChange={this.handleSameInfoChange}
                        color="primary"
                      />
                      Same as facility info
                    </Col>
                  )}

                  <Col
                    className="center profileActionButton mgTop16"
                    style={{ flexDirection: "row", width: "50%" }}
                    lg="12"
                  >
                    <Button
                      disabled={this.state.saveButtonEnabled === false}
                      onClick={this.save}
                      color="primary"
                    >
                      Save Profile
                    </Button>
                  </Col>
                  {/* {this.isEnterpriseAdminRight && (
                    <Col lg="12">
                      <Checkbox
                        checked={this.state.notifyByEmail}
                        onChange={this.handleNotificationEmailChange}
                        color="primary"
                      />
                      Enable email notification to notify me once a client has
                      finished their workout.
                    </Col>
                  )} */}
                </Row>
              </Form>
            </CardBody>
          </Card>
          {!isAdmin && (
            <div
              style={{
                margin: "40px 0",
                width: "65%",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 style={{ marginBottom: "30px", textAlign: "center" }}>
                Account Management
              </h1>
              <Card elevation={0}>
                <ButtonStyled
                  disabled={this.state.saveButtonEnabled === false}
                  onClick={() => this.props.history.push("/delete-account")}
                >
                  Permanently Close Account
                  <p>More info</p>
                </ButtonStyled>
              </Card>
            </div>
          )}
          {this.renderPopupModal()}
        </Container>
      </>
    );
  }
}
