import { DialogActions, TextField, Dialog } from "@material-ui/core";
import React from "react";
import ImageUploader from "../../uploaderImage/imageUploader";
import { isMobile } from "react-device-detect";
import NoteAssessmentEditor from "./noteAssessmentEditor";
import AssessmentFormsModal from "../noteAssessmentFormModal/assessmentFormsModal";
import NoteAssessmentLockedContent from "./noteLockedContent";
import { COLLECTIONS } from "../../../constants";

export default class AssessmentEditor extends NoteAssessmentEditor {
  constructor(props) {
    super(props);
    this.state = {
      summary:
        (this.props.selectedItem && this.props.selectedItem.summary) || "",
      attachments:
        (this.props.selectedItem &&
          this.props.selectedItem.attachments && [
            ...this.props.selectedItem.attachments,
          ]) ||
        [],
      id:
        (this.props.selectedItem &&
          this.props.selectedItem.id &&
          this.props.selectedItem.id) ||
        "",
      ...this.state,
    };
  }

  getEditorTitle = () => {
    return this.props.selectedItem && this.props.selectedItem.id
      ? "Edit Assessment"
      : "Add Assessment";
  };

  onChangeImages = (images) => {
    this.setState(
      {
        attachments: images,
      },
      this.enableSaveButton
    );
  };

  hasNoContent = () => {
    if (!this.state.summary && !this.state.formTitle) {
      window.NotificationUtils.showError("Summary is required");
      return true;
    }
    return false;
  };

  onFormSaved = async (form) => {
    if (!form || !form.clientId || !form.schema) {
      throw new Error("Something went wrong");
    }
    this.setState(
      {
        formToSave: form.id,
        id: form.assessmentId,
      },
      () => this.onClickPreview(form)
    );
  };

  onClickViewForm = () => {
    if (this.state.id && (this.state.formToSave || this.state.formId)) {
      let form = {
        clientId: this.props.clientId,
        assessmentId: this.state.id,
        id: this.state.formToSave || this.state.formId,
        submissionData: this.state.submissionData || {},
        submissionId:
          (this.props.selectedItem && this.props.selectedItem.submissionId) ||
          "",
      };
      this.onClickPreview(form);
    }
  };

  getItemSpecificData = () => {
    let data = {
      summary: this.state.summary || this.state.formTitle,
      attachments: this.state.attachments || [],
    };
    return data;
  };

  getSuccessMessage = () => {
    return "Assessment Saved Successfully";
  };

  getErrorMessage = () => {
    return "Error Saving Assessment";
  };

  isWorkoutNote = () => false;

  renderImageUploader = () => {
    return (
      <ImageUploader
        disableSaveButton={this.disableSaveButton}
        selectedImages={
          (this.state.attachments &&
            this.state.attachments.length > 0 &&
            this.state.attachments) ||
          []
        }
        onChange={this.onChangeImages}
        setPreviewOpened={this.setPreviewOpened}
        pathToStore={"assessments/"}
        extensions={["png", "jpg", "jpeg", "pdf"]}
      />
    );
  };

  shouldDisableAddButton = () => {
    let isEmpty = true;
    if (this.state.summary) {
      isEmpty = false;
    }

    return isEmpty;
  };

  renderFormsModal = () => {
    return (
      <>
        <Dialog
          fullScreen={isMobile ? true : false}
          fullWidth={true}
          onClose={this.closeFormsModal}
          open={this.state.showFormsModal}
          maxWidth="lg"
        >
          <AssessmentFormsModal
            closeFormsModal={this.closeFormsModal}
            onClickPreview={this.onClickPreview}
            onFormSaved={this.onFormSaved}
            editItemId={this.state.id || ""}
            {...this.props}
          />
        </Dialog>
      </>
    );
  };

  renderEditorContent = () => {
    return (
      <div className="noteTitleContainer">
        <TextField
          disabled={!this.state.isEditable}
          variant="outlined"
          fullWidth={true}
          placeholder={"Summary"}
          value={this.state.summary}
          onChange={(e) => this.handleChange(e, "summary")}
          className="noteDescriptionTextField"
          multiline={true}
          rows="4"
        />
        {this.renderFormsModal()}
        {this.renderPreviewFormModal()}
      </div>
    );
  };

  renderLockedContent = () => {
    return (
      <NoteAssessmentLockedContent
        tooltipTitle="This Assessment is locked."
        isLocked={this.isLocked}
        isEdited={this.isEdited}
        data={this.props.selectedItem || {}}
        openSidePanel={this.props.openSidePanel}
        noteId={(this.props.selectedItem && this.props.selectedItem.id) || ""}
        loading={this.state.primaryLoading}
        collection={COLLECTIONS.ASSESSMENT_ARCHIVES}
        creatorDetails={this.state.creatorDetails}
        loadingCreator={this.state.loadingCreator}
      />
    );
  };

  renderActions = () => {
    if (this.state.isEditable) {
      return (
        <DialogActions className="noteEditorDialogActions">
          <div className="noteActionIcons">{this.renderLockedContent()}</div>
          <div>
            {this.renderPrimaryButton()}
            {this.renderSecondaryButton()}
          </div>
        </DialogActions>
      );
    }
  };
}
