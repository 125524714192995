import React from "react";
import * as Sentry from "@sentry/browser";
import App from "./App";
import { Button } from "reactstrap";

export default class FortisFormaErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Button
          className="errorReportingButton"
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </Button>
      );
    } else {
      //when there's not an error, render children untouched
      return <App />;
    }
  }
}
