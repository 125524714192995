import { Divider, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import VideocamIcon from "@material-ui/icons/YouTube";
import React from "react";
import { EQUIPMENT_TYPES, RESISTANCE_DROPDOWN_DATA } from "../../constants";
import Timer from "./timer";

const EDIT_DETAILS = {
  SETS: "Sets",
  REPS: "Reps",
  WEIGHT: "Weight",
  TIME: "Time(sec)",
};

export default class EditExerciseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exerciseDetail: Object.assign({}, props.exerciseDetail),
      showVideoNotes: false,
    };
  }

  componentDidMount() {
    let exerciseDetail = Object.assign({}, this.props.exerciseDetail);
    this.setState({ exerciseDetail });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.exerciseDetail.sets !== this.props.exerciseDetail.sets ||
      prevProps.exerciseDetail.reps !== this.props.exerciseDetail.reps ||
      prevProps.exerciseDetail.time !== this.props.exerciseDetail.time ||
      prevProps.exerciseDetail.weight !== this.props.exerciseDetail.weight ||
      prevProps.exerciseDetail.resistance !==
        this.props.exerciseDetail.resistance
    ) {
      let exerciseDetail = Object.assign({}, this.props.exerciseDetail);
      this.setState({ exerciseDetail });
    }
  }

  handleResistanceChange = (e) => {
    let exerciseDetail = Object.assign({}, this.state.exerciseDetail);
    exerciseDetail.resistance = e.target.value;
    this.setState({ exerciseDetail });
  };

  getDetails() {
    return Object.assign({}, this.state.exerciseDetail);
  }

  isEmptyEquipmentType() {
    if (this.isEmptyType) {
      return this.isEmptyType;
    }
    let types = Object.values(this.state.exerciseDetail.equipmentTypes);
    this.isEmptyType = true;
    for (let type of types) {
      if (type) {
        this.isEmptyType = false;
      }
    }
    return this.isEmptyType;
  }

  isRepsDisabled = () => {
    if (this.repsDisabled !== undefined) {
      return this.repsDisabled;
    }
    this.repsDisabled = !this.state.exerciseDetail.reps;
    return this.repsDisabled;
  };
  isSetsDisabled = () => {
    if (this.setsDisabled !== undefined) {
      return this.setsDisabled;
    }
    this.setsDisabled = !this.state.exerciseDetail.sets;
    return this.setsDisabled;
  };
  isTimeDisabled = () => {
    if (this.timeDisabled !== undefined) {
      return this.timeDisabled;
    }
    this.timeDisabled = !this.state.exerciseDetail.time;
    return this.timeDisabled;
  };

  isResistanceDisabled = () => {
    if (this.resistanceDisabled !== undefined) {
      return this.resistanceDisabled;
    }
    let equipmentTypes = Object.values(
      this.state.exerciseDetail.equipmentTypes
    );
    this.resistanceDisabled = !equipmentTypes.includes(
      EQUIPMENT_TYPES.RESISTANCE
    );
    if (this.resistanceDisabled && this.isEmptyEquipmentType()) {
      this.resistanceDisabled = !this.state.exerciseDetail.resistance;
    }
    return this.resistanceDisabled;
  };

  isWeightDisabled = () => {
    if (this.weightDisabled !== undefined) {
      return this.weightDisabled;
    }
    let equipmentTypes = Object.values(
      this.state.exerciseDetail.equipmentTypes
    );
    this.weightDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.FREEWEIGHT);
    if (this.weightDisabled && this.isEmptyEquipmentType()) {
      this.weightDisabled = !this.state.exerciseDetail.weight;
    }
    return this.weightDisabled;
  };

  onClickNumberContainer = (itemName, number) => {
    let exerciseDetail = this.state.exerciseDetail;
    switch (itemName) {
      case EDIT_DETAILS.REPS:
        let reps = parseInt(exerciseDetail.reps, 10) + number;
        exerciseDetail.reps = reps;
        if (exerciseDetail.reps <= 0) {
          exerciseDetail.reps = 1;
        }
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;
      case EDIT_DETAILS.SETS:
        let sets = parseInt(exerciseDetail.sets, 10) + number;
        exerciseDetail.sets = sets;
        if (exerciseDetail.sets <= 0) {
          exerciseDetail.sets = 1;
        }
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;
      case EDIT_DETAILS.TIME:
        let time = parseInt(exerciseDetail.time, 10) + number;
        exerciseDetail.time = time;
        if (exerciseDetail.time <= 0) {
          exerciseDetail.time = 1;
        }
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;

      case EDIT_DETAILS.WEIGHT:
        let weight = parseInt(exerciseDetail.weight, 10) + number;
        exerciseDetail.weight = weight;
        if (exerciseDetail.weight <= 0) {
          exerciseDetail.weight = 1;
        }
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;

      default:
        return;
    }
  };

  onChangeText = (e, itemName) => {
    let exerciseDetail = this.state.exerciseDetail;
    switch (itemName) {
      case EDIT_DETAILS.REPS:
        let reps = Number(e.target.value);
        exerciseDetail.reps = reps;
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;
      case EDIT_DETAILS.SETS:
        let sets = Number(e.target.value);
        exerciseDetail.sets = sets;
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;
      case EDIT_DETAILS.TIME:
        let time = Number(e.target.value);
        exerciseDetail.time = time;
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;

      case EDIT_DETAILS.WEIGHT:
        let weight = Number(e.target.value);
        exerciseDetail.weight = weight;
        this.setState(
          {
            exerciseDetail: exerciseDetail,
          },
          this.props.changeInExerciseDetail(exerciseDetail)
        );
        return;

      default:
        return;
    }
  };

  updateExerciseData = () => {
    let exerciseDetail = this.state.exerciseDetail;
    this.setState({
      sets: exerciseDetail.sets,
      reps: exerciseDetail.reps,
      weight: exerciseDetail.weight,
      time: exerciseDetail.time,
    });
  };
  onTimerStop = (time) => {
    let exerciseDetail = Object.assign({}, this.state.exerciseDetail);
    exerciseDetail.time = time;
    this.setState(
      {
        exerciseDetail: exerciseDetail,
      },
      this.props.changeInExerciseDetail(exerciseDetail)
    );
  };

  renderExerciseEdit(itemName, itemDetail) {
    return (
      <div
        className="workoutSessionExerciseEditContainer"
        id={`exerciseConfig`}
      >
        <div>
          <Button
            className="numberButtonEditDetailModal"
            variant="outlined"
            color="primary"
            onClick={() => this.onClickNumberContainer(itemName, -5)}
            disabled={this.props.disabled}
          >
            -5
          </Button>

          <Button
            className="numberButtonEditDetailModal"
            variant="outlined"
            color="primary"
            onClick={() => this.onClickNumberContainer(itemName, -1)}
            disabled={this.props.disabled}
          >
            -1
          </Button>
          <TextField
            className="editExerciseDetailItemContainer"
            value={itemDetail}
            onChange={(e) => this.onChangeText(e, itemName)}
            variant="outlined"
            disabled={this.props.disabled}
          />
          <Button
            className="numberButtonEditDetailModal"
            variant="outlined"
            color="primary"
            onClick={() => this.onClickNumberContainer(itemName, 1)}
            disabled={this.props.disabled}
          >
            +1
          </Button>
          <Button
            className="numberButtonEditDetailModal"
            variant="outlined"
            color="primary"
            onClick={() => this.onClickNumberContainer(itemName, 5)}
            disabled={this.props.disabled}
          >
            +5
          </Button>
        </div>
        {EDIT_DETAILS.TIME === itemName && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: 8, marginRight: 10 }}
            />
            <div id="timeContainer">
              <Timer
                time={itemDetail}
                onTimerStop={this.onTimerStop}
                disabled={this.props.disabled}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  renderExerciseDetail(itemName, itemDetail) {
    return (
      <React.Fragment>
        <Typography
          className="marginBottom8 marginTop16 fontLarger"
          style={{ fontWeight: "600" }}
        >
          {itemName}
        </Typography>
        {this.renderExerciseEdit(itemName, itemDetail)}
        <Divider style={{ width: "100%" }} />
      </React.Fragment>
    );
  }

  renderRepsDetail() {
    let exerciseDetail = this.state.exerciseDetail;
    return this.renderExerciseDetail(EDIT_DETAILS.REPS, exerciseDetail.reps);
  }

  renderSetsDetail() {
    let exerciseDetail = this.state.exerciseDetail;
    return this.renderExerciseDetail(EDIT_DETAILS.SETS, exerciseDetail.sets);
  }

  renderTimeDetail() {
    let exerciseDetail = this.state.exerciseDetail;
    return this.renderExerciseDetail(EDIT_DETAILS.TIME, exerciseDetail.time);
  }

  renderWeightDetail() {
    let exerciseDetail = this.state.exerciseDetail;
    return this.renderExerciseDetail(
      EDIT_DETAILS.WEIGHT,
      exerciseDetail.weight
    );
  }

  renderResistanceDetail() {
    return (
      <React.Fragment>
        <Typography
          className="marginBottom8 marginTop16"
          style={{ fontWeight: "600" }}
        >
          Resistance
        </Typography>
        <div id="resistanceInputContainer">
          <FormControl variant="outlined" id="resistanceFormControl">
            <Select
              id="resistanceInput"
              value={this.state.exerciseDetail.resistance}
              onChange={this.handleResistanceChange}
              disabled={this.props.disabled}
            >
              {RESISTANCE_DROPDOWN_DATA.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Divider style={{ width: "100%" }} />
      </React.Fragment>
    );
  }

  renderEditExerciseDetails = () => {
    return (
      <React.Fragment>
        {Boolean(Number(this.state.exerciseDetail.sets)) &&
          !this.isSetsDisabled() &&
          this.renderSetsDetail()}
        {Boolean(Number(this.state.exerciseDetail.reps)) &&
          !this.isRepsDisabled() &&
          this.renderRepsDetail()}
        {Boolean(
          Number(
            this.state.exerciseDetail.time ||
              this.state.exerciseDetail.time !== 0
          )
        ) &&
          !this.isTimeDisabled() &&
          this.renderTimeDetail()}
        {Boolean(Number(this.state.exerciseDetail.weight)) &&
          !this.isWeightDisabled() &&
          this.renderWeightDetail()}
        {Boolean(this.state.exerciseDetail.resistance) &&
          !this.isResistanceDisabled() &&
          this.renderResistanceDetail()}
      </React.Fragment>
    );
  };

  renderExerciseNote = () => {
    let exerciseDetail = this.props.exerciseDetail;
    if (!exerciseDetail.note && !exerciseDetail.videoNote) {
      return null;
    }
    return (
      <div className={"exerciseDetailNoteContainer"}>
        <Typography className="marginTop16" style={{ fontWeight: "600" }}>
          Note
        </Typography>
        {Boolean(exerciseDetail.note) && (
          <Typography variant="body2">{exerciseDetail.note}</Typography>
        )}
        {Boolean(exerciseDetail.videoNote) &&
          this.renderVideNoteIcon(exerciseDetail.videoNote)}
      </div>
    );
  };

  renderVideNoteIcon(videoURL) {
    return (
      <IconButton
        size="small"
        aria-label="show video notes"
        href={videoURL}
        target="_blank"
      >
        <VideocamIcon fontSize="large" />
      </IconButton>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {this.renderEditExerciseDetails()}
          {this.renderExerciseNote()}
        </div>
      </React.Fragment>
    );
  }
}
