import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';

import PropertyEditCard from '../../components/propertyEditCard/propertyEditCard';
import EditorPanel from '../../components/editorPanel/editorPanel.js';

import {
  COLLECTIONS
} from '../../constants';

export default class MuscleGroups extends EditorPanel {

  constructor(props) {
    super(props);
    this.rootClassName = "exerciseEditor";
    this.state = Object.assign({
      title: "Muscles Groups",
    }, this.state);

    this.onClickCancel = this.onClickCancel.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData(query) {
    if (!query) {
      query = {}
    }

    query.collection = COLLECTIONS.MUSCLE_GROUPS;
    query.filters = this.state.filters;

    query.pageConfig = {};

    if (this.state.lastFetched) {
      query.pageConfig.orders = [{
        key: "name",
        after: this.state.lastFetched.name
      }]
    } else {
      query.pageConfig.orders = [{
        key: "name"
      }]
    }

    try {
      let results = await window.FortisForma.database.queryData(query);
      this.setState({
        lastFetched: results[results.length - 1],
        data: this.state.data.concat(results),
      })
      return results
    } catch (e) {
      console.error(e)
      throw e;
    }
  }

  async save(item) {
    this.setState({
      selected: null
    })

    try {
      let results = await window.FortisForma.database.storeMuscleGroups(item);
      let data = Object.assign([], this.state.data);
      let index = window._.findIndex(data, (i) => i.id === results.id);
      if (index < 0) {
        index = data.length;
      }
      data[index] = results;

      this.setState({
        data: data
      })
    } catch (e) {
      throw e;
    }
  }

  mapRowToDocument(row) {
    let document = Object.assign({}, row);
    if (!document.name) {
      throw new Error("Missing values in row");
    }

    return document;
  }

  async bulkSave(docs) {
    try {
      let results = await window.FortisForma.database.bulkUploadMuscleGroups(docs);
      let data = Object.assign([], this.state.data);
      data = data.concat(results);
      this.setState({
        data: data
      })
    } catch (e) {
      throw e;
    }
  }

  onSelect = (item) => {
    super.onSelect(item);
    this.navigate();
  }

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown ? "keyboard_arrow_up" : "keyboard_arrow_down"
    return (<div className="icon material-icons">{iconName}</div>);
  }

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node =
        document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
      catch(e) {
        
      }
    }
    this.setState({
      didNavigateDown: !didNavigateDown
    })
  }

  renderContent() {
    return (
      <React.Fragment>
        <Row className="editorPanelContainer" style={{alignItems: "center", marginBottom: 16}}>
          <Col id="musclesList" lg="7" className="listContainer editorPanelList">
            {
              this.state.data.map((item) => {
                return (
                  <Card className="admin-component-card margin-zero" key={item.id} onClick={() => {
                      this.onSelect(item)
                    }}>
                    <CardBody className="admin-component-body padding-zero">
                      {item.name}
                      <div className="editIconContainer">
                        <i className="editIcon icon material-icons">edit</i>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
            }
          </Col>
          <Col lg="5" className="clientDetailContainer">
          {this.state.selected !== null ?
              <PropertyEditCard reload={this.reload} collection={COLLECTIONS.MUSCLE_GROUPS} placeholder={"Ex. Primary"} data={this.state.selected} onClickCancel={this.onClickCancel} onClickSave={this.save}></PropertyEditCard> :
              null
          }
          </Col>
        </Row>
        <div hidden={this.state.selected === null} className="screenNavigationHelperButton" onClick={this.navigate}>{this.navigationIcon()}</div>
      </React.Fragment>
    )
  }
}
