import React from "react";
import { BubbleLoader } from "react-css-loaders";
import { Col, Row } from "reactstrap";
import ProgramListItem from "./ProgramListItem";

export default function ProgramList(props) {
  return (
    <div
      className="fullWidth"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Row
        className="clientCardHeading centerAlignJustify"
        style={{ minHeight: 50 }}
      >
        <Col
          xs="4"
          style={{ justifyContent: "start", paddingLeft: 8 }}
          className="centerAlignJustify incFontWeight"
        >
          <strong>Challenge Name</strong>
        </Col>
        <Col xs="2" className="centerAlignJustify incFontWeight">
          <strong>Price</strong>
        </Col>
        <Col xs="2" className="centerAlignJustify incFontWeight">
          <strong>Visible</strong>
        </Col>
        <Col lg="4" md="4" sm="4" xs="4" className="centerAlignJustify" />
      </Row>
      <div id="clientEditorList" style={{ flex: 1 }}>
        {props.programs.length === 0 ? (
          <div className="listMessage">
            <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
          </div>
        ) : null}
        {props.programs.map((value, index) => {
          return (
            <ProgramListItem
              index={index}
              data={value}
              key={value.id}
              onSelect={props.onSelect}
              onClickDelete={props.onDelete}
              onClickEdit={props.onEdit}
              onToggleAvailability={props.onToggleAvailability}
            />
          );
        })}
      </div>
    </div>
  );
}
