import React from "react";

export default function AttachmentImageItem(props) {
  return (
    <div className="attachedImageContainer">
      <div
        className="assessmentImage"
        style={{
          backgroundImage: `url(${CSS.escape(props.data.url)})`,
          width: "100%",
        }}
      />
    </div>
  );
}
