import GenericFilters, { INITIAL_STATE } from "./genericFilters";
import React from "react";
import FortisFormaSelect from "../autoSuggest/dropdownSelect.js";
import { EQUIPMENT_TYPES } from "../../constants";

export default class EquipmentsFilter extends GenericFilters {
  constructor(props) {
    super(props);
    this.state.type = "";
    this.types = [
      {
        label: "Support",
        value: EQUIPMENT_TYPES.SUPPORT,
      },
      {
        label: "Resistance",
        value: EQUIPMENT_TYPES.RESISTANCE,
      },
      {
        label: "Free weight",
        value: EQUIPMENT_TYPES.FREEWEIGHT,
      },
    ];
  }

  handleTypeSelect = (selected) => {
    this.setState({ type: selected, didApply: true }, this.debouncedApply);
  };

  prepareFilters() {
    return {
      ...this.state.filters,
      type: this.state.type ? this.state.type.value : "",
    };
  }

  _clearFilters(clearAll) {
    this.setState({ filters: INITIAL_STATE.filters });
    if (clearAll) {
      this.setState(INITIAL_STATE, this.applyFilters);
      this.setState({ type: "" });
    }
  }

  renderExtras() {
    return (
      <div
        style={{
          minWidth: 200,
          margin: "0 8px",
          marginRight: 0,
        }}
      >
        <FortisFormaSelect
          isClearable={true}
          placeholder="Equipment Type"
          value={this.state.type}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.handleTypeSelect}
          options={this.types}
        />
      </div>
    );
  }
}
