import React from "react";
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import TrainerFilters from '../../../components/filters/trainerFilters';
import TrainerDetails from './trainerDetails';
import TrainerList from './trainerList';

export default class PendingApprovals extends React.Component {
  constructor(props) {
    super(props);
    this.title = "Registered Trainers";

    this.state = {
      addNew: false,
      filters: [],
      lastFetched: null,
      selectedTrainer: null,
      trainers: [],
      listLoading: true
    }

    this.listRef = null;
    this.pageSize = 20;

    this.filtersRef = null;
  }

  applyFilters = (filters) => {
    this.setState({
      lastFetched: null,
      filters: filters,
      trainers: []
    }, () => {
      this.listRef.reset(true);
    })
  }

  clearFilters = () => {
    this.filtersRef.reset()
    this.setState({
      lastFetched: null,
      filters: [],
      trainers: []
    }, () => {
      this.listRef.reset(true);
    })
  }

  fetchTrainers = async (clear) => {
    const filters = this.state.filters;

    let pageConfig = {}
    if (this.state.lastFetched) {
      pageConfig.orders = [{
        key: "name",
        after: this.state.lastFetched.name
      }]
    } else {
      pageConfig.orders = [{
        key: "name"
      }]
    }

    try {
      let results = await window.FortisForma.database.getPendingApprovals(filters, pageConfig);
      this.setState({
        lastFetched: results[results.length - 1],
        trainers: clear ? results : this.state.trainers.concat(results),
        listLoading: false
      })
    }
    catch (e) {
      window.NotificationUtils.showError("Unable to fetch trainer list");
      console.error(e)
      this.setState({
        listLoading: false
      })
    }
  }

  onSelect = (selectedTrainer) => {
    this.setState({
      selectedTrainer: selectedTrainer,
    })
    this.navigate();
  }

  approveTrainer = (trainer, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.setState({
      selectedTrainer: null,
      listLoading: true
    }, async () => {
      try {
        await window.FortisForma.database.approveSignupRequest(trainer.id);
        window.NotificationUtils.showSuccess("Trainer succesfully approved");
        this.setState({
          lastFetched: null
        }, () => {
          this.fetchTrainers(true);
        })
      }
      catch (e) {
        window.NotificationUtils.showError("Unable to approve trainer");
        this.setState({
          listLoading: false
        })
      }
    })

  }

  rejectTrainer = (trainer, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.setState({
      selectedTrainer: null,
      listLoading: true
    }, async () => {
      try {
        await window.FortisForma.database.declineSignupRequest(trainer.id);
        window.NotificationUtils.showSuccess("Trainer rejected succesfully");
        this.setState({
          lastFetched: null
        }, () => {
          this.fetchTrainers(true);
        })
      }
      catch (e) {
        window.NotificationUtils.showError("Unable to reject trainer");
        this.setState({
          listLoading: false
        })
      }
    })
  }

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown ? "keyboard_arrow_up" : "keyboard_arrow_down"
    return (<div className="icon material-icons">{iconName}</div>);
  }

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node =
        document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
      catch (e) {
      }
    }
    this.setState({
      didNavigateDown: !didNavigateDown
    })
  }


  renderSearch() {
    return (
      <Col id="clientsFilterContainer">
        <span id="filterText">Search {this.title}</span>
        <div style={{ maxWidth: 600 }}>
          <TrainerFilters ref={(ref) => { this.filtersRef = ref }} inclusive={true} onSetFilters={this.applyFilters} onCancel={this.clearFilters} />
        </div>
      </Col>
    )
  }


  emptyPlaceholder = () => {
    return (
      <div>
        <div>
          No trainers found matching your criteria
          </div>

        {
          this.state.filters.length ?
            (
              <div className="filtersSearch internal">
                <Button className="clearFiltersButton" color="primary" onClick={this.clearFilters}>Clear Filters</Button>
              </div>
            ) : null
        }

      </div>)
  }

  renderListLoader() {
    return (
      <div className="approvalsLoader"><Spinner color="primary" /></div>
    )
  }

  render() {
    return (
      <Container className="clientEditor" fluid>
        <Row className="fullWidth" style={{ alignItems: "center", marginBottom: 8 }}>
          {
            this.renderSearch()
          }
        </Row>
        <Row className="fullWidth fullWHListContainer">
          <Col lg="6" md="12" sm="12" xs="12" className="listContainer">

            <div className="fullWidth" style={{ display: 'flex', flexDirection: "column", "height": "100%" }}>
              <Row className="clientCardHeading centerAlignJustify" style={{ minHeight: 50 }}>
                <Col lg="1" md="1" sm="1" xs="1" />
                <Col lg="3" md="3" sm="3" xs="3" className="incFontWeight">
                  Name
      			    </Col>
                <Col lg="3" md="3" sm="3" xs="3" className="incFontWeight">
                  Email
      			    </Col>
                <Col lg="3" md="3" sm="3" xs="3" className="centerAlignJustify incFontWeight">
                  Phone
      			    </Col>
                <Col lg="2" md="2" sm="2" xs="2" />
              </Row>
              <div id="clientEditorList" style={{ flex: 1 }}>
                {(<TrainerList ref={(r) => {
                  this.listRef = r;
                }} data={this.state.trainers}
                  autoSize={true}
                  padding={[0, 0]}
                  containerId={"clientEditorList"}
                  rowHeight={60}
                  columnCount={1}
                  selectedTrainer={this.state.selectedTrainer}
                  approveTrainer={this.approveTrainer}
                  rejectTrainer={this.rejectTrainer}
                  onSelect={this.onSelect}
                  loadData={this.fetchTrainers}
                  pageSize={this.pageSize}
                  emptyPlaceholder={this.emptyPlaceholder()} />)}


                {
                  this.state.listLoading && this.renderListLoader()
                }

              </div>
            </div>

          </Col>
          <Col lg="6" md="12" sm="12" xs="12" className="clientDetailContainer">
            {this.state.selectedTrainer !== null ? <TrainerDetails selectedTrainer={this.state.selectedTrainer} /> : <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>Please select a trainer from the list</div>}
          </Col>
        </Row>

        <div hidden={this.state.selectedTrainer === null} className="screenNavigationHelperButton" onClick={this.navigate}>{this.navigationIcon()}</div>

      </Container>
    );
  }
}
