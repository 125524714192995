import { StoreProvider, useStoreActions } from "easy-peasy";
import React, { useEffect } from "react";
import store from "./StoreManager";
import { useStoreState } from "easy-peasy";
import { DB_KEYS, LOCAL_STORAGE_KEYS } from "../../constants";
import { BubbleLoader } from "react-css-loaders";
import styled from "styled-components";
import { Container, Row, Col, Button } from "reactstrap";
import ProgramList from "./ProgramList";
import ChallengesCard from "../../components/ChallengesCard/ChallengesCard";

const CenteredDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 50px;
  margin: auto;
`;

const DEFAULT_FILTERS = {
  filters: [
    {
      key: DB_KEYS.PUBLIC,
      operator: "==",
      value: true,
    },
  ],
};

function Manager(props) {
  const programs = useStoreState((state) => state.programs);
  const selectedProgram = useStoreState((state) => state.selectedProgram);
  const setSelectedProgram = useStoreActions(
    (actions) => actions.setSelectedProgram
  );

  const error = useStoreState((state) => state.error);
  const info = useStoreState((state) => state.info);
  const success = useStoreState((state) => state.success);
  const loading = useStoreState((state) => state.loading);

  const loadPrograms = useStoreActions((actions) => actions.loadPrograms);
  const removeProgram = useStoreActions((actions) => actions.removeProgram);

  const toggleProgramAvailability = useStoreActions(
    (actions) => actions.toggleProgramAvailability
  );

  const onClickNew = () => {
    props.history.push("/workout-assigner/create");
  };

  useEffect(() => {
    loadPrograms(DEFAULT_FILTERS);
  }, []);

  useEffect(() => {
    error && window.NotificationUtils.showError(error);
  }, [error]);

  useEffect(() => {
    info && window.NotificationUtils.showConfirm(info);
  }, [info]);

  useEffect(() => {
    success && window.NotificationUtils.showSuccess(success);
  }, [success]);

  const onEdit = (program) => {
    console.info("Edit", program);
    try {
      let programID = JSON.stringify({ id: program.id });
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
        programID
      );
      props.history.push("/workout-assigner/edit");
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = (program, index) => {
    console.info("Delete", program);
    removeProgram({ program, index });
  };

  const onToggleAvailability = (program, index) => {
    toggleProgramAvailability({ program, index });
  };

  if (loading) {
    return (
      <CenteredDiv>
        <BubbleLoader style={{ margin: "0 auto" }} color={"#800520"} size={4} />
      </CenteredDiv>
    );
  }
  return (
    <Container
      fluid
      className="workoutDaysEditor centerAlignJustify"
      style={{ flexDirection: "column" }}
    >
      <Row className="workoutDaysHeader">
        <Col
          className="button-small-margin"
          style={{ textAlign: "right", padding: 0, maxWidth: 200 }}
        >
          <Button
            id="workoutDayAddNewButton"
            color="primary"
            onClick={onClickNew}
          >
            Add New Challenge
          </Button>
        </Col>
      </Row>

      <Row
        className="fullWidth fullWHListContainer workoutDaysList"
        style={{ height: "calc(100vh - 150px)", marginTop: 16 }}
      >
        <Col lg="5" md="12" sm="12" xs="12" className="listContainer">
          {programs.length < 1 && !loading ? (
            <Placeholder onClickNew={onClickNew} />
          ) : (
            <ProgramList
              programs={programs}
              onSelect={setSelectedProgram}
              onEdit={onEdit}
              onDelete={onDelete}
              onToggleAvailability={onToggleAvailability}
            />
          )}
        </Col>

        <Col>
          {Boolean(selectedProgram) && (
            <ChallengesCard data={selectedProgram} />
          )}
        </Col>
      </Row>
    </Container>
  );
}

function Placeholder(props) {
  return (
    <div
      className="fullWidth daysList"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Button color="link" className="btn btn-link" onClick={props.onClickNew}>
        Create New Challenge
      </Button>
    </div>
  );
}

export default function ManagerWrapper(props) {
  return (
    <StoreProvider store={store}>
      <Manager {...props} />
    </StoreProvider>
  );
}
