import React from "react";
import NoteAssessmentEditor from "../noteAssessmentEditor/noteAssessmentEditor";
import {
  Popover,
  TextField,
  IconButton,
  Tooltip,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import PaletteIcon from "@material-ui/icons/Palette";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ImageUploader from "../../uploaderImage/imageUploader";
import _ from "lodash";
import { COLLECTIONS } from "../../../constants";
import NoteFormsModal from "../noteAssessmentFormModal/noteFormsModal";
import { isMobile } from "react-device-detect";
import NoteAssessmentLockedContent from "./noteLockedContent";

const COLORS = {
  DEFAULT: {
    title: "Default",
    code: "#fff",
    className: "defaultColorIconButton",
  },
  RED: { title: "Red", code: "#f28b82" },
  ORANGE: {
    title: "Orange",
    code: "#fcbc03",
  },
  YELLOW: {
    title: "Yellow",
    code: "#fdeb75",
  },
  GREEN: { title: "Green", code: "#c4e992" },
  TEAL: { title: "Teal", code: "#a2f5eb" },
  BLUE: { title: "Blue", code: "#cbf0f8" },
  DARKBLUE: {
    title: "Dark Blue",
    code: "#aecbfa",
  },
  PURPLE: {
    title: "Purple",
    code: "#d7aefb",
  },
  PINK: { title: "Pink", code: "#f6cee8" },
  BROWN: { title: "Brown", code: "#e6c9a8" },
  GREY: { title: "Grey", code: "#e8eaed" },
};
export default class NoteEditor extends NoteAssessmentEditor {
  constructor(props) {
    super(props);
    this.state = {
      title: (this.props.selectedItem && this.props.selectedItem.title) || "",
      description:
        (this.props.selectedItem && this.props.selectedItem.description) || "",
      color:
        (this.props.selectedItem && this.props.selectedItem.color) ||
        COLORS.DEFAULT.code,
      images:
        (this.props.selectedItem &&
          this.props.selectedItem.images && [
            ...this.props.selectedItem.images,
          ]) ||
        [],
      anchorEl: null,
      showColorPalette: false,
      showFormsModal: false,
      ...this.state,
    };
  }

  getEditorTitle = () => {
    if (this.editMode) {
      return "Edit Note";
    }
    return "Add Note";
  };

  openColorPalette = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      showColorPalette: true,
    });
  };

  closeColorpalette = () => {
    this.setState({
      anchorEl: null,
      showColorPalette: false,
    });
  };

  handleChangeColor = (color) => {
    this.setState({ color });
  };

  onChangeImages = (images) => {
    this.setState(
      {
        images: images,
      },
      this.enableSaveButton
    );
  };

  onFormSaved = async (form) => {
    if (!form || !form.clientId || !form.schema) {
      throw new Error("Something went wrong");
    }
    this.setState(
      {
        formToSave: form.id,
        id: form.noteId,
      },
      () => this.onClickPreview(form)
    );
  };

  onClickViewForm = () => {
    if (this.state.id && (this.state.formToSave || this.state.formId)) {
      let form = {
        clientId: this.props.clientId,
        noteId: this.state.id,
        id: this.state.formToSave || this.state.formId,
        submissionData: this.state.submissionData || {},
        submissionId:
          (this.props.selectedItem && this.props.selectedItem.submissionId) ||
          "",
      };
      this.onClickPreview(form);
    }
  };

  hasNoContent = () => {
    if (!this.state.title && !this.state.formTitle) {
      window.NotificationUtils.showError("Note title is required");
      return true;
    }
    return false;
  };

  getItemSpecificData = () => {
    let data = {
      title: this.state.title || this.state.formTitle,
      description: this.state.description,
      color: this.state.color,
      images: this.state.images || [],
    };

    if (this.props.programId) {
      data.summaryId = `${this.props.clientId}_${this.props.programId}`;
    }
    return data;
  };

  getSuccessMessage = () => {
    return "Note Saved Successfully";
  };

  getErrorMessage = () => {
    return "Error Saving Note";
  };

  isWorkoutNote = () => _.has(this.props, "programId");

  renderColorPalletePopOver = () => {
    return (
      <>
        <Popover
          onClose={this.closeColorpalette}
          anchorEl={this.state.anchorEl}
          open={this.state.showColorPalette}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {this.renderColorPalette()}
        </Popover>
        <IconButton onClick={this.openColorPalette}>
          <PaletteIcon />
        </IconButton>
      </>
    );
  };

  renderColors = () => {
    return Object.keys(COLORS).map((color, index) => {
      return (
        <Tooltip title={COLORS[color].title} key={index}>
          <IconButton
            size="small"
            onClick={() => this.handleChangeColor(COLORS[color].code)}
          >
            {this.state.color === COLORS[color].code ? (
              <CheckCircleOutlineIcon
                className={
                  COLORS[color].title === "Default"
                    ? COLORS[color].className
                    : null
                } //if default color selected then className is passed to the iconButton else inline styling is passed
                style={{ color: COLORS[color].code }}
              />
            ) : COLORS[color].title === "Default" ? (
              <FiberManualRecordOutlinedIcon
                className={COLORS[color].className}
              /> // For default outlined icon is used
            ) : (
              <FiberManualRecordIcon style={{ color: COLORS[color].code }} />
            )}
          </IconButton>
        </Tooltip>
      );
    });
  };

  renderColorPalette = () => {
    return <div className="colorPalette">{this.renderColors()}</div>;
  };

  renderImageUploader = () => {
    return (
      <ImageUploader
        disableSaveButton={this.disableSaveButton}
        selectedImages={
          (this.state.images &&
            this.state.images.length > 0 &&
            this.state.images) ||
          []
        }
        onChange={this.onChangeImages}
        setPreviewOpened={this.setPreviewOpened}
        pathToStore={"noteAttachments/"}
        extensions={["png", "jpg", "jpeg", "pdf"]}
      />
    );
  };

  renderFormsModal = () => {
    return (
      <>
        <Dialog
          fullScreen={isMobile ? true : false}
          fullWidth={true}
          onClose={this.closeFormsModal}
          open={this.state.showFormsModal}
          maxWidth="lg"
        >
          <NoteFormsModal
            closeFormsModal={this.closeFormsModal}
            onClickPreview={this.onClickPreview}
            onFormSaved={this.onFormSaved}
            editItemId={this.state.id || ""}
            {...this.props}
          />
        </Dialog>
      </>
    );
  };

  renderEditorContent = () => {
    return (
      <div className="noteTitleContainer">
        <TextField
          fullWidth={true}
          variant="outlined"
          placeholder="Title"
          value={this.state.title}
          className="noteTitleTextField"
          onChange={(e) => this.handleChange(e, "title")}
          multiline={true}
        />

        <TextField
          fullWidth={true}
          variant="outlined"
          value={this.state.description}
          onChange={(e) => this.handleChange(e, "description")}
          className="noteDescriptionTextField"
          multiline={true}
          rows="4"
        />
        {this.renderFormsModal()}
        {this.renderPreviewFormModal()}
      </div>
    );
  };

  renderLockedContent = () => {
    return (
      <NoteAssessmentLockedContent
        tooltipTitle="This note is locked."
        isLocked={this.isLocked}
        isEdited={this.isEdited}
        data={this.props.selectedItem || {}}
        openSidePanel={this.props.openSidePanel}
        noteId={(this.props.selectedItem && this.props.selectedItem.id) || ""}
        loading={this.state.primaryLoading}
        collection={COLLECTIONS.NOTE_ARCHIVES}
        creatorDetails={this.state.creatorDetails}
        loadingCreator={this.state.loadingCreator}
      />
    );
  };

  renderActions = () => {
    return (
      <DialogActions className="noteEditorDialogActions">
        <div className="noteActionIcons">
          {this.renderColorPalletePopOver()}
          {this.renderLockedContent()}
        </div>
        <div>
          {this.renderPrimaryButton()}
          {this.renderSecondaryButton()}
        </div>
      </DialogActions>
    );
  };
}
