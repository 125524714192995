import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import LoginAuthContent from "../../components/loginAuthCard/loginAuthCard";

export default function LoginAuthentication(props) {
  const renderLogo = () => {
    return (
      <div className="center mgBottom16">
        <img
          alt="FortisForma Logo"
          className="logo"
          src="/assets/img/logo.png"
        />
      </div>
    );
  };
  const routeToDashboard = () => {
    props.history.replace("/dashboard");
  };

  const renderAuthentication = () => {
    return (
      <Container className="loginBody" id="loginForm">
        <Row>
          <Col className="center">{renderLogo()}</Col>
        </Row>
        <Card className="authCard">
          <LoginAuthContent routeToDashboard={routeToDashboard} />
        </Card>
      </Container>
    );
  };
  return <>{renderAuthentication()}</>;
}
