import React, { useEffect } from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EnhancedTable from "../../components/table/EnhancedTable";
import moment from "moment";

const Container = styled.div`
  width: 100%;
  padding: 30px;
`;

const Heading = styled.p`
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: #800520;
`;

const SubHeading = styled(Heading)`
  font-size: 24px;
  text-align: left;
`;

const PurchaseHistory = (props) => {
  const [year, setYear] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [sendData, setSendData] = React.useState(tableData);
  const [purchaseHistoryData, setPurchaseHistoryData] = React.useState();
  const [isLoading, setIsLoading] = React.useState();

  const handleChange = (event) => {
    setYear(event.target.value);
    if (event.target.value === "") {
      setSendData(tableData);
    } else {
      let newData = tableData.filter(
        (row) => moment(row.datePaid).get("year") == event.target.value
      );
      setSendData(newData);
    }
  };

  useEffect(() => {
    const getTransactionsHistory = async () => {
      try {
        let results = await window.FortisForma.database.transactionsHistory();
        let data = [];
        if (results) {
          results.forEach((result) => {
            // let charge = result.data.object.charge;
            let invoiceId = result.data.object.id;
            let interval = result.data.object.lines.data[0].plan.interval;
            // let planName = result.data.object.lines.data[0].plan.nickname;
            let planName = result.data.object.lines.data[0].description;
            let totalAmount = result.data.object.total / 100;
            let accountName = result.data.object.account_name;
            let cusName = result.data.object.customer_name;
            let cusEmail = result.data.object.customer_email;
            let datePaid = result.data.object.created;
            let endDate = result.data.object.lines.data[0].period.end;
            let startDate = result.data.object.lines.data[0].period.start;
            let receiptNo = result.data.object.receipt_number;
            let subTotal = result.data.object.subtotal;
            let tax = result.data.object.tax;
            let discount = result.data.object.total_discount_amounts[0]
              ? result.data.object.total_discount_amounts[0].amount
              : null;
            let total = result.data.object.total;
            let basePrice = result.data.object.lines.data[0].plan.amount;
            let invoiceNo = result.data.object.number;
            let chargeId = result.data.object.charge;
            let planText =
              interval === "year"
                ? `Yearly subscription to ${planName}`
                : interval === "month"
                ? `Monthly subscription to ${planName}`
                : "";
            let subData = {};
            if (totalAmount === 0) {
              subData = {
                invoiceNo,
                receiptNo,
                datePaid,
                chargeId,
                accountName,
                cusName,
                cusEmail,
                subTotal: 0,
                discount: 0,
                tax: 0,
                total: 0,
                basePrice: 0,
                prodDescription: planName,
                startDate,
                endDate,
              };
            }

            if (totalAmount > 0) {
              subData = {
                invoiceNo,
                receiptNo,
                datePaid,
                chargeId,
                accountName,
                cusName,
                cusEmail,
                subTotal,
                discount,
                tax,
                total,
                basePrice,
                prodDescription: planName,
                startDate,
                endDate,
              };
            }
            data.push({
              date: result.created,
              description: planName,
              total: totalAmount,
              id: invoiceId,
              data: subData,
            });
          });
        }
        setTableData(data);
        setSendData(data);
      } catch (e) {
        console.log("error in transactions", e);
      }
    };
    getTransactionsHistory();
  }, []);

  useEffect(() => {
    const getPurchaseHistoryDetails = async () => {
      setIsLoading(true);
      let result = await window.FortisForma.database.getPurchaseHistory(
        `payment_history/${props.user.customerId || null}?${new URLSearchParams(
          { year }
        )}`
      );
      setPurchaseHistoryData(result);
      setIsLoading(false);
    };
    getPurchaseHistoryDetails();
  }, [year]);

  return (
    <Container>
      <Heading>Purchase History</Heading>
      <FormControl
        variant="filled"
        style={{ minWidth: "250px", marginBottom: "30px" }}
      >
        <InputLabel id="demo-simple-select-filled-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={year}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2020}>2020</MenuItem>
        </Select>
        <FormHelperText
          style={{ color: "#800520", fontSize: "20px", margin: 0 }}
        >
          Select year
        </FormHelperText>
      </FormControl>
      {purchaseHistoryData && (
        <EnhancedTable
          data={purchaseHistoryData.history}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};
export default PurchaseHistory;
