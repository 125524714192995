import { Button, Tab, Tabs, Typography } from "@material-ui/core";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import React from "react";
import FormDetailsCard from "../../components/forms/formDetailCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  FORM_TYPES,
  ROLES,
  TIER,
  GET_ENT_ID,
  GET_USER_ID,
} from "../../constants";
import CreateForm from "../../components/forms/createFormModal";
import PreviewForm from "../../components/forms/previewFormModal";
import ChangeSequence from "../../components/forms/changeSequence";
import Dialog from "@material-ui/core/Dialog";
import SignatureField from "../../components/signatureField/signatureField";
import { isMobile } from "react-device-detect";
import PDFViewer from "../../components/forms/pdfViewer";
import DatePicker from "../../components/datePickerWidget/datePickerWidget";
import PhoneField from "../../components/phoneFieldWidget/phoneFieldWidget";
import UseGlobalTemplates from "../../components/forms/useGlobalTemplates";
import FreehandNote from "../../components/freehandNoteWidget/freehandNote";
import FormImagesEditor from "../../components/formImagesModal/formImagesEditor";
import AddImageWidget from "../../components/addImageWidget/addImageWidget";
const LOADING_MESSAGES = ["Deleting form"];
const LOADING_CHANGE_DURATION = 5000;

export default class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: FORM_TYPES.REGISTRATION,
      formList: [],
      loading: false,
      openCreateFormModal: false,
      openUseTemplatesModal: false,
      hasFetchedForms: false,
      globalForms: [],
      modalSelectedTab: null,
      previewForm: false,
      editFormId: null,
      data: {},
      openChangeSequenceModal: false,
      formImages: false,
    };
    this.filteredFormData = [];
    this.isAdmin =
      this.props.user &&
      this.props.user.role &&
      this.props.user.role === ROLES.ADMIN;
  }
  componentDidMount() {
    this.fetchForms();
  }

  fetchForms = async () => {
    try {
      this.setState({
        loading: true,
      });
      let result = [];
      const enterpriseId = GET_ENT_ID(this.props);
      const userID = GET_USER_ID(this.props);

      result = await window.FortisForma.database.fetchEnterpriseForms(
        enterpriseId,
        this.isAdmin,
        "",
        userID
        // this.props.user.id
      );
      // if (
      //   this.props.user.role === ROLES.TRAINER &&
      //   this.props.user.tier !== TIER.FREE
      // ) {
      //   console.log('this.isAdmin',this.isAdmin)
      //   result = await window.FortisForma.database.fetchUserForms(
      //     this.props.user.id
      //   );
      //   // result = await window.FortisForma.database.fetchEnterpriseForms(
      //   //   this.props.user.id,
      //   //   this.isAdmin,
      //   //    "pp"
      //   // );
      // } else {
      //   result = await window.FortisForma.database.fetchEnterpriseForms(
      //     this.props.user.enterpriseId,
      //     this.isAdmin,
      //     "",
      //     this.props.user.id
      //   );
      // }
      let arr = [];
      for (let i = 0; i <= 4; i++) {
        arr.push(await this.addSeqNum(i, result));
      }
      if (arr.includes(true)) {
        this.fetchForms();
      } else {
        this.setState({
          formList: result || [],
          loading: false,
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  fetchGlobalForms = async () => {
    try {
      var results = await window.FortisForma.database.fetchGlobalForms();
      this.setState({
        globalForms: results,
        hasFetchedForms: true,
      });
      return results;
    } catch (error) {
      throw error;
    }
  };

  handleTabChange = (e, value) => {
    this.setState({
      step: value,
    });
  };

  onClickAddForm = () => {
    this.setState(
      {
        openCreateFormModal: true,
      },
      this.handleCloseFormMenu
    );
  };

  onClickUseTemplates = () => {
    this.setState(
      {
        openUseTemplatesModal: true,
      },
      this.handleCloseFormMenu
    );
  };

  onClickChangeSequence = () => {
    this.setState({
      openChangeSequenceModal: true,
    });
  };

  closeCreateFormModal = () => {
    this.setState({
      openCreateFormModal: false,
      data: {},
      editFormId: null,
    });
  };

  onClickFormImages = () => {
    this.setState({ formImages: true });
  };

  onCloseFormImagesModal = () => {
    this.setState({ formImages: false });
  };

  onSaveForm = () => {
    this.fetchForms();
    this.setState({
      data: {},
      editFormId: null,
    });
  };

  getSequenceNumber = (data) => {
    var value = undefined;
    var filtered = data ? data : this.filteredFormData;
    if (filtered && filtered.length && filtered.length > 0) {
      value =
        Math.max.apply(
          Math,
          filtered.map(function (o) {
            return o.sequence;
          })
        ) + 1;
    }
    if (!value) {
      value = 1;
    }
    return value;
  };

  renderCreateFormModal = () => {
    if (!this.state.openCreateFormModal) {
      return;
    }
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closeCreateFormModal}
        open={this.state.openCreateFormModal}
        maxWidth="md"
      >
        <CreateForm
          closeCreateForm={this.closeCreateFormModal}
          onSaveForm={this.onSaveForm}
          data={this.state.data}
          editFormId={this.state.editFormId}
          tabSelected={this.state.step}
          {...this.props}
          sequenceNumber={this.getSequenceNumber()}
        />
      </Dialog>
    );
  };
  renderChangeSequenceModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closeChangeSequenceModal}
        open={this.state.openChangeSequenceModal}
        maxWidth="sm"
      >
        <ChangeSequence
          closeChangeSequenceModal={this.closeChangeSequenceModal}
          formList={this.state.formList}
          selectedTab={this.state.step}
        />
      </Dialog>
    );
  };

  renderUseTemplatesModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closeUseTemplatesModal}
        open={this.state.openUseTemplatesModal}
        maxWidth="lg"
      >
        <UseGlobalTemplates
          closeUseTemplatesModal={this.closeUseTemplatesModal}
          fetchGlobalForms={this.fetchGlobalForms}
          hasFetchedForms={this.state.hasFetchedForms}
          globalForms={this.state.globalForms}
          onClickPreview={this.onClickPreview}
          user={this.props.user || {}}
          formList={this.state.formList}
          fetchForms={this.fetchForms}
          tabOpenBefore={this.state.modalSelectedTab}
          selectedTab={this.state.step}
        />
      </Dialog>
    );
  };

  renderPreviewFormModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closePreviewForm}
        open={this.state.previewForm}
        maxWidth="md"
      >
        <PreviewForm
          closePreviewForm={this.closePreviewForm}
          data={this.state.data}
          widgets={{
            signatureField: SignatureField,
            pdfViewer: PDFViewer,
            DateWidget: DatePicker,
            phoneField: PhoneField,
            freehandNote: FreehandNote,
            addImageWidget: AddImageWidget,
          }}
        />
      </Dialog>
    );
  };

  renderFormImagesModal = () => {
    return (
      <Dialog
        fullWidth={true}
        onClose={this.onCloseFormImagesModal}
        open={this.state.formImages}
        maxWidth="md"
      >
        <FormImagesEditor
          onClose={this.onCloseFormImagesModal}
          user={this.props.user}
          {...this.props}
        />
      </Dialog>
    );
  };

  onClickPreview = (form, modalSelectedTab = null) => {
    this.setState({
      data: form,
      previewForm: true,
      modalSelectedTab: modalSelectedTab,
      openUseTemplatesModal: false,
    });
  };

  closePreviewForm = () => {
    this.setState({
      previewForm: false,
      data: {},
      editFormId: null,
      openUseTemplatesModal: this.state.modalSelectedTab ? true : false,
    });
  };

  closeUseTemplatesModal = () => {
    this.setState({
      openUseTemplatesModal: false,
      modalSelectedTab: null,
    });
  };

  closeChangeSequenceModal = () => {
    this.setState(
      {
        openChangeSequenceModal: false,
      },
      this.fetchForms
    );
  };

  onClickEdit = (form, event) => {
    event.stopPropagation();
    this.setState({
      data: form,
      openCreateFormModal: true,
      editFormId: form.id,
    });
  };

  onDeleteConfirmation = async (form) => {
    window.showLoadingMessages &&
      window.showLoadingMessages(LOADING_MESSAGES, LOADING_CHANGE_DURATION);
    try {
      let data = {
        id: form.id,
        active: false,
      };
      if (form.global) {
        await window.FortisForma.database.deleteGlobalForm(data);
      } else {
        await window.FortisForma.database.saveForms(data);
      }
      window.hideLoadingMessages && window.hideLoadingMessages();
      window.NotificationUtils.showSuccess("Form Deleted successfully");
      this.fetchForms();
    } catch (e) {
      console.error(e);
      window.hideLoadingMessages && window.hideLoadingMessages();
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  onClickDelete = async (form, event) => {
    event.stopPropagation();
    window.customConfirm(
      `Are you sure you want to remove ${
        form.schema.title ? form.schema.title : "this"
      } ?`,
      () => {
        this.onDeleteConfirmation(form);
      }
    );
  };

  renderTabs = () => {
    return (
      <div className="TabsContainer">
        <Tabs
          value={this.state.step}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Registration" value={FORM_TYPES.REGISTRATION} />
          <Tab label="Notes" value={FORM_TYPES.NOTES} />
          <Tab label="Assessments" value={FORM_TYPES.ASSESSMENTS} />
          <Tab label="General Info" value={FORM_TYPES.GENERAL_INFO} />
          <Tab label="Misc" value={FORM_TYPES.MISC} />
        </Tabs>
      </div>
    );
  };

  addSeqNum = async (formType, formList) => {
    const filterData = formList.filter((form) => form.step === formType);
    const nullSeqNum = formList.filter(
      (form) => form.step === formType && !form.sequence
    );
    //For same sequnece
    const lookup = filterData.reduce((a, e) => {
      a[e.sequence] = ++a[e.sequence] || 0;
      return a;
    }, {});
    const duplicateSeqNum = filterData.filter(
      (e) => lookup[e.sequence] && e.sequence != null
    );
    const finalArr = [...nullSeqNum, ...duplicateSeqNum];
    if (finalArr.length > 0) {
      const sequenceNo = this.getSequenceNumber(filterData);
      const filterIds = finalArr.map((data) => data.id);
      await window.FortisForma.database.addSeqNoInDocs(filterIds, sequenceNo);
      return true;
    } else {
      return false;
    }
  };

  renderCards = () => {
    switch (this.state.step) {
      case FORM_TYPES.REGISTRATION:
        this.filteredFormData = this.state.formList.filter(
          (form) => form.step === FORM_TYPES.REGISTRATION
        );
        break;
      case FORM_TYPES.ASSESSMENTS:
        this.filteredFormData = this.state.formList.filter(
          (form) => form.step === FORM_TYPES.ASSESSMENTS
        );
        break;
      case FORM_TYPES.GENERAL_INFO:
        this.filteredFormData = this.state.formList.filter(
          (form) => form.step === FORM_TYPES.GENERAL_INFO
        );
        break;
      case FORM_TYPES.MISC:
        this.filteredFormData = this.state.formList.filter(
          (form) => form.step === FORM_TYPES.MISC
        );
        break;
      case FORM_TYPES.NOTES:
        this.filteredFormData = this.state.formList.filter(
          (form) => form.step === FORM_TYPES.NOTES
        );
        break;
      default:
        this.filteredFormData = [];
        break;
    }
    if (this.state.loading) {
      return <CircularProgress className="spinnerForDocumentEditor" />;
    }
    if (!this.filteredFormData.length) {
      return this.renderEmptyPlaceHolder();
    }
    this.filteredFormData.sort((form1, form2) => {
      return form2.sequence - form1.sequence;
    });
    return this.filteredFormData.map((form, key) => {
      return (
        <FormDetailsCard
          data={form.schema}
          key={key}
          onClickPreview={() => this.onClickPreview(form)}
          onClickEdit={(event) => this.onClickEdit(form, event)}
          onClickDelete={(event) => this.onClickDelete(form, event)}
        />
      );
    });
  };

  renderEmptyPlaceHolder = () => {
    return (
      <div id="documentEmptyPlaceHolderContainer">
        <Typography>
          No forms added in this section. Click on the button above to add new
          form.
        </Typography>
      </div>
    );
  };

  getPageHeaderText = () => {
    if (this.isAdmin) {
      return "Forms";
    }
    return "Documents";
  };

  disableDropdown = () => {
    if (
      this.props.user.role === ROLES.FACILITY_ADMIN ||
      this.props.user.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.user.enterprise.active) return false;
    } else {
      if (this.props.user.active) return false;
    }

    return true;
  };

  renderMenuButton = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          // disabled={this.disableDropdown()}
          className="documentsAddFormsDropdownButton"
          caret
        >
          Add Form
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            style={{ fontWeight: "600", color: "black" }}
            onClick={this.onClickUseTemplates}
          >
            Use Template
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            style={{ fontWeight: "600", color: "black" }}
            onClick={this.onClickAddForm}
          >
            Add New
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="documentPageContainer">
          <div className="headerAndButtonContainer">
            <Typography variant="h4">{this.getPageHeaderText()}</Typography>

            <div className="documentsActionButtonsContainer">
              {!this.isAdmin && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="useTemplatesButton"
                    onClick={this.onClickFormImages}
                  >
                    Form Images
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={this.state.loading}
                    className="useTemplatesButton"
                    onClick={this.onClickChangeSequence}
                  >
                    Change Sequence
                  </Button>
                </>
              )}

              {this.isAdmin ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.onClickAddForm}
                >
                  Add Form
                </Button>
              ) : (
                this.renderMenuButton()
              )}
            </div>
          </div>
          <div className="documentEditorContainer">
            <div className="documentEditor">
              {this.renderTabs()}
              <div
                className={
                  this.state.loading
                    ? "spinnerForDocumentEditorContainer"
                    : "formCardsContainer"
                }
              >
                <div className="innerFormCardsContainer">
                  {this.renderCards()}
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.renderCreateFormModal()}
        {this.renderPreviewFormModal()}
        {this.renderUseTemplatesModal()}
        {this.renderChangeSequenceModal()}
        {this.renderFormImagesModal()}
      </React.Fragment>
    );
  }
}
