import React from "react";
import SectionedExerciseCard from "./sectionedListExerciseCard";
import { WORKOUT_SECTIONS_NAMES } from "../../constants";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Tooltip from "@material-ui/core/Tooltip";

export default class WorkoutSection extends React.Component {
  state = {
    expanded: [true],
  };
  expandSection = (index, e) => {
    e.stopPropagation();
    let expanded = Object.assign([], this.state.expanded);
    expanded[index] = true;
    this.setState({
      expanded,
    });
  };

  collapseSection = (index, e) => {
    e.stopPropagation();
    let expanded = Object.assign([], this.state.expanded);
    expanded[index] = false;
    this.setState({
      expanded,
    });
  };

  render() {
    let section = this.props.section;
    return (
      <div
        className="viewWorkoutSection"
        onClick={(e) => e.stopPropagation && e.stopPropagation()}
      >
        <div className="viewWorkoutSectionHeader">
          <div className="viewWorkoutSectionName">
            {`${section.workoutType}${
              section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT
                ? ` ${
                    this.props.firstSection.workoutType ===
                    WORKOUT_SECTIONS_NAMES.CIRCUIT
                      ? this.props.listIndex + 1
                      : this.props.listIndex
                  }`
                : ""
            }`}
            {section.repeatCircuit && (
              <div className="circuitDisplay">
                Circuit Count <span>{section.repeatCircuit.count}</span>
              </div>
            )}
            {section.repeatCircuit
              ? section.repeatCircuit.description && (
                  <Tooltip
                    arrow={true}
                    title={section.repeatCircuit.description}
                  >
                    <p className="descBtn">Description</p>
                  </Tooltip>
                )
              : null}

            <div className="viewSetsCounter" style={{ display: "none" }}>
              {section.sets && `x ${section.sets}`}
            </div>
          </div>
          {this.props.collapsible !== false && (
            <>
              {this.state.expanded[this.props.listIndex] !== false ? (
                <IconButton
                  size="small"
                  style={{ marginLeft: 4 }}
                  onClick={(e) => this.collapseSection(this.props.listIndex, e)}
                >
                  <ExpandLessIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  style={{ marginLeft: 4 }}
                  onClick={(e) => this.expandSection(this.props.listIndex, e)}
                >
                  <ExpandMoreIcon color="primary" />
                </IconButton>
              )}
            </>
          )}
        </div>
        {this.props.collapsible !== false ? (
          <Collapse in={this.state.expanded[this.props.listIndex] !== false}>
            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
              {section.exercises &&
                section.exercises.length > 0 &&
                section.exercises.map((exercise, index) => {
                  return <SectionedExerciseCard key={index} data={exercise} />;
                })}
            </div>
          </Collapse>
        ) : (
          <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            {section.exercises &&
              section.exercises.length > 0 &&
              section.exercises.map((exercise, index) => {
                if (this.props.workoutType === "logged") {
                  let loggedSection =
                    this.props.loggedData[this.props.loggedWorkoutId][
                      this.props.listIndex
                    ];
                  let logged = loggedSection && loggedSection[index];
                  if (!logged) {
                    // REVIEW: Render not completed view
                    return (
                      <div
                        key={index}
                        className="sectionedExerciseCard"
                        style={{
                          minHeight: 60,
                          width: "100%",
                          backgroundColor: "#F0F0F0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Exercise not logged
                      </div>
                    );
                  } else if (logged.exercise) {
                    return (
                      <SectionedExerciseCard
                        key={index}
                        data={logged.exercise}
                      />
                    );
                  }
                  let data = Object.assign({}, exercise, logged);
                  return <SectionedExerciseCard key={index} data={data} />;
                }
                return <SectionedExerciseCard key={index} data={exercise} />;
              })}
          </div>
        )}
      </div>
    );
  }
}
