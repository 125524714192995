import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const StyledButton = styled(Button)`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 8px;
  &.MuiButton-root {
    font-size: 15px;
    text-transform: none;
    padding: 8px 20px;
    font-weight: 500;
  }
  &.MuiButton-outlinedPrimary {
    border: none;
    outline: 3px solid #802520;
    outline-offset: -2px;
  }
  &.MuiButton-textPrimary:hover {
    color: #802520 !important;
  }
`;
const TAButton = (props) => {
  const {
    children,
    backgroundColor,
    textColor,
    height,
    width,
    style,
    border,
    ...rest
  } = props;

  const customStyle = {};
  if (backgroundColor) customStyle.backgroundColor = backgroundColor;
  if (textColor) customStyle.color = textColor;
  if (width) customStyle.width = width;
  if (height) customStyle.height = height;
  if (border) customStyle.border = "3px solid #802520";
  return (
    <StyledButton
      disableElevation
      {...rest}
      style={{ ...customStyle, ...style }}
    >
      {children}
    </StyledButton>
  );
};

export default TAButton;
