import hash from "object-hash";
import React from "react";
import ChipInput from "material-ui-chip-input";
import { Checkbox } from "@material-ui/core";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Delete from "../../assets/delete.png";
import { COLLECTIONS, RESISTANCE_LEVELS, ROLES } from "../../constants";
import FortisFormaSelect from "../autoSuggest/dropdownSelect.js";
import Factory from "../../modules/http/dataFetcher";
import Toggle from "react-toggle";
import bodybuilder from "bodybuilder";
import ImageUploaderModal from "../uploaderModal/uploaderModal";

export default class ExerciseEditCard extends React.Component {
  toSelectValue = (currentValue) => {
    if (!currentValue) {
      return null;
    }

    return {
      label: currentValue,
      value: currentValue,
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      muscles: [],
      muscleGroups: [],
      movements: [],
      resistance: [],
      functions: [],
      functionCategories: [],
      equipments: [],
      equipmentCategories: [],
      exerciseConfig: [],
      videoUploadMessage: "No video selected",
      videoUploaded: false,
      videoSelected: false,
      posterSelected: false,
      videoURL: "",
      posterURL: "",
      posterUploaded: false,
      posterAsShot: true,
      posterUploadMessage: "No poster selected",
      description: "",
      alternateNames: [],
      hidden: false,
      alternateNamesOption: [],
      shotsUploadMessage: "No shots selected",
      openUploaderModal: false,
      shots: [],
      editMode: false,
    };

    this.configTimer = null;
    this.equipmentsTypeMap = {};
    this.movementsMap = {};

    this.onVideoFileChange = this.onVideoFileChange.bind(this);
    this.inputFields = [
      {
        name: "name",
        placeholder: "Name*",
        colSpan: 6,
        type: "text",
      },
      {
        name: "level",
        placeholder: "Level",
        type: "number",
        showLabel: true,
        defaultValue: 1,
      },
    ];

    if (!this.props.request) {
      this.inputFields.push({
        name: "groupId",
        placeholder: "Group ID",
        showLabel: true,
        type: "text",
      });
    }

    this.autoSuggestFields = [
      {
        name: "movement",
        placeholder: "Movement",
      },
    ];

    this.addExerciseConfigFields();

    this["onChange_description"] = this.onChange.bind(this, "description");

    for (let field of this.inputFields) {
      if (field.type === "text") {
        this.state[field.name] = "";
      } else {
        this.state[field.name] = field.defaultValue || 0;
      }

      this["onChange_" + field.name] = this.onChange.bind(this, field.name);
    }

    for (let field of this.autoSuggestFields) {
      this.state[field.name] = "";
      this["onChange_" + field.name] = this.onChangeSelect.bind(
        this,
        field.name
      );
    }

    let resistanceOptions = [];
    resistanceOptions.push({
      label: "Ex. Light",
      value: RESISTANCE_LEVELS.EXTRA_LIGHT,
    });
    resistanceOptions.push({
      label: "Light",
      value: RESISTANCE_LEVELS.LIGHT,
    });
    resistanceOptions.push({
      label: "Medium",
      value: RESISTANCE_LEVELS.MEDIUM,
    });
    resistanceOptions.push({
      label: "Heavy",
      value: RESISTANCE_LEVELS.HEAVY,
    });
    resistanceOptions.push({
      label: "Ex. Heavy",
      value: RESISTANCE_LEVELS.EXTRA_HEAVY,
    });
    this.state.resistanceOptions = resistanceOptions;

    this.state = Object.assign({}, this.state, this.mapPropsToState(props));

    this.onClickSave = this.onClickSave.bind(this);
  }

  setState(data, callback) {
    if (!this._isMounted) {
      return;
    }
    super.setState(data, callback);
  }

  onChange(key, event, value) {
    let update = {};
    let updateValue = event.target.value;
    if (!updateValue && updateValue !== 0) {
      updateValue = value ? value.newValue : "";
    }

    let node = event.target;
    node && window.NotificationUtils.hideErrorFromForm(node);

    update[key] = updateValue;
    this.setState(update);
  }

  onChangeSelect(key, selected) {
    let update = {};
    let node = document.querySelector(`#${key}`);
    node && window.NotificationUtils.hideErrorFromForm(node);
    update[key] = selected;
    this.setState(update);
  }

  onVideoFileChange(event) {
    let onComplete = (downloadURL) => {
      if (!downloadURL) {
        console.error("onComplete called without URL");
        window.NotificationUtils.showError(
          "Something went wrong, support team is looking into it"
        );
        return;
      }
      this.setState({
        videoUploadMessage: "Uploaded",
        videoUploaded: true,
        videoURL: downloadURL,
      });
    };

    let onError = () => {
      this.setState({
        posterURL: this.props.data ? this.props.data.posterURL : "",
      });
    };

    let onSelect = (name) => {
      this.setState({
        videoSelected: true,
        videoUploaded: false,
        videoUploadMessage: "Selected " + name,
      });
    };

    this.onFileChange(
      event,
      "videoUploadMessage",
      onSelect,
      onComplete,
      onError
    );
    window.$("#videoFilePicker").value = "";
  }

  onPosterFileChange = (event) => {
    let onComplete = (downloadURL) => {
      if (!downloadURL) {
        console.error("onComplete called without URL");
        window.NotificationUtils.showError(
          "Something went wrong, support team is looking into it"
        );
        return;
      }
      this.setState({
        posterUploadMessage: "Uploaded",
        posterUploaded: true,
        posterURL: downloadURL,
      });
    };

    let onError = () => {
      this.setState({
        posterURL: this.props.data ? this.props.data.posterURL : "",
      });
    };

    let onSelect = (name) => {
      this.setState({
        posterSelected: true,
        posterUploaded: false,
        posterUploadMessage: "Selected " + name,
      });
    };

    this.onFileChange(
      event,
      "posterUploadMessage",
      onSelect,
      onComplete,
      onError
    );
    window.$("#posterFilePicker").value = "";
  };

  onFileChange = (event, progressKey, onSelect, onComplete, onError) => {
    var selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    } else {
      let name = selectedFile.name;
      onSelect(name);

      this.uploadFile(selectedFile, progressKey, onComplete, onError);
    }
  };

  uploadFile(file, messageKey, onComplete, onError) {
    if (this[`uploadTask${messageKey}`]) {
      this[`uploadTask${messageKey}`].cancel();
    }

    let ref = COLLECTIONS.EXERCISES;
    if (this.props.storageRef) {
      ref = this.props.storageRef;
    }

    let config = {
      data: file,
      storageRefPath: ref + "/" + new Date().getTime() + "_" + file.name,
      onProgress: (progress) => {
        if (progress < 100) {
          let update = {};
          update[messageKey] = `Uploading ${progress.toFixed(0)}%`;
          this.setState(update);
        }
      },
      onError: (error) => {
        if (error.code === "storage/canceled") {
          return;
        }
        console.error(error);
        let update = {};
        update[messageKey] = `Uploading Failed`;
        this.setState(update);
        if (onError) {
          onError(error);
        }
      },
      onComplete: onComplete,
    };
    this[`uploadTask${messageKey}`] =
      window.FortisForma.database.startUploadFileTask(config);
  }

  componentWillReceiveProps(props) {
    let update = this.mapPropsToState(props);
    this.setState(update);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.configTimer);
  }

  mapPropsToState(props) {
    let update = {};
    update.level = props.data && (props.data.level || 1);
    update.movement = props.data && this.toSelectValue(props.data.movement);
    update.name = props.data && (props.data.name || "");
    update.groupId = props.data && (props.data.groupId || "");
    update.description = props.data && (props.data.description || "");
    update.alternateNames = props.data && (props.data.alternateNames || []);

    update.videoURL = props.data && (props.data.videoURL || "");
    if (update.videoURL) {
      update.videoUploaded = true;
      update.videoUploadMessage = "Uploaded";
    }

    update.posterURL = props.data && (props.data.posterURL || "");
    if (update.posterURL) {
      update.posterUploaded = true;
      update.posterUploadMessage = "Uploaded";
    }

    if (
      props.data.posterAsShot === true ||
      props.data.posterAsShot === null ||
      typeof props.data.posterAsShot === "undefined" ||
      props.data.posterAsShot === "undefined"
    ) {
      update.posterAsShot = true;
    } else {
      update.posterAsShot = false;
    }

    let config = props.data && props.data.config;
    if (config) {
      update.resistance = this.toSelectValue(config.resistance);
      update.sets = config.sets;
      update.reps = config.reps;
      update.time = config.time;
      update.weight = config.weight;
    }

    if (props.data.shots) {
      update.shots = props.data.shots;
      update.shotsUploadMessage = `${props.data.shots.length} shots selected`;
    }

    return update;
  }

  async componentDidMount() {
    this._isMounted = true;
    const nameMapper = (item) => {
      return {
        value: item.name,
        label: item.name,
      };
    };
    this.fetch(COLLECTIONS.MUSCLES, nameMapper, "muscles");
    try {
      let results = await this.fetch(
        COLLECTIONS.EQUIPMENTS,
        nameMapper,
        "equipments"
      );
      this.equipmentsTypeMap = {};
      results.map((item) => {
        this.equipmentsTypeMap[item.name] = item.type;
        return null;
      });
    } catch (e) {
      //TODO: handle error
    }
    let movements = await this.fetch(
      COLLECTIONS.MOVEMENTS,
      nameMapper,
      "movements"
    );
    this.movementsMap = {};
    movements.map((item) => {
      this.movementsMap[item.name] = item;
      return null;
    });
    this.fetch(COLLECTIONS.FUNCTIONS, nameMapper, "functions");
    this.getConfigFields("muscleGroups", "Muscle");
    this.getConfigFields("equipmentCategories", "Equipment");
    this.getConfigFields("functionCategories", "Function");
    this.autofillFilters();
  }

  autofillFilters = () => {
    let filters = this.props.newExerciseFilters;
    if (this.props.data) {
      return true;
    }

    if (filters && filters.query) {
      this.setState({
        name: filters.query,
      });
    }
    if (filters && filters.equipment) {
      this.setState({
        primaryEquipment: {
          label: filters.equipment,
          value: filters.equipment,
        },
      });
    }
    if (filters && filters.functionItem) {
      this.setState({
        primaryFunction: {
          label: filters.functionItem,
          value: filters.functionItem,
        },
      });
    }
    if (filters && filters.muscle) {
      this.setState({
        primaryMuscle: {
          label: filters.muscle,
          value: filters.muscle,
        },
      });
    }
  };

  async getConfigFields(localStorageKey, fieldType) {
    this.loadingInputFields = true;
    try {
      let results = window.localStorage.getItem(localStorageKey);
      results = JSON.parse(results);
      if (!results) {
        await window.cacheCategories();
        throw new Error("Could not setup data properly");
      }
      this.onLoadInputFields(results, fieldType);
      this.loadingInputFields = false;
    } catch (e) {
      console.error(e);
      this.configTimer = setTimeout(() => {
        this.getConfigFields(localStorageKey, fieldType);
      }, 1000);
    }
  }

  onLoadInputFields(results, type) {
    if (results.length && results[0].order) {
      results = results.sort(function (a, b) {
        return a.order - b.order;
      });
    }

    let mapped = results.map((r) => {
      return { value: r.name };
    });

    var i;
    var groups = [];

    if (type === "Muscle") {
      groups.push({
        type: "label",
        name: "Muscles",
      });
      this.setState({ muscleGroups: mapped });
    } else if (type === "Function") {
      groups.push({
        type: "label",
        name: "Functions",
      });
      this.setState({ functionCategories: mapped });
    } else if (type === "Equipment") {
      this.setState({ equipmentCategories: mapped });
      groups.push({
        type: "label",
        name: "Equipments",
      });
    }

    for (i = 0; i < results.length; i++) {
      var newObj = {};
      let name = results[i].name;
      newObj.name = name.toLowerCase() + type;
      newObj.placeholder = results[i].name + " " + type;
      groups.push(newObj);
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state[newObj.name] = "";
      if (type === "Muscle") {
        if (this.props.data.muscleGroups) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state[newObj.name] = this.toSelectValue(
            this.props.data.muscleGroups[name]
          );
        }
      } else if (type === "Function") {
        if (this.props.data.functionCategories) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state[newObj.name] = this.toSelectValue(
            this.props.data.functionCategories[name]
          );
        }
      } else if (type === "Equipment") {
        if (this.props.data.equipmentCategories) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state[newObj.name] = this.toSelectValue(
            this.props.data.equipmentCategories[name]
          );
        }
      }
      this["onChange_" + newObj.name] = this.onChangeSelect.bind(
        this,
        newObj.name
      );
    }
    this.autoSuggestFields = this.autoSuggestFields.concat(groups);

    if (this._isMounted) {
      this.forceUpdate();
    }
  }

  async addExerciseConfigFields() {
    let inputFields = [];
    inputFields.push({
      name: "sets",
      placeholder: "Sets",
      showLabel: true,
      type: "text",
      ignoreSave: true,
    });
    inputFields.push({
      name: "reps",
      placeholder: "Reps",
      showLabel: true,
      type: "text",
      ignoreSave: true,
    });
    inputFields.push({
      name: "weight",
      placeholder: "Weight",
      showLabel: true,
      type: "text",
      ignoreSave: true,
    });
    inputFields.push({
      name: "time",
      placeholder: "Time",
      showLabel: true,
      ignoreSave: true,
      type: "text",
    });
    let dropdownFields = [];

    dropdownFields.push({
      name: "resistance",
      placeholder: "Resistance",
      colSpan: 3,
    });

    this.inputFields = this.inputFields.concat(inputFields);
    this.autoSuggestFields = dropdownFields.concat(this.autoSuggestFields);
    this._isMounted && this.forceUpdate();
  }

  getTrainerFilters = () => {
    let filters = bodybuilder();
    if (this.props.user.role === ROLES.TRAINER) {
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "trainerId", this.props.user.id)
          .orFilter("term", "trainerId", "admin")
      );
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", this.props.user.id)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
        );
      }
    } else if (this.props.user.enterpriseId) {
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "enterpriseId", this.props.user.enterpriseId)
          .orFilter("term", "trainerId", "admin")
          .orFilter("term", "trainerId", this.props.user.id)
      );
    } else {
      filters.andFilter("bool", (q) =>
        q.orFilter("term", "trainerId", "admin")
      );
    }
    return filters.build();
  };

  async fetch(collectionName, mapper, fieldName) {
    try {
      let fetcher;
      let filters = this.getTrainerFilters();
      switch (collectionName) {
        case COLLECTIONS.MUSCLES:
          fetcher = Factory.muscleFetcher();
          break;
        case COLLECTIONS.EQUIPMENTS:
          fetcher = Factory.equipmentFetcher();
          break;
        case COLLECTIONS.MOVEMENTS:
          fetcher = Factory.movementFetcher();
          break;
        case COLLECTIONS.FUNCTIONS:
          fetcher = Factory.functionFetcher();
          break;
        default:
          throw new Error("Factory method does not exist");
      }
      let data = window.FortisForma.Cache["editor" + collectionName];
      let original;

      const onDone = (_data) => {
        let data = _data.map(mapper);
        data = window._.uniq(data);
        let update = {};
        update[fieldName] = data;
        this.setState(update);
      };

      const updateCache = async () => {
        if (
          collectionName === COLLECTIONS.MOVEMENTS ||
          collectionName === COLLECTIONS.FUNCTIONS
        ) {
          data = await fetcher.fetch(filters);
        } else {
          data = await fetcher.fetch();
        }
        window.FortisForma.Cache["editor" + collectionName] = data;
      };
      if (data) {
        original = data;
        onDone(data);
        // UGLY code
        updateCache();
        return original;
      }
      if (
        collectionName === COLLECTIONS.MOVEMENTS ||
        collectionName === COLLECTIONS.FUNCTIONS
      ) {
        data = await fetcher.fetch(filters);
      } else {
        data = await fetcher.fetch();
      }

      original = data;
      window.FortisForma.Cache["editor" + collectionName] = data;
      onDone(data);
      return original;
    } catch (e) {
      throw e;
    }
  }

  onClickSave() {
    let exercise = this.prepareExercise();
    exercise.id = this.props.data.id || hash(exercise);
    if (this.checkErrors(exercise)) {
      return;
    }
    this.props.onClickSave(exercise);
  }

  prepareExercise() {
    let exercise = Object.assign({}, this.props.data);
    delete exercise.sort;
    delete exercise._score;
    for (let field of this.inputFields) {
      if (field.ignoreSave) {
        continue;
      }
      exercise[field.name] = this.state[field.name];
    }

    exercise.movement = this.state.movement ? this.state.movement.value : "";
    try {
      exercise.movementCategory =
        this.movementsMap[exercise.movement] &&
        this.movementsMap[exercise.movement].movementCategory;
      exercise.movementId = this.movementsMap[exercise.movement].movementId;
    } catch (e) {}

    exercise.muscleGroups = {};
    exercise.functionCategories = {};
    exercise.equipmentCategories = {};
    exercise.equipmentTypes = {};

    for (let muscleGroup of this.state.muscleGroups) {
      let key =
        this.state[muscleGroup.value] !== null &&
        this.state[muscleGroup.value.toLowerCase() + "Muscle"];
      exercise.muscleGroups[muscleGroup.value] = key ? key.value : "";
    }
    for (let fnc of this.state.functionCategories) {
      let key =
        this.state[fnc.value] !== null &&
        this.state[fnc.value.toLowerCase() + "Function"];
      exercise.functionCategories[fnc.value] = key ? key.value : "";
    }
    for (let equipmentCategory of this.state.equipmentCategories) {
      let key =
        this.state[equipmentCategory.value] !== null &&
        this.state[equipmentCategory.value.toLowerCase() + "Equipment"];
      let value = key ? key.value : "";
      exercise.equipmentCategories[equipmentCategory.value] = value;
      try {
        exercise.equipmentTypes[equipmentCategory.value] =
          this.equipmentsTypeMap[value] || "";
      } catch (e) {
        exercise.equipmentTypes = null;
      }
    }

    exercise.description = this.state.description || "";
    exercise.alternateNames = this.state.alternateNames || [];
    exercise.videoURL = this.state.videoURL || "";
    exercise.posterURL = this.state.posterURL || "";
    exercise.hidden = this.state.hidden || false;
    exercise.posterAsShot = this.state.posterAsShot;

    exercise.config = {
      sets: this.state.sets ? Number(this.state.sets) : "",
      resistance: this.state.resistance ? this.state.resistance.value : "",
      reps: this.state.reps ? Number(this.state.reps) : "",
      weight: this.state.weight ? Number(this.state.weight) : "",
      time: this.state.time ? Number(this.state.time) : "",
    };
    let shots = [];
    if (this.state.shots.length) {
      for (var i = 0; i < this.state.shots.length; i++) {
        shots.push({
          url: this.state.shots[i].url,
          sequence: i,
        });
      }
    }
    exercise.shots = shots;
    return exercise;
  }

  handleAddChip = (chip) => {
    var names = Object.assign([], this.state.alternateNames);
    for (var name in names) {
      if (name === chip) {
        window.NotificationUtils.showError("Name already exists!");
        return;
      }
    }
    names.push(chip);
    this.setState({
      alternateNames: names,
    });
  };

  handleDeleteChip = (chip, i) => {
    var names = Object.assign([], this.state.alternateNames);
    var filtered = names.filter(function (value, index) {
      return index !== i;
    });
    this.setState({
      alternateNames: filtered,
    });
  };

  onClickDelete = () => {
    window.customConfirm(
      "Are you sure you want to remove " + this.props.data.name + " ?",
      async () => {
        try {
          await window.FortisForma.database.deleteDocument(
            COLLECTIONS.EXERCISES,
            this.props.data.id
          );
          this.props.onDelete && this.props.onDelete(this.props.data.id);
        } catch (e) {
          console.error(e);
        }
      }
    );
  };

  checkErrors(exercise, hide) {
    let errorNodeId;
    let errorMessage;

    if (!exercise.name) {
      errorNodeId = "name";
      errorMessage = "Name is required";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          false,
          true
        );

      if (this.props.request) {
        return true;
      }
    } else {
      if (this.props.request) {
        return false;
      }
    }

    if (!exercise.movement) {
      errorNodeId = "movement";
      let errorMessage = `Movement "${exercise.movement}" is missing from database`;
      if (!exercise.movement) {
        errorMessage = "Movement is required";
      }
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          false,
          true
        );
    }

    if (!exercise.level) {
      errorNodeId = "level";
      errorMessage = "Level is required";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          false,
          true
        );
    }

    if (!exercise.movementCategory) {
      errorNodeId = "movement";
      errorMessage = "Movement Category is missing";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          false,
          true
        );
    }

    if (!exercise.movementId) {
      errorNodeId = "movement";
      errorMessage = "Movement ID is missing";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          false,
          true
        );
    }

    if (
      this.state.videoSelected &&
      (!this.state.videoUploaded ||
        (this.state.videoURL && this.state.videoURL.includes("blob")))
    ) {
      if (this.state.videoURL.includes("blob")) {
        console.error("Blobs are getting uploaded");
      }
      errorNodeId = "videoPickerButton";
      errorMessage = "Please wait for upload to finish";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          undefined,
          true
        );
    }

    if (
      this.state.posterSelected &&
      (!this.state.posterUploaded ||
        (this.state.posterURL && this.state.posterURL.includes("blob")))
    ) {
      if (this.state.posterURL.includes("blob")) {
        console.error("Blobs are getting uploaded");
      }
      errorNodeId = "posterPickerButton";
      errorMessage = exercise.posterURL
        ? "Please wait for upload to finish"
        : "Poster is required";
      let node = document.querySelector("#" + errorNodeId);
      !hide &&
        window.NotificationUtils.showErrorInForm(
          node,
          errorNodeId,
          errorMessage,
          undefined,
          true
        );
    }

    if (errorNodeId) {
      return true;
    }

    return false;
  }

  handlehidden = () => {
    this.setState({
      hidden: !this.state.hidden,
    });
  };

  onCloseFileUploader = (attachments) => {
    this.setState({
      shots: attachments,
      openUploaderModal: false,
      editMode: false,
      shotsUploadMessage: attachments.length
        ? `${attachments.length} shots selected`
        : "No shots selected",
    });
  };

  onClickSelectShots = () => {
    this.setState({ openUploaderModal: true });
  };

  handlePosterAsShot = (e) => {
    this.setState({ posterAsShot: !this.state.posterAsShot });
  };

  renderhiddenBox = () => {
    return (
      <label style={{ margin: 0 }}>
        {" "}
        Hide Exercise
        <Toggle
          checked={this.state.hidden}
          icons={false}
          onChange={this.handlehidden}
        />
      </label>
    );
  };

  onChangeFiles = (attachments) => {
    this.setState({
      shots: attachments,
      openUploaderModal: false,
      editMode: false,
      shotsUploadMessage: attachments.length
        ? `${attachments.length} shots selected`
        : "No shots selected",
    });
  };

  renderFileUploader = () => {
    if (this.state.openUploaderModal) {
      return (
        <ImageUploaderModal
          onClose={this.onCloseFileUploader}
          onChange={this.onChangeFiles}
          selectedFiles={
            (this.state.shots &&
              this.state.shots.length > 0 &&
              this.state.shots) ||
            []
          }
          pathToStore={"exercises/"}
          editMode={this.state.editMode}
          extensions={["png", "jpg", "jpeg"]}
          isDraggable={true}
        />
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          {this.inputFields.map((field) => {
            return (
              <Col lg={field.colSpan ? field.colSpan : "3"} key={field.name}>
                <FormGroup>
                  {field.showLabel && this.state[field.name] ? (
                    <Label className="innerSpan">{field.placeholder}</Label>
                  ) : null}
                  <Input
                    className={field.showLabel ? "innerSpanInput" : ""}
                    type={field.type}
                    name={field.name}
                    id={field.name}
                    placeholder={field.placeholder}
                    value={this.state[field.name]}
                    min="1"
                    onChange={this["onChange_" + field.name]}
                  />
                </FormGroup>
              </Col>
            );
          })}

          {this.autoSuggestFields.map((field) => {
            if (field.type === "label") {
              return (
                <Col lg="12" mg="12" key={field.name}>
                  <Label>{field.name}</Label>
                </Col>
              );
            }

            let source =
              field.name === "movement" ? this.state.movements : null;

            if (!source) {
              source = field.name.includes("Equipment")
                ? this.state.equipments
                : null;
            }

            if (!source) {
              source = field.name.includes("Muscle")
                ? this.state.muscles
                : null;
            }

            if (!source) {
              source = field.name.includes("Function")
                ? this.state.functions
                : null;
            }

            if (!source) {
              source = field.name.includes("resistance")
                ? this.state.resistanceOptions
                : null;
            }

            return (
              <Col
                lg={field.colSpan ? field.colSpan : "4"}
                key={field.name}
                style={{ paddingBottom: 8 }}
              >
                <FortisFormaSelect
                  placeholder={field.placeholder}
                  id={field.name}
                  inputId={field.name}
                  value={this.state[field.name]}
                  classNamePrefix="fortisFormaDropDownSelect"
                  onChange={this["onChange_" + field.name]}
                  options={source}
                />
              </Col>
            );
          })}

          {!this.props.request ? <Col lg="6"></Col> : null}
          <Row className="descriptionRow">
            <Col lg="12">
              <FormGroup style={{ display: "flex", flexDirection: "column" }}>
                <Label for="alternateNames" style={{ margin: 0 }}>
                  Alternate Names
                </Label>
                <ChipInput
                  id="alternateNames"
                  placeholder="Press `Enter` to add alternate name"
                  disableUnderline={true}
                  value={this.state.alternateNames}
                  onAdd={(chip) => this.handleAddChip(chip)}
                  onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="descriptionRow">
            <Col lg="12">
              <FormGroup>
                <Label for="descriptionText" style={{ margin: 0 }}>
                  Description
                </Label>
                <Input
                  type="text"
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  name="Description"
                  id="descriptionText"
                  value={this.state.description}
                  onChange={this.onChange_description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="exerciseFilterButtonsContainer">
            <Col lg="4">
              <div
                id="videoUploadMessage"
                style={{ marginTop: 8, fontSize: 11, paddingLeft: 14 }}
              >
                {this.state.videoUploadMessage}
              </div>
              <div style={{ position: "relative", paddingLeft: 14 }}>
                <Button id="videoPickerButton">Select Video</Button>
                <input
                  id="videoFilePicker"
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    width: 170,
                  }}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={this.onVideoFileChange}
                ></input>
              </div>
            </Col>

            <Col lg="4" id="posterUploadContainer">
              <div
                id="posterUploadMessage"
                style={{ marginTop: 8, fontSize: 11 }}
              >
                {this.state.posterUploadMessage}
              </div>
              <div style={{ position: "relative" }}>
                <Button id="posterPickerButton">Select Poster</Button>
                <input
                  id="posterFilePicker"
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    width: 170,
                  }}
                  accept="image/*"
                  onChange={this.onPosterFileChange}
                ></input>
              </div>
            </Col>

            <Col
              lg="4"
              id="posterUploadContainer"
              style={{ marginTop: this.state.posterUploaded ? "52px" : "10px" }}
            >
              <div id="posterUploadMessage" style={{ fontSize: 11 }}>
                {this.state.shotsUploadMessage}
              </div>
              <div style={{ position: "relative" }}>
                <Button
                  id="posterPickerButton"
                  onClick={this.onClickSelectShots}
                >
                  Select Shots
                </Button>
              </div>

              {this.state.posterUploaded && (
                <div style={{ fontSize: 11, marginLeft: "-8px" }}>
                  <label>
                    <Checkbox
                      id="posterAsShot"
                      color={"primary"}
                      checked={this.state.posterAsShot}
                      onChange={this.handlePosterAsShot}
                    />
                    <span> Use poster as the first Shot</span>
                  </label>
                </div>
              )}
            </Col>
          </Row>

          <div className="modal-footer" style={{ width: "100%" }}>
            <Button
              className="btn btn-outline-secondary"
              onClick={this.props.onClickCancel}
            >
              Cancel
            </Button>
            {this.props.data.id ? (
              <div className="exerciseDeleteIconContainer">
                <img
                  alt="Delete"
                  onClick={this.onClickDelete}
                  src={Delete}
                  className="exerciseDeleteIcon"
                />
              </div>
            ) : null}
            {!this.props.request ? this.renderhiddenBox() : null}
            <Button color="primary" onClick={this.onClickSave}>
              Save
            </Button>
          </div>
        </Row>
        {this.renderFileUploader()}
      </React.Fragment>
    );
  }
}
