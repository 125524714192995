import bodybuilder from "bodybuilder";
import React from "react";
import { Button, Col, Form, Row } from "reactstrap";
import SearchInput from "./searchInput";

export default class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      searchButtonEnabled: true,
    };

    this.onSetFilters = this.onSetFilters.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.fields = ["query"];
    for (let field of this.fields) {
      this["onChange_" + field] = this.onChange.bind(this, field);
    }

    this.debouncedAfterSelect = window._.debounce(
      this.afterSelect.bind(this),
      500
    );
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update, () => {
      this.props.inclusive && this.debouncedAfterSelect();
    });
  }

  onSetFilters(event) {
    if (event) {
      event.nativeEvent.stopPropagation();
    }
    let { filters, didApply, searchQuery } = this.prepareFilters();

    this.props.onSetFilters(filters, didApply, searchQuery);
  }

  prepareFilters() {
    let filters = bodybuilder();

    let query = this.state.query || "";

    let didApply = false;
    if (this.state.query) {
      didApply = true;
      filters.query("query_string", {
        query: "*" + query + "*",
        fields: ["name", "email"],
      });
    }

    if (this.props.filters) {
      for (let filter in this.props.filters) {
        filters.filter("term", filter, this.props.filters[filter]);
      }
    }

    if (didApply) {
      return {
        filters: filters.build(),
        didApply: true,
        searchQuery: query,
      };
    }

    return {
      filters: null,
      didApply: false,
      searchQuery: "",
    };
  }

  onCancel(event) {
    event.nativeEvent.stopPropagation();
    this.props.onCancel();
  }

  reset = () => {
    this.setState(
      {
        query: "",
        searchButtonEnabled: true,
      },
      this.afterSelect.bind(this)
    );
  };

  afterSelect = () => {
    if (this.props.inclusive) {
      this.onSetFilters();
    }
  };

  render() {
    return (
      <Row id="clientFilters">
        <Col lg="12">
          <Form>
            <Row>
              <Col sm="12" lg="4" key={"Search"}>
                <SearchInput
                  inputId={"searchClients"}
                  placeholder={this.props.placeholder || "Search Clients"}
                  value={this.state.query}
                  onChange={this["onChange_query"]}
                  onClickClear={this.reset}
                ></SearchInput>
              </Col>
            </Row>
          </Form>

          {this.props.inclusive ? null : (
            <div className="center" style={{ marginTop: 40 }}>
              <Button
                className="btn btn-outline-secondary cancelButton"
                onClick={this.onCancel}
              >
                Cancel
              </Button>

              <Button color="primary" onClick={this.onSetFilters}>
                Search
              </Button>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
