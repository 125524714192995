import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames';

export default class ProgramTemplateCard extends Component {

    onRemove = (event) => {
        this.props.onRemove(this.props.program, event)
    }

    onCopy = (event) => {
        this.props.onCopy(this.props.program, event)
    }

    onEdit = (event) => {
        this.props.onEdit(this.props.program, event)
    }

    render() {

        var rowClass = classNames({
            'selectedCard': this.props.selected,
            'clientSMCard': !this.props.selected,
            'centerAlignJustify': true
        })

        return (
            <React.Fragment>

                <div key={this.props.innerKey} style={{ display: "flex", justifyContent: "space-between", }} className={rowClass} onClick={() => this.props.onProgramSelect(this.props.program)}>
                    <div className="programName">{this.props.program.name || this.props.program.id}</div>

                    <div>
                        <ReactTooltip />
                        <i data-tip="Copy this program" className="icon material-icons programTemplateActionIcon" onClick={this.onCopy}>file_copy</i>
                        <i data-tip="Edit this program" className="icon material-icons programTemplateActionIcon" onClick={this.onEdit}>edit</i>
                        <i data-tip="Delete this program" className="icon material-icons programTemplateActionIcon" onClick={this.onRemove}>delete</i>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
