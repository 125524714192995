import React from "react";
import Factory from "../../modules/http/dataFetcher";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import CustomToggle from "../../components/customToggle/customToggle";
import AddMemberModal from "../../components/addMemberModal/addMemberModal";
import { COLLECTION_TYPES, DB_KEYS, ROLES } from "../../constants";
import { isMobileOnly } from "react-device-detect";
import { Button } from "reactstrap";
import { Dialog, Typography } from "@material-ui/core";
import StaffList from "./staffList";
import bodybuilder from "bodybuilder";

const LOADING_CHANGE_DURATION = 5000;

const TAB_OPTIONS = {
  REGISTER: 0,
  PENDING: 1,
};

const REMOVING_MESSAGES = ["Removing staff member"];
export default class StaffEditor extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.usersFetcher = Factory.usersFetcher();
    this.invitesFetcher = Factory.staffInvitesFetcher();
    this.state = {
      lastFetched: null,
      members: [],
      showPending: false,
      selectedTab: TAB_OPTIONS.REGISTER,
      filters: null,
      openDialog: false,
      selectedMember: {},
      addMemberLoading: false,
    };
    this.listRef = null;
    this.pageSize = 20;
    this.fetchMembers = this.fetchMembers.bind(this);
    this.addNewMember = this.addNewMember.bind(this);
    this.onClickSave = this.onClickSave.bind(this);

    this.selectRegistered = this.selectTab.bind(this, TAB_OPTIONS.REGISTER);
    this.selectPending = this.selectTab.bind(this, TAB_OPTIONS.PENDING);

    this.onSelect = this.onSelect.bind(this);
  }

  prepareQueryFilters = (key, value) => {
    let filters = bodybuilder();
    filters.filter("term", key, value);
    filters.notFilter("term", DB_KEYS.ROLE, ROLES.CLIENT);
    filters = filters.build();
    return filters;
  };

  async fetchMembers(clear) {
    if (clear) {
      this.usersFetcher.clearResults();
      this.invitesFetcher.clearResults();
    }

    this.usersFetcher.abort();
    this.invitesFetcher.abort();

    this.loading = true;
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    let query = this.state.filters || {};

    try {
      let results;
      if (this.state.selectedTab === TAB_OPTIONS.REGISTER) {
        if (!this.state.filters) {
          query = this.prepareQueryFilters(
            DB_KEYS.ENTERPRISE_ID,
            this.props.user.enterpriseId
          );
        }
        results = await this.usersFetcher.fetch(query);
      } else if (this.state.selectedTab === TAB_OPTIONS.PENDING) {
        results = await this.invitesFetcher.fetch(query);
      } else {
        throw Error("Invalid");
      }

      let members = clear ? results : this.state.members.concat(results);

      members &&
        members.sort((a, b) => {
          let nameA = (a.name || "").toLowerCase();
          let nameB = (b.name || "").toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      // bring own admin user to top of the list
      let n = members.findIndex((member) => member.id === this.props.user.id);
      if (n !== -1 && n !== 0) {
        let adminUser = members.splice(n, 1);
        members.unshift(adminUser[0]);
      }

      let lastFetched = results[results.length - 1] || this.state.lastFetched;
      this.loading = false;

      this.setState({
        lastFetched: lastFetched,
        members,
      });
      return members;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  onSelect(selectedMember) {
    this.setState({
      openDialog: true,
      selectedMember: selectedMember,
    });
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
      selectedMember: {},
      addMemberLoading: false,
    });
  };

  addNewMember() {
    this.setState(
      {
        openDialog: true,
        selectedMember: {},
      },
      () => {
        if (!this.state.showPending) {
          this.selectPending();
        }
      }
    );
  }

  onClickSave = async (memberData, edit = false) => {
    let data = {};
    if (this.state.selectedMember && this.state.selectedMember.id) {
      data = {
        id: this.state.selectedMember.id,
        collectionType: this.getCollectionType(this.state.selectedTab),
        memberData,
      };
    } else {
      data = {
        id: "",
        collectionType: COLLECTION_TYPES.ENTERPRISE_INVITES,
        memberData,
      };
    }
    try {
      await window.FortisForma.database.createStaffInvite(data);
      this.setState(
        {
          selectedMember: {},
          lastFetched: null,
        },
        () => {
          //Add one second break to let elastic search pickup the change
          setTimeout(async () => {
            try {
              await this.fetchMembers(true);
              this.handleClose();
            } catch (e) {
              console.error(e);
              window.NotificationUtils.showError("Error in fetching members");
              this.handleClose();
            }
          }, 1000);
        }
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  getCollectionType = (selectedTab) => {
    switch (selectedTab) {
      case TAB_OPTIONS.REGISTER:
        return COLLECTION_TYPES.USER_DATA;
      case TAB_OPTIONS.PENDING:
        return COLLECTION_TYPES.ENTERPRISE_INVITES;
      default:
        return COLLECTION_TYPES.INVALID;
    }
  };

  removeMember = (member) => {
    window.showLoadingMessages &&
      window.showLoadingMessages(REMOVING_MESSAGES, LOADING_CHANGE_DURATION);
    let data = {
      id: member.id,
      collectionType: this.getCollectionType(this.state.selectedTab),
    };
    if (data.collectionType === COLLECTION_TYPES.INVALID) {
      throw new Error("Invalid Data");
    }
    window.FortisForma.database
      .removeEnterpriseStaffMember(data)
      .then((results) => {
        if (!results) {
          console.error(results.data);
          const message =
            (results.data.message.errorInfo &&
              results.data.message.errorInfo.message) ||
            (results.data.message && results.data.message.message) ||
            "Something went wrong!";
          window.NotificationUtils.showError(message);
        } else {
          this.setState(
            {
              selectedClient: {},
              lastFetched: null,
            },
            () => {
              //Add one second break to let elastic search pickup the change
              setTimeout(async () => {
                try {
                  await this.fetchMembers(true);
                  window.hideLoadingMessages && window.hideLoadingMessages();
                } catch (e) {
                  console.error(e);
                  window.NotificationUtils.showError(
                    "Error in fetching members"
                  );
                  window.hideLoadingMessages && window.hideLoadingMessages();
                }
              }, 1000);
            }
          );
          console.info("Staff member removed", results);
          window.NotificationUtils.showSuccess("Staff member removed");
        }
      })
      .catch((e) => {
        window.hideLoadingMessages && window.hideLoadingMessages();
        console.error(e);
      });
  };

  selectTab(tab) {
    this.usersFetcher.abort();
    this.invitesFetcher.abort();

    this.usersFetcher.clearResults();
    this.invitesFetcher.clearResults();

    this.setState(
      {
        members: [],
        lastFetched: null,
        selectedTab: tab,
      },
      () => {
        this.listRef.reset(true, true);
      }
    );
  }

  changeTab = (value) => {
    if (value === TAB_OPTIONS.REGISTER) {
      return this.selectRegistered();
    }
    return this.selectPending();
  };

  emptyPlaceholder = () => {
    return (
      <div>
        {this.loading
          ? ""
          : "No pending staff member invites, click on the button above to add new member."}
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className="staffEditorRow">
        {this.renderTabs()}
        <Button color="primary" onClick={this.addNewMember}>
          Add New Member
        </Button>
      </div>
    );
  };

  renderTabs = () => {
    return (
      <div>
        <CustomToggle
          selected={this.state.selectedTab}
          onChange={this.changeTab}
        />
      </div>
    );
  };

  renderMemberDetailsDialog = () => {
    return (
      <Dialog
        fullScreen={isMobileOnly ? true : false}
        fullWidth={true}
        open={this.state.openDialog}
        onClose={() => this.handleClose()}
      >
        <AddMemberModal
          data={this.state.selectedMember}
          onClose={() => this.handleClose()}
          onClickSave={this.onClickSave}
          loading={this.state.addMemberLoading}
        />
      </Dialog>
    );
  };

  renderList = () => {
    return (
      <div
        className="staffMemberListTableContainer"
        id="staffMemberListTableContainer"
      >
        <div className="staffListHeaderContainer">
          <div style={{ flex: 0.1 }}></div>
          <Typography
            className="staffListCellEntry staffListHeaderItem staffListNameHeader"
            variant="subtitle1"
            color="inherit"
          >
            Name
          </Typography>
          <Typography
            className="staffListCellEntry staffListHeaderItem staffListEmailHeader"
            variant="subtitle1"
            color="inherit"
          >
            Email
          </Typography>
          <Typography
            className="staffListCellEntry staffListHeaderItem"
            variant="subtitle1"
            color="inherit"
          >
            Role/Designation
          </Typography>
          <Typography
            className="staffListCellEntry staffListHeaderItem staffListActionsHeader"
            align="center"
            variant="subtitle1"
            color="inherit"
          >
            Actions
          </Typography>
        </div>
        <div id="staffListContainer">
          <StaffList
            {...this.props}
            ref={(r) => {
              this.listRef = r;
            }}
            autoSize={true}
            data={this.state.members}
            containerId={"staffListContainer"}
            onEdit={this.onSelect}
            onDelete={this.removeMember}
            loadData={this.fetchMembers}
            pageSize={this.pageSize}
            emptyPlaceholder={this.emptyPlaceholder()}
            rowHeight={80}
            showResendInviteOption={
              this.state.selectedTab === TAB_OPTIONS.PENDING
            }
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderList()}
        {this.renderMemberDetailsDialog()}
      </>
    );
  }
}
