import React from "react";
import SectionedExerciseCard from "./sectionedListExerciseCard";
import { WORKOUT_SECTIONS_NAMES } from "../../../constants";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

export default class WorkoutSection extends React.Component {
  render() {
    let section = this.props.section;
    return (
      <div className="viewWorkoutSection">
        <div className="viewWorkoutSectionHeader">
          <div className="viewWorkoutPSectionName">
            {`${section.workoutType}${
              section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT
                ? ` ${
                    this.props.firstSection.workoutType ===
                    WORKOUT_SECTIONS_NAMES.CIRCUIT
                      ? this.props.listIndex + 1
                      : this.props.listIndex
                  }`
                : ""
            }`}
            {section.repeatCircuit && (
              <div className="circuitDisplay">
                Circuit Count <span>{section.repeatCircuit.count}</span>
              </div>
            )}
            {section.repeatCircuit
              ? section.repeatCircuit.description && (
                  <Tooltip
                    arrow={true}
                    title={section.repeatCircuit.description}
                  >
                    <p className="descBtn">Description</p>
                  </Tooltip>
                )
              : null}
          </div>
        </div>
        <div style={{ paddingLeft: 12, paddingRight: 12, overflow: "hidden" }}>
          {section.exercises &&
            section.exercises.length > 0 &&
            section.exercises.map((exercise, index) => {
              return <SectionedExerciseCard key={index} data={exercise} />;
            })}
        </div>
      </div>
    );
  }
}
