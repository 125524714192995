import {
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { isMobile } from "react-device-detect";
import PDFViewer from "../../components/forms/pdfViewer.js";
import PreviewForm from "../../components/forms/previewFormModal";
import SignatureField from "../../components/signatureField/signatureField";
import AddIcon from "@material-ui/icons/Add";
import DatePicker from "../../components/datePickerWidget/datePickerWidget.js";
import PhoneField from "../../components/phoneFieldWidget/phoneFieldWidget.js";
import Datatable from "../../components/datatable/datatable.js";
import Chip from "@material-ui/core/Chip";
import { FORM_TYPES, ROLES } from "../../constants.js";
import { FORM_TYPE_OPTIONS } from "./forms";
import DeleteIcon from "@material-ui/icons/Delete";
import ExportPDFButton from "../../components/noteAssessments/exportPDFButton.js";
import { handleSaveFormPDF } from "../../utils/formUtils.js";
import FreehandNote from "../../components/freehandNoteWidget/freehandNote.js";
import AddImageWidget from "../../components/addImageWidget/addImageWidget.js";

const FORM_STATUS = {
  COMPLETED: "Completed",
  PENDING: "Pending",
};

const FORM_STATUS_PROPS = {
  PENDING: {
    BACKGROUND: "rgba(255, 0, 0, 0.15)",
    TEXT: "red",
  },
  COMPLETED: {
    BACKGROUND: "rgba(0, 255, 0, 0.15)",
    TEXT: "green",
  },
};

const FORM_TYPES_PROPS = {
  REGISTRATION: {
    label: "Registration",
    backgroundColor: "rgba(255, 0, 0, 0.15)",
    color: "red",
  },
  ASSESSMENTS: {
    label: "Assessment",
    backgroundColor: "rgb(177, 201, 237)",
    color: "blue",
  },
  MISC: {
    label: "Miscellaneous",
    backgroundColor: "rgba(0, 255, 0, 0.15)",
    color: "green",
  },
  GENERAL_INFO: {
    label: "General Info",
    backgroundColor: "rgb(255, 233, 201)",
    color: "orange",
  },
  NOTES: {
    label: "Note",
    backgroundColor: "#f7e2ff",
    color: "#551a8b", //purple
  },
};

const isValidInput = (input) => {
  if (typeof input === "object" && Object.keys(input).length) {
    return true;
  }
  if ((input && typeof input !== "object") || input === 0) {
    return true;
  }
  return false;
};

export const getSubmittedQuestions = (properties, form) => {
  if (!properties) {
    throw new Error("Invalid Params");
  }

  let submittedQuestions = 0;
  for (let key in form) {
    let question = properties[key];
    if (question) {
      const questionRequiresInput = Boolean(
        !question.data && !(question.type === "object")
      );
      const validInput = isValidInput(form[key]);
      if (question.properties && validInput) {
        let sectionSubmittedQuestions = 0;
        sectionSubmittedQuestions = getSubmittedQuestions(
          question.properties,
          form[key]
        );
        submittedQuestions += sectionSubmittedQuestions;
      }

      if (questionRequiresInput && validInput) {
        submittedQuestions++;
      }
    }
  }
  return submittedQuestions;
};

const isNoteAssessmentForm = (data) => {
  if (data && (data.noteId || data.assessmentId)) {
    return true;
  }
  return false;
};

export default class ClientForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: false,
      data: {},
      addClientFormMenu: false,
      addFormMenuAnchorEl: null,
      formTypeSelectionAnchorEl: null,
      text_style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      },
    };
    this.COLUMNS = [
      {
        name: "title",
        label: "Name",
        options: {
          filter: false,
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          filter: false,
          customBodyRender: (data) => (data ? data : "-"),
        },
      },
      {
        name: "totalQuestions",
        label: "Questions",
      },
      {
        name: "step",
        label: "Type",
        options: {
          customBodyRender: (data) => this.renderFormType(data),
          customFilterListOptions: {
            render: (data) => this.renderFilterLabels(data),
          },
          filterOptions: {
            renderValue: (data) => this.renderFormTypeFilterOptions(data),
          },
        },
      },
      {
        name: "filled",
        label: "Status",
        options: {
          customBodyRender: (data) => this.renderStatus(data),
          customFilterListOptions: {
            render: (data) =>
              `Status: ${data ? FORM_STATUS.COMPLETED : FORM_STATUS.PENDING}`,
          },
          filterOptions: {
            renderValue: (data) =>
              data ? FORM_STATUS.COMPLETED : FORM_STATUS.PENDING,
          },
        },
      },
      {
        name: "formData",
        label: "Export",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (data) => this.renderExportIcon(data),
        },
      },
      {
        name: "formData",
        label: "Delete",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (data) =>
            isNoteAssessmentForm(data) ? null : this.renderDeleteIcon(data),
        },
      },
    ];
    this.addFormRef = React.createRef();
  }

  renderExportIcon = (formData) => {
    return (
      <div>
        <ExportPDFButton
          data={formData}
          onExport={() => this.handleExport(formData)}
          disableExportOptions={true}
          toolTipArrow={false}
          toolTipPlacement="bottom"
        />
      </div>
    );
  };

  renderDeleteIcon = (formData) => {
    return (
      <Tooltip title="Delete" placement={"bottom"}>
        <IconButton
          onClick={(e) => this.onClickDelete(formData, e)}
          size="small"
        >
          <DeleteIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  renderFormTypeFilterOptions = (data) => {
    const properties = this.getFormTypeProperties(data);
    return properties.label;
  };

  renderFilterLabels = (data) => {
    const properties = this.getFormTypeProperties(data);
    return `Type: ${properties.label}`;
  };

  renderStatus = (data) => {
    return (
      <Chip
        label={data ? FORM_STATUS.COMPLETED : FORM_STATUS.PENDING}
        size="small"
        style={{
          backgroundColor: data
            ? FORM_STATUS_PROPS.COMPLETED.BACKGROUND
            : FORM_STATUS_PROPS.PENDING.BACKGROUND,
          color: data
            ? FORM_STATUS_PROPS.COMPLETED.TEXT
            : FORM_STATUS_PROPS.PENDING.TEXT,
        }}
      />
    );
  };

  renderFormType = (data) => {
    const properties = this.getFormTypeProperties(data);
    return (
      <Chip
        label={properties.label}
        size="small"
        style={{
          backgroundColor: properties.backgroundColor,
          color: properties.color,
        }}
      />
    );
  };

  handleExport = async (formData) => {
    try {
      const submissionId = this.props.getSubmissionId(formData.id);
      if (submissionId) {
        formData.submissionId = submissionId;
      }
      formData.clientData = {
        name: this.props.clientName,
        email: this.props.clientEmail,
        dob: this.props.clientDOB,
      };
      await handleSaveFormPDF(formData);
    } catch (error) {
      throw error;
    }
  };

  getFormTypeProperties = (data) => {
    switch (data) {
      case FORM_TYPES.REGISTRATION:
        return FORM_TYPES_PROPS.REGISTRATION;
      case FORM_TYPES.ASSESSMENTS:
        return FORM_TYPES_PROPS.ASSESSMENTS;
      case FORM_TYPES.GENERAL_INFO:
        return FORM_TYPES_PROPS.GENERAL_INFO;
      case FORM_TYPES.MISC:
        return FORM_TYPES_PROPS.MISC;
      case FORM_TYPES.NOTES:
        return FORM_TYPES_PROPS.NOTES;
      default:
        return FORM_TYPES_PROPS.REGISTRATION;
    }
  };

  onSubmit = async (
    { formData, submittedQuestions, totalQuestions, locked, edited },
    noteId
  ) => {
    let submissionId = this.state.data.submissionId || "";
    try {
      let data = {
        formData: formData,
        formId: this.state.data.formId,
        clientId: this.props.clientId,
        id: submissionId,
        clientEmail: this.props.clientEmail,
        total: totalQuestions,
      };
      if (locked && noteId) {
        data.locked = true;
        data.noteId = noteId;
      }

      if (edited) {
        data.edited = true;
      }

      data.submitted = submittedQuestions;

      if (this.props.clientLoginPending) {
        data.isInvite = true;
      }
      let results = await window.FortisForma.database.saveFormSubmissionData(
        data
      );
      let formFilled = Boolean(data.total === data.submitted);
      let forms = JSON.parse(JSON.stringify(this.props.formsList));
      forms = forms.filter((f) => f.step === this.state.data.step);
      for (let form of forms) {
        if (form.formId === this.state.data.formId) {
          form.filled = formFilled;
          form.submissionId = results.id;
        }
        delete form.formData;
        delete form.step;
        delete form.description;
        delete form.title;
        delete form.totalQuestions;
        if (form.submissionData) {
          delete form.submissionData;
        }
      }
      let updatedData = {
        forms: forms,
        clientId: this.props.clientId,
        formType: this.state.data.step,
        email: this.props.clientEmail,
      };
      await window.FortisForma.database.updateClientFormsData(updatedData);
      let stateForms = JSON.parse(JSON.stringify(this.props.formsList));
      for (let stateForm of stateForms) {
        if (stateForm.formId === this.state.data.formId) {
          stateForm.filled = formFilled;
          stateForm.submissionId = results.id;

          if (results.locked) {
            stateForm.locked = true;
          }

          if (results.edited) {
            stateForm.edited = true;
          }
        }
      }

      this.props.setFormsList(stateForms);
      this.closePreviewForm();
    } catch (e) {
      throw e;
    }
  };

  onClickPreview = (form) => {
    form.formData.submissionId = form.submissionId || "";
    this.setState({
      data: form,
      previewForm: true,
    });
  };

  closePreviewForm = () => {
    this.setState({
      previewForm: false,
      data: {},
    });
  };

  onClickDelete = async (form, event) => {
    event.stopPropagation();
    let message = `Are you sure you want to delete this form?`;

    window.customConfirm(message, async () => {
      let forms = JSON.parse(JSON.stringify(this.props.formsList));
      forms = forms.filter((f) => f.step === form.step);
      forms = forms.filter((f) => f.formId !== form.id);
      for (let singleForm of forms) {
        delete singleForm.formData;
        delete singleForm.step;
        delete singleForm.description;
        delete singleForm.title;
        delete singleForm.totalQuestions;
        if (singleForm.submissionData) {
          delete singleForm.submissionData;
        }
      }
      let updatedData = {
        forms: forms,
        clientId: this.props.clientId,
        formType: form.step,
        email: this.props.clientEmail,
      };
      try {
        await window.FortisForma.database.updateClientFormsData(updatedData);
        let stateforms = this.props.formsList.filter(
          (f) => f.formId !== form.id
        );
        this.props.setFormsList(stateforms);
        window.NotificationUtils.showSuccess("Form deleted successfully");
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Unable to delete form");
      }
    });
  };

  onClickAddFormMenu = (event) => {
    this.setState({
      addFormMenuAnchorEl: event.currentTarget,
    });
  };

  onCloseAddFormMenu = () => {
    this.setState({
      addFormMenuAnchorEl: null,
    });
  };

  openFormTypeSelection = (event) => {
    this.setState({
      formTypeSelectionAnchorEl:
        this.addFormRef && this.addFormRef.current && this.addFormRef.current,
    });
  };

  onCloseFormTypeSelection = () => {
    this.setState({
      formTypeSelectionAnchorEl: null,
    });
  };
  //Todo :- convert forms so that it can be accessible for pp and ent.

  renderPreviewFormModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closePreviewForm}
        open={this.state.previewForm}
        maxWidth="md"
      >
        <PreviewForm
          closePreviewForm={this.closePreviewForm}
          widgets={{
            signatureField: SignatureField,
            pdfViewer: PDFViewer,
            DateWidget: DatePicker,
            phoneField: PhoneField,
            freehandNote: FreehandNote,
            addImageWidget: AddImageWidget,
          }}
          data={this.state.data.formData || {}}
          onSubmit={
            !isNoteAssessmentForm(this.state.data.formData || {}) &&
            this.onSubmit
          }
          submissionData={this.state.data.submissionData || {}}
        />
      </Dialog>
    );
  };

  renderForms = () => {
    if (this.props.loading) {
      return this.renderLoader();
    }
    // if (this.props.clientLoginPending) {
    //   return (
    //     <>
    //       <h2 style={{ textAlign: "center" }}>
    //         Not available for pending clients. Send invite to client for
    //         registeration on portal.
    //       </h2>
    //     </>
    //   );
    // }
    let sortedFormList = this.sortFormList(this.props.formsList);
    return (
      <Datatable
        data={sortedFormList}
        columns={this.COLUMNS}
        onRowClick={this.onClickPreview}
        customToolbar={this.renderDatatableCustomToolbar}
      />
    );
  };

  renderDatatableCustomToolbar = () => {
    return (
      <div style={{ display: "inline" }}>{this.renderAddFormsButton()}</div>
    );
  };

  disableDropdown = () => {
    if (
      this.props.user.role === ROLES.FACILITY_ADMIN ||
      this.props.user.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.user.enterprise.active) return false;
    } else {
      if (this.props.user.active) return false;
    }

    return true;
  };

  renderAddFormsButton = () => {
    const open = Boolean(this.state.addFormMenuAnchorEl);
    return (
      <>
        <Tooltip title="Add Form" placement={"bottom"}>
          <IconButton
            ref={this.addFormRef}
            onClick={this.onClickAddFormMenu}
            className="addFormIconButton"
            // disabled={this.disableDropdown()}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>

        <Menu
          keepMounted
          open={open}
          anchorEl={this.state.addFormMenuAnchorEl}
          onClose={this.onCloseAddFormMenu}
        >
          <MenuItem
            style={{ fontWeight: "600", color: "black" }}
            onClick={() => {
              this.onCloseAddFormMenu();
              this.props.onClickUseTemplate();
            }}
          >
            Use Template
          </MenuItem>
          <MenuItem
            style={{ fontWeight: "600", color: "black" }}
            onClick={(event) => {
              this.onCloseAddFormMenu();
              this.openFormTypeSelection(event);
            }}
          >
            Add New
          </MenuItem>
        </Menu>
        {this.renderFormTypePopover()}
      </>
    );
  };

  renderFormTypePopover = () => {
    const open = Boolean(this.state.formTypeSelectionAnchorEl);
    return (
      <Popover
        open={open}
        onClose={this.onCloseFormTypeSelection}
        anchorEl={this.state.formTypeSelectionAnchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {this.renderFormTypeOptions()}
      </Popover>
    );
  };

  renderFormTypeOptions = () => {
    return (
      <div className="createFormTypeOptionsContainer">
        <FormLabel>Select form type</FormLabel>
        <RadioGroup
          aria-label="form-type"
          value={this.props.newFormType}
          onChange={(e) => this.props.onChangeNewFormType(e.target.value)}
        >
          {FORM_TYPE_OPTIONS.map((formType, key) => {
            return (
              <span key={key}>
                <FormControlLabel
                  value={formType.value}
                  control={<Radio color="primary" />}
                  label={formType.label}
                />
              </span>
            );
          })}
        </RadioGroup>
        <div className="center">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              this.onCloseFormTypeSelection();
              this.props.onClickAddForm();
            }}
            variant="contained"
            style={{ marginTop: 8 }}
          >
            Create
          </Button>
        </div>
      </div>
    );
  };

  renderContent = () => {
    if (this.props.clientLoginPending) {
      return (
        <div
          className={this.props.loading ? "flexOne" : ""}
          style={this.state.text_style}
        >
          {this.renderForms()}
        </div>
      );
    } else {
      return (
        <div className={this.props.loading ? "flexOne" : ""}>
          {this.renderForms()}
        </div>
      );
    }
  };

  renderLoader = () => {
    return (
      <div className="clientDetailsLoadingContainer">
        <CircularProgress color="primary" />
      </div>
    );
  };

  renderEmptyPlaceHolder = () => {
    return (
      <div className="clientDetailsLoadingContainer">
        <Typography variant="body1" className="textColorGray">
          No forms assigned.
        </Typography>
      </div>
    );
  };

  sortFormList = (list) => {
    return list.sort((form1, form2) => {
      return form2.formData.sequence - form1.formData.sequence;
    });
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderPreviewFormModal()}
      </>
    );
  }
}
