import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { Avatar, Checkbox, Typography, Button } from "@material-ui/core";

export default class ClientDetails extends Component {
  state = {
    verified: this.props.selectedTrainer.verified,
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedTrainer && prevProps.selectedTrainer) {
      if (
        this.props.selectedTrainer.verified !==
        prevProps.selectedTrainer.verified
      ) {
        this.setState({
          verified: this.props.selectedTrainer.verified,
        });
      }
    }
  }

  toggleVerified = (e) => {
    this.setState({
      verified: e.target.checked,
    });
  };

  openLink = (link) => {
    if (!link) {
      return;
    }
    window.open(link, "_blank");
  };

  render() {
    let trainerData = this.props.selectedTrainer;
    let shortCodes = [];
    if (
      trainerData.documents &&
      trainerData.documents.educationAndCertification
    ) {
      for (let edu of trainerData.documents.educationAndCertification) {
        if (edu.shortCode) {
          shortCodes.push(edu.shortCode);
        }
      }
    }
    return (
      <React.Fragment>
        <Card className="clientDetailCard">
          <CardBody style={{ padding: 10 }}>
            <div className="publicTopContent">
              <div className="trainerProfilePicContainer">
                <Avatar
                  alt={this.props.selectedTrainer.name}
                  src={this.props.selectedTrainer.picURL}
                  className="trainerProfilePic"
                />
              </div>
              <div className="publicTrainerContent">
                <span className="publicContent">Name :</span>{" "}
                {this.props.selectedTrainer.name}
                <br />
                <span className="publicContent">Postal Code :</span>{" "}
                {this.props.selectedTrainer.postalCode}
                <br />
                <span className="publicContent">Short Tags :</span>{" "}
                {shortCodes.map((code, index) => {
                  return (
                    <span key={index} style={{ marginRight: 6 }}>
                      {code}
                    </span>
                  );
                })}
                <br />
              </div>
            </div>
            <div className="publicBottomContent">
              <span className="publicContent">Value Proposition</span>{" "}
              {this.props.selectedTrainer.valueProposition || "-"}
              <br />
              <br />
              <span className="publicContent">Bio</span>{" "}
              {this.props.selectedTrainer.bio || "-"}
              <br />
              <br />
              <div className="educationCertificationHeader">
                <span className="publicContent">
                  Education And Certifications
                </span>
                <span className="verificationCheck">
                  {this.state.verified !==
                    this.props.selectedTrainer.verified && (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.props.updateTrainer(
                          this.props.selectedTrainer,
                          this.state.verified
                        )
                      }
                    >
                      Update
                    </Button>
                  )}
                  <Checkbox
                    checked={this.state.verified}
                    onChange={this.toggleVerified}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  Verified
                </span>
              </div>
              <ul>
                {this.props.selectedTrainer.documents &&
                  this.props.selectedTrainer.documents
                    .educationAndCertification &&
                  this.props.selectedTrainer.documents.educationAndCertification.map(
                    (certificate, index) => {
                      return (
                        <li
                          className={
                            certificate.link
                              ? "certificationLinkText"
                              : "certificationText"
                          }
                          key={index}
                          onClick={() => this.openLink(certificate.link)}
                        >
                          {certificate.name}
                        </li>
                      );
                    }
                  )}
              </ul>
              {this.props.selectedTrainer.documents &&
                this.props.selectedTrainer.documents.others && (
                  <>
                    <span className="publicContent">Documents</span>
                    <ul>
                      <React.Fragment>
                        {this.props.selectedTrainer.documents.others
                          .insuranceURL && (
                          <li
                            className="certificationLinkText"
                            onClick={() =>
                              this.openLink(
                                this.props.selectedTrainer.documents.others
                                  .insuranceURL
                              )
                            }
                          >
                            Insurance Certificate
                          </li>
                        )}
                        {this.props.selectedTrainer.documents.others
                          .criminalRecordURL && (
                          <li
                            className="certificationLinkText"
                            onClick={() =>
                              this.openLink(
                                this.props.selectedTrainer.documents.others
                                  .criminalRecordURL
                              )
                            }
                          >
                            Criminal Record Check
                          </li>
                        )}
                      </React.Fragment>
                    </ul>
                  </>
                )}
              <span className="publicContent">Programs</span>
              <ul>
                {this.props.selectedTrainer.programs &&
                  this.props.selectedTrainer.programs.map((program, index) => {
                    return (
                      <li
                        className="programNameText"
                        style={{ marginTop: 8 }}
                        key={index}
                      >
                        <span className="boldFont">{program.name}</span>
                        <Typography variant="body">
                          - {program.description}
                        </Typography>
                        <ol>
                          {program.packages &&
                            program.packages.map((pack, pindex) => {
                              return (
                                <li key={pindex} className="programNameText">
                                  {pack.name}
                                  <span className="boldFont">
                                    {" "}
                                    - $ {pack.price}
                                  </span>
                                  {pack.per && <span>/{pack.per}</span>}
                                  {pack.extras &&
                                    pack.extras.map((ex, exindex) => {
                                      return <span key={exindex}> + {ex}</span>;
                                    })}
                                </li>
                              );
                            })}
                        </ol>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
