import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ProgramAssigned from "../../assets/program_assigned.svg";

export default function ProgramSuccessfullyAssignedModal(props) {
  return (
    <>
      <DialogContent id="programAssignedModalContent">
        <IconButton id="closeProgramAssignedIcon" onClick={props.closeModal}>
          <CloseIcon />
        </IconButton>
        <img
          src={ProgramAssigned}
          alt="Program Assigned"
          id="programAssignedImage"
        />
        <div id="programAssignedContentContainer">
          <Typography className="boldText marginBottom8" variant="h6">
            Program Assigned Successfully
          </Typography>
          <Typography className="mgBottom16" variant="body2">
            Close box to continue editing this client.
          </Typography>
          {props.user.role === "Trainer" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.history.push("/client-editor")}
            >
              Go to Clients Section
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.history.push("/client-management")}
            >
              Go to Clients Section
            </Button>
          )}
        </div>
      </DialogContent>
    </>
  );
}
