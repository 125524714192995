import React from 'react';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

export default class TrainerFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      searchButtonEnabled: true,
    }

    this.onSetFilters = this.onSetFilters.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.fields = ["email", "phone"];
    for (let field of this.fields) {
      this["onChange_" + field] = this.onChange.bind(this, field);
    }

    this.debouncedAfterSelect = window._.debounce(this.afterSelect.bind(this), 500);
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update, () => {
      this.props.inclusive && this.debouncedAfterSelect()
    });
  }

  onSetFilters(event) {
    if (event) {
      event.nativeEvent.stopPropagation();
    }
    let filters = this.preprareFilters();
    this.props.onSetFilters(filters);
  }

  preprareFilters() {
    let filters = [];

    if (this.state.name) {
      filters.push({
        key: "name",
        operator: "==",
        value: this.state.name
      })
    }

    if (this.state.email) {
      filters.push({
        key: "email",
        operator: "==",
        value: this.state.email
      })
    }

    if (this.state.phone) {
      filters.push({
        key: "phone",
        operator: "==",
        value: this.state.phone
      })
    }

    return filters;
  }

  onCancel(event) {
    event.nativeEvent.stopPropagation();
    this.props.onCancel();
  }

  reset = () => {
    this.setState({
      name: "",
      searchButtonEnabled: true,
      email: "",
      phone: ""
    })
  }

  afterSelect = () => {
    if (this.props.inclusive) {
      this.onSetFilters();
    }
  }

  render() {

    return (<Row id="clientFilters">
      <Col lg="12">

        <Form>
          <Row>

            {
              this.fields.map((field) => {
                let formatted = field[0].toUpperCase() + field.substring(1, field.length);

                return (
                  <Col sm="12" lg="4" key={field}>
                    <FormGroup>
                      <Input id={"#" + field}
                        placeholder={formatted}
                        value={this.state[field]}
                        onChange={this["onChange_" + field]}
                      ></Input>
                    </FormGroup>
                  </Col>
                )
              })
            }

          </Row>

        </Form>

        {
          this.props.inclusive ? null : (<div className="center" style={{marginTop: 40}}>
            <Button className="btn btn-outline-secondary cancelButton" onClick={this.onCancel}>Cancel</Button>

            <Button color="primary" onClick={this.onSetFilters}>Search</Button>

          </div>)
        }

      </Col>
    </Row>)
  }


}
