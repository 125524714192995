import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default class Waiting extends Component {
  render() {
    return (
      <div className="waitingPageContainer flexCenter flexColumn">
        <Lottie
          options={{
            ...defaultOptions,
            animationData: this.props.animationData,
          }}
          height={420}
          width={450}
        />
        <Typography id="waitingTitle" variant="h4" align="center">
          Thanks for reaching out!
        </Typography>
        <Typography variant="subtitle1" color="initial">
          Your profile will be live in the next 24 hours once it’s reviewed by
          our review team{" "}
        </Typography>
        <Button
          id="waitingAction"
          hidden={!Boolean(this.props.actionCallback)}
          variant="outlined"
          color="primary"
          onClick={this.props.actionCallback}
        >
          {this.props.actionLabel}
        </Button>
      </div>
    );
  }
}
