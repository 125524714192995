import classNames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Switch } from "@material-ui/core";
export default function ProgramListItem(props) {
  var rowClass = classNames({
    selectedCard: props.selected,
    clientSMCard: !props.selected,
    centerAlignJustify: true,
  });
  return (
    <Row
      className={rowClass}
      onClick={(event) => props.onSelect(props.data, event)}
    >
      <ReactTooltip />
      <Col
        xs="4"
        className="centerAlignJustify decPadding"
        style={{ justifyContent: "start", paddingVertical: 0 }}
      >
        {props.data.name}
      </Col>
      <Col xs="2" className="centerAlignJustify decFontSize">
        ${props.data.price}
      </Col>
      <Col xs="2" className="centerAlignJustify decFontSize">
        <Switch
          value="visible"
          checked={Boolean(props.data.visible)}
          onChange={() => {
            props.onToggleAvailability(props.data, props.index);
          }}
          inputProps={{ "aria-label": "" }}
        />
      </Col>
      <Col lg="4" md="4" xs="12" id="forMobileView">
        <Row>
          <Col xs="6" className="centerAlignJustify">
            <i
              data-tip="Edit this program"
              className="icon material-icons"
              onClick={(event) => {
                event.stopPropagation && event.stopPropagation();
                props.onClickEdit(props.data);
              }}
            >
              edit
            </i>
          </Col>
          <Col xs="6" className="centerAlignJustify">
            <i
              data-tip="Delete this program"
              className="icon material-icons"
              onClick={(event) => {
                event.stopPropagation && event.stopPropagation();
                props.onClickDelete(props.data, props.index);
              }}
            >
              delete
            </i>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
