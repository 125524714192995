// external imports
import React from 'react'
import PropTypes from 'prop-types'
// local imports
import FileInput from '../FileInput'

class FilePicker extends React.Component {
  constructor(props) {
    super(props)

    this._validate = this._validate.bind(this)
  }

  _validate(files) {
    const { onChange } = this.props
    // return native file object
    onChange(files)
  }

  render() {
    const { children, style } = this.props
    const accept = this.props.extensions.map(ext => `.${ext}`).join(',')
    return (
      <FileInput onChange={this._validate} style={style} accept={accept}>
        {children}
      </FileInput>
    )
  }
}

FilePicker.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // max file size in MB
  maxSize: PropTypes.number,
  // file extension
  extensions: PropTypes.array,
  // validate file contents
  validateContent: PropTypes.func,
  style: PropTypes.object
}

FilePicker.defaultProps = {
  maxSize: 2
}

export default FilePicker
