import { Avatar } from "@material-ui/core";
import classNames from "classnames";
import React, { Component } from "react";
import { ROLES } from "../../constants";

export default class ClientCard extends Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.isEnterpriseMember = this.props.isEnterpriseMember;
  }

  onSelect() {
    this.props.onSelect(this.props.data);
    if (this.props.data) {
      const results = window.FortisForma.database.checkUserForms(
        this.props.data.id,
        this.props.data.email
      );
    }
  }

  removeClient = (event) => {
    event.stopPropagation && event.stopPropagation();

    window.customConfirm(
      "Are you sure you want to remove " +
        `${
          this.props.data.email ? this.props.data.email : this.props.data.name
        } ?`,
      () => {
        this.props.onRemove({
          id: this.props.data,
        });
      }
    );
  };

  render() {
    let rowClass = classNames({
      selectedCard: this.props.selected,
      clientSMCardUpdated: !this.props.selected,
      centerAlignJustify: true,
    });
    return (
      <ul className={rowClass} onClick={() => this.onSelect()}>
        <li className="centerAlignJustify decPadding">
          <Avatar
            className="clientCardAvatar"
            alt={this.props.data.name}
            src={this.props.data.picURL}
          />
          <p>{this.props.data.name}</p>
        </li>

        <li className="centerAlignJustify decFontSize emailMultiline">
          <p className="emailMultiline">{this.props.data.email}</p>
        </li>
        <li className="centerAlignJustify decFontSize decPadding">
          <p>{this.props.data.phone}</p>
        </li>
        <li className="centerAlignJustify decFontSize decPadding">
          {(!this.isEnterpriseMember ||
            (this.isEnterpriseMember &&
              (this.props.role === ROLES.ENTERPRISE_ADMIN ||
                this.props.role === ROLES.FACILITY_ADMIN))) && (
            <div>
              <i
                className="icon material-icons pointer"
                alt="Delete"
                onClick={this.removeClient}
              >
                delete
              </i>
            </div>
          )}
        </li>
      </ul>
    );
  }
}
