import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";

const FormExport = () => {
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    exportForm();
  }, []);

  const getURLParams = () => {
    let queryParams = "";
    try {
      let queryIndex = window.location.hash.indexOf("?");
      if (queryIndex >= 0) {
        queryParams = window.location.hash.substring(queryIndex + 1);
      }
    } catch (e) {
      throw e;
    }
    return queryParams;
  };

  const generateFormVersion = async (data) => {
    try {
      const documentName = `formVersion_${window.moment().unix()}`;
      await window.FortisForma.database.generateFormVersion(data, documentName);
      window.NotificationUtils.showSuccess("Downloading PDF");
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      throw e;
    }
  };

  const exportForm = async () => {
    setLoading(true);
    try {
      const data = getURLParams();
      await generateFormVersion(data);
      setSuccess(true);
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
    setLoading(false);
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <div className="fullHeightAlignCenter">
          <CircularProgress color="primary" size={40} />
        </div>
      );
    }
  };

  const renderError = () => {
    if (hasError) {
      return (
        <div className="enterpriseComponentContainer">
          <img
            alt="Logo"
            src={"/assets/img/invalid.svg"}
            className="notFoundImage"
          />
          <Typography
            variant="h4"
            className="mgTop32 boldText textTransformInitial"
          >
            Unable To Generate Form
          </Typography>
          <Typography
            variant="subtitle1"
            className="textColorGray mgTop32 textTransformInitial"
          >
            Please try again with a valid link
          </Typography>
        </div>
      );
    }
  };

  const renderSuccess = () => {
    if (success) {
      return (
        <div className="enterpriseComponentContainer">
          <img
            alt="Logo"
            src={"/assets/img/success.svg"}
            className="notFoundImage"
          />
          <Typography
            variant="h4"
            className="mgTop32 boldText textTransformInitial"
          >
            Form Generated Successfully!
          </Typography>
        </div>
      );
    }
  };

  return (
    <>
      {renderLoading()}
      {renderError()}
      {renderSuccess()}
    </>
  );
};

export default FormExport;
