import React, { useEffect, useState } from "react";
import ChatRoom from "./ChatRoom";

import ClientList from "./ClientMessageList";
import { Col, Row } from "reactstrap";
import SearchInput from "../filters/searchInput";
import { COLLECTIONS, DB_KEYS, ROLES } from "../../constants";
import bodybuilder from "bodybuilder";
import Factory from "../../modules/http/dataFetcher";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const ChatSystem = (props) => {
  let unsubscribe = "";
  const { user } = props;

  const staff = props.location.state;
  const usersFetcher = Factory.usersFetcher();
  const [alignment, setAlignment] = useState("ClientMessage");
  const [clientList, setClientList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [searchStaffList, setSearchStaffList] = useState([]);
  const [originalClientList, setOriginalClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [totalCounts, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [lastMessage, setLastMessage] = useState({});

  useEffect(() => {
    fetchClients().then(() => {
      if (user.role !== "Trainer") {
        fetchMembers();
      }
    });
    return () => {
      // subscribe();
    };
  }, []);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    setSelectedClient("");
    if (newAlignment !== "ClientMessage") {
      // fetchMembers();
    }
  };
  const letterName = (name) => {
    let secondName = name.split(" ")[1];
    if (secondName) {
      secondName = secondName[0];
    } else {
      secondName = "";
    }
    name = name[0] + secondName;
    return name;
  };
  const lastUnreadMessages = (list) => {
    window.firebase
      .firestore()
      .collection(COLLECTIONS.MESSAGE)
      .where("receiver", "==", user.role)
      .onSnapshot((doc) => {
        let datas = lastMessage;
        if (doc.docs.length) {
          let totalCount = 0;
          let result = [...list];
          result.map((item) => {
            let count = 0;
            doc.docs.map((subItem) => {
              let data = subItem.data();
              if (
                data.clientId === item.id &&
                data.trainer_read_status === false
              ) {
                data.count = count + 1;
                datas[data.clientId] = [data];
                count++;
                totalCount++;
              } else if (
                data.trainerId === item.id &&
                data.coach_read_status === false
              ) {
                data.count = count + 1;
                datas[data.trainerId] = [data];
                count++;
                totalCount++;
              }
            });
          });

          if (datas) {
            setTotalCount(totalCount);

            setLastMessage(JSON.parse(JSON.stringify(datas)));
          } else {
            setLastMessage({});
          }
        }
      });
    // }
  };

  const prepareQueryFiltersForStaff = (key, value) => {
    let filters = bodybuilder();
    filters.filter("term", key, value);
    filters.notFilter("term", DB_KEYS.ROLE, ROLES.CLIENT);
    filters = filters.build();
    return filters;
  };

  async function fetchMembers() {
    setLoader(true);
    let currentUser = user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    let query = {};

    try {
      let results;
      query = prepareQueryFiltersForStaff(
        DB_KEYS.ENTERPRISE_ID,
        user.enterpriseId
      );
      results = await usersFetcher.fetch(query);

      let members = results;

      members &&
        members.sort((a, b) => {
          let nameA = (a.name || "").toLowerCase();
          let nameB = (b.name || "").toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      let newList = members.filter((member) => member.id !== user.id);

      setLoader(false);

      setStaffList(checkTrainer(newList, true));
      return;
    } catch (error) {
      this.loading = false;
    }
  }

  // const prepareFilterclient = () => {
  //   let filters = bodybuilder();
  //   let query = "";

  //   filters.query("query_string", {
  //     query: "*" + query + "*",
  //     fields: ["name", "email"],
  //   });
  //   filters.filter("term", "role", ROLES.CLIENT);
  //   if (props.ppEnterprise) {
  //     filters.orFilter("term", "enterpriseId", props.ppEnterprise.id);
  //   } else {
  //     filters.orFilter("term", "enterpriseId", props.user.enterpriseId);
  //   }
  //   filters.orFilter("term", "trainerId", props.user.id);
  //   if (props.user.role === ROLES.HEALTH_COACH) {
  //     let query = filters.build();
  //     return { coachId: props.user.id, ...query };
  //   } else {
  //     return filters.build();
  //   }
  // };

  const prepareFilterclient = () => {
    let filters = bodybuilder();
    filters.filter("term", "role", ROLES.CLIENT);
    if (
      user.role === ROLES.FACILITY_ADMIN ||
      user.role === ROLES.HEALTH_COACH
    ) {
      if (props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", user.enterpriseId)
            .orFilter("term", "trainerId", props.ppEnterprise.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q.orFilter("term", "enterpriseId", user.enterpriseId)
        );
      }
    } else {
      if (props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", props.ppEnterprise.id)
            .orFilter("term", "trainerId", user.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", user.enterpriseId)
            .orFilter("term", "trainerId", user.id)
        );
      }
    }
    if (user.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: user.id, ...query };
    } else {
      return filters.build();
    }
  };

  async function fetchClients() {
    // let query = {};
    let currentUser = user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }
    // if (!query.filter) {
    //   query.filters = [];
    // }
    // query.collection = COLLECTIONS.USER_DATA;
    // query.filters = [
    //   {
    //     key: DB_KEYS.TRAINER_ID_KEY,
    //     operator: "==",
    //     value: currentUser.id,
    //   }
    // ];

    try {
      let mergedList = [];
      if (!user.enterpriseId) {
        const query = prepareFilterclient();
        const results = await usersFetcher.fetch(query);
        mergedList = results;
        // let requestedClients = await window.FortisForma.database.queryData(
        //   query
        // );
        // console.log('requestedClients ===>', requestedClients)
        // requestedClients &&
        //   requestedClients.sort((a, b) => {
        //     let nameA = (a.name || "").toLowerCase();
        //     let nameB = (b.name || "").toLowerCase();
        //     if (nameA < nameB) return -1;
        //     if (nameA > nameB) return 1;
        //     return 0;
        //   });
        // query.collection = COLLECTIONS.INVITES;
        // let pendingInvites = await window.FortisForma.database.queryData(query);
        // for (let invite of pendingInvites) {
        //   invite.pendingLogin = true;
        // }
        // let pendingClients = pendingInvites;
        // pendingClients &&
        //   pendingClients.sort((a, b) => {
        //     let nameA = (a.name || "").toLowerCase();
        //     let nameB = (b.name || "").toLowerCase();
        //     if (nameA < nameB) return -1;
        //     if (nameA > nameB) return 1;
        //     return 0;
        //   });
        // mergedList = [...requestedClients, ...pendingClients];
      } else {
        const query = prepareSearchFilters();
        if (user.role === "Trainer") {
          query.coachId = user.id;
        }
        mergedList = await window.FortisForma.database.fetchEnterpriseClients(
          query
        );
      }
      mergeClientList(mergedList);
      return;
    } catch (e) {
      throw e;
    }
  }

  function handleSelected(client, staff) {
    removeLastMessage(client);
    if (window.innerWidth <= 991) {
      navigate();
    }
    unsubscribe && unsubscribe();
    if (staff) {
      setSelectedClient(client);
    } else {
      setSelectedClient(client);
    }
  }

  const navigate = () => {
    let node = document.querySelector("#chatsystem");
    node && node.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  function prepareSearchFilters() {
    let filters = bodybuilder();
    let query = "";
    filters.query("query_string", {
      query: "*" + query + "*",
      fields: ["name", "email"],
    });
    filters.filter("term", "role", ROLES.CLIENT);
    filters.filter("term", "pendingLogin", false);
    if (
      user.role === ROLES.FACILITY_ADMIN ||
      user.role === ROLES.HEALTH_COACH
    ) {
      if (props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", user.enterpriseId)
            .orFilter("term", "trainerId", props.ppEnterprise.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q.orFilter("term", "enterpriseId", user.enterpriseId)
        );
      }
    } else {
      if (props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", props.ppEnterprise.id)
            .orFilter("term", "trainerId", user.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", user.enterpriseId)
            .orFilter("term", "trainerId", user.id)
        );
      }
    }
    if (user.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: user.id, ...query };
    } else {
      return filters.build();
    }
  }

  const mergeClientList = (mergedList) => {
    mergedList.sort((a, b) => {
      if (!a.name || !b.name) {
        return 0;
      }
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });
    setLoader(false);
    setOriginalClientList(checkTrainer(mergedList));
  };

  function checkTrainer(list, staff = false) {
    lastUnreadMessages(list, { client: false });
    if (props.location.state !== undefined) {
      setAlignment("CoachMessages");

      setTimeout(() => {
        setSelectedClient(props.location.state);
      }, 500);
    }
    if (staff) {
      return list.filter(
        (item) =>
          (item.enterpriseId === props.user.enterpriseId ||
            item.trainerId === props.user.id) &&
          !item.pendingLogin
      );
    }
    let newList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].chatTrainer) {
        for (let j = 0; j < list[i].chatTrainer.length; j++) {
          if (
            // (list[i].chatTrainer[j].id === props.user.id || (props.ppEnterprise && list[i].chatTrainer[j].id === props.ppEnterprise.id))  &&
            list[i].chatTrainer[j].id === props.user.id &&
            !list[i].pendingLogin
          ) {
            newList.push(list[i]);
          }
        }
      }
    }
    // lastUnreadMessages(newList, { client: false });
    return newList;
  }

  const removeLastMessage = (item) => {
    let last = lastMessage;
    delete last[item.id];
    setLastMessage(last);
  };
  const handleClientSearch = (text) => {
    setLoader(true);
    setSearchValue(text);
    if (alignment === "ClientMessage") {
      let list = [...originalClientList];
      let newList = window._.filter(list, (item) =>
        item.name.toLowerCase().includes(text.toLowerCase())
      );
      setClientList(checkTrainer(newList));
    } else {
      let list = [...staffList];
      let newList = window._.filter(list, (item) =>
        item.name.toLowerCase().includes(text.toLowerCase())
      );
      setSearchStaffList(checkTrainer(newList, true));
    }
    setLoader(false);
  };

  return (
    <>
      <Row className="chatsystemtabs">
        <Col sm="12" lg="5" key={"Search"}>
          <SearchInput
            sx={{ border: 1 }}
            inputId={"searchClients"}
            placeholder={
              alignment === "ClientMessage"
                ? "Search Clients"
                : "Search Coaches"
            }
            value={searchValue}
            onChange={(e) => handleClientSearch(e.target.value)}
            onClickClear={() => {
              setSearchValue("");
              setClientList([]);
              setSearchStaffList([]);
            }}
          ></SearchInput>
        </Col>

        <Col sm="12" lg="4" key={"toggle_btn"}>
          {/* <p className='unreadMsg'>{totalCounts}</p> */}
          {user.enterpriseId && user.role !== "Trainer" ? (
            <ToggleButtonGroup
              variant="contained"
              color="red"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="Platform"
            >
              <ToggleButton value="ClientMessage">Client Message</ToggleButton>
              <ToggleButton value="CoachMessages">Coach Messages</ToggleButton>
            </ToggleButtonGroup>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      <div className="chatsystem" id="chatsystem">
        {alignment === "ClientMessage" ? (
          clientList.length > 0 ? (
            <ClientList
              clients={clientList}
              handleSelected={handleSelected}
              staff={false}
              loader={loader}
              selectedClient={selectedClient}
              lastMessage={lastMessage}
            />
          ) : (
            <ClientList
              clients={originalClientList}
              handleSelected={handleSelected}
              loader={loader}
              staff={false}
              selectedClient={selectedClient}
              lastMessage={lastMessage}
            />
          )
        ) : searchStaffList.length > 0 ? (
          <ClientList
            clients={searchStaffList}
            staff={true}
            handleSelected={handleSelected}
            loader={loader}
            selectedClient={selectedClient}
            lastMessage={lastMessage}
          />
        ) : (
          <ClientList
            clients={staffList}
            selectedClient={selectedClient}
            staff={true}
            handleSelected={handleSelected}
            loader={loader}
            lastMessage={lastMessage}
          />
        )}

        {selectedClient && (
          <ChatRoom
            selectedClient={selectedClient}
            currentUser={user}
            unsubscribe={unsubscribe}
            removeLastMessage={removeLastMessage}
            setSelectedClient={setSelectedClient}
          />
        )}
      </div>
    </>
  );
};
export default ChatSystem;
