import React from "react";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import _ from "lodash";

const EXPORT_TYPE = [
  {
    LABEL: "Current Version",
    VALUE: 0,
  },
  {
    LABEL: "All Versions",
    VALUE: 1,
  },
];

export default function ExportPDFButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(EXPORT_TYPE[0].VALUE);

  const disableExportOptions =
    _.has(props, "disableExportOptions") && props.disableExportOptions;

  const toolTipPlacement = _.has(props, "toolTipPlacement")
    ? props.toolTipPlacement
    : "top";
  const toolTipArrow = _.has(props, "toolTipArrow") ? props.toolTipArrow : true;

  const onClose = () => {
    setAnchorEl(null);
    setOption(EXPORT_TYPE[0].VALUE);
  };

  const onClickExport = (e) => {
    e.stopPropagation && e.stopPropagation();
    if (loading) {
      return;
    }
    if (!disableExportOptions && props.data.edited) {
      setAnchorEl(e.currentTarget);
      return;
    }
    return handleExport();
  };

  const handleExport = async (allVersions = false) => {
    setLoading(true);
    setAnchorEl(null);
    try {
      await props.onExport(props.data, allVersions);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
    setLoading(false);
    onClose();
  };

  const renderOptions = () => {
    return (
      <div className="createFormTypeOptionsContainer">
        <FormLabel>Select version</FormLabel>
        <RadioGroup
          aria-label="export-type"
          value={option}
          onChange={(e) => setOption(parseInt(e.target.value))}
        >
          {EXPORT_TYPE.map((TYPE, key) => {
            return (
              <span key={key}>
                <FormControlLabel
                  value={TYPE.VALUE}
                  control={<Radio color="primary" />}
                  label={TYPE.LABEL}
                />
              </span>
            );
          })}
        </RadioGroup>
        <div className="center">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              handleExport(option);
            }}
            variant="contained"
            style={{ marginTop: 8 }}
          >
            Export
          </Button>
        </div>
      </div>
    );
  };

  const renderExportOptions = () => {
    return (
      <Popover
        onClick={(e) => e.stopPropagation && e.stopPropagation()}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {renderOptions()}
      </Popover>
    );
  };

  const renderPDFIcon = () => {
    return (
      <Tooltip
        title="Download PDF"
        placement={toolTipPlacement}
        arrow={toolTipArrow}
      >
        <IconButton
          className={loading ? "cursorDefault" : null}
          onClick={(e) => {
            onClickExport(e);
          }}
          size="small"
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <GetAppRoundedIcon color="primary" />
          )}
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <>
      {renderPDFIcon()}
      {renderExportOptions()}
    </>
  );
}
