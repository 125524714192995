import { CircularProgress } from "@material-ui/core";
import React from "react";

const OpaqueLoading = () => {
  return (
    <div className="opaqueLoading">
      <CircularProgress color="primary" />
    </div>
  );
};

export default OpaqueLoading;
