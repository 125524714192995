import { Typography } from "@material-ui/core";
import React from "react";
import FortisFormaSelect from "../autoSuggest/dropdownSelect";
import UseTemplates, { DROPDOWN_FORM_TYPES } from "./useTemplates";
import { ROLES, TIER } from "../../constants";

export default class UseEnterpriseTemplates extends UseTemplates {
  constructor(props) {
    super(props);
    this.state = {
      forms: this.props.enterpriseForms || [],
      ...this.state,
    };
  }

  handleTabChange = (selectedOption) => {
    if (selectedOption === DROPDOWN_FORM_TYPES[1]) {
      return this.props.redirectToAssessments();
    }

    if (selectedOption === DROPDOWN_FORM_TYPES[4]) {
      return this.props.redirectToNotes();
    }

    this.setState(
      {
        selectedTab: selectedOption,
      },
      this.setSequenceNumber
    );
  };

  fetchForms = async () => {
    try {
      if (this.state.forms.length === 0 && !this.props.hasFetchedForms) {
        this.setState({
          loading: true,
        });
        // if (
        //   this.props.user.role === ROLES.TRAINER &&
        //   this.props.user.tier !== TIER.FREE
        // ) {
        //   console.log("client form if");
        //   results = await window.FortisForma.database.fetchUserForms(
        //     this.props.user.id
        //   );
        // } else {
        //   console.log("client form else");
        const results = await this.props.fetchEnterpriseForms();
        // }

        this.setState({
          forms: results,
          loading: false,
        });
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching the templates");
      this.setState({
        loading: false,
      });
    }
  };

  renderFormTypeDropdown = () => {
    return (
      <div className="formTypeDropdownContainer">
        <FortisFormaSelect
          id="useTemplatesFormTypeSelect"
          placeholder="Forms"
          value={this.state.selectedTab}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.handleTabChange}
          options={DROPDOWN_FORM_TYPES}
          isClearable={false}
          isDisabled={this.state.loading}
        />
        <Typography variant="caption" style={{ marginTop: "8px" }}>
          Note: For NOTE/ASSESSMENT form type, you will be redirected to the
          respective tab.
        </Typography>
      </div>
    );
  };
}
