import { Typography } from "@material-ui/core";
import React from "react";

export default class NoteAssessmentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPreview: false,
      url: "",
      loading: false,
      anchorEl: null,
      updatedTime:
        (this.props.data &&
          this.props.data.updatedTime &&
          this.props.data.updatedTime._seconds) ||
        "",
    };
  }

  handleOpenMenu = (e) => {
    e.stopPropagation && e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };

  onMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  clickEdit = () => {
    const { data } = this.props;
    this.props.onEdit(data);
  };

  renderItemContent = () => {
    const { data } = this.props;
    return (
      <div className="assessmentSummaryAndTimeContainer">
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{this.getItemtitle(data)}</Typography>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="assessmentItemContainer">
        {this.renderItemContentContainer()}
        <div className="timestampContainer">
          {this.props.data.timestamp && this.props.data.timestamp}
        </div>
      </div>
    );
  }
}
