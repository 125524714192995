import React, {
    Component
    } from 'react';

import {
    Spinner
    } from 'reactstrap';

import {ACCOUNT_ACTIONS} from '../../constants';

export default class AccountAction extends Component {

    componentDidMount() {
        let querySearchParams = this.props.location.search;
        let params = new URLSearchParams(querySearchParams);
        let code = params.get("oobCode");
        let mode = params.get("mode");
        let continueUrl = params.get("continueUrl");
        this.routeTo(mode, code, continueUrl)
    }

    routeTo(mode, code, continueUrl) {
        let url;
        switch(mode) {
            case ACCOUNT_ACTIONS.RESET_PASSWORD:
                url = `/forgot-password/action?code=${code}`;
                break;
            
            case ACCOUNT_ACTIONS.VERIFY_EMAIL:
                url = `/verify-email/action?code=${code}&continueUrl=${continueUrl}`;
                break;

            default:
                break;
        }
        this.props.history.replace(url);
    }

    render() {
        return(
            <div style={{display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner color="primary" />
            </div>
        )
    }
}