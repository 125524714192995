import React from "react";
import { Button } from "reactstrap";
import VisaCard from "../../assets/visa.png";
import MasterCard from "../../assets/master.png";

export default function StripeCard(props) {
  function onDelete() {
    window.customConfirm("Are you sure you want to remove this card?", () => {
      props.onDelete(props.data);
    });
  }

  function onDefChange() {
    if (props.default) {
      window.NotificationUtils.showSuccess("This card is already default");
    } else {
      window.customConfirm(
        "Are you sure you want to default this card?",
        () => {
          props.changeDefCard(props.data);
        }
      );
    }
  }

  const renderHeader = () => {
    return (
      <Button color="primary" onClick={onDefChange}>
        {props.default ? "Default" : "Set Default"}
      </Button>
    );
  };

  // const deletebtn = () => {
  //   return (
  //     <Button color="primary" onClick={onDelete} className="deleteIcon">
  //       Delete
  //     </Button>
  //   );
  // };

  return (
    <div className="staffStripeCards">
      <div className="strippe-modal-wrap">
        <input
          className="che"
          type="checkbox"
          checked={props.default}
          onChange={onDefChange}
        />
        <div className="staff-card">
          <div className="staff-card-head">
            <div className="card-imgs">
              <img
                src={
                  props.data.brand.toLowerCase() === "mastercard"
                    ? MasterCard
                    : VisaCard
                }
                alt="visa card"
              />
            </div>
            <div className="card-nmb">
              <p>Card Number</p>
              <p>**** **** **** {props.data.last4}</p>
            </div>
            <div className="stripedelete-btn pointer" onClick={onDelete}>
              <i className="icon material-icons" alt="Delete">
                delete
              </i>
              <span>Delete</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
