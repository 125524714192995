import { Button, Link, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import PublishIcon from "@material-ui/icons/PublishRounded";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import React from "react";
import Dropzone from "react-dropzone";
import "./fileUploader.css";

export default class AdvancedFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploadingProgress: 0,
      downloadURL: "",
      uploadSuccess: false,
      uploadFailed: false,
      fileData: [],
    };
  }

  onSingleFileChange = (file) => {
    if (this.state.fileData[0]) {
      this.onClickDelete(this.state.fileData[0], 0);
    }
    let files = Array.from(file);
    this.uploadDocument(files[0]);
  };

  onMultipleFileChange = (file) => {
    let files = Array.from(file);
    files.map((item) => {
      return this.uploadDocument(item);
    });
  };

  onFileChange = (file) => {
    if (this.props.filePickerProps.isMultiple) {
      this.onMultipleFileChange(file);
    } else {
      this.onSingleFileChange(file);
    }
  };

  uploadDocument = async (item) => {
    if (!item) {
      return;
    }
    this.setState({
      loading: true,
    });
    let onProgress = (progress, uploadTask) => {
      if (progress <= 100) {
        this.setState({
          uploadingProgress: Math.max(progress, 40),
        });
      }
    };

    try {
      let result = await window.FortisForma.database.uploadDocument(
        item,
        this.props.uploadLocation,
        onProgress
      );
      this.setState({
        loading: false,
        uploadSuccess: true,
      });
      this.getDownloadURL(result, item);
    } catch (e) {
      this.props.onFailure && this.props.onFailure(e);
      console.error(e);
      this.setState({
        loading: false,
        uploadFailed: true,
        uploadingProgress: 0,
      });
    }
  };

  getDownloadURL = async (result, item) => {
    let path = result.metadata.fullPath;
    try {
      let downloadURL = await window.FortisForma.database.getDownloadURL(path);
      let fileDataCopy = this.state.fileData;
      let data = {};
      data.url = downloadURL;
      data.name = item.name;
      data.storageName = result.metadata.name;
      fileDataCopy.push(data);
      this.setState(
        {
          fileData: fileDataCopy,
        },
        this.changeButtonAfterUpload()
      );
      let uploadedDocs = fileDataCopy.map((item) => {
        let data = {};
        data.name = item.name;
        data.url = item.url;
        return data;
      });

      this.props.onComplete && this.props.onComplete(uploadedDocs);
    } catch (e) {
      console.error(e);
    }
  };

  changeButtonAfterUpload = () => {
    setTimeout(() => {
      this.setState({
        uploadSuccess: false,
        uploadingProgress: 0,
      });
    }, 500);
  };

  async onClickDelete(item, index) {
    let fileDataCopy = this.state.fileData;
    fileDataCopy.splice(index, 1);
    this.setState({
      fileData: fileDataCopy,
    });

    try {
      let refPath = this.props.uploadLocation + item.storageName;
      await window.FortisForma.database.deleteFileFromStroage(refPath);
    } catch (e) {
      console.error(e);
    }
    this.props.onDelete && this.props.onDelete(item, fileDataCopy);
  }

  renderCircularProgress = () => {
    return (
      <CircularProgress
        color="primary"
        size={16}
        variant="static"
        id="uploadingImageCircularProgress"
        value={this.state.uploadingProgress}
      />
    );
  };

  render() {
    const accept = this.props.filePickerProps.extensions
      .map((ext) => `.${ext}`)
      .join(",");
    const acceptClean = this.props.filePickerProps.extensions
      .map((ext) => ext.toUpperCase())
      .join(", ");

    return (
      <div className="uploadManager">
        <Typography className="uploadTitle" variant="body1" color="initial">
          {this.props.placeholder} - [{acceptClean}]
        </Typography>
        <Card className="dropzoneCard" elevation={0}>
          <Dropzone onDrop={this.onFileChange} accept={accept}>
            {({ getRootProps, getInputProps }) => (
              <section className="pickerSection">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {this.state.uploadingProgress > 0 ? (
                    this.renderCircularProgress()
                  ) : (
                    <PublishIcon />
                  )}
                  {this.props.fileInvalid ? (
                    <p>Invalid file type, select an any of {accept}</p>
                  ) : (
                    <div className="dropAreaText">
                      Drag and Drop or{" "}
                      <Button variant="text" color="default">
                        Browse Files
                      </Button>
                    </div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </Card>
        {this.renderUploaded()}
      </div>
    );
  }

  renderUploaded() {
    return (
      this.state.fileData.length > 0 && (
        <div>
          {this.state.fileData.map((item, index) => {
            return (
              <div key={item.url}>
                <div className="uploadedDoc flexRow">
                  <div className="flexRow" style={{ flex: 1 }}>
                    <DescriptionIcon />
                    <Link
                      className="uploadedFileName"
                      target="_blank"
                      href={item.url}
                    >
                      {item.name}
                    </Link>
                  </div>

                  <IconButton
                    className="deleteIcon"
                    size="small"
                    onClick={() => this.onClickDelete(item, index)}
                  >
                    <DeleteOutlineOutlinedIcon color="primary" />
                  </IconButton>

                  <IconButton
                    href={item.url}
                    target="_blank"
                    className="download"
                    size="small"
                  >
                    <SystemUpdateAltRoundedIcon color="primary" />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  }
}
