import React from "react";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import FileUpload from "./fileUpload";

const imagePickerStyles = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  top: "0",
  backgroundColor: "lightgrey",
  borderRadius: "50%",
};

export default class ProfileImageUploader extends FileUpload {
  constructor(props) {
    super(props);
    this.storageRef = window.firebase.storage().ref(this.props.storageRef);
  }

  render() {
    return (
      <div className="margin-row-default">
        <CustomUploadButton
          hidden
          accept="image/*"
          filename={this.props.fileName}
          randomizeFilename={this.props.fileName ? false : true}
          storageRef={this.storageRef}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}
          disabled={this.state.isUploading}
        >
          <div
            className={"proPicContainer"}
            style={
              this.props.containerStyle ? { ...this.props.containerStyle } : {}
            }
          >
            {this.state.fileUrl ? (
              <img
                alt="uploading"
                src={this.state.fileUrl ? this.state.fileUrl : null}
                style={imagePickerStyles}
              />
            ) : (
              <div style={imagePickerStyles}></div>
            )}

            <div
              className="proPicLabel"
              style={this.props.labelStyle ? { ...this.props.labelStyle } : {}}
            >
              {this.state.isUploading
                ? `Uploading ${this.state.progress}%`
                : this.state.progress === 100
                ? ""
                : "Upload"}
            </div>
          </div>
        </CustomUploadButton>
      </div>
    );
  }
}
