import React from 'react';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const ENERGIZED_OPTIONS = [
    "Low Energy",
    "Medium Energy",
    "High Energy"
]

const PROUDNESS_OPTIONS = [
    "I Can Do More",
    "I Did Enough",
    "I Did More Than Expected"
]

const INTENSITY_OPTIONS = [
    "Too Low",
    "Just Right",
    "Crazy"
]

const LENGTH_OPTIONS = [
    "Too Short",
    "Just Right",
    "Too Long"
]
export default class WorkoutFeedback extends React.Component {
    constructor(props){
        super(props);
        this.state={
            energized:"",
            intensity:"",
            length:"",
            proudness:""
        }
    }

    renderEnergizedToggle=()=>{
        return(
            <ToggleButtonGroup
                className="fullWidth"
                value={this.state.energized}
                exclusive
                onChange={this.onChangeEnergizedToggleLevel}
                >
                    {ENERGIZED_OPTIONS.map((value, index)=>{
                        return this.renderToggleValue(value, index)
                    })}
            </ToggleButtonGroup>
        )
    }

    renderProudnessToggle=()=>{
        return(
            <ToggleButtonGroup
                className="fullWidth"
                value={this.state.proudness}
                exclusive
                onChange={this.onChangeProudnessToggleLevel}
                >
                    {PROUDNESS_OPTIONS.map((value, index)=>{
                        return this.renderToggleValue(value, index)
                    })}
            </ToggleButtonGroup>
        )
    }

    renderIntensityToggle=()=>{
        return(
            <ToggleButtonGroup
                className="fullWidth"
                value={this.state.intensity}
                exclusive
                onChange={this.onChangeIntensityToggleLevel}
                >
                    {INTENSITY_OPTIONS.map((value, index)=>{
                        return this.renderToggleValue(value, index)
                    })}
            </ToggleButtonGroup>
        )
    }


    renderLengthToggle=()=>{
        return(
            <ToggleButtonGroup
                className="fullWidth"
                value={this.state.length}
                exclusive
                onChange={this.onChangeLengthToggleLevel}
                >
                    {LENGTH_OPTIONS.map((value, index)=>{
                        return this.renderToggleValue(value, index)
                    })}
            </ToggleButtonGroup>
        )
    }

    renderToggleValue=(value, index)=>{
        return (
          <ToggleButton
            size="small"
            id="feedbackToggleButton"
            key={index}
            value={value}
          >
            {value}
          </ToggleButton>
        );
    }

    onChangeEnergizedToggleLevel =(event, value) =>{
        this.setState({
            energized: value
        })
    }
    onChangeProudnessToggleLevel =(event, value) =>{
        this.setState({
            proudness: value
        })
    }    
    onChangeIntensityToggleLevel =(event, value) =>{
        this.setState({
            intensity: value
        })
    }    
    onChangeLengthToggleLevel =(event, value) =>{
        this.setState({
            length: value
        })
    }



    renderTitle =(value) =>{
        return(
            <Typography className="marginTop8 marginBottom8 textColorGray textAlignCenter" variant="body2">
                {value}
            </Typography>
        )
    }

    getDetails = () => {
        let data={}
        data.energized = this.state.energized
        data.length = this.state.length
        data.proudness = this.state.proudness
        data.intensity = this.state.intensity
        return data
    }

    renderRoutineFeedback=()=>{
        return(
            <>
                <Typography className="marginTop16 marginBottom8 fw-600" variant="body1">
                    How was the routine ?
                </Typography>
                {this.renderTitle("Intensity")}
                {this.renderIntensityToggle()}
                {this.renderTitle("Length")}
                {this.renderLengthToggle()}
            </>
        )
    }



    render(){
        return(
            <React.Fragment>
                <Typography className="marginTop16 marginBottom8 fw-600" variant="body1">
                    How do you feel ?
                </Typography>
                {this.renderTitle("Energized")}
                {this.renderEnergizedToggle()}
                {this.renderTitle("Proudness")}
                {this.renderProudnessToggle()}
                {this.props.showAllFeedback && this.renderRoutineFeedback()}

            </React.Fragment>
        )
    }

}