import GenericFilters, { INITIAL_STATE } from "./genericFilters";
import React from "react";
import FortisFormaSelect from "../autoSuggest/dropdownSelect.js";

export default class MovementCategory extends GenericFilters {
  constructor(props) {
    super(props);
    this.state.category = "";
  }

  handleCategorySelect = (selected) => {
    this.setState({ category: selected, didApply: true }, this.debouncedApply);
  };

  prepareFilters() {
    return {
      ...this.state.filters,
      category: this.state.category ? this.state.category.value : "",
    };
  }

  _clearFilters(clearAll) {
    this.setState({ filters: INITIAL_STATE.filters });
    if (clearAll) {
      this.setState(INITIAL_STATE, this.applyFilters);
      this.setState({ category: "" });
    }
  }

  renderExtras() {
    return (
      <div
        style={{
          minWidth: 200,
          margin: "0 8px",
          marginRight: 0,
        }}
      >
        <FortisFormaSelect
          isClearable={true}
          placeholder="Movement Category"
          value={this.state.category}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.handleCategorySelect}
          options={this.props.categories || []}
        />
      </div>
    );
  }
}
