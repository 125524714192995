import MuiButton from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import Autosuggest from "react-autosuggest";
import Joyride from "react-joyride";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { isEmail, isMobilePhone, isPostalCode } from "validator";
import AssignCoachModal from "../../components/assignCoachModal/assignCoachModal";
import ProfileImageUploader from "../../components/profileImageUploader";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import {
  CLIENT_DETAIL_TABS,
  DATE_FORMAT,
  LOCAL_STORAGE_KEYS,
  ROLES,
  TIER,
  WALKTHROUGH,
  WORKOUT_SESSION_ROUTE,
} from "../../constants";
import { CLIENT_DETAIL_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import { showClientDetailsToUser } from "../enterpriseClientDetails/clientDetails";
import { assignWorkoutToUser } from "../workoutAssigner/workoutAssigner";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import NumberFormat from "react-number-format";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TAButton from "../../components/taButton/taButton";

const dateValidationRegEx =
  /^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/;

const PROVINCES = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon Territory",
];

const COUNTRY_CODES = ["en-CA", "en-IN"];

const getSuggestionValue = (suggestion) => {
  return suggestion;
};

const renderSuggestion = (suggestion) => (
  <div className="suggestion">{suggestion}</div>
);

export default class ClientDetails extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);

    this.state = {
      walkthrough: false,
      address: "",
      city: "",
      name: "",
      newClient: true,
      province: "",
      provinceSuggestions: [],
      provinces: PROVINCES,
      change: false,
      steps: CLIENT_DETAIL_WALKTHROUGH.steps,
      openAssignCoachModal: false,
      DOB: "",
      email: "",
      programName: "",
      membershipName: "",
      active: false,
      saving: false,
    };

    this.state = Object.assign({}, this.state, props.selectedClient);
    this.onSave = this.onSave.bind(this);
    this.assignWorkouts = this.assignWorkouts.bind(this);
    this.onChangeEmail = this.onChange.bind(this, "email");
    this.onChangeName = this.onChange.bind(this, "name");
    this.onChangeAddress = this.onChange.bind(this, "address");
    this.onChangeCity = this.onChange.bind(this, "city");
    this.onChangePostal = this.onChange.bind(this, "postal");
    this.onChangeProvince = this.onChange.bind(this, "province");
    this.onChangeMembershipName = this.onChange.bind(this, "membershipName");
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.CLIENT_DETAIL;
    this.originalData = this.state;
    this.isEnterpriseMember = !!(
      this.props.user && this.props.user.enterpriseId
    );
    this.isTrainer = this.props.user && this.props.user.role === "Trainer";
  }

  initializeState = () => {
    this.setState({
      name: "",
      email: "",
      DOB: "",
      phone: "",
      address: "",
      city: "",
      postal: "",
      province: "",
      provinceSuggestions: [],
      provinces: PROVINCES,
      programName: "",
      membershipName: "",
      picURL: "",
      newClient: true,
      active: false,
      chatTrainer: [],
    });
  };

  setProgramName = async (clientId, programId) => {
    this.setState({
      loadingProgram: true,
    });
    let programName;
    let programNames = { ...this.props.clientPrograms };
    if (programNames[clientId]) {
      programName = programNames[clientId];
    } else {
      try {
        programName = await this.fetchProgramName(programId);
        programNames[clientId] = programName;
        this.props.setProgramNames(programNames);
      } catch (e) {
        console.error(e);
      }
    }

    this.setState({
      programName,
      loadingProgram: false,
    });
  };

  fetchProgramName = async (programId) => {
    try {
      let result = await window.FortisForma.database.getProgram(programId);
      return result.name;
    } catch (e) {
      throw e;
    }
  };

  handelUndo = () => {
    if (!this.originalData.picURL) {
      this.originalData.picURL = "";
    }
    this.setState(this.originalData);
    this.setState({ change: false });
  };

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    update["change"] = true;
    this.setState(update);
  }

  onChangePhone = (event) => {
    let formattedValue = window.formatPhone(event.target.value);
    this.setState({
      phone: formattedValue,
      change: true,
    });
  };

  onChangeClientStatus = (event) => {
    this.setState({
      active: event.target.checked,
      change: true,
    });
  };

  componentDidMount() {
    this.setState({
      ...this.props.selectedClient,
    });
    if (this.props.selectedClient && this.props.selectedClient.programId) {
      this.setProgramName(
        this.props.selectedClient.id,
        this.props.selectedClient.programId
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.onClientSaved) {
      this.initializeState();
    }
    if (
      nextProps.selectedClient &&
      this.props.selectedClient &&
      nextProps.selectedClient.id === this.props.selectedClient.id
    ) {
      return;
    }
    this.setState({
      ...nextProps.selectedClient,
      change: false,
    });
    if (!nextProps.selectedClient.picURL) {
      this.originalData = Object.assign({}, nextProps.selectedClient, {
        picURL: "",
      });
    } else {
      this.originalData = Object.assign({}, nextProps.selectedClient);
    }
    if (!this.originalData.DOB) {
      this.originalData.DOB = "";
    }
    if (!this.originalData.email) {
      this.originalData.email = "";
    }
    if (!this.originalData.membershipName) {
      this.originalData.membershipName = "";
    }
    this.setState({
      programName: "",
    });
    if (nextProps.selectedClient && nextProps.selectedClient.programId) {
      this.setProgramName(
        nextProps.selectedClient.id,
        nextProps.selectedClient.programId
      );
    }
    this.setState(this.originalData);
  }

  setImageUrl = (val) => {
    this.setState({
      picURL: val,
      change: true,
    });
  };

  validateDate = () => {
    return dateValidationRegEx.test(moment(this.state.DOB).format(DATE_FORMAT));
  };

  onSave = async () => {
    if (this.props.selectedClient.id) {
      this.setState({ saving: true });
    }
    if (!this.state.name) {
      window.NotificationUtils.showError("Invalid Name");
      this.setState({ saving: false });
      return;
    }

    if (this.state.DOB && !this.validateDate()) {
      window.NotificationUtils.showError("Invalid Date");
      this.setState({ saving: false });
      return;
    }

    if (
      (!this.isEnterpriseMember &&
        (!this.state.email || !isEmail(this.state.email))) ||
      (this.isEnterpriseMember &&
        this.state.email &&
        !isEmail(this.state.email))
    ) {
      window.NotificationUtils.showError("Invalid Email");
      this.setState({ saving: false });
      return;
    }
    var Client = Object.assign({}, this.props.selectedClient);
    Client.address = this.state.address || "";
    Client.city = this.state.city || "";
    Client.email =
      (this.state.email && window.emailTrimAndLowerCase(this.state.email)) ||
      "";
    Client.id = this.state.id;
    Client.name = this.state.name || "";
    Client.picURL = this.state.picURL || "";
    Client.DOB = this.state.DOB
      ? moment(this.state.DOB).format(DATE_FORMAT)
      : null;
    try {
      Client.phone = this.state.phone || "";
      Client.postal = this.state.postal
        ? this.state.postal.match(/\w+/g).join("")
        : "";
    } catch (e) {
      //TODO handle error
    }
    Client.province = this.state.province || "";
    Client.membershipName = this.state.membershipName || "";
    Client.active = this.state.active;
    delete Client._score;
    delete Client.sort;
    if (
      this.props.selectedClient.id &&
      ((!this.props.selectedClient.email && Client.email) ||
        this.props.selectedClient.email !== Client.email)
    ) {
      Client.emailAdded = true;
    }
    try {
      await this.props.onSave(Client);
      this.setState({ change: false, saving: false });
    } catch (e) {
      this.setState({ change: false, saving: false });
      console.error(e);
    }
  };

  assignWorkouts() {
    assignWorkoutToUser(
      {
        name: this.state.name,
        email: this.state.email,
        id: this.state.id,
        pendingLogin: this.state.pendingLogin,
        dob: this.state.DOB,
        role: this.state.role,
        chatTrainer: this.state.chatTrainer,
      },
      // this.props.user,
      this.props.history,
      null,
      this.props.user.enterpriseId,
      this.props.user
    );
  }

  showClientDetails = (e, defaultTab = 0) => {
    showClientDetailsToUser(
      {
        name: this.state.name,
        email: this.state.email,
        id: this.state.id,
        dob: this.state.DOB,
        pendingLogin: this.state.pendingLogin,
        forms: this.state.forms,
        role: this.state.role,
        chatTrainer: this.state.chatTrainer,
      },
      this.props.history,
      defaultTab
    );
  };

  isCellNumberValid = (number) => {
    if (!number) {
      return false;
    }
    return isMobilePhone(number, COUNTRY_CODES);
  };

  isPostalValid = (postalCode) => {
    if (!postalCode) {
      return false;
    }
    return isPostalCode(postalCode, "CA");
  };

  parsePostalCode(value) {
    if (!value) {
      return "";
    }
    let match = value.match(/\w+/g);
    let string = "";
    if (match) {
      string = match.join("");
    }
    let parsed = "";
    if (string.length > 3) {
      parsed = string.slice(0, 3) + " " + string.slice(3, string.length);
    } else {
      parsed = string;
    }

    return parsed.toUpperCase();
  }

  filterProvinces = (value) => {
    if (!value) return [];

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let results =
      inputLength === 0
        ? []
        : this.state.provinces.filter((name) => {
            return name.toLowerCase().includes(inputValue);
          });

    return results;
  };

  onProvinceSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      provinceSuggestions: this.filterProvinces(value),
    });
  };

  onProvinceSuggestionsClearRequested = () => {
    this.setState({
      provinceSuggestions: [],
    });
  };

  onResendInvite = () => {
    this.props.onResendInvite(this.state.email, this.state.name);
  };

  removeClient = () => {
    window.customConfirm(
      "Are you sure you want to remove " + this.state.email,
      () => {
        this.props.onRemove({
          id: this.props.selectedClient,
        });
      }
    );
  };

  openWorkoutSummary = () => {
    var summaryDetails = {};
    summaryDetails.clientName = this.state.name;
    summaryDetails.clientEmail = this.state.email;
    summaryDetails.clientId = this.state.id;
    summaryDetails = JSON.stringify(summaryDetails);
    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_SUMMARY_DETAILS,
        summaryDetails
      );
      this.props.history.push("/workout-summary");
    } catch (e) {
      window.NotificationUtils.showError("Unable to access local storage");
    }
  };

  openAssignCoachModal = () => {
    this.setState({
      openAssignCoachModal: true,
    });
  };

  closeAssignCoachModal = () => {
    this.setState({
      openAssignCoachModal: false,
    });
  };

  renderAssignCoachModal = () => {
    return (
      <Dialog fullWidth={true} open={this.state.openAssignCoachModal}>
        <AssignCoachModal
          closeAssignCoachModal={this.closeAssignCoachModal}
          coaches={this.props.healthCoaches}
          selectedClient={this.props.selectedClient}
          user={this.props.user}
        />
      </Dialog>
    );
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          locale={{ last: "Done" }}
        />
      </div>
    );
  };

  handleDateChange = (date) => {
    if (date) {
      date = moment(date).format("YYYY/MM/DD");
    }
    this.setState({
      DOB: date,
      change: true,
    });
  };

  renderProgram() {
    return (
      <>
        {this.state.programName ? (
          <MuiButton
            size="small"
            color="primary"
            onClick={(e) =>
              this.isEnterpriseMember
                ? this.showClientDetails(e, CLIENT_DETAIL_TABS.WORKOUTS)
                : this.assignWorkouts()
            }
          >
            {this.state.programName}
          </MuiButton>
        ) : this.props.selectedClient && this.props.selectedClient.programId ? (
          `Program name missing`
        ) : (
          `No program assigned`
        )}
      </>
    );
  }

  linkToWorkoutSession = () => {
    try {
      let selectedClient = this.props.selectedClient;
      if (selectedClient) {
        selectedClient = JSON.stringify(selectedClient);
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.WORKOUT_SESSION_DETAILS,
          selectedClient
        );
      }

      this.props.history.push(WORKOUT_SESSION_ROUTE);
    } catch (e) {
      window.NotificationUtils.showError("Unable to access local storage");
    }
  };

  renderStartWorkoutButton = () => {
    const programId =
      this.props.selectedClient && this.props.selectedClient.programId;
    if (programId) {
      return (
        <div className="startWorkoutContainer">
          <MuiButton
            onClick={this.linkToWorkoutSession}
            variant="contained"
            color="primary"
            style={{ width: "100%", color: "#fff" }}
            startIcon={<FitnessCenterIcon />}
          >
            Start Workout
          </MuiButton>
        </div>
      );
    }
  };

  renderClientStatusCheckbox = () => {
    if (this.isEnterpriseMember && this.state.id) {
      return (
        <Row>
          <Col>
            <FormControlLabel
              style={{ marginBottom: 0 }}
              control={
                <Checkbox
                  type="checkbox"
                  id="clientStatus"
                  checked={this.state.active}
                  onChange={this.onChangeClientStatus}
                  color="primary"
                />
              }
              label={
                <div
                  style={{
                    fontSize: "12px",
                    color: "#000",
                  }}
                >
                  Set as Active
                </div>
              }
            />
          </Col>
        </Row>
      );
    }
  };

  render() {
    const inputProps = {
      placeholder: "Province",
      value: this.state.province || "",
      className: "form-control",
      onChange: this.onChangeProvince,
    };
    return (
      <React.Fragment>
        <Card className="clientDetailCard">
          <CardBody>
            <div id="topLayout">
              <div className="personImageContainer">
                <ProfileImageUploader
                  storageRef={"profilePictures"}
                  fileName={
                    this.props.selectedClient &&
                    `${this.props.selectedClient.id}_${moment().unix()}`
                  }
                  fileUrl={this.state.picURL || ""}
                  setFileUrl={this.setImageUrl}
                  containerStyle={{
                    width: 70,
                    height: 70,
                    paddingTop: 0,
                    boxShadow: "2px 2px 2px 2px #00000080",
                    border: "none",
                  }}
                  labelStyle={{ top: "38%", fontSize: 12 }}
                />
              </div>

              <FormGroup className="whiteText" style={{ flex: "0.8 1 auto " }}>
                <Input
                  className="whiteText"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name*"
                  value={this.state.name}
                  onChange={this.onChangeName}
                />
              </FormGroup>
            </div>

            <div style={{ padding: 16, maxWidth: "100%" }}>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={"Email*"}
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  autoComplete="off"
                  valid={isEmail(this.state.email || "")}
                  invalid={
                    this.state.email !== "" &&
                    isEmail(this.state.email || "") === false
                  }
                  disabled={
                    this.isEnterpriseMember
                      ? false
                      : Boolean(this.props.selectedClient.email)
                  }
                />
              </FormGroup>
              <div id="clientDetailDOB">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid>
                    <KeyboardDatePicker
                      disableFuture={true}
                      variant="inline"
                      format="YYYY/MM/DD"
                      margin="normal"
                      id="date-picker-inline"
                      value={this.state.DOB ? this.state.DOB : null}
                      onChange={this.handleDateChange}
                      openTo="year"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      inputProps={{
                        placeholder: "DOB (YYYY/MM/DD)",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <FormGroup>
                <NumberFormat
                  type="tel"
                  format="+1 ### ### ####"
                  mask=" "
                  placeholder="e.g. +1 999 999 9999"
                  customInput={Input}
                  id="phone"
                  value={this.state.phone}
                  isNumericString={true}
                  valid={this.isCellNumberValid(this.state.phone)}
                  invalid={
                    this.state.phone !== "" &&
                    this.isCellNumberValid(this.state.phone) === false
                  }
                  onChange={this.onChangePhone}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={this.state.address}
                  onChange={this.onChangeAddress}
                />
              </FormGroup>

              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                      value={this.state.city}
                      onChange={this.onChangeCity}
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      placeholder="Postal Code"
                      value={this.parsePostalCode(this.state.postal)}
                      onChange={this.onChangePostal}
                      valid={this.isPostalValid(this.state.postal)}
                      invalid={
                        this.state.postal !== "" &&
                        this.isPostalValid(this.state.postal) === false
                      }
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Autosuggest
                      suggestions={this.state.provinceSuggestions}
                      onSuggestionsFetchRequested={
                        this.onProvinceSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onProvinceSuggestionsClearRequested
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      name="membershipName"
                      id="membershipName"
                      placeholder="Program/Membership Name"
                      value={this.state.membershipName}
                      onChange={this.onChangeMembershipName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {this.renderClientStatusCheckbox()}
              {this.props.selectedClient && this.props.selectedClient.id ? (
                //TO ENABLE LATER
                <Row
                  className="displayNone"
                  style={{
                    marginTop: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <span className="clientProgramNameHeader">
                      Program Name:
                    </span>

                    <div style={{ marginLeft: 12, display: "initial" }}>
                      {this.state.loadingProgram ? (
                        <CircularProgress
                          color="primary"
                          size={20}
                          style={{
                            marginBottom: -4,
                            position: "relative",
                          }}
                        />
                      ) : (
                        this.renderProgram()
                      )}
                    </div>
                  </Col>
                </Row>
              ) : null}
              <div className="actionButtons">
                {this.state.id &&
                this.isEnterpriseMember &&
                this.state.change === false ? (
                  <Button
                    id="clientDetailAssignCoach"
                    className="btn btn-link"
                    color="primary"
                    onClick={this.openAssignCoachModal}
                  >
                    Assigned Coaches
                  </Button>
                ) : null}
                <div className="actionButtonClientDetails">
                  {this.state.id !== "" &&
                  this.state.change === false &&
                  this.props.user.tier === TIER.FREE ? (
                    <Button
                      id="clientDetailWorkoutButton"
                      className="btn btn-link"
                      color="primary"
                      onClick={this.assignWorkouts}
                    >
                      {/* Workouts */}
                      Client Details
                    </Button>
                  ) : null}
                  {this.state.id !== "" &&
                  this.state.change === false &&
                  (this.isEnterpriseMember ||
                    (this.props.user.role === ROLES.TRAINER &&
                      this.props.user.tier !== TIER.FREE)) ? (
                    <Button
                      id="clientDetailsButton"
                      className="btn btn-link"
                      color="primary"
                      onClick={this.showClientDetails}
                    >
                      Client Details
                    </Button>
                  ) : null}
                  {this.state.id && this.state.change === true ? (
                    <TAButton
                      className="clientDetailsButton"
                      color="primary"
                      onClick={this.handelUndo}
                    >
                      Undo
                    </TAButton>
                  ) : null}
                  {this.state.id === "" || this.state.change === true ? (
                    <TAButton
                      className="clientDetailsButton"
                      color="primary"
                      onClick={this.onSave}
                      isLoading={this.state.saving}
                      loadingMessage={"Saving"}
                    >
                      Save
                    </TAButton>
                  ) : null}
                  {this.state.id &&
                  this.state.pendingLogin &&
                  isEmail(this.state.email) &&
                  this.state.change === false ? (
                    <Button
                      className="btn btn-link"
                      color="primary"
                      onClick={this.onResendInvite}
                    >
                      {this.isEnterpriseMember
                        ? "Send Invite"
                        : "Resend Invite"}
                    </Button>
                  ) : null}
                  {this.state.id &&
                  this.state.programId &&
                  !this.state.pendingLogin &&
                  !(this.isEnterpriseMember || this.isTrainer) &&
                  this.state.change === false ? (
                    <Button
                      id="clientDetailWorkoutSummary"
                      className="btn btn-link"
                      color="primary"
                      onClick={this.openWorkoutSummary}
                    >
                      Workout Summary
                    </Button>
                  ) : null}
                </div>
              </div>
              {(this.isEnterpriseMember || this.isTrainer) &&
                this.renderStartWorkoutButton()}
            </div>
          </CardBody>
        </Card>
        {this.renderAssignCoachModal()}
      </React.Fragment>
    );
  }
}
