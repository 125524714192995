import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import ChipInput from "material-ui-chip-input";
import React from "react";
import { COLLECTIONS, FILE_TYPE } from "../../constants";
import { sendRequest } from "../../modules/http/HttpHelper";
import FortisFormaSelect from "../autoSuggest/dropdownSelect";
import ExerciseEditCard from "../exerciseEditCard/exerciseEditCard";
import FileUploader from "../fileUploader/fileUploader";
import FilesPicker from "../filePicker/filePicker";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import CreateEntityModal from "./createEntityModal";

const MB = 1000000;

const VIDEO_INSTUCTION = (
  <div style={{ fontSize: "14" }}>
    <span className="instructionMarginBotton">
      Dimension: 640 x 640 <br />
    </span>
    <span className="instructionMarginBotton">
      Max Duration : 45 sec <br />{" "}
    </span>
    <span className="instructionMarginBotton">
      File format : .mp4 or .MOV
      <br />
    </span>
    <span className="instructionMarginBotton">
      Max file size : 25 MB <br />
    </span>
  </div>
);

const IMAGE_INSTUCTION = (
  <div style={{ fontSize: "14px" }}>
    <span className="instructionMarginBotton">
      Dimension: 640 x 640 <br />
    </span>
    <span className="instructionMarginBotton">
      File format : .png or .jpg
      <br />
    </span>
    <span className="instructionMarginBotton">
      Max file size : 1 MB <br />
    </span>
  </div>
);

const CREATABLE = [
  "movement",
  "primaryFunction",
  "secondaryFunction",
  "tertiaryFunction",
  "primaryEquipment",
  "secondaryEquipment",
];

export default class SingleExerciseDesigner extends ExerciseEditCard {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {
        imageUploading: false,
        imageFileData: null,
        videoUploading: false,
        videoFileData: null,
        videoLoading: false,
        videoURL: "",
        posterURL: "",
        createEntityModal: false,
        createEntity: null,
        createEntityValue: null,
        showConfirmationBox: false,
      },
      this.state
    );
    this.id = new Date().getTime();
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState({
      id: this.props.data && this.props.data.id,
      videoURL: this.props.data && this.props.data.videoURL,
      posterURL: this.props.data && this.props.data.posterURL,
    });
  }

  onChangeSelect(key, selected) {
    if (selected && selected.__isNew__) {
      this.onAddEntityClick(key, selected);
    } else {
      super.onChangeSelect(key, selected);
    }
  }

  toSelectValue = (currentValue) => {
    if (!currentValue) {
      return null;
    }

    return {
      label: currentValue,
      value: currentValue,
    };
  };

  componentWillUnmount() {
    if (this.props.editMode !== true) {
      this.draft();
    }
    super.componentWillUnmount();
  }

  draft() {
    if (this.loadingInputFields) {
      return;
    }
    let exercise = this.prepareExercise();
    this.props.onDraftLevel(exercise, this.props.data.level);
  }

  getUploadingStatus = () =>
    this.state.imageUploading || this.state.videoUploading;

  updateProgress = (fileType, progress) => {
    if (fileType === FILE_TYPE.IMAGE) {
      this.setState({
        imageUploadingProgress: progress,
      });
    } else {
      this.setState({
        videoUploadingProgress: progress,
      });
    }
  };

  selectedFilesForUpload = (file, fileType) => {
    if (fileType === FILE_TYPE.IMAGE) {
      if (file[0].size > 1 * MB) {
        window.NotificationUtils.showError(
          "Poster size should be less than 1 mb"
        );
        return;
      }
      this.setState({
        imageUploading: true,
        imageFileData: file[0],
      });
    } else {
      if (file[0].size > 25 * MB) {
        window.NotificationUtils.showError(
          "Video size should be less than 25 mb"
        );
        return;
      }
      this.setState({
        videoUploading: true,
        videoFileData: file[0],
        videoLoading: true,
      });
    }
    this.uploadFile(file, fileType);
  };

  uploadFile = async (file, fileType) => {
    let ref = COLLECTIONS.EXERCISES;
    if (this.props.storageRef) {
      ref = this.props.storageRef;
    }

    let onComplete = async (downloadURL) => {
      if (fileType === FILE_TYPE.VIDEO) {
        try {
          let optimizedURL = await this.optimizeVideo(downloadURL);
          if (!optimizedURL) {
            return;
          }

          this.setState({
            videoURL: optimizedURL,
            videoLoading: false,
            videoFileData: null,
            videoUploading: false,
          });
          window.NotificationUtils.showConfirm("Video uploaded");
        } catch (e) {
          this.setState({
            videoLoading: false,
          });
          console.error(e);
        }
      } else {
        this.setState({
          posterURL: downloadURL,
          posterUploaded: true,
          imageUploading: false,
          imageFileData: null,
        });
        window.NotificationUtils.showConfirm("Poster uploaded");
      }
    };

    let config = {
      data: file[0],
      storageRefPath: (
        ref +
        "/" +
        new Date().getTime() +
        "_" +
        file[0].name
      ).toLowerCase(),
      onProgress: (progress) => {
        if (progress <= 100) {
          let update = {};
          update.uploadingProgress = `${progress.toFixed(0)}%`;
          this.updateProgress(fileType, progress);
          //this.setState(update)
        }
      },
      onError: (error) => {
        if (error.code === "storage/canceled") {
          return;
        }
        console.error(error);
      },
      onComplete: onComplete,
    };
    try {
      await window.FortisForma.database.startUploadFileTask(config);
      this.setState({
        imageLoading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  optimizeVideo = async (fileURL) => {
    let query = {
      url: fileURL,
    };
    try {
      let result = await new Promise((resolve, reject) =>
        sendRequest("trainerUpload", "post", query, resolve, {
          errorCallback: reject,
        })
      );
      return result;
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  onClickSave() {
    let exercise = this.prepareExercise();
    this.setState({
      nextPressed: true,
    });
    if (this.checkErrors(exercise, true)) {
      return;
    }
    this.setState({
      nextPressed: false,
    });
    this.props.onClickSave(exercise);
  }

  onAddEntityClick = (key, value) => {
    this.setState({
      createEntityModal: true,
      createEntity: key,
      createEntityValue: value,
    });
  };

  updateOptions = (entity, selected, data) => {
    if (entity.includes("movement")) {
      let movements = Object.assign([], this.state.movements);
      movements.push(selected);
      this.movementsMap[data.name] = data;
      this.setState({
        movements,
      });
    }
    if (entity.includes("Equipment")) {
      let equipments = Object.assign([], this.state.equipments);
      equipments.push(selected);
      this.equipmentsTypeMap[data.name] = data.type;
      this.setState({
        equipments,
      });
    }
    if (entity.includes("Function")) {
      let functions = Object.assign([], this.state.functions);
      functions.push(selected);
      this.setState({
        functions,
      });
    }
  };

  onCreateEntity = async (entity, data) => {
    if (this.props.user.enterpriseId) {
      data.enterpriseId = this.props.user.enterpriseId;
    } else {
      data.trainerId = this.props.user.id;
    }
    let collection;
    if (entity.includes("movement")) {
      collection = COLLECTIONS.MOVEMENTS;
    }
    if (entity.includes("Equipment")) {
      collection = COLLECTIONS.EQUIPMENTS;
    }
    if (entity.includes("Function")) {
      collection = COLLECTIONS.FUNCTIONS;
    }
    if (!collection) {
      return;
    }
    try {
      await window.FortisForma.database.store(collection, data);
      window.NotificationUtils.showSuccess("Saved Successfully");
      let selected = { label: data.name, value: data.name };
      this.updateOptions(entity, selected, data);
      super.onChangeSelect(entity, selected);
      this.onCloseEntityModal();
    } catch (e) {
      window.NotificationUtils.showError("Unable to save");
      console.error(e);
    }
  };

  onCloseEntityModal = () => {
    this.setState({
      createEntityModal: false,
      createEntity: null,
      createEntityValue: null,
    });
  };

  onClose = async () => {
    await this.draft();
    this.props.onClose();
  };

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationBox: false,
    });
  };

  openConfirmationDialog = () => {
    this.setState({
      showConfirmationBox: true,
    });
  };

  resetFields = () => {
    this.setState({
      movement: null,
      primaryEquipment: null,
      secondaryEquipment: null,
      tertiaryEquipment: null,
      primaryFunction: null,
      secondaryFunction: null,
      tertiaryFunction: null,
      primaryMuscle: null,
      secondaryMuscle: null,
      tertiaryMuscle: null,
      quarterlyMuscle: null,
      quinaryMuscle: null,
      reps: null,
      sets: null,
      resistance: null,
      time: null,
      weight: null,
      videoURL: "",
      posterURL: "",
    });
  };

  deleteExercise = () => {
    this.resetFields();
    this.props.onDeleteLevel(this.props.tabLevel);
    this.closeConfirmationDialog();
  };

  renderUploadAgain = (type) => {
    let extensions;
    if (type === FILE_TYPE.IMAGE) {
      extensions = ["jpg", "jpeg", "png"];
    } else {
      extensions = ["mp4", "MOV"];
    }
    return (
      <FilesPicker
        onChange={(file) => this.selectedFilesForUpload(file, type)}
        extensions={extensions}
      >
        <IconButton>
          <CloudUploadIcon color="primary" />
        </IconButton>
      </FilesPicker>
    );
  };

  renderConfirmationDialog = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={this.state.showConfirmationBox}
      >
        <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this exercise ?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={this.closeConfirmationDialog}
            color="primary"
          >
            No
          </Button>
          <Button onClick={this.deleteExercise} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const saveDisabled = this.state.imageUploading || this.state.videoUploading;
    return (
      <React.Fragment>
        {this.renderConfirmationDialog()}
        <DialogContent>
          <div id="exerciseDesignerContainer">
            <div className="exerciseDesignerFormMainContainer">
              <div id="uploaderFormContainer" style={{ marginTop: 24 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 0.4, marginRight: 16 }}>
                    <div className="uploaderNote paddingBottom">
                      <Tooltip
                        title={VIDEO_INSTUCTION}
                        arrow={true}
                        placement="left"
                      >
                        <div className="uploadingContainer">
                          <FileUploader
                            extensions={["mp4", "MOV"]}
                            text={"Upload Video"}
                            progress={this.state.videoUploadingProgress}
                            imageType={false}
                            fileURL={this.state.videoURL}
                            uploading={this.state.videoUploading}
                            videoData={this.state.videoFileData}
                            videoLoading={this.state.videoLoading}
                            fileType={FILE_TYPE.VIDEO}
                            onFileChange={this.selectedFilesForUpload}
                          />
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {this.state.videoUploading && (
                              <CircularProgress
                                size={20}
                                style={{ marginLeft: 14 }}
                              />
                            )}
                            {this.state.videoURL
                              ? this.renderUploadAgain(FILE_TYPE.VIDEO)
                              : null}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ flex: 0.6 }}>
                    <TextField
                      error={this.state.nextPressed && !this.state.name}
                      className="textFieldFullWidth marginBottom"
                      onChange={this.onChange_name}
                      label="Name"
                      variant="outlined"
                      value={this.state.name}
                    />
                    <TextField
                      className="textFieldFullWidth marginBottom"
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={4}
                      variant="outlined"
                      onChange={this.onChange_description}
                      value={this.state.description}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ flex: "0.4", marginRight: 16 }}>
                    <div className="uploaderNote paddingBottom">
                      <Tooltip
                        title={IMAGE_INSTUCTION}
                        arrow={true}
                        placement="left"
                      >
                        <div className="uploadingContainer">
                          <FileUploader
                            extensions={["jpg", "jpeg", "png"]}
                            text={"Upload Poster"}
                            progress={this.state.imageUploadingProgress}
                            imageType={true}
                            uploading={this.state.imageUploading}
                            imageData={this.state.imageFileData}
                            imageLoading={this.state.imageLoading}
                            fileURL={this.state.posterURL}
                            fileType={FILE_TYPE.IMAGE}
                            onFileChange={this.selectedFilesForUpload}
                          />
                          {this.state.imageUploading && (
                            <CircularProgress
                              size={20}
                              style={{ marginLeft: 14 }}
                            />
                          )}
                          {this.state.posterURL
                            ? this.renderUploadAgain(FILE_TYPE.IMAGE)
                            : null}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <div style={{ flex: "0.6" }}>
                    <div id="editAndMovementContainer">
                      <FormControl
                        id="movementFormControl"
                        error={this.state.nextPressed && !this.state.movement}
                      >
                        {this.state.nextPressed && !this.state.movement && (
                          <InputLabel
                            className="overlappingLabel"
                            htmlFor={"movementdown"}
                          >
                            {" "}
                            Movement is required
                          </InputLabel>
                        )}
                        <FortisFormaSelect
                          creatable={true}
                          formatCreateLabel={(input) => {
                            return (
                              <span className="dropdownAddLabel">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  style={{ marginRight: 4 }}
                                >
                                  Add
                                </Button>{" "}
                                {input}
                              </span>
                            );
                          }}
                          placeholder="Movement"
                          id="movementdown"
                          className="dropdownUnderline dropdownHeight marginBottom"
                          inputId="movement"
                          value={this.state.movement}
                          classNamePrefix="fortisFormaDropDownSelect"
                          onChange={this.onChange_movement}
                          options={this.state.movements && this.state.movements}
                        />
                      </FormControl>
                      <div id="levelAndEdit">
                        <span className="marginRight">{`Level ${
                          this.props.data && this.props.data.level
                        }`}</span>
                        <IconButton
                          onClick={() =>
                            this.props.changeLevel(
                              this.props.tabLevel,
                              this.prepareExercise()
                            )
                          }
                        >
                          <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                      </div>
                    </div>
                    <div className="twoFieldContainer">
                      <TextField
                        className="textFieldFullWidth marginBottom marginRight"
                        value={this.state.sets}
                        onChange={this.onChange_sets}
                        label="Sets"
                        variant="outlined"
                      />
                    </div>
                    <div className="twoFieldContainer">
                      <TextField
                        className="textFieldFullWidth marginBottom marginRight"
                        value={this.state.reps}
                        onChange={this.onChange_reps}
                        label="Reps"
                        variant="outlined"
                      />
                      <TextField
                        className="textFieldFullWidth marginBottom marginLeft"
                        value={this.state.weight}
                        onChange={this.onChange_weight}
                        label="Weight"
                        variant="outlined"
                      />
                    </div>
                    <div className="twoFieldContainer">
                      <TextField
                        className="textFieldFullWidth marginBottom marginRight"
                        value={this.state.time}
                        onChange={this.onChange_time}
                        label="Time"
                        variant="outlined"
                      />
                      <FortisFormaSelect
                        placeholder="Resistance"
                        id="resistancedown"
                        className="dropdownUnderline dropdownHeight textFieldFullWidth marginBottom marginLeft"
                        inputId="resistance"
                        value={this.state.resistance}
                        classNamePrefix="fortisFormaDropDownSelect"
                        onChange={this.onChange_resistance}
                        options={
                          this.state.resistanceOptions &&
                          this.state.resistanceOptions
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="shotsUploader" onClick={this.onClickSelectShots}>
                <CloudUploadIcon color="primary" />
                <span id="uploadImageText">
                  Upload Shots{" "}
                  {this.state.shots.length
                    ? ` - ${this.state.shots.length} shots uploaded`
                    : ""}
                </span>
              </div>
              {this.state.posterUploaded && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{ fontSize: 11, float: "left", marginLeft: "-8px" }}
                  >
                    <label>
                      <Checkbox
                        id="posterAsShot"
                        color={"primary"}
                        checked={this.state.posterAsShot}
                        onChange={this.handlePosterAsShot}
                      />
                      <span> Use poster as the first Shot</span>
                    </label>
                  </div>
                </div>
              )}

              <div style={{ width: "100%" }}>
                <div className="flexWrapClass">
                  {this.autoSuggestFields.map((field, index) => {
                    if (
                      field.name.includes("movement") ||
                      field.name.includes("resistance")
                    ) {
                      return null;
                    }

                    if (field.type === "label") {
                      return (
                        <React.Fragment key={index}>
                          <Divider className="dividerFullwidth" />
                          <div style={{ width: "100%", marginBottom: "16px" }}>
                            <Typography variant="h5">{field.name}</Typography>
                          </div>
                        </React.Fragment>
                      );
                    }
                    let source = null;

                    if (!source) {
                      source = field.name.includes("Equipment")
                        ? this.state.equipments
                        : null;
                    }

                    if (!source) {
                      source = field.name.includes("Muscle")
                        ? this.state.muscles
                        : null;
                    }

                    if (!source) {
                      source = field.name.includes("Function")
                        ? this.state.functions
                        : null;
                    }

                    return (
                      <React.Fragment key={index}>
                        <FormControl>
                          <FortisFormaSelect
                            creatable={CREATABLE.includes(field.name)}
                            formatCreateLabel={(input) => {
                              return (
                                <span className="dropdownAddLabel">
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    style={{ marginRight: 4 }}
                                  >
                                    Add
                                  </Button>{" "}
                                  {input}
                                </span>
                              );
                            }}
                            placeholder={field.placeholder}
                            id={field.name}
                            className="dropdownUnderline dropdownHeight marginBottom marginRight fixedWidth"
                            inputId={field.name}
                            value={this.state[field.name]}
                            classNamePrefix="fortisFormaDropDownSelect"
                            onChange={this["onChange_" + field.name]}
                            options={source}
                          />
                        </FormControl>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>

              <Divider className="dividerFullwidth" />

              <div style={{ width: "100%" }}>
                {/* <div className="subHeading">Alternate Names</div> */}
                <div style={{ width: "100%", marginBottom: "16px" }}>
                  <Typography variant="h5">Alternate Names</Typography>
                </div>
                <ChipInput
                  id="alternateNames"
                  className="alternateNamesContainer"
                  placeholder="Press `Enter` to add alternate name"
                  disableUnderline={true}
                  value={this.state.alternateNames}
                  onAdd={(chip) => this.handleAddChip(chip)}
                  onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                />
              </div>
            </div>
          </div>
          <Dialog id="createEntityModal" open={this.state.createEntityModal}>
            <CreateEntityModal
              entity={this.state.createEntity}
              entityValue={this.state.createEntityValue}
              onClose={this.onCloseEntityModal}
              onCreate={this.onCreateEntity}
            />
          </Dialog>
        </DialogContent>

        <DialogActions id="addExerciseModal">
          <div id="addExerciseAction">
            {this.props.data.id && (
              <IconButton
                onClick={this.openConfirmationDialog}
                size="small"
                style={{ marginRight: 8, padding: 8 }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
            <Button
              className="marginRight"
              variant="contained"
              color="secondary"
              onClick={this.onClose}
            >
              Close
            </Button>
            <Button
              disabled={saveDisabled}
              variant="contained"
              color="primary"
              onClick={this.onClickSave}
            >
              Save
            </Button>
          </div>
        </DialogActions>
        {this.renderFileUploader()}
      </React.Fragment>
    );
  }
}
