import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import MovementEditCard from "./movementEditCard";
import EditorPanel from "../../components/editorPanel/editorPanel.js";
import MovementFilters from "../../components/filters/movementFilters";
import { COLLECTIONS, DB_KEYS, ROLES } from "../../constants";
import { CircularProgress } from "@material-ui/core";

export default class MovementsEditor extends EditorPanel {
  constructor(props) {
    super(props);
    this.FilterClass = MovementFilters;
    this.rootClassName = "exerciseEditor";
    this.state = Object.assign(
      {
        title: "Movement Editor",
        movementCategories: [],
        showChangeModal: false,
        otherMovement: {},
      },
      this.state
    );

    this.onClickCancel = this.onClickCancel.bind(this);
  }

  _filter(item, filters) {
    let include = super._filter(item, filters);
    if (filters.category) {
      // If user is searching with text then both should match, otherwise type match is enough
      if (filters.text)
        return include && item.movementCategory === filters.category;
      else return item.movementCategory === filters.category;
    } // if no type selected then super can handle text matching
    return include;
  }

  componentDidMount() {
    this.fetchData();
    this.fetchMovementCategories();
  }

  async fetchData(query) {
    if (!query) {
      query = {};
    }

    query.collection = COLLECTIONS.MOVEMENTS;
    if (this.state.filters) {
      query.filters = this.state.filters;
    } else query.filters = [];
    
    query.filters = [{
      key: DB_KEYS.TRAINER_ID_KEY,
      operator: "==",
      value: DB_KEYS.ADMIN
    }].concat(query.filters);

    query.pageConfig = {};

    if (this.state.lastFetched) {
      query.pageConfig.orders = [
        {
          key: "name",
          after: this.state.lastFetched.name,
        },
      ];
    } else {
      query.pageConfig.orders = [
        {
          key: "name",
        },
      ];
    }

    try {
      let results = await window.FortisForma.database.queryData(query);
      this.setState({
        lastFetched: results[results.length - 1],
        data: this.state.data.concat(results),
      });
      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async fetchMovementCategories(query) {
    if (!query) {
      query = {};
    }

    query.collection = COLLECTIONS.MOVEMENT_CATEGORIES;
    query.filters = this.state.filters;

    query.pageConfig = {};

    if (this.state.lastFetched) {
      query.pageConfig.orders = [
        {
          key: "name",
        },
      ];
    } else {
      query.pageConfig.orders = [
        {
          key: "name",
        },
      ];
    }

    try {
      let results = await window.FortisForma.database.queryData(query);
      results = results.map((category) => {
        return category.name;
      });
      results = window._.uniq(results);
      results = results.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      this.filterProps = {
        categories: results,
      };
      this.setState({
        movementCategories: this.state.movementCategories.concat(results),
      });
      this.filterProps = {
        categories: results,
      };
      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async save(item) {
    this.setState({
      selected: null,
    });
    if (this.props.user.role === ROLES.ADMIN) {
      item[DB_KEYS.TRAINER_ID_KEY] = "admin";
    }
    try {
      let results = await window.FortisForma.database.storeMovement(item);
      let data = Object.assign([], this.state.data);
      let index = window._.findIndex(data, (i) => i.id === results.id);
      if (index < 0) {
        index = data.length;
      }
      data[index] = results;
      this.setState({
        data: data,
      });
      window.NotificationUtils.showSuccess(`Saved ${item.name} successfully`);
    } catch (e) {
      window.NotificationUtils.showSuccess(`Unable to save ${item.name}`);
      throw e;
    }
  }

  mapRowToDocument(row) {
    let document = Object.assign({}, row);
    if (
      !document.movementName ||
      !document.movementId ||
      !document.movementCategory
    ) {
      throw new Error("Missing values in row");
    }

    if (isNaN(document.movementId)) {
      throw new Error(
        `Invalid movement id ${document.movementId}} for movement ${document.movementName}`
      );
    }
    return {
      name: document.movementName,
      movementId: Number(document.movementId),
      movementCategory: document.movementCategory,
    };
  }

  async bulkSave(docs) {
    if (this.props.user.role === ROLES.ADMIN) {
      for (let data of docs) {
        data[DB_KEYS.TRAINER_ID_KEY] = "admin";
      }
    }
    try {
      let results = await window.FortisForma.database.bulkUploadMovements(docs);
      let data = Object.assign([], this.state.data);
      data = data.concat(results);
      this.setState({
        data: data,
      });
    } catch (e) {
      throw e;
    }
  }

  onSelect = (item) => {
    super.onSelect(item);
    this.navigate();
  };

  getExercisesForMovement = async (data) => {
    try {
      return window.FortisForma.database.getExercisesWithProperty(
        DB_KEYS.MOVEMENT,
        data.name
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(
        "Unable to fetch exercises with this movement"
      );
    }
  };

  onChangeMovement = () => {
    this.showMovementChangeModal();
  };

  showMovementChangeModal = () => {
    this.setState({
      showChangeModal: true,
    });
  };

  hideMovementChangeModal = () => {
    this.setState({
      showChangeModal: false,
    });
  };

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
  };

  onSelectOtherMovement = (item) => {
    this.setState({
      otherMovement: item,
    });
  };

  onReplaceMovement = async () => {
    this.setState({
      loading: true,
    });
    if (!this.state.otherMovement || !this.state.selected) {
      return;
    }
    if (this.state.otherMovement.id === this.state.selected.id) {
      return;
    }
    try {
      await window.FortisForma.database.replaceMovementFromExercises(
        this.state.selected,
        this.state.otherMovement
      );
      window.NotificationUtils.showSuccess("Movement replaced successfully");

      this.hideMovementChangeModal();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to replace movement");
    }
    this.setState({
      loading: false,
    });
  };

  renderMovementChange = () => {
    return (
      <div className="movementChangeLeft">
        <h5>Select Movement</h5>
        <div className="movementChangeContainer">
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    this.state.otherMovement.id === item.id
                      ? "sMovementChangeItem"
                      : "movementChangeItem"
                  }
                  onClick={() => this.onSelectOtherMovement(item)}
                >
                  {item.name}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div
        className="loaderContainer"
        style={{ paddingTop: 32, paddingBottom: 32 }}
      >
        <CircularProgress />
      </div>
    );
  };

  renderMovementChangeModal() {
    return (
      <Modal isOpen={this.state.showChangeModal} backdrop={true}>
        {this.state.loading ? (
          this.renderLoading()
        ) : (
          <React.Fragment>
            <ModalBody className="flexRow">
              {this.renderMovementChange()}
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn btn-outline-secondary"
                onClick={this.hideMovementChangeModal}
              >
                Cancel
              </Button>{" "}
              {this.state.otherMovement && (
                <Button color="primary" onClick={this.onReplaceMovement}>
                  Done
                </Button>
              )}
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
    );
  }

  renderContent() {
    return (
      <React.Fragment>
        <Row
          className="editorPanelContainer"
          style={{ alignItems: "center", marginBottom: 16 }}
        >
          <Col
            id="movementCategoryList"
            lg="7"
            className="listContainer editorPanelList"
          >
            {!this.state.data.length && this.state.filtered
              ? this.emptyPlaceholder()
              : null}

            {this.state.data.map((item) => {
              return (
                <Card
                  className="admin-component-card margin-zero"
                  key={item.id}
                  onClick={() => {
                    this.onSelect(item);
                  }}
                >
                  <CardBody className="admin-component-body padding-zero">
                    {item.name}
                    <div className="editIconContainer">
                      <i className="editIcon icon material-icons">edit</i>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
          <Col lg="5" className="clientDetailContainer">
            {this.state.selected !== null ? (
              <MovementEditCard
                onChangeMovement={this.onChangeMovement}
                onDelete={this.onDelete}
                collection={COLLECTIONS.MOVEMENTS}
                placeholder={"Ex. Arm Circles"}
                movementCategories={this.state.movementCategories}
                data={this.state.selected}
                onClickCancel={this.onClickCancel}
                onClickSave={this.save}
                getExercisesForMovement={this.getExercisesForMovement}
              ></MovementEditCard>
            ) : null}
          </Col>
        </Row>
        <div
          hidden={this.state.selected === null}
          className="screenNavigationHelperButton"
          onClick={this.navigate}
        >
          {this.navigationIcon()}
        </div>
        {this.renderMovementChangeModal()}
      </React.Fragment>
    );
  }
}
