import React from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import SendMessageModal from "../../components/modals/sendMessageModal";
import { PENDING_CLIENT_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import Joyride from "react-joyride";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";

export default class PendingClientDetails extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      modalState: false,
      walkthrough: false,
      steps: PENDING_CLIENT_WALKTHROUGH.steps,
    };
    this.sendMessageRef = React.createRef();
  }

  openMessageModal = () => {
    this.setState({
      modalState: true,
    });
  };

  closeMessageModal = () => {
    this.setState({
      modalState: false,
    });
    this.sendMessageRef.current.resetMessage();
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          showSkipButton={true}
          locale={{ last: "Done" }}
        />
      </div>
    );
  };

  /*
comments: "Test"
consistency: "2-4 days a week"
email: "wikafej692@swanticket.com"
id: "AsW7f8KFvMgrK5cWtuIG"
motive: ["Be held accountable"]
name: "Mukul"
phone: ""
postal: ""
requiredService: ["In-Home"]
*/
  render() {
    let services = (this.props.selectedClient.requiredService || ["-"]).join(
      ", "
    );
    let motive = (this.props.selectedClient.motive || ["-"]).join(", ");
    return (
      <React.Fragment>
        {this.state.walkthrough && this.renderJoyride()}
        <div className="requestModalContainer">
          <Card style={{ width: "80%" }} id="serviceClientDetails">
            <CardBody>
              <Table bordered>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{this.props.selectedClient.name || "-"}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{this.props.selectedClient.email || "-"}</td>
                  </tr>
                  <tr>
                    <th>Motive</th>
                    <td>{motive}</td>
                  </tr>
                  <tr>
                    <th>Services</th>
                    <td>{services}</td>
                  </tr>
                  <tr>
                    <th>Consistency</th>
                    <td>{this.props.selectedClient.consistency || "-"}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{this.props.selectedClient.city || "-"}</td>
                  </tr>
                  <tr>
                    <th>Postal Code</th>
                    <td>{this.props.selectedClient.postal || "-"}</td>
                  </tr>
                  <tr>
                    <th>Message</th>
                    <td>{this.props.selectedClient.comments || "-"}</td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ marginTop: 16 }}>
                <div className="messageButton">
                  <Button
                    id="sendMessageButton"
                    className="btn btn-link"
                    color="primary"
                    onClick={this.openMessageModal}
                  >
                    Message
                  </Button>
                </div>
              </div>
              <div>
                <SendMessageModal
                  ref={this.sendMessageRef}
                  clientName={this.props.selectedClient.name}
                  modalState={this.state.modalState}
                  closeModal={this.closeMessageModal}
                  sendMessage={this.props.sendMessage}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
