import React from "react";
import { Avatar, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import renderHTML from "react-render-html";

const ChallengeCardContainer = styled.div`
  height: 470px;
  margin-top: 12px;
`;

const StyledChallengeCard = styled.div`
  margin: 0 10px;
  width: 320px;
  height: 470px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const ChallengeProfileTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledAvatar = styled(Avatar)`
  width: 100px !important;
  height: 70px !important;
  border-radius: 4px !important;
  object-fit: contain;
`;
const ContentContainer = styled.div`
  margin-left: 16px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;
const StyledTypography = styled(Typography)`
  color: grey;
`;

const ChallengeCardButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ThingsToKnow = styled.div`
  font-size: 0.6666666666666666rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  max-height: 122px;
  overflow-y: scroll;

  & > ul {
    padding-left: 18px;
  }
`;

const MainContentContainer = styled.div`
  overflow-y: scroll;
`;

export default function ChallengeCard(props) {
  function onClickTry() {}

  function onClickStart() {}

  function renderThingsToKnow() {
    return (
      <ThingsToKnow>{renderHTML(props.data.prerequisites || "")}</ThingsToKnow>
    );
  }

  return (
    <ChallengeCardContainer>
      <StyledChallengeCard>
        <ChallengeProfileTopContainer>
          <StyledAvatar
            alt={props.data.name}
            src={props.data.picURL}
            variant="square"
          />
          <ContentContainer>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              {props.data.name}
            </Typography>
            <Typography
              variant="caption"
              style={{ color: "grey", marginBottom: 8 }}
            >
              {props.data.value}
            </Typography>
          </ContentContainer>
        </ChallengeProfileTopContainer>
        <MainContentContainer>
          <Section>
            <AlignCenter>
              <StyledTypography variant="caption">
                About the Challenge
              </StyledTypography>
            </AlignCenter>
            <AlignCenter>
              <Typography variant="caption">{props.data.about}</Typography>
            </AlignCenter>
          </Section>
          <Section>
            <AlignCenter>
              <StyledTypography variant="caption">
                Things you may want to know
              </StyledTypography>
            </AlignCenter>
            {renderThingsToKnow()}
          </Section>
        </MainContentContainer>
        <ChallengeCardButtons>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 16 }}
            size="small"
            onClick={onClickTry}
            disabled={true}
          >
            Try Phase 1 for Free
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
            size="small"
            onClick={onClickStart}
            disabled={true}
          >
            {"Start Now"}
            {props.data.price ? ` for $${props.data.price}` : ""}
          </Button>
        </ChallengeCardButtons>
      </StyledChallengeCard>
    </ChallengeCardContainer>
  );
}
