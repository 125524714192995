import { CircularProgress, Collapse, DialogContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ReactPlayer from "react-player";
import EditExerciseDetail from "../workoutSession/editExerciseDetail";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExerciseFeedback from "../workoutSession/exerciseFeedback";

const FEEDBACK_DATA = {
  difficulty: "",
  painLevel: 0,
  feedbackText: "",
};
export default class ExerciseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Object.assign({}, this.props.data),
      feedbackData: Object.assign({}, this.props.feedbackLog) || FEEDBACK_DATA,
      showLoading: false,
      showDescription: false,
      isComplete: false,
    };
    this.exerciseDetailRef = React.createRef();
    this.feedbackRef = React.createRef();
  }

  // componentDidMount() {
  //     if (this.props) {
  //         if (this.props.feedbackLog !== undefined) {
  //             let feedbackData = {
  //                 selectedDifficulty: this.props.feedbackLog.difficulty || "",
  //                 painLevel: this.props.feedbackLog.painLevel || 0,
  //                 feedbackText: this.props.feedbackLog.feedbackText || ""
  //             };
  //             let data = this.props.data && Object.assign({}, this.props.data);
  //             this.setState({
  //                 feedbackData: feedbackData,
  //                 showLoading: false,
  //                 data: data,
  //                 isComplete: Boolean(this.props.isComplete)
  //             }, () => {
  //                 this.playerRef && this.playerRef.showPreview();
  //             });
  //             this.feedbackRef.current && this.feedbackRef.current.reset(feedbackData);
  //         }
  //     }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let data = this.props.data && Object.assign({}, this.props.data);
      this.setState(
        {
          // feedbackData: feedbackData,
          showLoading: false,
          data: data,
          isComplete: Boolean(this.props.isComplete),
        },
        () => {
          this.playerRef && this.playerRef.showPreview();
        }
      );
    }
    if (this.props.feedbackLog && this.props.data.id !== prevProps.data.id) {
      let feedbackData = {
        difficulty: this.props.feedbackLog.difficulty || "",
        painLevel: this.props.feedbackLog.painLevel || 0,
        feedbackText: this.props.feedbackLog.feedbackText || "",
      };
      this.setState({
        feedbackData: feedbackData,
      });
      this.feedbackRef.current && this.feedbackRef.current.reset(feedbackData);
    } else if (
      !this.props.feedbackLog &&
      this.props.data.id !== prevProps.data.id
    ) {
      let feedbackData = {
        difficulty: "",
        painLevel: 0,
        feedbackText: "",
      };
      this.setState({
        feedbackData: feedbackData,
      });
      this.feedbackRef.current && this.feedbackRef.current.reset(feedbackData);
    }
  }

  changeInExerciseDetail = (exerciseDetail) => {
    this.setState({ data: exerciseDetail });
  };

  onDonePress = (removeLog = false) => {
    let params = this.prepareParameters();
    params.exercise.log.difficulty = this.state.feedbackData.difficulty;
    params.exercise.log.painLevel = this.state.feedbackData.painLevel;
    params.exercise.log.feedbackText = this.state.feedbackData.feedbackText;
    this.props.onClose();
    if (removeLog === true) {
      this.props.onRemoveLog(params.exercise, params.data);
      return;
    }
    this.props.onDonePress(params.exercise, params.data);
  };

  prepareParameters() {
    let exercise = {};
    exercise.logId = this.props.logId;
    exercise.name = this.state.data.name;
    exercise.index = this.props.index;
    exercise.log = {};
    let details = this.exerciseDetailRef.current.getDetails();
    exercise.log.sets = details.sets || "";
    exercise.log.reps = details.reps || "";
    exercise.log.time = details.time || "";
    exercise.log.weight = details.weight || "";
    exercise.log.resistance = details.resistance || "";
    return { exercise, data: this.state.data };
  }

  toggleCollapse() {
    this.setState({
      showDescription: !this.state.showDescription,
    });
  }

  renderExerciseCard() {
    if (!this.state.data) {
      return null;
    }
    return (
      <div id="exerciseCardMainContainer">
        <div className="displayFlex" style={{ position: "relative" }}>
          <DialogContent id="exerciseCardContentDetail">
            {this.renderCardMedia()}
            {this.renderCardContent()}
          </DialogContent>
        </div>
      </div>
    );
  }

  renderDoneButton() {
    const { isComplete, disabled } = this.props;
    return (
      <div id="doneButton">
        <Button
          color="primary"
          fullWidth={true}
          variant="contained"
          onClick={() => this.onDonePress(isComplete)}
          size="large"
          disabled={disabled}
        >
          {isComplete ? "Remove Log" : "Save Log"}
        </Button>
      </div>
    );
  }

  renderCancelButton() {
    return (
      <div id="cancelButton">
        <Button
          color="primary"
          fullWidth={true}
          variant="outlined"
          onClick={this.props.onClose}
          size="large"
        >
          Cancel
        </Button>
      </div>
    );
  }

  onChangeFeedbackData = (key, value) => {
    let stateFeedbackData = Object.assign({}, this.state.feedbackData);
    stateFeedbackData[key] = value;
    this.setState({
      feedbackData: stateFeedbackData,
    });
  };

  renderExerciseFeedback() {
    return (
      <ExerciseFeedback
        ref={this.feedbackRef}
        data={this.state.feedbackData}
        onChangeDifficultyLevel={this.onChangeFeedbackData}
        onChangeFeedbackText={this.onChangeFeedbackData}
        onChangePainSlider={this.onChangeFeedbackData}
        disabled={this.props.disabled}
      />
    );
  }

  renderLoading = () => {
    if (this.state.showLoading) {
      return (
        <div style={{ position: "absolute" }}>
          <CircularProgress />
        </div>
      );
    }
  };

  renderNoVideoPlaceholder = () => {
    let exerciseCardContent = this.state.data;
    if (!exerciseCardContent.posterURL && !exerciseCardContent.videoURL) {
      return (
        <div className="noVideoPlaceholder">
          <Typography variant="h4">No video</Typography>
        </div>
      );
    }
  };

  getPosterUrl = () => {
    if (this.state.data.posterURL) {
      return `url(${CSS.escape(this.state.data.posterURL)})`;
    }
  };

  getPosterStyle = () => {
    return {
      backgroundImage: this.getPosterUrl(),
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  };

  renderVideoPlayer = () => {
    let exerciseCardContent = this.state.data;
    if (exerciseCardContent.posterURL || exerciseCardContent.videoURL) {
      return (
        <>
          <ReactPlayer
            ref={(ref) => {
              this.playerRef = ref;
            }}
            style={!exerciseCardContent.videoURL ? this.getPosterStyle() : null}
            onPlay={() => {
              this.setState({ showLoading: false });
            }}
            onClickPreview={() => this.setState({ showLoading: true })}
            width={"100%"}
            id="mainVideoPlayer"
            autoPlay={true}
            playing={true}
            playsinline={false}
            muted={true}
            loop={true}
            controls={false}
            url={exerciseCardContent.videoURL}
            height={"550px"}
            light={CSS.escape(exerciseCardContent.posterURL) || true}
          />
        </>
      );
    }
  };

  renderCardMedia() {
    return (
      <div className={this.state.showLoading ? "mainVideoLoaderContainer" : ""}>
        {this.renderLoading()}
        {this.renderVideoPlayer()}
        {this.renderNoVideoPlaceholder()}
      </div>
    );
  }

  renderExerciseDescription() {
    let exerciseCardContent = this.state.data;
    return (
      exerciseCardContent.description && (
        <div style={{ maxWidth: 600 }}>
          <div
            className="marginTop16 pointer noselect justifyContentSpaceBetween"
            style={{ display: "flex" }}
            onClick={(e) => this.toggleCollapse()}
          >
            <Typography
              component="span"
              className="fontLarger fw-600"
              variant="body2"
            >
              Description
            </Typography>
            <IconButton
              size="small"
              style={{ marginTop: -2, marginLeft: 4 }}
              onClick={(e) => this.toggleCollapse()}
            >
              {this.state.showDescription ? (
                <ExpandLessIcon color="primary" />
              ) : (
                <ExpandMoreIcon color="primary" />
              )}
            </IconButton>
          </div>
          <Collapse in={this.state.showDescription} timeout="auto">
            <Typography className="textColorGray fontLarger" variant="body2">
              {exerciseCardContent.description}
            </Typography>
          </Collapse>
        </div>
      )
    );
  }

  renderCardContent() {
    let exerciseCardContent = this.state.data;
    return (
      <div className="exerciseCardContentContainer">
        <Typography className="fw-600 font-larger" variant="h6">
          {exerciseCardContent.name}
        </Typography>
        <div id="levelAndTimerContainer">
          <Typography
            className="mgBottom12 fontLarger fw-600"
            variant="body2"
            color="primary"
          >
            Level {exerciseCardContent.level}
          </Typography>
        </div>
        {this.renderExerciseDescription()}
        <EditExerciseDetail
          ref={this.exerciseDetailRef}
          exerciseDetail={this.state.data}
          changeInExerciseDetail={this.changeInExerciseDetail}
          disabled={this.props.disabled}
        />
        {this.renderExerciseFeedback()}
        {this.renderDoneButton()}
        {this.renderCancelButton()}
      </div>
    );
  }

  render() {
    return <React.Fragment>{this.renderExerciseCard()}</React.Fragment>;
  }
}
