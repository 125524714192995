import React, { Component } from 'react'
import { FilePicker } from './filePickerSrc';

export default class FilesPicker extends Component {
    render() {
        return (
            <FilePicker
                extensions={this.props.extensions}
                dims={{ minWidth: 100, minHeight: 100 }}
                onChange={FileObject => this.props.onChange(FileObject)}
                onError={errMsg => console.error(errMsg)}
                multiple={true}
            >
                {this.props.children}
            </FilePicker>
        )
    }
}