import React, { Component } from "react";
import { Typography, Button, Collapse, IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default class TrainerPrograms extends Component {
  state = {
    collapsed: {},
  };

  toggleSection = (pIndex, index) => {
    let collapsed = Object.assign([], this.state.collapsed);
    if (!collapsed[pIndex]) {
      collapsed[pIndex] = {};
    }
    collapsed[pIndex][index] = !Boolean(collapsed[pIndex][index]);
    this.setState({
      collapsed,
    });
  };

  renderPackage(pack, index, pIndex) {
    return (
      <div key={index} className="trainerPackage">
        <div className="pricingContainer">
          <div className="packageNameContainer">
            <Typography
              variant="body1"
              color="primary"
              className="trainerProgramNameContainer"
            >
              {pack.name}
            </Typography>
            {this.state.collapsed[pIndex] &&
            this.state.collapsed[pIndex][index] ? (
              <IconButton
                size="small"
                onClick={() => this.toggleSection(pIndex, index)}
              >
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={() => this.toggleSection(pIndex, index)}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </div>
          <Collapse
            in={
              Boolean(
                this.state.collapsed[pIndex] &&
                  this.state.collapsed[pIndex][index]
              ) || false
            }
            timeout="auto"
          >
            <div className="flexRow programPrice">
              <Typography variant="h5" className="boldFont">
                ${pack.price}
              </Typography>
              {pack.per && (
                <Typography variant="caption" className="letterSpacingInc">
                  /{pack.per}
                </Typography>
              )}
              {Boolean(pack.extras && pack.extras.length) &&
                pack.extras.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="caption"
                      className="letterSpacingInc"
                    >
                      +{item}
                    </Typography>
                  );
                })}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }

  renderProgram(program, index) {
    return (
      <div key={index} className="trainerProgram">
        <div className="programsHeader">
          <Typography variant="subtitle2" className="boldFont">
            {program.name}
          </Typography>
        </div>

        <Typography variant="body2" className="greyColor">
          {program.description}
        </Typography>

        {this.renderPackages(program, index)}
      </div>
    );
  }

  renderPackages(program, pIndex) {
    if (!program.packages || !program.packages.length) {
      return null;
    }

    return (
      <>
        <Typography
          variant="caption"
          color="initial"
          style={{ marginTop: 16, display: "inline-block" }}
        >
          Service Variations and Price
        </Typography>
        {program.packages.map((pack, index) => {
          return this.renderPackage(pack, index, pIndex);
        })}
      </>
    );
  }
  render() {
    return (
      <div className="trainerCard trainerCardRadius">
        <div className="trainerCardContentContainer">
          <Typography
            hidden={Boolean(this.props.isStandAlone)}
            variant="subtitle2"
            className="trainerInfoTitle"
          >
            Services Offered
          </Typography>
          {this.props.selectedTrainer &&
            this.props.selectedTrainer.programs &&
            this.props.selectedTrainer.programs.length &&
            this.props.selectedTrainer.programs.map((program, index) => {
              return this.renderProgram(program, index);
            })}
        </div>
        <div className="trainerProfileButtons">
          {!this.props.isStandAlone && (
            <Button
              variant="contained"
              color="primary"
              className="mg-tp-16"
              size="small"
              onClick={this.props.goBack}
            >
              Back
            </Button>
          )}

          <Button
            variant="contained"
            className="mg-tp-16"
            size="small"
            onClick={this.props.onClose}
            color="secondary"
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
}
