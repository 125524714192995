import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { GetApp } from "@material-ui/icons";
import Receipt from "../../views/receipt/receipt";
import Logo from "../../assets/logo-white.png";
import { saveAs } from "file-saver";
import { pdf, PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import MyDocument from "../../views/receipt/receipt";
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import { BubbleLoader } from "react-css-loaders";

function createData(date, description, total, id) {
  return { date, description, total, id };
}

// const rows = [
//   createData(
//     "23/05/2020",
//     "Monthly Subscription to Enterprise Solution",
//     3.7,
//     3
//   ),
//   createData(
//     "25/05/2020",
//     "Monthly Subscription to Private Practice Solution",
//     25.0,
//     4
//   ),
//   createData(
//     "26/05/2020",
//     "Yearly Subscription to Private Practice Solution",
//     16.0,
//     5
//   ),
//   createData(
//     "28/05/2020",
//     "Yearly Subscription to Private Practice Solution",
//     6.0,
//     6
//   ),
//   createData(
//     "28/05/2020",
//     "Monthly Subscription to Private Practice Solution",
//     16.0,
//     7
//   ),
//   createData(
//     "29/05/2020",
//     "Yearly Subscription to Enterprise Solution",
//     3.2,
//     13
//   ),
//   createData(
//     "30/04/2020",
//     "Monthly Subscription to Private Practice Solution",
//     9.0,
//     8
//   ),
//   createData(
//     "20/05/2020",
//     "Yearly Subscription to Private Practice Solution",
//     0.0,
//     9
//   ),
//   createData(
//     "21/05/2020",
//     "Monthly Subscription to Private Practice Solution",
//     26.0,
//     10
//   ),
//   createData("18/05/2020", "Monthly Subscription to Enterprise Solution", 0.2),
//   createData(
//     "17/05/2020",
//     "Monthly Subscription to Private Practice Solution",
//     0,
//     11
//   ),
//   createData(
//     "16/05/2020",
//     "Yearly Subscription to Private Practice Solution",
//     19.0,
//     12
//   ),
//   createData(
//     "11/05/2020",
//     "Monthly Subscription to Enterprise Solution",
//     18.0,
//     13
//   ),
// ];
const data = { something: "somethingggg" };
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  { id: "Total", numeric: true, disablePadding: false, label: "Total" },
  //   { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
  //   { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
];

// const handleReceiptGeneration = (data) => {
//   const doc = new jsPDF();
//   let encodeImageUrl = '';
//   await encodeImage(imageUrl).then((dataUrl) => {
//     encodeImageUrl = dataUrl;
//   });
//   doc.addImage(Logo, "PNG", 60, 10, 90, 20);
//   doc.save("receipt.pdf");
// };

const generatePdfDocument = async (
  documentData = [1, 2, 3],
  cb,
  fileName = "test.pdf"
) => {
  //   const blob = await pdf(
  //     <MyDocument
  // title='My PDF'
  // pdfDocumentData={documentData}
  //     />
  //   ).toBlob();
  //   saveAs(blob, `${documentData}.pdf`);

  //   <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
  //     {({ blob, url, loading, error }) =>
  //       loading ? "Loading document..." : "Download now!"
  //     }
  //   </PDFDownloadLink>;
  return (
    <PDFDownloadLink document={<MyDocument data={data} />}>
      {({ blob, url, loading, error }) => !loading && cb(blob, "index")}
    </PDFDownloadLink>
  );
  //   const arr = documentData.map((doc, index) => {
  //     return (
  //       <PDFDownloadLink document={<MyDocument data={data} />}>
  //         {({ blob, url, loading, error }) => !loading && cb(blob, index)}
  //       </PDFDownloadLink>
  //     );
  //   });
  //   console.log("arararyra", arr);
  //   console.log("arararyra");
};

const SomeComponent = () => (
  <div>
    <a href={this.state.url} download={`document.pdf`}>
      Download
    </a>
    <MyDocument
      onRender={({ blob }) => {
        this.setState({ url: URL.createObjectURL(blob) });
      }}
    ></MyDocument>
  </div>
);

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={classes.sortLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    color: "#800520",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, subData } = props;
  // console.log("propssssssssss", props);

  // let invoiceNo = "";
  // let receiptNo = "";
  // let datePaid = "";
  // let accountName = "";
  // let cusName = "";
  // let cusEmail = "";
  // let subTotal = "";
  // let discount = "";
  // let tax = "";
  // let total = "";
  // let basePrice = "";
  // let prodDescription = "";
  // let startDate = "";
  // let endDate = "";
  // let isCharge = false;
  // let chargeId = null;
  // let charge = null;
  // let paymentMethod = null;
  // let card = null;

  // subData.forEach((value) => {
  //   const { data } = value;
  //   if (value.id === props.data[0]) {
  //     invoiceNo = data.invoiceNo;
  //     receiptNo = data.receiptNo;
  //     datePaid = moment(data.datePaid * 1000).format("ll");
  //     accountName = data.accountName;
  //     cusName = data.cusName;
  //     cusEmail = data.cusEmail;
  //     subTotal = data.subTotal;
  //     discount = data.discount;
  //     tax = data.tax;
  //     total = data.total;
  //     basePrice = data.basePrice;
  //     prodDescription = data.prodDescription;
  //     startDate = data.startDate;
  //     endDate = data.endDate;

  //     if (data.chargeId) {
  //       isCharge = true;
  //       chargeId = data.chargeId;
  //     }
  //   }
  // });

  const generatePDF = async (pdfData) => {
    let doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
    });

    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    let margin = 20;
    let logoWidth = 92;
    let logoHeight = 42.4;
    for (const data of pdfData) {
      // pdfData.forEach(async (data) => {
      // const data = pdfData;
      // let invoiceNo = data.invoice;
      // let receiptNo = data.receiptNo;
      // let datePaid = moment(data.datePaid * 1000).format("ll");
      // let accountName = data.accountName;
      // let cusName = data.customer_name;
      // let cusEmail = data.cusEmail;
      // let subTotal = (data.subTotal / 100).toFixed(2);
      // let discount = (data.discount / 100).toFixed(2);
      // let tax = (data.tax / 100).toFixed(2);
      // let total = (data.total / 100).toFixed(2);
      // let basePrice = (data.basePrice / 100).toFixed(2);
      // let prodDescription = data.prodDescription;
      // let startDate = moment(data.startDate * 1000).format("ll");
      // let endDate = moment(data.endDate * 1000).format("ll");
      // let isCharge = false;
      // let chargeId = null;
      // let charge = null;
      // let paymentMethod = null;
      // let card = null;
      // =========================== For Singe entry

      let invoiceNo = data.invoice;
      let receiptNo = data.id;
      let datePaid = moment(data.start_date).format("ll");
      let accountName = data.customer_name;
      let cusName = data.customer_name;
      let cusEmail = data.customer_email;
      let subTotal = data.amount;
      let discount = (data.discount / 100).toFixed(2);
      let tax = (data.tax / 100).toFixed(2);
      let total = (Number(subTotal) - Number(discount) + Number(tax)).toFixed(
        2
      );
      let basePrice = data.amount.toFixed(2);
      let prodDescription = data.description;
      let startDate = moment(data.start_date).format("ll");
      let endDate = moment(data.end_date).format("ll");
      let isCharge = false;
      let chargeId = null;
      let charge = null;
      let paymentMethod = null;
      let card = null;

      if (data.chargeId) {
        isCharge = true;
        chargeId = data.chargeId;
      }

      if (isCharge) {
        let result = await window.FortisForma.database.getPaymentInfo({
          chargeId,
        });
        paymentMethod = result.payment_method_details.card.last4;
        card = result.payment_method_details.card.brand;
      }

      doc.addImage(
        Logo,
        "PNG",
        width - margin - logoWidth,
        20,
        logoWidth,
        logoHeight,
        "",
        "FAST"
      );
      doc.setFont(undefined, "bold");
      doc.setFontSize(18);
      doc.text("Receipt", margin, 40);
      doc.setFont(undefined, "normal");
      doc.setFontSize(10);

      doc.text("Date paid", margin, 60);
      doc.text(`${datePaid}`, margin * 4, 60);
      doc.text("Invoice number", margin, 70);
      doc.text(`${invoiceNo}`, margin * 4, 70);
      doc.text("Receipt number", margin, 80);
      doc.text(receiptNo !== null ? `${receiptNo}` : "", margin * 4, 80);
      doc.text("Payment method", margin, 90);
      doc.text(
        paymentMethod !== null ? `${paymentMethod} - ` : "",
        margin * 4,
        90
      );
      doc.text(paymentMethod !== null ? `${card}` : "", margin * 5.2, 90);

      doc.setFont(undefined, "bold");
      doc.text(`${accountName}`, margin, 110);
      doc.text("Bill to", width / 2 + margin, 110);
      doc.text(`${cusName}`, width / 2 + margin, 120);
      doc.text(`${cusEmail}`, width / 2 + margin, 130);

      doc.setFont(undefined, "bold");
      doc.setFontSize(14);
      doc.text(`C$${total} paid on May 24, 2022`, margin, 170);

      doc.setFont(undefined, "normal");
      doc.setFontSize(8);
      doc.text("Description", margin, 210);
      doc.text("Qty", width / 2 + margin, 210);
      doc.text("Unit price", width * (3 / 4) - margin * 2, 210);
      doc.text("Amount", width - margin, 210, "right");

      doc.line(margin, 215, width - margin, 215);

      doc.setFont(undefined, "bold");
      doc.setFontSize(10);
      doc.text(`${prodDescription}`, margin, 225, {
        maxWidth: width / 2 + margin / 2,
      });
      doc.setFont(undefined, "normal");
      doc.text("1", width / 2 + margin, 225);
      doc.text(`C$${basePrice}`, width * (3 / 4) - margin * 2, 225);
      doc.text(`C$${basePrice}`, width - margin, 225, "right");
      doc.text(`${startDate} - ${endDate}`, margin, 245);

      doc.setDrawColor(197, 197, 197);
      doc.line(width / 2 + margin, 260, width - margin, 260);
      doc.text("Subtotal", width / 2 + margin, 270);
      doc.text(`C$${subTotal}`, width - margin, 270, "right");

      doc.line(width / 2 + margin, 275, width - margin, 275);
      doc.text("10% Yearly Discount (10% off)", width / 2 + margin, 285);
      doc.text(
        discount === null ? `-C$${discount}` : "C$0.00",
        width - margin,
        285,
        "right"
      );

      doc.line(width / 2 + margin, 290, width - margin, 290);
      doc.text("Sales tax (13%)", width / 2 + margin, 300);
      doc.text(`C$${tax}`, width - margin, 300, "right");

      doc.line(width / 2 + margin, 305, width - margin, 305);
      doc.text("Total", width / 2 + margin, 315);
      doc.text(`C$${total}`, width - margin, 315, "right");

      doc.line(width / 2 + margin, 320, width - margin, 320);
      doc.setFont(undefined, "bold");
      doc.text("Amount paid", width / 2 + margin, 330);
      doc.text(`C$${total}`, width - margin, 330, "right");

      doc.line(width / 2 + margin, 335, width - margin, 335);

      doc.addPage();
    }
    // });
    // * Remove the last empty page from document
    var pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount);
    doc.save(`${"Purchase History"}.pdf`);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Purchase History
        </Typography>
      )}
      <Tooltip title="Download">
        <IconButton
          aria-label="download"
          onClick={() => {
            generatePDF(props.selectedData);
            // props.selectedData.forEach((selectedRow) => {
            //   generatePDF(selectedRow);
            // });
            props.deselect();
          }}
        >
          <GetApp />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  sortLabel: {
    color: "#800520",
    fontWeight: "bold",
  },
}));

let count = 1;

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState(props.data);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedData(props.data);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    let newArr = props.data.filter((data) => {
      return newSelected.includes(data.id);
    });

    setSelected(newSelected);
    setSelectedData(newArr);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          data={selected}
          subData={props.data}
          selectedData={selectedData}
          deselect={() => setSelected([])}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />

            {props.isLoading ? (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                }}
              >
                <BubbleLoader color={"#800520"} size={5} />
              </div>
            ) : (
              <TableBody>
                {props.data &&
                  stableSort(props.data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                          >
                            {moment(row.start_date).format("D/MM/YYYY")}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="right">{row.amount}</TableCell>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
