import { Badge } from "@material-ui/core";
import React from "react";
import NoteAssessmentItem from "./noteAssessmentItem";
import { NOTE_TYPE } from "../../../constants";
import ExportPDFButton from "../exportPDFButton";
import Attachment from "./attachment";

export default class NoteItem extends NoteAssessmentItem {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      exportOptionsAnchorEl: null,
    };
  }

  renderPDFIcon = () => {
    const { data } = this.props;
    if (
      !data.title &&
      !data.description &&
      !(data.images && data.images.length)
    ) {
      return null;
    }
    return (
      <div className="exportIconContainer">
        <ExportPDFButton
          data={this.props.data}
          onExport={this.props.onExport}
        />
      </div>
    );
  };

  renderAttachmentsList = () => {
    const { data } = this.props;
    if (data.images && data.images.length > 0) {
      return (
        <div className="attachmentListContainer">
          {data.images.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Attachment item={item} index={index} />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  getItemtitle = (data) => {
    if (data.title) {
      return <b>{data.title}</b>;
    }
  };

  renderItemContentContainer = () => {
    const badgeContent = this.props.data.summaryId
      ? NOTE_TYPE.WORKOUT_NOTE
      : NOTE_TYPE.CLINICAL_NOTE;
    return (
      <Badge
        badgeContent={badgeContent}
        id="noteBadge"
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="noteItemInnerContainer"
          style={{ backgroundColor: this.props.data.color }}
          onClick={this.clickEdit}
        >
          {this.renderPDFIcon()}
          {this.renderItemContent()}
          {this.renderAttachmentsList()}
        </div>
      </Badge>
    );
  };
}
