import React, { Component } from "react";
import MergedClientList from "../../views/clientList/mergedClientList";
import { assignWorkoutToUser } from "../../views/workoutAssigner/workoutAssigner";

export default class Assigner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      selectedWorkout: null,
    };
  }

  handleDaySelect = (selectedDays) => {
    this.setState({ selectedDays });
  };

  handleWorkoutSelect = (selectedWorkout) => {
    this.setState({ selectedWorkout });
    this.props.onSelectClient(selectedWorkout);
  };

  checkErrors = () => {
    if (!this.state.selectedWorkout || this.state.selectedWorkout === {}) {
      let node = document.querySelector("#workoutDaySelect");
      window.NotificationUtils.showErrorInForm(
        node,
        "workoutDaySelect",
        "Please select a client"
      );
      return true;
    }
    if (!this.state.selectedDays || this.state.selectedDays.length === 0) {
      let node = document.querySelector("#daysSelect");
      window.NotificationUtils.showErrorInForm(
        node,
        "daysSelect",
        "Please select days"
      );
      return true;
    }
  };

  save = (workoutDay) => {
    assignWorkoutToUser(
      this.getSelectedClient(),
      this.props.history,
      workoutDay,
      this.props.currentUser.enterpriseId,
      this.props.currentUser
    );
  };

  getSelectedClient() {
    return this.state.selectedWorkout.value;
  }

  assigne = async (workoutDay) => {
    if (this.checkErrors()) {
      return Promise.reject("Invalid values in assign options");
    }
    let daysIndexes = this.getDaysIndexes();
    const clientData = this.getSelectedClient();

    try {
      window.NotificationUtils.showConfirm("Assigning workout to client");
      await window.FortisForma.database.addWorkoutDayToClientProgram(
        clientData,
        workoutDay,
        daysIndexes
      );
      window.NotificationUtils.showSuccess("Workout succesfully assigned");
      return {
        clientData,
      };
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to assign workout");
      throw e;
    }
  };

  getDaysIndexes = () => {
    let days = Object.assign([], this.state.selectedDays);
    let indexes = days.map((day, index) => {
      return day.value;
    });

    return indexes;
  };

  render() {
    return (
      <MergedClientList
        currentUser={this.props.currentUser}
        placeholder={"Select Client"}
        showClientsOnly={true}
        handleWorkoutSelect={this.handleWorkoutSelect}
        selectedWorkout={this.state.selectedWorkout}
        {...this.props}
      />
    );
  }
}
