import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import FortisFormaSelect from "../autoSuggest/dropdownSelect";
import FormSequenceCard from "./formSequenceCard";
import { Typography, DialogContent, DialogTitle } from "@material-ui/core";
import { ARROW_ACTIONS, FORM_TYPES_LABEL } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const DROPDOWN_FORM_TYPES = [
  {
    label: FORM_TYPES_LABEL.REGISTRATION,
    value: 0,
  },
  {
    label: FORM_TYPES_LABEL.ASSESSMENTS,
    value: 1,
  },
  {
    label: FORM_TYPES_LABEL.GENERAL_INFO,
    value: 2,
  },
  {
    label: FORM_TYPES_LABEL.MISC,
    value: 3,
  },
  {
    label: FORM_TYPES_LABEL.NOTES,
    value: 4,
  },
];

export default class ChangeSequence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedTab: this.getSelectedTabValue(),
    };
    this.filteredForms = [];
  }

  getSelectedTabValue = () => {
    if (this.props.selectedTab) {
      var result = DROPDOWN_FORM_TYPES.find(
        (formType) => formType.value === this.props.selectedTab
      );
      if (result) {
        return result;
      }
    }
    return DROPDOWN_FORM_TYPES[0];
  };

  filterAndSort = () => {
    var forms = this.props.formList.filter(
      (form) => form.step === this.state.selectedTab.value
    );
    forms.sort((form1, form2) => {
      return form2.sequence - form1.sequence;
    });
    this.filteredForms = forms;
  };

  handleTabChange = (selectedOption) => {
    this.setState({
      selectedTab: selectedOption,
    });
  };

  renderLoading = () => {
    return <OpaqueLoading />;
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.closeChangeSequenceModal()}
        disabled={this.state.loading}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  renderDialogContentData = () => {
    this.filterAndSort();
    return (
      <div className="useTemplatesContent">
        <div className="changeSequenceInnerFormCardsContainer">
          {this.renderCards()}
        </div>
      </div>
    );
  };

  renderEmptyPlaceHolder = () => {
    return (
      <div className="useTemplatesEmptyPlaceholderContainer">
        <Typography>No forms available for this type</Typography>
      </div>
    );
  };

  onClickArrowIcon = async (index, action) => {
    try {
      this.setState({
        loading: true,
      });
      var firstFormId = this.filteredForms[index].id;
      var firstFormSequence = this.filteredForms[index].sequence;
      var secondForm;
      if (action === ARROW_ACTIONS.UP) {
        secondForm = this.filteredForms[index - 1];
      } else {
        secondForm = this.filteredForms[index + 1];
      }
      var secondFormId = secondForm.id;
      var secondFormSequence = secondForm.sequence;
      if (
        !(
          firstFormId &&
          secondFormId &&
          firstFormSequence &&
          secondFormSequence
        )
      ) {
        throw new Error("Invalid Action");
      }
      await window.FortisForma.database.swapSequence({
        firstFormId,
        secondFormId,
        firstFormSequence,
        secondFormSequence,
      });
      window.NotificationUtils.showSuccess("Updated sequence successfully");
      var forms = this.filteredForms;
      forms[index].sequence = secondFormSequence;
      forms[action === ARROW_ACTIONS.UP ? index - 1 : index + 1].sequence =
        firstFormSequence;
      this.filteredForms = forms;
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      throw error;
    }
  };

  renderCards = () => {
    if (this.filteredForms && this.filteredForms.length === 0) {
      return this.renderEmptyPlaceHolder();
    }
    return this.filteredForms.map((form, index) => {
      return (
        <FormSequenceCard
          key={index}
          index={index}
          data={form}
          formsLength={this.filteredForms.length}
          onClickArrowIcon={this.onClickArrowIcon}
        />
      );
    });
  };

  render() {
    return (
      <div className={this.state.loading && "pointerEventsNone"}>
        {this.state.loading && this.renderLoading()}
        <DialogTitle
          disableTypography={true}
          className="documentsModalHeaderContainer"
        >
          <Typography
            className="modalFormTitle boldText"
            variant="h5"
            display="inline"
          >
            Change Sequence
          </Typography>
          <FortisFormaSelect
            id="useTemplatesFormTypeSelect"
            placeholder="Forms"
            value={this.state.selectedTab}
            classNamePrefix="fortisFormaDropDownSelect"
            onChange={this.handleTabChange}
            options={DROPDOWN_FORM_TYPES}
            isClearable={false}
            isDisabled={this.state.loading}
          />
        </DialogTitle>
        <DialogContent className="useTemplatesContentContainer">
          {this.renderCloseButton()}
          {this.renderDialogContentData()}
        </DialogContent>
      </div>
    );
  }
}
