import { Button, Dialog } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FORM_WIDGET_CONFIRMATION_TEXT } from "../../constants";
import ImageEditorModal from "../imageEditorModal/imageEditorModal";

export default function FreehandNote(props) {
  const { value, onChange, schema, required } = props;

  const [showEditor, setShowEditor] = useState(false);

  const editorModalRef = useRef(null);

  const onCloseEditor = () => {
    setShowEditor(false);
  };

  const handleCloseEditor = () => {
    editorModalRef.current &&
      editorModalRef.current.confirmClose(
        onCloseEditor,
        FORM_WIDGET_CONFIRMATION_TEXT.UNSAVED_DATA
      );
  };

  const handleSave = (val) => {
    onChange(val);
    setShowEditor(false);
  };

  const renderPreview = () => {
    return (
      <div
        className="freehandNotePreviewContainer"
        onClick={() => setShowEditor(true)}
      >
        <img
          src={value && value.canvasImage}
          className="formImageWidgetPreview"
          alt="note-preview"
        />
        Click to edit
      </div>
    );
  };

  const renderAddButton = () => {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowEditor(true)}
        >
          Add Note
        </Button>
      </div>
    );
  };

  const renderEditor = () => {
    const canvasData = (value && value.canvasData) || null;
    return (
      <Dialog
        open={showEditor}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseEditor}
      >
        <ImageEditorModal
          ref={editorModalRef}
          title="Note Editor"
          onClose={handleCloseEditor}
          imageMode={false}
          canvasData={canvasData}
          handleSave={handleSave}
        />
      </Dialog>
    );
  };

  return (
    <div>
      {showEditor && renderEditor()}
      <div
        style={{ marginBottom: "4px", fontSize: "0.9rem" }}
        className="widgetQuestionText"
      >
        {`${schema.title}${required ? "*" : ""}`}
      </div>
      {value && value.canvasImage ? renderPreview() : renderAddButton()}
    </div>
  );
}
