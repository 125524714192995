import { DialogContent } from "@material-ui/core";
import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";

const TrailModal = ({
  onClose,
  modalTitle,
  modalBody,
  buttonText,
  user,
  hist,
}) => {
  const renderCloseButton = () => {
    return (
      <IconButton className="closeIconButton" onClick={() => onClose()}>
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  return (
    <>
      <DialogContent className="memberAddDialog-mui">
        <div className="memberAddDialogContent trialModal">
          <div className="memberFieldsContainer">
            {renderCloseButton()}
            <div>
              {modalTitle ? (
                <h5 style={{ marginBottom: "20px" }}>{modalTitle}</h5>
              ) : (
                <>
                  <h5> Welcome to your free 2-week trial!</h5>
                  <h5 style={{ marginTop: "0px", marginBottom: "20px" }}>
                    Now you can try out all of the features that the{" "}
                    <span style={{ fontWeight: 600 }}>{user.tier}</span> has to
                    offer.
                  </h5>
                </>
              )}
              <h4
                style={{
                  marginBottom: modalTitle && "30px",
                }}
                dangerouslySetInnerHTML={{
                  __html: modalBody && modalBody,
                }}
              ></h4>
              <label>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    hist.replace("/subscriptions");
                    onClose();
                  }}
                >
                  {buttonText && buttonText}
                </span>
              </label>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default React.memo(TrailModal);
