import React from "react";

import WorkoutDayCard from "./workoutdaycard";

import InfiniteList from "../../../components/infiniteList/list.js";

export default class WorkoutsList extends InfiniteList {
  loadNextPage() {
    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData(false);
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  rowRenderer({ key, index, isScrolling, style }) {
    let data = this.props.data[index];
    return (
      <div style={style} key={key}>
        <WorkoutDayCard
          key={index}
          type={this.props.type}
          enableWalkthrough={index === 0}
          data={data}
          assignWorkoutDay={this.props.assignWorkoutDay}
          selectedWorkoutDay={this.props.selectedWorkoutDay}
          onSelect={this.props.onSelect}
          deleteWorkoutDay={this.props.deleteWorkoutDay}
          editWorkoutDay={this.props.editWorkoutDay}
          copyWorkoutDay={this.props.copyWorkoutDay}
          onCancel={this.props.onCancel}
          selected={this.props.selectedWorkoutDay.id === data.id}
          onClickShareIcon={this.props.onClickShareIcon}
        />
      </div>
    );
  }
}
