import React from "react";
import TAButton from "../taButton/taButton";
import formImagesModal from "./formImagesModal";
import {
  ROLES,
  UPLOADED_IMAGE_TYPE,
  TIER,
  GET_ENT_ID,
  GET_USER_ID,
} from "../../constants";
import ImageUploader from "../uploaderImage/imageUploader";

export default class formImagesEditor extends formImagesModal {
  state = {
    ...this.state,
  };
  fetchEnterpriseImages = async () => {
    try {
      // let results;
      // if (
      //   this.props.user.role === ROLES.TRAINER &&
      //   this.props.user.tier !== TIER.FREE
      // ) {
      //   results = await window.FortisForma.database.fetchFormImages(
      //     this.props.user.enterpriseId,
      //     this.props.user.id
      //   );
      // } else {
      //   results = await window.FortisForma.database.fetchFormImages(
      //     this.props.user.enterpriseId,
      //     this.props.user.id
      //   );
      // }
      const enterpriseId = GET_ENT_ID(this.props);
      const userID = GET_USER_ID(this.props);
      const results = await window.FortisForma.database.fetchFormImages(
        enterpriseId,
        userID
        // this.props.user.id
      );

      return results;
    } catch (e) {
      throw e;
    }
  };

  getImagesToSave = () => {
    let imagesToSave = this.state.images.filter((image) => !image.id);
    for (let image of imagesToSave) {
      if (
        this.props.user.role === ROLES.TRAINER &&
        this.props.user.tier !== TIER.FREE
      ) {
        image.trainerId = this.props.user.id;
      } else {
        image.enterpriseId = this.props.user.enterpriseId;
      }
      image.creatorId = this.props.user.id;
    }
    return imagesToSave;
  };

  onSave = async () => {
    let imagesToSave = this.getImagesToSave();

    if (!(imagesToSave && imagesToSave.length)) {
      return this.props.onClose();
    }

    this.setState({ saving: true });
    try {
      await window.FortisForma.database.saveFormImages(imagesToSave);
      window.NotificationUtils.showSuccess("Images added");
      this.props.onClose();
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
    this.setState({ saving: false });
  };

  onDelete = async (id) => {
    try {
      await window.FortisForma.database.deleteFormImage(id);
    } catch (e) {
      throw e;
    }
  };

  renderDialogContent = () => {
    const { user } = this.props;
    const { images, disableButtons } = this.state;
    const isEnterpriseAdmin =
      user.role === ROLES.ENTERPRISE_ADMIN || user.role === ROLES.TRAINER;
    return (
      <ImageUploader
        disableSaveButton={() => this.setState({ disableButtons: true })}
        selectedImages={(images && images.length > 0 && images) || []}
        onChange={this.onChangeImages}
        setPreviewOpened={(value) => this.setState({ showPreview: value })}
        pathToStore={"formImages/"}
        extensions={["png", "jpg", "jpeg"]}
        onDelete={this.onDelete}
        hideRemove={disableButtons || !isEnterpriseAdmin}
        uploadedImageType={UPLOADED_IMAGE_TYPE.IMAGE_EDITOR}
      />
    );
  };

  renderDialogActions = () => {
    return (
      <TAButton
        onClick={this.onSave}
        isLoading={this.state.saving}
        color="primary"
        disabled={this.state.loading || this.state.disableButtons}
        loadingMessage="Saving"
      >
        Save
      </TAButton>
    );
  };

  getDialogTitle = () => "Form Images";
}
