import { Typography } from "@material-ui/core";
import React from "react";
import Attachment from "./attachment";

function ItemContent({ data, onClickView }) {
  const renderNoteDescription = () => {
    if (data.description) {
      return <Typography>{data.description}</Typography>;
    }
    if (data.summary) {
      return <Typography>{data.summary}</Typography>;
    }
  };

  const getNotetitle = () => {
    if (data.title) {
      return <b>{data.title}</b>;
    }
  };

  const renderViewFormButton = () => {
    return (
      <div
        className="viewFormButtonText"
        style={{ marginLeft: "4px" }}
        onClick={() => onClickView(data)}
      >
        View Form
      </div>
    );
  };

  const renderFormSection = () => {
    if (data.formId) {
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Filled form: {renderViewFormButton()}
        </span>
      );
    }
  };

  const renderItemDetails = () => {
    return (
      <div className="assessmentSummaryAndTimeContainer">
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{getNotetitle()}</Typography>
          </div>
          {renderNoteDescription()}
          {renderFormSection()}
        </div>
      </div>
    );
  };

  const renderAttachmentsList = () => {
    const attachments = data.images || data.attachments || [];
    if (attachments.length) {
      return (
        <div className="attachmentListContainer">
          {attachments.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Attachment item={item} index={index} />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      {renderItemDetails()}
      {renderAttachmentsList()}
    </>
  );
}

export default ItemContent;
