import {
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import { isEmail } from "validator";
import { ROLES } from "../../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TAButton from "../taButton/taButton";

const MEMBER_ROLES = [
  { label: "Enterprise Admin", value: ROLES.ENTERPRISE_ADMIN },
  { label: "Health Coach", value: ROLES.HEALTH_COACH },
  { label: "Facility Admin", value: ROLES.FACILITY_ADMIN },
];
export default class AddMemberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      role: "",
      designation: "",
      registrationNumber: "",
      checkErrors: false,
      loading: this.props.loading || false,
    };
    this.editMode = false;
  }

  componentDidMount = () => {
    if (this.props.data) {
      this.setState({
        name: (this.props.data.name && this.props.data.name) || "",
        email: (this.props.data.email && this.props.data.email) || "",
        role: (this.props.data.role && this.props.data.role) || "",
        designation:
          (this.props.data.designation && this.props.data.designation) || "",
        registrationNumber:
          (this.props.data.registrationNumber &&
            this.props.data.registrationNumber) ||
          "",
      });
      this.editMode = this.props.data.id ? true : false;
    }
  };

  handleInputChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.onClose()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };
  errorInFieldName = (key) => {
    return !this.state[key];
  };
  errorInEmail = () => {
    const email = this.formatEmail(this.state.email);
    return !email || !isEmail(email);
  };

  checkErrors = () => {
    const email = this.formatEmail(this.state.email);
    let error = false;
    if (!this.state.name) {
      window.NotificationUtils.showError("Please enter name");
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    if (!email || !isEmail(email)) {
      window.NotificationUtils.showError("Please enter valid email");
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    if (!this.state.role) {
      window.NotificationUtils.showError("Please select a role");
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    return error;
  };

  formatEmail = (email) => {
    let trimmedEmail = email.trim();
    let formattedEmail = trimmedEmail.toLowerCase();
    return formattedEmail;
  };

  onCreate = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }
    this.setState({
      loading: true,
    });
    let memberData = {};
    memberData.registrationNumber = this.state.registrationNumber;
    memberData.name = this.state.name;
    memberData.email = this.formatEmail(this.state.email);
    memberData.role = this.state.role;
    memberData.designation = this.state.designation;
    try {
      if (this.editMode) {
        await this.props.onClickSave(memberData, true);
        window.NotificationUtils.showSuccess(
          "Staff member updated successfully"
        );
      } else {
        await this.props.onClickSave(memberData);
        window.NotificationUtils.showSuccess("Staff member added successfully");
      }
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      this.setState({
        loading: false,
      });
      console.error(e);
    }
  };

  renderLoader = () => {
    return (
      <div className="addMemberModalLoadingContainer">
        <CircularProgress color="primary" />
      </div>
    );
  };

  render() {
    return (
      <>
        <DialogContent className="memberAddDialog-mui">
          <Typography className="memberAddDialogTitle boldText" variant="h5">
            {this.editMode ? "Edit Member" : "Add Member"}
          </Typography>
          <div className="memberAddDialogContent">
            <div className="memberFieldsContainer">
              {this.renderCloseButton()}
              <div className="firstRowFieldsContainer">
                <TextField
                  className="memberInputFields rightMargin"
                  fullWidth={true}
                  onChange={(e) => this.handleInputChange(e, "name")}
                  variant="standard"
                  value={this.state.name}
                  label="Name"
                  required={true}
                  disabled={this.editMode}
                  error={
                    this.state.checkErrors && this.errorInFieldName("name")
                  }
                />
                <TextField
                  variant="standard"
                  className="memberInputFields leftMargin"
                  fullWidth={true}
                  error={this.state.checkErrors && this.errorInEmail()}
                  required={true}
                  onChange={(e) => this.handleInputChange(e, "email")}
                  value={this.state.email}
                  label="Email"
                  disabled={this.editMode}
                />
              </div>
              <div className="secondRowFieldsContainer">
                <TextField
                  variant="standard"
                  className="memberInputFields rightMargin"
                  fullWidth={true}
                  required={true}
                  onChange={(e) => this.handleInputChange(e, "role")}
                  select
                  error={
                    this.state.checkErrors && this.errorInFieldName("role")
                  }
                  value={this.state.role}
                  label="Role"
                >
                  {MEMBER_ROLES.map((role, key) => {
                    if (role.label !== "Enterprise Admin") {
                      return (
                        <MenuItem key={key} value={role.value}>
                          {role.label}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>

                <TextField
                  variant="standard"
                  className="memberInputFields leftMargin"
                  fullWidth={true}
                  onChange={(e) => this.handleInputChange(e, "designation")}
                  value={this.state.designation}
                  label="Designation"
                />
              </div>
            </div>
            <div className="registrationFieldContainer">
              <TextField
                variant="standard"
                className="memberInputRegistrationField"
                fullWidth={true}
                onChange={(e) =>
                  this.handleInputChange(e, "registrationNumber")
                }
                value={this.state.registrationNumber}
                label="Registration Number"
                type={"number"}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <TAButton
            onClick={this.onCreate}
            isLoading={this.state.loading}
            loadingMessage={this.editMode ? "Saving" : "Creating"}
            color="primary"
            size="large"
          >
            {this.editMode ? "Save" : "Create"}
          </TAButton>
        </DialogActions>
      </>
    );
  }
}
