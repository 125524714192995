import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-virtualized/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import * as Sentry from "@sentry/browser";
import { ERROR_CODES } from "./constants";

import FortisFormaErrorBoundary from "./FortisFormaErrorBoundary";
import * as serviceWorker from "./serviceWorker";

import _ from "underscore";
import moment from "moment";

import $ from "jquery";

let dsn = process.env.REACT_APP_SENTRY_DSN;

if (dsn) {
  Sentry.init({ dsn: dsn, attachStacktrace: true });

  window.captureException = (error, level) => {
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setTag("version", process.env.REACT_APP_VERSION);
      Sentry.captureException(error);
    });
  };
  let originalConsoleError = console.error;
  console.error = (error) => {
    if (dsn) {
      window.captureException(error, "error");
    }
    checkPermissionsError(error);
    // eslint-disable-next-line no-undef
    originalConsoleError.apply(console, arguments);
  };
} else {
  let originalConsoleError = console.error;
  console.error = (error) => {
    checkPermissionsError(error);
    // eslint-disable-next-line no-undef
    originalConsoleError(error);
  };
}

// if (localStorage.getItem("token")) {
//   askForPermissioToReceiveNotifications();
// }

const checkPermissionsError = (error) => {
  if (error.code === ERROR_CODES.PERMISSION_DENIED) {
    window.FortisForma.database.signOut();
  }
};
//LocalStorage Polyfill
if (!window.localStorage) {
  Object.defineProperty(
    window,
    "localStorage",
    new (function () {
      var aKeys = [],
        oStorage = {};
      Object.defineProperty(oStorage, "getItem", {
        value: function (sKey) {
          return this[sKey] ? this[sKey] : null;
        },
        writable: false,
        configurable: false,
        enumerable: false,
      });
      Object.defineProperty(oStorage, "key", {
        value: function (nKeyId) {
          return aKeys[nKeyId];
        },
        writable: false,
        configurable: false,
        enumerable: false,
      });
      Object.defineProperty(oStorage, "setItem", {
        value: function (sKey, sValue) {
          if (!sKey) {
            return;
          }
          document.cookie =
            escape(sKey) +
            "=" +
            escape(sValue) +
            "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
        },
        writable: false,
        configurable: false,
        enumerable: false,
      });
      Object.defineProperty(oStorage, "length", {
        get: function () {
          return aKeys.length;
        },
        configurable: false,
        enumerable: false,
      });
      Object.defineProperty(oStorage, "removeItem", {
        value: function (sKey) {
          if (!sKey) {
            return;
          }
          document.cookie =
            escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        },
        writable: false,
        configurable: false,
        enumerable: false,
      });
      Object.defineProperty(oStorage, "clear", {
        value: function () {
          if (!aKeys.length) {
            return;
          }
          for (var sKey in oStorage) {
            document.cookie =
              escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
          }
        },
        writable: false,
        configurable: false,
        enumerable: false,
      });
      this.get = function () {
        var iThisIndx;
        for (var sKey in oStorage) {
          iThisIndx = aKeys.indexOf(sKey);
          if (iThisIndx === -1) {
            oStorage.setItem(sKey, oStorage[sKey]);
          } else {
            aKeys.splice(iThisIndx, 1);
          }
          delete oStorage[sKey];
        }
        for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) {
          oStorage.removeItem(aKeys[0]);
        }
        for (
          var aCouple,
            iKey,
            nIdx = 0,
            aCouples = document.cookie.split(/\s*;\s*/);
          nIdx < aCouples.length;
          nIdx++
        ) {
          aCouple = aCouples[nIdx].split(/\s*=\s*/);
          if (aCouple.length > 1) {
            oStorage[(iKey = unescape(aCouple[0]))] = unescape(aCouple[1]);
            aKeys.push(iKey);
          }
        }
        return oStorage;
      };
      this.configurable = false;
      this.enumerable = true;
    })()
  );
}

// let originalConsoleWarning = console.warn
// console.warn = (warning) => {
//   window.captureException(warning, 'warning');
//   // eslint-disable-next-line no-undef
//   originalConsoleWarning.apply(console, arguments)
// }

window._ = _;
window.moment = moment;
window.$ = $;

require("popper.js");
require("bootstrap");
require("bootstrap-daterangepicker");

window.isValidNumber = function (number) {
  return /^\d+$/.test(number);
};

window.areEqualShallow = function (a, b) {
  if ((a && !b) || (b && !a)) {
    return false;
  }
  for (var key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};
const cachedVersion = localStorage.getItem("appVersion");
const currentVersion = process.env.REACT_APP_VERSION;
console.log(caches, "names", cachedVersion, currentVersion);
if (cachedVersion !== currentVersion) {
  clearCacheData();
  localStorage.setItem("appVersion", currentVersion);
  window.location.reload(true);
} else {
  ReactDOM.render(
    <FortisFormaErrorBoundary />,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();

console.info(
  `Fortis-Forma-${process.env.NODE_ENV}-${process.env.REACT_APP_VERSION}`
);
