import React, {Component} from 'react';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div style={{width: '100vw', height: '100vh', overflowX: 'scroll', overflowY: 'scroll', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '80%'}}>
                    <h2 className="boldText">Fortis Forma Web Services Privacy Policy</h2>
                    <div style={{marginTop: 40}}>
                        <h4 className="boldText">What personal information does Fortis Forma collect?</h4>
                        <p>We collect your name and email address (phone number & address are optional) to customize your experience using our application(s), to easily allow syncing between devices and to allow you to reset your password.</p>
                    </div>
                    <div style={{marginTop: 40}}>
                        <h4 className="boldText">What general information does Fortis Forma collect?</h4>
                        <p>We use basic analytics to analyze the usage of our apps.
Example: How often users login to the app, how much time they spend on the app and what key features they interact with the most. With the goal of delivering the highest quality and ‘bug-free’ experience possible.</p>
                    </div>
                    <div style={{marginTop: 40}}>
                        <h4 className="boldText">How does Fortis Forma use the information collected?</h4>
                        <p>We use the personal information to create a more customized and convenient experience. We use the analytics to analyze app usage to try and improve this and other apps we develop by providing better features & content.</p>
                    </div>
                    <div style={{marginTop: 40}}>
                        <h4 className="boldText">Personal Information</h4>
                        <p>We do not share your personal information with anyone. We will never contact you outside of the application.</p>
                    </div>
                    <div style={{marginTop: 40}}>
                        <h4 className="boldText">Is there anything we’re not telling you?</h4>
                        <p>No, but if you ever have any comments, questions, feedback or concerns please send us an email to: admin@fortisforma.com</p>
                    </div>
                    
                </div>   
            </div>
        )   
    }
}