import React, { Component } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import LocationAutoComplete from "../../components/autoComplete/AutoComplete";
import TextField from "./onboardField";

const INITIAL_DETAILS = {
  bio: "",
  address: {},
  experience: moment(),
  valueProposition: "",
  postalCode: "",
};

export default class Bio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: INITIAL_DETAILS,
      autocompleteSelected: false,
    };
  }

  componentDidMount() {
    var autocompleteSelected = false;
    if (!this.props.data.experience) {
      this.props.data.experience = moment().format("YYYY");
    }
    if (
      this.props.data &&
      this.props.data.address &&
      this.props.data.address.latitude &&
      this.props.data.address.longitude
    ) {
      autocompleteSelected = true;
    }
    this.setState({
      bio: this.props.data,
      autocompleteSelected,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (!this.props.data.experience) {
        this.props.data.experience = moment().format("YYYY");
      }
      this.setState({
        bio: this.props.data,
      });
    }
  }

  updateValue(key, value) {
    var autocompleteSelected = this.state.autocompleteSelected || false;
    if (key === "experience") {
      value = moment(value).format("YYYY");
    }
    if (key === "address") {
      if (value && value.latitude && value.longitude) {
        autocompleteSelected = true;
      } else {
        autocompleteSelected = false;
      }
    }
    let bio = Object.assign({}, this.state.bio);
    bio[key] = value;

    this.setState({
      bio,
      autocompleteSelected,
    });
  }

  updatePostalCode(pCode) {
    let bio = Object.assign({}, this.state.bio);
    let address = Object.assign({}, bio.address);
    address.postalCode = pCode;
    bio.address = address;
    this.setState({
      bio,
    });
  }

  checkErrorsInForm = () => {
    if (!this.state.bio.address || !this.state.autocompleteSelected) {
      window.NotificationUtils.showError("Please enter a valid address");
      return true;
    }

    if (!this.state.bio.experience) {
      window.NotificationUtils.showError("Please enter a valid experience");
      return true;
    }

    if (!this.state.bio.valueProposition) {
      window.NotificationUtils.showError(
        "Please write a valid value proposition"
      );
      return true;
    }

    if (!this.state.bio.bio) {
      window.NotificationUtils.showError(
        "Please write a valid bio about yourself"
      );
      return true;
    }

    return false;
  };

  getData = (checkErrors = true) => {
    if (checkErrors && this.checkErrorsInForm()) {
      return false;
    }
    let data = Object.assign({}, this.state.bio);
    return data;
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <React.Fragment>
          <div className="onBoardingHeading onBoardingSectionHeader">
            Basic Information
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <div className="fieldContainer onboardingFieldContainer">
              <TextField
                id="valueProposition"
                label="Value Proposition"
                helperText="In one sentence or less what is the one thing that you feel sets you apart or makes you different"
                fullWidth={true}
                required
                value={this.state.bio.valueProposition || ""}
                onChange={(ev) => {
                  this.updateValue("valueProposition", ev.target.value);
                }}
              />
            </div>
            <div
              style={{ flex: 1 }}
              className="fieldContainer onboardingFieldContainer"
            >
              <TextField
                className="aboutTextArea"
                name="about"
                placeholder="Tell your potential clients about yourself."
                helperText="A good bio not only highlights things about you, but also tells someone the benefits you can offer them, all while being relatable"
                value={this.state.bio.bio || ""}
                required
                rows={6}
                multiline={true}
                onChange={(ev) => {
                  this.updateValue("bio", ev.target.value);
                }}
              />
            </div>
            <div
              style={{ flex: 1 }}
              className="fieldContainer onboardingFieldContainer"
            >
              <LocationAutoComplete
                id="businessadd"
                label="Business Address"
                fullWidth={true}
                helperText="If you operate out of a studio, clinic or train people in your home please put your whole address here. It won’t be visible to any potential clients until they’ve confirmed their first session with you. 
                If you train people in their environment or online you only have to enter your postal code."
                value={this.state.bio.address || {}}
                required
                onChange={(value) => this.updateValue("address", value)}
              />
            </div>
            {this.state.bio.address ? (
              <div
                style={{ flex: 1 }}
                className="fieldContainer onboardingFieldContainer"
              >
                <TextField
                  fullWidth={true}
                  name="postalCode"
                  label="Postal Code"
                  required
                  helperText="Change postal code here if not accurate. Note: This will be used by clients to search nearby trainers."
                  value={
                    (this.state.bio.address &&
                      this.state.bio.address.postalCode) ||
                    ""
                  }
                  onChange={(ev) => {
                    this.updatePostalCode(ev.target.value);
                  }}
                />
              </div>
            ) : null}

            <div
              style={{ flex: 1 }}
              className="fieldContainer onboardingFieldContainer"
            >
              <div id="yearPickerBio" style={{ display: "flex" }}>
                <DatePicker
                  views={["year"]}
                  inputVariant="outlined"
                  label="Start Year"
                  required
                  helperText="Year you started practicing, used to calculate experience"
                  value={this.state.bio.experience}
                  onChange={(ev) => {
                    this.updateValue("experience", ev);
                  }}
                  animateYearScrolling
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      </MuiPickersUtilsProvider>
    );
  }
}
