import { sendRequest } from "../http/HttpHelper";

const DEFAULT_QUERY = {
  query: {
    match_all: {},
  },
  sort: [
    {
      _id: { order: "asc" },
    },
  ],
};

class DataFetcher {
  constructor(props) {
    if (!props) {
      props = {};
    }

    this.endpoint = props.endpoint;
    if (!this.endpoint) {
      throw new Error("Missing endpoint");
    }

    this.pageSize = props.pageSize;
    this.lastFetched = 0;
    this.lastRequest = null;
  }

  abort() {
    if (this.lastRequest) {
      this.lastRequest.abort();
      this.lastRequest = null;
    }
  }

  async fetch(query, cleanup = false, client = false) {
    if (!query) {
      query = Object.assign({}, DEFAULT_QUERY);
    }

    this.abort();

    // let sort = [
    //   {
    //     _score: { order: "desc" },
    //   },
    // ];
    let sort = [];
    if (client) {
      sort = [
        {
          _script: {
            order: "asc",
            type: "string",
            script: {
              lang: "painless",
              source: "params._source.name.toLowerCase()",
            },
          },
        },
      ];
    } else {
      sort = [
        {
          _score: { order: "desc" },
        },
      ];
    }

    if (!query.sort) {
      query.sort = [];
    }

    query.sort = sort.concat(query.sort);

    if (this.pageSize) {
      if (!query.size) {
        query.size = this.pageSize;
      }
      query.from = this.lastFetched;
    }

    if (cleanup) {
      query.from = 0;
    }

    try {
      let { promise, abort } = this._fetch(query);
      this.lastRequest = abort;
      let results = await promise;
      results = results.map((item) => {
        item._source._score = item._score;
        item._source.sort = item.sort;
        return item._source;
      });
      this.lastFetched = this.lastFetched + results.length;
      return results;
    } catch (e) {
      return [];
    }
  }

  _fetch(query) {
    let abort;
    let promise = new Promise((resolve, reject) => {
      abort = sendRequest(
        this.endpoint,
        this.method || "post",
        query,
        (results) => {
          resolve(results);
        },
        {
          errorCallback: reject,
        }
      );
    });
    return { promise, abort };
  }

  clearResults() {
    this.lastFetched = 0;
  }
}

class ExerciseFetcher extends DataFetcher {
  fetch(query, cleanup) {
    if (!query) {
      query = Object.assign({}, DEFAULT_QUERY);
    }
    query.sort = [
      {
        level: { order: "asc" },
      },
    ];
    return super.fetch(query, cleanup);
  }
}

class Factory {
  static exerciseFetcher() {
    return new ExerciseFetcher({
      endpoint: "exercises",
      pageSize: 20,
    });
  }

  static movementFetcher() {
    return new DataFetcher({
      endpoint: "movements",
    });
  }

  static movementCategoryFetcher() {
    return new DataFetcher({
      endpoint: "movementCategories",
    });
  }

  static equipmentFetcher() {
    return new DataFetcher({
      endpoint: "equipments",
    });
  }

  static muscleFetcher() {
    return new DataFetcher({
      endpoint: "muscles",
    });
  }

  static functionFetcher() {
    return new DataFetcher({
      endpoint: "functions",
    });
  }

  static usersFetcher() {
    return new DataFetcher({
      endpoint: "users",
      pageSize: 20,
    });
  }

  static invitesFetcher() {
    return new DataFetcher({
      endpoint: "invites",
      pageSize: 20,
    });
  }

  static staffInvitesFetcher() {
    return new DataFetcher({
      endpoint: "staffInvites",
      pageSize: 20,
    });
  }

  static enterpriseClientsFetcher() {
    return new DataFetcher({
      endpoint: "enterpriseClients",
      pageSize: 20,
    });
  }

  static enterpriseInvitesFetcher() {
    return new DataFetcher({
      endpoint: "enterpriseClientInvites",
      pageSize: 20,
    });
  }
}

export const DefaultQuery = Object.assign({}, DEFAULT_QUERY);
export default Factory;
