import React from 'react';
import InfiniteList from '../components/infiniteList/list';
import ClientSelectionCard from './clientSelectionCard';


export default class ClientSelectionList extends InfiniteList {

  loadNextPage() {

    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    })

  }


  rowRenderer({
    key,
    index,
    isScrolling,
    style
  }) {
    let data = this.props.data[index];
    let isSelected = false;
    let styleCopy = Object.assign({}, style);
    styleCopy.width = style.width - 20;


    isSelected = this.props.checkedClients[data.id] || false;

    return (
      <div style={styleCopy} key={key}>
        <ClientSelectionCard onSelect={this.props.onSelect} onRemove={this.props.onRemove} selected={isSelected} data={data} checkedClients={this.props.checkedClients} checked={this.props.checked} />
      </div>
    )
  }
}


