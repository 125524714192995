import React from "react";
import { Avatar } from "@material-ui/core";
import { BubbleLoader } from "react-css-loaders";
import { Col, Row } from "reactstrap";
import { MONTHS } from "../../constants";

function ClientList({
  clients,
  staff,
  handleSelected,
  loader,
  lastMessage,
  selectedClient,
}) {
  const letterName = (name) => {
    let secondName = name.split(" ")[1];
    let secondName2 = name.split("_")[1];
    if (secondName) {
      secondName = secondName[0];
    } else {
      secondName = "";
    }
    if (secondName2) {
      secondName = secondName2[0];
    }
    name = name[0] + secondName;
    return name;
  };
  const displayMeaningFullDate = (date) => {
    let d = new Date(date);
    let month = "";
    MONTHS.map((item) => {
      if (item.num === parseInt(d.getMonth() + 1)) {
        month = item.str;
      }
    });
    let join_Date = month + " " + d.getDate() + ", " + d.getFullYear();
    return join_Date;
  };

  return (
    <>
      <div className="ClientMessageList">
        <div className="client-chat-list">
          <Row
            className="clientCardHeading centerAlignJustify customRow"
            style={{ minHeight: 50 }}
          >
            <Col
              lg="2"
              md="2"
              sm="2"
              xs="2"
              style={{ justifyContent: "start", paddingLeft: 18 }}
              className="centerAlignJustify incFontWeight"
            >
              <strong>Name</strong>
            </Col>
            <Col
              lg="2"
              md="2"
              sm="2"
              xs="2"
              style={{ justifyContent: "start", paddingLeft: 26 }}
              className="centerAlignJustify incFontWeight"
            >
              <strong>Email </strong>
            </Col>
            <Col
              lg="6"
              md="6"
              sm="6"
              xs="8"
              style={{ justifyContent: "center" }}
              className="centerAlignJustify incFontWeight"
            >
              <strong>Phone</strong>
            </Col>
          </Row>
          {clients.length > 0 ? (
            clients.map((item, index) => {
              if (item) {
                return (
                  <div className="chat_list" key={index + item.name}>
                    <div className="client-info">
                      <Avatar>{letterName(item.name).toUpperCase()}</Avatar>

                      <div className="lastMsgArea">
                        <span>{item.name} </span>
                        {Object.keys(lastMessage).map((message) => {
                          if (message === item.id) {
                            return (
                              <span>
                                {" "}
                                <strong>{lastMessage[message][0].text}</strong>
                              </span>
                            );
                          }
                        })}
                      </div>
                      <span>{item.email}</span>
                      <span>{item.phone}</span>
                      <div className="msgRelBtn">
                        <i
                          className="icon material-icons pointer"
                          onClick={() => handleSelected(item, staff)}
                          alt="message"
                          style={{
                            color: `${
                              selectedClient.id !== item.id ? "" : "#800520"
                            }`,
                          }}
                        >
                          message
                        </i>
                        {Object.keys(lastMessage).map((message) => {
                          if (message === item.id) {
                            return (
                              <p className="unreadMsg">
                                {lastMessage[message][0].count}
                              </p>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : loader ? (
            <BubbleLoader color={"#800520"} size={5} />
          ) : (
            <p style={{ textAlign: "center", marginTop: "revert" }}>
              No {staff ? "Coaches" : "Clients"} found matching your criteria.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default ClientList;
