import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Header from '../../components/header/';

import mainRoutes from "../../routes/main";

var ps;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body && document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body && document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({
      activeColor: color
    });
  }
  handleBgClick = (color) => {
    this.setState({
      backgroundColor: color
    });
  }
  _renderHeader(prop) {
    if (prop.hideHeader) {
      return null;
    }
    return (<Header size={prop.headerSize} {...this.props} />)
  }
  render() {
    let location = this.props.hist.location;
    let path = (location && location.pathname) || '';
    if (path) {
      path = path.replace('/', '');
    }
    if (path === 'resend-email') {
      path = ''
    }

    return (
      <div className="wrapper">
        <div className="main-panel" ref="mainPanel">
          <Switch>
            {mainRoutes(this.props.isLoggedIn, this.props.user, this.props.hist.location).map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect exact={true} from={prop.path} to={prop.pathTo + ((this.props.isLoggedIn || !path) ? '' : `?route=${path}`)} key={key} />;
              }
              if (!prop.component) {
                return null;
              }
              return (
                <Route exact={true} path={prop.path} render={(props) => {
                  return (<div>
                    {
                      this._renderHeader(prop)
                    }
                    <prop.component {...this.props} {...props} />
                  </div>)
                }} key={key} />
              );
            })}
          </Switch>
        </div>
      </div>
    );
  }
}

export default Main;
