import { Typography } from "@material-ui/core";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ARROW_ACTIONS } from "../../constants";

const formSequenceCard = (props) => {
  var disableUpArrow = props.index === 0;
  var disableDownArrow = props.index === props.formsLength - 1;

  function renderFormDescription(desc) {
    const maxLength = 40;
    const descLength = desc && desc.length;
    if (descLength <= maxLength) {
      return desc;
    }
    if (!descLength) {
      return "";
    }
    let cutOffLength = descLength - maxLength;
    const result = desc.substring(0, descLength - cutOffLength);
    return `${result}...`;
  }

  const onClickArrowIcon = async (index, action) => {
    try {
      await props.onClickArrowIcon(index, action);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error trying to update sequence");
    }
  };

  return (
    <div className="formSequenceCard">
      <div className="formSequenceCardLeft">
        <div className="formSequenceCardColumn">
          <Typography variant="h6">{props.index + 1}</Typography>
        </div>
        <div className="formSequenceCardColumn">
          <Typography variant="body1" className="fw-500">
            {renderFormDescription(props.data.schema.title)}
          </Typography>
          {props.data.schema.description && (
            <Typography variant="body2">
              {renderFormDescription(props.data.schema.description)}
            </Typography>
          )}
        </div>
      </div>
      <div className="formSequenceCardRight">
        <Tooltip title={disableUpArrow ? "" : "Move Up"} placement="top">
          <IconButton
            className="marginActionButton"
            onClick={() => onClickArrowIcon(props.index, ARROW_ACTIONS.UP)}
            disabled={disableUpArrow}
          >
            <ArrowUpwardIcon color={disableUpArrow ? "disabled" : "primary"} />
          </IconButton>
        </Tooltip>

        <Tooltip title={disableDownArrow ? "" : "Move Down"} placement="top">
          <IconButton
            className="marginActionButton"
            onClick={() => onClickArrowIcon(props.index, ARROW_ACTIONS.DOWN)}
            disabled={disableDownArrow}
          >
            <ArrowDownwardIcon
              color={disableDownArrow ? "disabled" : "primary"}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default formSequenceCard;
