import { Button, CircularProgress, Dialog } from "@material-ui/core";
import React, { Component } from "react";
import * as animationData from "../../assets/waiting.json";
import Bio from "./bio";
import EducationAndCertifications from "./educationAndCertifications";
import GetStarted from "./getStarted";
import ProfilePreview from "./profilePreview";
import Programs from "./programs";
import Services from "./services";
import {
  validateBasicDetails,
  validateBioAndExperience,
  validateDocuments,
  validateProfile,
  validatePrograms,
  validateServices,
} from "./trainerValidator";
import Waiting from "./waiting";

const LAST_PAGE = 5;

const ONBOARDING_STEP_MAP = {
  1: "basicDetails",
  2: "bio",
  3: "documents",
  4: "services",
  5: "programs",
};

const VALIDATOR_FUNCTION_MAP = {
  1: validateBasicDetails,
  2: validateBioAndExperience,
  3: validateDocuments,
  4: validateServices,
  5: validatePrograms,
};

export default class OnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      trainerData: {},
      previewData: {},
      basicDetails: {},
      bio: {},
      shortCodes: [],
      services: [],
      documents: {},
      programs: [],
      showPreview: false,
      loading: true,
      showSuccessPage: false,
    };

    this.getStartedRef = React.createRef();
    this.basicDetailsRef = React.createRef();
    this.educationAndCertificationsRef = React.createRef();
    this.servicesRef = React.createRef();
    this.documentsRef = React.createRef();
    this.programsRef = React.createRef();
  }

  async componentDidMount() {
    let user = this.props.user;
    let trainerData;
    try {
      trainerData = await window.FortisForma.database.getPublicTrainerData(
        user.id
      );
      if (!trainerData) {
        trainerData = {};
      }
      trainerData.email = user.email;
      trainerData.phone = user.phone;
      let basicDetails = this.getStepData(trainerData, ONBOARDING_STEP_MAP[1]);
      let bio = this.getStepData(trainerData, ONBOARDING_STEP_MAP[2]);
      let documents = this.getStepData(trainerData, ONBOARDING_STEP_MAP[3]);
      let services = this.getStepData(trainerData, ONBOARDING_STEP_MAP[4]);
      let programs = this.getStepData(trainerData, ONBOARDING_STEP_MAP[5]);
      let profileErrors = this.getProfileErrors(trainerData);
      let pageNumber = this.getClosestStepWithErrors(profileErrors);
      this.setState({
        trainerData,
        previewData: trainerData,
        basicDetails,
        bio,
        services,
        documents,
        programs,
        profileErrors,
        currentPage: pageNumber,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to get trainer data");
    }

    this.setState({
      loading: false,
    });
  }

  getProfileErrors(trainerData) {
    let errors = validateProfile(trainerData);
    return errors;
  }

  getClosestStepWithErrors(errors) {
    if (!errors) {
      return 1;
    }
    let keys = Object.keys(errors);
    let positions = [];
    for (let key of Object.keys(ONBOARDING_STEP_MAP)) {
      if (keys.includes(ONBOARDING_STEP_MAP[key])) {
        positions.push(Number(key));
      }
    }
    return positions.sort()[0] || 1;
  }

  isComplete(step, customStepData) {
    let stepName = ONBOARDING_STEP_MAP[step];
    let stepData;
    if (customStepData) {
      stepData = customStepData;
    } else {
      stepData = this.state[stepName];
    }
    let validator = VALIDATOR_FUNCTION_MAP[step];
    let errors = validator(stepData);
    return !errors;
  }

  getStepData = (trainerData, step) => {
    switch (step) {
      case ONBOARDING_STEP_MAP[1]:
        let data = {
          picURL: trainerData.picURL,
          name: trainerData.name,
          email: trainerData.email,
          phone: trainerData.phone,
        };
        return data;

      case ONBOARDING_STEP_MAP[2]:
        data = {
          bio: trainerData.bio,
          address: trainerData.address,
          experience: trainerData.experience,
          valueProposition: trainerData.valueProposition,
        };
        return data;

      case ONBOARDING_STEP_MAP[3]:
        return trainerData.documents;

      case ONBOARDING_STEP_MAP[4]:
        return trainerData.services;

      case ONBOARDING_STEP_MAP[5]:
        return trainerData.programs;

      default:
        break;
    }
  };

  nextPage = () => {
    let currentPage = this.state.currentPage;
    this.setState({
      currentPage: ++currentPage,
    });
  };

  backPage = () => {
    let currentPage = this.state.currentPage;
    this.setState({
      currentPage: --currentPage,
    });
  };

  onNext = async () => {
    await this.handleDataSave();
  };

  onDone = async () => {
    await this.handleDataSave();
  };

  onSkip = () => {
    if (this.state.currentPage === Object.keys(ONBOARDING_STEP_MAP).length) {
      this.routeToDashboard();
      return;
    }
    this.nextPage();
  };

  handleDataSave = async () => {
    window.NotificationUtils.showSuccess("Saving");
    try {
      switch (this.state.currentPage) {
        case 1:
          let data = this.getStartedRef.current.getData();
          if (!data) {
            return;
          }
          this.setState({
            basicDetails: { ...data },
          });
          let email = window.emailTrimAndLowerCase(data.email);
          data.email = email;
          await window.FortisForma.database.updateUserData(data);
          delete data.email;
          delete data.phone;
          await window.FortisForma.database.saveOnBoardingDetails(data);
          await this.updateIncompleteFlag({ ...data });
          window.NotificationUtils.showSuccess("Saved Basic Details");

          this.nextPage();
          break;

        case 2:
          data = this.basicDetailsRef.current.getData();
          if (!data) {
            return;
          }
          this.setState({
            bio: data,
          });
          data.postalCode = data.address.postalCode || "";
          await window.FortisForma.database.saveOnBoardingDetails(data);
          await this.updateIncompleteFlag(data);
          window.NotificationUtils.showSuccess("Saved Bio");
          this.nextPage();
          break;

        case 3:
          data = this.educationAndCertificationsRef.current.getData();
          if (!data.data) {
            return;
          }
          this.setState({
            documents: data.data.documents,
          });

          await window.FortisForma.database.saveOnBoardingDetails(data.data);
          if (data.errors) {
            window.NotificationUtils.showSuccess(
              "Saved Education and Certifications"
            );
          } else {
            await this.updateIncompleteFlag(data.data.documents);
            window.NotificationUtils.showSuccess("Saved All Documents");
          }

          this.nextPage();
          break;

        case 4:
          data = this.servicesRef.current.getData();
          if (!data) {
            return;
          }
          this.setState({
            services: data.services,
          });
          await window.FortisForma.database.saveOnBoardingDetails(data);
          await this.updateIncompleteFlag(data.services);
          window.NotificationUtils.showSuccess("Saved Services");
          this.nextPage();
          break;

        case 5:
          data = this.programsRef.current.getData();
          if (!data) {
            return;
          }
          let firstTimeSave = !this.isComplete(5);
          this.setState({
            programs: data.programs,
          });
          await window.FortisForma.database.saveOnBoardingDetails(data);
          await this.updateIncompleteFlag(data.programs);
          window.NotificationUtils.showSuccess("Saved Programs");
          if (firstTimeSave) {
            this.setState({
              showSuccessPage: true,
            });
          } else {
            this.routeToDashboard();
          }
          break;

        default:
          window.NotificationUtils.showError("No step selected");
          break;
      }
    } catch (e) {
      window.NotificationUtils.showError("Unable to Save!");
      console.error(e);
    }
  };

  updateIncompleteFlag = async () => {
    let errors = this.getProfileErrors(this.getCurrentProfileData());
    let flag = true;
    if (!errors) {
      flag = false;
    }
    try {
      await window.FortisForma.database.updateIncompleteStatus(flag);
      if (flag === false) {
        this.props.onUserChange();
      }
    } catch (e) {
      throw e;
    }
  };

  routeToDashboard() {
    this.props.history.replace("/dashboard");
  }

  getCurrentProfileData() {
    let currentStepData = {};

    switch (this.state.currentPage) {
      case 1:
        currentStepData = this.getStartedRef.current.getData(false);
        break;

      case 2:
        currentStepData = this.basicDetailsRef.current.getData(false);
        break;

      case 3:
        currentStepData = this.educationAndCertificationsRef.current.getData(
          false
        );
        currentStepData =
          currentStepData &&
          currentStepData.data &&
          currentStepData.data.documents;
        break;

      case 4:
        currentStepData = this.servicesRef.current.getData(false);
        currentStepData = currentStepData && currentStepData.services;
        break;

      case 5:
        currentStepData = this.programsRef.current.getData(false);
        currentStepData = currentStepData && currentStepData.programs;
        break;

      default:
        window.NotificationUtils.showError("No step selected");
        break;
    }

    if (!currentStepData) {
      currentStepData = null;
    }
    let previewData = {
      ...this.state.basicDetails,
      ...this.state.bio,
      services: this.state.services,
      documents: this.state.documents,
      programs: this.state.programs,
    };

    if (currentStepData) {
      if (this.state.currentPage < 3) {
        previewData = { ...previewData, ...currentStepData };
      } else {
        previewData[
          ONBOARDING_STEP_MAP[this.state.currentPage]
        ] = currentStepData;
      }
    }

    return previewData;
  }

  showPreview = () => {
    let previewData = this.getCurrentProfileData();

    this.setState({
      previewData,
      showPreview: true,
    });
  };

  closePreview = () => {
    this.setState({
      showPreview: false,
    });
  };

  renderPreview() {
    return (
      <Dialog onClose={this.closePreview} open={this.state.showPreview}>
        <ProfilePreview
          trainerData={this.state.previewData}
          onClose={this.closePreview}
        />
      </Dialog>
    );
  }

  getTopButtonText() {
    if (this.isComplete(this.state.currentPage)) {
      return "NEXT";
    }

    if (this.state.currentPage === LAST_PAGE) {
      return "CLOSE";
    }

    return "SKIP";
  }

  getBottomButtonText() {
    if (this.isComplete(this.state.currentPage)) {
      return "UPDATE";
    }

    if (this.state.currentPage === LAST_PAGE) {
      return "FINISH";
    }

    return "SAVE & NEXT";
  }

  renderRightSide = () => {
    return (
      <div className="rightSideInnerContainer">
        <div className="pageCount">
          <span>
            {this.state.currentPage || 1}/{LAST_PAGE}
          </span>
        </div>
        <div className="previewButtonContainer">
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={this.showPreview}
          >
            Profile Preview
          </Button>
        </div>
        <div className="onboardingButtonContainer">
          <Button
            color="default"
            variant="outlined"
            className="onboardingMainButton"
            onClick={this.onSkip}
          >
            {this.getTopButtonText()}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onNext}
            className="onboardingMainButton"
          >
            {this.getBottomButtonText()}
          </Button>
        </div>
      </div>
    );
  };

  renderLeftSide = () => {
    return (
      <div>
        {this.state.currentPage !== 1 ? (
          <div
            className="goBackContainer onBoardingHeading"
            onClick={this.backPage}
          >
            Go Back
          </div>
        ) : null}
      </div>
    );
  };

  renderMainContent = () => {
    switch (this.state.currentPage) {
      case 1:
        return (
          <GetStarted
            ref={this.getStartedRef}
            data={this.state.basicDetails}
            {...this.props}
          />
        );

      case 2:
        return (
          <Bio
            ref={this.basicDetailsRef}
            data={this.state.bio}
            {...this.props}
          />
        );

      case 3:
        return (
          <EducationAndCertifications
            ref={this.educationAndCertificationsRef}
            data={this.state.documents}
            {...this.props}
          />
        );

      case 4:
        return (
          <Services
            ref={this.servicesRef}
            data={this.state.services}
            {...this.props}
          />
        );

      case 5:
        return (
          <Programs
            ref={this.programsRef}
            data={this.state.programs}
            {...this.props}
          />
        );

      default:
        return (
          <GetStarted
            ref={this.getStartedRef}
            data={this.state.basicDetails}
            {...this.props}
          />
        );
    }
  };

  renderSuccessPage() {
    return (
      <Waiting
        animationData={animationData.default}
        actionCallback={() => {
          this.routeToDashboard();
        }}
        actionLabel={"Open dashboard"}
      />
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingCenter">
          <CircularProgress color="primary" />
        </div>
      );
    }

    if (this.state.showSuccessPage) {
      return this.renderSuccessPage();
    }
    return (
      <div className="onboardingContainer">
        <div
          className={
            this.state.currentPage === 1
              ? "onboardingLeftSide hideItem"
              : "onboardingLeftSide"
          }
        >
          {this.renderLeftSide()}
        </div>
        <div className="onboardingMainContent">{this.renderMainContent()}</div>
        <div
          className={
            this.state.currentPage === 1
              ? "onboardingRightSide stepOneButtonContainer"
              : "onboardingRightSide"
          }
        >
          {this.renderRightSide()}
        </div>
        {this.renderPreview()}
      </div>
    );
  }
}
