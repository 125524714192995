import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { Component, createRef } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import HeaderDetails from "./headerDetails";
import PreviewForm from "../../forms/previewFormModal";
import PDFViewer from "../../forms/pdfViewer";
import DatePickerWidget from "../../datePickerWidget/datePickerWidget";
import PhoneFieldWidget from "../../phoneFieldWidget/phoneFieldWidget";
import { isMobile } from "react-device-detect";
import SignatureField from "../../signatureField/signatureField";
import TAButton from "../../taButton/taButton";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import _ from "lodash";
import FreehandNote from "../../freehandNoteWidget/freehandNote";
import AddImageWidget from "../../addImageWidget/addImageWidget";
import { GET_ENT_ID, GET_USER_ID, ROLES, TIER } from "../../../constants";

const LOADING_BUTTON_KEY = {
  PRIMARY: "primaryLoading",
  SECONDARY: "secondaryLoading",
};

export default class NoteAssessmentEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSaveButton: false,
      loading: false,
      hasError: false,
      isSuccess: false,
      showFormsModal: false,
      form: null,
      previewForm: false,
      isClientForm: false,
      id: (this.props.selectedItem && this.props.selectedItem.id) || "",
      submissionData: null,
      formId: (this.props.selectedItem && this.props.selectedItem.formId) || "",
      primaryLoading: false,
      secondaryLoading: false,
      creatorDetails: {},
      loadingCreator: false,
      isEditable: true,
    };
    this.editMode = Boolean(
      this.props.selectedItem && this.props.selectedItem.id
    );
    this.isLocked = Boolean(
      this.props.selectedItem && this.props.selectedItem.locked
    );
    this.isEdited = Boolean(
      this.props.selectedItem && this.props.selectedItem.edited
    );
    // this.isEdited = Boolean(
    //   this.props.selectedItem && this.props.selectedItem.edited
    // );

    this.previewFormRef = createRef();
  }

  componentDidMount() {
    this.fetchCreatorDetails();
  }

  fetchCreatorDetails = async () => {
    this.setState({ loadingCreator: true });
    try {
      const item = this.props.selectedItem;
      if (!_.isEmpty(item) && _.has(item, "creatorId")) {
        const users = [item.creatorId];
        if (!_.isEmpty(users)) {
          let result =
            await window.FortisForma.database.fetchEnterpriseStaffDetails(
              users
            );
          if (!_.isEmpty(result)) {
            this.setState({ creatorDetails: result[0] });
          }
        }
      }
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
    this.setState({ loadingCreator: false });
  };

  setPreviewOpened = (value) => {
    this.setState({ previewOpened: value });
  };

  handleChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  disableSaveButton = () => {
    this.setState({
      disableSaveButton: true,
    });
  };

  enableSaveButton = () => {
    this.setState({
      disableSaveButton: false,
    });
  };

  openFormsModal = () => {
    this.setState({ showFormsModal: true });
  };

  closeFormsModal = () => {
    this.setState({ showFormsModal: false });
  };

  onClickPreview = (form) => {
    this.setState({
      form: form,
      previewForm: true,
      showFormsModal: false,
      isClientForm: Boolean(form.clientId),
    });
  };

  closePreviewForm = () => {
    this.setState({
      previewForm: false,
      form: {},
      isClientForm: false,
      showFormsModal: Boolean(!this.state.isClientForm),
      formTitle: this.previewFormRef.getFormTitle(),
    });
  };

  getItemData = () => {
    const enterpriseId = GET_ENT_ID(this.props);
    const userID = GET_USER_ID(this.props);
    let data = {
      id: this.getItemId(),
      enterpriseId: enterpriseId,
      clientId: this.props.clientId || "",
      // creatorId: this.props.user.id,
    };
    if (
      this.props.user.role === ROLES.HEALTH_COACH ||
      this.props.user.role === ROLES.FACILITY_ADMIN
    ) {
      data.creatorId = this.props.user.id;
    } else {
      data.creatorId = userID;
    }
    if (this.props.user.tier === TIER.PRIVATE && !this.editMode) {
      delete data["enterpriseId"];
      // data.userId = this.props.user.id;
      data.userId = userID;
    }
    if (this.editMode && this.props.selectedItem.userId) {
      delete data["enterpriseId"];
      // data.userId = this.props.user.id;
      data.userId = userID;
    }
    if (this.props.clientLoginPending) {
      data.isInvite = true;
    }

    if (this.editMode && this.isLocked) {
      data.edited = true;
    }

    if (this.editMode && this.props.selectedItem.updatedTime) {
      data.updatedTime = this.props.selectedItem.updatedTime;
    }

    if (this.state.formToSave || this.state.formId) {
      data.formId = this.state.formToSave || this.state.formId;
    }

    if (
      this.state.submissionData &&
      Object.keys(this.state.submissionData).length
    ) {
      data.submissionData = this.state.submissionData;
    }

    if (this.editMode && this.props.selectedItem.submissionId) {
      data.submissionId = this.props.selectedItem.submissionId;
    }

    return { ...data, ...this.getItemSpecificData() };
  };

  handleSave = async (locked = false, buttonKey) => {
    this.setState({
      [buttonKey]: true,
    });
    if (this.hasNoContent()) {
      this.setState({ [buttonKey]: false });
      return;
    }
    let data = this.getItemData();

    if (locked || this.isLocked) {
      data.locked = true;
    }
    try {
      await this.props.onSave(data);
      window.NotificationUtils.showSuccess(this.getSuccessMessage());
      this.setState(
        { formToSave: "", submissionData: null },
        this.props.onClose
      );
    } catch (e) {
      console.log("eeeeee", e);
      window.NotificationUtils.showError(this.getErrorMessage());
      console.error(e);
      this.setState({ [buttonKey]: false });
    }
  };

  onSubmit = ({ formData, submittedQuestions, totalQuestions }) => {
    let submissionId = (this.state.form && this.state.form.submissionId) || "";
    let data = {
      formData: formData,
      formId: this.state.form.id,
      clientId: this.props.clientId,
      id: submissionId,
      clientEmail: this.props.clientEmail,
      total: totalQuestions,
    };

    data.submitted = submittedQuestions;

    if (this.props.clientLoginPending) {
      data.isInvite = true;
    }

    this.setState({ submissionData: data }, this.closePreviewForm);
  };

  getItemId = () => {
    if (this.editMode) {
      return this.props.selectedItem.id;
    }
    if (this.state.formToSave) {
      return this.state.id;
    }
    return "";
  };

  renderPreviewFormModal = () => {
    const showSubmit = Boolean(this.state.form && this.state.isClientForm);
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closePreviewForm}
        open={this.state.previewForm}
        maxWidth="md"
      >
        <PreviewForm
          ref={(ref) => (this.previewFormRef = ref)}
          closePreviewForm={this.closePreviewForm}
          data={this.state.form}
          widgets={{
            signatureField: SignatureField,
            pdfViewer: PDFViewer,
            DateWidget: DatePickerWidget,
            phoneField: PhoneFieldWidget,
            freehandNote: FreehandNote,
            addImageWidget: AddImageWidget,
          }}
          submissionData={
            (this.state.form && this.state.form.submissionData) || {}
          }
          onSubmit={showSubmit && this.onSubmit}
        />
      </Dialog>
    );
  };

  renderCloseButton = () => {
    return (
      <IconButton className="notecloseIconButton" onClick={this.props.onClose}>
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  renderHeader = () => {
    return (
      <HeaderDetails
        clientName={this.props.clientName}
        clientEmail={this.props.clientEmail || ""}
        clientDOB={this.props.clientDOB || ""}
      />
    );
  };

  deletePendingForm = async (handleCloseCallback) => {
    window.NotificationUtils.showConfirm("Discarding Data");
    if (this.state.formToSave) {
      try {
        await this.props.deletePendingForm(this.state.formToSave);
      } catch (e) {
        window.NotificationUtils.showError("Error discarding data");
        console.error(e);
      }
    }
    handleCloseCallback();
  };

  getCustomConfirmMessage = () => {
    if (this.state.formToSave) {
      return "We found a form that was assigned to the client, what would you like to do with it?";
    }

    if (this.state.submissionData) {
      return "We found submitted form data, what would you like to do with it?";
    }
  };

  confirmClose = (handleCloseCallback) => {
    if (this.state.formToSave || this.state.submissionData) {
      window.customConfirm(
        this.getCustomConfirmMessage(),
        () => this.deletePendingForm(handleCloseCallback),
        null,
        {
          cancelButtonText: "Keep",
          okButtonText: "Discard",
        }
      );
      return;
    }
    return handleCloseCallback();
  };

  renderFormSelector = () => {
    if (this.state.formId || this.state.formToSave) {
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            margin: "8px 0",
          }}
        >
          <b>Filled form: </b>
          {this.renderViewFormText()}
        </span>
      );
    }
    return (
      <>
        <Button
          variant="outlined"
          onClick={this.openFormsModal}
          color="primary"
          style={{ margin: "8px 0" }}
        >
          Add Form
        </Button>
      </>
    );
  };

  renderViewFormText = () => {
    return (
      <div
        className="viewFormButtonText"
        style={{ marginLeft: "4px" }}
        onClick={this.onClickViewForm}
      >
        View Form
      </div>
    );
  };

  getPrimaryNoteButtonLabel = () => {
    if (this.editMode) {
      return "Update";
    }
    return "Save";
  };

  getSecondaryNoteButtonLabel = () => {
    if (this.editMode) {
      return "Update & Lock";
    }
    return "Save & Lock";
  };

  renderPrimaryButton = () => {
    return (
      <TAButton
        className={this.state.secondaryLoading && "displayNone"}
        isLoading={this.state.primaryLoading}
        loadingMessage="Saving"
        disabled={this.state.disableSaveButton}
        onClick={() =>
          this.handleSave(this.isWorkoutNote(), LOADING_BUTTON_KEY.PRIMARY)
        }
        color="primary"
      >
        {this.getPrimaryNoteButtonLabel()}
      </TAButton>
    );
  };

  renderSecondaryButton = () => {
    if ((this.editMode && this.isLocked) || this.isWorkoutNote()) {
      return null;
    }
    return (
      <TAButton
        className={this.state.primaryLoading && "displayNone"}
        isLoading={this.state.secondaryLoading}
        loadingMessage="Saving"
        disabled={this.state.disableSaveButton}
        onClick={() => this.handleSave(true, LOADING_BUTTON_KEY.SECONDARY)}
        color="primary"
        endIcon={<LockOutlinedIcon />}
      >
        {this.getSecondaryNoteButtonLabel()}
      </TAButton>
    );
  };

  render() {
    return (
      <div
        style={{ backgroundColor: this.state.color }}
        className={this.state.previewOpened ? "hidden" : ""}
      >
        <DialogContent className="noteEditorContentArea">
          <Typography variant="h5" className="noteEditorTitle boldText">
            {this.getEditorTitle()}
          </Typography>
          {this.renderCloseButton()}
          {this.renderHeader()}
          {this.renderEditorContent()}
          {this.renderFormSelector()}
          {this.renderImageUploader()}
          {this.renderFormsModal()}
        </DialogContent>
        {this.renderActions()}
      </div>
    );
  }
}
