import React, { Component } from "react";

import { Container, Row, Col, Spinner } from "reactstrap";

import {
  EMAIL_VERIFICATION_STATES,
  LOCAL_STORAGE_KEYS,
  CONTINUE_ROUTES,
  WEB_APP_URL,
} from ".././../constants";

const TIMER = 8000;

function getDomainName(hostName) {
  if (hostName === "localhost") {
    return "localhost:3000";
  }
  return hostName;
}

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: EMAIL_VERIFICATION_STATES.LOADING,
      remaining: TIMER / 1000,
      routeTo: null,
    };
  }

  componentDidMount() {
    let querySearchParams = this.props.location.search;
    querySearchParams = querySearchParams + this.props.location.hash;
    let params = new URLSearchParams(querySearchParams);
    let code = params.get("code");
    let continueUrl = params.get("continueUrl");
    if (!this.props.match || !this.props.match.params) {
      this.setState({
        status: EMAIL_VERIFICATION_STATES.INVALID_CODE,
      });
      return;
    }

    window.FortisForma.database
      .checkActionCode(code)
      .then(() => {
        //Valid Code
        window.FortisForma.database
          .applyActionCode(code)
          .then((result) => {
            //Verification Successful
            this.setState({
              code,
            });
            window.localStorage.removeItem(
              LOCAL_STORAGE_KEYS.VERIFICATION_PENDING
            );
            this.onVerifySuccess(continueUrl);
          })
          .catch((e) => {
            //Verification Unsuccessful
            console.error(e);
            this.setState({
              status: EMAIL_VERIFICATION_STATES.INVALID_CODE,
            });
            this.startFailureTimer();
          });
      })
      .catch((e) => {
        //Invalid Code
        console.error(e);
        window.NotificationUtils.showError(
          "Expired or Invalid Reset Code",
          1500,
          "bottomCenter"
        );
        this.setState({
          status: EMAIL_VERIFICATION_STATES.INVALID_CODE,
        });
        this.startFailureTimer();
      });
  }

  onVerifySuccess(continueUrl) {
    if (continueUrl) {
      this.setContinueURL(continueUrl);
    } else {
      this.setState({
        status: EMAIL_VERIFICATION_STATES.EMAIL_VERIFIED,
      });
      this.startTimer(5000);
    }
  }

  async setContinueURL(continueUrl) {
    let urlParams = continueUrl.split("/");
    let lastParam = urlParams[urlParams.length - 1];
    let clientUid = lastParam.split("uid=").pop();
    if (
      !continueUrl.includes(CONTINUE_ROUTES.APP) &&
      !continueUrl.includes(WEB_APP_URL[0]) &&
      !continueUrl.includes(WEB_APP_URL[1])
    ) {
      this.setState({
        status: EMAIL_VERIFICATION_STATES.LOADING,
      });
      let uid = this.props.location.hash.substring(
        this.props.location.hash.indexOf("uid=") + 4
      );
      // doesnt throw error
      await window.FortisForma.database.onTrainerVerify(uid);
      this.setState({
        routeTo: `${CONTINUE_ROUTES.DASHBOARD}`,
        status: EMAIL_VERIFICATION_STATES.EMAIL_VERIFIED,
      });
      this.startTimer();
      return;
    } else {
      try {
        this.setState({
          status: EMAIL_VERIFICATION_STATES.LOADING,
          routeTo: `${continueUrl}#/${CONTINUE_ROUTES.APP}`,
        });
        let result = await window.FortisForma.database.clientEmailVerification({
          uid: clientUid,
        });
        if (result) {
          this.setState({
            type: "client",
            status: EMAIL_VERIFICATION_STATES.EMAIL_VERIFIED,
            routeTo: continueUrl,
            remaining: 0,
          });

          window.location.href = continueUrl;
          return;
        } else {
          this.setState({
            status: EMAIL_VERIFICATION_STATES.INVALID_CODE,
          });
          this.startFailureTimer();
        }
      } catch (e) {
        console.error(e);
        // TODO: we need to handle this case
        window.NotificationUtils.showError(
          "Something went wrong while verifying your email"
        );
        this.startFailureTimer();
      }
    }
  }

  startTimer(timer = TIMER) {
    this.timer = setTimeout(() => {
      if (!this.state.routeTo) {
        window.location =
          window.location.protocol +
          "//" +
          getDomainName(window.location.hostname);
      } else {
        this.props.history.replace(this.state.routeTo);
      }
    }, timer - 500);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, timer / (timer / 1000));
  }

  startFailureTimer(timer = TIMER) {
    this.timer = setTimeout(() => {
      if (window.FortisForma.database.currentUser()) {
        this.props.history.replace("/resend-email");
      } else {
        window.location =
          window.location.protocol +
          "//" +
          getDomainName(window.location.hostname);
      }
    }, timer - 500);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, timer / (timer / 1000));
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.interval);
  }

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  renderEmailVerifiedContent() {
    return (
      <div className="welcomeMessage">
        <Row style={{ paddingBottom: 100 }}>
          <Col className="center">
            <img
              alt="FortisForma Logo"
              className="logo"
              src="/assets/img/logo.png"
            />
          </Col>
        </Row>
        <h3>Email Verified Successfully!</h3>
        {this.state.type !== "client" ? (
          <p>
            <p className="redirect">
              {" "}
              Please login to continue using Fortis Forma.
            </p>
          </p>
        ) : (
          <p>You can continue to use the app back on your mobile device</p>
        )}

        {this.state.remaining > 0 && (
          <p className="redirect">
            Redirecting in {this.state.remaining} seconds...
          </p>
        )}
      </div>
    );
  }

  renderEmailVerificationFailedContent() {
    return (
      <div className="welcomeMessage">
        <Row style={{ paddingBottom: 100 }}>
          <Col className="center">
            <img
              alt="FortisForma Logo"
              className="logo"
              src="/assets/img/logo.png"
            />
          </Col>
        </Row>
        <h3>Email Verification Failed!</h3>
        <p className="redirect">
          Link seems to invalid or already used. Please Login again to retry.
        </p>
        {this.state.remaining > 0 && (
          <p className="redirect">
            Taking you back in {this.state.remaining} seconds...
          </p>
        )}
      </div>
    );
  }

  _render() {
    switch (this.state.status) {
      case EMAIL_VERIFICATION_STATES.INVALID_CODE:
        return this.renderEmailVerificationFailedContent();

      case EMAIL_VERIFICATION_STATES.EMAIL_VERIFIED:
        return this.renderEmailVerifiedContent();

      case EMAIL_VERIFICATION_STATES.LOADING:
        return this.progressIndicator();

      default:
        break;
    }
  }

  render() {
    return <Container className="loginBody">{this._render()}</Container>;
  }
}
