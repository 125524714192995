import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import React from "react";
import ImageUploader from "../uploaderImage/imageUploader";

export default class ImageUploaderModal extends React.Component {
  state = {
    images: Object.assign([], this.props.selectedFiles) || [],
    disableSaveButton: false,
  };

  componentDidMount = () => {
    if (!this.props.editMode) {
      setTimeout(this.openUploader, 0);
    }
  };
  openUploader = () => {
    document.getElementById("fileUploader").click();
  };

  onChangeImages = (images) => {
    this.setState(
      {
        images: images,
      },
      this.enableSaveButton
    );
  };

  disableSaveButton = () => {
    this.setState({
      disableSaveButton: true,
    });
  };
  enableSaveButton = () => {
    this.setState({
      disableSaveButton: false,
    });
  };

  renderCloseButton = () => {
    return (
      <IconButton
        onClick={() => this.props.onClose(this.props.selectedFiles)}
        disabled={this.state.disableSaveButton}
        id="uploaderModalCloseButton"
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  setPreviewOpened = (value) => {
    this.setState({
      previewOpened: value,
    });
  };

  renderUploadedFiles = () => {
    return (
      <React.Fragment>
        <ImageUploader
          enableSaveButton={this.enableSaveButton}
          disableSaveButton={this.disableSaveButton}
          selectedImages={
            (this.state.images.length > 0 && this.state.images) || []
          }
          onChange={this.onChangeImages}
          setPreviewOpened={this.setPreviewOpened}
          pathToStore={this.props.pathToStore}
          extensions={this.props.extensions && this.props.extensions}
          isDraggable={this.props.isDraggable}
        />
      </React.Fragment>
    );
  };

  onSave = () => {
    this.props.onChange(this.state.images);
  };
  render() {
    return (
      <Dialog open={true} maxWidth="sm" fullWidth={true}>
        {this.renderCloseButton()}
        <DialogContent>
          <Typography variant="h5" className="uploaderTitle">
            Attach files
          </Typography>
          {this.renderUploadedFiles()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onSave}
            disabled={this.state.disableSaveButton}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
