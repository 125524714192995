import { Button, Dialog, DialogActions } from "@material-ui/core";
import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

export default function SignatureField(props) {
  const { value, onChange } = props;
  let sigPadRef = {};
  sigPadRef[props.id] = useRef(null);
  const [showSignaturePad, handleSignaturePad] = useState(false);

  const handleChange = (val) => {
    onChange(val);
  };

  function onClear() {
    sigPadRef[props.id].clear();
  }

  function onClose() {
    handleSignaturePad(false);
  }

  function onSave() {
    let signURL =
      sigPadRef[props.id] &&
      sigPadRef[props.id].getTrimmedCanvas().toDataURL("image/png");
    handleChange(signURL);
    onClose(false);
  }

  function renderSignaturePad() {
    return (
      <Dialog open={showSignaturePad} onClose={() => handleSignaturePad(false)}>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          ref={(ref) => {
            sigPadRef[props.id] = ref;
          }}
        />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button color="primary" onClick={onClear}>
            Clear
          </Button>
          <Button color="primary" variant="contained" onClick={onSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function renderSignature() {
    return <img src={value} alt="signature" className="signaturePad" />;
  }

  function renderPlaceholder() {
    return <div className="signPlaceholder">Click to sign</div>;
  }

  return (
    <div>
      <div className="signaturePad" onClick={() => handleSignaturePad(true)}>
        {value ? renderSignature() : renderPlaceholder()}
      </div>
      {renderSignaturePad()}
    </div>
  );
}
