import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export default class RenderImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableDelete: this.props.disableDelete ? true : false,
    };
  }
  render() {
    return (
      <div className="imageMainContainer">
        <div className="logoAndNameContainer">
          <img
            src={this.props.imageURL}
            alt="imgPreview"
            className="logoImageContainer"
          />
          <Typography className="logoFileName" variant="subtitle1">
            {this.props.imageName}
          </Typography>
        </div>
        <div className="binDeleteContainer">
          <IconButton
            disabled={this.state.disableDelete}
            onClick={this.props.onClickDelete}
          >
            <DeleteOutlineIcon
              color={this.state.disableDelete ? "" : "primary"}
            />
          </IconButton>
        </div>
      </div>
    );
  }
}
