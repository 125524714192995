import React from "react";
import classNames from "classnames";
import { Col, Row } from "reactstrap";
import WalkthroughEventDispatcher from "../../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import { SHARE_WORKOUT_WALKTHROUGH } from "../../../modules/walkThrough/walkThrough";
import { WALKTHROUGH } from "../../../constants";
import Joyride from "react-joyride";
import Tooltip from "@material-ui/core/Tooltip";
import ShareWorkoutButton from "../../../components/shareWorkoutButton/shareWorkoutButton";

export default class WorkoutDayCard extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      walkthrough: false,
      steps: SHARE_WORKOUT_WALKTHROUGH.steps,
    };
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.SHARE_WORKOUT;
  }

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          disableScrolling={true}
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          locale={{ last: "Done" }}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
            spotlight: {
              maxWidth: "250px",
            },
          }}
        />
      </div>
    );
  };

  getExerciseCount() {
    if (this.props.data.exercises) {
      return this.props.data.exercises.length;
    }
    let count = 0;
    if (this.props.data.workoutSections) {
      for (let section of this.props.data.workoutSections) {
        if (section.exercises && section.exercises.length > 0) {
          for (let exercise of section.exercises) {
            if (exercise) {
              count++;
            }
          }
        }
      }
      return count;
    }
    return 0;
  }

  render() {
    var rowClass = classNames({
      selectedCard: this.props.selected,
      clientSMCardUpdated: !this.props.selected,
      centerAlignJustify: true,
    });
    return (
      <React.Fragment>
        {this.props.enableWalkthrough &&
          this.state.walkthrough &&
          this.renderJoyride()}
        <Row
          className={rowClass}
          onClick={(event) => this.props.onSelect(this.props.data, event)}
        >
          <Col
            lg={{ size: "5", offset: 1 }}
            md={{ size: "5", offset: 1 }}
            xs={{ size: "9" }}
            className="centerAlignJustify decPadding"
            style={{ justifyContent: "start", paddingVertical: 0 }}
          >
            {this.props.data.name}
          </Col>
          <Col
            lg="2"
            md="2"
            sm="2"
            xs="3"
            className="centerAlignJustify decFontSize"
          >
            <p style={{ marginBottom: 0 }}>{this.getExerciseCount()}</p>
          </Col>
          <Col lg="4" md="4" xs="12" id="forMobileView">
            <Row>
              <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify">
                <Tooltip title="Copy this workout" placement={"top"} arrow>
                  <i
                    id="copyWorkoutButton"
                    className="icon material-icons"
                    onClick={(event) => {
                      event.stopPropagation && event.stopPropagation();
                      this.props.copyWorkoutDay(this.props.data);
                    }}
                  >
                    file_copy
                  </i>
                </Tooltip>
              </Col>

              <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify">
                <Tooltip title="Edit this workout" placement={"top"} arrow>
                  <i
                    className="icon material-icons"
                    onClick={(event) => {
                      event.stopPropagation && event.stopPropagation();
                      this.props.editWorkoutDay(this.props.data);
                    }}
                  >
                    edit
                  </i>
                </Tooltip>
              </Col>
              <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify">
                <Tooltip title="Delete this workout" placement={"top"} arrow>
                  <i
                    className="icon material-icons"
                    onClick={(event) => {
                      event.stopPropagation && event.stopPropagation();
                      this.props.deleteWorkoutDay(this.props.data);
                    }}
                  >
                    delete
                  </i>
                </Tooltip>
              </Col>
              {!this.props.type && (
                <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify">
                  <Tooltip title="Assign this workout" placement={"top"} arrow>
                    <i
                      data-tip=""
                      className="icon material-icons"
                      onClick={(event) => {
                        event.stopPropagation && event.stopPropagation();
                        this.props.assignWorkoutDay(this.props.data);
                      }}
                    >
                      assignment
                    </i>
                  </Tooltip>
                </Col>
              )}

              <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify">
                <ShareWorkoutButton
                  onSelectShareOption={(option) => {
                    this.props.onClickShareIcon(this.props.data, option);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
