import React, { Component } from "react";
import { Button, ModalBody, ModalFooter, Modal } from "reactstrap";

export default class RepeatCircuitModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick.bind(this);
  }
  handleClick(value, type) {
    this.props.selectedSection(value, type);
  }
  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} className="repeatCircuitModal">
          <ModalBody>
            <div className="content">
              <h1>
                How many times do you want your client to repeat this circuit?
              </h1>
              <input
                type="number"
                className="noInput"
                value={this.props.count}
                onChange={(e) => this.handleClick(e.target.value, "count")}
              ></input>
              <input
                type="text"
                value={this.props.description}
                onChange={(e) =>
                  this.handleClick(e.target.value, "description")
                }
                placeholder="you can type your extra components for your client here..."
              ></input>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginTop: "14px" }}>
            <Button
              onClick={this.props.onCancel}
              className="btn btn-outline-secondary btn btn-secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.props.circuitCountHandler(
                  this.props.count,
                  this.props.description
                )
              }
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
