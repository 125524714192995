import React, { Component } from "react";
import { BubbleLoader } from "react-css-loaders";
import { components } from "react-select";
import { FormGroup } from "reactstrap";
import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect";
import { COLLECTIONS, DB_KEYS, ROLES } from "../../constants";
import bodybuilder from "bodybuilder";

export const TEMPLATE_OPTION = {
  label: "My Workout Templates",
  options: [{ label: "My Workout Templates", value: "my_workouts" }],
};
export const ALL_WORKOUTS = {
  label: "All workouts",
  options: [{ label: "All workouts", value: "all_workouts" }],
};
export const DRAFTS_OPTION = {
  label: "My Drafts",
  options: [{ label: "My Drafts", value: "my_workouts" }],
};
export const ALL_DRAFTS = {
  label: "All Drafts",
  options: [{ label: "All Drafts", value: "all_workouts" }],
};

const groupStyles = {
  color: "black",
  background: "white",
  padding: "5px 0px",
  display: "flex",
  alignItems: "center",
};

export default class MergedClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workoutType: "",
      requestedClients: [],
      pendingClients: [],
      mergedList: [],
      clientListOptions: [],
      loading: true,
    };
    this.fetchClients = this.fetchClients.bind(this);
    this.isEnterpriseMember =
      (this.props.currentUser && this.props.currentUser.enterpriseId) ||
      (this.props.ppEnterprise && this.props.ppEnterprise.id)
        ? true
        : false;
  }

  componentDidMount() {
    this.fetchClients();
  }

  async fetchClients() {
    this.loading = true;

    let query = {};
    let currentUser = this.props.currentUser;

    if (!currentUser) {
      throw new Error("User not logged in");
    }

    if (!query.filter) {
      query.filters = [];
    }

    query.collection = COLLECTIONS.USER_DATA;

    query.filters = [
      {
        key: DB_KEYS.TRAINER_ID_KEY,
        operator: "==",
        value: currentUser.id,
      },
    ];

    try {
      var mergedList = [];
      if (!this.isEnterpriseMember) {
        let requestedClients = await window.FortisForma.database.queryData(
          query
        );
        requestedClients &&
          requestedClients.sort((a, b) => {
            let nameA = (a.name || "").toLowerCase();
            let nameB = (b.name || "").toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

        query.collection = COLLECTIONS.INVITES;
        let pendingInvites = await window.FortisForma.database.queryData(query);
        for (let invite of pendingInvites) {
          invite.pendingLogin = true;
        }

        let pendingClients = pendingInvites;
        pendingClients &&
          pendingClients.sort((a, b) => {
            let nameA = (a.name || "").toLowerCase();
            let nameB = (b.name || "").toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

        mergedList = [...requestedClients, ...pendingClients];
      } else {
        query = this.prepareSearchFilters();
        mergedList = await window.FortisForma.database.fetchEnterpriseClients(
          query
        );
      }

      this.mergeClientList(mergedList);

      return;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }
  //staging filter working f9
  // prepareSearchFilters() {
  //   let filters = bodybuilder();
  //   let query = "";
  //   filters.query("query_string", {
  //     query: "*" + query + "*",
  //     fields: ["name", "email"],
  //   });
  //   filters.filter("term", "role", ROLES.CLIENT);

  //   if (this.props.ppEnterprise) {
  //     filters.orFilter("term", "enterpriseId", this.props.ppEnterprise.id);
  //   } else {
  //     filters.orFilter(
  //       "term",
  //       "enterpriseId",
  //       this.props.currentUser.enterpriseId
  //     );
  //   }
  //   filters.orFilter("term", "trainerId", this.props.currentUser.id);
  //   return filters.build();
  // }

  //change staging filter due to version diff
  prepareSearchFilters() {
    let filters = bodybuilder();
    let query = "";
    filters.query("query_string", {
      query: "*" + query + "*",
      fields: ["name", "email"],
    });
    filters.filter("term", "role", ROLES.CLIENT);
    if (
      this.props.currentUser.role === ROLES.FACILITY_ADMIN ||
      this.props.currentUser.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter(
              "term",
              "enterpriseId",
              this.props.currentUser.enterpriseId
            )
            .orFilter("term", "trainerId", this.props.ppEnterprise.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q.orFilter(
            "term",
            "enterpriseId",
            this.props.currentUser.enterpriseId
          )
        );
      }
    } else {
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
            .orFilter("term", "trainerId", this.props.currentUser.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter(
              "term",
              "enterpriseId",
              this.props.currentUser.enterpriseId
            )
            .orFilter("term", "trainerId", this.props.currentUser.id)
        );
      }
    }
    if (this.props.currentUser.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: this.props.user.id, ...query };
    } else {
      return filters.build();
    }
    // return filters.build();
  }

  mergeClientList = (mergedList) => {
    mergedList.sort((a, b) => {
      if (!a.name || !b.name) {
        return 0;
      }
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });

    var optionsList = mergedList.map((obj) => {
      const clientOptions = {};
      clientOptions.label = obj.name;
      clientOptions.value = obj;
      return clientOptions;
    });

    this.setState({
      clientListOptions: optionsList,
      loading: false,
    });
  };

  renderLoader() {
    return (
      <div className="spinnerContainer">
        <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
      </div>
    );
  }

  GroupHeading = (props) => {
    return (
      <div style={groupStyles}>
        <components.GroupHeading {...props} />
      </div>
    );
  };

  renderShowBoth = () => {
    let finalOptions = [];
    if (this.props.type) {
      finalOptions = [
        ALL_DRAFTS,
        DRAFTS_OPTION,
        {
          label: "Client Drafts",
          options: this.state.clientListOptions,
        },
      ];
    } else {
      finalOptions = [
        ALL_WORKOUTS,
        TEMPLATE_OPTION,
        {
          label: "Client workout templates",
          options: this.state.clientListOptions,
        },
      ];
    }

    return (
      <FormGroup id="workoutDaySelect">
        <FortisFormaSelect
          style={this.props.styles}
          isClearable={this.props.isClearable}
          placeholder={
            this.state.clientListOptions.length
              ? this.props.placeholder
              : "No clients to select from"
          }
          value={this.props.selectedWorkout}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.props.handleWorkoutSelect}
          handleLoading={false}
          options={finalOptions}
          components={{ GroupHeading: this.GroupHeading }}
          styles={{
            groupHeading: (base) => ({
              ...base,
              flex: "1 1",
              color: "#812520",
              margin: 0,
            }),
          }}
        />
      </FormGroup>
    );
  };

  renderShowClient = () => {
    return (
      <FormGroup id="workoutDaySelect">
        <FortisFormaSelect
          isClearable={true}
          placeholder={
            this.state.clientListOptions.length
              ? this.props.placeholder
              : "No clients to select from"
          }
          value={this.props.selectedWorkout}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.props.handleWorkoutSelect}
          handleLoading={false}
          options={this.state.clientListOptions}
          components={this.GroupHeading}
          styles={{
            groupHeading: (base) => ({
              ...base,
              flex: "1 1",
              color: "white",
              margin: 0,
            }),
          }}
        />
      </FormGroup>
    );
  };

  render() {
    return this.state.loading
      ? this.renderLoader()
      : this.props.showClientsOnly
      ? this.renderShowClient()
      : this.renderShowBoth();
  }
}
