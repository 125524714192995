import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import React, { useEffect, useState } from "react";
import { CircularProgress, Badge } from "@material-ui/core";
import TAButton from "../../components/taButton/taButton";
import NoteAssessmentVersionItem from "../../components/noteAssessments/noteAssessmentVersionItem";
import _ from "lodash";

const MG_TOP_JUSTIFY_CENTER = {
  marginTop: 32,
  display: "flex",
  justifyContent: "center",
};

export default function NoteAssessmentVersions(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    if (!props.itemId) {
      window.NotificationUtils.showError("Invalid item");
      return;
    }
    setLoading(true);
    try {
      let archives = await window.FortisForma.database.fetchArchives(
        props.itemId,
        props.collection
      );
      // Appending creator details for each note item

      if (!_.isEmpty(archives)) {
        const userIds = [];
        _.map(archives, (version) => {
          if (_.has(version, "creatorId")) {
            userIds.push(version.creatorId);
          }
        });

        if (!_.isEmpty(userIds)) {
          let res =
            await window.FortisForma.database.fetchEnterpriseStaffDetails(
              userIds
            );
          if (!_.isEmpty(res)) {
            _.map(archives, (note, key) => {
              _.map(res, (creator) => {
                if (note.creatorId === creator.id) {
                  archives[key] = { ...note, creator: creator };
                }
              });
            });
          }
        }
      }
      setData(archives);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to fetch note versions");
    }
    setLoading(false);
  }

  const onExport = async () => {
    setExporting(true);
    try {
      await props.onExport(props.selectedItem, true);
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
    setExporting(false);
  };

  const renderCurrentItem = () => {
    const item = props.selectedItem;
    if (item && item.id && !loading) {
      return (
        <Badge
          badgeContent={"Current"}
          id="currentVersionBadge"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <NoteAssessmentVersionItem
            item={item}
            onClickViewForm={props.onClickViewForm}
            onExport={props.onExport}
          />
        </Badge>
      );
    }
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <div style={MG_TOP_JUSTIFY_CENTER}>
          <CircularProgress />
        </div>
      );
    }
  };

  function renderContent() {
    let sortedData = [];
    if (data && data.length) {
      sortedData = data.sort(
        (item1, item2) => item2.updatedTime.seconds - item1.updatedTime.seconds
      );
    }
    return (
      <>
        {renderCurrentItem()}
        {sortedData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <NoteAssessmentVersionItem
                item={item}
                onClickViewForm={props.onClickViewForm}
                onExport={props.onExport}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  function renderExportAllVersionsButton() {
    if (data && data.length) {
      return (
        <div className="flexEnd" style={{ marginBottom: "16px" }}>
          <TAButton
            isLoading={exporting}
            startIcon={
              <GetAppRoundedIcon color={exporting ? "disabled" : "primary"} />
            }
            variant="outlined"
            onClick={onExport}
            loadingMessage="Exporting"
            color="primary"
          >
            Export All
          </TAButton>
        </div>
      );
    }
  }

  return (
    <div className="versionsPaneContent">
      {_.has(props.selectedItem, "edited") && renderExportAllVersionsButton()}
      {renderContent()}
      {renderLoading()}
    </div>
  );
}
