import React, { Component } from "react";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import OpaqueLoading from "../../opaqueLoading/opaqueLoading";
import FormDetailsCard from "../../forms/formDetailCard";

export default class NoteAssessmentFormsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formList: [],
      sequenceNumber: null,
    };
  }

  componentDidMount() {
    this.fetchEnterpriseForms();
  }

  renderLoading = () => {
    return <OpaqueLoading />;
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={this.props.closeFormsModal}
        disabled={this.state.loading}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  renderDialogContentData = () => {
    return (
      <div className="useTemplatesContent">
        <div className="innerFormCardsContainer">{this.renderCards()}</div>
      </div>
    );
  };

  renderCards = () => {
    const sortedFormData = this.state.formList.sort((form1, form2) => {
      return form2.sequence - form1.sequence;
    });

    return sortedFormData.map((form, key) => {
      return (
        <FormDetailsCard
          data={form.schema}
          key={key}
          onClickPreview={() => this.props.onClickPreview(form)}
          onFill={(e) => this.onFillForm(e, form)}
          isGlobalFormCard={true}
          loading={this.state.loading}
        />
      );
    });
  };

  render() {
    return (
      <>
        {this.state.loading && this.renderLoading()}
        <DialogTitle
          disableTypography={true}
          className="documentsModalHeaderContainer"
        >
          <Typography
            className="modalFormTitle boldText"
            variant="h5"
            display="inline"
          >
            {this.getDialogTitle()}
          </Typography>
        </DialogTitle>
        <DialogContent className="useTemplatesContentContainer">
          {this.renderCloseButton()}
          {this.renderDialogContentData()}
        </DialogContent>
      </>
    );
  }
}
