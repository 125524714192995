import { takeUntil } from "rxjs/operators";
import SubscriptionBase from "../subscriptionBase/subscriptionBase";
import { STATUS } from "react-joyride";

export default class WalkthroughEventDispatcher extends SubscriptionBase {
  componentDidMount() {
    let onNext = (data) => {
      if (data && data.walkthrough) {
        this.globalWalkthrough = Object.assign({}, data.walkthrough);
        let isDisabled = data.walkthrough[this.walkthroughKey] === false;
        this.setState({ walkthrough: !isDisabled });
      } else {
        this.globalWalkthrough = {};
        this.setState({ walkthrough: true });
      }
    };

    let onError = (e) => {
      console.error("Error in stream", e);
    };

    window.walkthroughStream
      .pipe(takeUntil(this.end$))
      .subscribe(onNext, onError);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCompleteWalkthrough = () => {
    let walkthrough = this.globalWalkthrough;
    if (walkthrough[this.walkthroughKey] === false) {
      return;
    }
    walkthrough[this.walkthroughKey] = false;
    window.walkthroughStream.next({
      walkthrough: walkthrough,
    });

    try {
      window.FortisForma.database.updateWalkthroughStatus(this.walkthroughKey);
    } catch (e) {
      console.error(e);
    }
  };

  handleJoyrideCallback = (data) => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({
        walkthrough: false,
      });
      this.onCompleteWalkthrough();
    }
  };
}
