const dashbordWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#viewClientButton",
      content: "Click here to view the status of all your clients.",
    },
    {
      target: "#viewPendingInvitesButton",
      content: "Click here to see your invited, but unconfirmed clients.",
    },
    {
      target: "#viewClientRequestButton",
      content: "Click here to view pending requests from clients",
    },
    {
      target: "#viewPlannedWorkoutButton",
      content: "Click here to view your workouts.",
    },
    {
      target: "._hj-f5b2a1eb-9b07_feedback_minimized_label",
      content: "If you find any problems with our platform, let us know here.",
      placement: "left",
    },
    {
      target: "#Designerwalkthrough",
      content: "Great! Now let's create your first workout by clicking here.",
    },
  ],
};

const designerWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#exerciseName",
      content: "Write the name of your workout here.",
    },
    {
      target: "#exerciseFilters",
      content: " Add filters to refine your search.",
    },
    {
      target: "#addExercisePlusButton",
      content: "Click here to add this exercise to your workout.",
    },
    {
      target: "#helpButton",
      content: "Click here if you have any problem finding an exercise.",
      placement: "left",
    },
  ],
};

const exerciseDetailWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#exerciseConfig",
      content: "Add reps, weight & time for the exercise.",
    },
    {
      target: "#levelLeftArrow",
      content: "You can decrease the exercise level by clicking here",
    },
    {
      target: "#levelRightArrow",
      content: "You can increase the exercise level by clicking here",
    },
    {
      target: "#exerciseDetailsButtons",
      content:
        "Delete, Copy, Add note to this exercise or Find an alternate exercise",
    },
    // {
    //   target: '#exerciseCardNoteIcon',
    //   content: 'You can add a note to the exercise by clicking here '
    // },
    {
      target: "#dragHandle",
      content: "Drag & drop to rearrange order of exercise.",
    },
  ],
};

const workoutDayWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#workoutDayCardList",
      content: "Here you get the list of your workouts, click to view details.",
    },
    {
      target: "#copyWorkoutButton",
      content:
        "Use this and options to its right for copy edit and delete respectively.",
    },
    {
      target: "#workoutDayAddNewButton",
      content: "Click here to add new workout.",
    },
  ],
};

const clientWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#registeredClient",
      content: "Click here to view your registered clients.",
    },
    {
      target: "#pendingClient",
      content: "Click here to view clients who haven't logged in yet.",
    },
    {
      target: "#requestClient",
      content: "Click here to view your new client requests.",
    },
    {
      target: "#clientFilterFields",
      content: "You can apply filters on client list from here.",
    },
    {
      target: "#addNewClientButton",
      content: "Click here to add a new client.",
    },
    {
      target: "#clientRow",
      content: "Click to view details of client ",
    },
  ],
};

const clientDetailWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#clientDetailWorkoutButton",
      content: "Click here to assign workouts to this client.",
    },
    {
      target: "#clientDetailWorkoutSummary",
      content: "Click here to view workout summary.",
    },
    {
      target: "#clientDetailAssignCoach",
      content: "Click here to assign the coaches",
    },
    {
      target: "#clientDetailsButton",
      content: "Click here to view more client details",
    },
  ],
};

const workoutAssignerWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#workoutDaysList",
      content: "Select a day to add workout to it.",
    },
    {
      target: "#addDayButton",
      content: "Add days according to your choice.",
    },
    {
      target: "#repeatDaysToggle",
      content: "Toggle to keep program repeating.",
    },
    {
      target: "#displayDaysToggle",
      content: "Toggle to view day in a calender or in a numbered form",
    },
    {
      target: "#restDayIcon0",
      content: "Feeling tired, make it a rest day.",
    },
    {
      target: "#messageIcon0",
      content: "Workout start and end message.",
    },
    {
      target: "#clientsWorkout",
      content: "Click here to view workouts specific to the client.",
    },
    {
      target: "#myTemplates",
      content: "Click here to view your workout templates.",
    },
    {
      target: "#allWorkouts",
      content: "Click here to view all workouts.",
    },
  ],
};

const workoutCardWalkThrough = {
  steps: [
    {
      target: "#workoutCardEditButton",
      content: "Click here to edit workout.",
    },
    {
      target: "#workoutCardAssignButton",
      content: "Click here to assign this workut to selected day.",
    },
    {
      target: "#workoutCardAssignAllButton",
      content: "Click here to assign this workout to all days.",
    },
    {
      target: "#workoutCardDropdown",
      content: "Click this dropdown to view details of workout.",
    },
  ],
};

const workoutSummaryWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#datePickerStep",
      content: "Click to select a period for the summary.",
    },
    {
      target: "#summaryDateList",
      content: "Select a date to view its workout summary on the right panel.",
    },
  ],
};

const pendingClientWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#serviceClientDetails",
      content: "Client request details.",
    },
    {
      target: "#sendMessageButton",
      content: "Drop a message for your client.",
    },
  ],
};

const shareWorkoutWalkThrough = {
  steps: [
    {
      disableBeacon: true,
      target: "#shareWorkoutIcon",
      content: "Click to share workout.",
    },
  ],
};

module.exports = {
  DASHBOARD_WALKTHROUGH: dashbordWalkThrough,
  DESIGNER_WALKTHROUGH: designerWalkThrough,
  WORKOUTDAY_WALKTHROUGH: workoutDayWalkThrough,
  CLIENT_WALKTHROUGH: clientWalkThrough,
  WORKOUT_ASSIGNER_WALKTHROUGH: workoutAssignerWalkThrough,
  CLIENT_DETAIL_WALKTHROUGH: clientDetailWalkThrough,
  WORKOUT_SUMMARY_WALKTHROUGH: workoutSummaryWalkThrough,
  EXERCISE_DETAIL_WALKTHROUGH: exerciseDetailWalkThrough,
  WORKOUT_CARD_WALKTHROUGH: workoutCardWalkThrough,
  PENDING_CLIENT_WALKTHROUGH: pendingClientWalkThrough,
  SHARE_WORKOUT_WALKTHROUGH: shareWorkoutWalkThrough,
};
