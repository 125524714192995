import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { BubbleLoader } from "react-css-loaders";
import TAButton from "../../../components/taButton/taButton";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FortisFormaSelect from "../../../components/autoSuggest/dropdownSelect";
import CloseIcon from "@material-ui/icons/Close";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import { Typography } from "@material-ui/core";
import bodybuilder from "bodybuilder";
import {
  COLLECTIONS,
  DB_KEYS,
  PDF_TEMPLATES,
  ROLES,
  SHARE_WORKOUT_OPTIONS,
} from "../../../constants";
import { components, default as ReactSelect } from "react-select";

export const colourOptions = [
  { value: "POE", label: "Pictures of exercises" },
  { value: "CON", label: "Coach's Notes" },
  { value: "reps", label: "Reps" },
  { value: "sets", label: "Sets" },
  { value: "resistance", label: "Resistance" },
  { value: "time", label: "Time" },
  { value: "weight", label: "Weight" },
  { value: "description", label: "Description" },
];

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          className="multiCheck"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default class ShareWorkoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      workoutNote: null,
      workoutData: null,
      clientData: null,
      clientList: [],
      saving: false,
      loading: false,
      pdfRadio: "noDesc",
      optionSelected: {
        POE: true,
        description: true,
        weight: true,
        time: true,
        reps: true,
        sets: true,
        resistance: true,
        CON: true,
      },
    };
    this.fetchClients = this.fetchClients.bind(this);
    this.isEnterpriseMember =
      this.props.currentUser && this.props.currentUser.enterpriseId
        ? true
        : false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openShareModal !== this.props.openShareModal) {
      this.setClientData();
    }
  }

  renderLoader() {
    if (this.state.loading) {
      return (
        <div className="spinnerContainer">
          <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
        </div>
      );
    }
  }

  mergeClientList = (mergedList) => {
    mergedList.sort((a, b) => {
      if (!a.name || !b.name) {
        return 0;
      }
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; //default return value (no sorting)
    });

    var optionsList = mergedList.map((obj) => {
      const clientOptions = {};
      clientOptions.label = obj.name;
      clientOptions.value = obj;
      return clientOptions;
    });

    return optionsList;
  };

  prepareSearchFilters() {
    let filters = bodybuilder();
    let query = "";
    filters.query("query_string", {
      query: "*" + query + "*",
      fields: ["name", "email"],
    });
    filters.filter("term", "role", ROLES.CLIENT);
    if (
      this.props.currentUser.role === ROLES.FACILITY_ADMIN ||
      this.props.currentUser.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter(
              "term",
              "enterpriseId",
              this.props.currentUser.enterpriseId
            )
            .orFilter("term", "trainerId", this.props.ppEnterprise.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q.orFilter(
            "term",
            "enterpriseId",
            this.props.currentUser.enterpriseId
          )
        );
      }
    } else {
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
            .orFilter("term", "trainerId", this.props.currentUser.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter(
              "term",
              "enterpriseId",
              this.props.currentUser.enterpriseId
            )
            .orFilter("term", "trainerId", this.props.currentUser.id)
        );
      }
    }
    if (this.props.currentUser.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: this.props.user.id, ...query };
    } else {
      return filters.build();
    }
    // filters.filter("term", "enterpriseId", this.props.currentUser.enterpriseId);
    // return filters.build();
  }

  async fetchClients() {
    this.setState({
      loading: true,
    });
    let query = {};
    let currentUser = this.props.currentUser;

    if (!currentUser) {
      throw new Error("User not logged in");
    }

    if (!query.filter) {
      query.filters = [];
    }

    query.collection = COLLECTIONS.USER_DATA;
    query.filters = [
      {
        key: DB_KEYS.TRAINER_ID_KEY,
        operator: "==",
        value: currentUser.id,
      },
    ];

    try {
      var mergedList = [];
      if (!this.isEnterpriseMember) {
        let requestedClients = await window.FortisForma.database.queryData(
          query
        );
        requestedClients &&
          requestedClients.sort((a, b) => {
            let nameA = (a.name || "").toLowerCase();
            let nameB = (b.name || "").toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

        query.collection = COLLECTIONS.INVITES;
        let pendingInvites = await window.FortisForma.database.queryData(query);
        for (let invite of pendingInvites) {
          invite.pendingLogin = true;
        }

        let pendingClients = pendingInvites;
        pendingClients &&
          pendingClients.sort((a, b) => {
            let nameA = (a.name || "").toLowerCase();
            let nameB = (b.name || "").toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

        mergedList = [...requestedClients, ...pendingClients];
      } else {
        query = this.prepareSearchFilters();
        mergedList = await window.FortisForma.database.fetchEnterpriseClients(
          query
        );
      }
      this.setState({
        loading: false,
      });
      return mergedList;
    } catch (e) {
      throw e;
    }
  }

  closeModal() {
    this.setState({
      saving: false,
      sendingMail: false,
      selectedClient: null,
      workoutNote: null,
      workoutData: null,
      clientData: null,
      clientList: [],
      loading: false,
      error: false,
      optionSelected: {
        POE: true,
        description: true,
        weight: true,
        time: true,
        reps: true,
        sets: true,
        resistance: true,
        CON: true,
      },
    });
    this.props.onCloseShareModalIcon();
  }

  handleChange = (selected) => {
    let obj = {
      CON: false,
      reps: false,
      time: false,
      resistance: false,
      weight: false,
      sets: false,
      description: false,
      POE: false,
    };
    if (selected) {
      // eslint-disable-next-line array-callback-return
      selected.map((item) => {
        if (item.value === "CON") {
          obj.CON = true;
        }
        if (item.value === "reps") {
          obj.reps = true;
        }
        if (item.value === "sets") {
          obj.sets = true;
        }
        if (item.value === "time") {
          obj.time = true;
        }
        if (item.value === "weight") {
          obj.weight = true;
        }
        if (item.value === "resistance") {
          obj.resistance = true;
        }
        if (item.value === "description") {
          obj.description = true;
        }
        if (item.value === "POE") {
          obj.POE = true;
        }
      });
    }
    this.setState({
      optionSelected: obj,
    });
  };

  handleNoteChange(e) {
    this.setState({
      workoutNote: e.target.value,
    });
  }

  handleClientChange(selectedOption) {
    if (selectedOption !== null) {
      this.setState({
        selectedClient: selectedOption,
        clientData: selectedOption.value,
        error: false,
      });
    } else {
      this.setState({
        selectedClient: null,
        clientData: null,
      });
    }
  }

  setClientData() {
    if (
      !this.props.clientEmail &&
      this.props.openShareModal &&
      this.props.openShareModal !== SHARE_WORKOUT_OPTIONS.VIA_LINK
    ) {
      this.fetchClients().then((data) => {
        this.setState({
          clientList: this.mergeClientList(data),
          clientData: data,
          workoutData: this.props.workout,
        });
      });
    } else {
      this.setClientDetailsFromProps();
    }
  }

  setClientDetailsFromProps = () => {
    let clientData = {};
    clientData.email = this.props.clientEmail || "";
    clientData.DOB = this.props.clientDOB || "";
    clientData.name = this.props.clientName;
    this.setState({ clientData });
  };
  renderSelectClient = () => {
    return (
      <>
        <Typography style={{ width: "450px" }} className="marginBottom">
          Workout For Client:
        </Typography>
        <FortisFormaSelect
          isClearable={true}
          placeholder={"No client selected"}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.handleClientChange.bind(this)}
          value={this.state.selectedClient}
          handleLoading={false}
          options={this.state.clientList}
        />
      </>
    );
  };
  renderClientFromProps = () => {
    return (
      <>
        <Typography className="marginBottom">Workout For Client:</Typography>
        <Typography variant="subtitle2" className="boldText marginBottom8">
          {this.props.clientName}
        </Typography>
        {this.props.clientEmail && (
          <Typography>({this.props.clientEmail})</Typography>
        )}
      </>
    );
  };

  getPDFData = () => {
    let enterpriseData = {};
    if (this.isEnterpriseMember) {
      enterpriseData = window.getEnterpriseData();
    } else {
      enterpriseData.enterpriseName = this.props.currentUser.name;
    }
    let workout = this.props.workout;
    let filter = {
      descriptionStatus: this.state.optionSelected.description,
      coach_notes: this.state.optionSelected.CON,
      timeStatus: this.state.optionSelected.time,
      weightStatus: this.state.optionSelected.weight,
      resistanceStatus: this.state.optionSelected.resistance,
      exercise_pictures: this.state.optionSelected.POE,
      repsStatus: this.state.optionSelected.reps,
      setsStatus: this.state.optionSelected.sets,
    };
    for (let i = 0; i < workout.workoutSections.length; i++) {
      let existing = workout.workoutSections[i].exercises;
      for (let j = 0; j < existing.length; j++) {
        workout.workoutSections[i].exercises[j].filter = filter;
      }
    }
    const pdfData = {
      enterpriseData: enterpriseData,
      clientData: {
        name: this.state.clientData && this.state.clientData.name,
        email: this.state.clientData && this.state.clientData.email,
        dob: this.state.clientData && this.state.clientData.DOB,
      },
      mainData: {
        note: this.state.workoutNote,
        workout: workout,
      },
    };
    return pdfData;
  };

  getDocumentName = () => {
    const workoutName =
      this.props.workout &&
      this.props.workout.name &&
      this.props.workout.name.substring(0, 20);

    const clientName =
      (this.state.clientData && this.state.clientData.name) || "";

    return `${clientName}_${workoutName}`;
  };

  async handleSavePDF() {
    this.setState({
      saving: true,
    });
    try {
      const pdfData = this.getPDFData();
      const documentName = this.getDocumentName();
      let results = await window.FortisForma.database.getPDF(
        PDF_TEMPLATES.WORKOUT,
        pdfData,
        documentName
      );
      if (results.saved) {
        this.closeModal();
        window.NotificationUtils.showSuccess("Downloading PDF");
      } else {
        window.NotificationUtils.showError("Error");
        this.setState({
          error: true,
          saving: false,
        });
      }
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      this.setState({ error: true, saving: false });
      console.error(e);
    }
  }

  handleSendEmail = async () => {
    this.setState({
      sendingMail: true,
    });
    try {
      if (
        !this.state.clientData ||
        (this.state.clientData && !this.state.clientData.email)
      ) {
        throw new Error("Invalid Params");
      }

      const { clientData } = this.state;
      const pdfData = this.getPDFData();
      const documentName = this.getDocumentName();

      let success = await window.FortisForma.database.sendWorkoutMail(
        pdfData,
        documentName,
        this.props.link,
        clientData
      );
      if (success) {
        this.closeModal();
        window.NotificationUtils.showSuccess("Email sent");
      } else {
        window.NotificationUtils.showError("Error");
        this.setState({
          error: true,
          sendingMail: false,
        });
      }
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      this.setState({ error: true, sendingMail: false });
      console.error(e);
    }
  };

  getModalTitle = () => {
    if (this.props.titleText) {
      return this.props.titleText;
    } else {
      return "Share Workout";
    }
  };

  shouldShowSendEmailButton = () => {
    return Boolean(
      this.state.clientData &&
        this.state.clientData.name &&
        this.state.clientData.email
    );
  };

  renderSendEmailNote = () => {
    if (this.state.selectedClient && !this.shouldShowSendEmailButton()) {
      return (
        <Typography variant="caption">
          Note: Please add client's email to send workout PDF/link directly.
        </Typography>
      );
    }
  };

  renderButton = (isEmailButton = false) => {
    if (isEmailButton && this.shouldShowSendEmailButton()) {
      return (
        <TAButton
          isLoading={this.state.sendingMail}
          loadingMessage={"Sending"}
          color="primary"
          size="large"
          onClick={this.handleSendEmail.bind(this)}
        >
          Send Email
        </TAButton>
      );
    }
    if (!isEmailButton) {
      return (
        <TAButton
          isLoading={this.state.saving}
          loadingMessage={"Saving"}
          color="primary"
          size="large"
          onClick={this.handleSavePDF.bind(this)}
        >
          Save PDF
        </TAButton>
      );
    }
    return <></>;
  };

  renderSharePDF(byEmail = false) {
    return (
      <>
        <div>
          <TextField
            onChange={this.handleNoteChange.bind(this)}
            id="note-input"
            label="Workout Note"
            style={{ width: "550px" }}
          />
        </div>
        <div style={{ marginTop: "32px", marginBottom: "20px" }}>
          {this.renderLoader()}
          {this.props.clientName
            ? this.renderClientFromProps()
            : this.renderSelectClient()}
        </div>
        {byEmail ? this.renderSendEmailNote() : null}
        <span
          class="d-inline-block multiSelect"
          data-toggle="popover"
          data-trigger="focus"
          data-content="Select what you want included in your PDF?"
        >
          <ReactSelect
            options={colourOptions}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(189, 0, 0, 0.3)",
                primary: "#800520",
              },
            })}
            styles={{
              option: (base) => ({
                ...base,
                borderBottom: "1px solid #fff",
              }),
            }}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
            }}
            classNamePrefix={"fortisFormaDropDownSelect"}
            onChange={this.handleChange}
            defaultValue={colourOptions}
          />
        </span>
        {/* <RadioGroup
          className="sharePdfFlex"
          value={this.state.pdfRadio}
          onChange={(e) => this.setState({ pdfRadio: e.target.value })}
        >
          <FormControlLabel
            value="noDesc"
            control={<Radio color="primary" />}
            label="Compact printer fiendly version (no exercise descriptions)"
          />
          <FormControlLabel
            value="withDesc"
            control={<Radio color="primary" />}
            label="Full workout (descriptions included)"
          />
        </RadioGroup> */}
        <div style={{ marginTop: "30px", float: "right" }}>
          {this.renderButton(byEmail)}
        </div>
      </>
    );
  }

  renderMainContent() {
    let optionToShow = this.props.openShareModal;
    switch (optionToShow) {
      case SHARE_WORKOUT_OPTIONS.VIA_LINK:
        return this.renderShareLink();
      case SHARE_WORKOUT_OPTIONS.VIA_EMAIL:
        return this.renderSharePDF(true);
      case SHARE_WORKOUT_OPTIONS.DOWNLOAD_PDF:
        return this.renderSharePDF(false);
      default:
        return <></>;
    }
  }

  renderShareLink() {
    let link = this.props.link;
    return (
      <React.Fragment>
        <div className="displayFlex alignCenter mgBottom16">
          <IconButton id="getLinkIcon">
            <LinkOutlinedIcon />
          </IconButton>
          <Typography variant="h6">Get Link</Typography>
        </div>
        <div className="displayFlex alignCenter">
          <Typography id="shareLink" className="mgRight16" variant="body2">
            {link}
          </Typography>
          <Button
            className="displayFlex alignCenter justifyContentCenter"
            variant="contained"
            color="primary"
            onClick={this.props.onClickCopyLink}
            size="small"
          >
            Copy Link
          </Button>
        </div>
      </React.Fragment>
    );
  }

  renderShareModalContent() {
    return (
      <ModalBody style={{ width: "600px" }}>
        <Typography
          className="boldText mgBottom32 textAlignCenter"
          variant="h5"
        >
          {this.getModalTitle()}
        </Typography>
        <div>{this.renderMainContent()}</div>
      </ModalBody>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          style={{ width: "600px" }}
          isOpen={Boolean(this.props.openShareModal)}
          className="assignerModal"
          id="shareModal"
          backdrop={true}
        >
          <IconButton
            style={{ margin: "10px 10px 0 0 " }}
            id="closeShareModalIcon"
            onClick={this.closeModal.bind(this)}
          >
            <CloseIcon />
          </IconButton>
          {this.renderShareModalContent()}
        </Modal>
      </React.Fragment>
    );
  }
}
