import moment from "moment";
import { isTablet } from "react-device-detect";
import _ from "lodash";
import {
  FIELD_TYPES,
  UI_DESCRIPTION,
  UI_ORDER,
  UI_WIDGET,
} from "../components/forms/createFormModal";
import { PDF_TEMPLATES } from "../constants";

//! *************  IMPORTANT  ***************
//! If any changes in formutils make sure to apply same to  "forms.js" module of fof-server.

export const addFormToNoteAssessment = async (data) => {
  try {
    if (data.formId) {
      const formData = await window.FortisForma.database.fetchFormsByFormId(
        data.formId
      );
      let submissionData = {};
      if (_.has(data, "submissionId")) {
        submissionData = await getSubmissionData(data);
      }
      const formattedForm = await formatData(formData, submissionData);
      return formattedForm;
    }
    return false;
  } catch (error) {
    throw error;
  }
};

export const handleSaveFormPDF = async (data) => {
  try {
    const submissionData = await getSubmissionData(data);
    const formattedData = formatData(data, submissionData);
    const pdfData = await getPDFData(formattedData, data.clientData);
    const date =
      window.getTimestampSeconds(data.createdTime) || moment().unix();
    const documentName = `form_${date}`;
    const results = await window.FortisForma.database.getPDF(
      PDF_TEMPLATES.FORM,
      pdfData,
      documentName
    );
    if (results.saved) {
      window.NotificationUtils.showSuccess("Downloading PDF");
    } else {
      window.NotificationUtils.showError("Error fetching PDF");
    }
  } catch (e) {
    window.NotificationUtils.showError("Error fetching PDF");
    throw e;
  }
};

const getPDFData = async (data, clientData) => {
  try {
    let enterpriseData = {};
    enterpriseData = window.getEnterpriseData();

    const mainData = data;
    clientData.enterpriseName = enterpriseData
      ? enterpriseData.enterpriseName
      : "";
    const pdfData = {
      enterpriseData,
      clientData,
      mainData,
    };
    return pdfData;
  } catch (e) {
    throw e;
  }
};

const formatData = (data, submissionData) => {
  const result = {};
  result.formMetaData = data.formMetaData;
  result.title = data.schema.title;
  result.description = data.schema.description;
  const uiSchema = data.uiSchema;
  const uiOrder = uiSchema[UI_ORDER];
  const parentSchema = data.schema;
  result.questions = getMainQuestions(
    uiOrder,
    uiSchema,
    parentSchema,
    submissionData
  );
  return result;
};

const hasUiWidget = (schema, widget) =>
  _.has(schema, UI_WIDGET) && schema[UI_WIDGET] === widget;

const getOptions = (type, questionSchema) => {
  if ([FIELD_TYPES.DROPDOWN, FIELD_TYPES.MULTIPLECHOICE].includes(type)) {
    return questionSchema.enum ? questionSchema.enum : [];
  }
  if (type === FIELD_TYPES.CHECKBOX) {
    return questionSchema.items.enum ? questionSchema.items.enum : [];
  }
  return false;
};

const getQuestionTitleString = (type, questionSchema, questionUiSchema) => {
  if (type === FIELD_TYPES.PLAINTEXT) {
    return questionSchema.description;
  }
  if (type === FIELD_TYPES.SIGNATURE) {
    return questionUiSchema[UI_DESCRIPTION];
  }
  return questionSchema.title;
};

const isValidAnswer = (ans) => {
  if (
    _.isString(ans) ||
    _.isArray(ans) ||
    _.isNumber(ans) ||
    (_.isObject(ans) && !_.isEmpty(ans))
  ) {
    return true;
  }
  return false;
};

const getAnswerString = (type, key, submissionData, questionSchema) => {
  const haveSubmission = !_.isEmpty(submissionData);
  if (haveSubmission && isValidAnswer(submissionData[key])) {
    let answerString = submissionData[key];
    if (answerString.toString().trim() === "") {
      return "-";
    }
    if (type === FIELD_TYPES.PHONE) {
      return `+1 ${answerString}`;
    }
    if (type === FIELD_TYPES.DATE) {
      return moment(answerString).format("MM/DD/YYYY");
    }
    if (type === FIELD_TYPES.TEXTAREA) {
      return answerString.toString().replace(/\n/g, "<br />");
    }
    if (type === FIELD_TYPES.FREEHAND_NOTE || type === FIELD_TYPES.ADD_IMAGE) {
      return formatCanvasData(answerString);
    }
    return answerString;
  }
  if (type === FIELD_TYPES.PDF) {
    return questionSchema.data;
  }
  return "-";
};

const getQuestionType = (questionSchema, questionUiSchema) => {
  if (_.has(questionSchema, "properties")) {
    return FIELD_TYPES.SECTION;
  }
  if (questionSchema.title === "" && questionSchema.type === "object") {
    return FIELD_TYPES.PLAINTEXT;
  }
  if (_.has(questionSchema, "format") && questionSchema.format === "date") {
    return FIELD_TYPES.DATE;
  }
  if (hasUiWidget(questionUiSchema, "phoneField")) {
    return FIELD_TYPES.PHONE;
  }
  if (_.has(questionSchema, "enum") && questionSchema.type === "string") {
    return FIELD_TYPES.DROPDOWN;
  }
  if (hasUiWidget(questionUiSchema, "checkboxes")) {
    return FIELD_TYPES.CHECKBOX;
  }
  if (hasUiWidget(questionUiSchema, "radio")) {
    return FIELD_TYPES.MULTIPLECHOICE;
  }
  if (hasUiWidget(questionUiSchema, "textarea")) {
    return FIELD_TYPES.TEXTAREA;
  }
  if (hasUiWidget(questionUiSchema, "pdfViewer")) {
    return FIELD_TYPES.PDF;
  }
  if (hasUiWidget(questionUiSchema, "signatureField")) {
    return FIELD_TYPES.SIGNATURE;
  }
  if (hasUiWidget(questionUiSchema, "addImageWidget")) {
    return FIELD_TYPES.ADD_IMAGE;
  }
  if (hasUiWidget(questionUiSchema, "freehandNote")) {
    return FIELD_TYPES.FREEHAND_NOTE;
  }
  if (
    _.size(questionSchema) === 2 &&
    _.has(questionSchema, "title") &&
    _.has(questionSchema, "type")
  ) {
    return FIELD_TYPES.TEXTFIELD;
  }
  return false;
};

const getMainQuestions = (uiOrder, uiSchema, parentSchema, submissionData) => {
  const questions = {};
  const properties = parentSchema.properties;
  _.map(uiOrder, (key) => {
    if (_.has(properties, key)) {
      if (!_.has(uiSchema, key)) {
        // Handle case where uiSchema of corresponding question is not present (in old forms)
        const type = getQuestionType(properties[key]);
        if (!type) {
          throw new Error("Invalid Question type");
        }
      }
      const questionSchema = properties[key];
      const questionUiSchema = uiSchema[key];
      const required = parentSchema.required || [];
      const type = getQuestionType(questionSchema, questionUiSchema);
      let question = getQuestionTitleString(
        type,
        questionSchema,
        questionUiSchema
      );
      if (required.includes(key)) {
        question += "*";
      }
      const answer = getAnswerString(type, key, submissionData, questionSchema);
      const options = getOptions(type, questionSchema);

      questions[key] = {
        type,
        question,
        answer,
      };
      if (options) {
        questions[key].options = options;
      }

      if (type === FIELD_TYPES.SECTION) {
        if (!_.isEmpty(questionUiSchema) && _.has(questionUiSchema, UI_ORDER)) {
          questions[key].properties = getMainQuestions(
            questionUiSchema[UI_ORDER],
            questionUiSchema,
            questionSchema,
            submissionData[key]
          );
        }
      }
    }
  });
  return questions;
};

const getSubmissionData = (data) => {
  return new Promise(async (resolve) => {
    if (_.has(data, "submissionId") && data.submissionId) {
      try {
        let response = await window.FortisForma.database.fetchFormData(
          data.submissionId
        );
        if (_.has(response, "formData") && !_.isEmpty(response.formData)) {
          resolve(response.formData);
        }
      } catch (error) {
        throw error;
      }
    }
    resolve(false);
  });
};

const formatCanvasData = (data) => {
  let cloneData = _.cloneDeep(data);
  if (_.isArray(cloneData)) {
    return cloneData.map((item, index) => {
      if (item.canvasData) {
        delete item.canvasData;
      }
      if (item.backgroundImageURL) {
        item.backgroundImageURL = CSS.escape(item.backgroundImageURL);
      }
      if (data.length > 1) {
        item.page = index + 1;
      }
      return item;
    });
  }
  if (cloneData.canvasData) {
    delete cloneData.canvasData;
  }
  if (cloneData.backgroundImageURL) {
    cloneData.backgroundImageURL = CSS.escape(cloneData.backgroundImageURL);
  }
  return cloneData;
};

export const shouldHandleLongText = (content, directString) => {
  const maxLength = isTablet ? 60 : 75;
  if (
    directString &&
    typeof directString === "string" &&
    directString.length > maxLength
  ) {
    return true;
  } else {
    const schema = content && content.props && content.props.schema;
    const uiSchema = content && content.props && content.props.uiSchema;
    if (schema && uiSchema) {
      const type = getQuestionType(schema, uiSchema);
      if (type === FIELD_TYPES.SECTION || type === FIELD_TYPES.PLAINTEXT) {
        return false;
      }
      if (schema.title && schema.title.length > maxLength) {
        return true;
      }
    }
  }

  return false;
};
