import React from "react";

import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";

import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect.js";

import Delete from "../../assets/delete.png";
import { CircularProgress } from "@material-ui/core";

export default class PropertyEditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.mapPropsToState(props);

    this.onChangeName = this.onChange.bind(this, "name");
    this.onChangeMovementId = this.onChange.bind(this, "movementId");
    this.onChangeMovementCategory = this.onChange.bind(
      this,
      "movementCategory"
    );
  }

  componentWillReceiveProps(props) {
    let update = this.mapPropsToState(props);
    this.setState(update);
  }

  mapPropsToState(props) {
    let category = props.data.movementCategory
      ? {
          label: props.data.movementCategory,
          value: props.data.movementCategory,
        }
      : "";
    return {
      name: props.data.name || "",
      movementId: props.data.movementId || "",
      movementCategory: category,
      movementCategories: props.movementCategories || [],
    };
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update);
  }

  handleMovementCategorySelect = (selectedOption) => {
    this.setState({
      movementCategory: selectedOption,
    });
  };

  onClickSave = () => {
    if (this.checkErrors()) {
      return;
    }
    this.props.onClickSave(
      Object.assign({}, this.props.data, {
        name: this.state.name,
        movementId: Number(this.state.movementId),
        movementCategory: this.state.movementCategory.value,
      })
    );
  };

  checkErrors() {
    if (!this.state.name) {
      let node = document.querySelector("#name");
      window.NotificationUtils.showErrorInForm(
        node,
        "name",
        "Name is required"
      );
      return true;
    }
    if (!this.state.movementId || isNaN(this.state.movementId)) {
      let node = document.querySelector("#movementId");
      window.NotificationUtils.showErrorInForm(
        node,
        "movementId",
        "Movement ID is required"
      );
      return true;
    }
    if (!this.state.movementCategory) {
      let node = document.querySelector("#movementCategory");
      window.NotificationUtils.showErrorInForm(
        node,
        "movementCategory",
        "Movement Category is required"
      );
      return true;
    }

    return false;
  }

  onClickDelete = async () => {
    this.setState({
      deleteLoading: true,
    });
    let exercises = [];
    exercises = await this.props.getExercisesForMovement(this.props.data);
    this.setState({
      deleteLoading: false,
    });
    window.customConfirm(
      this.renderDeleteMessage(this.props.data, exercises),
      async () => {
        try {
          var result = await window.FortisForma.database.deleteDocument(
            this.props.collection,
            this.props.data.id
          );
          if (result) {
          }
          this.props.onDelete(this.props.data.id);
        } catch (e) {
          console.error(e);
        }
      }
    );
  };

  renderDeleteMessage(data, exercises) {
    return (
      <div>
        <p>
          Following exercises use {data.name} are you sure you want to remove it
          from all these exercises ?<br />
        </p>
        <ul>
          {exercises &&
            exercises.map((ex, index) => {
              return <li key={index}>{ex.name}</li>;
            })}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label>Name</Label>
            <Input
              type={"text"}
              id="name"
              placeholder={this.props.placeholder}
              value={this.state.name}
              onChange={this.onChangeName}
            />
          </FormGroup>
        </Col>

        <Col lg="6">
          <FormGroup>
            <Label>Movement ID</Label>
            <Input
              type={"text"}
              id="movementId"
              value={this.state.movementId}
              onChange={this.onChangeMovementId}
            />
          </FormGroup>
        </Col>

        <Col lg="12">
          <FormGroup>
            <Label for="movementCategory">Movement Category</Label>
            <FortisFormaSelect
              id="movementCategory"
              isClearable={true}
              placeholder="Movement Category"
              value={this.state.movementCategory}
              classNamePrefix="fortisFormaDropDownSelect"
              onChange={this.handleMovementCategorySelect}
              options={this.state.movementCategories}
            />
          </FormGroup>
        </Col>

        <Col lg="12">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.data.id ? (
              this.state.deleteLoading ? (
                <CircularProgress color="primary" style={{ marginRight: 4 }} />
              ) : (
                <img
                  alt="Delete"
                  onClick={this.onClickDelete}
                  src={Delete}
                  className="deleteIcon"
                />
              )
            ) : null}
            <Button
              className="btn btn-outline-secondary"
              onClick={this.props.onClickCancel}
            >
              Cancel
            </Button>{" "}
            <Button
              className="btn btn-outline-secondary"
              onClick={this.props.onChangeMovement}
            >
              Change Movement
            </Button>{" "}
            <Button color="primary" onClick={this.onClickSave}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
