/*
usage
import {sendRequest} from '../Base/HttpHelper.js'
*/

const SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS;

// process.env.REACT_APP_SERVER_ADDRESS;

const CONTENT_TYPE_HEADER = "Content-Type";
const CONTENT_TYPE_JSON = "application/json";
const CONTENT_TYPE_JSON_WITH_ENCODING = "application/json; charset=utf-8";

const defaultHeaders = {};
defaultHeaders[CONTENT_TYPE_HEADER] = CONTENT_TYPE_JSON;

const INVALID_BODY_MESSAGE = "Please check the information you provided";
const FORBIDDEN = "Action Denied";
const SERVICE_ERROR =
  "Something went wrong on our side, Our support team is looking into it";

const AUTHORIZATION_HEADER = "Authorization";

let isTokenFetchRequested = false;

const defaultErrorMessagesMap = {
  400: INVALID_BODY_MESSAGE,
  403: FORBIDDEN,
  500: SERVICE_ERROR,
};

const pipeStatus = (config, response) => {
  let errorMessagesMap = config.errorMessagesMap;

  let messaesMap = defaultErrorMessagesMap;

  if (errorMessagesMap) {
    messaesMap = Object.assign({}, defaultErrorMessagesMap, errorMessagesMap);
  }

  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    let message = response.statusText;
    let errorMessage = messaesMap[response.status] || message;

    return new Promise(function (resolve, reject) {
      response
        .text()
        .then((message) => {
          if (message === response.statusText) {
            throw new Error("Server didnt send custom messages, use ours");
          }
          reject({
            message: message || errorMessage,
            status: response.status,
          });
        })
        .catch(() => {
          reject({
            message: errorMessage,
            status: response.status,
          });
        });
    });
  }
};

const pipeData = (response) => {
  let dataType = response.headers.get(CONTENT_TYPE_HEADER);
  switch (dataType) {
    case CONTENT_TYPE_JSON:
    case CONTENT_TYPE_JSON_WITH_ENCODING:
      return response.json();
    default:
      return response.text();
  }
};

const _getURLWithQuery = (url, params) => {
  if (!params) {
    return url;
  }
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");

  return url + (query ? "?" + query : "");
};

const connectionError = {
  message: "Could not connect to Custom servers",
};

const sendRequest = (api, method, body, callback, config) => {
  const originalBody = body;
  if (!config) {
    config = {};
  }

  let url = (config.base ? config.base : SERVER_ADDRESS) + api;

  if (!method) {
    throw new Error("Http method needs to be defined");
  }

  let headersToSend = defaultHeaders;
  if (config.headers) {
    headersToSend = Object.assign({}, defaultHeaders, config.headers);
  }

  let cachePolicy = config.cachePolicy || "no-cache";

  let abortController = new window.AbortController();
  let cancelSignal = abortController.signal;

  var options = {
    method: method,
    headers: headersToSend,
    cache: cachePolicy,
    signal: cancelSignal,
  };

  switch (method.toLowerCase()) {
    case "post":
    case "put":
      body =
        headersToSend[CONTENT_TYPE_HEADER] !== CONTENT_TYPE_JSON
          ? body
          : JSON.stringify(body);
      options.body = body;
      break;
    case "get":
    case "delete":
      url = _getURLWithQuery(url, body);
      break;
    default:
  }

  if (!headersToSend[CONTENT_TYPE_HEADER]) {
    delete headersToSend[CONTENT_TYPE_HEADER];
  }

  const process = () => {
    fetch(url, options)
      .then(pipeStatus.bind(this, config))
      .then(pipeData)
      .then((response) => {
        // Separate out stack from promise stack
        setTimeout(callback.bind(this, response), 10);
      })
      .catch((error) => {
        if (error.code === 20 || error.name === "AbortError") {
          return;
        }

        if (error.status === 403) {
          isTokenFetchRequested = false;
          sendRequest(api, method, originalBody, callback, config);
          localStorage.clear();
          // window.location.href = "https://dashboard.fortisforma.com/#/login";
          window.location.reload();
          window.FortisForma.database.signOut();
          return;
        }
        console.log(error, "error in fetch api call");
        console.error(error);
        if (config.errorCallback) {
          config.errorCallback(error);
        }

        if (config.dontShowMessage) {
          return;
        }

        if (error.status) {
          window.location.reload();

          window.NotificationUtils.showError(error.message, 2000, "topRight");
        }
      });
  };

  let forceFetch = !isTokenFetchRequested;
  window.FortisForma.database
    .getUserToken(forceFetch)
    .then((token) => {
      headersToSend[AUTHORIZATION_HEADER] = `Bearer ${token}`;
      isTokenFetchRequested = true;
      process();
    })
    .catch((e) => {
      process();
      console.log(e, "ggggg");

      window.location.replace("https://dashboard.fortisforma.com/#/login");
      let waste = window.FortisForma.database.signOut();
      console.log(waste, "waste");
    });

  return abortController;
};

let helperAPIs = {
  /*
        Study the config for advanced usage
        It has headers map, errorMessages map, dontShowMessage: boolean options
        To remove Content-Type header, set it to false
        To make user logout on access denied use logoutOnDeny: true
        */
  sendRequest: sendRequest,

  pipeStatus: pipeStatus,

  pipeData: pipeData,

  connectionError: connectionError,
};

module.exports = helperAPIs;
