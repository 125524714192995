import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { WORKOUT_SECTIONS_NAMES } from "../../../constants";
import SectionedWorkout from "./sectionedWorkout";

export default class ChooseWorkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      saved: false,
      workouts: [],
      selectedWorkout: null,
    };
  }

  componentDidMount() {
    let workouts = [];
    if (
      this.props.workouts &&
      this.props.workouts.workoutDataMap &&
      Object.keys(this.props.workouts.workoutDataMap).length
    ) {
      workouts = this.mapWorkout(
        Object.assign({}, this.props.workouts.workoutDataMap)
      );
    }
    this.setState({
      workouts,
    });
  }

  mapWorkout(workouts) {
    let arrayWorkout = [];
    for (let workoutId of Object.keys(workouts)) {
      let workout = workouts[workoutId];
      if (
        !workout.workoutSections &&
        workout.exercises &&
        workout.exercises.length
      ) {
        workout.workoutSections = [
          {
            workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
            set: 1,
            exercises: workout.exercises,
          },
        ];
      }
      workout.workoutSections = workout.workoutSections || [];
      let wksections = workout.workoutSections.map((section) => {
        let newSection = Object.assign({}, section);
        newSection.data = newSection.exercises;
        return newSection;
      });
      workout.workoutSections = wksections;
      if (workout.id === this.props.selectedWorkoutId) {
        this.setState({
          selectedWorkout: workout,
        });
      }
      arrayWorkout.push(workout);
    }
    return arrayWorkout;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workouts !== this.props.workouts) {
      let workouts = [];
      if (
        this.props.workouts &&
        this.props.workouts.workoutDataMap &&
        Object.keys(this.props.workouts.workoutDataMap).length
      ) {
        workouts = this.mapWorkout(
          Object.assign({}, this.props.workouts.workoutDataMap)
        );
      }
      this.setState({
        workouts,
      });
    }
  }

  onWorkoutSelect = (workout) => {
    workout.workoutSections = workout.workoutSections.map((section, index) => {
      section.index = index;
      return section;
    });
    this.setState({
      selectedWorkout: workout,
    });
  };

  onClickSelect = () => {
    this.props.changeWorkout(this.state.selectedWorkout);
  };

  renderWorkoutCard = (workout, index) => {
    let isSelected = Boolean(
      this.state.selectedWorkout && this.state.selectedWorkout.id === workout.id
    );
    return (
      <div
        key={index}
        className={
          isSelected
            ? "workoutChooseCard selectedWorkoutChooseCard"
            : "workoutChooseCard"
        }
        onClick={() => this.onWorkoutSelect(workout)}
      >
        {workout.name}
      </div>
    );
  };

  renderEmptyPlaceHolder = () => {
    return (
      <div id="workoutSwitcherEmptyPlaceHolder">
        <Typography variant="subtitle1">Please select a workout</Typography>
      </div>
    );
  };

  render() {
    return (
      <>
        <DialogTitle>Choose Workout</DialogTitle>
        <DialogContent>
          <div id="workoutChooseContainer">
            {this.state.workouts.map((workout, index) => {
              return this.renderWorkoutCard(workout, index);
            })}
          </div>
          <div id="workoutPreviewContainer">
            {this.state.selectedWorkout ? (
              <SectionedWorkout
                workoutSections={
                  this.state.selectedWorkout
                    ? this.state.selectedWorkout.workoutSections
                    : []
                }
              />
            ) : (
              this.renderEmptyPlaceHolder()
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={!this.state.selectedWorkout}
            onClick={this.onClickSelect}
            color="primary"
          >
            Select
          </Button>
        </DialogActions>
      </>
    );
  }
}
