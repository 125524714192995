import React from "react";

//Refer to http://www.daterangepicker.com/#usage for inputProps

export default class DatePicker extends React.Component {
  componentWillReceiveProps(props) {
    this.init(props);
  }

  componentDidMount() {
    this.init(this.props);
    if (
      this.props.inputProps &&
      this.props.inputProps.autoUpdateInput === false
    ) {
      var dateToday = window.moment();
      window
        .$(`input[name="${this.props.name}"]`)
        .val(`Start - ${dateToday.format("DD/MM/YYYY")}`);
    }
  }

  init(props) {
    window.$(`input[name="${this.props.name}"]`).off("cancel.daterangepicker");
    window.$(`input[name="${this.props.name}"]`).off("apply.daterangepicker");
    window.$(`input[name="${props.name}"]`).daterangepicker({
      ...props.inputProps,
    });
    window
      .$(`input[name="${props.name}"]`)
      .on("apply.daterangepicker", (ev, picker) => {
        var dateToday = window.moment();
        if (picker.startDate._isValid && picker.endDate._isValid) {
          window
            .$(`input[name="${props.name}"]`)
            .val(
              picker.startDate.format("DD/MM/YYYY") +
                " - " +
                picker.endDate.format("DD/MM/YYYY")
            );
        } else {
          props.callback(null, null);
          window
            .$(`input[name="${props.name}"]`)
            .val(`Start - ${dateToday.format("DD/MM/YYYY")}`);
          window
            .$(`input[name="${props.name}"]`)
            .data("daterangepicker")
            .setEndDate(dateToday.format("DD/MM/YYYY"));
          window
            .$(`input[name="${props.name}"]`)
            .data("daterangepicker")
            .setStartDate(dateToday.format("DD/MM/YYYY"));
        }
        if (props.callback) {
          props.callback(picker.startDate, picker.endDate);
        }
      });
    window
      .$(`input[name="${this.props.name}"]`)
      .on("cancel.daterangepicker", function (ev, picker) {
        if (props.inputProps && props.inputProps.autoUpdateInput === false) {
          var dateToday = window.moment();
          props.callback(null, null);
          window
            .$(`input[name="${props.name}"]`)
            .val(`Start - ${dateToday.format("DD/MM/YYYY")}`);
        }
      });
  }

  componentWillUnmount() {
    window.$(`input[name="${this.props.name}"]`).off("apply.daterangepicker");
    window.$(`input[name="${this.props.name}"]`).off("cancel.daterangepicker");
  }

  render() {
    return (
      <React.Fragment>
        <input
          className={this.props.klassName}
          type="text"
          autoComplete="off"
          name={this.props.name}
        />
      </React.Fragment>
    );
  }
}
