import React from "react";
import ExerciseCard from "../exerciseList/exerciseCard";

import { EQUIPMENT_TYPES } from "../../constants";

import { Row, Col } from "reactstrap";

import Reps from "../../assets/reps.png";
import Sets from "../../assets/reps.png";
import Weight from "../../assets/weight.png";
import Clock from "../../assets/clock.png";
import ReactPlayer from "react-player";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

const RESISTANCE_OPTIONS = {
  EXTRA_LIGHT: "Extra Light",
  LIGHT: "Light",
  MEDIUM: "Medium",
  HEAVY: "Heavy",
  EXTRA_HEAVY: "Extra Heavy",
};

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  repsCard: {
    textAlign: "center",
    fontSize: "0.85rem",
    display: "flex",
    backgroundColor: "white",
    borderRadius: 8,
    height: 40,
    width: 40,
    textDecoration: "underline",
    color: "#800520",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "rgba(78, 76, 76, 0.52) 0px 0px 3px 0px",
  },
  lowerCardContent: {
    marginTop: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  lowerCardText: {
    fontSize: 10,
  },
  noteStyle: {
    textAlign: "right",
    fontSize: "0.8em",
    margin: "4px 0",
  },
};

const randomID = function () {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
};

const resistantStyle = Object.assign({}, styles.repsCard, {
  fontSize: "0.65em",
  fontWeight: "bold",
});

export default class WorkoutExerciseCard extends ExerciseCard {
  constructor(props) {
    super(props);
    this.className = "exerciseDetailsCard";
    this.noteId = "note" + randomID();
  }

  renderExtra(key, value, icon, options) {
    const contentStyle = (options && options.contentStyle) || styles.repsCard;
    return (
      <div style={styles.cardContainer}>
        <div style={contentStyle}>{value}</div>
        <div style={styles.lowerCardContent}>
          {icon && (
            <img
              style={{
                width: 13,
                height: 13,
                marginRight: 8,
              }}
              src={icon}
              alt={key}
            ></img>
          )}
          <span style={styles.lowerCardText}>{key}</span>
        </div>
      </div>
    );
  }

  toggleNotes = () => {
    this.setState({
      notesOpen: !this.state.notesOpen,
    });
  };

  toggleVideoNotes = () => {
    this.setState({
      videoNotesOpen: true,
    });
  };

  renderIcon() {
    return (
      <>
        {this.props.data.note && this.props.showNote && (
          <i
            onClick={this.toggleNotes}
            className="icon material-icons"
            style={{ color: "gray", cursor: "pointer", fontSize: 24, top: -4 }}
          >
            note
          </i>
        )}
        {this.props.data.videoNotes && this.props.showNote && (
          <i
            onClick={this.toggleVideoNotes}
            className="icon material-icons"
            style={{
              color: "gray",
              cursor: "pointer",
              fontSize: 24,
              top: -4,
            }}
          >
            voice_chat
          </i>
        )}
      </>
    );
  }

  isEmptyEquipmentType() {
    if (this.isEmptyType) {
      return this.isEmptyType;
    }
    let types = Object.values(this.props.data.equipmentTypes);
    this.isEmptyType = true;
    for (let type of types) {
      if (type) {
        this.isEmptyType = false;
      }
    }
    return this.isEmptyType;
  }

  isRepsDisabled = () => {
    if (this.repsDisabled !== undefined) {
      return this.repsDisabled;
    }
    this.repsDisabled = !this.props.data.reps;
    return this.repsDisabled;
  };
  isSetsDisabled = () => {
    if (this.setsDisabled !== undefined) {
      return this.setsDisabled;
    }
    this.setsDisabled = !this.props.data.sets;
    return this.setsDisabled;
  };

  isTimeDisabled = () => {
    if (this.timeDisabled !== undefined) {
      return this.timeDisabled;
    }
    this.timeDisabled = !this.props.data.time;
    return this.timeDisabled;
  };

  isResistanceDisabled = () => {
    if (this.resistanceDisabled !== undefined) {
      return this.resistanceDisabled;
    }
    let equipmentTypes = Object.values(this.props.data.equipmentTypes);
    this.resistanceDisabled = !equipmentTypes.includes(
      EQUIPMENT_TYPES.RESISTANCE
    );
    if (this.resistanceDisabled && this.isEmptyEquipmentType()) {
      this.resistanceDisabled = !this.props.data.resistance;
    }
    return this.resistanceDisabled;
  };

  isWeightDisabled = () => {
    if (this.weightDisabled !== undefined) {
      return this.weightDisabled;
    }
    let equipmentTypes = Object.values(this.props.data.equipmentTypes);
    this.weightDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.FREEWEIGHT);
    if (this.weightDisabled && this.isEmptyEquipmentType()) {
      this.weightDisabled = !this.props.data.weight;
    }
    return this.weightDisabled;
  };

  closeVideNotes = () => {
    this.setState({
      videoNotesOpen: false,
    });
  };

  renderExtras() {
    return (
      <React.Fragment>
        {this.state.notesOpen && (
          <div style={styles.noteStyle}>{this.props.data.note}</div>
        )}

        <Dialog
          open={Boolean(this.state.videoNotesOpen)}
          onClose={this.closeVideNotes}
          aria-labelledby="video-notes"
        >
          <DialogTitle id="video-notes">Video Notes</DialogTitle>
          <DialogContent>
            {this.props.data.videoNotes && (
              <ReactPlayer controls={true} url={this.props.data.videoNotes} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeVideNotes} color="default">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Row style={{ marginTop: 8, paddingRight: 9 }}>
          {this.props.data.resistance && !this.isResistanceDisabled() && (
            <Col style={{ marginLeft: "-10px" }}>
              {this.renderExtra(
                "Resistance",
                RESISTANCE_OPTIONS[this.props.data.resistance],
                null,
                { contentStyle: resistantStyle }
              )}
            </Col>
          )}
          {Boolean(Number(this.props.data.sets)) && !this.isSetsDisabled() && (
            <Col>{this.renderExtra("Sets", this.props.data.sets, Sets)}</Col>
          )}
          {Boolean(Number(this.props.data.reps)) && !this.isRepsDisabled() && (
            <Col>{this.renderExtra("Reps", this.props.data.reps, Reps)}</Col>
          )}
          {Boolean(Number(this.props.data.weight)) &&
            !this.isWeightDisabled() && (
              <Col>
                {this.renderExtra("Weight", this.props.data.weight, Weight)}
              </Col>
            )}
          {Boolean(Number(this.props.data.time)) && !this.isTimeDisabled() && (
            <Col>{this.renderExtra("Time", this.props.data.time, Clock)}</Col>
          )}
        </Row>
      </React.Fragment>
    );
  }
}
