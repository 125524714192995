import React from "react";
import { Button, Dialog, Typography } from "@material-ui/core";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import DateRangePicker from "../../components/datePicker/datePicker";
import NoteFilters from "../../components/noteFilters/noteFilters";
import {
  COLLECTIONS,
  FORM_TYPES,
  PDF_DATE_FORMAT,
  PDF_TEMPLATES,
  ROLES,
} from "../../constants";
import NoteEditor from "../../components/noteAssessments/noteAssessmentEditor/noteEditor";
import NoteAssessmentCommon, {
  getClientFormsdata,
  updateClientForms,
} from "./noteAssessmentCommon";
import NotesList from "../../components/noteAssessments/noteAssessmentList/notesList";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";

export default class NotesMain extends NoteAssessmentCommon {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      dateToday: window.moment(),
      showAttachments: false,
      showClinicalNotes: true,
      showWorkoutNotes: true,
      collection: null,
      ...this.state,
    };
    this.customSubmitRef = React.createRef();
  }

  filterClientForms = (forms) => {
    return forms[FORM_TYPES.NOTES] || [];
  };

  fetchData = async (loadMore = false) => {
    var canLoadMore = false;
    if (!loadMore) {
      this.setState({
        loading: true,
      });
    }
    let notesDataCopy = [];
    if (loadMore) {
      notesDataCopy = Object.assign([], this.state.data);
      this.setState({ loadingMore: true });
    }
    try {
      let data = {
        clientId: this.props.clientId,
      };
      if (loadMore && this.state.lastFetched) {
        data.lastFetchedNoteId = this.state.lastFetched.id;
      }
      if (this.state.startDate && this.state.endDate) {
        data.startDate = this.state.startDate.startOf("day").toDate();
        data.endDate = this.state.endDate.endOf("day").toDate();
      }
      let result = await window.FortisForma.database.fetchNotes(data);
      if (result && result.length && result.length >= 50) {
        canLoadMore = true;
      }
      notesDataCopy = notesDataCopy.concat(result);

      if (notesDataCopy.length && this.state.showAttachments) {
        notesDataCopy = notesDataCopy.filter(
          (note) => note.images && note.images.length
        );
      }

      if (notesDataCopy.length && !this.state.showWorkoutNotes) {
        notesDataCopy = notesDataCopy.filter((note) => !note.summaryId);
      }

      if (notesDataCopy.length && !this.state.showClinicalNotes) {
        notesDataCopy = notesDataCopy.filter((note) => note.summaryId);
      }

      this.setState({
        data: notesDataCopy,
        loading: false,
        loadingMore: false,
        lastFetched: result[result.length - 1],
        disableLoadMore: !canLoadMore,
      });
    } catch (e) {
      window.NotificationUtils.showError("Error fetching notes");
      this.setState({
        loading: false,
        loadingMore: false,
      });
      console.error(e);
    }
  };

  setDateFilter = (start, end) => {
    this.setState(
      {
        startDate: start && start._isValid ? start : null,
        endDate: end && end._isValid ? end : null,
      },
      this.fetchData
    );
  };

  deletePendingForm = async (formId) => {
    const updatedData = getClientFormsdata(
      this.state.clientForms,
      this.props.clientId,
      FORM_TYPES.NOTES,
      this.props.clientEmail
    );
    try {
      await Promise.all([
        window.FortisForma.database.updateClientFormsData(updatedData),
        window.FortisForma.database.removeForm(formId),
      ]);
    } catch (e) {
      throw e;
    }
  };

  onFilterChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.checked,
      },
      this.fetchData
    );
  };

  onSave = async (noteData) => {
    try {
      let submissionData = { ...noteData.submissionData } || null;
      const result = await window.FortisForma.database.saveNoteData(noteData);
      if (noteData.formId && submissionData && submissionData.formData) {
        await updateClientForms(
          submissionData,
          result,
          this.state.clientForms,
          FORM_TYPES.NOTES
        );
      }
      if (this.state.selectedItem && this.state.selectedItem.id) {
        this.onItemSaved(result, this.state.selectedItem);
      } else {
        this.onNewNoteSaved(result);
      }
      this.scrollToTop();
    } catch (e) {
      throw e;
    }
  };

  onNewNoteSaved = (result) => {
    let notesData = Object.assign([], this.state.data);
    if (!this.state.endDate || this.state.endDate >= window.moment()) {
      notesData.unshift(result);
    }
    this.setState(
      {
        data: notesData,
        selectedItem: {},
        lastFetched: notesData[notesData.length - 1],
      },
      this.fetchClientForms
    );
  };

  getPlaceholderText = () => {
    if (this.state.startDate && this.state.endDate) {
      return "No notes available for the selected range of dates and filters.";
    }
    return `No notes available for this client, click on the "Add Note" button to
    add new note or change filter options.`;
  };

  renderEmptyPlaceholder = () => {
    return (
      <div className="notesEmptyPlaceholder">
        <Typography variant="body1" className="textColorGray">
          {this.getPlaceholderText()}
        </Typography>
      </div>
    );
  };

  handleSavePDF = async (data, exportAllVersions = false) => {
    try {
      const archives = await this.fetchNoteAssessmentArchives(
        data,
        exportAllVersions,
        COLLECTIONS.NOTE_ARCHIVES
      );
      const pdfData = await this.getPDFData(archives, exportAllVersions);
      const documentName = `note_${window.getTimestampSeconds(
        data.updatedTime
      )}`;
      const templateId = exportAllVersions
        ? PDF_TEMPLATES.NOTE_VERSIONS
        : PDF_TEMPLATES.NOTE;
      const results = await window.FortisForma.database.getPDF(
        templateId,
        pdfData,
        documentName
      );

      if (results.saved) {
        window.NotificationUtils.showSuccess("Downloading PDF");
      } else {
        window.NotificationUtils.showError("Error");
      }
    } catch (e) {
      throw e;
    }
  };

  formatData = (noteData, exportAllVersions = false) => {
    let data = {};
    data.title = noteData.title && noteData.title;
    data.description = noteData.description && noteData.description;
    data.date = moment
      .unix(window.getTimestampSeconds(noteData.updatedTime))
      .format(PDF_DATE_FORMAT);
    data.attachments = {};
    data.attachments.images = (noteData.images && noteData.images) || [];
    data.attachments.documents = data.attachments.images.filter((p) =>
      window.isPDF(p.url) ? true : false
    );
    data.attachments.images = data.attachments.images.filter((p) =>
      !window.isPDF(p.url) ? true : false
    );
    if (noteData.creator) {
      data.createdBy = noteData.creator;
    }
    if (exportAllVersions && noteData.formId) {
      data.form = this.getFormVersionURL(
        noteData.formId,
        noteData.submissionId,
        noteData.creatorId
      );
    }

    return data;
  };

  renderItemsList = () => {
    return (
      <NotesList
        data={this.state.data}
        fetchData={this.fetchData}
        loader={this.renderLoading}
        loading={this.state.loading}
        onEdit={this.onClickEdit}
        onDelete={this.onClickDelete}
        onExport={this.handleSavePDF}
        onClickPreview={this.onClickPreview}
        clientForms={this.state.clientForms}
        renderEmptyPlaceholder={this.renderEmptyPlaceholder}
      />
    );
  };

  renderEditor = () => {
    return (
      <Dialog
        id="noteEditorDialog"
        maxWidth="md"
        fullWidth={true}
        open={this.state.openEditor}
        onClose={this.onCloseEditor}
      >
        <NoteEditor
          {...this.props}
          ref={this.editorRef}
          onClose={this.onCloseEditor}
          onSave={this.onSave}
          selectedItem={this.state.selectedItem.id && this.state.selectedItem}
          enterpriseId={this.props.user.enterpriseId || ""}
          clientId={this.props.clientId || ""}
          clientLoginPending={this.props.clientLoginPending}
          clientEmail={this.props.clientEmail || ""}
          clientName={this.props.clientName}
          clientDOB={this.props.clientDOB}
          openSidePanel={this.openSidePanel}
          user={this.props.user}
          openFormsModal={this.openFormsModal}
          deletePendingForm={this.deletePendingForm}
        />
      </Dialog>
    );
  };

  renderNotesDateRangeFilter = () => {
    return (
      <div className="notesDateRangePickerContainer">
        <Typography variant="subtitle1" className="textTransformInitial">
          Filter by date:
        </Typography>
        <InsertInvitationIcon
          className="notesDateRangePickerCalendarIcon"
          onClick={() => {
            window.$(`input[name="notesDateRangePicker"]`).click();
          }}
          fontSize="small"
          color="primary"
        />
        <DateRangePicker
          key="datePicker"
          klassName="datePicker"
          name="notesDateRangePicker"
          callback={this.setDateFilter}
          inputProps={{
            autoUpdateInput: false,
            opens: "left",
            maxDate: new Date(),
            locale: {
              cancelLabel: "Clear",
            },
            startDate: this.state.startDate || this.state.dateToday,
            endDate: this.state.endDate || this.state.dateToday,
            ranges: {
              All: [null, null],
              "Last Week": [
                window.moment().subtract(1, "weeks").startOf("isoWeek"),
                window.moment().subtract(1, "weeks").endOf("isoWeek"),
              ],
              "Last Month": [
                window.moment().subtract(1, "months").startOf("month"),
                window.moment().subtract(1, "months").endOf("month"),
              ],
              "Last 30 days": [
                window.moment().subtract(30, "days"),
                window.moment(),
              ],
            },
          }}
        />
      </div>
    );
  };

  getSidePanelTitle = () => {
    return "Note Versions";
  };

  disableDropdown = () => {
    if (
      this.props.user.role === ROLES.FACILITY_ADMIN ||
      this.props.user.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.user.enterprise.active) return false;
    } else {
      if (this.props.user.active) return false;
    }

    return true;
  };

  renderAddButton = () => {
    return (
      <>
        <Button
          className="addNoteAssessmentButton"
          color="primary"
          variant="contained"
          onClick={this.openEditor}
          startIcon={<AddIcon />}
          // disabled={this.disableDropdown()}
        >
          Add Note
        </Button>
      </>
    );
  };

  renderNotesFilters = () => {
    return (
      <div className="noteFilterContainer">
        <NoteFilters
          onFilterChange={this.onFilterChange}
          showAttachments={this.state.showAttachments}
          showWorkoutNotes={this.state.showWorkoutNotes}
          showClinicalNotes={this.state.showClinicalNotes}
          handleChangeAttachments={this.handleChangeAttachments}
        >
          {this.renderNotesDateRangeFilter()}
        </NoteFilters>
      </div>
    );
  };

  renderExtras = () => {
    return <>{this.renderNotesFilters()}</>;
  };
}
