import {
  COLLECTIONS,
  ROLES,
  TIER,
  GET_ENT_ID,
  GET_USER_ID,
} from "../../../constants";
import { getTotalQuestions } from "../../../views/enterpriseClientDetails/forms";
import NoteAssessmentFormsModal from "./noteAssessmentFormsModal";

export default class NoteFormsModal extends NoteAssessmentFormsModal {
  getDialogTitle = () => {
    return "Note Forms";
  };

  fetchEnterpriseForms = async () => {
    try {
      this.setState({ loading: true });
      let result;
      const enterpriseId = GET_ENT_ID(this.props);
      const userID = GET_USER_ID(this.props);
      result = await window.FortisForma.database.fetchNoteForms(
        // this.props.user.id,
        userID,
        enterpriseId
      );

      // if (
      //   this.props.user.role === ROLES.TRAINER &&
      //   this.props.user.tier !== TIER.FREE
      // ) {
      //   console.log('fetchNoteForms if')
      //   result = await window.FortisForma.database.fetchNoteForms(
      //     this.props.user.id,
      //     this.props.user.enterpriseId
      //   );
      // } else {
      //   console.log('fetchNoteForms else')
      //   result = await window.FortisForma.database.fetchNoteForms(
      //     this.props.user.id,
      //     this.props.user.enterpriseId
      //   );
      // }

      this.setState({
        formList: result || [],
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  getNoteId = async () => {
    let noteId = this.props.editItemId || "";

    if (!noteId) {
      noteId = await window.FortisForma.database.getRandomDocId(
        COLLECTIONS.NOTES
      );
    }

    return noteId;
  };

  onFillForm = async (event, form) => {
    event.stopPropagation && event.stopPropagation();
    this.setState({ loading: true });
    try {
      let noteId = await this.getNoteId();
      let formData = {
        formMetaData: form.formMetaData,
        schema: form.schema,
        uiSchema: form.uiSchema,
        step: form.step,
        sequence: null,
        enterpriseId: this.props.user.enterpriseId || "",
        enterpriseForm: true,
        active: true,
        clientId: this.props.clientId,
        clientEmail: this.props.clientEmail,
        noteId: noteId,
      };
      // if (this.props.user.role === ROLES.TRAINER &&
      //   this.props.user.tier !== TIER.FREE) {
      //   formData.userId = this.props.user.id;
      //   formData.enterpriseForm = false;
      // }else{
      //   formData.enterpriseId =  this.props.user.enterpriseId || "";
      //   formData.enterpriseForm = true;
      // }

      const totalQuestions = getTotalQuestions(
        form.schema && form.schema.properties
      );

      formData.filled = Boolean(!totalQuestions);
      const results = await window.FortisForma.database.saveForms(formData);
      window.NotificationUtils.showSuccess("Form assigned to client");
      this.props.onFormSaved(results);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };
}
