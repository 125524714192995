import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WorkoutFeedback from "../workoutSession/workoutFeedback";
import moment from "moment";

export default class WorkoutCompleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showAllFeedback: false,
      feedbackRequired: true,
    };
    this.workoutFeedbackRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });
    let lastFeedbackDate = moment.unix(this.props.lastFeedbackDate);
    //TODO : Check if this method is correct
    if (!lastFeedbackDate.isValid()) {
      this.setState({
        showAllFeedback: true,
      });
      return;
    }
    let todayUnix = moment.unix(this.props.todayUnix);
    var diff = Math.ceil(
      moment.duration(todayUnix.diff(lastFeedbackDate)).asDays()
    );
    if (diff >= 6) {
      this.setState({
        showAllFeedback: true,
      });
    }
  }

  renderTitle = () => {
    if (this.props.message) {
      return null;
    }
    return (
      <Typography className="textAlignCenter" variant="h6" color="primary">
        Awesome!
      </Typography>
    );
  };

  renderSubTitle = () => {
    if (this.props.message) {
      return (
        <Typography
          color="primary"
          variant="subtitle1"
          className="marginBottom18 textAlignCenter"
        >
          {this.props.message}
        </Typography>
      );
    }
    return (
      <Typography
        variant="body2"
        className="textColorGray marginBottom18 textAlignCenter"
      >
        Looks like you are done for the day
      </Typography>
    );
  };

  onClickContinue = (goback = false) => {
    let details = this.workoutFeedbackRef.current.getDetails();
    let feedback = {};
    let updateDate = false;
    feedback.energized = details.energized;
    feedback.proudness = details.proudness;
    if (this.state.showAllFeedback) {
      feedback.intensity = details.intensity;
      feedback.length = details.length;
      updateDate = true;
    }
    this.props.workoutCompleteDone(feedback, updateDate, goback);
  };

  renderActionButtons = () => {
    return (
      <div>
        <Button
          size="large"
          id="completeModalContinueButton"
          color="primary"
          onClick={() => this.onClickContinue(false)}
        >
          Continue Workout
        </Button>

        <Button
          size="large"
          id="completeModalContinueBackButton"
          color="primary"
          variant="contained"
          onClick={() => this.onClickContinue(true)}
        >
          Submit & End
        </Button>
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className="marginBottom18" id="doneCompleteModalIcon">
        <CheckCircleIcon
          fontSize="large"
          color="primary"
          style={{ width: 60, height: 60 }}
        />
      </div>
    );
  };

  renderWorkoutFeedbackForm = () => {
    return (
      <WorkoutFeedback
        ref={this.workoutFeedbackRef}
        showAllFeedback={this.state.showAllFeedback}
      />
    );
  };

  render() {
    return (
      <div id="workoutCompleteModal">
        <DialogContent>
          {this.renderHeader()}
          {this.renderTitle()}
          {this.renderSubTitle()}
          {this.state.feedbackRequired && this.renderWorkoutFeedbackForm()}
        </DialogContent>
        <DialogActions className="justifyContentCenter" style={{ padding: 16 }}>
          {this.renderActionButtons()}
        </DialogActions>
      </div>
    );
  }
}
