import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  addUserMessages,
  currentUserMessages,
  sendMessageofNotification,
} from "../../modules/database/database";
import { COLLECTIONS, MONTHS, ROLES } from "../../constants";
import { v4 as uuidv4 } from "uuid";

function ChatRoom(
  {
    selectedClient,
    currentUser,
    unsubscribe,
    removeLastMessage,
    setSelectedClient,
  },
  props
) {
  const [formValue, setFormValue] = useState("");
  const [messages, setMessages] = useState({});

  const [oldMessages, setOldMessages] = useState([]);
  let subscribeStaff;
  let subscribeClient;
  const getCurrentMessages = async () => {
    await updateReadStatus();
    await currentUserMessages(currentUser, selectedClient).then((res) => {
      if (res.length) {
        let result = [...res];
        result.sort((a, b) => a.createdAt - b.createdAt);
        setMessages(groupByDateTime(result));
        setOldMessages(result);

        let myDiv = document.getElementById("chat_container");
        myDiv.scrollTop = myDiv.scrollHeight;
      } else {
        setMessages({});
      }
    });
  };
  useEffect(() => {
    // getCurrentMessages();

    return () => {
      subscribeStaff && subscribeStaff();
      subscribeClient && subscribeClient();
    };
  }, [props]);
  const getRealTimeMessageForStaff = () => {
    let id = currentUser.id + "-" + selectedClient.id;
    let id2 = selectedClient.id + "-" + currentUser.id;
    return window.firebase
      .firestore()
      .collection(COLLECTIONS.MESSAGE)
      .where("type", "in", [id, id2])
      .onSnapshot((doc) => {
        subscribeClient && subscribeClient();
        let data = [];
        doc.docs.map((item) => {
          data.push(item.data());
        });
        if (data.length) {
          let result = [...data];
          result.sort((a, b) => a.createdAt - b.createdAt);
          setMessages(groupByDateTime(result));
          setOldMessages(result);
          updateReadStatus();
          removeLastMessage && removeLastMessage(selectedClient);
          if (result.length > oldMessages.length) {
            let myDiv = document.getElementById("chat_container");
            myDiv.scrollTop = myDiv.scrollHeight;
          }
        } else {
          setMessages({});
        }
      });
  };
  const getRealTimeMessageForClient = () => {
    return window.firebase
      .firestore()
      .collection(COLLECTIONS.MESSAGE)
      .where("trainerId", "==", currentUser.id)
      .where("clientId", "==", selectedClient.id)
      .onSnapshot((doc) => {
        subscribeStaff && subscribeStaff();
        let data = [];
        doc.docs.map((item) => {
          data.push(item.data());
        });
        if (data.length) {
          let result = [...data];

          result.sort((a, b) => a.createdAt - b.createdAt);
          setMessages(groupByDateTime(result));
          setOldMessages(result);
          updateReadStatus();
          removeLastMessage && removeLastMessage(selectedClient);
          if (result.length > oldMessages.length) {
            let myDiv = document.getElementById("chat_container");
            myDiv.scrollTop = myDiv.scrollHeight;
          }
        } else {
          setMessages({});
        }
      });
  };
  useEffect(() => {
    unsubscribe && unsubscribe();
    if (selectedClient.role !== "Client") {
      subscribeStaff = getRealTimeMessageForStaff();
    } else {
      subscribeClient = getRealTimeMessageForClient();
    }

    return () => {
      subscribeStaff && subscribeStaff();
      subscribeClient && subscribeClient();
      // subscribe&&subscribe();
      // setSelectedClient("");
    };
  }, [selectedClient]);

  const updateReadStatus = () => {
    unsubscribe && unsubscribe();

    // console.log(currentUser, "====", selectedClient);
    if (selectedClient.role !== "Client") {
      removeLastMessage && removeLastMessage(selectedClient);
      // let id = currentUser.id + "-" + selectedClient.id;
      let id2 = selectedClient.id + "-" + currentUser.id;
      window.firebase
        .firestore()
        .collection(COLLECTIONS.MESSAGE)
        .where("type", "in", [id2])
        .where("coach_read_status", "==", false)
        .get()
        .then((doc) => {
          doc.docs.map((item) => {
            let messageRef = window.firebase
              .firestore()
              .collection(COLLECTIONS.MESSAGE)
              .doc(item.id);
            messageRef.set(
              {
                coach_read_status: true,
              },
              { merge: true }
            );
          });
        });
    } else {
      removeLastMessage && removeLastMessage(selectedClient);
      window.firebase
        .firestore()
        .collection(COLLECTIONS.MESSAGE)
        .where("trainerId", "==", currentUser.id)
        .where("clientId", "==", selectedClient.id)
        .where("trainer_read_status", "==", false)
        .get()
        .then((doc) => {
          doc.docs.map((item) => {
            let messageRef = window.firebase
              .firestore()
              .collection(COLLECTIONS.MESSAGE)
              .doc(item.id);
            messageRef.set(
              {
                trainer_read_status: true,
              },
              { merge: true }
            );
          });
        });
    }
    removeLastMessage && removeLastMessage(selectedClient);
  };
  const groupByDateTime = (messages) => {
    let finalObj = {};
    messages.forEach((item) => {
      let yesterday = new Date(Date.now() - 86400000);
      let ts = new Date(item.createdAt);
      let today = new Date();
      const date = JSON.stringify(new Date(item.createdAt))
        .split("T")[0]
        .replace('"', "");

      const modifiedDate = date.split("-");
      let dateString = "";
      MONTHS.map((item) => {
        if (item.num === parseInt(modifiedDate[1])) {
          if (yesterday.toDateString() === ts.toDateString()) {
            dateString = "Yesterday";
          } else if (ts.toDateString() === today.toDateString()) {
            dateString = "Today";
          } else {
            dateString =
              item.str + " " + modifiedDate[2] + ", " + modifiedDate[0];
          }
        }
      });
      if (finalObj[dateString]) {
        finalObj[dateString].push(item);
      } else {
        finalObj[dateString] = [item];
      }
    });

    return finalObj;
  };
  const convertTimeToDate = (seconds) => {
    if (seconds) {
      let yesterday = new Date(Date.now() - 86400000);
      let ts = new Date(seconds);
      let today = new Date();
      let myDate = new Date(seconds);

      function tConvert(time) {
        time = time
          .toString()
          .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
          time = time.slice(1);
          time = time.slice(0, 3);
          time[3] = +time[0] < 12 ? "AM" : "PM";
          time[0] = +time[0] % 12 || 12;
        }
        return time.join("");
      }

      if (yesterday.toDateString() === ts.toDateString()) {
        return (
          <>
            <b>Yesterday</b> {tConvert(myDate.toLocaleTimeString())}
          </>
        );
      } else if (ts.toDateString() === today.toDateString()) {
        return (
          <>
            <b>Today</b> {tConvert(myDate.toLocaleTimeString())}
          </>
        );
      } else {
        let yesterday1 = new Date(Date.now() - 86400000);
        let ts1 = new Date(seconds);
        let myDate1 = new Date(seconds);
        if (yesterday1.toDateString() === ts1.toDateString()) {
          return (
            <>
              <b>Yesterday</b> {tConvert(myDate1.toLocaleTimeString())}
            </>
          );
        } else if (ts1.toDateString() === today.toDateString()) {
          return (
            <>
              <b>Today</b> {tConvert(myDate1.toLocaleTimeString())}
            </>
          );
        } else {
          return (
            <>
              <b>{new Date(seconds).toDateString()}</b>{" "}
              {tConvert(myDate1.toLocaleTimeString())}
            </>
          );
        }
      }
    }
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      window.NotificationUtils.showSuccess("Sending....");
      let msgID = uuidv4();
      await sendMessageofNotification(
        { name: currentUser.name, text: formValue },
        selectedClient
      );
      setFormValue("");
      await addUserMessages(formValue, currentUser, selectedClient, msgID).then(
        (response) => {
          window.NotificationUtils.showSuccess("Message Sent Successfully.");
          if (response) {
            // getCurrentMessages();
          }
        }
      );
      // }
    } catch (e) {
      window.NotificationUtils.showError("Message not sent.");
      setFormValue("");
    }
  };

  return (
    <>
      <div className="ChatRoom">
        <h4>{selectedClient.name}</h4>
        <div className="msg_history">
          <div className="chat" id="chat_container">
            {JSON.stringify(messages) === "{}" && (
              <p className="chat-date">Say Hi!</p>
            )}
            {Object.keys(messages).map((message) => {
              return (
                <>
                  <p className="chat-date">{message}</p>
                  {messages[message].length ? (
                    messages[message].map((item, index) => (
                      <>
                        {item.trainerId === currentUser.id &&
                        item.sender === currentUser.role ? (
                          <div className="outgoing_msg" key={index}>
                            <div className="sent_msg">
                              <span className="time_date">
                                <b>
                                  <i>You,</i>
                                </b>{" "}
                                {item.createdAt !== null &&
                                  convertTimeToDate(item.createdAt)}
                              </span>
                              <p>{item.text}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="incoming_msg" key={index}>
                            <div className="received_msg">
                              <span className="time_date">
                                <b>
                                  <i>{selectedClient.name},</i>
                                </b>{" "}
                                {item.createdAt !== null &&
                                  convertTimeToDate(item.createdAt)}
                              </span>
                              <p>{item.text}</p>
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <p className="chat-date">Say Hi!</p>
                  )}
                </>
              );
            })}
          </div>
          <div className="type_msg">
            <form onSubmit={sendMessage} className="input_msg_write">
              <input
                value={formValue}
                onChange={(e) => setFormValue(e.target.value)}
                className="write_msg"
                placeholder="Type your message here..."
              />
              <Button
                variant="contained"
                color="primary"
                className="msg_send_btn"
                type="submit"
                disabled={!formValue}
              >
                Send
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatRoom;
