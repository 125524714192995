import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export default class TermsConditions extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggleTermsConditions}>
                <ModalHeader id="termsModalHeader" toggle={this.props.toggleTermsConditions}>Terms {"&"} Conditions</ModalHeader>
                <ModalBody id="termsModalBody">
                    <strong>Privacy Policy</strong ><br /><br />      
                    Fortis Forma understands your right to privacy of personal information. We recognize that you have provided us with, and will be providing us on an ongoing basis, information such as your contact information, education and professional experience. This information will be used to help us connect you with potential new clients and will not be released without your consent.<br />
                    We will be using your personal contact information for filtering purposes to match you with the most relevant clients and in some cases billing purposes.<br />
                    All employees and contractors of Fortis Forma will adhere to our privacy policy.<br /><br /> 

                    <strong>Terms {"&"} Conditions</strong><br /><br /> 
                    Information published on this web site is provided for the use of its visitors and you are advised that, although care has been taken to ensure technical and factual accuracy, some errors may occur. No guarantee is given of the accuracy or completeness of information on these pages. Please be aware that Fortis Forma may alter the information on its web site from time to time. Fortis Forma shall have no liability arising from the use by any party of the information on this web site. Fortis Forma does not warrant the information on this web site in any way and in particular no warranty is given that the web site or its contents or hypertext links are virus free or uncontaminated. You are advised to make your own virus checks and to implement your own precautions in this respect. Fortis Forma excludes all liability for contamination or damage caused by any virus or electronic transmission.<br />
                    If you have any questions regarding our services and/or our policies, please feel free to contact <br /> 
                    Jonathan Chant at our office (613) 857-3962.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggleTermsConditions}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}