import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React from "react";
import LevelDecider from "../levelDecider/levelDecider";
import ExerciseDesigner from "./singleExerciseDesigner";
import memoize from "memoize-one";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { COLLECTIONS, DB_KEYS } from "../../constants";

export default class ExerciseLevelsDesigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLevel: 0,
      levelsStatusMap: { 0: false },
      levels: props.levels || { 0: { level: 1 } },
      showLevelDecider: false,
      levelEditMode: false,
      showConfirmationBox: false,
    };
    this.singleExerciseDesignerRef = React.createRef();
    let exercise = this.props.data;
    if (exercise) {
      this.state.selectedLevel = +exercise.level - 1;
      for (let i = 0; i < this.state.selectedLevel; i++) {
        this.state.levels[i] = this.createDummyLevel(i + 1);
        this.state.levelsStatusMap[i] = false;
      }
      this.state.levels[this.state.selectedLevel] = exercise;
      this.state.levelsStatusMap[this.state.selectedLevel] = true;
    }

    if (this.props.newExerciseFilters && !exercise) {
      let exercise = {
        name: this.props.newExerciseFilters.query,
        level: 1,
        muscleGroups: {
          Primary: this.props.newExerciseFilters.muscle,
        },
        equipmentCategories: {
          Primary: this.props.newExerciseFilters.equipment,
        },
        functionCategories: {
          Primary: this.props.newExerciseFilters.functionItem,
        },
      };
      this.state.levels[0] = exercise;
    }
  }

  onDraftLevel = (data, level) => {
    let levels = Object.assign({}, this.state.levels);
    levels[level - 1] = data;
    this.setState({ levels });
  };

  componentDidMount() {
    this.props.data && this.fetchSimilarExercise(this.props.data);
  }

  fetchSimilarExercise = async (exercise) => {
    try {
      let results = await window.FortisForma.database.loadMoreLevelsOfExercise(
        exercise
      );
      let exercises = [];
      for (let doc of results) {
        if (doc.id !== exercise.id) {
          let data = doc;
          data.id = doc.id;
          if (
            +data.level === +this.state.selectedLevel + 1 ||
            data.trainerId === DB_KEYS.ADMIN
          ) {
            continue;
          }
          exercises.push(data);
        }
      }

      let update = {};
      let levelsStatusUpdate = {};
      for (let exercise of exercises) {
        update[+exercise.level - 1] = exercise;
        levelsStatusUpdate[+exercise.level - 1] = true;
      }
      let levels = Object.assign({}, this.state.levels, update);
      let levelKeys = Object.keys(levels);
      if (!(levelKeys.length === Number(levelKeys[levelKeys.length]))) {
        for (let i = Number(levelKeys[levelKeys.length - 1]); i > 0; i--) {
          if (!levelKeys.includes(i.toString())) {
            levels[i] = this.createDummyLevel(i + 1);
            this.state.levelsStatusMap[i] = false;
          }
        }
      }
      this.setState({
        levels: levels,
        levelsStatusMap: Object.assign(
          {},
          this.state.levelsStatusMap,
          levelsStatusUpdate
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  onSaveLevel = async (data, level) => {
    window.NotificationUtils.showConfirm(`Saving ${data.name}`);
    let levels = Object.assign({}, this.state.levels);
    let levelsStatusMap = Object.assign({}, this.state.levelsStatusMap);
    levels[level] = data;
    levelsStatusMap[level] = true;
    this.setState({ levels, levelsStatusMap });
    await this.saveExercise(data);
  };

  saveExercise = async (exercise) => {
    let exerciseData = exercise;

    if (this.props.user.enterpriseId) {
      exerciseData.enterpriseId = this.props.user.enterpriseId;
    } else {
      exerciseData.trainerId = this.props.user.id;
    }
    try {
      let response = await window.FortisForma.database.storeExercise(
        exerciseData
      );
      this.props.onClickSave(response);
      window.NotificationUtils.showSuccess(`${exercise.name} saved`);
    } catch (e) {
      console.error(e);
    }
  };

  onAddLevel = () => {
    this.singleExerciseDesignerRef.current.draft();
    this.setState({
      showLevelDecider: true,
      levelEditMode: false,
    });
  };

  closeLevelDecider = () => {
    this.setState({
      showLevelDecider: false,
    });
  };

  getLevels = () => {
    let levels = Object.keys(this.state.levels).length;
    return +levels;
  };

  editLevel = (level) => {
    let index = +level - 1;
    let copyLevel = Object.assign({}, this.state.levels);
    let copySelectedLevel = copyLevel[this.state.selectedLevel];
    let levelsStatusMap = Object.assign({}, this.state.levelsStatusMap);
    copyLevel[index] = copySelectedLevel;
    levelsStatusMap[index] = levelsStatusMap[this.state.selectedLevel];
    copyLevel[index].level = level;
    levelsStatusMap[this.state.selectedLevel] = false;
    copyLevel[this.state.selectedLevel] = Object.assign(
      {},
      this.createDummyLevel(
        +this.state.selectedLevel + 1,
        this.state.levels[+this.state.selectedLevel]
      )
    );
    this.setState({
      levels: copyLevel,
      levelsStatusMap,
      selectedLevel: index,
    });
  };

  addNewLevels = async (level) => {
    let previousLevels = this.getLevels();
    let levelsToAdd = level - previousLevels;
    if (+levelsToAdd === 0) {
      return;
    } else {
      for (let i = 1; i <= levelsToAdd; i++) {
        let levelToAdd = previousLevels + i;
        if (this.state.levelEditMode && levelToAdd === level) {
          continue;
        }
        await this.addLevel(levelToAdd);
      }
      this.state.levelEditMode && this.editLevel(level);
    }
  };

  createDummyLevel(level, currentLevel) {
    let newLevel = Object.assign({}, currentLevel);
    delete newLevel.id;
    newLevel.level = level;
    newLevel.name = "";
    newLevel.description = "";
    newLevel.videoURL = "";
    newLevel.posterURL = "";
    newLevel.alternateNames = [];
    newLevel.createdTime = null;
    newLevel.updatedTime = null;
    return newLevel;
  }

  getDummyLevel() {
    let level = {
      name: "",
      description: "",
      videoURL: "",
      posterURL: "",
      alternateNames: [],
      config: { reps: "", weight: "", resistance: "", time: "", sets: "" },
      equipmentCategories: { Primary: "", Secondary: "" },
      equipmentTypes: { Primary: "", Secondary: "" },
      functionCategories: { Primary: "", Secondary: "", Tertiary: "" },
      movement: "",
      muscleGroups: {
        Primary: "",
        Secondary: "",
        Tertiary: "",
        Quarterly: "",
        Quinary: "",
      },
    };
    return level;
  }

  addLevel = (level) => {
    let levels = Object.assign({}, this.state.levels);
    let levelsStatusMap = Object.assign({}, this.state.levelsStatusMap);
    levelsStatusMap[+level - 1] = false;
    levels[+level - 1] = Object.assign(
      {},
      this.createDummyLevel(level, levels[this.state.selectedLevel])
    );
    return new Promise((resolve) =>
      this.setState(
        {
          levels,
          levelsStatusMap,
        },
        resolve
      )
    );
  };

  handleChange = (event, value) => {
    if (!this.allowLevelChange()) {
      return;
    }
    // TODO: Level data will be lost confirmation
    this.setState({ selectedLevel: value });
  };

  allowLevelChange = () => {
    const isUploading =
      this.singleExerciseDesignerRef.current.getUploadingStatus();
    if (isUploading) {
      window.NotificationUtils.showConfirm("Upload in progress, please wait.");
      return false;
    }
    return true;
  };

  changeLevel = (level, levelData) => {
    if (!this.allowLevelChange()) {
      return;
    }
    this.onDraftLevel(levelData, Number(level) + 1);
    this.setState({
      selectedLevel: +level,
      showLevelDecider: true,
      levelEditMode: true,
    });
  };

  onDeleteLevel = async (level) => {
    let levels = Object.assign({}, this.state.levels);
    let levelsStatusMap = Object.assign({}, this.state.levelsStatusMap);
    window.NotificationUtils.showSuccess("Deleting exercise");
    try {
      if (levels[level].id) {
        let result = await this.deleteExercise(levels[level]);
        if (!result) {
          return;
        }
      }
      window.NotificationUtils.showSuccess("Exercise deleted successfully");
      delete levels[level];
      levels[level] = this.getDummyLevel();
      levels[level].level = +level + 1;
      levelsStatusMap[level] = false;
      this.setState({
        levels,
        levelsStatusMap,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to delete exercise");
    }
  };

  deleteExercise = async (exercise) => {
    try {
      await window.FortisForma.database.deleteDocument(
        COLLECTIONS.EXERCISES,
        exercise.id
      );
      this.props.onDelete(exercise.id);
      return true;
    } catch (e) {
      throw e;
    }
  };

  onClose = () => {
    let unsaved = 0;
    let levels = Object.assign({}, this.state.levels);
    let levelsStatusMap = Object.assign({}, this.state.levelsStatusMap);
    for (let level of Object.keys(levelsStatusMap)) {
      if (levels[level].name) {
        if (levelsStatusMap[level] === false) {
          unsaved++;
        }
      }
    }
    if (unsaved > 0) {
      this.setState({
        showConfirmationBox: true,
      });
    } else {
      this.props.onClose();
    }
  };

  closeEditor = () => {
    this.closeConfirmationDialog();
    this.props.onClose();
  };

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationBox: false,
    });
  };

  renderConfirmationDialog = () => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={this.state.showConfirmationBox}
      >
        <DialogTitle id="confirmation-dialog-title">Confirmation</DialogTitle>
        <DialogContent dividers>
          You have some unsaved levels, are you sure you want to close the
          editor?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={this.closeConfirmationDialog}
            color="primary"
          >
            No
          </Button>
          <Button onClick={this.closeEditor} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  renderSavedTab = (level) => {
    return (
      <div>
        Level {this.state.levels[level].level}
        <CheckCircleIcon
          style={{ marginLeft: 6 }}
          fontSize="small"
          color="secondary"
        />
      </div>
    );
  };

  renderUnsavedTab = (level) => {
    return <div>Level {this.state.levels[level].level}</div>;
  };

  render() {
    let keys = Object.keys(this.state.levels);
    return (
      <>
        <div className="levelTabsContainer">
          {this.renderConfirmationDialog()}
          <Tabs
            value={this.state.selectedLevel}
            onChange={this.handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons={keys.length < 11 ? "off" : "on"}
          >
            {keys.map((level, index) => {
              return (
                <Tab
                  className={"levelTab"}
                  key={index}
                  label={
                    this.state.levelsStatusMap[+level]
                      ? this.renderSavedTab(level)
                      : this.renderUnsavedTab(level)
                  }
                />
              );
            })}
          </Tabs>
          <IconButton
            size="small"
            onClick={this.onAddLevel}
            color="primary"
            style={{ padding: 4, marginLeft: 6, outline: "none" }}
          >
            <AddCircleIcon fontSize="small" id="addNewLevelButton" />
          </IconButton>
        </div>

        {this.getExerciseDesigner(
          +this.state.selectedLevel,
          this.state.levels[this.state.selectedLevel]
        )}

        <Dialog open={this.state.showLevelDecider}>
          <LevelDecider
            onDoneClick={this.addNewLevels}
            onClickCancel={this.closeLevelDecider}
            currentValue={keys.length}
            editMode={this.state.levelEditMode}
            levels={this.state.levels}
            editLevel={this.editLevel}
          />
        </Dialog>
      </>
    );
  }

  getExerciseDesigner = memoize((level, data) => {
    return (
      <ExerciseDesigner
        {...this.props}
        ref={this.singleExerciseDesignerRef}
        key={level}
        tabLevel={level}
        changeLevel={this.changeLevel}
        newExerciseFilters={this.props.newExerciseFilters}
        data={data}
        onDraftLevel={this.onDraftLevel}
        onClickSave={(saveData) => {
          this.onSaveLevel(saveData, this.state.selectedLevel);
        }}
        onClose={this.onClose}
        editMode={this.state.showLevelDecider}
        onDeleteLevel={this.onDeleteLevel}
      />
    );
  });
}
