import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/umd/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const url = props.schema.data;
  const getScreenWidth = () => {
    let dialog = document.getElementById("previewFormContent");
    let width = dialog.offsetWidth;
    width -= 48;
    return width;
  };
  const renderAllPages = () => {
    const arrayOfPages = [];
    for (let i = 1; i <= numPages; i++) {
      arrayOfPages.push(i);
    }
    return arrayOfPages.map((item, index) => {
      return (
        <Page
          pageNumber={item}
          key={index}
          className="formPdfPreviewPage marginBottom"
          width={getScreenWidth()}
        />
      );
    });
  };
  const renderLoader = () => {
    return (
      <div className="previewFormLoadingContainer">
        <CircularProgress color="primary" />
      </div>
    );
  };
  return (
    <div className="formPdfViewerPreviewContainer">
      <Document
        file={url}
        className="formPdfViewerPreview"
        onLoadSuccess={onDocumentLoadSuccess}
        loading={renderLoader}
      >
        {renderAllPages()}
      </Document>
    </div>
  );
}
