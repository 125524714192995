import classNames from 'classnames';
import React, { Component } from 'react';


export default class ClientSelectionCard extends Component {
	constructor(props) {
	  super(props);
	  this.onSelect = this.onSelect.bind(this);
    }

    onSelect = () => {
		this.props.onSelect(this.props.data)
	}

	onChange = (e) => {
		e.stopPropagation()
	}

    render() {
		var rowClass = classNames ({
			'selectedCard': this.props.selected,
			'clientSMCard': !this.props.selected,
			'centerAlignJustify': true,
			'clientSelectionCard': true
        })
        
                    
		return (
			<div className={rowClass} onClick={this.onSelect}>
				<div className="decFontSize decPadding">
					<p style={{}} >{this.props.data.name}</p>
					<p className="emailMultiline">{this.props.data.email}</p>
				</div>
				
				<div className="centerAlignJustify">
					<div className="form-check text-left">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" checked={this.props.selected} onChange={this.onChange} onClick={this.onChange} />
                          <span className="form-check-sign"></span>
        		        </label>
                    </div>
				</div>
			</div>
		)
    }
}