import React from "react";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";

const FEEDBACK_OPTIONS = ["Boring", "Easy", "Hard"];

export default class ExerciseFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      difficulty: this.props.data.difficulty || null,
      painLevel: this.props.data.painLevel || 0,
      feedbackText: this.props.data.feedbackText || "",
    };
  }

  reset(data) {
    this.setState({
      difficulty: data.difficulty || null,
      painLevel: data.painLevel || 0,
      feedbackText: data.feedbackText || "",
    });
  }

  renderToggleValue = (value, index) => {
    return (
      <ToggleButton
        disabled={this.props.disabled}
        size="small"
        id="feedbackToggleButton"
        key={index}
        value={value}
        className={`${this.props.disabled && "colorDisabled"}`}
      >
        {value}
      </ToggleButton>
    );
  };

  onChangeDifficultyLevel = (event, value) => {
    this.setState(
      {
        difficulty: value,
      },
      () => this.props.onChangeDifficultyLevel("difficulty", value)
    );
  };

  renderToggleButtons = () => {
    return (
      <ToggleButtonGroup
        className="fullWidth"
        value={this.state.difficulty}
        exclusive
        onChange={this.onChangeDifficultyLevel}
        disabled={this.props.disabled}
      >
        {FEEDBACK_OPTIONS.map((value, index) => {
          return this.renderToggleValue(value, index);
        })}
      </ToggleButtonGroup>
    );
  };

  onChangePainSlider = (event, value) => {
    this.setState(
      {
        painLevel: value,
      },
      () => this.props.onChangePainSlider("painLevel", value)
    );
  };

  renderPainSlider = () => {
    return (
      <div id="feedbackPainSlider">
        <Slider
          onChange={this.onChangePainSlider}
          id="painSlider"
          defaultValue={0}
          value={this.state.painLevel}
          step={1}
          valueLabelDisplay="auto"
          max={10}
          disabled={this.props.disabled}
        />
      </div>
    );
  };

  renderTextSliderValue = () => {
    return (
      <div id="sliderTextValues">
        <Typography className="textColorGray fontLarger" variant="body2">
          No Pain
        </Typography>
        <Typography className="textColorGray fontLarger" variant="body2">
          Unbearable Pain
        </Typography>
      </div>
    );
  };

  onChangeFeedback = (e) => {
    let value = e.target.value;
    this.setState(
      {
        feedbackText: value,
      },
      () => this.props.onChangeFeedbackText("feedbackText", value)
    );
  };

  renderFeedbackTextField = () => {
    return (
      <TextField
        variant="outlined"
        fullWidth={true}
        multiline={true}
        rows={4}
        onChange={this.onChangeFeedback}
        value={this.state.feedbackText}
        placeholder="Write your feedback here"
        disabled={this.props.disabled}
        className="mgBottom16"
      />
    );
  };
  render() {
    return (
      <>
        <Typography
          className="marginBottom8 marginTop16 fontLarger fw-600"
          variant="body2"
        >
          This was pretty
        </Typography>
        {this.renderToggleButtons()}
        <Typography
          className="marginTop16 marginBottom8 fontLarger fw-600"
          variant="body2"
        >
          Pain I've felt doing this (0-10)
        </Typography>
        {this.renderTextSliderValue()}
        {this.renderPainSlider()}
        <Typography className="marginBottom8 fontLarger fw-600" variant="body2">
          I have something to say
        </Typography>
        {this.renderFeedbackTextField()}
      </>
    );
  }
}
