import React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Delete from '../../assets/delete.png';
import { EQUIPMENT_TYPES } from '../../constants';




export default class EquipmentEditCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.data.name,
      type: this.props.data.type || ''
    }
    
    this.onChangeName = this.onChange.bind(this, "name");
    this.onChangeEquipmentType = this.onChange.bind(this, "type");
  }

  componentWillReceiveProps(props) {
    this.setState({
      name: props.data.name || '',
      type: props.data.type || ''
    })
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update);
  }


  onClickSave = () => {
    if (this.checkErrors()) {
      return;
    }
    this.props.onClickSave(Object.assign({}, this.props.data, {
      name: this.state.name,
      type: this.state.type || ''
    }))
  }

  checkErrors() {
    if (!this.state.name) {
      let node = document.querySelector("#name")
      window.NotificationUtils.showErrorInForm(node, "name", "Name is required");
      return true;
    }

    return false;
  }

  onClickDelete = () => {
    window.customConfirm("Are you sure you want to remove " + this.props.data.name + " ?", async () => {
      try {
        var result = await window.FortisForma.database.deleteDocument( this.props.collection, this.props.data.id )
        if (result) {}
        this.props.onDelete(this.props.data.id);
      }
      catch(e) {
        console.error(e)
      }
    })
  }

  render() {
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label>Name</Label>
            <Input type={"text"} id="name" placeholder="Ex. Bodyweight" value={this.state.name} onChange={this.onChangeName} />
          </FormGroup>
        </Col>

        <Col lg="12">
          <FormGroup>
            <Label for="equipmentType">Equipment Type</Label>
            <Input type="select" id="equipmentType" value={this.state.type} onChange={this.onChangeEquipmentType}>
              <option value="">Select</option>
              <option value={EQUIPMENT_TYPES.FREEWEIGHT}>Free weight</option>
              <option value={EQUIPMENT_TYPES.RESISTANCE}>Resistance</option>
              <option value={EQUIPMENT_TYPES.SUPPORT}>Support</option>
            </Input>
          </FormGroup>
       </Col>

        <Col lg="12">
          <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
            { this.props.data.id ? <img alt="Delete" onClick={this.onClickDelete} src={Delete} className="deleteIcon" />: null}
            <Button className="btn btn-outline-secondary" onClick={this.props.onClickCancel}>Cancel</Button>{' '}
            <Button color="primary" onClick={this.onClickSave}>Save</Button>
          </div>
        </Col>
      </Row>
    )
  }
}
