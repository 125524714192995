import { createStore, thunk, action, computed } from "easy-peasy";

import { COLLECTIONS } from "../../constants";

export default createStore({
  programs: [],
  loading: false,
  programCount: computed((state) => state.programs.length),
  addProgram: action((state, payload) => {
    if (payload.index !== undefined) {
      state.programs.splice(payload.index, 0, payload.program);
    } else {
      state.programs.push(payload.program);
    }
  }),
  deleteProgram: action((state, deletedId) => {
    state.programs = state.programs.filter(
      (program) => program.id !== deletedId
    );
  }),
  replaceProgram: action((state, payload) => {
    state.programs.splice(payload.index, 1, payload.program);
  }),
  removeProgram: thunk(async (actions, payload) => {
    actions.deleteProgram(payload.program.id);
    try {
      await window.FortisForma.database.deleteProgram(payload.program.id);
    } catch (e) {
      console.error(e);
      actions.setError({
        error: "Something went wrong",
      });
      actions.addProgram(payload);
    }
  }),
  toggleProgramAvailability: thunk(async (actions, payload) => {
    try {
      let program = { ...payload.program };
      program.visible = !program.visible;
      actions.setInfo({
        info: "Changing visibility",
      });
      await window.FortisForma.database.saveProgram(program);
      actions.replaceProgram({
        program: program,
        index: payload.index,
      });
      actions.setSuccess({
        success: "Changed visibility",
      });
    } catch (e) {
      console.error(e);
      actions.setError({
        error: "Something went wrong",
      });
      actions.addProgram(payload);
    }
  }),
  setPrograms: action((state, payload) => {
    state.programs = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  loadPrograms: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      const data = await window.FortisForma.database.queryData({
        collection: COLLECTIONS.WORKOUT_PROGRAM,
        filters: payload.filters || [],
      });
      actions.setPrograms(data);
    } catch (e) {
      console.error(e);
      actions.setError(e);
    }
    actions.setLoading(false);
  }),
  error: null,
  info: null,
  success: null,
  _setError: action((state, payload) => {
    state.error = payload.error;
  }),
  setError: thunk((actions, payload) => {
    actions._setError(payload);
    setTimeout(() => {
      actions._setError({ error: "" });
    }, 2000);
  }),
  _setSuccess: action((state, payload) => {
    state.success = payload.success;
  }),
  setSuccess: thunk((actions, payload) => {
    actions._setSuccess(payload);
    setTimeout(() => {
      actions._setSuccess({ success: "" });
    }, 2000);
  }),
  _setInfo: action((state, payload) => {
    state.info = payload.info;
  }),
  setInfo: thunk((actions, payload) => {
    actions._setInfo(payload);
    setTimeout(() => {
      actions._setInfo({ info: "" });
    }, 2000);
  }),
  selectedProgram: null,
  setSelectedProgram: action((state, payload) => {
    state.selectedProgram = payload;
  }),
});
