import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import PauseIcon from "@material-ui/icons/Pause";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class TimerModal extends React.Component {
  render() {
    return (
      <Dialog
        className="timerModal"
        open={this.props.isOpen}
        fullScreen={false}
        onClose={this.props.onClose}
        disableBackdropClick={true}
        style={{ textAlign: "center" }}
        aria-labelledby="timerShow"
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={this.props.onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="">
            <CircularProgressbar
              value={this.props.progress}
              maxValue={100}
              strokeWidth={50}
              circleRatio={1}
              colors={"#771823"}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "white",
                trailColor: "#771823",
              })}
            />
          </div>
          <div className="timeCount">{this.props.countdown}s</div>
          <div className="btnGroup">
            <IconButton
              className="replayBtn"
              style={{
                background: "transparent",
                color: "white",
              }}
              onClick={this.props.restartTimer}
            >
              <ReplayIcon />
            </IconButton>
            {!this.props.running ? (
              <IconButton
                className="playBtn"
                style={{
                  background: "#fff",
                  color: "#000",
                }}
                onClick={this.props.startTimer}
              >
                <PlayArrowIcon />
              </IconButton>
            ) : (
              <IconButton
                className="playBtn"
                style={{
                  background: "#fff",
                  color: "#000",
                }}
                onClick={this.props.pauseTimer}
              >
                <PauseIcon />
              </IconButton>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default TimerModal;
