import { Chip, Tooltip, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import _ from "lodash";
import React from "react";

export default function NoteAssessmentLockedContent(props) {
  const showPreviousVersions = (noteId) => {
    if (props.loading || props.loadingCreator) {
      return;
    }
    return props.openSidePanel(
      noteId,
      props.collection,
      !_.isEmpty(props.creatorDetails) ? props.creatorDetails : {}
    );
  };

  const renderLockIcon = () => {
    return (
      <Tooltip title={props.tooltipTitle} placement={"top"}>
        <LockOutlinedIcon className="lockedContentIcon" />
      </Tooltip>
    );
  };

  const renderCreatorsInfo = () => {
    const { creatorDetails, loadingCreator } = props;

    if (loadingCreator) {
      return (
        <Skeleton
          variant="text"
          width={"140px"}
          style={{ marginLeft: "8px" }}
        />
      );
    }

    if (shouldShowCreatorInfo() && !_.isEmpty(creatorDetails)) {
      return (
        <Typography component="span" className="lockedContentText">
          &nbsp;by&nbsp;
          <span className="bold500">{creatorDetails.name}</span>
          {creatorDetails.designation && (
            <Chip
              label={creatorDetails.designation}
              size="small"
              style={{ marginLeft: "4px", height: "18px" }}
              variant="outlined"
              color="primary"
            />
          )}
        </Typography>
      );
    }
  };

  const shouldShowCreatorInfo = () => {
    if (_.has(props.data, "creatorId")) {
      return true;
    } else {
      return false;
    }
  };

  const renderLockedContent = () => {
    let updatedTime = (props.data && props.data.updatedTime) || "";

    // if (props.isLocked && !props.isEdited) {
    // if (props.isLocked ) {
    //   return renderLockIcon();
    // }
    // if (props.isLocked && props.isEdited && updatedTime && props.noteId) {
    if (props.isLocked && updatedTime && props.noteId) {
      updatedTime = window.getTimestampSeconds(updatedTime);
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {renderLockIcon()}
          <div
            className={`${
              shouldShowCreatorInfo()
                ? "creatorLockedContentContainer"
                : "lockedContentContainer"
            }`}
          >
            <Typography className="lockedContentText">
              Last edited on{" "}
              {window.moment.unix(updatedTime).format("MMM DD, YYYY")}{" "}
              {renderCreatorsInfo()}
            </Typography>
            <Typography
              style={{ marginLeft: !shouldShowCreatorInfo() && "6px" }}
              className={
                props.loading || props.loadingCreator
                  ? "disabledNoteVersionsButtonText"
                  : "noteVersionsButtonText"
              }
              onClick={() => showPreviousVersions(props.noteId)}
            >
              See previous versions
            </Typography>
          </div>
        </div>
      );
    }
  };

  return <>{renderLockedContent()}</>;
}
