import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import EducationCard from "./educationAndCertificationsComponents/educationCard";
import EducationCertificationEditCard from "./educationAndCertificationsComponents/educationCertificationEditCard";
import AdvancedFileUpload from "../../components/advancedFileUploader";
import PDFThumbnail from "../../components/PDFThumbnail/PDFThumbnail";

export const TYPES = {
  EDUCATION: "education",
  CERTIFICATION: "certification",
};

const FILES = {
  INSURANCE_URL: "_insuranceURL",
  CRIMINAL_RECORD_URL: "_criminalRecordURL",
};

const EDUCATION_EXAMPLES = [
  {
    name: "[Example] Bachelor of Science in Human Kinetics – University of Ottawa",
    link: "https://firebasestorage.googleapis.com/v0/b/fortisforma.appspot.com/o/extras%2Fsample.pdf?alt=media&token=2099537d-8f45-4218-b9bf-451543ac60b7",
    type: "education",
    shortCode: "B.Sc.",
    isExample: true,
  },
];

const CERTIFICATION_EXAMPLES = [
  {
    name: "[Example] Certified Strength and Conditioning Specialist with the National Strength and Conditioning Association",
    link: "https://firebasestorage.googleapis.com/v0/b/fortisforma.appspot.com/o/extras%2Fsample.pdf?alt=media&token=2099537d-8f45-4218-b9bf-451543ac60b7",
    type: "certification",
    shortCode: "CSCS",
    isExample: true,
  },
];

export default class EducationAndCertifications extends Component {
  state = {
    selectedEducation: null,
    educations: [],
    selectedCertification: null,
    certifications: [],
    insuranceURL: "",
    criminalRecordURL: "",
  };

  componentDidMount() {
    if (
      this.props.data &&
      ((this.props.data.educationAndCertification &&
        this.props.data.educationAndCertification.length > 0) ||
        this.props.data.others)
    ) {
      this.setMappedData(this.props.data);
    }
    if (
      !this.props.data ||
      !this.props.data.educationAndCertification ||
      !this.props.data.educationAndCertification.length
    ) {
      this.setState({
        educations: EDUCATION_EXAMPLES,
        certifications: CERTIFICATION_EXAMPLES,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data !== prevProps.data &&
      this.props.data &&
      this.props.data.length > 0
    ) {
      this.setMappedData(this.props.data);
    }
  }

  setMappedData = (data) => {
    let mappedData = this.getMappedEducationAndCertification(
      data.educationAndCertification
    );
    this.setState({
      educations: mappedData.educations,
      certifications: mappedData.certifications,
      insuranceURL: this.props.data.others
        ? this.props.data.others.insuranceURL
        : "",
      criminalRecordURL: this.props.data.others
        ? this.props.data.others.criminalRecordURL
        : "",
    });
  };

  getMappedEducationAndCertification(data = []) {
    let educations = [];
    let certifications = [];
    for (let datum of data) {
      if (datum.type === TYPES.EDUCATION) {
        educations.push(datum);
      } else {
        certifications.push(datum);
      }
    }
    return { educations, certifications };
  }

  onEducationEdit = (educationData, index) => {
    let educationToEdit = { ...educationData };
    if (educationToEdit.example) {
      delete educationToEdit.link;
    }
    delete educationToEdit.example;
    this.setState({
      editEducation: true,
      editCertification: false,
      newCertification: false,
      selectedEducation: educationToEdit,
      selectedEducationIndex: index,
      newEducation: false,
    });
  };

  onCertificationEdit = (certificationData, index) => {
    let certificationToEdit = { ...certificationData };
    if (certificationToEdit.example) {
      delete certificationToEdit.link;
    }
    delete certificationToEdit.example;
    this.setState({
      editCertification: true,
      editEducation: false,
      newEducation: false,
      selectedCertification: certificationToEdit,
      selectedCertificationIndex: index,
      newCertification: false,
    });
  };

  closeEducationEditCard = () => {
    this.setState({
      editEducation: false,
      newEducation: false,
      selectedEducation: null,
      selectedEducationIndex: null,
    });
  };

  closeCertificationEditCard = () => {
    this.setState({
      editCertification: false,
      newCertification: false,
      selectedCertification: null,
      selectedCertificationIndex: null,
    });
  };

  onNewEducation = () => {
    this.setState({
      newEducation: true,
      editEducation: true,
      selectedEducation: null,
      selectedEducationIndex: this.state.educations.length,
    });
  };

  onNewCertification = () => {
    this.setState({
      newCertification: true,
      editCertification: true,
      selectedCertification: null,
      selectedCertificationIndex: this.state.certifications.length,
    });
  };

  onRemoveItem = (itemIndex, itemType) => {
    let items = Object.assign([], this.state[itemType]);
    items.splice(itemIndex, 1);
    this.setState({
      [itemType]: items,
    });
  };

  saveDetails = (details) => {
    let currentDetails;
    let selectedDetailIndex;
    let detailType;
    delete details.isExample;
    if (this.state.editEducation || this.state.newEducation) {
      detailType = "education";
    } else {
      detailType = "certification";
    }
    details.type = detailType;
    const detailsRef = `${detailType}s`;
    currentDetails = Object.assign([], this.state[detailsRef]);
    const indexRef = `selected${detailType
      .charAt(0)
      .toUpperCase()}${detailType.slice(1)}Index`;
    selectedDetailIndex = this.state[indexRef];
    if (
      selectedDetailIndex ||
      selectedDetailIndex !== null ||
      selectedDetailIndex !== undefined ||
      selectedDetailIndex !== ""
    ) {
      currentDetails[selectedDetailIndex] = details;
    } else {
      currentDetails.push(details);
    }
    this.setState({
      [detailsRef]: currentDetails,
    });
    this.closeEducationEditCard();
    this.closeCertificationEditCard();
  };

  getFileName(fileType) {
    return (
      this.props.user && `trainerDocuments/${this.props.user.id}/${fileType}/`
    );
  }

  updateValue(key, val) {
    this.setState({
      [key]: val,
    });
  }

  checkErrorsInForm = () => {
    let hasExamples =
      (this.state.educations || []).filter((ed) => ed.example === true).length >
      0;
    if (!hasExamples) {
      hasExamples =
        (this.state.certifications || []).filter((ed) => ed.example === true)
          .length > 0;
    }

    if (hasExamples) {
      window.NotificationUtils.showError(
        "Please add yours education and certifications"
      );
      return true;
    }

    if (!this.state.educations.length) {
      window.NotificationUtils.showError("Please add at-least one education");
      return true;
    }
    if (!this.state.certifications.length) {
      window.NotificationUtils.showError(
        "Please add at-least one certification"
      );
      return true;
    }

    return false;
  };

  checkErrorsForInsurance = () => {
    if (!this.state.insuranceURL) {
      return true;
    }
  };

  getData = (checkErrors = true) => {
    if (checkErrors && this.checkErrorsInForm()) {
      return false;
    }
    let errors = false;
    if (this.checkErrorsForInsurance()) {
      errors = true;
    }
    let education = Object.assign([], this.state.educations);
    let certification = Object.assign([], this.state.certifications);
    let educationAndCertification = education.concat(certification);
    educationAndCertification = educationAndCertification.filter((item) => {
      return item.isExample !== true;
    });
    let data = {
      documents: {
        educationAndCertification: educationAndCertification,
        others: {
          criminalRecordURL: this.state.criminalRecordURL,
          insuranceURL: this.state.insuranceURL,
        },
      },
    };
    return { data, errors };
  };

  renderDocumentsUploaders() {
    return (
      <div className="documentsContainer">
        <div className="documentsInnerContainer">
          <div className="fileUploaderContainer">
            <div className="uploaderContainer">
              <AdvancedFileUpload
                onFailure={(e) =>
                  window.NotificationUtils.showError("Failed to upload")
                }
                uploadLocation={this.getFileName(FILES.INSURANCE_URL)}
                onComplete={(files) =>
                  this.updateValue("insuranceURL", files[0].url)
                }
                placeholder={`Insurance Certificate`}
                buttonProps={{
                  variant: "outlined",
                  color: "primary",
                  loadingMessage: "Uploading ...",
                }}
                filePickerProps={{
                  extensions: ["pdf", "png", "jpeg", "jpg"],
                }}
                onDelete={() => this.updateValue("insuranceURL", null)}
              />

              <div style={{ width: "100%", marginTop: 16 }}>
                {this.state.insuranceURL &&
                  (window.isPDF(this.state.insuranceURL) ? (
                    <PDFThumbnail
                      link={this.state.insuranceURL}
                      height={150}
                      className="fortisformaPDF"
                    />
                  ) : (
                    <div className="certificateImgContainer">
                      <img
                        alt="Insurance Proof"
                        src={this.state.insuranceURL}
                        className="certificateImg"
                      />
                    </div>
                  ))}
              </div>
              <span className="requiredText">Required*</span>
            </div>

            <div style={{ flex: 0.1 }} />
            <div className="uploaderContainer">
              <AdvancedFileUpload
                onFailure={(e) =>
                  window.NotificationUtils.showError("Failed to upload")
                }
                uploadLocation={this.getFileName(FILES.CRIMINAL_RECORD_URL)}
                onComplete={(files) =>
                  this.updateValue("criminalRecordURL", files[0].url)
                }
                placeholder={`Criminal Record Check`}
                buttonProps={{
                  variant: "outlined",
                  color: "primary",
                  loadingMessage: "Uploading ...",
                }}
                filePickerProps={{
                  extensions: ["pdf", "png", "jpeg", "jpg"],
                }}
                onDelete={() => this.updateValue("criminalRecordURL", null)}
              />

              <div style={{ width: "100%", marginTop: 30 }}>
                {this.state.criminalRecordURL &&
                  (window.isPDF(this.state.criminalRecordURL) ? (
                    <PDFThumbnail
                      link={this.state.criminalRecordURL}
                      height={150}
                      className="fortisformaPDF"
                    />
                  ) : (
                    <div className="certificateImgContainer">
                      <img
                        alt="Criminal Check Proof"
                        src={this.state.criminalRecordURL}
                        className="certificateImg"
                      />
                    </div>
                  ))}
              </div>
              <span className="requiredText">Optional</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="onBoardingSectionHeader">
          <div className="onBoardingHeading">Documents</div>
          <Typography variant="body2" color="initial">
            The PDF document uploads are for identification verification purpose
            and to determine proper insurance coverage only. Proof of insurance
            is required to be a visible and verified practitioner on the Fortis
            Forma platform. They won't be shared visible to the public or other
            users on the platform. We collect this information because we feel a
            responsibility to all parties involved since we are making the
            connection.
          </Typography>
        </div>
        {this.renderDocumentsUploaders()}
        <div className="educationAndCertificationsContainer">
          <div className="educationAndCertificationsInnerContainer">
            <div className="onBoardingSubHeaderContainer">
              <div className="onBoardingSubHeading">Education</div>
              <div
                className="addNewtext educationAndCertificationsAddNew"
                onClick={this.onNewEducation}
              >
                + Add new
              </div>
            </div>
            <div className="educationCardsContainer">
              {this.state.educations &&
                this.state.educations.map((edu, index) => {
                  return (
                    <EducationCard
                      key={index}
                      data={edu}
                      index={index}
                      onEducationEditClick={this.onEducationEdit}
                      removeEducation={this.onRemoveItem}
                    />
                  );
                })}
            </div>
          </div>
          <div className="educationAndCertificationsInnerContainer">
            <div className="onBoardingSubHeaderContainer">
              <div className="onBoardingSubHeading">Certifications</div>
              <div className="addNewtext" onClick={this.onNewCertification}>
                + Add new
              </div>
            </div>
            <div className="certificationCardsContainer">
              {this.state.certifications &&
                this.state.certifications.map((certification, index) => {
                  return (
                    <EducationCard
                      key={index}
                      data={certification}
                      index={index}
                      onEducationEditClick={this.onCertificationEdit}
                      removeEducation={this.onRemoveItem}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {this.state.editEducation && (
          <EducationCertificationEditCard
            user={this.props.user}
            data={this.state.selectedEducation}
            index={this.state.selectedEducationIndex}
            title={
              this.state.newEducation ? "Add New Education" : "Edit Education"
            }
            fieldLabel="Education"
            fieldName="education"
            buttonText={this.state.newEducation ? "Save" : "Update"}
            onClose={this.closeEducationEditCard}
            onSave={this.saveDetails}
          />
        )}
        {this.state.editCertification ? (
          <EducationCertificationEditCard
            user={this.props.user}
            index={this.state.selectedCertificationIndex}
            data={this.state.selectedCertification}
            onSave={this.saveDetails}
            title={
              this.state.newCertification
                ? "Add New Certification"
                : "Edit Certification"
            }
            fieldLabel="Certification"
            fieldName="certification"
            buttonText={this.state.newCertification ? "Save" : "Update"}
            onClose={this.closeCertificationEditCard}
          />
        ) : null}
      </React.Fragment>
    );
  }
}
