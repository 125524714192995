//todo:
/*
1. Add keys to the user object
    a. customerId: completed (stripe customer ID)
    b. subscription: 
    c. tier: free / paid
    d. subscription_id: product_id of the subscription from stripe
    e. free_trial: date and time
2. After successful payment, we need to add a key of date and time
*/

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";
import TAButton from "../../utils/TAButton";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 66px);
  width: 100%;
`;

const Title = styled.p`
  font-size: 36px;
  font-weight: bold;
  color: #707070;
`;

const Text = styled.p`
  /* color: ${(props) => (props.primary ? "white" : "palevioletred")}; */
  font-size: ${(props) => (props.size ? props.size : "14px")};
  color: ${(props) => (props.color ? props.color : "#707070")};
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  padding: ${(props) => (props.px ? `0 ${props.px}` : "0")};
  padding-bottom: ${(props) => (props.pb ? props.pb : "5px")};
  margin: 0;
`;

const SummaryContainer = styled.div`
  width: 450px;
  box-shadow: 0 0 35px rgb(0 0 0 / 0.2);
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: 350px;
`;

const Summary = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
`;

const Box = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 0.5px;
  margin: 20px 0;
  border: 1px solid #e9e9e9;
`;

const StripeSuccess = (props) => {
  const [sessionDetails, setSessionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleStripeSuccess = async () => {
      setLoading(true);
      try {
        let result = await window.FortisForma.database.stripeSuccess(
          props.history.location.search
        );
        if (result.session) {
          setSessionDetails({
            discount: result.session.total_details.amount_discount / 100,
            tax: result.session.total_details.amount_tax / 100,
            email: result.session.customer_details.email,
            name: result.session.customer_details.name,
            subtotal: result.session.amount_subtotal / 100,
            total: result.session.amount_total / 100,
          });
          setLoading(false);
        } else {
          setLoading(false);

          props.history.push("/");
        }
      } catch (e) {
        setLoading(false);
        console.log("error success stripe page", e);
        props.history.push("/");
      }
    };

    handleStripeSuccess().then((r) => console.log("success"));
  }, []);

  return (
    <Container>
      <Text size={"18px"} weight={600} pb={"35px"}>
        {sessionDetails.name}
      </Text>
      <Text size={"22px"} weight={600} pb={"15px"}>
        Thank You!
      </Text>
      <Text size={"16px"} weight={600} pb={"15px"}>
        <CheckCircleIcon
          style={{ fontSize: "40px", color: "#800520", marginRight: "10px" }}
        />
        Your payment was successful
      </Text>
      <Text color="#929292" pb={"25px"} px={"15px"}>
        Within the next few minutes, you should receive your subscription
        confirmation.
      </Text>
      <TAButton
        variant="contained"
        onClick={async () => {
          props.history.push("/");
        }}
        style={{
          backgroundColor: "#800520",
          color: "white",
          width: "250px",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
        color="primary"
      >
        Get Started
      </TAButton>
      <SummaryContainer>
        {loading && (
          <CircularProgress
            size={18}
            className="icon loading"
            color="primary"
          />
        )}
        {!loading && (
          <Summary>
            <Text weight={600} size={"18px"} pb={"30px"}>
              Summary of your Order:
            </Text>
            <Box>
              <Text weight={600}>Name:</Text>
              <Text>{sessionDetails.name}</Text>
            </Box>
            <Box>
              <Text weight={600}>Email:</Text>
              <Text>{sessionDetails.email}</Text>
            </Box>
            {/*<Box>*/}
            {/*  <Text weight={600}>Name:</Text>*/}
            {/*  <Text>{sessionDetails.name}</Text>*/}
            {/*</Box>*/}
            {/* <Box>
              <Text weight={600}>Subscription details:</Text>
              <Text>Private Practice Solution (Yearly)</Text>
            </Box> */}
            <Line />
            <Box>
              <Text weight={600}>Subtotal:</Text>
              <Text>${sessionDetails.subtotal.toFixed(2)} CAD</Text>
            </Box>
            <Box>
              <Text weight={600}>Discount:</Text>
              <Text>${sessionDetails.discount.toFixed(2)} CAD</Text>
            </Box>
            <Box>
              <Text weight={600}>Tax:</Text>
              <Text>${sessionDetails.tax.toFixed(2)} CAD</Text>
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <Text weight={600}>Total:</Text>
              <Text>${sessionDetails.total.toFixed(2)} CAD</Text>
            </Box>
          </Summary>
        )}
      </SummaryContainer>
    </Container>
  );
};

export default StripeSuccess;
