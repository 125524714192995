import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopy from "@material-ui/icons/FileCopy";
import LoopIcon from "@material-ui/icons/Loop";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DNDList from "../../components/dndList/dndList";
import ExerciseEditCard from "../../components/exerciseEditCard/exerciseDetailsEditCard.js";
import SlidingPane from "../../components/slidingPane/slidingPane";
import _ from "lodash";
import { LEVEL_UPDATE_TYPES, WORKOUT_SECTIONS_NAMES } from "../../constants";
import SimilarExercisesContainer from "./similarExercisesContainer";
import RepeatCircuitModal from "../../components/modals/repeatCircuitModal";
import EditFieldModal from "../../components/modals/fieldEditModal";
import EditExcerciseFieldModal from "../../components/modals/exerciseFieldEditModal";

const BOARD = "board";
const DROPPABLE = "droppable";
const sorter = (a, b) => Number(a) - Number(b);

const centerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  opacity: isDragging ? 0.4 : 1,
  ...draggableStyle,
});

export default class RightSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: {},
      selectedSectionIndex: null,
      selectedExerciseIndex: null,
      workoutName: null,
      exerciseName: null,
      count: 0,
      description: "",
      repeatCircuitModal: false,
      editNameModal: false,
      editExerciseNameModal: false,
      workoutSections: this.props.workoutSections,
      selectedExercisesMap: {},
      similarTarget: null,
      showSimilarExercises: false,
      expanded: [true],
      globalValues: {
        sets: 0,
        reps: 0,
        weight: 0,
        time: 0,
        resistance: "",
      },
    };
    this.hasShownNotification = false;
    this.state.exercises = this.arrayToMap(props.exercises || []);

    this.itemRenderer = this.itemRenderer.bind(this);
    this.onItemsMove = this.onItemsMove.bind(this);
    this.onCheckExercise = this.onCheckExercise.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);

    let fields = ["sets", "reps", "weight", "time"];

    for (let field of fields) {
      this["onChangeGlobal_" + field] = this.onChangeGlobalValue.bind(
        this,
        field
      );
    }

    this.applyToAll = this.applyToAll.bind(this);
    this.applyToSelected = this.applyToSelected.bind(this);

    this.state.levelsLoading = [[], [], [], []];

    this.levelData = [[], [], [], []];
    this.similarExercisesRef = null;
  }

  componentDidUpdate(nextProps) {
    const { workoutSections, selectedSection } = this.props;
    if (nextProps.workoutSections !== workoutSections) {
      this.setState({
        workoutSections,
      });
    }

    if (nextProps.selectedSection !== selectedSection) {
      this.setState({
        selectedSection,
      });
    }
  }

  updateLevelData(updateType, sectionIndex) {
    let levelData = Object.assign([], this.levelData);
    let levelsLoading = Object.assign([], this.state.levelsLoading);
    if (updateType === LEVEL_UPDATE_TYPES.ADD) {
      levelData.splice(sectionIndex, 0, []);
      levelsLoading.splice(sectionIndex, 0, []);
    } else {
      levelData.splice(sectionIndex, 1);
      levelsLoading.splice(sectionIndex, 1);
    }
    this.levelData = levelData;
    this.setState({
      levelsLoading,
    });
  }

  renderRepeatCircuitModal(index, section) {
    this.setState({
      count: 0,
      description: "",
    });
    if (section.repeatCircuit) {
      this.setState({
        count: section.repeatCircuit.count,
        description: section.repeatCircuit.description,
        repeatCircuitModal: true,
        selectedSectionIndex: index,
      });
    } else {
      this.setState({
        repeatCircuitModal: true,
        selectedSectionIndex: index,
      });
    }
  }

  editSectionNameModal(section, index) {
    this.setState(
      {
        editNameModal: true,
        workoutName: section.workoutType,
        selectedSectionIndex: index,
      },
      () => {
        this.props.recentStateChange();
      }
    );
  }

  editExerciseNameModal = (sectionIndex, exerciseIndex) => {
    const exerciseName =
      this.state.workoutSections[sectionIndex].exercises[exerciseIndex].name;
    this.setState(
      {
        editExerciseNameModal: true,
        selectedSectionIndex: sectionIndex,
        selectedExerciseIndex: exerciseIndex,
        exerciseName,
      },
      () => {
        this.props.recentStateChange();
      }
    );
  };

  closeRepeatCircuitModal = () => {
    this.setState({ repeatCircuitModal: false });
  };
  closeEditSectionModal = () => {
    this.setState({ editNameModal: false });
  };
  closeEditExerciseModal = () => {
    this.setState({ editExerciseNameModal: false });
  };
  onRemove = (exerciseIndex, sectionIndex) => {
    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionIndex]);
    selectedSection.exercises.splice(exerciseIndex, 1);
    workoutSections[sectionIndex] = selectedSection;
    this.props.updateSections(workoutSections);
    try {
      if (
        this.levelData &&
        this.levelData[sectionIndex] &&
        this.levelData[sectionIndex][exerciseIndex]
      ) {
        delete this.levelData[sectionIndex][exerciseIndex];
      }
    } finally {
    }
    //Currently missing: updating counts for selected exercises (this.props.onRemove)
  };

  onDuplicate = (exerciseIndex, sectionIndex) => {
    let workoutSections = Object.assign([], this.state.workoutSections);
    let sourceSection = Object.assign({}, workoutSections[sectionIndex]);
    let exercise = sourceSection.exercises[exerciseIndex];
    let copy = Object.assign({}, exercise);
    copy.createdTime = exercise.createdTime;
    if (copy && copy.updatedTime) {
      copy.updatedTime = exercise.updatedTime;
    }
    delete copy.key;
    delete copy.index;
    sourceSection.exercises[sourceSection.exercises.length] = copy;
    workoutSections[sectionIndex] = sourceSection;
    this.props.updateSections(workoutSections);

    try {
      this.levelData[sectionIndex][sourceSection.exercises.length - 1] =
        this.levelData[sectionIndex][exerciseIndex];
    } finally {
    }
  };

  onSaveNote = (exerciseIndex, sectionIndex, note) => {
    this._onSaveField(exerciseIndex, sectionIndex, "note", note);
  };

  onSaveVideoNote = (exerciseIndex, sectionIndex, videoNote) => {
    this._onSaveField(exerciseIndex, sectionIndex, "videoNote", videoNote);
  };

  onSaveDescription = (exerciseIndex, sectionIndex, description) => {
    this._onSaveField(exerciseIndex, sectionIndex, "description", description);
  };

  _onSaveField = (exerciseIndex, sectionIndex, key, value) => {
    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionIndex]);
    let exercises = Object.assign([], selectedSection.exercises);
    let exercise = exercises[exerciseIndex];
    exercise[key] = value;
    exercises[exerciseIndex] = exercise;
    selectedSection.exercises = exercises;
    workoutSections[sectionIndex] = selectedSection;
    this.props.updateSections(workoutSections);
  };

  _updateExercises(newData) {
    let oldExercises = this.mapToArray(this.state.exercises);

    let oldIdMap = {};
    oldExercises.map((e) => (oldIdMap[e.id] = (oldIdMap[e.id] || 0) + 1));

    let newExercises = newData.filter((e) => {
      let canAdd = false;
      if (!oldIdMap[e.id]) {
        canAdd = true;
      } else {
        oldIdMap[e.id] = oldIdMap[e.id] - 1;
      }
      return canAdd;
    });

    newExercises = newExercises.map((e) => Object.assign({}, e));

    let newIdMap = this.arrayToMap(newData, false);
    let keepExercises = oldExercises.filter((e) => {
      return newIdMap[e.id];
    });

    let index = keepExercises.length;
    newExercises = newExercises.map((e) => {
      e.index = index;
      index++;
      return e;
    });

    newExercises = newExercises || [];

    keepExercises = keepExercises.concat(newExercises);

    keepExercises = keepExercises.map((e) => {
      e = Object.assign(e, e.config || {});
      delete e.config;
      e.resistance = e.resistance || "";
      e.reps = e.reps || "";
      e.sets = e.sets || "";
      e.weight = e.weight || "";
      e.time = e.time || "";
      e.note = e.note || "";
      e.videoNote = e.videoNote || "";
      return e;
    });

    this.setState(
      {
        exercises: this.arrayToMap(keepExercises),
      },
      () => {
        this.props.onChange();
      }
    );
  }

  arrayToMap(exercises, useIndex) {
    let mapped = {};
    exercises.map((exercise, index) => {
      let key = exercise.id;
      if (useIndex !== false) {
        key = exercise.id + "_" + index;
        exercise.key = key;
      }
      mapped[key] = exercise;
      return null;
    });
    return mapped;
  }

  mapToArray(map) {
    let keys = Object.keys(map);
    let exercises = [];

    keys.sort((key1, key2) => {
      let index1 = key1.split("_")[1];
      let index2 = key2.split("_")[1];
      return index1 - index2;
    });

    for (let key of keys) {
      let exercise = map[key];
      exercises.push(exercise);
    }
    let sortedExercises = exercises.sort((a, b) => {
      return Number(a.index) - Number(b.index);
    });

    let originalIndex = 0;
    for (let exercise of sortedExercises) {
      let order = Number(exercise.index);
      if (order !== originalIndex) {
        exercise.index = originalIndex;
      }
      originalIndex++;
    }
    return exercises;
  }

  onItemsMove(source, destination) {
    try {
      this._changeLevelDataOnMove(source, destination);
    } catch (e) {
      console.error(e);
    }
  }

  itemRenderer({ value, itemIndex, sectionIndex }) {
    let loading = this.state.levelsLoading[sectionIndex]
      ? this.state.levelsLoading[sectionIndex][itemIndex]
      : false;

    let levelData = this.levelData[sectionIndex]
      ? this.levelData[sectionIndex][itemIndex]
      : {};

    let highestLevel = levelData && levelData.highestLevel;

    let canLevelUp =
      Number(value.level) < Number(highestLevel) || !highestLevel;
    let canLevelDown = value.level > 1;

    return (
      <>
        <ExerciseEditCard
          sectionIndex={sectionIndex}
          editExerciseNameModal={this.editExerciseNameModal}
          user={this.props.user}
          canLevelUp={canLevelUp}
          canLevelDown={canLevelDown}
          loading={loading}
          onLevelDown={this.onLevelDown.bind(
            this,
            itemIndex,
            value,
            sectionIndex
          )}
          onExerciseInfo={this.onExerciseInfo.bind(
            this,
            itemIndex,
            value,
            sectionIndex
          )}
          showModal={this.props.showModal}
          modalData={this.props.modalData}
          exerciseFromModalData={this.props.modalData}
          onLevelUp={this.onLevelUp.bind(this, itemIndex, value, sectionIndex)}
          index={itemIndex}
          itemIndex={itemIndex}
          key={value.key}
          exercise={value}
          onCheckExercise={this.onCheckExercise}
          onRemove={this.onRemove.bind(this, itemIndex, sectionIndex)}
          showJoyride={itemIndex === 0 ? true : false}
          onDuplicate={this.onDuplicate.bind(this, itemIndex, sectionIndex)}
          onLoadSimilar={this.loadSimilarExercises.bind(
            this,
            itemIndex,
            value,
            sectionIndex
          )}
          isSelected={this.state.selectedExercisesMap[value.id] || false}
          onSaveNote={this.onSaveNote}
          onSaveDescription={this.onSaveDescription}
          onSaveVideoNote={this.onSaveVideoNote}
          onChangeValue={this.onChangeValue}
        ></ExerciseEditCard>
      </>
    );
  }

  onCheckExercise(exercise, checked) {
    let newMap = Object.assign({}, this.state.selectedExercisesMap);
    newMap[exercise.id] = checked;

    this.setState({
      selectedExercisesMap: newMap,
    });
  }

  _onChangeValue(originalExercise, fieldName, value) {
    let exercise = Object.assign({}, originalExercise);
    exercise[fieldName] = value;
    let update = Object.assign({}, this.state.exercises);
    update[exercise.key] = exercise;
    this.setState(
      {
        exercises: update,
      },
      () => {
        this.props.onChange();
      }
    );
  }

  //Cleanup: More than 3 arguments
  onChangeValue(
    originalExercise,
    fieldName,
    value,
    { sectionIndex, exerciseIndex }
  ) {
    let exercise = Object.assign({}, originalExercise);
    exercise[fieldName] = value;
    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionIndex]);
    selectedSection.exercises[exerciseIndex] = exercise;
    workoutSections[sectionIndex] = selectedSection;
    this.props.updateSections(workoutSections, () => {
      this.props.onChange();
    });
  }

  onChangeGlobalValue(key, event) {
    let values = Object.assign({}, this.state.globalValues);
    values[key] = event.target.value;
    this.setState({
      globalValues: values,
    });
  }

  applyToAll() {
    this.applyGlobalToChildren(false);
  }

  applyToSelected() {
    this.applyGlobalToChildren(true);
  }

  applyGlobalToChildren(checkSelected) {
    let exercises = this.state.exercises.map((e) => {
      if (!checkSelected || this.state.selectedExercisesMap[e.id]) {
        e = Object.assign(e, this.state.globalValues);
      }
      return e;
    });

    this.setState({
      exercises: exercises,
    });
  }

  prepareForSave() {
    let exercises = this.mapToArray(this.state.exercises);
    exercises = exercises.map((e) => {
      e = Object.assign({}, e);
      e.sets = Number(e.sets);
      e.reps = Number(e.reps);
      e.weight = Number(e.weight);
      e.time = Number(e.time);
      delete e.createdTime;
      delete e.updatedTime;
      delete e.alternateNames;
      delete e.key;
      return e;
    });
    return exercises;
  }

  shouldCancelStart(e) {
    let target = e.target;
    if (!target) {
      return false;
    }

    if (target.type === "textarea") {
      return true;
    }

    if (target.type === "select-one") {
      return true;
    }

    if (target.type === "text") {
      return true;
    }

    if (target.tagName === "I") {
      return true;
    }

    if (
      target.className.includes("react-player") ||
      target.className.includes("editExerciseIconButton") ||
      target.className.includes("iconLabel")
    ) {
      return true;
    }

    if (target === "Copy" || target === "file_copy") {
      return true;
    }

    if (target === "edit" || target === "Note") {
      return true;
    }

    if (target === "refresh" || target === "Alternate") {
      return true;
    }
  }

  onLevelUp(position, data, sectionIndex) {
    this.changeLevel(position, data, 1, sectionIndex);
  }

  onLevelDown(position, data, sectionIndex) {
    this.changeLevel(position, data, -1, sectionIndex);
  }

  async onExerciseInfo(position, data, sectionIndex) {
    this.props.modalData([]);
    this.props.modalDataLoading(true);
    let exercisesData = await this.exerciseData(position, data, sectionIndex);
    this.props.modalData(exercisesData);
    this.props.modalDataLoading(false);
    this.props.exerciseFromModalData({ position, data, sectionIndex });
  }

  async setNewLevelfromModal(position, item, change, sectionIndex) {
    let selectedLevel = item.level;
    let positionLevelData =
      this.levelData[sectionIndex] && this.levelData[sectionIndex][position];
    let exerciseList = positionLevelData && positionLevelData.exerciseList;

    if (!exerciseList) {
      let levelsLoading = Object.assign({}, this.state.levelsLoading);
      if (!levelsLoading[sectionIndex]) {
        levelsLoading[sectionIndex] = [];
      }
      levelsLoading[sectionIndex][position] = true;

      this.setState({
        levelsLoading,
      });

      await this.loadMoreLevels(position, item, sectionIndex);

      levelsLoading = Object.assign({}, this.state.levelsLoading);
      if (!levelsLoading[sectionIndex]) {
        levelsLoading[sectionIndex] = [];
      }
      levelsLoading[sectionIndex][position] = false;
      this.setState({
        levelsLoading,
      });
    }
    if (
      !this.levelData[sectionIndex] ||
      !this.levelData[sectionIndex][position]
    ) {
      return;
    }

    exerciseList = this.levelData[sectionIndex][position].exerciseList;
    if (!exerciseList || Object.values(exerciseList).length < 2) {
      return;
    }

    let levels = Object.keys(exerciseList).sort(sorter);
    let indexOfLevel = levels.findIndex((item) => +item === +selectedLevel);
    // let newLevel = levels[indexOfLevel + change];
    // this._setLevel(item, position, newLevel, sectionIndex);
    this._setLevel(item, position, change, sectionIndex);
  }

  async exerciseData(position, exercise, sectionIndex, callback) {
    try {
      let results = await window.FortisForma.database.loadMoreLevelsOfExercise(
        exercise
      );

      let exercisess = [];
      for (let doc of results) {
        if (doc.id !== exercise.id) {
          let data = doc;
          data.sets = data.config.sets;
          data.reps = data.config.reps;
          data.weight = data.config.weight;
          data.resistance = data.config.resistance;
          data.time = data.config.time;
          data.id = doc.id;
          if (
            !data.videoURL ||
            !data.posterURL ||
            data.hidden ||
            !_.isEqual(data.equipmentCategories, exercise.equipmentCategories)
          ) {
            // console.log("inner if=================");
            // console.log("data.videoURL", data.videoURL);
            // console.log("!data.videoURL", !data.videoURL);
            // console.log("data.posterURL", data.posterURL);
            // console.log("!data.posterURL", !data.posterURL);
            // console.log("!data.hidden", data.hidden);
            // console.log(
            //   "!datalastttttttt",
            //   !_.isEqual(data.equipmentCategories, exercise.equipmentCategories)
            // );
            // console.log("data.equipmentCategories", data.equipmentCategories);
            // console.log(
            //   "exercise.equipmentCategories",
            //   exercise.equipmentCategories
            // );
            // console.log("===============inner if");
          } else {
            // console.log("else ran");
            exercisess.push(data);
          }
        } else {
          // console.log("outer else ran");
        }
      }

      // console.log("RESULTS=================", results);
      // console.log("Exercisess=================", exercisess);

      let positionLevelData =
        this.levelData[sectionIndex] && this.levelData[sectionIndex][position];
      let exerciseList = positionLevelData && positionLevelData.exerciseList;

      if (!exerciseList) {
        let levelsLoading = Object.assign({}, this.state.levelsLoading);
        if (!levelsLoading[sectionIndex]) {
          levelsLoading[sectionIndex] = [];
        }
        levelsLoading[sectionIndex][position] = true;

        this.setState({
          levelsLoading,
        });

        await this.loadMoreLevels(position, exercise, sectionIndex);

        levelsLoading = Object.assign({}, this.state.levelsLoading);
        if (!levelsLoading[sectionIndex]) {
          levelsLoading[sectionIndex] = [];
        }
        levelsLoading[sectionIndex][position] = false;
        this.setState({
          levelsLoading,
        });
      }
      if (
        !this.levelData[sectionIndex] ||
        !this.levelData[sectionIndex][position]
      ) {
        return;
      }

      exerciseList = this.levelData[sectionIndex][position].exerciseList;
      if (!exerciseList || Object.values(exerciseList).length < 2) {
        return;
      }

      // console.log("exerciseData exerciseList========", exerciseList);

      let exercises = [];
      for (let doc of results) {
        if (doc.id !== exercise.id) {
          let data = doc;
          data.sets = data.config.sets;
          data.reps = data.config.reps;
          data.weight = data.config.weight;
          data.resistance = data.config.resistance;
          data.time = data.config.time;
          data.id = doc.id;
          if (
            !data.videoURL ||
            !data.posterURL ||
            data.hidden ||
            !_.isEqual(data.equipmentCategories, exercise.equipmentCategories)
          ) {
          } else {
            exercises.push(data);
          }
        }
      }

      let update = {};

      for (let exercise of exercises) {
        if (!update[Number(exercise.level)]) {
          update[Number(exercise.level)] = exercise;
        }
      }

      if (callback) {
        callback(update);
      }

      if (!exercises.length) {
        window.NotificationUtils.showSuccess(
          "You have viewed all levels",
          undefined,
          "topRight"
        );
      }

      update[Number(exercise.level)] = exercise;

      let levels = Object.keys(update).sort(sorter);
      if (!this.levelData[sectionIndex]) {
        this.levelData[sectionIndex] = {};
      }
      this.levelData[sectionIndex][position] = {
        highestLevel: levels[levels.length - 1],
        exerciseList: update,
      };

      return exercises;
    } catch (e) {
      console.error(e);
    }
  }

  //Cleanup: More than 3 arguments to function
  async changeLevel(position, item, change, sectionIndex) {
    let selectedLevel = item.level;
    let positionLevelData =
      this.levelData[sectionIndex] && this.levelData[sectionIndex][position];
    let exerciseList = positionLevelData && positionLevelData.exerciseList;

    if (!exerciseList) {
      let levelsLoading = Object.assign({}, this.state.levelsLoading);
      if (!levelsLoading[sectionIndex]) {
        levelsLoading[sectionIndex] = [];
      }
      levelsLoading[sectionIndex][position] = true;

      this.setState({
        levelsLoading,
      });

      await this.loadMoreLevels(position, item, sectionIndex);

      levelsLoading = Object.assign({}, this.state.levelsLoading);
      if (!levelsLoading[sectionIndex]) {
        levelsLoading[sectionIndex] = [];
      }
      levelsLoading[sectionIndex][position] = false;
      this.setState({
        levelsLoading,
      });
    }
    if (
      !this.levelData[sectionIndex] ||
      !this.levelData[sectionIndex][position]
    ) {
      return;
    }

    exerciseList = this.levelData[sectionIndex][position].exerciseList;
    if (!exerciseList || Object.values(exerciseList).length < 2) {
      return;
    }

    let levels = Object.keys(exerciseList).sort(sorter);

    let indexOfLevel = levels.findIndex((item) => +item === +selectedLevel);
    let newLevel = levels[indexOfLevel + change];
    if (newLevel >= 0) {
      this._setLevel(item, position, newLevel, sectionIndex);
    } else {
      if (change < 0) {
        window.NotificationUtils.showError(
          "You have reached the lowest level",
          undefined,
          "topRight"
        );
      } else {
        window.NotificationUtils.showError(
          "You have reached the highest level",
          undefined,
          "topRight"
        );
      }
    }
  }

  //Cleanup: More than 3 arguments to function
  _setLevel(oldExercise, position, level, sectionIndex) {
    let index = position;
    let newExercise = Object.assign(
      {},
      oldExercise,
      this.levelData[sectionIndex][position].exerciseList[level]
    );
    newExercise.key = newExercise.id + "_" + index;

    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionIndex]);
    let exercises = Object.assign([], selectedSection.exercises);
    exercises[index] = newExercise;
    selectedSection.exercises = exercises;
    workoutSections[sectionIndex] = selectedSection;
    this.props.updateSections(workoutSections);
  }

  _replaceExercises = (oldExercise, newExercise) => {
    let exercises = Object.assign({}, this.state.workoutSections);
    try {
      exercises[newExercise.key] = newExercise;
      delete exercises[oldExercise.key];
    } catch (e) {
      console.error(e);
    }

    this.setState(
      {
        exercises,
      },
      () => {
        this.props.onReplace(this.mapToArray(exercises));
        this.props.onChange();
      }
    );
  };

  replaceExercises = (sectionPosition, exercisePosition, newExercise) => {
    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionPosition]);
    let exercises = Object.assign([], selectedSection.exercises);
    exercises[exercisePosition] = newExercise;
    selectedSection.exercises = exercises;
    workoutSections[sectionPosition] = selectedSection;
    this.props.updateSections(workoutSections);
  };

  _changeLevelDataOnMove(source, destination) {
    let newLevelsLoading = Object.assign([], this.state.levelsLoading);
    let newLevelsData = Object.assign([], this.levelData);

    if (!newLevelsLoading[source.sourceSection]) {
      newLevelsLoading[source.sourceSection] = {};
    }
    if (!newLevelsLoading[destination.destinationSection]) {
      newLevelsLoading[destination.destinationSection] = {};
    }
    if (!newLevelsData[source.sourceSection]) {
      newLevelsData[source.sourceSection] = {};
    }
    if (!newLevelsData[destination.destinationSection]) {
      newLevelsData[destination.destinationSection] = {};
    }

    let oldLevelLoading =
      newLevelsLoading[source.sourceSection][source.sourceIndex];
    let oldLevelData = newLevelsData[source.sourceSection][source.sourceIndex];

    newLevelsLoading[source.sourceSection][source.sourceIndex] =
      newLevelsLoading[destination.destinationSection][
        destination.destinationIndex
      ];
    newLevelsData[source.sourceSection][source.sourceIndex] =
      newLevelsData[destination.destinationSection][
        destination.destinationIndex
      ];
    newLevelsLoading[destination.destinationSection][
      destination.destinationIndex
    ] = oldLevelLoading;
    newLevelsData[destination.destinationSection][
      destination.destinationIndex
    ] = oldLevelData;

    this.setState({
      levelsLoading: newLevelsLoading,
    });
    this.levelData = newLevelsData;
  }

  async loadMoreLevels(position, exercise, sectionIndex, callback) {
    try {
      let results = await window.FortisForma.database.loadMoreLevelsOfExercise(
        exercise
      );

      let exercises = [];
      for (let doc of results) {
        if (doc.id !== exercise.id) {
          let data = doc;
          data.sets = data.config.sets;
          data.reps = data.config.reps;
          data.weight = data.config.weight;
          data.resistance = data.config.resistance;
          data.time = data.config.time;
          data.id = doc.id;
          if (
            !data.videoURL ||
            !data.posterURL ||
            data.hidden ||
            !_.isEqual(data.equipmentCategories, exercise.equipmentCategories)
          ) {
          } else {
            exercises.push(data);
          }
        }
      }

      let update = {};

      for (let exercise of exercises) {
        if (!update[Number(exercise.level)]) {
          update[Number(exercise.level)] = exercise;
        }
      }

      if (callback) {
        callback(update);
      }

      if (!exercises.length) {
        window.NotificationUtils.showSuccess(
          "You have viewed all levels",
          undefined,
          "topRight"
        );
      }

      update[Number(exercise.level)] = exercise;

      let levels = Object.keys(update).sort(sorter);
      if (!this.levelData[sectionIndex]) {
        this.levelData[sectionIndex] = {};
      }
      this.levelData[sectionIndex][position] = {
        highestLevel: levels[levels.length - 1],
        exerciseList: update,
      };
    } catch (e) {
      console.error(e);
    }
  }

  loadSimilarExercises = (index, exercise, sectionIndex) => {
    this.onPaneOpen();
    this.setState({
      similarTarget: {
        position: index,
        sectionPosition: sectionIndex,
        exercise,
      },
    });
  };

  _onSimilarExerciseSelect = (newExercise) => {
    let oldExercise =
      this.state.similarTarget && this.state.similarTarget.exercise;
    if (!oldExercise) {
      console.error("We should always have similar target here");
      return;
    }

    let position = this.state.similarTarget.position;
    delete this.levelData[position];
    let loadingCopy = Object.assign({}, this.state.levelsLoading);
    delete loadingCopy[position];

    this.setState({
      levelsLoading: loadingCopy,
    });

    newExercise = Object.assign({}, oldExercise, newExercise);
    newExercise.key = newExercise.id + "_" + position;
    this._replaceExercises(this.state.similarTarget.exercise, newExercise);
    this.onPaneClose();
  };

  onSimilarExerciseSelect = (newExercise) => {
    let oldExercise =
      this.state.similarTarget && this.state.similarTarget.exercise;
    if (!oldExercise) {
      console.error("We should always have similar target here");
      return;
    }

    let position = this.state.similarTarget.position;
    let sectionPosition = this.state.similarTarget.sectionPosition;
    delete this.levelData[sectionPosition][position];
    let loadingCopy = Object.assign({}, this.state.levelsLoading);
    delete loadingCopy[sectionPosition][position];

    this.setState({
      levelsLoading: loadingCopy,
    });

    newExercise = Object.assign({}, oldExercise, newExercise);
    newExercise.key = newExercise.id + "_" + position;
    this.replaceExercises(sectionPosition, position, newExercise);
    this.onPaneClose();
  };

  onPaneOpen = () => {
    this.setState({
      showSimilarExercises: true,
    });
  };

  onPaneClose = () => {
    this.setState({
      showSimilarExercises: false,
      similarTarget: null,
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let destinationIndex = result.destination.index;
    let sourceIndex = result.source.index;
    let workoutSections = Object.assign([], this.state.workoutSections);
    //If dragging sections
    if (result.source.droppableId === BOARD) {
      if (result.destination.index === result.source.index) {
        return;
      }
      if (
        workoutSections[0].workoutType === WORKOUT_SECTIONS_NAMES.WARMUP &&
        (destinationIndex === 0 || sourceIndex === 0)
      ) {
        window.NotificationUtils.showError(
          `Can't change sequence for ${workoutSections[0].workoutType} Section`
        );
        return;
      }
      if (
        workoutSections[workoutSections.length - 1].workoutType ===
          WORKOUT_SECTIONS_NAMES.COOLDOWN &&
        (destinationIndex === workoutSections.length - 1 ||
          sourceIndex === workoutSections.length - 1)
      ) {
        window.NotificationUtils.showError(
          `Can't change sequence for ${
            workoutSections[workoutSections.length - 1].workoutType
          } Section`
        );
        return;
      }
      let source = workoutSections[sourceIndex];
      workoutSections.splice(sourceIndex, 1);
      workoutSections.splice(destinationIndex, 0, source);
      this.onSectionMove(sourceIndex, destinationIndex);
    }
    //If dragging exercises
    else {
      let sourceSection = +result.source.droppableId.replace(DROPPABLE, "");
      let destinationSection = +result.destination.droppableId.replace(
        DROPPABLE,
        ""
      );
      if (
        sourceSection === destinationSection &&
        sourceIndex === destinationIndex
      ) {
        return;
      }
      let exerciseToMove =
        workoutSections[sourceSection].exercises[sourceIndex];

      workoutSections[sourceSection].exercises.splice(sourceIndex, 1);
      workoutSections[destinationSection].exercises.splice(
        destinationIndex,
        0,
        exerciseToMove
      );

      this.onItemsMove(
        { sourceSection, sourceIndex },
        { destinationSection, destinationIndex }
      );
    }

    this.props.updateSections(workoutSections);
  };

  onSectionMove = (sourceSection, destinationSection) => {
    let newLevelsLoading = Object.assign([], this.state.levelsLoading);
    let newLevelsData = Object.assign([], this.levelData);
    let oldLevelLoading = newLevelsLoading[sourceSection];
    let oldLevelData = newLevelsData[sourceSection];
    newLevelsLoading[sourceSection] = newLevelsLoading[destinationSection];
    newLevelsData[sourceSection] = newLevelsData[destinationSection];
    newLevelsLoading[destinationSection] = oldLevelLoading;
    newLevelsData[destinationSection] = oldLevelData;

    this.setState({
      levelsLoading: newLevelsLoading,
    });
    this.levelData = newLevelsData;
  };

  expandSection = (index) => {
    let expanded = Object.assign([], this.state.expanded);
    expanded[index] = true;
    this.setState({
      expanded,
    });
  };

  collapseSection = (index, e) => {
    e.stopPropagation();
    let expanded = Object.assign([], this.state.expanded);
    expanded[index] = false;
    this.setState({
      expanded,
    });
  };

  emptyPlaceholder = () => {
    return <div style={centerStyles}>No Similar Exercise Found</div>;
  };

  renderSimilarExercises = () => {
    return (
      <SimilarExercisesContainer
        user={this.props.user}
        target={this.state.similarTarget}
        onSimilarExerciseSelect={this.onSimilarExerciseSelect}
      ></SimilarExercisesContainer>
    );
  };

  updateSetCount(e, change, sectionIndex) {
    e.stopPropagation();
    let workoutSections = Object.assign([], this.state.workoutSections);
    let selectedSection = Object.assign({}, workoutSections[sectionIndex]);
    let sectionSets = selectedSection.sets;
    if (selectedSection.sets === 1 && change < 0) {
      return;
    }
    sectionSets = sectionSets + change;
    selectedSection.sets = sectionSets;
    workoutSections[sectionIndex] = selectedSection;
    this.props.updateSections(workoutSections);
  }

  renderDraggableSections = ({ section, listIndex }, provided) => {
    return (
      <div
        className={
          this.props.selectedSection === listIndex
            ? "workoutSection selectedWorkoutSection"
            : "workoutSection unselectedWorkoutSection"
        }
        onClick={() => this.props.onSelectSection(listIndex)}
      >
        <div {...provided.dragHandleProps} className="workoutSectionHeading">
          <div>
            {`${section.workoutType}${
              section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT
                ? ` ${
                    this.state.workoutSections[0].workoutType ===
                    WORKOUT_SECTIONS_NAMES.CIRCUIT
                      ? listIndex + 1
                      : listIndex
                  }`
                : ""
            }`}
            <IconButton
              size="small"
              onClick={() => this.editSectionNameModal(section, listIndex)}
            >
              <i className="material-icons smallIcon">edit</i>
            </IconButton>
            {this.state.expanded[listIndex] ? (
              <IconButton
                size="small"
                style={{ marginTop: -2, marginLeft: 4 }}
                onClick={(e) => this.collapseSection(listIndex, e)}
              >
                <ExpandLessIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                style={{ marginTop: -2, marginLeft: 4 }}
                onClick={() => this.expandSection(listIndex)}
              >
                <ExpandMoreIcon color="primary" />
              </IconButton>
            )}
            {section.exercises.length > 0 && (
              <IconButton
                onClick={() =>
                  this.renderRepeatCircuitModal(listIndex, section)
                }
                size="small"
                className="repeatBtn"
              >
                Repeat Circuit
                <LoopIcon color="primary" />
                {section.repeatCircuit ? (
                  <strong>{section.repeatCircuit.count}</strong>
                ) : null}
              </IconButton>
            )}
          </div>
          <div className="setCounterAndDeleteContainer">
            {section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT && (
              <div
                className="setCounterAndDeleteContainer"
                style={{ display: "none" }}
              >
                <span style={{ color: "#000" }}>Sets</span>
                <span style={{ marginLeft: 12 }}>x</span>
                <div style={{ marginRight: 8, marginLeft: 6 }}>
                  <IconButton
                    size="small"
                    onClick={(e) => this.updateSetCount(e, -1, listIndex)}
                  >
                    <KeyboardArrowLeftIcon fontSize="small" color="primary" />
                  </IconButton>
                  <span>{section.sets}</span>
                  <IconButton
                    size="small"
                    onClick={(e) => this.updateSetCount(e, +1, listIndex)}
                  >
                    <KeyboardArrowRightIcon fontSize="small" color="primary" />
                  </IconButton>
                </div>
              </div>
            )}
            <Tooltip title="Copy Section" arrow={true} placement="left">
              <IconButton
                size="small"
                style={{ marginTop: -2, marginRight: 4 }}
                onClick={(e) => this.props.copySection(listIndex, e)}
              >
                <FileCopy fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Section" arrow={true} placement="left">
              <IconButton
                size="small"
                style={{ marginTop: -2, marginRight: 4 }}
                onClick={(e) => this.props.deleteSection(listIndex, e)}
              >
                <DeleteIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Collapse
          in={this.state.expanded[listIndex]}
          timeout="auto"
          unmountOnExit
        >
          <DNDList
            listIndex={listIndex}
            workoutType={section.workoutType}
            shouldCancelStart={this.shouldCancelStart}
            useDragHandle={false}
            items={section.exercises}
            itemRenderer={this.itemRenderer}
          />
        </Collapse>
      </div>
    );
  };
  sectionNameChanger = (value) => {
    let data = this.state.workoutSections;
    for (let i = 0; i < data.length; i++) {
      if (this.state.selectedSectionIndex === i) {
        data[i].workoutType = "";
        data[i].workoutType = value;
      }
    }
    this.setState(
      {
        workoutSections: data,
      },
      () => {
        this.props.onChange();
      }
    );
    this.setState({ editNameModal: false });
  };
  exerciseNameChanger = (value) => {
    let data = this.state.workoutSections;
    data[this.state.selectedSectionIndex].exercises[
      this.state.selectedExerciseIndex
    ].name = value;

    this.setState(
      {
        workoutSections: data,
      },
      () => {
        this.props.onChange();
      }
    );
    this.setState({ editExerciseNameModal: false });
  };
  circuitRepeatCountHandler = (count, description) => {
    let data = this.state.workoutSections;
    data[this.state.selectedSectionIndex].repeatCircuit = {
      count: Number(count),
      description: description,
    };
    this.setState(
      {
        workoutSections: data,
      },
      () => {
        this.props.onChange();
      }
    );
    this.setState({ repeatCircuitModal: false });
  };
  renderDraggableParent = (exercises) => {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="board">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.workoutSections.length > 0 &&
                this.state.workoutSections.map((section, index) => {
                  return (
                    <Draggable
                      key={`section-${index}`}
                      draggableId={`section-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {this.renderDraggableSections(
                              { section, listIndex: index },
                              provided,
                              snapshot
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  editModalValue(workoutName) {
    this.setState({ workoutName });
  }

  editExerciseModalValue(exerciseName) {
    this.setState({ exerciseName });
  }

  editRepeatCircuitModalValue(value, type) {
    if (type === "count") {
      this.setState({ count: value });
    } else {
      this.setState({ description: value });
    }
  }

  render() {
    let exercises = this.mapToArray(this.state.exercises);
    exercises.sort((a, b) => a.index - b.index);
    return (
      <div className="workoutFinalView">
        {this.renderDraggableParent(exercises)}
        <div className="addNewSection" onClick={this.props.addNewSection}>
          + Add New Section
        </div>

        <SlidingPane
          open={this.state.showSimilarExercises}
          renderPaneData={this.renderSimilarExercises}
          onClose={this.onPaneClose}
          KlassName="similarExPane"
        />
        <RepeatCircuitModal
          circuitCountHandler={this.circuitRepeatCountHandler}
          isOpen={this.state.repeatCircuitModal}
          count={this.state.count}
          description={this.state.description}
          selectedSection={this.editRepeatCircuitModalValue.bind(this)}
          onCancel={this.closeRepeatCircuitModal}
        />

        <EditFieldModal
          fieldEditHandler={this.sectionNameChanger}
          selectedSection={this.editModalValue.bind(this)}
          workoutType={this.state.workoutName}
          isOpen={this.state.editNameModal}
          onCancel={this.closeEditSectionModal}
        />
        <EditExcerciseFieldModal
          fieldEditHandler={this.exerciseNameChanger}
          selectedExercise={this.editExerciseModalValue.bind(this)}
          exerciseName={this.state.exerciseName}
          isOpen={this.state.editExerciseNameModal}
          onCancel={this.closeEditExerciseModal}
        />
      </div>
    );
  }
}
