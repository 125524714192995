import React from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import WorkoutDayDetails from "./workoutdaydetails";
import MergedClientList, {
  ALL_DRAFTS,
  ALL_WORKOUTS,
  DRAFTS_OPTION,
  TEMPLATE_OPTION,
} from "../../clientList/mergedClientList";
import { assignWorkoutToUser } from "../../workoutAssigner/workoutAssigner";

import {
  COLLECTIONS,
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  WORKOUT_SHARE_URL,
  WORKOUT_TYPE,
} from "../../../constants";

import { BubbleLoader } from "react-css-loaders";
import Assigner from "../../../components/assigner/assigner";
import SearchInput from "../../../components/filters/searchInput";
import SectionedWorkout from "../../../components/sectionedWorkout/sectionedWorkout";
import copy from "copy-to-clipboard";
import ShareWorkoutModal from "./shareWorkoutModal";
import WorkoutsList from "./workoutList";
import _ from "lodash";
import Fuse from "fuse.js";

export default class WorkoutDays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWorkoutDay: {},
      trainerWorkouts: [],
      trainerDraftWorkouts: [],
      data: [],
      draftData: [],
      loading: true,
      assignerModal: false,
      workoutToAssign: null,
      selectedOption: TEMPLATE_OPTION.options[0],
      selectedOptionChange: false,
      workoutDataToShare: null,
      type: false,
    };
    this.options = {
      includeScore: true,
      keys: ["name"],
      shouldSort: true,
      threshold: 0.6,
      filter: (result, search) => {
        const nameWords = result.item.name.split(" ");
        const firstWord = nameWords[0];
        return firstWord.toLowerCase() === search.toLowerCase();
      },
    };
    this.onSelect = this.onSelect.bind(this);
    this.addNewWorkout = this.addNewWorkout.bind(this);
    this.deleteWorkoutDay = this.deleteWorkoutDay.bind(this);
    this.editWorkoutDay = this.editWorkoutDay.bind(this);
    this.saveWorkoutDayToLocal = this.saveWorkoutDayToLocal.bind(this);
    this.fetchWorkouts = this.fetchWorkouts.bind(this);
    this.fetchDraftWorkouts = this.fetchDraftWorkouts.bind(this);
    this.assignerRef = null;
    this.pageSize = 20;
    this.listRef = null;
  }

  onSelectClient = (selectedClient) => {
    this.setState({
      selectedClient,
    });
  };

  // componentDidMount() {
  //     if (this.props.location.search.replace("?", "") === "draft") {
  //         this.setState({
  //             type: true
  //         });
  //         this.fetchDraftWorkouts();
  //     } else {
  //         this.setState({
  //             type: false
  //         }, () => {
  //             this.fetchWorkouts();
  //         });
  //
  //
  //     }
  //     this.restoreState();
  // }

  componentWillMount() {
    if (this.props.location.search.replace("?", "") === "draft") {
      this.setState(
        {
          type: true,
        },
        () => {
          this.fetchDraftWorkouts();
        }
      );
    } else {
      this.setState(
        {
          type: false,
        },
        () => {
          this.fetchWorkouts();
        }
      );
    }
    this.restoreState();
  }

  restoreState() {
    let selectedUser;
    let selectedOption = {};
    try {
      selectedUser = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.SELECTED_USER
      );
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_USER);
      if (selectedUser && selectedUser.email) {
        selectedUser = JSON.parse(selectedUser);
        selectedOption.value = selectedUser;
        selectedOption.label = selectedUser.name;
        this.handleWorkoutSelect(selectedOption);
      }
    } catch (e) {
      console.error(e);
      console.error("Unable to access local storage");
    }
  }

  deleteWorkoutDay(workoutDay, event) {
    let message = `Are you sure you want to delete ${workoutDay.name}`;
    window.customConfirm(message, async () => {
      if (this.state.type) {
        let index = window._.findIndex(
          this.state.trainerDraftWorkouts,
          (item) => {
            return item.id === workoutDay.id;
          }
        );

        if (index >= 0) {
          let newWorkouts = Object.assign([], this.state.trainerDraftWorkouts);
          newWorkouts.splice(index, 1);

          let update = {
            query: "",
            draftData: newWorkouts,
            trainerDraftWorkouts: newWorkouts,
          };

          if (this.state.selectedWorkoutDay.id === workoutDay.id) {
            update["selectedWorkoutDay"] = {};
          }

          this.setState(update);
        }
      } else {
        let index = window._.findIndex(this.state.trainerWorkouts, (item) => {
          return item.id === workoutDay.id;
        });

        if (index >= 0) {
          let newWorkouts = Object.assign([], this.state.trainerWorkouts);
          newWorkouts.splice(index, 1);

          let update = {
            query: "",
            data: newWorkouts,
            trainerWorkouts: newWorkouts,
          };

          if (this.state.selectedWorkoutDay.id === workoutDay.id) {
            update["selectedWorkoutDay"] = {};
          }

          this.setState(update);
        }
      }

      if (event && event.stopPropagation) {
        event.stopPropagation();
      }

      let results = await window.FortisForma.database.deleteWorkout(
        workoutDay.id,
        this.state.type
      );
      if (results) {
      }
      this.props.onUserChange();
    });
  }

  editWorkoutDay(workoutDay, event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.state.type) {
      this.saveWorkoutDayToLocal(workoutDay, () => {
        this.routeTo("/workout-designer/edit-draft");
      });
    } else {
      this.saveWorkoutDayToLocal(workoutDay, () => {
        this.routeTo("/workout-designer/edit");
      });
    }
  }

  copyWorkoutDay = (workoutDay, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    delete workoutDay.id;
    delete workoutDay.clientId;
    if (this.state.type) {
      this.saveWorkoutDayToLocal(workoutDay, () => {
        this.routeTo("/workout-designer/copy-draft");
      });
    } else {
      this.saveWorkoutDayToLocal(workoutDay, () => {
        this.routeTo("/workout-designer/copy");
      });
    }
  };

  saveWorkoutDayToLocal(workoutDay, callback) {
    try {
      this.storeSelectedOptionInState(workoutDay);
      workoutDay = JSON.stringify(workoutDay);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE,
        workoutDay
      );
      callback();
    } catch (e) {
      console.error(e);
    }
  }

  storeSelectedOptionInState(workoutDay) {
    let user;
    if (
      (this.state.selectedOption.value !== ALL_WORKOUTS.options[0].value &&
        this.state.selectedOption.value !== TEMPLATE_OPTION.options[0].value) ||
      (this.state.selectedOption.value !== ALL_DRAFTS.options[0].value &&
        this.state.selectedOption.value !== DRAFTS_OPTION.options[0].value)
    ) {
      let selectedUserData = this.state.selectedOption.value;
      user = selectedUserData;
    } else if (workoutDay && workoutDay.clientId) {
      user = {
        id: workoutDay.clientId,
      };
    }
    if (user) {
      user = JSON.stringify(user);
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_USER, user);
    }
  }

  addNewWorkout() {
    this.storeSelectedOptionInState();
    this.routeTo("/workout-designer");
  }

  routeTo(route) {
    this.props.history.replace(route);
  }

  fetchWorkouts = async (query) => {
    if (!this.props || !this.props.user) {
      return;
    }
    let currentUser = this.props.user;

    if (!currentUser) {
      throw new Error("User not logged in");
    }

    if (!query) {
      query = {};
    }

    let pageConfig = {
      limit: 20,
    };

    pageConfig.orders = [];
    query.collection = COLLECTIONS.WORKOUT_DAYS;
    if (currentUser.enterpriseId) {
      query.filters = [
        {
          key: DB_KEYS.ENTERPRISE_ID,
          operator: "==",
          value: currentUser.enterpriseId,
        },
      ];
    } else {
      query.filters = [
        {
          key: DB_KEYS.TRAINER_ID_KEY,
          operator: "==",
          value: currentUser.id,
        },
      ];
    }
    if (this.state.lastFetched) {
      pageConfig.orders.push({
        key: "id",
        after: this.state.lastFetched.id,
      });
    } else {
      pageConfig.orders.push({
        key: "id",
      });
    }
    if (this.state.selectedOption.value === ALL_WORKOUTS.options[0].value) {
      // Show all
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "save",
      });
    } else if (
      this.state.selectedOption.value === TEMPLATE_OPTION.options[0].value
    ) {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.TRAINER,
      });
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "save",
      });
    } else {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.CLIENT_WORKOUT,
      });
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "save",
      });
      query.filters.push({
        key: "clientId",
        operator: "==",
        value: this.state.selectedOption.value.id,
      });
    }
    this.setState({
      loading: true,
    });
    if (this.state.selectedOptionChange) {
      this.setState({
        trainerWorkouts: [],
        data: [],
      });
    }

    try {
      query.filters = query.filters.concat([]);
      pageConfig.orders = pageConfig.orders.concat([]);
      query.pageConfig = pageConfig;
      let result1 = await window.FortisForma.database.getWorkouts(query);
      let result = [];
      if (currentUser.enterpriseId) {
        const idxObj = query.filters.findIndex((object) => {
          return object.key === "enterpriseId";
        });
        query.filters.splice(idxObj, 1);
        query.filters.push({
          key: "trainerId",
          operator: "==",
          value: currentUser.id,
        });
        result = await window.FortisForma.database.getWorkouts(query);
      } else {
        if (this.props.ppEnterprise) {
          const idxObj = query.filters.findIndex((object) => {
            return object.key === "trainerId";
          });
          query.filters.splice(idxObj, 1);
          query.filters.push({
            key: "enterpriseId",
            operator: "==",
            value: this.props.ppEnterprise.id,
          });
          result = await window.FortisForma.database.getWorkouts(query);
        }
      }

      let results = [...result1, ...result];
      requestAnimationFrame(() => {
        this.setState(
          {
            lastFetched: results[results.length - 1],
            trainerWorkouts:
              this.state.trainerWorkouts.length <= 0 ||
              this.state.selectedOptionChange
                ? results
                : this.state.trainerWorkouts.concat(results),
            data:
              this.state.data.length <= 0 || this.state.selectedOptionChange
                ? results
                : this.state.data.concat(results),
            selectedOptionChange: false,
          },
          () => {
            if (this.state.query) {
              this.setState({
                data: this.filterWorkoutOnSearching(
                  this.state.trainerWorkouts,
                  this.state.query
                ),
              });
            }
          }
        );
      });

      this.setState({
        loading: false,
      });

      return results;
    } catch (e) {
      this.setState({
        loading: false,
      });

      console.error(e);
      throw e;
    }
  };
  fetchDraftWorkouts = async (query) => {
    if (!this.props || !this.props.user) {
      return;
    }
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    if (!query) {
      query = {};
    }

    let pageConfig = {
      limit: 20,
    };

    pageConfig.orders = [];
    query.collection = COLLECTIONS.WORKOUT_DAYS;
    if (currentUser.enterpriseId) {
      query.filters = [
        {
          key: DB_KEYS.ENTERPRISE_ID,
          operator: "==",
          value: currentUser.enterpriseId,
        },
      ];
    } else {
      query.filters = [
        {
          key: DB_KEYS.TRAINER_ID_KEY,
          operator: "==",
          value: currentUser.id,
        },
      ];
    }
    if (this.state.lastFetched) {
      pageConfig.orders.push({
        key: "id",
        after: this.state.lastFetched.id,
      });
    } else {
      pageConfig.orders.push({
        key: "id",
      });
    }
    if (this.state.selectedOption.value === ALL_DRAFTS.options[0].value) {
      // Show all
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "draft",
      });
    } else if (
      this.state.selectedOption.value === DRAFTS_OPTION.options[0].value
    ) {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.TRAINER,
      });
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "draft",
      });
    } else {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.CLIENT_WORKOUT,
      });
      query.filters.push({
        key: "clientId",
        operator: "==",
        value: this.state.selectedOption.value.id,
      });
      query.filters.push({
        key: "templateName",
        operator: "==",
        value: "draft",
      });
    }

    this.setState({
      loading: true,
    });
    if (this.state.selectedOptionChange) {
      this.setState({
        trainerDraftWorkouts: [],
        draftData: [],
      });
    }

    try {
      query.filters = query.filters.concat([]);
      pageConfig.orders = pageConfig.orders.concat([]);
      query.pageConfig = pageConfig;

      let result1 = await window.FortisForma.database.getWorkouts(query);
      let result = [];

      if (currentUser.enterpriseId) {
        const idxObj = query.filters.findIndex((object) => {
          return object.key === "enterpriseId";
        });
        // if (idxObj) {
        query.filters.splice(idxObj, 1);
        query.filters.push({
          key: "trainerId",
          operator: "==",
          value: currentUser.id,
        });
        result = await window.FortisForma.database.getWorkouts(query);
        // }
      } else {
        if (this.props.ppEnterprise) {
          const idxObj = query.filters.findIndex((object) => {
            return object.key === "trainerId";
          });

          // if (idxObj) {
          query.filters.splice(idxObj, 1);
          query.filters.push({
            key: "enterpriseId",
            operator: "==",
            value: this.props.ppEnterprise.id,
          });

          result = await window.FortisForma.database.getWorkouts(query);
          // }
        }
      }

      let results = [...result1, ...result];
      requestAnimationFrame(() => {
        this.setState(
          {
            lastFetched: results[results.length - 1],
            trainerDraftWorkouts:
              this.state.trainerDraftWorkouts.length <= 0 ||
              this.state.selectedOptionChange
                ? results
                : this.state.trainerDraftWorkouts.concat(results),
            draftData:
              this.state.draftData.length <= 0 ||
              this.state.selectedOptionChange
                ? results
                : this.state.draftData.concat(results),
            selectedOptionChange: false,
          },
          () => {
            if (this.state.query) {
              this.setState({
                draftData: this.filterWorkoutOnSearching(
                  this.state.trainerDraftWorkouts,
                  this.state.query
                ),
              });
            }
          }
        );
      });

      this.setState({
        loading: false,
      });

      return results;
    } catch (e) {
      this.setState({
        loading: false,
      });

      console.error(e);
      throw e;
    }
  };

  onSelect(selectedWorkoutDay) {
    this.setState({
      selectedWorkoutDay,
    });
    this.navigate();
  }

  assignWorkoutDay = (data) => {
    let userData = Object.assign({}, data);
    userData.buttonType = this.state.type;

    if (
      (this.state.selectedOption.value !== ALL_WORKOUTS.options[0].value &&
        this.state.selectedOption.value !== TEMPLATE_OPTION.options[0].value) ||
      (this.state.selectedOption.value !== ALL_DRAFTS.options[0].value &&
        this.state.selectedOption.value !== DRAFTS_OPTION.options[0].value)
    ) {
      assignWorkoutToUser(
        this.state.selectedOption.value,
        this.props.history,
        userData,
        this.props.user.enterpriseId,
        this.props.user
      );
    } else {
      this.setState({ workoutToAssign: userData, assignerModal: true });
    }
  };

  closeAssigner = () => {
    this.setState({ assignerModal: false });
  };

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
  };

  onAssign = async () => {
    if (this.state.workoutToAssign) {
      try {
        await this.assignerRef.save(this.state.workoutToAssign);
        this.setState({ assignerModal: false });
      } catch (e) {
        console.error(e);
      }
    }
  };

  renderAssignerModal() {
    return (
      <Modal
        isOpen={this.state.assignerModal}
        className="assignerModal"
        id="assignerModal"
        backdrop={true}
      >
        <ModalHeader>Assign Workout</ModalHeader>
        {this.renderModalContent()}
        <ModalFooter>
          <Button className="secondaryButton" onClick={this.closeAssigner}>
            Cancel
          </Button>
          <Button
            disabled={Boolean(!this.state.selectedClient)}
            className="primaryButton"
            color="primary"
            onClick={this.onAssign}
          >
            Assign
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderModalContent() {
    return (
      <ModalBody>
        <Assigner
          currentUser={this.props.user}
          history={this.props.history}
          ref={(c) => (this.assignerRef = c)}
          onSelectClient={this.onSelectClient}
          {...this.props}
        />
      </ModalBody>
    );
  }

  renderLoading = () => {
    return (
      <div className="listMessage">
        <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
      </div>
    );
  };

  workoutDays() {
    return (
      <div
        className="fullWidth"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Row
          className="clientCardHeading centerAlignJustify"
          style={{ minHeight: 50 }}
        >
          <Col
            lg={{ size: "5", offset: 1 }}
            md={{ size: "5", offset: 1 }}
            sm={{ size: "5", offset: 1 }}
            xs={{ size: "8" }}
            style={{ justifyContent: "start", paddingLeft: 8 }}
            className="centerAlignJustify incFontWeight"
          >
            <strong>Workout Name</strong>
          </Col>
          <Col
            lg="2"
            md="2"
            sm="2"
            xs="4"
            className="centerAlignJustify incFontWeight"
          >
            <strong>Exercises</strong>
          </Col>
          <Col lg="4" md="4" sm="4" xs="4" className="centerAlignJustify" />
        </Row>
        <div id="clientEditorList" style={{ flex: 1 }}>
          {this.state.type ? (
            <WorkoutsList
              ref={(r) => {
                this.listRef = r;
              }}
              type={this.state.type}
              data={_.uniqBy(this.state.draftData, "id")}
              autoSize={true}
              padding={[0, 0]}
              containerId={"clientEditorList"}
              rowHeight={90}
              columnCount={1}
              selectedWorkoutDay={this.state.selectedWorkoutDay}
              onSelect={this.onSelect}
              loadData={this.fetchDraftWorkouts}
              pageSize={this.pageSize}
              emptyPlaceholder={
                this.state.loading
                  ? this.renderLoading()
                  : this.workoutDaysPlaceholder()
              }
              assignWorkoutDay={this.assignWorkoutDay}
              deleteWorkoutDay={this.deleteWorkoutDay}
              editWorkoutDay={this.editWorkoutDay}
              copyWorkoutDay={this.copyWorkoutDay}
              onCancel={this.props.onCancel}
              onClickShareIcon={this.onClickShareIcon}
            />
          ) : (
            <WorkoutsList
              type={this.state.type}
              ref={(r) => {
                this.listRef = r;
              }}
              data={_.uniqBy(this.state.data, "id")}
              autoSize={true}
              padding={[0, 0]}
              containerId={"clientEditorList"}
              rowHeight={90}
              columnCount={1}
              selectedWorkoutDay={this.state.selectedWorkoutDay}
              onSelect={this.onSelect}
              loadData={this.fetchWorkouts}
              pageSize={this.pageSize}
              emptyPlaceholder={
                this.state.loading
                  ? this.renderLoading()
                  : this.workoutDaysPlaceholder()
              }
              assignWorkoutDay={this.assignWorkoutDay}
              deleteWorkoutDay={this.deleteWorkoutDay}
              editWorkoutDay={this.editWorkoutDay}
              copyWorkoutDay={this.copyWorkoutDay}
              onCancel={this.props.onCancel}
              onClickShareIcon={this.onClickShareIcon}
            />
          )}
        </div>
      </div>
    );
  }

  workoutDaysPlaceholder() {
    return (
      <div
        className="fullWidth daysList"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Button
          color="link"
          className="btn btn-link"
          onClick={this.addNewWorkout}
        >
          Create New Workout
        </Button>
      </div>
    );
  }

  handleWorkoutSelect = (selectedOption) => {
    if (this.state.type) {
      this.setState(
        { selectedOption, selectedOptionChange: true, lastFetched: {} },
        () => {
          this.fetchDraftWorkouts();
        }
      );
    } else {
      this.setState(
        { selectedOption, selectedOptionChange: true, lastFetched: {} },
        () => {
          this.fetchWorkouts();
          this.fetchWorkouts();
        }
      );
    }
  };
  filterWorkoutOnSearching = (data, text) => {
    let fuse = new Fuse(data, this.options);
    const results = fuse.search(text);
    const exactMatchIndex = results.findIndex(
      (result) => result.item.name.toLowerCase() === text.toLowerCase()
    );
    if (exactMatchIndex !== -1) {
      const exactMatch = results.splice(exactMatchIndex, 1)[0];
      results.unshift(exactMatch);
    }
    return results.map((result) => result.item);
  };
  handleQueryTextChange = (event) => {
    let text = event.target.value;
    if (text) {
      text = text.toLowerCase();
      if (this.state.type) {
        let draftData = this.filterWorkoutOnSearching(
          this.state.trainerDraftWorkouts,
          text
        );
        this.setState({
          query: text,
          draftData,
        });
      } else {
        let data = this.filterWorkoutOnSearching(
          this.state.trainerWorkouts,
          text
        );

        this.setState({
          query: text,
          data,
        });
      }
    } else {
      if (this.state.type) {
        this.setState({
          query: text,
          draftData: this.state.trainerDraftWorkouts,
        });
      } else {
        this.setState({
          query: text,
          data: this.state.trainerWorkouts,
        });
      }
    }
  };
  clearQueryText = () => {
    if (this.state.type) {
      this.setState({
        query: "",
        draftData: this.state.trainerDraftWorkouts,
      });
    } else {
      this.setState({
        query: "",
        data: this.state.trainerWorkouts,
      });
    }
  };

  onClickShareIcon = (data, shareOption) => {
    this.setState({
      openShareModal: shareOption,
      workoutId: data.id,
      workoutDataToShare: data,
    });
  };

  onCloseShareModalIcon = () => {
    this.setState({
      openShareModal: false,
    });
  };

  getShareableLInk = () => {
    return `${WORKOUT_SHARE_URL}${this.state.workoutId}`;
  };

  onClickCopyLink = (e) => {
    e && e.stopPropagation();
    let link = this.getShareableLInk();
    copy(link);
    window.NotificationUtils.showSuccess("Link copied");
  };

  render() {
    return (
      <Container
        fluid
        className="workoutDaysEditor centerAlignJustify mobileAlignItemsNone"
        style={{ flexDirection: "column" }}
      >
        <Row className="workoutDaysHeader marginAuto">
          <Col className="topClientList" style={{ paddingLeft: 0 }}>
            <div id="workoutTypeSelector">
              <MergedClientList
                isClearable={false}
                handleWorkoutSelect={this.handleWorkoutSelect}
                selectedWorkout={this.state.selectedOption}
                currentUser={this.props.user}
                type={this.state.type}
                {...this.props}
              />
              <SearchInput
                inputId="searchWorkouts"
                placeholder={`Search ${
                  this.state.type ? "Drafts" : "Workouts"
                }`}
                value={this.state.query}
                onChange={this.handleQueryTextChange}
                onClickClear={this.clearQueryText}
              />
              <div id="draftFilterButton">
                <Button
                  id="workoutDesignerDraftSave"
                  className={this.state.type && "active"}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    this.clearQueryText();
                    this.setState({
                      type: true,
                      query: "",
                    });
                    this.fetchDraftWorkouts();
                    setTimeout(() => {
                      this.fetchDraftWorkouts();
                    }, 1000);
                  }}
                >
                  Drafts
                </Button>
              </div>
              <div id="draftFilterButton">
                <Button
                  id="workoutDesignerDraftSave"
                  className={!this.state.type && "active"}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    this.setState(
                      {
                        type: false,
                        query: "",
                      },
                      () => {
                        this.fetchWorkouts();
                      }
                    );
                    setTimeout(() => {
                      this.fetchWorkouts();
                    }, 1000);
                  }}
                >
                  My Templates
                </Button>
              </div>
            </div>
          </Col>
          <Col
            className="button-small-margin"
            style={{ textAlign: "right", padding: 0, maxWidth: 200 }}
          >
            <Button
              id="workoutDayAddNewButton"
              color="primary"
              onClick={this.addNewWorkout}
            >
              Add New Workout
            </Button>
          </Col>
        </Row>
        <Row
          style={{ height: "calc(100vh - 150px)", marginTop: 16 }}
          className="fullWidth workoutDaysList fullWHListContainer mobileHeightInitial"
        >
          <Col
            lg="5"
            md="12"
            sm="12"
            xs="12"
            className="listContainer mobilOverFlowHidden"
          >
            {this.workoutDays()}
          </Col>
          <div style={{ flex: 1 }} className="clientDetailContainer">
            {Object.keys(this.state.selectedWorkoutDay).length > 0 &&
              (this.state.selectedWorkoutDay.exercises ? (
                <WorkoutDayDetails
                  exercises={this.state.selectedWorkoutDay.exercises}
                />
              ) : (
                <div style={{ width: "90%" }} className="mobileFullWidth">
                  <SectionedWorkout
                    workoutSections={
                      this.state.selectedWorkoutDay.workoutSections
                    }
                  />
                </div>
              ))}
          </div>
        </Row>
        <div
          hidden={this.state.selected === null}
          className="screenNavigationHelperButton"
          onClick={this.navigate}
        >
          {this.navigationIcon()}
        </div>
        {this.renderAssignerModal()}
        <ShareWorkoutModal
          openShareModal={this.state.openShareModal}
          link={this.getShareableLInk()}
          currentUser={this.props.user}
          workout={this.state.workoutDataToShare}
          onCloseShareModalIcon={this.onCloseShareModalIcon}
          onClickCopyLink={this.onClickCopyLink}
          {...this.props}
        />
      </Container>
    );
  }
}
