import React from "react";
import NoteAssessmentList from "./noteAssessmentList";
import NoteItem from "../noteAssessmentItem/noteItem";

export default class NotesList extends NoteAssessmentList {
  renderEmptyPlaceholder = () => {
    return this.props.renderEmptyPlaceholder();
  };

  renderItem = (item) => {
    return (
      <NoteItem
        data={item}
        onEdit={this.props.onEdit}
        onExport={this.props.onExport}
        onClickPreview={this.props.onClickPreview}
      />
    );
  };
}
