import { DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import { FORM_TYPES, FORM_TYPES_LABEL, ROLES } from "../../constants";
import FormDetailsCard from "./formDetailCard";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { getTotalQuestions } from "../../views/enterpriseClientDetails/forms";

export const DROPDOWN_FORM_TYPES = [
  {
    label: FORM_TYPES_LABEL.REGISTRATION,
    value: 0,
  },
  {
    label: FORM_TYPES_LABEL.ASSESSMENTS,
    value: 1,
  },
  {
    label: FORM_TYPES_LABEL.GENERAL_INFO,
    value: 2,
  },
  {
    label: FORM_TYPES_LABEL.MISC,
    value: 3,
  },
  {
    label: FORM_TYPES_LABEL.NOTES,
    value: 4,
  },
];

export default class UseTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedTab: this.getSelectedTabValue(),
      sequenceNumber: null,
    };
    this.filteredFormData = [];
  }

  componentDidMount() {
    this.fetchForms();
    this.setSequenceNumber();
  }

  getSelectedTabValue = () => {
    if (this.props.tabOpenBefore) {
      return this.props.tabOpenBefore;
    }
    if (this.props.selectedTab) {
      var result = DROPDOWN_FORM_TYPES.find(
        (formType) => formType.value === this.props.selectedTab
      );
      if (result) {
        return result;
      }
    }
    return DROPDOWN_FORM_TYPES[0];
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.closeUseTemplatesModal()}
        disabled={this.state.loading}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  renderLoading = () => {
    return <OpaqueLoading />;
  };

  renderEmptyPlaceHolder = () => {
    return (
      <div className="useTemplatesEmptyPlaceholderContainer">
        <Typography>No templates available for this form type</Typography>
      </div>
    );
  };

  setSequenceNumber = () => {
    var value = undefined;
    var filtered = this.props.formList.filter(
      (form) => form.step === this.state.selectedTab.value
    );
    if (filtered.length > 0) {
      value =
        Math.max.apply(
          Math,
          filtered.map(function (o) {
            return o.sequence;
          })
        ) + 1;
    }
    if (!value) {
      value = 1;
    }
    if (this.state.sequenceNumber !== value) {
      this.setState({
        sequenceNumber: value,
      });
    }
  };
  onClickCopy = async (form) => {
    this.setState({
      loading: true,
    });
    try {
      let formData = {
        formMetaData: form.formMetaData,
        schema: form.schema,
        uiSchema: form.uiSchema,
        step: form.step,
        sequence: this.state.sequenceNumber,
        // enterpriseId: this.props.user.enterpriseId || "",
        // enterpriseForm: true,
        active: true,
      };

      if (this.props.isClientForm) {
        formData.clientId = this.props.clientId;
        formData.clientEmail = this.props.clientEmail;
      }

      if (this.props.user.role === ROLES.TRAINER && this.props.user.active) {
        formData.userId = this.props.user.id;
        formData.enterpriseForm = false;
        // delete formData.enterpriseId;
      } else {
        formData.enterpriseId = this.props.user.enterpriseId || "";
        formData.enterpriseForm = true;
      }

      const totalQuestions = getTotalQuestions(
        form.schema && form.schema.properties
      );

      formData.filled = Boolean(!totalQuestions);
      //NO changes required
      await window.FortisForma.database.saveForms(formData);

      window.NotificationUtils.showSuccess("Template added successfully");
      this.setState(
        {
          loading: false,
        },
        async () => {
          if (this.props.formType) {
            await this.props.fetchForms(this.props.formType);
          } else {
            await this.props.fetchForms();
          }
          this.setSequenceNumber();
        }
      );
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error trying to use template");
      this.setState({
        loading: false,
      });
    }
  };

  renderCards = () => {
    switch (this.state.selectedTab.value) {
      case FORM_TYPES.REGISTRATION:
        this.filteredFormData =
          this.state.forms &&
          this.state.forms.filter(
            (form) => form.step === FORM_TYPES.REGISTRATION
          );
        break;
      case FORM_TYPES.ASSESSMENTS:
        this.filteredFormData =
          this.state.forms &&
          this.state.forms.filter(
            (form) => form.step === FORM_TYPES.ASSESSMENTS
          );

        break;

      case FORM_TYPES.GENERAL_INFO:
        this.filteredFormData =
          this.state.forms &&
          this.state.forms.filter(
            (form) => form.step === FORM_TYPES.GENERAL_INFO
          );

        break;
      case FORM_TYPES.MISC:
        this.filteredFormData =
          this.state.forms &&
          this.state.forms.filter((form) => form.step === FORM_TYPES.MISC);

        break;
      case FORM_TYPES.NOTES:
        this.filteredFormData =
          this.state.forms &&
          this.state.forms.filter((form) => form.step === FORM_TYPES.NOTES);

        break;

      default:
        throw new Error("Invalid Selection");
    }
    if (this.filteredFormData) {
      if (!this.filteredFormData.length) {
        return this.renderEmptyPlaceHolder();
      }
      this.filteredFormData.sort((form1, form2) => {
        return form2.sequence - form1.sequence;
      });
      return this.filteredFormData.map((form, key) => {
        return (
          <FormDetailsCard
            data={form.schema}
            key={key}
            onClickPreview={() =>
              this.props.onClickPreview(form, this.state.selectedTab)
            }
            onClickCopy={() => this.onClickCopy(form)}
            isGlobalFormCard={true}
            loading={this.state.loading}
          />
        );
      });
    }
  };

  renderDialogContentData = () => {
    return (
      <div className="useTemplatesContent">
        <div className="innerFormCardsContainer">{this.renderCards()}</div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.loading && this.renderLoading()}
        <DialogTitle
          disableTypography={true}
          className="documentsModalHeaderContainer"
        >
          <Typography
            className="modalFormTitle boldText"
            variant="h5"
            display="inline"
          >
            Use Templates
          </Typography>
          {this.renderFormTypeDropdown()}
        </DialogTitle>
        <DialogContent className="useTemplatesContentContainer">
          {this.renderCloseButton()}
          {this.renderDialogContentData()}
        </DialogContent>
      </>
    );
  }
}
