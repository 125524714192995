import React from "react";
import Joyride from "react-joyride";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ClientFilters from "../../components/filters/clientFilters";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import {
  BULK_UPLOAD_ERRORS,
  COLLECTIONS,
  DATE_FORMAT,
  DB_KEYS,
  ROLES,
  WALKTHROUGH,
} from "../../constants";
import Factory from "../../modules/http/dataFetcher";
import { CLIENT_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import ClientDetails from "../clientDetails/clientDetails";
import PendingClientDetails from "../clientDetails/pendingClientDetails";
import ClientList from "../clientList/clientList";
import XLSX from "xlsx";
import { isEmail } from "validator";
import Dialog from "@material-ui/core/Dialog";
import ErrorModal from "./errorModal";
import BulkUploadModal from "./bulkUploadModal";
import moment from "moment";
import bodybuilder from "bodybuilder";

const LOADING_MESSAGES = [
  "Creating client",
  "Updating client profile",
  "Sending welcome note to client",
];
const REMOVING_MESSAGES = ["Removing client"];
const APPROVING_MESSAGES = ["Approving client"];
const REJECTING_MESSAGES = ["Rejecting client"];

const LOADING_CHANGE_DURATION = 5000;
const TAB_OPTIONS = {
  REGISTER: 0,
  PENDING: 1,
  REQUEST: 2,
};

const BULK_UPLOAD_CLIENT = {
  NAME: "Name*",
  EMAIL: "Email",
  DOB: "DOB",
  PHONE: "Phone",
  ADDRESS: "Address",
  CITY: "City",
  POSTAL: "Postal",
  PROVINCE: "Province",
  MEMBERSHIP_NAME: "Program/Membership Name",
};
export default class ClientEditor extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);

    this.usersFetcher = Factory.usersFetcher();
    this.invitesFetcher = Factory.invitesFetcher();
    this.enterpriseClientsFetcher = Factory.enterpriseClientsFetcher();
    this.state = {
      emptyClient: {
        email: "",
        name: "",
        phone: "",
        address: "",
        city: "",
        postal: "",
        province: "",
        membershipName: "",
        id: "",
      },
      walkthrough: false,
      addNew: false,
      filters: null,
      filtersApplied: false,
      lastFetched: null,
      selectedClient: null,
      didNavigateDown: false,
      clients: [],
      showPending: false,
      selectedTab: TAB_OPTIONS.REGISTER,
      steps: CLIENT_WALKTHROUGH.steps,
      searchQuery: "",
      errorsModal: false,
      openBulkUploadModal: false,
      clientPrograms: {},
      uploading: false,
    };

    this.listRef = null;
    this.pageSize = 20;
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.CLIENT_EDITOR;
    this.fetchClients = this.fetchClients.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.addNewClient = this.addNewClient.bind(this);
    this.onSave = this.onSave.bind(this);
    this.createNewClient = this.createNewClient.bind(this);

    this.selectRegistered = this.selectTab.bind(this, TAB_OPTIONS.REGISTER);
    this.selectPending = this.selectTab.bind(this, TAB_OPTIONS.PENDING);
    this.selectRequest = this.selectTab.bind(this, TAB_OPTIONS.REQUEST);

    this.onSelect = this.onSelect.bind(this);
    this.pendingDetailsRef = React.createRef();
    this.filtersRef = null;
    this.isEnterpriseMember = !!(
      this.props.user && this.props.user.enterpriseId
    );
    this.bulkUploadErrors = [];
  }

  setProgramNames = (clientPrograms) => {
    this.setState({
      clientPrograms,
    });
  };

  toggleFilters = () => {};

  applyFilters(filters, didApply, searchQuery) {
    this.usersFetcher.clearResults();
    this.invitesFetcher.clearResults();
    this.enterpriseClientsFetcher.clearResults();
    this.setState(
      {
        lastFetched: null,
        filters: filters,
        filtersApplied: didApply,
        clients: [],
        searchQuery,
      },
      () => {
        if (this.state.selectedTab !== TAB_OPTIONS.REQUEST) {
          this.listRef.reset(true, true);
        }
      }
    );

    this.toggleFilters();
  }

  clearFilters() {
    this.filtersRef.reset();
    this.usersFetcher.clearResults();
    this.invitesFetcher.clearResults();
    this.enterpriseClientsFetcher.clearResults();
    this.setState(
      {
        lastFetched: null,
        filters: null,
        filtersApplied: false,
        clients: [],
        searchQuery: "",
      },
      () => {
        if (this.state.selectedTab !== TAB_OPTIONS.REQUEST) {
          this.listRef.reset(true, true);
        }
      }
    );
  }
  //staging filter working f9
  // prepareSearchFilters() {
  //   let filters = bodybuilder();
  //   let query = "";
  //   if (this.state.filters) {
  //     return this.state.filters;
  //   } else {
  //     filters.query("query_string", {
  //       query: "*" + query + "*",
  //       fields: ["name", "email"],
  //     });
  //     filters.filter("term", "role", ROLES.CLIENT);
  //     if (this.props.ppEnterprise) {
  //       filters.orFilter("term", "enterpriseId", this.props.ppEnterprise.id);
  //     } else {
  //       filters.orFilter("term", "enterpriseId", this.props.user.enterpriseId);
  //     }
  //     filters.orFilter("term", "trainerId", this.props.user.id);
  //   }
  //   if (this.props.user.role === ROLES.HEALTH_COACH) {
  //     let query = filters.build();
  //     return { coachId: this.props.user.id, ...query };
  //   } else {
  //     return filters.build();
  //   }
  // }

  //change staging filter due to version diff
  prepareSearchFilters() {
    let filters = bodybuilder();
    let query = "";
    // if (this.state.filters) {
    //   return this.state.filters;
    // } else {
    let entId;
    if (this.props.ppEnterprise) {
      entId = this.props.ppEnterprise.id;
    } else {
      entId = this.props.user.enterpriseId;
    }
    filters.query("query_string", {
      query: "*" + this.state.searchQuery + "*",
      fields: ["name", "email"],
    });
    filters.filter("term", "role", ROLES.CLIENT);
    filters.andFilter(
      "bool",
      (q) =>
        q
          .orFilter("term", "trainerId", this.props.user.id)
          .orFilter("term", "enterpriseId", entId)
      // .andFilter("term", "role", ROLES.CLIENT)
    );
    // }
    if (this.props.user.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: this.props.user.id, ...query };
    } else {
      return filters.build();
    }
  }

  async fetchClients(clear) {
    if (clear) {
      this.usersFetcher.clearResults();
      this.invitesFetcher.clearResults();
    }

    this.usersFetcher.abort();
    this.invitesFetcher.abort();

    this.loading = true;
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    let query = this.prepareSearchFilters();

    try {
      let results;
      if (this.state.filtersApplied && this.state.searchQuery) {
        const users = await this.usersFetcher.fetch(query);
        const invities = await this.invitesFetcher.fetch(query);
        for (let invite of invities) {
          invite.pendingLogin = true;
        }
        results = [...users, ...invities];
      } else if (this.state.selectedTab === TAB_OPTIONS.REGISTER) {
        results = await this.usersFetcher.fetch(query);
      } else if (this.state.selectedTab === TAB_OPTIONS.REQUEST) {
        query = {};
        query.collection = COLLECTIONS.REQUESTS;
        query.filters = [
          {
            key: DB_KEYS.ACTIVE_STATUS,
            operator: "==",
            value: true,
          },
          {
            key: DB_KEYS.VISIBLE,
            operator: "==",
            value: true,
          },

          {
            key: DB_KEYS.TRAINER_ID_KEY,
            operator: "==",
            value: this.props.user.id,
          },
        ];

        if (this.state.searchQuery) {
          query.filters.push({
            key: "email",
            operator: "==",
            value: this.state.searchQuery,
          });
        }

        let pageConfig = {};
        if (this.state.lastFetched) {
          pageConfig.orders = [
            {
              key: "name",
              after: this.state.lastFetched.name,
            },
          ];
        } else {
          pageConfig.orders = [
            {
              key: "name",
            },
          ];
        }

        query.pageConfig = pageConfig;

        results = await window.FortisForma.database.queryData(query);
        // results = [];
      } else if (this.state.selectedTab === TAB_OPTIONS.PENDING) {
        let pendingInvites = await this.invitesFetcher.fetch(query);
        for (let invite of pendingInvites) {
          invite.pendingLogin = true;
        }
        results = pendingInvites;
      } else {
        throw Error("Invalid");
      }
      let clients = clear ? results : this.state.clients.concat(results);
      const values = new Set();
      const uniqueClients = [];
      for (const singleClient of clients) {
        if (!values.has(singleClient.id)) {
          values.add(singleClient.id);
          uniqueClients.push(singleClient);
        }
      }
      clients = uniqueClients;
      clients &&
        clients.sort((a, b) => {
          let nameA = (a.name || "").toLowerCase();
          let nameB = (b.name || "").toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      let lastFetched = results[results.length - 1] || this.state.lastFetched;
      this.loading = false;

      this.setState({
        lastFetched: lastFetched,
        clients,
      });
      return clients;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  onSelect(selectedClient) {
    this.setState({
      selectedClient: selectedClient,
      onClientSaved: false,
      addNew: false,
    });
    if (window.innerWidth <= 991) {
      this.navigate({ status: true });
    }
  }

  addNewClient() {
    //&& !this.isEnterpriseMember
    if (!this.state.showPending) {
      this.selectPending();
    }
    this.setState({
      selectedClient: this.state.emptyClient,
    });
  }

  async onSave(data) {
    if (data.id) {
      try {
        if (data.emailAdded) {
          let emailPresent =
            await window.FortisForma.database.checkIfEmailPresent(data.email);
          if (emailPresent) {
            delete data.email;
            delete data.emailAdded;
            this.setState({
              selectedClient: data,
            });
            throw new Error("Invalid email");
          } else {
            delete data.emailAdded;
          }
        }
        let results = await window.FortisForma.database.storeUserProfile(data);

        window.NotificationUtils.showSuccess("Client updated");
        // TODO: Update local reference form-control
        let update = Object.assign([], this.state.clients);
        let index = window._.findIndex(update, (value) => {
          return value.id === data.id;
        });
        if (index >= 0) {
          update[index] = data;
          this.setState({
            selectedClient: data,
            clients: update,
          });
        }
      } catch (e) {
        window.NotificationUtils.showError("Could not update client");
        throw e;
      }
    } else {
      if (data.email) {
        this.createNewClient(data);
      } else {
        if (!data.email) {
          window.NotificationUtils.showError("Email is required");
        }
      }
    }
  }

  createNewClient(profile) {
    window.showLoadingMessages &&
      window.showLoadingMessages(LOADING_MESSAGES, LOADING_CHANGE_DURATION);
    window.FortisForma.database
      .createNewClient(profile)
      .then((results) => {
        window.hideLoadingMessages && window.hideLoadingMessages();
        if (!results || results.data.message.errorInfo) {
          console.error(results.data);
          const message =
            results.data.message.errorInfo.message ||
            results.data.message.errorInfo ||
            "Access Denied";
          window.NotificationUtils.showError(message);
        } else if (results.data.code === 500) {
          window.NotificationUtils.showError("Email already exists");
        } else {
          this.setState(
            {
              selectedClient: this.state.emptyClient,
              onClientSaved: true,
              lastFetched: null,
            },
            () => {
              //Add one second break to let elastic search pickup the change
              setTimeout(() => {
                this.fetchClients(true);
              }, 1000);
            }
          );
          console.info("Client saved", results);
          window.NotificationUtils.showSuccess("Client created");
        }
      })
      .catch((e) => {
        window.hideLoadingMessages && window.hideLoadingMessages();
        console.error(e);
      });
  }

  removeClient = (client) => {
    window.showLoadingMessages &&
      window.showLoadingMessages(REMOVING_MESSAGES, LOADING_CHANGE_DURATION);
    window.FortisForma.database
      .removeClient(client)
      .then((results) => {
        window.hideLoadingMessages && window.hideLoadingMessages();
        if (!results || results.data.message.errorInfo) {
          console.error(results.data);
          const message =
            results.data.message.errorInfo.message ||
            results.data.message.errorInfo ||
            "Something went wrong!";
          window.NotificationUtils.showError(message);
        } else {
          this.setState(
            {
              selectedClient: this.state.emptyClient,
              lastFetched: null,
            },
            () => {
              //Add one second break to let elastic search pickup the change
              setTimeout(() => {
                this.fetchClients(true);
              }, 1000);
            }
          );
          console.info("Client removed", results);
          window.NotificationUtils.showSuccess("Client removed");
        }
      })
      .catch((e) => {
        window.hideLoadingMessages && window.hideLoadingMessages();
        console.error(e);
      });
  };

  resendInvite = (email, name) => {
    window.NotificationUtils.showConfirm("Sending Invite");
    email = window.emailTrimAndLowerCase(email);
    window.FortisForma.database
      .sendInvite(email, name)
      .then((results) => {
        if (
          !results ||
          results.data.message.errorInfo ||
          results.data.status !== 200
        ) {
          const message =
            (results.data.message.errorInfo &&
              results.data.message.errorInfo.message) ||
            (results.data.message && results.data.message.message) ||
            "Something went wrong!";
          throw new Error(message);
        } else {
          window.NotificationUtils.showSuccess("Invite sent");
        }
      })
      .catch((e) => {
        window.NotificationUtils.showError("Could not send invite");
        console.error(e);
      });
  };

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = ({ status }) => {
    let didNavigateDown = status;
    let node;
    if (status) {
      node = document.querySelector("#clientDetailContainer");
    } else {
      node = document.querySelector("nav");
    }
    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !status,
    });
  };

  onApproveRequest = async (reqId, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let requestData = {
      reqId,
    };
    window.showLoadingMessages &&
      window.showLoadingMessages(APPROVING_MESSAGES, LOADING_CHANGE_DURATION);
    try {
      await window.FortisForma.database.approveClientRequest(requestData);
      let requests = this.state.clients.filter((client) => {
        return client.id !== requestData.reqId;
      });
      this.setState(
        {
          clients: requests,
          lastFetched: null,
        },
        () => {
          this.fetchClients(true);
        }
      );
      window.NotificationUtils.showSuccess("Request Approved Successfully");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Request Approval Unsuccessful");
    }
    window.hideLoadingMessages && window.hideLoadingMessages();
  };

  onRejectRequest = async (reqId, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let requestData = {
      reqId,
    };
    window.showLoadingMessages &&
      window.showLoadingMessages(REJECTING_MESSAGES, LOADING_CHANGE_DURATION);
    try {
      await window.FortisForma.database.rejectClientRequest(requestData);
      let requests = this.state.clients.filter((client) => {
        return client.id !== requestData.reqId;
      });
      this.setState(
        {
          clients: requests,
          lastFetched: null,
        },
        () => {
          this.fetchClients(true);
        }
      );
      window.NotificationUtils.showSuccess("Request Rejected Successfully");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Request Rejection Unsuccessful");
    }
    window.hideLoadingMessages && window.hideLoadingMessages();
  };

  renderSearch() {
    return (
      <Col id="clientsFilterContainer">
        <div id="clientFilterFields" style={{ maxWidth: 600 }}>
          <ClientFilters
            ref={(ref) => {
              this.filtersRef = ref;
            }}
            filters={{
              [DB_KEYS.TRAINER_ID_KEY]: this.props.user.id,
              [DB_KEYS.ROLE]: ROLES.CLIENT,
            }}
            inclusive={true}
            placeholder={
              this.state.selectedTab === TAB_OPTIONS.REQUEST
                ? "Search By Email"
                : "Search Clients"
            }
            onSetFilters={this.applyFilters}
            onCancel={this.clearFilters}
          ></ClientFilters>
        </div>
      </Col>
    );
  }

  emptyPlaceholder = () => {
    return (
      <div>
        <div>
          {this.loading ? "" : "No clients found matching your criteria"}
        </div>

        {this.state.filtersApplied ? (
          <div className="filtersSearch internal">
            <Button
              className="clearFiltersButton"
              color="primary"
              onClick={this.clearFilters}
            >
              Clear Filters
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  selectTab(tab) {
    if (this.state.searchQuery && this.state.filtersApplied) {
      this.setState({ selectedTab: tab });
      return;
    }
    this.usersFetcher.abort();
    this.invitesFetcher.abort();
    this.enterpriseClientsFetcher.abort();

    this.filtersRef.reset();
    this.usersFetcher.clearResults();
    this.invitesFetcher.clearResults();
    this.enterpriseClientsFetcher.clearResults();

    this.setState(
      {
        clients: [],
        filters: null,
        filtersApplied: false,
        lastFetched: null,
        selectedTab: tab,
        selectedClient: null,
      },
      () => {
        if (this.state.selectedTab !== TAB_OPTIONS.REQUEST) {
          this.listRef.reset(true, true);
        }
      }
    );
  }

  sendMessage = async (message) => {
    try {
      let messageData = {
        to: this.state.selectedClient.id,
        message: message,
      };
      window.NotificationUtils.showConfirm("Sending Message...");
      await window.FortisForma.database.sendMessage(messageData);
      window.NotificationUtils.showSuccess("Message Sent Successfully");
      this.pendingDetailsRef.current.closeMessageModal();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to send message");
    }
  };

  trim = (row) => {
    for (let key of Object.keys(row)) {
      let value = row[key];
      if (value && typeof value === "string") {
        row[key] = value.trim();
      }
    }

    return row;
  };

  parseDateExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  };

  mapRowToDocument = async (row, index) => {
    if (row[BULK_UPLOAD_CLIENT.PHONE]) {
      row[BULK_UPLOAD_CLIENT.PHONE] = row[BULK_UPLOAD_CLIENT.PHONE].toString();
    }
    if (typeof row[BULK_UPLOAD_CLIENT.DOB] === "number") {
      row[BULK_UPLOAD_CLIENT.DOB] = moment(
        new Date(this.parseDateExcel(row[BULK_UPLOAD_CLIENT.DOB]))
      ).format(DATE_FORMAT);
    }
    try {
      let error = {};
      const errMessages = await this.checkRowErrors(row);
      if (errMessages.length) {
        error.row = index;
        error.errMessages = errMessages;
        this.bulkUploadErrors.push(error);
        return;
      }

      let client = {
        name: row[BULK_UPLOAD_CLIENT.NAME],
        email:
          (row[BULK_UPLOAD_CLIENT.EMAIL] &&
            window.emailTrimAndLowerCase(row[BULK_UPLOAD_CLIENT.EMAIL])) ||
          "",
        phone: row[BULK_UPLOAD_CLIENT.PHONE] || "",
        DOB: row[BULK_UPLOAD_CLIENT.DOB] || "",
        address: row[BULK_UPLOAD_CLIENT.ADDRESS] || "",
        province: row[BULK_UPLOAD_CLIENT.PROVINCE] || "",
        postal: row[BULK_UPLOAD_CLIENT.POSTAL] || "",
        city: row[BULK_UPLOAD_CLIENT.CITY] || "",
        membershipName: row[BULK_UPLOAD_CLIENT.MEMBERSHIP_NAME] || "",
      };
      return client;
    } catch (e) {
      throw e;
    }
  };

  checkRowErrors = async (row) => {
    try {
      let messages = [];
      if (!row[BULK_UPLOAD_CLIENT.NAME]) {
        messages.push(BULK_UPLOAD_ERRORS.NAME);
      }

      if (
        row[BULK_UPLOAD_CLIENT.EMAIL] &&
        !isEmail(row[BULK_UPLOAD_CLIENT.EMAIL])
      ) {
        messages.push(BULK_UPLOAD_ERRORS.EMAIL_FORMAT);
      }

      if (
        row[BULK_UPLOAD_CLIENT.EMAIL] &&
        (await window.FortisForma.database.checkIfEmailPresent(
          row[BULK_UPLOAD_CLIENT.EMAIL]
        ))
      ) {
        messages.push(BULK_UPLOAD_ERRORS.EMAIL_EXISTS);
      }

      return messages;
    } catch (e) {
      throw e;
    }
  };

  onBulkFileSelect = async (file) => {
    if (!file) {
      return;
    }
    this.readFile(file)
      .then(async (fileData) => {
        try {
          const wb = XLSX.read(fileData, {
            type: "array",
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          await this.onBulkFileReady(data);
        } catch (e) {
          window.NotificationUtils.showError("Invalid data in file");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onload = (evt) => {
        resolve(evt.target.result);
      };
      reader.onerror = (evt) => {
        throw evt;
      };
      reader.readAsArrayBuffer(file);
    });
  };

  handleErrorsList = () => {
    if (!this.state.errorsModal) {
      this.bulkUploadErrors = [];
    }
  };

  onBulkFileReady = async (data) => {
    try {
      window.NotificationUtils.showConfirm("Reading file");
      let documents = await Promise.all(
        data.map(async (row, index) => {
          let doc = await this.mapRowToDocument(this.trim(row), index);
          return doc;
        })
      );
      this.setState({ openBulkUploadModal: false });
      const clients = documents.filter((doc) => doc != null);
      window.NotificationUtils.showConfirm(
        `found ${documents.length} rows ...`
      );
      await this.bulkSave(clients);
      window.NotificationUtils.showSuccess(`Uploaded ${clients.length} rows`);
    } catch (e) {
      this.handleErrorsList();
      this.setState({ openBulkUploadModal: false });
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
  };

  async bulkSave(clients) {
    if (this.bulkUploadErrors && this.bulkUploadErrors.length) {
      this.setState({ errorsModal: true });
      window.NotificationUtils.showError(
        `${this.bulkUploadErrors.length} errors found in uploaded data`
      );
    }

    if (!clients || !clients.length) {
      throw new Error("All bulk upload rows failed");
    }

    try {
      this.setState({ uploading: true });
      window.NotificationUtils.showSuccess(`Uploading ${clients.length} rows`);
      await window.FortisForma.database.bulkClientsUpload(clients);
      this.setState(
        {
          selectedClient: this.state.emptyClient,
          lastFetched: null,
          uploading: false,
        },
        () => {
          //Add one second break to let elastic search pickup the change
          setTimeout(() => {
            this.fetchClients(true);
            this.selectPending();
          }, 1000);
        }
      );
    } catch (e) {
      this.setState({ uploading: false });
      throw e;
    }
  }

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          showSkipButton={true}
          locale={{ last: "Done" }}
        />
      </div>
    );
  };

  renderClientDetails() {
    if (this.state.selectedClient === null) {
      return null;
    } else if (this.state.selectedTab === TAB_OPTIONS.REQUEST) {
      return (
        <PendingClientDetails
          ref={this.pendingDetailsRef}
          {...this.props}
          selectedClient={this.state.selectedClient}
          sendMessage={this.sendMessage}
        />
      );
    } else {
      return (
        <ClientDetails
          {...this.props}
          selectedClient={this.state.selectedClient}
          onSave={this.onSave}
          onResendInvite={this.resendInvite}
          healthCoaches={this.state.healthCoaches}
          clientPrograms={this.state.clientPrograms}
          setProgramNames={this.setProgramNames}
          onClientSaved={this.state.onClientSaved}
        />
      );
    }
  }

  onCloseErrorModal = () => {
    this.bulkUploadErrors = [];
    this.setState({
      errorsModal: false,
      uploading: false,
    });
  };

  onCLickBulkUploadButton = () => {
    this.setState({
      openBulkUploadModal: true,
    });
  };

  onCloseBulkUploadModal = () => {
    this.setState({
      openBulkUploadModal: false,
    });
  };
  renderErrorModal = () => {
    return (
      <React.Fragment>
        <Dialog open={this.state.errorsModal} fullWidth={true}>
          <ErrorModal
            loading={this.state.uploading}
            errors={this.bulkUploadErrors}
            onClose={this.onCloseErrorModal}
          />
        </Dialog>
      </React.Fragment>
    );
  };

  renderBulkUploadModal = () => {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.openBulkUploadModal}
          fullWidth={true}
          onClose={this.onCloseBulkUploadModal}
        >
          <BulkUploadModal
            onClose={this.onCloseBulkUploadModal}
            onBulkFileSelect={this.onBulkFileSelect}
          />
        </Dialog>
      </React.Fragment>
    );
  };

  renderTabs() {
    return (
      <Nav tabs>
        <NavItem>
          <NavLink
            className={`clientTabsLink ${
              this.state.selectedTab !== TAB_OPTIONS.REGISTER ? "" : "active"
            }`}
            id="registeredClient"
            onClick={this.selectRegistered}
          >
            Registered
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`clientTabsLink ${
              this.state.selectedTab !== TAB_OPTIONS.PENDING ? "" : "active"
            }`}
            id="pendingClient"
            onClick={this.selectPending}
          >
            Pending
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`clientTabsLink ${
              this.state.selectedTab !== TAB_OPTIONS.REQUEST ? "" : "active"
            }`}
            id="requestClient"
            onClick={this.selectRequest}
          >
            Requests
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.walkthrough && this.renderJoyride()}
        <Container className="clientEditor" fluid>
          <Row className="fullWidth" style={{ alignItems: "center" }}>
            {this.renderSearch()}
            {/*{this.isEnterpriseMember && (*/}
            <Col style={{ textAlign: "right", padding: 0, maxWidth: 200 }}>
              <div className="bulkUploadButtonContainer">
                <Button
                  id="bulkPickerButton"
                  onClick={this.onCLickBulkUploadButton}
                >
                  Bulk Upload
                </Button>
              </div>
            </Col>
            {/*)}*/}
            <Col style={{ textAlign: "right", padding: 0, maxWidth: 200 }}>
              <Button
                id="addNewClientButton"
                color="primary"
                onClick={this.addNewClient}
              >
                Add New Client
              </Button>
            </Col>
          </Row>
          {/* -------------------------------------------------------------------------------------------- */}
          <Row className="fullWidth fullWHListContainer">
            <Col lg="6" md="12" sm="12" xs="12" id="clientEditorListContainer">
              <React.Fragment>
                {this.renderTabs()}
                <TabContent className="listContainer" style={{ flex: 1 }}>
                  <TabPane style={{ color: "inherit", height: "100%" }}>
                    {this.renderTabContent()}
                  </TabPane>
                </TabContent>
              </React.Fragment>
            </Col>
            <Col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              className="clientDetailContainer"
              id="clientDetailContainer"
            >
              {this.renderClientDetails()}
            </Col>
          </Row>
          <div
            hidden={this.state.selected === null}
            className="screenNavigationHelperButton"
            onClick={() =>
              this.navigate({ status: this.state.didNavigateDown })
            }
          >
            {this.navigationIcon()}
          </div>
        </Container>
        {this.renderErrorModal()}
        {this.renderBulkUploadModal()}
      </React.Fragment>
    );
  }

  renderTabContent() {
    return (
      <div
        id="clientEditorList"
        className="fullWidth"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Row
          hidden
          className="clientCardHeading centerAlignJustify"
          style={{ minHeight: 50 }}
        >
          <Col xs="2" hidden />
          <Col
            lg="3"
            md="3"
            sm="3"
            xs="3"
            className="incFontWeight"
            style={{ paddingLeft: 8 }}
          >
            Name
          </Col>
          <Col lg="3" md="3" sm="3" xs="3" className="incFontWeight">
            Email
          </Col>
          <Col
            lg="3"
            md="3"
            sm="3"
            xs="3"
            className="centerAlignJustify incFontWeight"
          >
            Phone
          </Col>
          <Col xs="1" hidden id="deleteButtonHeader" />
        </Row>
        <div id="clientEditorList" style={{ flex: 1 }}>
          {this.renderList(this.state.showPending)}
        </div>
      </div>
    );
  }

  renderList() {
    if (this.state.selectedTab === TAB_OPTIONS.REQUEST) {
      // return (
      //   <ClientApprovalList
      //     ref={(r) => {
      //       this.listRef = r;
      //     }}
      //     data={this.state.clients}
      //     autoSize={true}
      //     padding={[0, 0]}
      //     containerId={"clientEditorList"}
      //     rowHeight={60}
      //     columnCount={1}
      //     selectedClient={this.state.selectedClient}
      //     onSelect={this.onSelect}
      //     approveClient={this.onApproveRequest}
      //     rejectClient={this.onRejectRequest}
      //     loadData={this.fetchClients}
      //     pageSize={this.pageSize}
      //     emptyPlaceholder={this.emptyPlaceholder()}
      //   />
      // );
      return (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            color: "#800520",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          New Feature "Public Profile" is coming soon
        </div>
      );
    } else if (this.state.selectedTab === TAB_OPTIONS.PENDING) {
      return (
        <ClientList
          ref={(r) => {
            this.listRef = r;
          }}
          data={this.state.clients.filter((item) => item.pendingLogin == true)}
          autoSize={true}
          padding={[0, 0]}
          containerId={"clientEditorList"}
          rowHeight={90}
          columnCount={1}
          selectedClient={this.state.selectedClient}
          onRemove={this.removeClient}
          onSelect={this.onSelect}
          loadData={this.fetchClients}
          pageSize={this.pageSize}
          emptyPlaceholder={this.emptyPlaceholder()}
          isEnterpriseMember={this.isEnterpriseMember}
          role={this.props.user && this.props.user.role}
        />
      );
    }
    return (
      <ClientList
        ref={(r) => {
          this.listRef = r;
        }}
        data={this.state.clients.filter((item) => item.pendingLogin !== true)}
        autoSize={true}
        padding={[0, 0]}
        containerId={"clientEditorList"}
        rowHeight={90}
        columnCount={1}
        selectedClient={this.state.selectedClient}
        onRemove={this.removeClient}
        onSelect={this.onSelect}
        loadData={this.fetchClients}
        pageSize={this.pageSize}
        emptyPlaceholder={this.emptyPlaceholder()}
        isEnterpriseMember={this.isEnterpriseMember}
        role={this.props.user && this.props.user.role}
      />
    );
  }
}
