import React from "react";
import ClientForms from "./clientForms";
import CreateForm from "../../components/forms/createFormModal";
import { isMobile } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import {
  CLIENT_DETAIL_TABS,
  COLLECTIONS,
  FORM_TYPES,
  FORM_TYPES_LABEL,
  ROLES,
  TIER,
  GET_ENT_ID,
  GET_USER_ID,
} from "../../constants";
import PreviewForm from "../../components/forms/previewFormModal";
import SignatureField from "../../components/signatureField/signatureField";
import PDFViewer from "../../components/forms/pdfViewer";
import DatePicker from "../../components/datePickerWidget/datePickerWidget";
import PhoneField from "../../components/phoneFieldWidget/phoneFieldWidget";
import _ from "lodash";
import UseEnterpriseTemplates from "../../components/forms/useEnterpriseTemplates";
import FreehandNote from "../../components/freehandNoteWidget/freehandNote.js";
import AddImageWidget from "../../components/addImageWidget/addImageWidget";

export const FORM_TYPE_OPTIONS = [
  { label: FORM_TYPES_LABEL.REGISTRATION, value: FORM_TYPES.REGISTRATION },
  { label: FORM_TYPES_LABEL.ASSESSMENTS, value: FORM_TYPES.ASSESSMENTS },
  { label: FORM_TYPES_LABEL.GENERAL_INFO, value: FORM_TYPES.GENERAL_INFO },
  { label: FORM_TYPES_LABEL.MISC, value: FORM_TYPES.MISC },
  { label: FORM_TYPES_LABEL.NOTES, value: FORM_TYPES.NOTES },
];

const getAllFormIds = (forms) => {
  const clientForms = Object.values(forms);
  let newFormIds = [];

  for (let type = 0; type < clientForms.length; type++) {
    for (let i = 0; i < clientForms[type].length; i++) {
      newFormIds.push(clientForms[type][i].formId);
    }
  }
  return newFormIds;
};

const getAllClientForms = (forms) => {
  const clientForms = Object.values(forms);
  let allClientForms = [];

  for (let type = 0; type < clientForms.length; type++) {
    for (let i = 0; i < clientForms[type].length; i++) {
      allClientForms.push(clientForms[type][i]);
    }
  }
  return allClientForms;
};

const getGroupedIds = (forms) => {
  const formIds = getAllFormIds(forms);
  let groupedIds = [];
  if (!formIds || !formIds.length) {
    return [];
  }
  let index = 0;
  const numberOfGroups = Math.ceil(formIds.length / 10);
  while (index < formIds.length) {
    for (let group = 0; group < numberOfGroups; group++) {
      groupedIds[group] = [];

      for (index; index < formIds.length; index++) {
        if (groupedIds[group].length === 10) {
          break;
        }

        if (formIds[index]) {
          groupedIds[group].push(formIds[index]);
        }
      }
    }
  }
  return groupedIds;
};

export const getTotalQuestions = (properties) => {
  let questions = 0;

  if (properties && !Object.keys(properties).length) {
    return questions;
  }
  for (let key in properties) {
    const question = properties[key];
    const questionRequiresInput = Boolean(
      !question.data && !(question.type === "object")
    );

    if (question.properties) {
      let sectionQuestions = 0;
      sectionQuestions = getTotalQuestions(question.properties);
      questions += sectionQuestions;
    }

    if (questionRequiresInput) {
      questions++;
    }
  }
  return questions;
};

export default class Forms extends React.Component {
  state = {
    loading: true,
    data: {},
    editFormId: null,
    openCreateFormModal: false,
    formsList: [],
    enterpriseForms: [],
    hasFetchedForms: false,
    modalSelectedTab: null,
    previewForm: false,
    openUseTemplateModal: false,
    newFormType: FORM_TYPE_OPTIONS[0].value,
  };

  componentDidMount() {
    this.fetchForms(this.props.clientId);
  }

  fetchForms = async (clientId = "") => {
    this.setState({
      loading: true,
    });
    let data = {
      clientId: clientId || this.props.clientId,
      email: this.props.clientEmail || "",
    };
    let forms;
    try {
      forms = await window.FortisForma.database.fetchClientForms(data);
      const groupedIds = getGroupedIds(forms);
      if (!groupedIds || !groupedIds.length) {
        this.setState({
          loading: false,
          formsList: [],
        });
        return;
      }
      const clientsForms = getAllClientForms(forms);
      let formsList = [];
      for (let group of groupedIds) {
        const results =
          await window.FortisForma.database.fetchDocumentsByArrayOfIds(
            group,
            COLLECTIONS.FORMS
          );
        for (let form of clientsForms) {
          if (form) {
            let formData = results.find((f) => f.id === form.formId);
            if (formData && formData.schema && formData.schema.properties) {
              let totalQuestions = getTotalQuestions(
                formData.schema.properties
              );
              form.formData = formData;
              form.title = formData.schema.title;
              form.description = formData.schema.description || "";
              form.totalQuestions = totalQuestions;
              form.step = formData.step;
              formsList.push(form);
            }
          }
        }
        this.setState({
          loading: false,
          formsList: formsList,
        });
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error occured while fetching forms");
      this.setState({
        loading: false,
      });
    }
  };

  setFormsList = (list) => {
    this.setState({
      formsList: list,
    });
  };

  redirectToNotes = () => {
    this.props.handleTabChange({}, CLIENT_DETAIL_TABS.NOTES, true);
  };

  redirectToAssessments = () => {
    this.props.handleTabChange({}, CLIENT_DETAIL_TABS.ASSESSMENT, true);
  };

  onClickAddForm = () => {
    if (this.state.newFormType === FORM_TYPES.NOTES) {
      return this.redirectToNotes();
    }

    if (this.state.newFormType === FORM_TYPES.ASSESSMENTS) {
      return this.redirectToAssessments();
    }

    this.setState({
      openCreateFormModal: true,
    });
  };

  closeCreateFormModal = () => {
    this.setState({
      openCreateFormModal: false,
      data: {},
      editFormId: null,
    });
  };

  onClickUseTemplate = () => {
    this.setState({
      openUseTemplateModal: true,
    });
  };

  closeUseTemplate = () => {
    this.setState({
      openUseTemplateModal: false,
    });
  };

  onSaveForm = (form, totalQuestions) => {
    var newForms = [...this.state.formsList];
    var formIndex = newForms.findIndex((f) => f.formId === form.id);
    if (formIndex === -1) {
      var formMeta = {
        filled: Boolean(!totalQuestions),
        formData: form,
        formId: form.id,
        title: form.schema.title,
        description: form.schema.description || "",
        totalQuestions: totalQuestions || 0,
        step: form.step,
      };
      newForms.push(formMeta);
    } else {
      newForms[formIndex].formData = form;
    }
    this.setState(
      {
        data: {},
        editFormId: null,
        formsList: newForms,
      },
      this.scrollToBottom
    );
  };

  onChangeNewFormType = (newFormType) => {
    this.setState({
      newFormType: parseInt(newFormType),
    });
  };

  getSubmissionId = (formId) => {
    const { formsList } = this.state;
    for (const item of formsList) {
      if (item.formData.id === formId && _.has(item, "submissionId")) {
        return item.submissionId;
      }
    }
    return null;
  };

  scrollToBottom = () => {
    let target = document.querySelectorAll(".formDetailsCardContainer");
    target = target[target.length - 1];
    if (target) {
      target.scrollIntoView &&
        target.scrollIntoView({
          behavior: "smooth",
        });
    }
  };

  renderCreateFormModal = () => {
    if (!this.state.openCreateFormModal) {
      return;
    }
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closeCreateFormModal}
        open={this.state.openCreateFormModal}
        maxWidth="md"
      >
        <CreateForm
          closeCreateForm={this.closeCreateFormModal}
          onSaveForm={this.onSaveForm}
          data={this.state.data}
          editFormId={this.state.editFormId}
          tabSelected={this.state.newFormType}
          {...this.props}
          sequenceNumber={null}
          isClientForm={true}
        />
      </Dialog>
    );
  };

  renderPreviewFormModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closePreviewForm}
        open={this.state.previewForm}
        maxWidth="md"
      >
        <PreviewForm
          closePreviewForm={this.closePreviewForm}
          data={this.state.data}
          widgets={{
            signatureField: SignatureField,
            pdfViewer: PDFViewer,
            DateWidget: DatePicker,
            phoneField: PhoneField,
            freehandNote: FreehandNote,
            addImageWidget: AddImageWidget,
          }}
        />
      </Dialog>
    );
  };

  onClickPreview = (form, modalSelectedTab = null) => {
    this.setState({
      data: form,
      previewForm: true,
      modalSelectedTab: modalSelectedTab,
      openUseTemplatesModal: false,
    });
  };

  closePreviewForm = () => {
    this.setState({
      previewForm: false,
      data: {},
      editFormId: null,
      openUseTemplatesModal: this.state.modalSelectedTab ? true : false,
    });
  };

  fetchEnterpriseForms = async () => {
    try {
      let results;
      const enterpriseId = GET_ENT_ID(this.props);
      const userID = GET_USER_ID(this.props);
      results = await window.FortisForma.database.fetchEnterpriseForms(
        enterpriseId,
        this.isAdmin,
        "",
        userID
        // this.props.user.id
      );
      //Previous Code
      // if (
      //   this.props.user.tier !== TIER.FREE &&
      //   this.props.user.role === ROLES.TRAINER
      // ) {
      //   results = await window.FortisForma.database.fetchClientForms(
      //     this.props.user.id
      //   );
      // } else {

      //   results = await window.FortisForma.database.fetchEnterpriseForms(
      //     this.props.user.enterpriseId,
      //     "",
      //     "",
      //     this.props.user.id
      //   );

      this.setState({
        enterpriseForms: results,
        hasFetchedForms: true,
      });
      return results;
    } catch (error) {
      throw error;
    }
  };

  renderUseTemplatesModal = () => {
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closeUseTemplate}
        open={this.state.openUseTemplateModal}
        maxWidth="lg"
      >
        <UseEnterpriseTemplates
          closeUseTemplatesModal={this.closeUseTemplate}
          fetchEnterpriseForms={this.fetchEnterpriseForms}
          hasFetchedForms={this.state.hasFetchedForms}
          enterpriseForms={this.state.enterpriseForms}
          onClickPreview={this.onClickPreview}
          user={this.props.user || {}}
          formList={this.state.formsList}
          fetchForms={this.fetchForms}
          tabOpenBefore={this.state.modalSelectedTab}
          isClientForm={true}
          redirectToNotes={this.redirectToNotes}
          redirectToAssessments={this.redirectToAssessments}
          {...this.props}
        />
      </Dialog>
    );
  };

  render() {
    return (
      <>
        <ClientForms
          setFormsList={this.setFormsList}
          formsList={this.state.formsList}
          loading={this.state.loading}
          clientId={this.state.clientId}
          clientLoginPending={this.state.clientLoginPending}
          clientName={this.state.clientName}
          clientEmail={this.state.clientEmail}
          onClickAddForm={this.onClickAddForm}
          onClickUseTemplate={this.onClickUseTemplate}
          onChangeNewFormType={this.onChangeNewFormType}
          newFormType={this.state.newFormType}
          getSubmissionId={this.getSubmissionId}
          {...this.props}
        />
        {this.renderCreateFormModal()}
        {this.renderPreviewFormModal()}
        {this.renderUseTemplatesModal()}
      </>
    );
  }
}
