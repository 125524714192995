import React from "react";
import PDFThumbnail from "../../PDFThumbnail/PDFThumbnail";
import AttachmentImageItem from "../attachmentImageItem";
import PreviewImageModal from "../../uploaderImage/previewImageModal";

export default function Attachment({ item, index }) {
  const [url, setUrl] = React.useState("");
  const [preview, openPreview] = React.useState(false);

  const openPreviewModal = (e, link) => {
    e.stopPropagation && e.stopPropagation();
    setUrl(link);
    openPreview(true);
  };

  const renderPreviewModal = () => {
    return (
      <PreviewImageModal
        image={url}
        openPreviewModal={preview}
        closePreviewModal={() => openPreview(false)}
      />
    );
  };

  const renderPDFThumbnail = () => {
    if (item && item.url && window.isPDF(item.url)) {
      return (
        <div
          className="attachmentListItem"
          key={index}
          onClick={(e) => openPreviewModal(e, item.url)}
        >
          <PDFThumbnail
            link={item.url}
            height={60}
            className="attachedImageContainer"
            pageClassName="assessmentImage"
          />
        </div>
      );
    }
  };

  const renderImageThumbnail = () => {
    if (item && item.url && !window.isPDF(item.url)) {
      return (
        <div
          className="attachmentListItem"
          key={index}
          onClick={(e) => openPreviewModal(e, item.url)}
        >
          <AttachmentImageItem data={item} />
        </div>
      );
    }
  };

  return (
    <>
      {renderPDFThumbnail()}
      {renderImageThumbnail()}
      {renderPreviewModal()}
    </>
  );
}
