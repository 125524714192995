import React from "react";
import BaseModal from "./baseModal";

import { Modal, ModalBody, Progress } from "reactstrap";

export default class LoadingMessageModal extends BaseModal {
  show(messages, changeDuration) {
    super.show(messages[0]);
    if (messages.length > 1) {
      let i = 1;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (i >= messages.length) {
          i = 0;
        }
        this.setState({
          message: messages[i],
        });
        i++;
      }, changeDuration);
    }
  }

  hide() {
    super.hide();
    clearInterval(this.timer);
  }

  render() {
    return (
      <Modal isOpen={this.state.show} backdrop={true}>
        <ModalBody style={{ padding: 16, textAlign: "center" }}>
          {this.state.message}
          <div>
            <Progress
              className="loadingMessagesProgressBar"
              bar
              animated
              color="#8c3044"
              value="100"
            ></Progress>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
