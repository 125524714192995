import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import React, { Component } from "react";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

export default class FormImagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: false,
      saving: false,
      showPreview: false,
      disableButtons: false,
    };
  }

  componentDidMount() {
    this.fetchFormImages();
  }

  fetchFormImages = async () => {
    this.setState({ loading: true });
    try {
      let results = [];
      results = await this.fetchEnterpriseImages();
      this.setState({ images: results });
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
    this.setState({ loading: false });
  };

  renderLoading = () => {
    if (this.state.loading) {
      return <OpaqueLoading />;
    }
  };

  renderCloseButton = () => {
    const { loading, disableButtons } = this.state;
    return (
      <IconButton
        className="closeIconButton"
        onClick={this.props.onClose}
        disabled={loading || disableButtons}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  onChangeImages = (images) => {
    this.setState({
      images,
      disableButtons: false,
    });
  };

  render() {
    return (
      <div className={this.state.showPreview ? "hidden" : ""}>
        {this.renderLoading()}
        {this.renderCloseButton()}
        <DialogTitle>{this.getDialogTitle()}</DialogTitle>
        <DialogContent>{this.renderDialogContent()}</DialogContent>
        <DialogActions>{this.renderDialogActions()}</DialogActions>
      </div>
    );
  }
}
