import { DialogContent, Divider, Typography } from "@material-ui/core";
import Form from "@rjsf/material-ui";
import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTotalQuestions } from "../../views/enterpriseClientDetails/forms";
import { getSubmittedQuestions } from "../../views/enterpriseClientDetails/clientForms";
import { shouldHandleLongText } from "../../utils/formUtils";

function ObjectFieldTemplate(props) {
  return (
    <div className="previewFormContainer">
      <Typography variant="h5" className="marginBottom8">
        {props.title}
      </Typography>
      {props.title && props.description && (
        <Divider className="marginBottom8" />
      )}
      {props.description && (
        <Typography variant="subtitle2" className="marginBottom">
          {props.description}
        </Typography>
      )}
      {props.properties.map((element, index) => {
        const isWithLongText = shouldHandleLongText(element.content);
        let className = "previewFormContent marginBottom";
        if (isWithLongText) {
          className += " withLongText";
        }
        return (
          <div key={index} className={className}>
            {element.content}
          </div>
        );
      })}
    </div>
  );
}

export default class PreviewForm extends React.Component {
  state = {
    loading: false,
    formData: {},
    data: null,
    submissionData: null,
  };

  componentDidMount() {
    const { data, submissionData } = this.props;
    if (
      (data && !data.schema) ||
      (submissionData && !Object.keys(submissionData).length)
    ) {
      return this.fetchForm();
    }
    this.setState({
      data: data || {},
      submissionData: submissionData || {},
    });
  }

  getFormTitle = () => {
    const { data } = this.state;
    const formTitle = data && data.schema && data.schema.title;
    return formTitle;
  };

  fetchForm = async () => {
    this.setState({ loading: true });
    try {
      if (!this.props.data.id) {
        throw new Error("Something went wrong");
      }

      let formData =
        this.props.data && this.props.data.schema && this.props.data;

      let submissionData =
        this.props.submissionData &&
        this.props.submissionData.formData &&
        this.props.submissionData;
      const formId = this.props.data.id;
      const submissionId = this.props.data.submissionId || "";

      if (!formData) {
        formData = await window.FortisForma.database.fetchFormsByFormId(formId);
      }

      if (!submissionData && submissionId) {
        submissionData =
          submissionId &&
          (await window.FortisForma.database.fetchFormData(submissionId));
      }

      this.setState({
        loading: false,
        data: formData,
        submissionData: submissionData || {},
      });
    } catch (e) {
      this.setState({ loading: false });
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
  };

  renderLoader = () => {
    return (
      <div className="previewFormLoadingContainer">
        <CircularProgress color="primary" />
      </div>
    );
  };

  getItemId = () => {
    if (this.state.data && this.state.data.assessmentId) {
      return this.state.data.assessmentId;
    }
    if (this.state.data && this.state.data.noteId) {
      return this.state.data.noteId;
    }
    return "";
  };

  onSubmit = async ({ formData }) => {
    if (!this.props.onSubmit) {
      return "";
    }
    this.setState({
      loading: true,
    });
    try {
      let schemaProperties =
        this.state.data.schema &&
        this.state.data.schema.properties &&
        this.state.data.schema.properties;
      let totalQuestions = getTotalQuestions(schemaProperties) || 0;
      let submittedQuestions =
        getSubmittedQuestions(schemaProperties, formData) || 0;
      let itemId = this.getItemId();
      let submissionData = {
        formData,
        submittedQuestions,
        totalQuestions,
      };

      await this.props.onSubmit(submissionData, itemId);
      if (itemId) {
        window.NotificationUtils.showConfirm("Form data added");
      } else {
        window.NotificationUtils.showSuccess("Form data saved successfully");
      }
    } catch (e) {
      if (e.status === 413) {
        let errorMessage = "Unable to save, file size too large";
        console.error(errorMessage);
        window.NotificationUtils.showError(errorMessage);
      } else {
        console.error(e);
        window.NotificationUtils.showError("Something went wrong");
      }
    }
    this.setState({
      loading: false,
    });
  };

  getChildren = () => {
    if (!this.props.onSubmit) {
      return { children: <></> };
    }
    return {};
  };

  renderDialogContentData = () => {
    const { data, submissionData, loading } = this.state;
    if (loading) {
      return this.renderLoader();
    }
    return (
      <div className="previewContainer whiteSpacePreLine">
        {data &&
          data.formMetaData &&
          data.formMetaData.headerLogoMetaData &&
          data.formMetaData.headerLogoMetaData.url && (
            <img
              src={data.formMetaData.headerLogoMetaData.url}
              alt="imgPreview"
              className="previewImageContainer"
            />
          )}
        <Form
          schema={(data && data.schema) || {}}
          uiSchema={(data && data.uiSchema) || {}}
          widgets={this.props.widgets}
          onSubmit={this.onSubmit}
          formData={(submissionData && submissionData.formData) || {}}
          ObjectFieldTemplate={ObjectFieldTemplate}
          noHtml5Validate={true}
          noValidate={true}
          id="previewFormContent"
          {...this.getChildren()}
        />
        {this.renderFooter()}
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className="footerContainer">
        <div className="footerBorder" />
        <div className="poweredByContainer">
          <Typography variant="body1" className="mgLeft12 mgTop2 textPowered">
            Powered
          </Typography>
          <Typography variant="body1" className="mgLeft4 mgTop2 textBy">
            by
          </Typography>
        </div>
        <img
          alt="FortisForma Logo"
          className="fofLogo mgLeft8 mgRight12"
          src="/assets/img/text-logo.png"
        />
        <div className="footerBorder" />
      </div>
    );
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.closePreviewForm()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  render() {
    return (
      <>
        <DialogContent className="createFormModalContent">
          {this.renderCloseButton()}
          {this.renderDialogContentData()}
        </DialogContent>
      </>
    );
  }
}
