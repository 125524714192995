import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import { INVALID_LOGIN_REDIRECT_URL } from "../../constants";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  margin-top: 16px !important;
`;

const TIMER = 8000;

export default class Welcome extends Component {
  state = {
    remaining: TIMER / 1000,
  };

  componentDidMount() {
    this.startTimer();
  }
  startTimer(timer = TIMER) {
    this.timer = setTimeout(() => {
      this.routeToHome();
    }, timer - 500);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, timer / (timer / 1000));
  }

  routeToHome = () => {
    window.location.href = INVALID_LOGIN_REDIRECT_URL;
    return;
  };

  render() {
    return (
      <div className="pageContainer flexCenter flexColumn">
        <Typography variant="h4" align="center">
          Sorry this is the coaches section, we'll redirect you to your
          exercises momentarily.
        </Typography>
        {this.state.remaining > 0 && (
          <StyledTypography className="mg-tp-16">
            Redirecting to client web app in {this.state.remaining} seconds.
          </StyledTypography>
        )}

        <Button
          id="waitingAction"
          variant="outlined"
          color="primary"
          onClick={() => {
            this.routeToHome();
          }}
          className="mg-tp-16 "
        >
          Go to Client Web App
        </Button>
      </div>
    );
  }
}
