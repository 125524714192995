import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React from "react";
export default function ProgramCompleteMessageEditor(props) {
  let [message, onChange] = React.useState(props.message || "");

  React.useEffect(() => {
    onChange(props.message);
  }, [props.message]);

  return (
    <Dialog open={props.open} aria-labelledby="message-dialog">
      <DialogTitle id="message-dialog">
        Message after program is complete
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          required
          label="Message"
          placeholder="Program is complete, please contact your trainer"
          value={message}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onChange(message);
          }}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
