import { Typography, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import React from "react";
import ErrorItem from "./errorItem";


export default function ErrorModal(props) {
	const sortedErrors = props.errors.sort((error1, error2) => { return error1.row- error2.row });

	const renderLoader = () => {
    return (
        <CircularProgress size={16} style={{marginRight: "8px"}}/>
    );
  }

  return (
    <>
      <DialogTitle>Bulk Upload Errors</DialogTitle>
      <DialogContent>
				{ props.loading &&
					<div className="errorModalLoader">
							{renderLoader()} 
						<Typography variant="body1">
							Uploading
						</Typography>
					</div>
				}
        {sortedErrors.map((error, index) => {
          return (
            <div key={index}>
              <div className="errorModalItem displayFlex">
                <Typography variant="body1" style={{display: "flex"}}>
									Row {error.row + 2}: <ErrorItem messages={error.errMessages}/>
								</Typography>
              </div>
              <Divider className="fullWidth" />
            </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </>
  );
}
