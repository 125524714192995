import React from "react";
import ExerciseCard from "../exerciseList/exerciseCard";
import { BubbleLoader } from "react-css-loaders";
import { Button } from "reactstrap";
import Factory from "../../modules/http/dataFetcher";
import bodybuilder from "bodybuilder";

const centerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const cardStyle = {
  position: "relative",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: 220,
  height: 320,
};

export default class SimilarExercisesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastLoaded: null,
    };

    this.exerciseFetcher = Factory.exerciseFetcher();
    this.loadSimilarExercises = this.loadSimilarExercises.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    let target = this.props.target;
    this.loadSimilarExercises(target.position, target.exercise);
  };

  async loadSimilarExercises(index, exercise) {
    this.setState({
      similarLoading: true,
    });
    let filters = bodybuilder();
    // filters.query("term", "movementId", exercise.movementId);
    if (this.props.user.enterpriseId) {
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "enterpriseId", this.props.user.enterpriseId)
          .orFilter("term", "trainerId", "admin")
      );
    } else {
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "trainerId", this.props.user.id)
          .orFilter("term", "trainerId", "admin")
      );
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", this.props.user.id)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
        );
      }
    }
    let query = this.convertToScoreQuery(filters.build(), exercise);
    try {
      let results = await this.exerciseFetcher.fetch(query);
      results = results.filter((item) => item.id !== exercise.id);
      let data = (this.state.similarExercises || []).slice();
      for (let datum of results) {
        data.push(datum);
      }

      let groups = window._.groupBy(data, "level");
      let newData = [];

      let sortedKeys = Object.keys(groups);
      sortedKeys.sort((a, b) => {
        let diffA = Math.abs(exercise.level - Number(a));
        let diffB = Math.abs(exercise.level - Number(b));

        if (diffA === diffB) {
          return Number(a) - Number(b);
        }

        return diffA - diffB;
      });

      for (let level of sortedKeys) {
        let values = groups[level];
        values.sort((a, b) => b._score - a._score);
        newData = newData.concat(values);
      }

      /* can we prioritize the exercises showing by the following priorities - 
            same movement with different equipment, 
            same movement category with same muscles and closest level, 
            add same function first
            then same movement category exercises
            */
      this.setState({
        similarExercises: newData,
        similarLoading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        similarLoading: false,
      });
    }
  }

  createPermutations(exerciseData, key, weight) {
    let keys = Object.keys(exerciseData[key]);
    let functions = [];
    for (let innerKey of keys) {
      if (!exerciseData[key][innerKey]) {
        continue;
      }
      for (let _innerKey of keys) {
        let data = exerciseData[key][_innerKey];
        if (!data) {
          continue;
        }
        functions.push({
          filter: { match: { [`${key}.${innerKey}`]: data } },
          weight: weight,
        });
      }
    }
    return functions;
  }

  convertToScoreQuery(query, exercise) {
    let functions = [];

    functions.push({
      filter: {
        match: {
          movement: exercise.movement,
        },
      },
      weight: 0.01,
    });

    functions.push({
      filter: {
        match: {
          movementCategory: exercise.movementCategory,
        },
      },
      weight: 0.01,
    });

    functions.push({
      linear: {
        level: {
          origin: exercise.level,
          scale: 1,
          decay: 0.1,
        },
      },
    });

    functions = functions.concat(
      this.createPermutations(exercise, "muscleGroups", 0.005)
    );
    functions = functions.concat(
      this.createPermutations(exercise, "functionCategories", 0.01)
    );

    let functionScore = {
      function_score: {
        ...query,
        boost: 1,
        functions: functions,
        score_mode: "sum",
        boost_mode: "replace",
      },
    };

    return {
      query: functionScore,
    };
  }

  render() {
    if (this.state.similarLoading) {
      return (
        <div style={centerStyles}>
          <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
        </div>
      );
    }
    if (!this.state.similarExercises || !this.state.similarExercises.length) {
      return <div style={centerStyles}>No Similar Exercises Found</div>;
    }
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          overflowY: "scroll",
          flexDirection: "column",
        }}
      >
        {this.state.similarExercises.map((data) => {
          return (
            <div style={{ paddingBottom: 4 }} key={data.id}>
              <ExerciseCard
                cardType={"similarExercise"}
                klassName={"small"}
                key={data.id}
                showBadge={false}
                videoWidth={220}
                videoHeight={220}
                cardStyle={cardStyle}
                data={data}
                selectEnabled={true}
                onSelect={this.props.onSimilarExerciseSelect}
                selectButtonView={"Select"}
              />
            </div>
          );
        })}

        <Button className="loadMoreButton" onClick={this.loadData}>
          Load More
        </Button>
      </div>
    );
  }
}
