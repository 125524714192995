import WorkoutSummary from "./workoutSummary";
import { COLLECTIONS, DB_KEYS } from "../../constants";

export default class EnterpriseWorkoutSummary extends WorkoutSummary {
  _createQuery() {
    let filters = [
      {
        key: DB_KEYS.CLIENT_ID,
        operator: "==",
        value: this.state.clientId,
      },
      {
        key: DB_KEYS.ENTERPRISE_ID,
        operator: "==",
        value: this.props.user.enterpriseId,
      },
    ];

    return {
      collection: COLLECTIONS.WORKOUT_SUMMARY,
      filters,
    };
  }
}
