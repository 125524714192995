import { TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

  
export default function PhoneFieldWidget(props) {
    const { value, onChange, schema, required} = props;
    const getLabel = () => {
        return `${schema.title}${required ? "*" : ""}`
    }

    const onChangePhone = (event) => {
        const formattedValue = window.formatPhone(event.target.value);
        onChange(formattedValue);
    }

    return (
        <NumberFormat
            format="+1 ### ### ####"
            isNumericString={true}
            type="tel"
            customInput={TextField}
            style={{width: "100%"}}
            value={value}
            label={getLabel()}
            onChange={onChangePhone}
            placeholder="e.g. +1 999 999 9999"
        />
    )
}
