import React, { Component } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { NavLink } from "react-router-dom";

import { isEmail } from "validator";

import {
  DB_KEYS,
  ERROR_CODES,
  LOCAL_STORAGE_KEYS,
  ROLES,
} from ".././../constants";

import PasswordInput from "../../components/passwordInput/passwordInput";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginButtonEnabled: true,
      emailInvalid: false,
    };

    this._isMounted = true;

    this.onChangeEmail = this.onChange.bind(this, "email");
    this.onChangePassword = this.onChange.bind(this, "password");
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("keypress", this.handleKeyPress);
    let email = window.localStorage.getItem(DB_KEYS.LAST_USER_EMAIL);
    if (email) {
      this.setState({
        email: email,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.user && !this.state.loginButtonEnabled) {
      this.setState({
        loginButtonEnabled: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update);
  }

  login(e) {
    try {
      window.FortisForma.database.signOut();
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }

    if (e.nativeEvent) {
      e.nativeEvent.stopPropagation();
    }
    let { email, password } = this.state;

    if (!email) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector("#email"),
        "email",
        "Invalid email"
      );
      return;
    }

    if (!password) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector("#password"),
        "password",
        "Invalid password"
      );
      return;
    }

    this.setState({
      loginButtonEnabled: false,
    });

    window.localStorage.removeItem(DB_KEYS.SIGN_UP_STATE);

    window.FortisForma.database
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        try {
          // await window.FortisForma.database.checkTier();
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.TRIALMODAL,
            JSON.stringify(true)
          );
          window.setEmailInLocalStorage(email);
          const claims = await window.FortisForma.database.getCustomClaims();
          if (
            claims.role === ROLES.HEALTH_COACH ||
            claims.role === ROLES.FACILITY_ADMIN
          ) {
            this.setState({
              loginButtonEnabled: true,
            });
          }
          const result = window.hasMFA(claims);
          if (!result) {
            await window.FortisForma.database.updateUserLoginTime();
          }
          // if (
          //     claims.role !== ROLES.HEALTH_COACH ||
          //     claims.role !== ROLES.FACILITY_ADMIN
          // ) {
          //     await window.FortisForma.database.stripeCallForNewUser();
          // }

          // await this.props.onUserChange();
        } catch (e) {
          console.error(e);
          window.NotificationUtils.showError("Something went wrong!");
          this.setState({
            loginButtonEnabled: true,
          });
        }
      })
      .catch((error) => {
        this.showError(error);
        window.NotificationUtils.showError("Something went wrong!");

        this.setState({
          loginButtonEnabled: true,
        });
      });
  }

  forgotPassword() {
    if (!this.isValidEmail()) {
      return;
    }
    window.FortisForma.database
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        this.onSuccessForgotPass();
      })
      .catch((error) => {
        if (error.code === ERROR_CODES.USER_NOT_FOUND) {
          return this.onSuccessForgotPass();
        }
        this.showError(error);
      });
  }

  onSuccessForgotPass() {
    window.NotificationUtils.showSuccess("Successfully sent reset email");
  }

  isValidEmail() {
    if (this.state.email && isEmail(this.state.email)) {
      return true;
    }
    window.NotificationUtils.showError("Email is not valid");
    return false;
  }

  showError(error) {
    console.error(error);

    switch (error.code) {
      case ERROR_CODES.USER_DISABLED:
        window.NotificationUtils.showError(
          "Your account is blocked",
          null,
          "bottomCenter"
        );
        break;

      case ERROR_CODES.TOO_MANY_ATTEMPTS:
        window.NotificationUtils.showError(
          "Too many failed login attempts, please wait for few minutes now",
          null,
          "bottomCenter"
        );
        break;

      default:
        window.NotificationUtils.showError(
          "Invalid email or password",
          null,
          "bottomCenter"
        );
        console.error(error);
    }
  }

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      this.login(evt);
    }
  };

  setEmailValid = (isValid) => {
    if (isValid === true) {
      this.setState({ emailInvalid: false });
      return;
    }

    if (isEmail(this.state.email) === false) {
      this.setState({ emailInvalid: true });
      return;
    }
    this.setState({ emailInvalid: false });
  };

  render() {
    return (
      <Container className="loginBody" id="loginForm">
        {this.state.loginButtonEnabled ? (
          <React.Fragment>
            <Row>
              <Col className="center">
                <img
                  alt="FortisForma Logo"
                  className="logo"
                  src="/assets/img/logo.png"
                />
              </Col>
            </Row>
            <Card className="loginCard">
              <CardBody>
                <Form autoComplete="off">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      style={{ marginBottom: 8 }}
                      placeholder="someone@example.com"
                      type="email"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      autoComplete="off"
                      onBlur={this.setEmailValid}
                      onFocus={() => this.setEmailValid(true)}
                      valid={isEmail(this.state.email)}
                      invalid={
                        this.state.email !== "" && this.state.emailInvalid
                      }
                    />
                    <FormFeedback invalid={""}>
                      Please enter a valid email
                    </FormFeedback>
                  </FormGroup>

                  <PasswordInput
                    showIndicator={false}
                    style={{ marginTop: 20 }}
                    label={"Password"}
                    name="password"
                    inputId="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: -12,
                    }}
                  >
                    <div
                      onClick={this.forgotPassword}
                      className="forgotPasswordButton"
                    >
                      Forgot Password ?
                    </div>
                  </div>
                  <div className="center" style={{ marginTop: 20 }}>
                    <Button
                      disabled={this.state.loginButtonEnabled === false}
                      onClick={this.login}
                      color="primary"
                    >
                      Login
                    </Button>
                    <NavLink to={"/signup"} className="nav-link mt-3">
                      Don't have an account? Sign Up
                    </NavLink>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </React.Fragment>
        ) : (
          this.progressIndicator()
        )}
      </Container>
    );
  }
}
