import { CircularProgress, Dialog } from "@material-ui/core";
import React, { Component } from "react";
import PreviewForm from "../../components/forms/previewFormModal";
import SignatureField from "../../components/signatureField/signatureField";
import PDFViewer from "../../components/forms/pdfViewer";
import DatePicker from "../../components/datePickerWidget/datePickerWidget";
import PhoneField from "../../components/phoneFieldWidget/phoneFieldWidget";
import TAButton from "../../components/taButton/taButton";
import SlidingPane from "../../components/slidingPane/slidingPane";
import NoteAssessmentVersions from "./NoteAssessmentVersions";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { FORM_VERSION_EXPORT_LINK } from "../../constants";
import { addFormToNoteAssessment } from "../../utils/formUtils";
import FreehandNote from "../../components/freehandNoteWidget/freehandNote";
import AddImageWidget from "../../components/addImageWidget/addImageWidget";

export const getClientFormsdata = (forms, clientId, formType, clientEmail) => {
  let clientFormsData = {
    forms: forms,
    clientId: clientId,
    formType: formType,
    email: clientEmail,
  };
  return clientFormsData;
};

export const updateClientForms = async (
  submissionData,
  noteData,
  clientForms,
  formType
) => {
  try {
    if (
      !submissionData ||
      !noteData ||
      (noteData && (!noteData.formId || !noteData.submissionId)) ||
      !clientForms
    ) {
      throw new Error("Invalid submission data");
    }
    let formData = {
      filled: Boolean(submissionData.total === submissionData.submitted),
      formId: noteData.formId,
      submissionId: noteData.submissionId,
    };
    let forms = JSON.parse(JSON.stringify(clientForms));
    let formIndex = forms.findIndex((form) => form.formId === noteData.formId);

    if (formIndex === -1) {
      forms.push(formData);
    } else {
      forms[formIndex] = formData;
    }

    let clientFormsData = getClientFormsdata(
      forms,
      noteData.clientId,
      formType,
      submissionData.clientEmail
      // this.props.clientEmail
    );
    await window.FortisForma.database.updateClientFormsData(clientFormsData);
  } catch (e) {
    throw e;
  }
};

export default class NoteAssessmentCommon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: {},
      data: [],
      form: {},
      clientForms: [],
      showFormsModal: false,
      previewForm: false,
      disableLoadMore: true,
      loading: false,
      loadingMore: false,
      lastFetched: null,
      openEditor: false,
      selectedItemId: null,
    };
    this.editorRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      this.fetchClientForms();
      this.fetchData();
      this.shouldOpenEditor();
    });
  };

  shouldOpenEditor = () => {
    if (this.props.shouldOpenEditor) {
      this.openEditor();
    }
  };

  fetchClientForms = async () => {
    let data = {
      clientId: this.props.clientId,
      email: this.props.clientEmail || "",
    };
    try {
      const forms = await window.FortisForma.database.fetchClientForms(data);

      const clientForms = this.filterClientForms(forms);

      if (clientForms && clientForms.length) {
        this.setState({ clientForms: clientForms });
      }
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      console.error(e);
    }
  };

  onItemSaved = (result, selectedItem) => {
    let dataCopy = Object.assign([], this.state.data);

    if (selectedItem && Object.keys(selectedItem).length) {
      const index = dataCopy.findIndex((item) => item.id === selectedItem.id);
      dataCopy.splice(index, 1);
    }

    dataCopy.unshift(result);
    this.setState(
      {
        data: dataCopy,
        selectedItem: {},
        lastFetched: dataCopy[dataCopy.length - 1],
      },
      this.fetchClientForms
    );
  };

  openEditor = () => {
    this.setState({
      openEditor: true,
    });
  };

  onClickEdit = (data) => {
    this.setState({
      selectedItem: data,
      openEditor: true,
    });
  };

  handleCloseEditor = () => {
    this.setState({
      openEditor: false,
      selectedItem: {},
    });
  };

  onCloseEditor = () => {
    if (this.editorRef.current && this.editorRef.current.confirmClose) {
      return this.editorRef.current.confirmClose(this.handleCloseEditor);
    }
  };

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  openFormsModal = () => {
    this.setState({ showFormsModal: true });
  };

  closeFormsModal = () => {
    this.setState({ showFormsModal: false });
  };

  onClickPreview = (form) => {
    this.setState({
      form: form,
      previewForm: true,
      showFormsModal: false,
      isClientForm: Boolean(form.clientId),
    });
  };

  closePreviewForm = () => {
    this.setState({
      previewForm: false,
      form: {},
      isClientForm: false,
      showFormsModal: Boolean(!this.state.isClientForm),
    });
  };

  openSidePanel = (itemId, collection, currentItemCreator = null) => {
    this.setState({
      selectedItemId: itemId,
      collection,
      form: {},
      previewForm: false,
      formLocked: false,
      openEditor: false,
      selectedItem: currentItemCreator
        ? _.assignIn(this.state.selectedItem, {
            creator: currentItemCreator,
          })
        : this.state.selectedItem,
      //For the first note item
    });
  };

  closeSidePanel = () => {
    this.setState({
      selectedItemId: null,
      collection: "",
      selectedItem: {},
    });
  };

  fetchNoteAssessmentArchives = async (data, isMultiple, collection) => {
    try {
      let archives = [];
      if (isMultiple) {
        archives = await window.FortisForma.database.fetchArchives(
          data.id,
          collection
        );
        if (Array.isArray(archives)) {
          archives.unshift(data);
        }
      } else {
        archives = [data];
      }
      return archives;
    } catch (error) {
      throw error;
    }
  };

  appendCreatorsDetailInArchives = async (archives) => {
    try {
      const archivesWithCreators = _.cloneDeep(archives);
      if (!_.isEmpty(archivesWithCreators)) {
        const userIds = [];
        _.map(archivesWithCreators, (version) => {
          if (_.has(version, "creatorId")) {
            userIds.push(version.creatorId);
          }
        });

        if (!_.isEmpty(userIds)) {
          let res =
            await window.FortisForma.database.fetchEnterpriseStaffDetails(
              userIds
            );
          if (!_.isEmpty(res)) {
            _.map(archivesWithCreators, (note, key) => {
              _.map(res, (creator) => {
                if (note.creatorId === creator.id) {
                  archivesWithCreators[key] = {
                    ...note,
                    creator: creator,
                  };
                }
              });
            });
          }
        }
      }
      return archivesWithCreators;
    } catch (error) {
      throw error;
    }
  };

  getFormVersionURL = (formId, submissionId = "", creatorId) => {
    return `${FORM_VERSION_EXPORT_LINK}?${
      this.props.clientLoginPending ? "1" : "0"
    }_${formId}_${submissionId ? submissionId : ""}_${
      creatorId ? creatorId : ""
    }`;
  };

  getPDFData = async (archives, exportAllVersions) => {
    try {
      let enterpriseData = {};
      enterpriseData = window.getEnterpriseData();

      const notesWithCreators = await this.appendCreatorsDetailInArchives(
        archives
      );
      const sortedData = notesWithCreators.sort(
        (item1, item2) =>
          window.getTimestampSeconds(item2.updatedTime) -
          window.getTimestampSeconds(item1.updatedTime)
      );
      let formattedData = [];
      if (exportAllVersions) {
        formattedData = sortedData.map((note) =>
          this.formatData(note, exportAllVersions)
        );
      } else {
        formattedData = this.formatData(sortedData[0]);
        const form = await addFormToNoteAssessment(sortedData[0]);
        if (form) {
          formattedData.form = form;
        }
      }
      const mainData = exportAllVersions
        ? { versions: formattedData }
        : formattedData;
      const pdfData = {
        enterpriseData,
        clientData: {
          name: this.props.clientName,
          email: this.props.clientEmail,
          dob: this.props.clientDOB,
          enterpriseName: enterpriseData ? enterpriseData.enterpriseName : "",
        },
        mainData,
      };
      return pdfData;
    } catch (e) {
      throw e;
    }
  };

  renderVersisons = () => {
    return (
      <NoteAssessmentVersions
        itemId={this.state.selectedItemId}
        collection={this.state.collection}
        onClickViewForm={this.onClickPreview}
        selectedItem={this.state.selectedItem}
        onExport={this.handleSavePDF}
      />
    );
  };

  renderVersions = () => {
    return (
      <SlidingPane
        open={Boolean(this.state.selectedItemId)}
        renderPaneData={this.renderVersisons}
        onClose={this.closeSidePanel}
        KlassName="versionsPane"
        title={this.getSidePanelTitle()}
        id="versions-pane"
      />
    );
  };

  onFormLock = () => {
    this.setState({ formLocked: true });
  };

  renderPreviewFormModal = () => {
    const showSubmit = Boolean(
      this.state.form &&
        this.state.isClientForm &&
        this.state.form.isFormEditable
    );
    return (
      <Dialog
        fullScreen={isMobile ? true : false}
        fullWidth={true}
        onClose={this.closePreviewForm}
        open={this.state.previewForm}
        maxWidth="md"
      >
        <PreviewForm
          closePreviewForm={this.closePreviewForm}
          data={this.state.form}
          widgets={{
            signatureField: SignatureField,
            pdfViewer: PDFViewer,
            DateWidget: DatePicker,
            phoneField: PhoneField,
            freehandNote: FreehandNote,
            addImageWidget: AddImageWidget,
          }}
          submissionData={this.state.form.submissionData || {}}
          onSubmit={showSubmit && this.onSubmit}
        />
      </Dialog>
    );
  };

  renderLoadMoreButton = () => {
    if (this.state.disableLoadMore) {
      return;
    }
    return (
      <TAButton
        className="loadMoreAssessmentButton"
        onClick={() => this.fetchData(true)}
        isLoading={this.state.loadingMore}
        color="primary"
      >
        Load More
      </TAButton>
    );
  };

  renderLoading = () => {
    if (this.state.loading) {
      return (
        <div className="assessmentLoadingContainer">
          <CircularProgress />
        </div>
      );
    }
  };

  scrollToTop = () => {
    const divElement = document.getElementById("assessmentListContainer");
    if (divElement) {
      divElement.scrollTo(0, 0);
    }
  };

  render() {
    return (
      // <div className="noteAssessmentContainer">
      //   {this.props.clientLoginPending ? (
      //     <h2 style={{ textAlign: "center" }}>
      //       Not available for pending clients. Send invite to client for
      //       registeration on portal.
      //     </h2>
      //   ) : (
      <>
        {this.renderAddButton()}
        {this.renderEditor()}
        {this.renderPreviewFormModal()}
        {this.renderLoadMoreButton()}
        {this.renderItemsList()}
        {this.renderExtras()}
        {this.renderVersions()}
      </>
      // )}
      // </div>
    );
  }
}
