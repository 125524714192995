import React from "react";
import NoteAssessmentList from "./noteAssessmentList";
import AssessmentItem from "../noteAssessmentItem/assessmentItem";

export default class AssessmentsList extends NoteAssessmentList {
  renderEmptyPlaceholder = () => {
    return this.props.renderEmptyPlaceholder();
  };

  renderItem = (item) => {
    return (
      <AssessmentItem
        data={item}
        onEdit={this.props.onEdit}
        onExport={this.props.onExport}
        onClickPreview={this.props.onClickPreview}
      />
    );
  };
}
