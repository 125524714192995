import React, { Component } from 'react';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

export default class CustomAlert extends Component {

  render() {
    return (
      <Modal isOpen={this.props.show} backdrop={true} className="fullPageModal">
        <ModalBody style={{height: '100%', width: "100%"}}>
          {this.props.message}
        </ModalBody>
        {this.props.disableActions && <ModalFooter>
           <Button className="btn btn-outline-primary" onClick={this.props.onClose}>Close</Button>
         </ModalFooter>}
      </Modal>
    )
  }

}
