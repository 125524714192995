// import jsPDF from "jspdf";
// import Logo from "../../assets/logo.png";
// // Create Document Component
// const handleReceiptGeneration = async (data) => {
//   const doc = new jsPDF();
//   doc.addImage(Logo, "PNG", 60, 10, 90, 20);
//   await doc.save("receipt.pdf");
// };

// export default handleReceiptGeneration;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: "120px",
    margin: "0 auto",
  },
});

// Create Document Component
const MyDocument = (
  { data } //
) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image src={"/assets/img/logo.png"} style={styles.logo} />
      <View style={styles.section}>
        <Text>{"data.something"}</Text>
      </View>
      <View style={styles.section}>
        <Text>{"data.something"}</Text>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
