import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Input,} from 'reactstrap';



export default class SendMessageModal extends Component {
    constructor(props){
        super(props)
        this.state={
            textMessage: "",

        }
        this.onChangeMessage = this.onChange.bind(this, "textMessage");
    }

    //TODO:- Create sendMessage function
    onChange(key, event, value) {
        let update = {};
        update[key] = event.target.value || (value ? value.newValue : "");
        update['change'] = true;
        this.setState(update);
    }

    sendMessage = () => {
        this.props.sendMessage(this.state.textMessage);
    }

    resetMessage = () => {
        this.setState({textMessage: ""});
    }

    

    renderModalContent(){
        return(
            <ModalBody>
                <div>
                    <div>
                        <FormGroup>
                            <Input className="textareaField" type="textarea" style={{paddingHorizontal: 0}} value={this.state.textMessage} placeholder="(Max Limit 300 Words)" onChange={this.onChangeMessage} maxLength={300}/> 
                        </FormGroup>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: 16}}>
                        <div>
                            <Button className="btn btn-outline-secondary btn btn-secondary" onClick={this.props.closeModal}>Close</Button>
                        </div>
                        <div>
                            <Button onClick={this.sendMessage}>Send</Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.modalState} id="changePasswordModal" backdrop={true}>
                    <ModalHeader>Message to <span style={{fontWeight: "800"}}>{this.props.clientName}</span></ModalHeader>
                    {this.renderModalContent()}
                </Modal>
            </React.Fragment>
        )
    }
}