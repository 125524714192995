import moment from "moment";
import React, { Component } from "react";
import {
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  WORKOUT_SECTIONS_NAMES,
} from "../../constants";
import SectionedWorkout from "../../components/workoutSession/sectionedWorkouts";
import memoize from "memoize-one";
import Spinner from "@material-ui/core/CircularProgress";
import restDay from "../../assets/rest.svg";
import ArrowBack from "@material-ui/icons/ArrowBack";
import MoreVert from "@material-ui/icons/MoreVert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import WorkoutCompleteModal from "../../components/workoutSession/completeModal";
import ChooseWorkout from "../../components/workoutSession/chooseWorkout/chooseWorkout";

const NO_WORKOUT_MESSAGE = "No workout assigned for this period";

const resistanceValueMap = {
  zXTRA_LIGHT: 1,
  LIGHT: 2,
  MEDIUM: 3,
  HEAVY: 4,
  EXTRA_HEAVY: 5,
};

const valueResistanceMap = {
  1: "EXTRA_LIGHT",
  2: "LIGHT",
  3: "MEDIUM",
  4: "HEAVY",
  5: "EXTRA_HEAVY",
};

const sorter = (a, b) => Number(a) - Number(b);

function isOldLogType(log = {}) {
  try {
    let keys = Object.keys(log);
    if (keys.length > 0) {
      let loggedValue = log[keys[0]] || {};
      if (
        loggedValue.hasOwnProperty("exerciseId") ||
        loggedValue.hasOwnProperty("exercise")
      ) {
        return true;
      }
    }
  } catch (e) {
    console.error(e);
    return false;
  }
  return false;
}

function getCurrentWorkoutIndex(
  workoutStartDate,
  today,
  daysConfig = { total: 7, repeating: false }
) {
  let day;
  if (workoutStartDate) {
    day = moment.unix(workoutStartDate);
  } else {
    day = moment();
  }

  day = day.startOf("day");
  today = today || moment();
  today = today.startOf("day");

  let repeating = Boolean(daysConfig.repeating);

  let diff = today.diff(day, "days");
  if (repeating) {
    diff = diff % daysConfig.total;
  }
  return Math.abs(diff);
}

export default class WorkoutSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logId: null,
      logExercise: null,
      clientDetails: null,
      loading: true,
      noWorkout: false,
      workoutComplete: false,
      showCalender: true,
      calenderVisible: true,
      exerciseOrder: [],
      workoutLog: {},
      currentExerciseLevel: {},
      levelsLoadedMap: {},
      workoutName: "",
      exerciseName: "",
      exercises: {},
      workoutSummary: {},
      log: {
        sets: "",
        reps: "",
        weight: "",
        resistance: "",
        time: "",
      },
      enabled: {
        sets: false,
        reps: false,
        weight: false,
        resistance: false,
        time: false,
      },
      paramsModalVisible: false,
      showCompleteModal: false,
      sectionsExpanded: [],
      showWorkoutStartMessageModal: false,
      showChooseWorkoutModal: false,
      workoutStartMessage: null,
    };
    this.currentDayUnix = moment().startOf("day").unix();
    this.todayUnix = moment().startOf("day").unix();
    this.programData = null;
    this.workoutId = null;
    this.assignedExercises = null;
    this.freshLogID = null;
    this.workoutSummary = null;
    this.sectionedWorkoutRef = React.createRef();
    this.dayIndex = null;
  }

  componentDidMount() {
    this.restoreStateFromStorage();
  }

  restoreStateFromStorage = () => {
    try {
      let sessionDetails = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.WORKOUT_SESSION_DETAILS
      );

      if (sessionDetails) {
        sessionDetails = JSON.parse(sessionDetails);
        if (!sessionDetails.programId) {
          return this.routeToDashboard();
        }
        this.setState({ clientDetails: sessionDetails }, this.fetchData);
      }
      if (!sessionDetails) {
        return this.routeToDashboard();
      }
    } catch (e) {
      console.error(e);
    }
  };

  routeToDashboard = () => {
    this.props.history.replace("/dashboard");
  };

  fetchData = async () => {
    try {
      await this.handleProgramStartDate();
      await this.fetchWorkout();
      await this.fetchWorkoutSummary(this.getData);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  handleProgramStartDate = async () => {
    let { id, programId, pendingLogin, programStartDate } =
      this.state.clientDetails && this.state.clientDetails;
    if (!programStartDate) {
      try {
        this.programStartDate = moment().startOf("day").unix();
        await window.FortisForma.database.changeWorkoutStartDate(
          id,
          programId,
          pendingLogin
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      this.programStartDate = programStartDate;
    }
  };

  fetchWorkout = async () => {
    const { clientDetails } = this.state;
    this.setState({
      loading: true,
    });
    try {
      let result = await window.FortisForma.database.getUserProgram(
        clientDetails.id,
        clientDetails.pendingLogin
      );
      this.programData = result;
      let daysConfig = { total: 7, repeating: false };
      if (result && result.workoutDaysMap) {
        daysConfig.total = Object.keys(result.workoutDaysMap).length;
      }
      if (result.repeating) {
        daysConfig.repeating = true;
      }
      let dayIndex = getCurrentWorkoutIndex(
        this.programStartDate,
        moment(),
        daysConfig
      );
      this.dayIndex = dayIndex;

      if (dayIndex < 0) {
        this.setState({
          noWorkout: true,
          showCalender: false,
          calenderVisible: false,
          loading: false,
        });
        return window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
      }
      this.setWorkoutDayIndex(dayIndex);
    } catch (error) {
      if (error.code && error.code === 404) {
        window.NotificationUtils.showError(error.message);
      } else {
        console.error(error);
      }
      this.setState({
        loading: false,
      });
    }
  };

  setWorkoutDayIndex = (index, callback) => {
    this.setState({
      loading: false,
    });

    if (!this.programData) {
      window.NotificationUtils.showError(NO_WORKOUT_MESSAGE);
    }
    let workoutId = this.programData.workoutDaysMap[index || 0];
    if (!workoutId) {
      this.setState({
        exerciseOrder: [],
        workoutIndex: index,
      });
      return;
    }
    this.setState({
      exerciseOrder: [],
      workoutLog: {},
      currentExerciseLevel: {},
      levelsLoadedMap: {},
      workoutIndex: index || 0,
    });

    const setupLogs = (workout) => {
      if (this.pendingLogSetup) {
        this.setupTodayWorkoutLog(this.pendingLogSetup[workoutId]);
        this.pendingLogSetup = null;
      }
      // TODO PENDINGLOGSETUP
      callback && callback(workout);
      // TODO CHANGE WORKOUT
    };
    if (workoutId) {
      this.workoutId = workoutId;
      let workout = this.programData.workoutDataMap[workoutId];
      if (workout) {
        try {
          this.setWorkout(workout, () => {
            setupLogs(workout);
          });
        } catch (e) {
          console.error(e);
          window.NotificationUtils.showError(
            "Something went wrong on our side, error reported!"
          );
        }
      } else {
        setupLogs();
        console.error("Missing workout data for id", workoutId);
        window.NotificationUtils.showError(
          "Something went wrong on our side, error reported!"
        );
      }
    } else {
      this.setState({
        noWorkout: true,
        showCalender: false,
        calenderVisible: false,
      });
    }
  };

  setWorkout(newWorkout, callback) {
    let workout = Object.assign({}, newWorkout);
    this.setState({
      workoutSections: workout.workoutSections,
    });
    let exercises = this.mapWorkoutToExercises(workout);
    this.workoutId = workout.id;

    let keys = Object.keys(exercises);
    let assignedLevels = {};
    this.assignedExercises = workout.exercises || [];

    for (let index of keys) {
      let exerciseList = exercises[index];
      let assigned = Object.values(exerciseList);
      assignedLevels[index] = Number(assigned[0].level);
    }

    this.assignedLevels = assignedLevels;

    let order = Object.keys(exercises);
    (order || []).sort(sorter);
    this.setState(
      {
        workoutName: workout.name || "",
        exercises: exercises,
        exerciseOrder: order,
        currentExerciseLevel: assignedLevels,
      },
      callback
    );
  }

  mapWorkoutToExercises = (workout = {}) => {
    if (workout.exercises) {
      workout.exercises = workout.exercises.map((exercise, index) => {
        exercise.exerciseIndexInSection = index;
        exercise.workoutType = WORKOUT_SECTIONS_NAMES.CIRCUIT;
        exercise.section = 0;
        return exercise;
      });
      let workoutSections = [
        {
          workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
          set: 1,
          exercises: workout.exercises,
        },
      ];
      this.setState({ workoutSections });
    } else {
      let exercises = [];
      for (let sIndex = 0; sIndex < workout.workoutSections.length; sIndex++) {
        let section = workout.workoutSections[sIndex];
        let sExercises = section.exercises;
        sExercises = sExercises.map((exercise, index) => {
          exercise.exerciseIndexInSection = index;
          exercise.workoutType = section.workoutType;
          exercise.section = sIndex;
          return exercise;
        });
        exercises = exercises.concat(sExercises);
        workout.exercises = exercises;
      }
    }
    return this.mapWorkoutToExercisesOld(workout);
  };

  mapWorkoutToExercisesOld = (workout = {}) => {
    let exercises = {};
    let sortedExercises = (workout.exercises || []).sort((a, b) => {
      return Number(a.index) - Number(b.index);
    });
    let originalIndex = 0;
    for (let exercise of sortedExercises) {
      if (!exercises[originalIndex]) {
        exercises[originalIndex] = {};
      }
      exercises[originalIndex][Number(exercise.level)] = exercise;
      originalIndex++;
    }
    return exercises;
  };

  getMaxValues = (loggedExercise, defaultExercise) => {
    let maxValues = {};
    maxValues.sets = Math.max(loggedExercise.sets, defaultExercise.sets);
    maxValues.reps = Math.max(loggedExercise.reps, defaultExercise.reps);
    maxValues.time = Math.max(loggedExercise.time, defaultExercise.time);
    maxValues.weight = Math.max(loggedExercise.weight, defaultExercise.weight);
    maxValues.resistance =
      valueResistanceMap[
        Math.max(
          resistanceValueMap[loggedExercise.resistance],
          resistanceValueMap[defaultExercise.resistance]
        )
      ];
    return maxValues;
  };

  getData = memoize(
    (exerciseOrder, exercises, currentExerciseLevel, workoutLogs) => {
      let mapped = exerciseOrder
        .map((index) => {
          let exerciseList = exercises[index];
          let selectedLevel = currentExerciseLevel[index];
          if (!selectedLevel) {
            selectedLevel = 0;
          }

          let exercise = exerciseList[selectedLevel];
          if (!exercise) {
            return { index };
          }

          let assigned = this.assignedExercises[index];
          if (!assigned) {
            console.error(
              `Missing assigned exercises index ${index}, assigned keys, ${Object.keys(
                this.assignedExercises
              )}`
            );
            return { index, exclude: true };
          }

          if (assigned.id !== exercise.id) {
            exercise.reps = assigned.reps;
            exercise.sets = assigned.sets;
            exercise.weight = assigned.weight;
            exercise.time = assigned.time;
            exercise.resistance = assigned.resistance;
          }

          if (this.currentDayUnix <= this.todayUnix) {
            let workoutLog = workoutLogs && workoutLogs[index];
            if (workoutLog && workoutLog.exerciseId !== exercise.id) {
              if (workoutLog.exercise) {
                if (
                  workoutLog.exerciseId !== exercise.id &&
                  exercise.id === assigned.id
                ) {
                  workoutLog.exercise.workoutType = exercise.workoutType;
                  workoutLog.repeatCircuit = exercise.repeatCircuit;
                  workoutLog.exercise.section = exercise.section;
                  workoutLog.exercise.index = exercise.index;
                  workoutLog.exercise.exerciseIndexInSection =
                    exercise.exerciseIndexInSection;
                  exercise = workoutLog.exercise;
                }
                if (
                  this.currentDayUnix === this.todayUnix &&
                  !Boolean(this.freshLogID)
                ) {
                  let maxValues = this.getMaxValues(workoutLog, exercise);
                  exercise = Object.assign({}, exercise, maxValues);
                } else {
                  exercise = Object.assign({}, exercise, workoutLog);
                }
              }
            } else if (workoutLog) {
              let logged = Object.assign({}, exercise, workoutLog);
              if (
                this.currentDayUnix === this.todayUnix &&
                !Boolean(this.freshLogID)
              ) {
                let maxValues = this.getMaxValues(logged, exercise);
                delete maxValues.section;
                delete maxValues.exerciseIndexInSection;
                delete maxValues.index;
                delete maxValues.workoutType;
                exercise = Object.assign({}, exercise, maxValues);
              } else {
                delete logged.section;
                delete logged.exerciseIndexInSection;
                delete logged.index;
                delete logged.workoutType;
                exercise = Object.assign({}, exercise, logged);
              }
            }
          }

          if (!exercise) {
            return { index };
          }
          let logId = `${exercise.id}_${exercise.section}_${exercise.exerciseIndexInSection}_${index}`;

          return {
            logId,
            index,
            exercise,
            exerciseList,
          };
        })
        .filter((entry) => !entry.exclude);
      // REVIEW decide if need mapping
      let sections = [];
      for (let entry of mapped) {
        if (!sections[entry.exercise.section]) {
          sections[entry.exercise.section] = {
            workoutType: entry.exercise.workoutType,
            repeatCircuit:
              this.state.workoutSections[entry.exercise.section].repeatCircuit,
            index: entry.exercise.section,
            sets:
              this.state.workoutSections &&
              this.state.workoutSections[entry.exercise.section] &&
              this.state.workoutSections[entry.exercise.section].sets,
            data: [],
          };
        }
        sections[entry.exercise.section].data.push(entry);
      }
      return sections;
    }
  );

  fetchWorkoutSummary = (callback) => {
    const clientId = this.state.clientDetails && this.state.clientDetails.id;
    const programId =
      this.state.clientDetails &&
      this.state.clientDetails.programId &&
      this.state.clientDetails.programId;
    window.FortisForma.database
      .getWorkoutSummary(clientId, programId)
      .then(async (results) => {
        if (!results) {
          return;
        }
        if (callback) {
          callback(results);
        }

        this.setState({ workoutSummary: results });
        let today = window.workoutLogsKey();
        this.workoutSummary = results;
        let todayLogs = results.logs[today];
        // let workout = this.programData.workoutDataMap[this.workoutId];
        if (!todayLogs) {
          this.freshLogID = today;
          return;
        }
        if (this.workoutId) {
          todayLogs = todayLogs[this.workoutId] || {};
          this.setupTodayWorkoutLog(todayLogs);
        } else {
          this.pendingLogSetup = todayLogs;
        }
      })
      .catch((error) => {
        console.error("Error fetching workout summary", error);
      });
  };

  setupTodayWorkoutLog(todayLogs = {}) {
    let workoutLog = this.mapWorkoutLogs(todayLogs);
    let isComplete = this.isWorkoutComplete(workoutLog);
    this.setState(
      {
        workoutLog,
        showCompleteModal: isComplete,
        workoutComplete: isComplete,
      },
      () => {
        try {
          let indexes = Object.keys(workoutLog);
          (indexes || []).sort(sorter);
          let lastIndex = indexes.pop();
          if (lastIndex && !isComplete) {
            setTimeout(() => {
              requestAnimationFrame(() => {
                let exerciseList = this.state.exercises[+lastIndex + 1];

                if (exerciseList) {
                  let assignedLevel = this.assignedLevels[+lastIndex + 1] || 1;
                  let entry = exerciseList[assignedLevel];
                  if (
                    entry &&
                    entry.section >= 0 &&
                    entry.exerciseIndexInSection >= 0
                  ) {
                    this.scrollTo(entry.section, entry.exerciseIndexInSection);
                  }
                }
              });
            }, 500);
          }
        } catch (e) {}
      }
    );
  }

  isWorkoutComplete = (log) => {
    for (let exLog of Object.keys(log)) {
      if (!log[exLog]) {
        delete log[exLog];
      }
    }
    return (
      Object.keys(log || {}).length ===
      Object.keys(this.state.exercises || {}).length
    );
  };

  mapWorkoutLogs = (log = {}) => {
    let logState = {};
    let isOldType = isOldLogType(log);
    if (isOldType) {
      logState = log;
    } else {
      let order = this.state.exerciseOrder;
      for (let index of order) {
        let exercise = this.state.exercises[index][this.assignedLevels[index]];
        let logValue =
          log[exercise.section] &&
          log[exercise.section][exercise.exerciseIndexInSection];
        if (logValue) {
          logState[index] = Object.assign({}, logValue);
        }
      }
    }

    let items = Object.keys(logState);
    for (let itemIndex of items) {
      let logValue = logState[itemIndex];

      if (!logValue.section) {
        logValue.section = 0;
      }
      if (!logValue.exerciseIndexInSection) {
        logValue.exerciseIndexInSection =
          logValue.index || logValue.exerciseIndexInSection || 0;
      }
      logState[itemIndex] = logValue;
    }
    return logState;
  };

  isTimeEnabled = (exercise) => {
    return Boolean(Number(exercise.time));
  };

  isRepsEnabled = (exercise) => {
    return Boolean(Number(exercise.reps));
  };
  isSetsEnabled = (exercise) => {
    return Boolean(Number(exercise.sets));
  };

  isResistanceEnabled = (exercise) => {
    return Boolean(exercise.resistance);
  };

  isWeightEnabled = (exercise) => {
    return Boolean(Number(exercise.weight));
  };

  onDonePress = (logResults, exercise) => {
    this.setLogsData({ logResults, exercise, exerciseToLog: null }, () => {
      this.logExercise(this.state.log);
      this.setState({ exerciseToLog: null });
    });
  };

  setLogsData({ logResults, exercise, paramsModalVisible }, callback) {
    this.setState(
      {
        logId: logResults.logId,
        logExercise: exercise,
        paramsModalVisible,
        enabled: {
          sets: this.isSetsEnabled(exercise),
          reps: this.isRepsEnabled(exercise),
          resistance: this.isResistanceEnabled(exercise),
          weight: this.isWeightEnabled(exercise),
          time: this.isTimeEnabled(exercise),
        },
        log: {
          sets: "" + logResults.log.sets,
          reps: "" + logResults.log.reps,
          resistance: logResults.log.resistance,
          weight: "" + logResults.log.weight,
          time: "" + logResults.log.time,
          feedback: {
            difficulty: logResults.log.difficulty || "",
            painLevel: logResults.log.painLevel || 0,
            feedbackText: logResults.log.feedbackText || "",
          },
        },
      },
      callback
    );
  }

  isProgramComplete = (isWorkoutComplete = false) => {
    if (this.currentDayUnix < this.todayUnix) {
      return false;
    }
    let totalWorkoutDays = Object.keys(
      (this.programData && this.programData.workoutDaysMap) || {}
    ).length;
    let finalDayIndex = totalWorkoutDays - 1;
    if (isWorkoutComplete) {
      return this.dayIndex >= finalDayIndex;
    }
    return this.dayIndex > finalDayIndex;
  };

  setSectionLogsData = (section, callback) => {
    let sectionLogs = [];
    let exerciseLog = {};
    for (let exercise of section.data) {
      exerciseLog = {
        logId: exercise.logId,
        logExercise: exercise.exercise,
        enabled: {
          sets: this.isSetsEnabled(exercise.exercise),
          reps: this.isRepsEnabled(exercise.exercise),
          resistance: this.isResistanceEnabled(exercise.exercise),
          weight: this.isWeightEnabled(exercise.exercise),
          time: this.isTimeEnabled(exercise.exercise),
        },
        log: {
          sets: "" + exercise.exercise.sets || "",
          reps: "" + exercise.exercise.reps || "",
          resistance: exercise.exercise.resistance || "",
          weight: "" + exercise.exercise.weight || "",
          time: "" + exercise.exercise.time || "",
        },
      };
      sectionLogs.push(exerciseLog);
    }
    this.setState(
      {
        sectionLogs,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  addSectionLog = async (section) => {
    this.setSectionLogsData(section, async () => {
      let update = {};
      let logData = this.getSectionLogData(section);
      let startDate =
        this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
      let isComplete = false;
      let newWorkoutLog = {};
      try {
        requestAnimationFrame(() => {
          window.NotificationUtils.showConfirm("Saving summary");
        });
        let log = await window.FortisForma.database.addWorkoutSectionLog(
          logData,
          !startDate,
          this.state.clientDetails.id,
          this.state.clientDetails.pendingLogin || false
        );
        let logIndex = 0;
        for (let exercise of section.data) {
          update[exercise.index] = log[logIndex];
          logIndex++;
        }
        if (!startDate) {
          // Set to true so that subsequent calls dont update it
          this.workoutSummary[DB_KEYS.START_DATES] = true;
        }
        newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
        isComplete = this.isWorkoutComplete(newWorkoutLog);
        if (!isComplete) {
          window.NotificationUtils.showSuccess("Workout log saved");
        }
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Could not save log");
        update = {};
      }

      if (isComplete) {
        requestAnimationFrame(() => {
          this.setCompleteModalVisible(true);
        });
      }

      if (!this.workoutSummary.logs[logData[0].logDate]) {
        this.workoutSummary.logs[logData[0].logDate] = {};
      }
      this.workoutSummary.logs[logData[0].logDate][this.workoutId] =
        newWorkoutLog;

      this.setState({
        logId: null,
        logExercise: null,
        workoutLog: newWorkoutLog || {},
        workoutComplete: isComplete,
      });
    });
  };

  removeSectionLog = async (section) => {
    this.setSectionLogsData(section, async () => {
      const clientId = this.state.clientDetails.id;
      let update = {};
      let logData = this.getSectionLogData(section, true);
      try {
        await window.FortisForma.database.removeWorkoutSectionLog(
          logData,
          clientId
        );
        for (let exercise of section.data) {
          update[exercise.index] = false;
        }
        window.NotificationUtils.showSuccess("Workout log updated");
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Could not update log");
      }
      let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
      this.setState({
        logId: null,
        logExercise: null,
        workoutLog: newWorkoutLog || {},
        workoutComplete: false,
      });
    });
  };

  getSectionLogData = (section, removed = false) => {
    let index = 0;
    let workoutLog = [];
    for (let exercise of section.data) {
      let data;
      if (removed) {
        data = this.getRemovedExerciseLogData(index, exercise.exercise);
      } else {
        data = this.getExerciseLogData(index, exercise.exercise);
      }

      workoutLog.push(data);
      index++;
    }
    return workoutLog;
  };

  getRemovedExerciseLogData = (index) => {
    let update = {};
    if (
      !this.state.sectionLogs ||
      (this.state.sectionLogs && !this.state.sectionLogs[index])
    ) {
      return false;
    }
    let exerciseLogData = Object.assign({}, this.state.sectionLogs[index]);
    let split = exerciseLogData.logId.split("_");
    let sectionIndex = split[1];
    let exerciseIndex = split[2];
    let exindex = split[3];
    update[exindex] = false;
    let params = {
      programId: this.programData.id,
      logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
      exerciseIndex,
      sectionIndex,
      workoutId: this.workoutId,
    };
    return params;
  };

  getExerciseLogData = (index, exercise) => {
    let update = {};
    if (
      !this.state.sectionLogs ||
      (this.state.sectionLogs && !this.state.sectionLogs[index])
    ) {
      return false;
    }
    let exerciseLogData = Object.assign({}, this.state.sectionLogs[index]);
    let log = Object.assign({}, exerciseLogData.log);
    let split = exerciseLogData.logId.split("_");
    log.exerciseId = split[0];
    let sectionIndex = split[1];
    let exerciseIndex = split[2];
    let exindex = split[3];
    update[exindex] = true;
    let params = {};
    let isChanged =
      exerciseLogData.logExercise.id !==
      (this.assignedExercises[exindex] || {}).id;

    if (isChanged) {
      log.exercise = exerciseLogData.logExercise;
    }

    params = {
      programId: this.programData.id,
      log,
      logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
      exerciseIndex,
      sectionIndex,
      workoutId: this.workoutId,
      feedback:
        this.state.workoutLog[Number(exindex)] &&
        this.state.workoutLog[Number(exindex)].feedback,
    };

    if (!this.workoutSummary) {
      this.workoutSummary = {
        logs: {
          [params.logDate]: {
            [this.workoutId]: {},
          },
        },
      };
    }
    return params;
  };

  onRemoveLog = (logResults, exercise) => {
    this.removeLogsData(
      { logResults, exercise, paramsModalVisible: false, exerciseToLog: null },
      () => {
        this.removeLog(this.state.log);
        this.setState({
          exerciseToLog: null,
        });
      }
    );
  };

  removeLogsData({ logResults, exercise, paramsModalVisible }, callback) {
    this.setState(
      {
        logId: logResults.logId,
        logExercise: null,
        paramsModalVisible,
        enabled: {
          sets: this.isSetsEnabled(exercise),
          reps: this.isRepsEnabled(exercise),
          resistance: this.isResistanceEnabled(exercise),
          weight: this.isWeightEnabled(exercise),
          time: this.isTimeEnabled(exercise),
        },
        log: {
          reps: "" + exercise.reps,
          sets: "" + exercise.sets,
          resistance: exercise.resistance,
          weight: "" + exercise.weight,
          time: "" + exercise.time,
        },
      },
      callback
    );
  }

  removeLog = async () => {
    let update = {};
    let split = this.state.logId.split("_");
    let sectionIndex = split[1];
    let exerciseIndex = split[2];
    let index = split[3];
    update[index] = false;
    let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
    let params = {
      clientId: this.state.clientDetails.id,
      programId: this.programData.id,
      logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
      exerciseIndex,
      sectionIndex,
      workoutId: this.workoutId,
    };
    let startDate =
      this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
    try {
      await window.FortisForma.database.removeWorkoutLog(params, !startDate);
      window.NotificationUtils.showSuccess("Workout log updated");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Could not update log");
    }
    update[index] = false;

    this.setState({
      logId: null,
      logExercise: null,
      workoutLog: newWorkoutLog || {},
      workoutComplete: false,
    });
  };

  logExercise = async (log) => {
    let update = {};
    let split = this.state.logId.split("_");
    log.exerciseId = split[0];
    let sectionIndex = split[1];
    let exerciseIndex = split[2];
    let index = split[3];
    update[index] = true;
    let newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
    let isComplete = this.isWorkoutComplete(newWorkoutLog);

    // TODO: index will not always be sequential
    if (!isComplete && sectionIndex && exerciseIndex) {
      let toSectionIndex = +sectionIndex;
      let toExerciseIndex = +exerciseIndex;
      try {
        if (
          toExerciseIndex ===
          this.state.workoutSections[sectionIndex].exercises.length - 1
        ) {
          toSectionIndex = toSectionIndex + 1;
          toExerciseIndex = 0;
        } else {
          toExerciseIndex = toExerciseIndex + 1;
        }
      } catch (e) {}
      this.scrollTo(toSectionIndex, toExerciseIndex);
      this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(
        toSectionIndex,
        toExerciseIndex
      );
    }
    requestAnimationFrame(async () => {
      let isChanged =
        this.state.logExercise &&
        this.state.logExercise.id !==
          ((this.assignedExercises && this.assignedExercises[index]) || {}).id;

      if (isChanged) {
        log.exercise = this.state.logExercise;
      }

      requestAnimationFrame(() => {
        window.NotificationUtils.showConfirm("Saving summary");
      });
      let params = {
        clientId: this.state.clientDetails.id,
        programId: this.programData.id,
        log,
        logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
        exerciseIndex,
        sectionIndex,
        workoutId: this.workoutId,
      };

      let startDate =
        this.workoutSummary && this.workoutSummary[DB_KEYS.START_DATES];
      if (!this.workoutSummary) {
        this.workoutSummary = {
          logs: {
            [params.logDate]: {
              [this.workoutId]: {},
            },
          },
        };
      }

      try {
        update[index] = await window.FortisForma.database.addWorkoutLog(
          params,
          !startDate,
          this.state.clientDetails.pendingLogin
        );

        if (!startDate) {
          // Set to true so that subsequent calls dont update it
          this.workoutSummary[DB_KEYS.START_DATES] = true;
        }

        if (!isComplete) {
          window.NotificationUtils.showSuccess("Workout log saved");
        }
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Could not save log");
        update = {};
      }

      if (isComplete) {
        requestAnimationFrame(() => {
          this.setCompleteModalVisible(true);
        });
      }

      newWorkoutLog = Object.assign({}, this.state.workoutLog, update);
      if (!this.workoutSummary.logs[params.logDate]) {
        this.workoutSummary.logs[params.logDate] = {};
      }
      this.workoutSummary.logs[params.logDate][this.workoutId] = newWorkoutLog;

      this.setState({
        logId: null,
        logExercise: null,
        workoutLog: newWorkoutLog || {},
        workoutComplete: isComplete,
      });
    });
  };

  scrollTo(toSectionIndex, toExerciseIndex) {
    let element = document.getElementById(
      `exerciseCard_${toSectionIndex}_${toExerciseIndex}`
    );
    if (element && element.scrollIntoView) {
      try {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } catch (e) {}
    }
  }

  workoutCompleteDone = async (feedback, updateDate, goback) => {
    this.setCompleteModalVisible(false);
    let feedbackAdded = false;
    for (let key of Object.keys(feedback)) {
      if (feedback[key]) {
        feedbackAdded = true;
      }
    }

    let data = {
      clientId: this.state.clientDetails.id,
      programId: this.programData.id,
      logDate: window.workoutLogsKey(moment.unix(this.currentDayUnix)),
      workoutId: this.workoutId,
    };

    if (feedbackAdded && feedback) {
      data.feedback = feedback;
    }

    try {
      if (feedbackAdded) {
        await window.FortisForma.database.saveWorkoutLogMeta(data, updateDate);
      }
      if (goback) {
        this.onBackClick();
      }
      window.NotificationUtils.showSuccess("Feedback saved successfully");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to save feedback");
    }
  };

  setCompleteModalVisible = (visible) => {
    this.setState({ workoutComplete: visible, showCompleteModal: visible });
  };

  renderWorkoutCompleteModal = () => {
    let message =
      this.programData &&
      this.programData.workoutCompleteMessages &&
      this.programData.workoutCompleteMessages[this.state.workoutIndex];
    if (this.isProgramComplete(true)) {
      message = this.programData && this.programData.programCompleteMessage;
    }
    return (
      <Dialog id="workoutCompleteDialog" open={this.state.showCompleteModal}>
        <WorkoutCompleteModal
          message={message}
          workoutCompleteDone={this.workoutCompleteDone}
          setCompleteModalVisible={this.setCompleteModalVisible}
          lastFeedbackDate={this.state.workoutSummary.feedbackDate}
          todayUnix={this.todayUnix}
        />
      </Dialog>
    );
  };

  renderWorkoutSections = () => {
    let sections = this.getData(
      this.state.exerciseOrder,
      this.state.exercises,
      this.state.currentExerciseLevel,
      this.state.workoutLog
    );
    let isEnabled = this.currentDayUnix <= this.todayUnix;
    if (sections && sections.length) {
      return (
        <React.Fragment>
          <SectionedWorkout
            ref={this.sectionedWorkoutRef}
            clientId={this.state.clientDetails && this.state.clientDetails.id}
            clientLoginPending={
              this.state.clientDetails && this.state.clientDetails.pendingLogin
            }
            clientName={
              this.state.clientDetails && this.state.clientDetails.name
            }
            clientEmail={
              (this.state.clientDetails && this.state.clientDetails.email) || ""
            }
            workoutSection={sections}
            programId={this.programData.id}
            workoutId={this.workoutId}
            onDonePress={this.onDonePress}
            onRemoveLog={this.onRemoveLog}
            addSectionLog={this.addSectionLog}
            removeSectionLog={this.removeSectionLog}
            getEnabledFields={this.getEnabledFields}
            workoutLog={this.state.workoutLog}
            todayUnix={this.todayUnix}
            currentDayUnix={this.currentDayUnix}
            isEnabled={isEnabled}
            goBack={this.onBackClick}
            showCompleteModal={this.state.showCompleteModal}
            workoutComplete={this.state.workoutComplete}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  };
  renderWorkoutStartMessage = () => {
    const closeDialog = () => {
      this.setState({
        showWorkoutStartMessageModal: false,
      });
    };
    return (
      <Dialog
        open={this.state.showWorkoutStartMessageModal}
        onClose={closeDialog}
        aria-labelledby="workout-start-title"
      >
        <DialogTitle id="workout-start-title">Before you begin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.workoutStartMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getWorkoutIndex = (workoutId) => {
    for (let key of Object.keys(this.programData.workoutDaysMap)) {
      if (this.programData.workoutDaysMap[key] === workoutId) {
        return key;
      }
    }
    return undefined;
  };

  getWorkoutStartMessage = (workoutId) => {
    const { workoutStartMessages } = this.programData && this.programData;
    let index = this.getWorkoutIndex(workoutId);
    if (!index) {
      return false;
    }
    let message = workoutStartMessages && workoutStartMessages[index];
    if (!message) {
      return false;
    }
    return message;
  };

  hasLogs = (workoutSummary, workoutId) => {
    let today = window.workoutLogsKey();
    let todayLogs =
      (workoutSummary && workoutSummary.logs && workoutSummary.logs[today]) ||
      {};
    let workoutLogs = todayLogs[workoutId];
    if (workoutLogs && Object.keys(workoutLogs).length > 0) {
      return true;
    }
    return false;
  };

  shouldShowStartMessage = memoize((workoutSummary, workoutId) => {
    if (
      !workoutId ||
      !workoutSummary ||
      (workoutSummary && Object.keys(workoutSummary).length < 1) ||
      this.state.showCompleteModal
    ) {
      return false;
    }
    let message = this.getWorkoutStartMessage(workoutId);
    if (!message) {
      return false;
    }
    if (this.hasLogs(workoutSummary, workoutId)) {
      return false;
    }
    this.setState({
      workoutStartMessage: message,
      showWorkoutStartMessageModal: true,
    });
  });

  onBackClick = () => {
    this.props.history.goBack();
  };

  onOptionsClick = (e) => {
    this.setState({
      optionsAnchorEl: e.currentTarget,
    });
  };

  handleOptionsClose = () => {
    this.setState({
      optionsAnchorEl: null,
    });
  };

  setClientDetailsInStorage = (details) => {
    const clientDetails = JSON.stringify(details);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.WORKOUT_SESSION_DETAILS,
      clientDetails
    );
  };

  onResetProgram = () => {
    this.setState({ optionsAnchorEl: null });
    let message = this.getConfirmMessage();
    window.customConfirm(message, this.handleResetProgram);
  };

  getConfirmMessage = () => {
    return (
      <>
        Are you sure you want to reset this program? <br />
        <Typography variant="body2" className="marginTop8 mgBottom16">
          Note: This will reset the program and remove today's workout logs, it
          may also change the day, you can re-select this day at the top of the
          screen, if you want.
        </Typography>
      </>
    );
  };

  handleResetProgram = async () => {
    const { id, programId, pendingLogin } = this.state.clientDetails;
    this.setState({ loading: true });
    try {
      let clientDetails = Object.assign({}, this.state.clientDetails);
      clientDetails.programStartDate = moment().startOf("day").unix();
      this.setClientDetailsInStorage(clientDetails);
      await window.FortisForma.database.removeDayLogs(id, programId);
      await window.FortisForma.database.changeWorkoutStartDate(
        id,
        programId,
        pendingLogin
      );
      this.setState({ clientDetails }, this.fetchData);
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");
      this.setState({ loading: false, optionsAnchorEl: null });
      console.error(e);
    }
  };

  openChooseWorkoutModal = () => {
    this.setState({
      showChooseWorkoutModal: true,
    });
  };

  closeChooseWorkoutModal = () => {
    this.setState({
      showChooseWorkoutModal: false,
    });
  };

  changeWorkout = async (workout) => {
    this.closeChooseWorkoutModal();
    this.setState({
      sectionsExpanded: [],
    });
    let dayIndex = this.getWorkoutIndex(workout.id);
    if (dayIndex < 0) {
      window.NotificationUtils.showError("Unable to find workout");
      return;
    }
    let programStateToStore = {
      id: this.programData.id,
      selectedDayIndex: dayIndex,
      day: moment().startOf("day"),
    };
    programStateToStore = JSON.stringify(programStateToStore);
    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.SELECTED_WORKOUT_DAY,
        programStateToStore
      );
    } catch (e) {
      global.NotificationUtils.showError(
        "Unable to store updated workout to storage"
      );
    }
    let date = moment.unix(this.currentDayUnix);
    this.setWorkoutDayIndex(dayIndex, (workout) => {
      if (workout) {
        this.setSpecificDateLog(date, workout);
        this.sectionedWorkoutRef.current.updateSelectedExerciseFromParent(0, 0);
      }
    });
  };

  setSpecificDateLog(date, workout) {
    let logsKey = window.workoutLogsKey(date);
    let dateLogs = this.workoutSummary && this.workoutSummary.logs[logsKey];
    // let todayDate = moment().startOf("day");
    if (!dateLogs) {
      this.setState({ workoutLog: {} });
      return;
    }
    if (workout && workout.id) {
      dateLogs = dateLogs[workout.id] || {};
      this.setState({ workoutLog: this.mapWorkoutLogs(dateLogs) }, () => {
        this.forceUpdate();
      });
    }
  }

  forceUpdate() {
    //Not Required
  }

  renderOptionsMenu = () => {
    let open = Boolean(this.state.optionsAnchorEl);
    return (
      <Menu
        id="option-menu"
        anchorEl={this.state.optionsAnchorEl}
        keepMounted
        open={open}
        onClose={this.handleOptionsClose}
      >
        <MenuItem onClick={this.onResetProgram}>Reset Program</MenuItem>
      </Menu>
    );
  };

  renderTopRow = () => {
    let { workoutName } = this.state;
    if (this.state.exerciseOrder && this.state.exerciseOrder.length === 0) {
      workoutName = "Rest Day";
    }
    return (
      <div style={{ flexDirection: "column" }} className="centerAlignJustify">
        <div className="workoutSessionTopRow">
          <IconButton onClick={this.onBackClick}>
            <ArrowBack />
          </IconButton>
          <div style={{ fontSize: 18 }}>
            {this.state.clientDetails && this.state.clientDetails.name}
          </div>
          <IconButton onClick={this.onOptionsClick}>
            <MoreVert />
          </IconButton>
          {this.renderOptionsMenu()}
        </div>
        <Button
          className="sessionWorkoutName"
          color="primary"
          endIcon={<ArrowDropDownIcon />}
          onClick={this.openChooseWorkoutModal}
        >
          {workoutName}
        </Button>
      </div>
    );
  };

  renderChooseWorkoutModal = () => {
    return (
      <Dialog id="chooseWorkoutDialog" open={this.state.showChooseWorkoutModal}>
        <ChooseWorkout
          onClose={this.closeChooseWorkoutModal}
          workouts={Object.assign({}, this.programData)}
          selectedWorkoutId={this.workoutId}
          changeWorkout={this.changeWorkout}
        />
      </Dialog>
    );
  };

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  renderEmptyDay() {
    let message = "It's a Rest Day";
    if (this.isProgramComplete()) {
      message = this.programData && this.programData.programCompleteMessage;
    }
    return (
      <div id="restDay" style={{ textAlign: "center", marginTop: 32 }}>
        <img alt="Empty" className="emptyImage" src={restDay} />
        <Typography variant="h4" color="primary">
          {message}
        </Typography>
      </div>
    );
  }

  render() {
    this.shouldShowStartMessage(this.state.workoutSummary, this.workoutId);
    if (!this.state.exerciseOrder.length && !this.state.loading) {
      return (
        <>
          {this.renderTopRow()}
          {this.renderEmptyDay()}
          {this.renderChooseWorkoutModal()}
        </>
      );
    }
    if (this.state.loading) {
      return this.progressIndicator();
    }
    return (
      <>
        {this.renderTopRow()}
        {this.state.workoutSections && this.renderWorkoutSections()}
        {this.renderChooseWorkoutModal()}
        {this.renderWorkoutCompleteModal()}
        {this.renderWorkoutStartMessage()}
      </>
    );
  }
}
