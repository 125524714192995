import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createHashHistory } from "history";
import memoize from "memoize-one";
import React, { Component } from "react";
import { withOrientationChange } from "react-device-detect";
import Modal from "react-modal";
import { Route, Router, Switch } from "react-router-dom";
import { Subject } from "rxjs";
import { Dialog } from "@material-ui/core";
import jwt from "jsonwebtoken";
import { shareReplay } from "rxjs/operators";
import "../node_modules/noty/lib/noty.css";
import "../node_modules/noty/lib/themes/semanticui.css";
import "../node_modules/video-react/dist/video-react.css";
import "./onboard.css";
import "./App.css";
import "./App_responsive.css";
import ChangePasswordModal from "./components/modals/changePasswordModal";
import TrailModal from "./components/modals/trialInfoModal";
import ConfirmModal from "./components/modals/confirmModal";
import LoadingMessageModal from "./components/modals/loadingMessagesModal";
import {
  ACTION_STATES,
  COLLECTIONS,
  DB_KEYS,
  INITIALIZE_WALKTHROUGH,
  LOCAL_STORAGE_KEYS,
  ROLES,
  TIER,
  USER_STATES,
} from "./constants";
import database from "./modules/database/database.js";
import indexRoutes from "./routes/index";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import AccDisableModal from "./components/modals/accDisableModal";

const hist = createHashHistory();

require("./modules/notifications");

window.FortisForma.database = database;

window.FortisForma.asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      window.localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return window.localStorage.getItem(key);
    });
  },
};

class App extends Component {
  constructor() {
    super();
    let profile;
    let entData;
    try {
      profile = JSON.parse(
        window.localStorage.getItem(DB_KEYS.USER_PROFILE_KEY)
      );
      entData = JSON.parse(window.localStorage.getItem(DB_KEYS.PP_ENT));
    } catch (e) {
      console.error(e);
    }
    let verificationPending = window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.VERIFICATION_PENDING,
      false
    );
    verificationPending = Boolean(verificationPending);

    this.state = {
      isLoggedIn: profile != null,
      user: profile,
      subscription: {},
      ppEnterprise: entData,
      loading: true,
      trialBool: true,
      disableAcc: false,
    };

    if (verificationPending) {
      this.state.user = {
        emailVerified: false,
      };
    }

    if (!this.state.user) {
      window.FortisForma.database.signOut();
    }
    // window.FortisForma.database.registerPushNotifications();
    this.confirmModalRef = React.createRef();
    this.ChangePasswordModalRef = React.createRef();
    this.LoadingMessageModalRef = React.createRef();

    this.onUserChange = this.onUserChange.bind(this);
    this.walkthroughStream = new Subject().pipe(shareReplay(1));
    window.walkthroughStream = this.walkthroughStream;

    window.firebase.auth().onAuthStateChanged((user) => {
      let signupState = window.localStorage.getItem(DB_KEYS.SIGN_UP_STATE);
      if (signupState && signupState !== ACTION_STATES.COMPLETE) {
        return;
      }

      if (user) {
        this.onAuthUserChanged(user);
      } else {
        window.clearLocalStorage();
        this.setState(
          {
            isLoggedIn: false,
            user: null,
            ppEnterprise: null,
          },
          () => {
            window.FortisForma.database.signOut();
          }
        );
      }
    });
  }

  decodeToken = (token) => {
    try {
      const decoded = jwt.decode(token);
      return decoded;
    } catch (e) {
      return null;
    }
  };

  startTimer = (exp) => {
    const duration = exp * 1000 - Date.now();
    this.timerId = setTimeout(() => {
      this.setState({ isLoggedIn: false });
      localStorage.clear();
    }, duration);
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = this.decodeToken(token);
      if (
        decodedToken &&
        decodedToken.exp &&
        decodedToken.exp > Date.now() / 1000
      ) {
        this.setState({ isLoggedIn: true });
        this.startTimer(decodedToken.exp);
      } else {
        localStorage.clear();
        this.setState({ isLoggedIn: false });
        window.NotificationUtils.showError(
          "Your session has been over.",
          3000,
          "bottomCenter"
        );
      }
    }
    window.FortisForma.database.registerPushNotifications();
    this.setState({ trialBool: true });
    Modal.setAppElement(this.el);
    window.customConfirm = (
      message,
      confirmCallback,
      cancelCallback,
      extras,
      saveDraftCallBack
    ) => {
      this.confirmModalRef.current &&
        this.confirmModalRef.current.show(
          message,
          confirmCallback,
          cancelCallback,
          extras,
          saveDraftCallBack
        );
    };

    window.showLoadingMessages = (messages, changeDuration) => {
      this.LoadingMessageModalRef.current &&
        this.LoadingMessageModalRef.current.show(messages, changeDuration);
    };

    window.changePassword = () => {
      this.ChangePasswordModalRef.current &&
        this.ChangePasswordModalRef.current.show();
    };

    window.hideLoadingMessages = () => {
      this.LoadingMessageModalRef.current &&
        this.LoadingMessageModalRef.current.hide();
    };
    window.cacheCategories = this.cacheCategories;

    window.emailTrimAndLowerCase = (email) => {
      let trimmedEmail = email.trim();

      let result = trimmedEmail.toLowerCase();

      return result;
    };
    window.textTrim = (text) => {
      let result = text.trim();
      return result;
    };

    window.setEmailInLocalStorage = (email) => {
      window.localStorage.setItem(DB_KEYS.LAST_USER_EMAIL, email);
    };

    window.clearLocalStorage = () => {
      let email = window.localStorage.getItem(DB_KEYS.LAST_USER_EMAIL);
      window.localStorage.clear();
      if (email) {
        window.setEmailInLocalStorage(email);
      }
    };
    window.getEnterpriseData = () => {
      if (this.state.user.enterprise) {
        let enterpriseData = {};
        enterpriseData.enterpriseName = this.state.user.enterprise.name || "";
        enterpriseData.enterpriseLogo =
          (this.state.user.enterprise.logo &&
            this.state.user.enterprise.logo.url) ||
          "";
        return enterpriseData;
      } else {
        if (this.state.user) {
          let enterpriseData = {};
          enterpriseData.enterpriseName = this.state.user.name || "";
          enterpriseData.enterpriseLogo = "";
          return enterpriseData;
        }
      }
    };
    window.onOTPSuccess = this.onOTPSuccess;
    window.hasMFA = this.hasMFA;
    window.workoutLogsKey = (date) => {
      if (!date) {
        date = moment();
      }
      return date.format("L").replace(/\//g, "-");
    };

    window.formatPhone = (value) => {
      let newValue = value.split(" ").join("").slice(2);
      return newValue;
    };

    window.isPDF = (link) => {
      if (link.includes(".pdf")) {
        return true;
      }
      return false;
    };
    window.getTimestampSeconds = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        return timestamp.seconds;
      }
      if (timestamp && timestamp._seconds) {
        return timestamp._seconds;
      }
    };
  }

  onOTPSuccess = () => {
    this.onAuthUserChanged(
      this.state.user,
      true,
      window.FortisForma.database.updateUserLoginTime
    );
  };

  cacheCategories = async () => {
    try {
      let results = await this.fetch(COLLECTIONS.MUSCLE_GROUPS);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.MUSCLE_GROUPS,
        JSON.stringify(results)
      );
    } catch (e) {
      // DONE TO IGNORE THE PERMISSIONS ERROR AND NOTIFICATION
    }

    try {
      let results = await this.fetch(COLLECTIONS.EQUIPMENT_CATEGORIES);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.EQUIPMENT_CATEGORIES,
        JSON.stringify(results)
      );
    } catch (e) {
      // DONE TO IGNORE THE PERMISSIONS ERROR AND NOTIFICATION
    }
    try {
      let results = await this.fetch(COLLECTIONS.FUNCTION_CATEGORIES);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.FUNCTION_CATEGORIES,
        JSON.stringify(results)
      );
    } catch (e) {
      // DONE TO IGNORE THE PERMISSIONS ERROR AND NOTIFICATION
    }
  };

  removeTrial = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.TRIALMODAL,
      JSON.stringify(false)
    );
    this.setState({ trialBool: false });
  };

  renderTrialModal = () => {
    if (
      this.state.subscription &&
      this.state.subscription.currentTier &&
      (this.state.subscription.currentTier.is_paid ||
        this.state.subscription.currentTier.tier == TIER.FREE)
    ) {
      return;
    }
    let trialModalBool = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.TRIALMODAL)
    );
    return (
      <Dialog
        disableBackdropClick
        fullWidth={true}
        open={trialModalBool && this.state.trialBool}
        onClose={() => this.removeTrial()}
      >
        {this.state.subscription &&
        this.state.subscription.trial &&
        this.state.subscription.trial.active ? (
          <TrailModal
            onClose={() => this.removeTrial()}
            // modalTitle="Welcome to free 2-week trial! <br /> Now you can try out all of the features that the Private Solution has to offer."
            modalBody="Remember to fill in your payment details <b>before</b> the end of the 2 weeks, so you can continue using these awesome features after the trial is over!"
            buttonText=" Update Payment Method"
            user={this.state.user}
            hist={hist}
          />
        ) : (
          <TrailModal
            onClose={() => this.removeTrial()}
            modalTitle="Your FREE trial is over"
            modalBody="In order to continue using these features, you must first upgrade your subscription!"
            buttonText="Continue to Subscriptions"
            user={this.state.user}
            hist={hist}
          />
        )}
      </Dialog>
    );
  };

  async fetch(collectionName) {
    try {
      let data = await window.FortisForma.database.queryData({
        collection: collectionName,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  routeToInvalidLogin = () => {
    hist.replace("/invalid-login");
  };
  gotoOTPScreen = (user) => {
    hist.replace("/login-authentication");
  };

  async onAuthUserChanged(authUser, force = false, callback) {
    this.setState({ trialBool: true });

    try {
      let claims = await window.FortisForma.database.getCustomClaims(force);

      console.log("claims", claims);

      let myClaim;
      if (claims.hasOwnProperty("active")) {
        myClaim = claims.active;
      } else {
        myClaim = true;
      }
      console.log("myClaim activeClaim===", !myClaim);
      if (
        (claims.role === ROLES.HEALTH_COACH ||
          claims.role === ROLES.FACILITY_ADMIN) &&
        !myClaim
      ) {
        this.setState({ disableAcc: true });
        return;
      }
      if (callback) {
        callback();
      }
      claims.emailVerified = authUser.emailVerified;
      let accessResults = this.canAccessDashboard(claims);
      if (accessResults.verified === false) {
        if (accessResults.error) {
          window.NotificationUtils.showError(
            accessResults.error,
            null,
            "bottomCenter"
          );
        }
        if (accessResults.reason === DB_KEYS.ROLE) {
          window.FortisForma.database.signOut();
          this.routeToInvalidLogin();
        } else {
          if (accessResults.reason === DB_KEYS.EMAIL_VERIFIED) {
            this.waitForVerification();
          }
          let entResults;
          if (claims.role === ROLES.TRAINER) {
            entResults =
              await window.FortisForma.database.fetchEnterpriseInPPFree();
          } else if (
            claims.role === ROLES.FACILITY_ADMIN ||
            claims.role === ROLES.HEALTH_COACH
          ) {
            entResults =
              await window.FortisForma.database.fetchEnterpriseDetailsForStaff(
                claims.enterpriseId
              );
          }
          this.setState({ ppEnterprise: entResults });
          this.setState({ isLoggedIn: false, user: authUser });
        }
        return;
      }
      if (this.hasMFA(claims)) {
        if (!this.isMFAAuthenticated(claims)) {
          this.setState({ user: authUser });

          if (claims.role === ROLES.TRAINER) {
            const results =
              await window.FortisForma.database.fetchEnterpriseInPPFree();
            this.setState({ ppEnterprise: results });
          } else {
            if (
              claims.role === ROLES.FACILITY_ADMIN ||
              claims.role === ROLES.HEALTH_COACH
            ) {
              const result =
                await window.FortisForma.database.fetchEnterpriseDetailsForStaff(
                  claims.enterpriseId
                );
              this.setState({ ppEnterprise: result });
            }
          }
          window.FortisForma.userRole = claims.role;
          return this.gotoOTPScreen();
        }
      }
      // await window.FortisForma.database.checkTier();
    } catch (e) {
      console.error(e);
      return window.FortisForma.database.signOut();
    }

    this.onUserChange();
  }

  hasMFA(claims) {
    if (claims.enableMFA) {
      return true;
    }
    return false;
  }

  isMFAAuthenticated(claims) {
    if (claims.auth_time && claims.auth_time === claims.mfaTime) {
      return true;
    }
    return false;
  }

  subCheck = async () => {
    try {
      let subs = await window.FortisForma.database.getSubscription();
      this.setState({ subscription: subs });
      return true;
    } catch (e) {
      return false;
    }
  };

  async onUserChange() {
    this.setState({ trialBool: true });

    try {
      let actualData = await window.FortisForma.database.checkTier();
      await window.FortisForma.database.createTrialForNewAccount();
      let resultn = await window.FortisForma.database.stripeCallForNewUser(
        actualData.email ? actualData.email : null
      );
      if (resultn !== undefined) {
        await this.subCheck();
      }

      let user = await window.FortisForma.database.getUserData(true);

      let isInvalidEnterpriseMember =
        user &&
        user.state &&
        user.state === USER_STATES.INVALID_ENTERPRISE_MEMBER
          ? true
          : false;
      var validAccess = !(user && user.role && user.role === ROLES.CLIENT);
      if (!validAccess) {
        window.FortisForma.database.signOut();
        this.routeToInvalidLogin();
        return;
      }
      let entResult;
      if (user.enterpriseId) {
        let enterpriseData =
          await window.FortisForma.database.getEnterpriseData(
            user.enterpriseId
          );
        user.enterprise = enterpriseData;
        user.workoutdayCount = enterpriseData.workoutdayCount;
        if (
          user.role === ROLES.FACILITY_ADMIN ||
          user.role === ROLES.HEALTH_COACH
        ) {
          entResult =
            await window.FortisForma.database.fetchEnterpriseDetailsForStaff(
              user.enterpriseId
            );
        }
      } else {
        if (user.role === ROLES.TRAINER) {
          entResult =
            await window.FortisForma.database.fetchEnterpriseInPPFree();
        }
      }
      window.FortisForma[DB_KEYS.WALKTHROUGH] = user[DB_KEYS.WALKTHROUGH];
      this.walkthroughStream.next({
        walkthrough: user[DB_KEYS.WALKTHROUGH],
      });
      await window.FortisForma.database.getUserToken(true);
      if (!(await window.FortisForma.database.currentUser())) {
        //IF USER DOESNT EXIST
        return;
      }

      this.setState({
        isLoggedIn: true,
        user: user,
        ppEnterprise: entResult,
      });

      window.localStorage.setItem(
        DB_KEYS.USER_PROFILE_KEY,
        JSON.stringify(user)
      );
      window.localStorage.setItem(DB_KEYS.PP_ENT, JSON.stringify(entResult));
      await this.subCheck();
      if (isInvalidEnterpriseMember) {
        window.FortisForma.database.signOut();
        window.NotificationUtils.showError("Invalid User");
        return;
      }

      Sentry.configureScope((scope) => {
        scope.setTag("User ID", user.id);
      });
      const location = hist.location;
      if (
        location.pathname === "/login" ||
        location.pathname === "/login-authentication"
      ) {
        let route = this.getURLRoute() || "dashboard";
        hist.replace(route);
      }

      this.cacheCategories();
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong!");
      if (e.code === 404) {
        this.setState(
          {
            isLoggedIn: true,
            user: null,
            ppEnterprise: null,
          },
          () => {
            hist.replace("/profile");
          }
        );
      } else if (e.code === "unavailable") {
        window.location.reload();
        window.NotificationUtils.showError(
          "Network error, please refresh page"
        );
      }
    }
  }

  canAccessDashboard(claims) {
    let results = { error: null, verified: false, reason: null };

    if (claims.role !== ROLES.ADMIN && !claims.emailVerified) {
      results.error = "";
      results.reason = DB_KEYS.EMAIL_VERIFIED;
      return results;
    }

    if (claims.role && claims.role === ROLES.CLIENT) {
      results.error = "";
      results.reason = DB_KEYS.ROLE;
      return results;
    }

    if (claims && claims.role === ROLES.TRAINER) {
      let userState = claims[DB_KEYS.USER_STATE];

      if (userState !== USER_STATES.APPROVED) {
        results.error =
          userState === USER_STATES.BLOCKED
            ? "Your signup request has been declined"
            : "Please wait for your account approval";
        results.reason = DB_KEYS.USER_STATE;
        return results;
      }
    }

    results.verified = true;
    return results;
  }

  getURLRoute() {
    let queryParams = "";
    try {
      let queryIndex = window.location.hash.indexOf("?");
      if (queryIndex >= 0) {
        queryParams = window.location.hash.substring(queryIndex);
      }
    } catch (e) {
      console.error(e);
    }
    let urlParams = new URLSearchParams(queryParams);
    return urlParams.get("route");
  }

  waitForVerification() {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.VERIFICATION_PENDING, true);
    hist.replace("/resend-email");
  }

  resetTutorial = () => {
    window.FortisForma.database.initializeWalkthrough();
    this.walkthroughStream.next({
      walkthrough: INITIALIZE_WALKTHROUGH,
    });
  };

  updateOrientation = memoize((isPortrait) => {
    if (isPortrait) {
      return true;
    }
    return false;
  });

  render() {
    let isPortrait = this.updateOrientation(this.props.isPortrait);

    return (
      <div ref={(ref) => (this.el = ref)}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  key={key}
                  render={(props) => (
                    <prop.component
                      {...props}
                      user={this.state.user}
                      subscription={this.state.subscription}
                      isPortrait={isPortrait}
                      isLoggedIn={this.state.isLoggedIn}
                      onUserChange={this.onUserChange}
                      ppEnterprise={this.state.ppEnterprise}
                      hist={hist}
                    />
                  )}
                />
              );
            })}
          </Switch>
        </Router>

        {/*{this.state.user && (*/}
        {/*  // <div*/}
        {/*  //   id="tutorialButton"*/}
        {/*  //   className="tutorialButton"*/}
        {/*  //   onClick={this.resetTutorial}*/}
        {/*  // >*/}
        {/*  //   Tutorial*/}
        {/*  // </div>*/}
        {/*)}*/}

        {((this.state.user && this.state.user.tier == "Enterprise Solution") ||
          (this.state.user &&
            this.state.user.tier == "Private Practice Solution")) &&
          this.state.subscription &&
          this.state.subscription.trial &&
          this.renderTrialModal()}
        <AccDisableModal
          disableAcc={this.state.disableAcc}
          closeModal={() => this.setState({ disableAcc: false })}
          hist={hist}
        />

        <ConfirmModal
          ref={(ref) => {
            this.confirmModalRef = { current: ref };
          }}
        />
        <ChangePasswordModal
          user={this.state.user}
          ref={(ref) => {
            this.ChangePasswordModalRef = { current: ref };
          }}
        />
        <LoadingMessageModal
          ref={(ref) => {
            this.LoadingMessageModalRef = { current: ref };
          }}
        />
      </div>
    );
  }
}

App = withOrientationChange(App);

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    htmlFontSize: 18,
  },
  palette: {
    secondary: {
      main: "#455a64",
      light: "rgb(106, 123, 131)",
      dark: "rgb(48, 62, 70)",
      contrastText: "#fff",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    type: "light",
    action: {
      hoverOpacity: 0.08,
      hover: "rgba(0, 0, 0, 0.08)",
      selected: "rgba(0, 0, 0, 0.14)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.26)",
      active: "rgba(0, 0, 0, 0.54)",
    },
    primary: {
      main: "#802520",
      light: "rgb(153, 80, 76)",
      dark: "rgb(89, 25, 22)",
      contrastText: "#fff",
    },
  },
});
export default class AppWrapper extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <App {...this.props}></App>
      </MuiThemeProvider>
    );
  }
}
