import { Typography } from "@material-ui/core";
import React from "react";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import EnterpriseDetails from "./enterpriseDetails";
import EnterpriseList from "./enterpriseList";

const LOADING_MESSAGES = ["Creating enterprise"];
const LOADING_CHANGE_DURATION = 5000;

export default class EnterpriseAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEnterprise: null,
      enterprises: [],
      listLoading: true,
      lastFetched: null,
      emptyEnterprise: {
        name: "",
        admin: {
          name: "",
          email: "",
        },
        id: "",
      },
    };

    this.listRef = null;
    this.pageSize = 20;
  }

  fetchEnterprises = async () => {
    const results = await window.FortisForma.database.getEnterprises();
    this.setState({
      lastFetched: results[results.length - 1],
      enterprises: results,
      listLoading: false,
    });
  };

  onSelect = (selectedEnterprise) => {
    this.setState({
      selectedEnterprise: selectedEnterprise,
    });
    this.navigate();
  };

  addNewEnterprise = () => {
    this.setState({
      selectedEnterprise: this.state.emptyEnterprise,
    });
    this.navigate();
  };

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".enterpriseDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
  };

  emptyPlaceholder = () => {
    return <div>No enterprise found</div>;
  };

  renderListLoader() {
    return (
      <div className="approvalsLoader">
        <Spinner color="primary" />
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="enterpriseHeader">
        <Typography variant="h5">Enterprise Accounts</Typography>
        <Button onClick={this.addNewEnterprise}>Add Enterprise</Button>
      </div>
    );
  }

  onSave = async (enterpriseData) => {
    window.showLoadingMessages &&
      window.showLoadingMessages(LOADING_MESSAGES, LOADING_CHANGE_DURATION);
    try {
      let result = await window.FortisForma.database.createEnterprise(
        enterpriseData
      );
      if (!result) {
        window.hideLoadingMessages && window.hideLoadingMessages();
        window.NotificationUtils.showError("Something went wrong");
      } else {
        this.setState(
          {
            selectedEnterprise: this.state.emptyEnterprise,
          },
          () => {
            setTimeout(() => {
              this.fetchEnterprises();
            }, 1000);
          }
        );
        window.hideLoadingMessages && window.hideLoadingMessages();
        window.NotificationUtils.showSuccess("Enterprise created successfully");
      }
    } catch (e) {
      console.error(e);
      var message = e.message ? e.message : "Something went wrong";
      window.hideLoadingMessages && window.hideLoadingMessages();
      window.NotificationUtils.showError(message);
    }
  };

  render() {
    return (
      <Container className="enterpriseEditor" fluid>
        <Row
          className="fullWidth"
          style={{ alignItems: "center", marginBottom: 8 }}
        >
          {this.renderHeader()}
        </Row>
        <Row className="fullWidth fullWHListContainer">
          <Col lg="6" md="12" sm="12" xs="12" className="listContainer">
            <div
              className="fullWidth"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Row
                className="enterpriseCardHeading centerAlignJustify"
                style={{ minHeight: 50 }}
              >
                <Col lg="1" md="1" sm="1" xs="1" />
                <Col lg="5" md="5" sm="5" xs="5" className="incFontWeight">
                  Enterprise Name
                </Col>
                <Col lg="5" md="5" sm="5" xs="5" className="incFontWeight">
                  Admin Name
                </Col>
                <Col lg="1" md="1" sm="1" xs="1" />
              </Row>
              <div id="enterpriseEditorList" style={{ flex: 1 }}>
                {
                  <EnterpriseList
                    ref={(r) => {
                      this.listRef = r;
                    }}
                    data={this.state.enterprises}
                    autoSize={true}
                    padding={[0, 0]}
                    containerId={"enterpriseEditorList"}
                    rowHeight={60}
                    columnCount={1}
                    selectedEnterprise={this.state.selectedEnterprise}
                    onSelect={this.onSelect}
                    loadData={this.fetchEnterprises}
                    pageSize={this.pageSize}
                    emptyPlaceholder={this.emptyPlaceholder()}
                  />
                }

                {this.state.listLoading && this.renderListLoader()}
              </div>
            </div>
          </Col>
          <Col
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="enterpriseDetailContainer"
          >
            {this.state.selectedEnterprise !== null ? (
              <EnterpriseDetails
                selectedEnterprise={this.state.selectedEnterprise}
                onSave={this.onSave}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please select an Enterprise from the list
              </div>
            )}
          </Col>
        </Row>

        <div
          hidden={this.state.selectedEnterprise === null}
          className="screenNavigationHelperButton"
          onClick={this.navigate}
        >
          {this.navigationIcon()}
        </div>
      </Container>
    );
  }
}
