import { Typography } from "@material-ui/core";
import React, { Component } from "react";

export default class HeaderDetails extends Component {
  renderFacilityLogo = (logo) => {
    if (logo) {
      return (
        <div>
          <img src={logo} className="formFacilityLogo" alt="Facility Logo" />
        </div>
      );
    }
  };

  render() {
    const enterprise = window.getEnterpriseData() || "";
    let logo = enterprise.enterpriseLogo || "";
    const clientName = this.props.clientName || "";
    const clientEmail = this.props.clientEmail || "";
    const clientDOB = this.props.clientDOB || "";

    return (
      <div className="editorHeaderContainer">
        {this.renderFacilityLogo(logo)}
        <div className="clientDetailsContainer">
          <Typography display="inline">
            <span className="headerDetailsKey">Client Name:</span>
            <span className="headerDetailsClientText">{clientName}</span>
          </Typography>
          {clientEmail && (
            <Typography display="inline">
              <span className="headerDetailsEmailText headerDetailsKey">
                Client Email:
              </span>
              <span className="headerDetailsClientText">{clientEmail}</span>
            </Typography>
          )}
          {clientDOB && (
            <Typography display="inline">
              <span className="headerDetailsDOBText headerDetailsKey">
                Client DOB:
              </span>
              <span className="headerDetailsClientText">{clientDOB}</span>
            </Typography>
          )}
        </div>
      </div>
    );
  }
}
