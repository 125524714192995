import { Button, Typography } from "@material-ui/core";
import { CardBody, Col, Row } from "reactstrap";
import React from "react";
import { COLLECTIONS, ROLES } from "../../constants";
import Card from "@material-ui/core/Card";
import BlogEditCard from "./blogDetail";
import Dialog from "@material-ui/core/Dialog";

export default class AllBlogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      filters: "",
      listLoading: true,
      lastFetched: null,
      selected: null,
      filtered: false,
      openModal: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.isAdmin =
      this.props.user &&
      this.props.user.role &&
      this.props.user.role === ROLES.ADMIN;
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData(query) {
    if (!query) {
      query = {};
    }

    query.collection = COLLECTIONS.BLOGS;
    if (this.state.filters) {
      query.filters = this.state.filters;
    }

    query.pageConfig = {};

    if (this.state.lastFetched) {
      query.pageConfig.orders = [
        {
          key: "title",
          after: this.state.lastFetched.name,
        },
      ];
    } else {
      query.pageConfig.orders = [
        {
          key: "title",
        },
      ];
    }
    try {
      let results = await window.FortisForma.database.queryData(query);

      this.setState({
        lastFetched: results[results.length - 1],
        data: results,
      });
      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  onSelect(item) {
    this.setState({
      selected: Object.assign({}, item),
    });
  }

  getPageHeaderText = () => {
    if (this.isAdmin) {
      return "Blogs";
    }
    return null;
  };
  onDelete = async (id) => {
    try {
      await window.FortisForma.database.deleteBlog(id);
      window.NotificationUtils.showSuccess(
        `Deleted ${this.state.selected.title} Successfully`
      );

      this.setState({ selected: null }, () => {
        this.fetchData();
      });
    } catch (e) {
      console.log(e, "error");
      window.NotificationUtils.showError("Something went wrong");
    }
  };
  onCancel = () => {};
  onSave = async (item, isNew) => {
    try {
      if (isNew) {
        await window.FortisForma.database.addBlogs(item, isNew);
        window.NotificationUtils.showSuccess(
          `Added ${item.title} Successfully`
        );
      } else {
        await window.FortisForma.database.addBlogs(
          item,
          isNew,
          this.state.selected.id
        );
        window.NotificationUtils.showSuccess("Updated successfully.");
      }
      this.setState({ openModal: false, selected: null }, () => {
        this.fetchData();
      });
    } catch (e) {
      console.log(e, "error");
      window.NotificationUtils.showError("Something went wrong");
    }
  };
  addNewBlog = (status) => {
    this.setState({ openModal: status });
  };

  render() {
    return (
      <React.Fragment>
        <div className="documentPageContainer">
          <div className="headerAndButtonContainer">
            <Typography variant="h4">{this.getPageHeaderText()}</Typography>
            <div className="documentsActionButtonsContainer">
              {this.isAdmin && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => this.addNewBlog(true)}
                >
                  Add Blog
                </Button>
              )}
            </div>
          </div>
        </div>
        <Row
          className="editorPanelContainer"
          style={{ alignItems: "center", marginBottom: 16 }}
        >
          <Col id="blogsList" lg="7" className="listContainer editorPanelList">
            {this.state.data.map((item, index) => {
              return (
                <Card
                  className="admin-component-card margin-zero"
                  onClick={() => {
                    this.onSelect(item);
                  }}
                  key={item.title}
                >
                  <CardBody className="admin-component-body padding-zero">
                    {item.title}
                    <div className="editIconContainerBlog">
                      <i className="editIcon icon material-icons">edit</i>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
          <Col lg="5" className="clientDetailContainer">
            {this.state.selected !== null ? (
              <BlogEditCard
                selected={this.state.selected}
                isNew={false}
                onSave={this.onSave}
                onDelete={this.onDelete}
                onCancel={() => this.setState({ selected: null })}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please select a Blog from the list
              </div>
            )}
          </Col>
        </Row>
        <Dialog
          fullWidth={true}
          onClose={() => this.setState({ openModal: false })}
          open={this.state.openModal}
          maxWidth="md"
        >
          <BlogEditCard
            selected={null}
            isNew={true}
            onSave={this.onSave}
            onCancel={() => this.setState({ openModal: false })}
          />
        </Dialog>
        {/*<div hidden={this.state.selected === null} className="screenNavigationHelperButton" onClick={this.navigate}>{this.navigationIcon()}</div>*/}
      </React.Fragment>
    );
  }
}
