import React from "react";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";

export default class SectionedFeedbackCard extends React.Component {
  state = {};
  renderPainValue() {
    let color;
    let icon;
    if (this.props.data.feedback.painLevel < 3) {
      color = "green";
      icon = <SentimentSatisfiedIcon className="feedbackEmoji" />;
    } else if (this.props.data.feedback.painLevel < 7) {
      color = "darkyellow";
      icon = <SentimentDissatisfiedIcon className="feedbackEmoji" />;
    } else {
      color = "red";
      icon = <SentimentVeryDissatisfiedIcon className="feedbackEmoji" />;
    }
    if (icon) {
    }
    return (
      <React.Fragment>
        <div className="displayRow alignCenter">
          <div
            style={{ backgroundColor: color, marginRight: 4 }}
            className="workoutFeedbackDot"
          />
          {this.props.data.feedback.painLevel}/10
        </div>
      </React.Fragment>
    );
  }

  renderDifficulty() {
    let icon;
    if (this.props.data.feedback.difficulty === "Hard") {
      icon = (
        <SentimentSatisfiedIcon fontSize="small" className="feedbackEmoji" />
      );
    } else if (this.props.data.feedback.difficulty === "Boring") {
      icon = (
        <SentimentDissatisfiedIcon fontSize="small" className="feedbackEmoji" />
      );
    } else {
      icon = (
        <SentimentVeryDissatisfiedIcon
          fontSize="small"
          className="feedbackEmoji"
        />
      );
    }
    return (
      <div>
        {this.props.data.feedback.difficulty}
        {isMobile ? "" : icon}
      </div>
    );
  }

  showFeedbackModal = () => {
    this.setState({
      showFeedbackModal: true,
    });
  };

  closeDialog = () => {
    this.setState({
      showFeedbackModal: false,
    });
  };

  renderFeedbackModal = (text) => {
    return (
      <Dialog
        open={true}
        onClose={this.closeDialog}
        aria-labelledby="feedback-title"
      >
        <DialogTitle id="feedback-title">User Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderDescription() {
    const length = 60;
    return (
      <div
        className="feedbackAns"
        id={`id${this.props.data.exerciseId}_${this.props.index}`}
      >
        {this.state.showFeedbackModal &&
          this.renderFeedbackModal(this.props.data.feedback.feedbackText)}
        {this.props.data.feedback.feedbackText.length > length ? (
          <div>
            {`${this.props.data.feedback.feedbackText.substring(0, length)}...`}
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={this.showFeedbackModal}
            >
              Read More
            </Button>
          </div>
        ) : (
          this.props.data.feedback.feedbackText
        )}
      </div>
    );
  }
  render() {
    return (
      <div id="summaryFeedbackContainer" className="sectionedExerciseCard">
        <div className="feedbackLeftPortion">
          <div className="displayColumn">
            <span className="feedbackSectionHeader">Pain</span>
            <span className="feedbackAns">
              {this.props.data.feedback.painLevel !== null || undefined || ""
                ? this.renderPainValue()
                : "-"}
            </span>
          </div>
        </div>
        <div className="feedbackMiddlePortion">
          <div className="displayColumn">
            <span className="feedbackSectionHeader">Difficulty</span>
            <span className="feedbackAns">
              {this.props.data.feedback.difficulty
                ? this.renderDifficulty()
                : "-"}
            </span>
          </div>
        </div>
        <div className="feedbackRightPortion">
          <span className="feedbackSectionHeader">Description</span>
          {this.props.data.feedback.feedbackText ? (
            this.renderDescription()
          ) : (
            <span className="noDescriptionText">No description</span>
          )}
        </div>
      </div>
    );
  }
}
