import React, { Component } from "react";

import { Container, Row, Col, Spinner, Button } from "reactstrap";

import { CONTINUE_URL } from "../../constants";

const STATES = {
  INITIAL: 0,
  LOADING: 1,
  SENT: 2,
};

const TIMER = 8000;

function getDomainName(hostName) {
  if (hostName === "localhost") {
    return "localhost:3000";
  }
  return hostName;
}

export default class ResendEmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: STATES.INITIAL,
      remaining: TIMER / 1000,
    };
  }

  resendVerificationEmail = async () => {
    this.setState({
      status: STATES.LOADING,
    });
    try {
      await window.FortisForma.database.sendEmailVerification({
        url: CONTINUE_URL,
        handleCodeInApp: false,
      });
      this.setState({
        status: STATES.SENT,
      });
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(
        "Could not send verififcation email",
        1500,
        "bottomCenter"
      );
      this.setState({
        status: STATES.INITIAL,
      });
    }
  };

  startTimer(timer = TIMER) {
    this.timer = setTimeout(() => {
      window.location =
        window.location.protocol +
        "//" +
        getDomainName(window.location.hostname);
    }, timer - 500);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, timer / (timer / 1000));
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.interval);
  }

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  renderEmailVerificationPending() {
    return (
      <div className="welcomeMessage">
        <Row style={{ paddingBottom: 100 }}>
          <Col className="center">
            <img
              alt="FortisForma Logo"
              className="logo"
              src="/assets/img/logo.png"
            />
          </Col>
        </Row>
        <h3>Verification Pending</h3>
        <p className="redirect">Please verify your email to continue</p>
        <Button onClick={this.resendVerificationEmail}>
          Resend Verification Email
        </Button>
        <Button
          className="btn-link"
          style={{ fontSize: 12 }}
          onClick={() => window.FortisForma.database.signOut()}
        >
          Log Out
        </Button>
      </div>
    );
  }

  emailSent() {
    return (
      <div className="welcomeMessage">
        <Row style={{ paddingBottom: 100 }}>
          <Col className="center">
            <img
              alt="FortisForma Logo"
              className="logo"
              src="/assets/img/logo.png"
            />
          </Col>
        </Row>
        <h3>Verification Email Sent!</h3>
        <p className="redirect">
          Please check your email for the verification link.
        </p>
        <p className="redirect">
          Taking you back in {this.state.remaining} seconds...
        </p>
      </div>
    );
  }

  _render() {
    switch (this.state.status) {
      case STATES.INITIAL:
        return this.renderEmailVerificationPending();

      case STATES.LOADING:
        return this.progressIndicator();

      case STATES.SENT:
        return this.emailSent();

      default:
        break;
    }
  }

  render() {
    return <Container className="loginBody">{this._render()}</Container>;
  }
}
