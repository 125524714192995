import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ExerciseEditCard from "../../components/exerciseEditCard/exerciseEditCard";
import { DB_KEYS, COLLECTIONS } from "../../constants";
import BaseModal from "../modals/baseModal";
import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect";
import FileUploader from "react-firebase-file-uploader";
import { Progress } from "reactstrap";
import { deviceDetect } from "react-device-detect";

const OPTIONS = {
  MISSING_EXERCISE: "I can’t find an exercise",
  MISCATEGORIZED_EXERCISE:
    "An exercise is mis-categorized or the wrong video shows up",
};

const INITIAL_STATE = {
  show: false,
  comment: "",
  meta: {
    exercise: "",
    category: "",
  },
  selection: "",
  showFooter: true,
  isUploading: false,
  progress: 0,
  attachmentURL: "",
  reactVersion: process.env.REACT_APP_VERSION,
};

export default class FeedbackForm extends BaseModal {
  constructor(props) {
    super(props);
    this.state = this.state || {};
    this.state = Object.assign(this.state, INITIAL_STATE);

    this.exerciseCategoryOptions = [
      { value: "Movement", label: "Movement" },
      { value: "Primary function", label: "Primary function" },
      { value: "Muscle", label: "Muscle" },
      { value: "Movement Category", label: "Movement Category" },
      { value: "Function Category", label: "Function Category" },
      { value: "Equipment", label: "Equipment" },
    ];

    this.handlers = {
      MISSING_EXERCISE: this.handle.bind(this, "MISSING_EXERCISE"),
      MISCATEGORIZED_EXERCISE: this.handle.bind(
        this,
        "MISCATEGORIZED_EXERCISE"
      ),
      BUILDING_WORKOUTS: this.handle.bind(this, "BUILDING_WORKOUTS"),
      CLIENT_MANAGEMENT: this.handle.bind(this, "CLIENT_MANAGEMENT"),
      SOMETHING_BROKEN: this.handle.bind(this, "SOMETHING_BROKEN"),
      OTHER: this.handle.bind(this, "OTHER"),
    };
  }

  handleExerciseCategorySelect = (item) => {
    this.setState({
      meta: Object.assign({}, this.state.meta, { category: item }),
    });
  };

  onChangeExerciseName = (event) => {
    this.setState({
      meta: Object.assign({}, this.state.meta, {
        exercise: event.target.value,
      }),
    });
  };

  onChangeComment = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  handle(key, event) {
    if (event) {
      key = event.target.checked === true ? key : null;
    }

    if (this.state.isDirect) {
      this.onCancel();
    }

    this.setState({
      selection: key,
      isDirect: false,
      showFooter: this.shouldShowFooter(key),
      showBack: this.shouldShowback(key),
    });
  }

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
    window.NotificationUtils.showError("Something went wrong");
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };
  handleProgress = (progress) => this.setState({ progress });
  handleOnUploadSucess = async (fileName) => {
    let url = await window.firebase
      .storage()
      .ref(COLLECTIONS.FEEDBACK)
      .child(fileName)
      .getDownloadURL();
    return this.setState({ isUploading: false, attachmentURL: url });
  };

  shouldShowFooter(key) {
    switch (key) {
      case "MISSING_EXERCISE":
        return false;
      default:
        return true;
    }
  }

  shouldShowback(key) {
    switch (key) {
      case "MISCATEGORIZED_EXERCISE":
        return true;
      default:
        return false;
    }
  }

  handleCancel = () => {
    this.state.showBack ? this.handle() : this.onCancel();
  };

  onCancel = () => {
    this.setState(INITIAL_STATE);
    if (this.cancelCallback) {
      this.cancelCallback();
    }
  };

  headerNameExtras() {
    switch (this.state.selection) {
      case "MISSING_EXERCISE":
        return "- Request Exercise";
      default:
        return "";
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.show}
        id={"feedbackForm"}
        className={this.state.selection || "options"}
      >
        <ModalHeader>{`Feedback Form ${this.headerNameExtras()}`}</ModalHeader>

        <ModalBody>{this._render()}</ModalBody>

        {this.state.showFooter && (
          <ModalFooter>
            <Button
              className="btn btn-outline-secondary"
              onClick={this.handleCancel}
            >
              {this.state.showBack ? "Back" : "Cancel"}
            </Button>{" "}
            <Button
              color="primary"
              disabled={!this.state.selection}
              onClick={this.saveFeedback}
            >
              Submit Feedback
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }

  saveFeedback = async () => {
    let deviceInfo = deviceDetect();
    Object.keys(deviceInfo).forEach((key) =>
      deviceInfo[key] === undefined ? delete deviceInfo[key] : ""
    );

    let feedback = {
      option: this.state.selection,
      comment: this.state.comment,
      attachmentURL: this.state.attachmentURL,
      reactVersion: process.env.REACT_APP_VERSION,
      deviceInfo: deviceInfo,
    };

    if (this.state.meta.exercise) {
      feedback.meta = Object.assign({}, this.state.meta);
      feedback.meta.category = feedback.meta.category.value;
    }

    if (this.state.meta.request) {
      feedback.meta = this.state.meta;
    }

    let config = {
      id: new Date().getTime(),
      queue: "feedback",
    };

    window.NotificationUtils.showConfirm(
      "Sending your valuable feedback to our team",
      null,
      null,
      config
    );
    try {
      await window.FortisForma.database.storeFeedback(feedback);
      window.NotificationUtils.showSuccess(
        "Your feedback is received, thanks for helping us improve!",
        null,
        null,
        config
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError(
        "Something went wrong",
        null,
        null,
        config
      );
    }
    this.setState(INITIAL_STATE);
    this.onConfirm();
  };

  saveExercise = async (exercise) => {
    let meta = Object.assign({}, this.state.meta, { request: exercise });
    this.setState(
      {
        meta,
      },
      async () => {
        await this.saveFeedback();
        this.handle();
        this.onConfirm();
      }
    );
  };

  _render() {
    switch (this.state.selection) {
      case "BUILDING_WORKOUTS":
      case "CLIENT_MANAGEMENT":
      case "OTHER":
      case "SOMETHING_BROKEN":
        return this.renderOptionsWithComments();
      case "MISSING_EXERCISE":
        return this.renderExericseRequest();
      case "MISCATEGORIZED_EXERCISE":
        return this.renderMiscategoirzed();
      default:
        return this.renderOptions();
    }
  }

  renderMiscategoirzed() {
    return (
      <React.Fragment>
        <Input
          style={{ marginBottom: 8 }}
          type="text"
          placeholder="Exercise Name"
          value={this.state.meta.exercise}
          onChange={this.onChangeExerciseName}
        />

        <div style={{ marginBottom: 8 }}>
          <FortisFormaSelect
            isClearable={true}
            placeholder="What is wrong with this exericse?"
            value={this.state.meta.category}
            classNamePrefix="fortisFormaDropDownSelect"
            onChange={this.handleExerciseCategorySelect}
            options={this.exerciseCategoryOptions}
          />
        </div>

        {this.renderComments()}
        {this.renderFileUploader()}
      </React.Fragment>
    );
  }

  renderOptionsWithComments = () => {
    return (
      <React.Fragment>
        {this.renderOptions()}
        {this.renderComments()}
        {this.renderFileUploader()}
      </React.Fragment>
    );
  };

  renderExericseRequest() {
    return (
      <ExerciseEditCard
        user={this.props.user}
        data={{}}
        storageRef={DB_KEYS.REQUEST_STORAGE_KEY}
        request={true}
        onClickCancel={this.handle.bind(this, null, null)}
        onClickSave={this.saveExercise}
      />
    );
  }

  renderComments() {
    return (
      <div>
        <Input
          type="textarea"
          placeholder="Comments"
          style={{
            width: "100%",
            height: 200,
            border: "1px solid",
            paddingLeft: 8,
          }}
          value={this.state.comment}
          onChange={this.onChangeComment}
        />
      </div>
    );
  }

  renderFileUploader() {
    return (
      <div className="fileUploader">
        {this.state.isUploading === true && (
          <Progress animated value={this.state.progress} />
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <label
              className="btn"
              style={{ background: "#800520", color: "white" }}
            >
              {" "}
              Select file to upload
              <FileUploader
                hidden
                accept="image/*,video/mp4,video/x-m4v,video/*"
                name="file uploader"
                storageRef={window.firebase.storage().ref(COLLECTIONS.FEEDBACK)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleOnUploadSucess}
                onProgress={this.handleProgress}
              ></FileUploader>
            </label>
          </div>
          <div id="fileUploadedDiv" style={{ marginLeft: 32 }}>
            {this.state.attachmentURL && <p>File uploaded</p>}
          </div>
        </div>
      </div>
    );
  }

  renderOptions() {
    let options = Object.keys(OPTIONS);
    return (
      <div style={{ marginBottom: 12 }}>
        {options.map((option) => {
          return (
            <div key={option} className="form-check text-left">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  id="option"
                  type="checkbox"
                  checked={this.state.selection === option}
                  onChange={this.handlers[option]}
                />
                <span className="form-check-sign"></span>
                {OPTIONS[option]}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
}
