import hash from "object-hash";
import { from } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { COLLECTIONS } from "../../constants";

let resultsMap = {};

for (let collectionKey of Object.keys(COLLECTIONS)) {
  resultsMap[COLLECTIONS[collectionKey]] = {};
}

let queryMap = {};
let originalQueryData;

export default function (database) {
  let wrapped = Object.assign({}, database);
  originalQueryData = wrapped.queryData;
  wrapped.queryData = cachedQueryData;
  return wrapped;
}

function cachedQueryData(query) {
  let collection = query.collection;
  let queryHash;
  if (collection === "blogs") {
  } else {
    queryHash = hash(query);
  }

  if (resultsMap[collection][queryHash] && collection !== "blogs") {
    console.info("Got results from memory cache", query.collection, queryHash);
    return resultsMap[collection][queryHash];
  }

  let source = queryMap[queryHash];
  if (!source) {
    source = from(originalQueryData(query));
    source = source.pipe(shareReplay(1));
    queryMap[queryHash] = source;
  } else {
    console.info("Shared fetching", query.collection);
  }

  return new Promise((resolve, reject) => {
    source.subscribe(
      (results) => {
        if (isCached(query.collection)) {
          resultsMap[collection][queryHash] = results;
        }
        resolve(results);
      },
      reject,
      () => {
        delete queryMap[queryHash];
      }
    );
  });
}

function isCached(collection) {
  switch (collection) {
    case COLLECTIONS.WORKOUT_DAYS:
    case COLLECTIONS.WORKOUT_PROGRAM:
    case COLLECTIONS.USER_DATA:
    case COLLECTIONS.INVITES:
    case COLLECTIONS.REQUESTS:
      return false;
    default:
      return true;
  }
}

window.FortisForma.Cache = {};
window.FortisForma.Cache.clearCache = (collection) => {
  resultsMap[collection] = {};
  console.info(`Cleared local ${collection}`, resultsMap[collection]);
};

window.FortisForma.Cache.removeDoc = (collection, id) => {
  for (let query of Object.keys(resultsMap[collection])) {
    resultsMap[collection][query] = resultsMap[collection][query].filter(
      (item) => {
        return item.id !== id;
      }
    );
  }
  console.info(`Removed ${id} from local ${collection}`);
};

window.FortisForma.Cache.updateDoc = (collection, id, doc) => {
  for (let query of Object.keys(resultsMap[collection])) {
    resultsMap[collection][query] = resultsMap[collection][query].map(
      (item) => {
        if (item.id === id) {
          return Object.assign(item, doc);
        } else {
          return item;
        }
      }
    );
  }
  console.info(`Updated ${doc.id} in local ${collection}`);
};
