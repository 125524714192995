import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import React, { Component } from "react";
import PDFThumbnail from "../PDFThumbnail/PDFThumbnail";
import PreviewImage from "./previewImageModal";
export default class UploadedImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPreviewModal: false,
      deleting: false,
    };
  }

  renderImageAction = () => {
    return (
      <div className="imageActionsNotes">
        {this.state.deleting
          ? this.renderLoadingIcon()
          : this.renderDeleteIcon()}
      </div>
    );
  };

  removeImage = async () => {
    this.setState({ deleting: true });
    console.log(
      "this.props.onDelete && this.props.imageId",
      this.props.onDelete,
      this.props.imageId
    );
    if (this.props.onDelete && this.props.imageId) {
      try {
        await this.props.onDelete(this.props.imageId);
      } catch (e) {
        console.error(e);
        window.NotificationUtils.showError("Unable to delete");
        this.setState({ deleting: false });
        return;
      }
    }
    this.props.removeImageLocally(this.props.index);
    this.setState({ deleting: false });
  };

  renderLoadingIcon = () => {
    return (
      <div className="verticalCenter">
        <CircularProgress size={14} className="imageActionButton" />
      </div>
    );
  };

  renderDeleteIcon = () => {
    return (
      <div>
        <IconButton
          size="small"
          onClick={this.removeImage}
          disabled={this.props.hideRemove}
        >
          <ClearIcon fontSize="small" className="imageActionButton" />
        </IconButton>
      </div>
    );
  };

  onClickContainer = () => {
    this.props.setPreviewOpened(true);
    this.setState({
      openPreviewModal: true,
    });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
    this.props.setPreviewOpened(false);
  };

  renderPreviewModal = () => {
    return (
      <PreviewImage
        openPreviewModal={this.state.openPreviewModal}
        closePreviewModal={this.closePreviewModal}
        image={this.props.image}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="uploadedImageContainer">
          {window.isPDF(this.props.image) ? (
            <div className="noteImage" onClick={this.onClickContainer}>
              <PDFThumbnail
                className="noteImage"
                height={56}
                link={this.props.image}
              />
            </div>
          ) : (
            <div
              className="noteImage"
              style={{
                backgroundImage: `url("${CSS.escape(this.props.image)}")`,
                width: "100%",
              }}
              onClick={this.onClickContainer}
            />
          )}
          {this.renderImageAction()}
        </div>
        {this.renderPreviewModal()}
      </React.Fragment>
    );
  }
}
