import {
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import { isEmail } from "validator";
import { ROLES } from "../../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TAButton from "../taButton/taButton";

const data = [
  {
    name: "Private Practice Solution",
    type: [
      {
        amount: 20.0,
        value: "Monthly",
        label: "month",
      },
      {
        amount: 200.0,
        value: "Yearly",
        label: "year",
      },
    ],
  },
  {
    name: "Enterprise Solution",
    type: [
      {
        amount: 40.0,
        value: "Monthly",
        label: "month",
      },
      {
        amount: 400.0,
        value: "Yearly",
        label: "year",
      },
    ],
  },
];
export default class PricePlanModal extends Component {
  constructor(props) {
    super(props);
  }

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.onClose()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  getvalues = (name, interval, amount) => {
    this.props.getModalvalues(name, interval, amount);
  };

  render() {
    return (
      <>
        <DialogContent className="memberAddDialog-mui">
          {/* <Typography className="memberAddDialogTitle boldText" variant="h5">
              {this.editMode ? "Edit Member" : "Add Member"}
            </Typography> */}
          <div className="memberAddDialogContent pricePlanModal">
            <div className="memberFieldsContainer">
              {this.renderCloseButton()}
              <div>
                <h5>
                  What subscription would you like to choose for the{" "}
                  {data[this.props.index].name}?
                </h5>
                <h4>
                  Note: You won't get charged until AFTER the free trial is
                  over.
                </h4>
                {data[this.props.index].type.map((item) => (
                  <label>
                    <input
                      type="radio"
                      name={data[this.props.index].name}
                      id={item.value}
                      onChange={() =>
                        this.getvalues(
                          data[this.props.index].name,
                          item.label,
                          item.amount
                        )
                      }
                    ></input>
                    <span>
                      {data[this.props.index].name}({item.value}):
                      <span>${item.amount}+applicable taxes</span>
                      {item.value == "Yearly" && <span>Save 10%!</span>}
                    </span>
                  </label>
                ))}

                {/* <input type="radio" name="price1"></input>
                        <span>
                        
                        Private Practice (monthly):
                        <span>$20.00+applicable taxes</span>
                        </span>
                    </label> */}
                {/* <label>
                        <input type="radio" name="price1"></input>
                        <span>
                        Private Practice (yearly):
                        <span>$200.00+applicable taxes</span>
                        </span>
                    </label> */}
              </div>
            </div>
          </div>
        </DialogContent>
      </>
    );
  }
}
