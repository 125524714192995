import DialogContent from "@material-ui/core/DialogContent";
import React, { Component } from "react";
import ReactCardFlip from "react-card-flip";
import TrainerCard from "./previewComponents/trainerCard";
import TrainerPrograms from "./previewComponents/trainerPrograms";

export default class ProfilePreview extends Component {
  state = {
    isFlipped: false,
  };

  showPrograms = () => {
    this.setState({
      isFlipped: true,
    });
  };

  hidePrograms = () => {
    this.setState({
      isFlipped: false,
    });
  };

  render() {
    return (
      <div>
        <DialogContent style={{ padding: 0, overflow: "hidden" }}>
          <div id="flipCardContainer">
            <ReactCardFlip
              isFlipped={this.state.isFlipped}
              flipDirection="horizontal"
            >
              <TrainerCard
                isFlipped={this.state.isFlipped}
                trainerData={this.props.trainerData}
                showPrograms={this.showPrograms}
                onClose={this.props.onClose}
              />
              <TrainerPrograms
                goBack={this.hidePrograms}
                selectedTrainer={this.props.trainerData}
                onClose={this.props.onClose}
              />
            </ReactCardFlip>
          </div>
        </DialogContent>
      </div>
    );
  }
}
