import React from "react";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import MUIButton from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toggle from "react-toggle";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import { DB_KEYS, LOCAL_STORAGE_KEYS, ROLES } from "../../constants";
import { BubbleLoader } from "react-css-loaders";
import bodybuilder from "bodybuilder";
import FortisFormaSelect from "../autoSuggest/dropdownSelect.js";
import Factory from "../../modules/http/dataFetcher";
import SearchInput from "./searchInput";

const ON_ROLE_FILTER = {
  ALL_EXERCISES: "All Exercises",
  MY_EXERCISES: "My Exercises",
  FORTIS_FORMA_EXERCISES: "Fortis Forma Exercises",
};

const DROPDOWN_OPTION = [
  { value: ON_ROLE_FILTER.ALL_EXERCISES, label: ON_ROLE_FILTER.ALL_EXERCISES },
  { value: ON_ROLE_FILTER.MY_EXERCISES, label: ON_ROLE_FILTER.MY_EXERCISES },
  {
    value: ON_ROLE_FILTER.FORTIS_FORMA_EXERCISES,
    label: ON_ROLE_FILTER.FORTIS_FORMA_EXERCISES,
  },
];

export default class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      searchButtonEnabled: true,
      movements: [],
      movementCategories: [],
      originalMovementCategories: [],
      equipments: [],
      muscles: [],
      muscle: null,
      movement: null,
      equipment: null,
      movementCategory: null,
      functions: [],
      functionItem: null,
      videoMissingOn: false,
      levelProblemOn: false,
      tooltipOpen: false,
      showFilters: false,
      onRoleFilterDropdownValue: DROPDOWN_OPTION[0],
    };

    this.onSetFilters = this.onSetFilters.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);

    this.debouncedApplyFilters = window._.debounce(
      this.afterSelect.bind(this),
      500
    );
  }

  updateFilters = (filters) => {
    let recievedFilters = filters;
    this.setState(
      {
        query: recievedFilters.query || "",
        muscle: this.getDropdownValue(recievedFilters.muscle),
        equipment: this.getDropdownValue(recievedFilters.equipment),
        movementCategory: this.getDropdownValue(
          recievedFilters.movementCategory
        ),
        functionItem: this.getDropdownValue(recievedFilters.functionItem),
      },
      this.afterSelect.bind(this)
    );
  };

  getDropdownValue(value) {
    if (value) {
      return {
        value: value,
        label: value,
      };
    }
    return null;
  }

  componentDidMount() {
    let filters = this.getTrainerFilters();
    this.fetchMovements(filters);
    this.fetchMovementCategories();
    this.fetchEquipments();
    this.fetchFunctions(filters);
    this.fetchMuscles();
    if (!window.localStorage.getItem(LOCAL_STORAGE_KEYS.FILTER_TOOLTIP_SHOWN)) {
      this.setState({
        tooltipOpen: true,
      });
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.FILTER_TOOLTIP_SHOWN,
        "yes"
      );
      setTimeout(() => {
        this.setState({
          tooltipOpen: false,
        });
      }, 5000);
    }
  }

  handleToggle(type) {
    if (type === "video") {
      this.setState(
        {
          videoMissingOn: !this.state.videoMissingOn,
        },
        this.afterSelect.bind(this)
      );
    } else {
      this.setState(
        {
          levelProblemOn: !this.state.levelProblemOn,
        },
        this.afterSelect.bind(this)
      );
    }
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  getTrainerFilters() {
    let filters = bodybuilder();
    if (!this.props.isAdmin) {
      if (this.props.user.enterpriseId) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", this.props.user.enterpriseId)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "trainerId", this.props.user.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", this.props.user.id)
            .orFilter("term", "trainerId", "admin")
        );
        if (this.props.ppEnterprise) {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "trainerId", this.props.user.id)
              .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
              .orFilter("term", "trainerId", "admin")
          );
        }
      }
    } else {
      filters.andFilter("bool", (q) =>
        q.orFilter("term", "trainerId", "admin")
      );
    }
    return filters.build();
  }

  applyTrainerFilter() {
    let filters = bodybuilder();
    if (this.props.user[DB_KEYS.ENTERPRISE_ID]) {
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", DB_KEYS.ENTERPRISE_ID, this.props.user.enterpriseId)
          .orFilter("term", "trainerId", this.props.user.id)
      );
    } else {
      filters.andFilter("bool", (q) =>
        q.orFilter("term", "trainerId", this.props.user.id)
      );
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", this.props.user.id)
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
        );
      }
    }

    filters = filters.build();
    this.setState({
      onRoleFilterDropdownValue: DROPDOWN_OPTION[1],
    });
    this.props.onSetFilters(filters, {}, true, this.state.levelProblemOn);
  }

  async fetchMovements(query) {
    try {
      let data = await Factory.movementFetcher().fetch(query);
      this.setState({
        originalMovements: data,
      });
      data = data.map((category) => {
        return category.name;
      });
      data.sort();
      this.setMovements(data);
    } catch (e) {
      throw e;
    }
  }

  async fetchMovementCategories() {
    try {
      let data = await Factory.movementCategoryFetcher().fetch();
      this.setState({
        originalMovementCategories: data,
      });
      data = data.map((category) => {
        return category.name;
      });
      data.sort();
      this.setMovementCategories(data);
    } catch (e) {
      throw e;
    }
  }

  async fetchEquipments() {
    try {
      let data = await Factory.equipmentFetcher().fetch();
      this.setState({
        originalEquipments: data,
      });
      data = data.map((equipment) => {
        return equipment.name;
      });
      data.sort();
      this.setEquipments(data);
    } catch (e) {
      throw e;
    }
  }

  async fetchFunctions(query) {
    try {
      let data = await Factory.functionFetcher().fetch(query);
      this.setState({
        originalFunctions: data,
      });
      data = data.map((functionItem) => {
        return functionItem.name;
      });
      data.sort();
      this.setFunctions(data);
    } catch (e) {
      throw e;
    }
  }

  async fetchMuscles() {
    try {
      let data = await Factory.muscleFetcher().fetch();
      this.setState({
        originalMuscles: data,
      });
      data = data.map((muscle) => {
        return muscle.name;
      });
      data.sort();
      this.setMuscles(data);
    } catch (e) {
      throw e;
    }
  }

  setMovements(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      movements: data,
    });
  }

  setMovementCategories(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      movementCategories: data,
    });
  }

  setEquipments(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      equipments: data,
    });
  }

  setFunctions(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      functions: data,
    });
  }

  setMuscles(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      muscles: data,
    });
  }

  setCheckboxesSourceData(dataKey, selectionKey, data) {
    data = window._.uniq(data);
    for (let option of data) {
      this["onChange_" + dataKey + "_" + option] = this.onAddOption.bind(
        this,
        selectionKey,
        option
      );
    }
    let update = {};
    update[dataKey] = data;
    this.setState(update);
  }

  onAddOption(key, option, event) {
    let options = Object.assign([], this.state[key]);
    if (event.target.checked) {
      options.push(option);
    } else {
      options = options.filter((value) => {
        return value !== option;
      });
    }
    let update = [];
    update[key] = options;
    this.setState(update);
  }

  onSetFilters(event) {
    if (event) {
      event.nativeEvent.stopPropagation();
    }
    let { filters, filtersData, didApply } = this.prepareFilters();

    this.props.onSetFilters(
      filters,
      filtersData,
      didApply,
      this.state.levelProblemOn
    );
  }

  prepareFilters() {
    let filters = bodybuilder();
    let filtersData = {
      equipment: this.state.equipment && this.state.equipment.value,
      functionItem: this.state.functionItem && this.state.functionItem.value,
      muscle: this.state.muscle && this.state.muscle.value,
      movement: this.state.movement && this.state.movement.value,
      movementCategory:
        this.state.movementCategory && this.state.movementCategory.value,
    };
    let query = this.state.query.trim() || "";
    query = query.replace(/[^a-zA-Z0-9]/g, " ");
    filtersData.query = this.state.query;
    let didApply = false;
    if (this.state.query) {
      didApply = true;
      filters.orQuery("multi_match", {
        query: query,
        fields: ["name", "alternateNames"],
      });
    }
    if (didApply) {
      filters.orQuery("term", "name.exact", { value: query, boost: 3 });
      filters.orQuery("term", "alternateNames.exact", {
        value: query,
        boost: 3,
      });
    }

    if (this.state.equipment) {
      didApply = true;
      filters.andFilter("bool", (q) =>
        q
          .orFilter(
            "term",
            "equipmentCategories.Primary",
            this.state.equipment.value
          )
          .orFilter(
            "term",
            "equipmentCategories.Secondary",
            this.state.equipment.value
          )
          .orFilter(
            "term",
            "equipmentCategories.Tertiary",
            this.state.equipment.value
          )
      );
    }

    if (this.state.functionItem) {
      didApply = true;
      filters.andFilter("bool", (q) =>
        q
          .orFilter(
            "term",
            "functionCategories.Primary",
            this.state.functionItem.value
          )
          .orFilter(
            "term",
            "functionCategories.Secondary",
            this.state.functionItem.value
          )
          .orFilter(
            "term",
            "functionCategories.Tertiary",
            this.state.functionItem.value
          )
      );
    }

    if (this.state.muscle) {
      didApply = true;
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "muscleGroups.Primary", this.state.muscle.value)
          .orFilter("term", "muscleGroups.Secondary", this.state.muscle.value)
          .orFilter("term", "muscleGroups.Tertiary", this.state.muscle.value)
          .orFilter("term", "muscleGroups.Quarterly", this.state.muscle.value)
          .orFilter("term", "muscleGroups.Quinary", this.state.muscle.value)
      );
    }

    if (this.state.videoMissingOn) {
      didApply = true;
      filters.filter("bool", (q) =>
        q
          .orFilter("term", "videoURL", "")
          .orFilter("bool", (q2) => q2.notFilter("exists", "videoURL"))
      );
    }
    // if (this.state.levelProblemOn) {
    // didApply = true;
    // filters.andFilter("term", "equipmentCategories.Primary");
    // }
    if (this.state.movement) {
      didApply = true;
      filters.filter("term", "movement", this.state.movement.value);
    }

    if (this.state.movementCategory) {
      didApply = true;
      filters.filter(
        "term",
        "movementCategory",
        this.state.movementCategory.value
      );
    }

    if (!this.props.isAdmin) {
      didApply = true;
      filters.notFilter("term", "hidden", true);

      if (
        this.state.onRoleFilterDropdownValue &&
        this.state.onRoleFilterDropdownValue.value ===
          ON_ROLE_FILTER.FORTIS_FORMA_EXERCISES
      ) {
        filters.andFilter("term", "trainerId", "admin");
      } else if (
        this.state.onRoleFilterDropdownValue &&
        this.state.onRoleFilterDropdownValue.value ===
          ON_ROLE_FILTER.MY_EXERCISES
      ) {
        if (this.props.user[DB_KEYS.ENTERPRISE_ID]) {
          filters.orFilter(
            "term",
            DB_KEYS.ENTERPRISE_ID,
            this.props.user.enterpriseId
          );
          filters.orFilter("term", "trainerId", this.props.user.id);
        } else {
          if (this.props.ppEnterprise) {
            filters.orFilter(
              "term",
              DB_KEYS.ENTERPRISE_ID,
              this.props.ppEnterprise.id
            );
            filters.orFilter("term", "trainerId", this.props.user.id);
          } else {
            filters.andFilter("term", "trainerId", this.props.user.id);
          }
        }
      } else {
        if (this.props.user[DB_KEYS.ENTERPRISE_ID]) {
          filters.andFilter("bool", (q) =>
            q
              .orFilter(
                "term",
                DB_KEYS.ENTERPRISE_ID,
                this.props.user.enterpriseId
              )
              .orFilter("term", "trainerId", "admin")
              .orFilter("term", "trainerId", this.props.user.id)
          );
        } else {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "trainerId", this.props.user.id)
              .orFilter("term", "trainerId", "admin")
          );
          if (this.props.ppEnterprise) {
            filters.andFilter("bool", (q) =>
              q
                .orFilter("term", "trainerId", this.props.user.id)
                .orFilter("term", "trainerId", "admin")
                .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
            );
          }
        }
      }
    } else {
      didApply = true;
      filters.andFilter("term", "trainerId", "admin");
    }

    if (didApply) {
      return {
        filters:
          this.state.muscle || this.state.functionItem || this.state.equipment
            ? this.convertToScoreQuery(filters.build())
            : filters.build(),
        filtersData,
        didApply: true,
      };
    }

    return {
      filters: null,
      status: this.state.levelProblemOn,
      filtersData: null,
      didApply: false,
    };
  }

  convertToScoreQuery(query) {
    let functions = [];
    if (this.state.equipment) {
      functions.push({
        filter: {
          match: { "equipmentCategories.Primary": this.state.equipment.value },
        },
        weight: 2,
      });
      functions.push({
        filter: {
          match: {
            "equipmentCategories.Secondary": this.state.equipment.value,
          },
        },
        weight: 1.5,
      });

      functions.push({
        filter: {
          match: { "equipmentCategories.Tertiary": this.state.equipment.value },
        },
        weight: 1,
      });
    }

    if (this.state.functionItem) {
      functions.push({
        filter: {
          match: {
            "functionCategories.Primary": this.state.functionItem.value,
          },
        },
        weight: 2,
      });
      functions.push({
        filter: {
          match: {
            "functionCategories.Secondary": this.state.functionItem.value,
          },
        },
        weight: 1.5,
      });
      functions.push({
        filter: {
          match: {
            "functionCategories.Tertiary": this.state.functionItem.value,
          },
        },
        weight: 1,
      });
    }

    if (this.state.muscle) {
      functions.push({
        filter: { match: { "muscleGroups.Primary": this.state.muscle.value } },
        weight: 3,
      });
      functions.push({
        filter: {
          match: { "muscleGroups.Secondary": this.state.muscle.value },
        },
        weight: 2.5,
      });
      functions.push({
        filter: { match: { "muscleGroups.Tertiary": this.state.muscle.value } },
        weight: 2,
      });
      functions.push({
        filter: {
          match: { "muscleGroups.Quarterly": this.state.muscle.value },
        },
        weight: 1.5,
      });
      functions.push({
        filter: { match: { "muscleGroups.Quinary": this.state.muscle.value } },
        weight: 1,
      });
    }

    let functionScore = {
      function_score: {
        ...query,
        boost: 1,
        functions: functions,
        score_mode: "multiply",
        boost_mode: "sum",
      },
    };

    return {
      query: functionScore,
    };
  }

  onCancel(event) {
    event.nativeEvent.stopPropagation();
    this.props.onCancel();
  }

  reset = () => {
    this.setState({
      query: "",
      searchButtonEnabled: true,
      level: "",
      movement: "",
      movementCategory: "",
      equipment: "",
      muscle: "",
      functionItem: "",
      videoMissingOn: false,
      levelProblemOn: false,
      onRoleFilterDropdownValue: DROPDOWN_OPTION[0],
    });
  };

  handleQueryTextChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.props.inclusive && this.debouncedApplyFilters();
      }
    );
  };

  handleMovementSelect = (selectedOption) => {
    this.setState(
      {
        movement: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  handleMovementCategorySelect = (selectedOption) => {
    this.setState(
      {
        movementCategory: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  handleEquipmentSelect = (selectedOption) => {
    this.setState(
      {
        equipment: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  handleFunctionSelect = (selectedOption) => {
    this.setState(
      {
        functionItem: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  handleMuscleSelect = (selectedOption) => {
    this.setState(
      {
        muscle: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  afterSelect = () => {
    if (this.props.inclusive) {
      this.onSetFilters();
    }
  };

  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  clearQueryText = () => {
    this.setState(
      {
        query: "",
      },
      () => {
        this.props.inclusive && this.debouncedApplyFilters();
      }
    );
  };

  renderMovementCategoryFilter() {
    return (
      <Col
        sm="12"
        className="filterPadding"
        lg={this.props.isAdmin ? "3" : "3"}
      >
        {!this.state.movementCategories.length ? (
          <BubbleLoader
            style={{ marginLeft: 55, marginTop: 0, marginBottom: 0 }}
            color={"#800520"}
            size={4}
          />
        ) : null}

        {this.state.movementCategories.length ? (
          <FormGroup>
            <FortisFormaSelect
              isClearable={true}
              placeholder="Movement Category"
              value={this.state.movementCategory}
              classNamePrefix="fortisFormaDropDownSelect"
              onChange={this.handleMovementCategorySelect}
              options={this.state.movementCategories}
            />
          </FormGroup>
        ) : null}
      </Col>
    );
  }

  renderMovementFilter() {
    return (
      <Col
        sm="12"
        className="filterPadding"
        lg={this.props.isAdmin ? "3" : "2"}
      >
        {!this.state.movements.length ? (
          <BubbleLoader
            style={{ marginLeft: 55, marginTop: 0, marginBottom: 0 }}
            color={"#800520"}
            size={4}
          />
        ) : null}

        {this.state.movements.length ? (
          <FormGroup>
            <FortisFormaSelect
              isClearable={true}
              placeholder="Movement"
              value={this.state.movement}
              classNamePrefix="fortisFormaDropDownSelect"
              onChange={this.handleMovementSelect}
              options={this.state.movements}
            />
          </FormGroup>
        ) : null}
      </Col>
    );
  }

  OnRoleFilterChange = (selectedOption) => {
    this.setState(
      {
        onRoleFilterDropdownValue: selectedOption,
      },
      this.afterSelect.bind(this)
    );
  };

  renderFilterOnRole = () => {
    return (
      <React.Fragment>
        <FortisFormaSelect
          isClearable={false}
          value={this.state.onRoleFilterDropdownValue}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.OnRoleFilterChange}
          options={DROPDOWN_OPTION}
        />
      </React.Fragment>
    );
  };
  renderDraftBtn = () => {
    return (
      <div id="draftFilterButton">
        <Button
          id="workoutDesignerDraftSave"
          variant="contained"
          size="large"
          color="primary"
          // onClick={this.confirmSave}
        >
          Draft
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Row
        id="exerciseFilters"
        style={{ marginTop: this.props.parentComponent === "designer" ? 1 : 6 }}
      >
        <Col lg="12">
          <Form>
            <Row>
              <Col
                sm="12"
                lg={this.props.isAdmin ? "6" : "6"}
                className="filterQueryTextPadding"
              >
                <SearchInput
                  inputId="searchExerciseFilter"
                  placeholder="Search Exercises"
                  value={this.state.query}
                  onChange={this.handleQueryTextChange}
                  onClickClear={this.clearQueryText}
                />
              </Col>
              {!this.props.isAdmin && (
                <Col
                  className="filterPadding"
                  style={{ paddingLeft: 5, paddingRight: 10 }}
                  sm="12"
                  lg="3"
                >
                  {this.renderFilterOnRole()}
                </Col>
              )}
              <div className="filtersCollapsableButtonContainer">
                <MUIButton
                  variant="outlined"
                  color="secondary"
                  style={{ height: "100%" }}
                  onClick={this.toggleFilters}
                  startIcon={<FilterListIcon />}
                >
                  {this.state.showFilters ? "Less Filters" : "More Filters"}
                </MUIButton>
              </div>
            </Row>
            <Collapse timeout={250} in={this.state.showFilters}>
              <Row
                style={{
                  marginTop: 16,
                  marginBottom: 8,
                  display: "flex",
                  padding: "0 10px",
                  justifyContent: "space-between",
                }}
                id="filterContainer"
              >
                {!this.props.isAdmin ? (
                  <Col className="filterPadding" sm="12" lg="3">
                    {!this.state.muscles.length ? (
                      <BubbleLoader
                        id="musclesBubbleLoader"
                        style={{
                          marginLeft: 55,
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        color={"#800520"}
                        size={4}
                      />
                    ) : null}

                    {this.state.muscles.length ? (
                      <FormGroup>
                        <FortisFormaSelect
                          id="muscleFilter"
                          isClearable={true}
                          placeholder="Muscle"
                          value={this.state.muscle}
                          classNamePrefix="fortisFormaDropDownSelect"
                          onChange={this.handleMuscleSelect}
                          options={this.state.muscles}
                        />
                      </FormGroup>
                    ) : null}
                  </Col>
                ) : null}

                {this.props.isAdmin
                  ? this.renderMovementFilter()
                  : this.renderMovementCategoryFilter()}

                <Col
                  sm="12"
                  className="filterPadding"
                  lg={this.props.isAdmin ? "3" : "3"}
                >
                  {!this.state.equipments.length ? (
                    <BubbleLoader
                      style={{ marginLeft: 55, marginTop: 0, marginBottom: 0 }}
                      color={"#800520"}
                      size={4}
                    />
                  ) : null}

                  {this.state.equipments.length ? (
                    <FormGroup>
                      <FortisFormaSelect
                        isClearable={true}
                        placeholder="Equipment"
                        value={this.state.equipment}
                        classNamePrefix="fortisFormaDropDownSelect"
                        onChange={this.handleEquipmentSelect}
                        options={this.state.equipments}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
                <Col sm="12" className="filterPadding removeLeftPadding" lg="3">
                  {!this.state.functions.length ? (
                    <BubbleLoader
                      style={{ marginLeft: 55, marginTop: 0, marginBottom: 0 }}
                      color={"#800520"}
                      size={4}
                    />
                  ) : null}

                  {this.state.functions.length ? (
                    <FormGroup>
                      <FortisFormaSelect
                        isClearable={true}
                        placeholder="Function"
                        value={this.state.functionItem}
                        classNamePrefix="fortisFormaDropDownSelect"
                        onChange={this.handleFunctionSelect}
                        options={this.state.functions}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
                {this.props.isAdmin ? (
                  <Col
                    sm="12"
                    lg="1"
                    className="verticalCenter"
                    id="exerciseToggle"
                  >
                    <Tooltip title="Find exercises with missing videos">
                      <label style={{ margin: 0 }}>
                        <Toggle
                          checked={this.state.videoMissingOn}
                          icons={false}
                          onChange={() => this.handleToggle("video")}
                        />
                      </label>
                    </Tooltip>
                  </Col>
                ) : null}
                {this.props.isAdmin ? (
                  <Col
                    sm="12"
                    lg="1"
                    className="verticalCenter"
                    id="exerciseToggle"
                  >
                    <Tooltip title="Find exercises with levels problem">
                      <label style={{ margin: 0 }}>
                        <Toggle
                          checked={this.state.levelProblemOn}
                          icons={false}
                          onChange={() => this.handleToggle("level")}
                        />
                      </label>
                    </Tooltip>
                  </Col>
                ) : null}
              </Row>
            </Collapse>
          </Form>

          {this.props.inclusive ? null : (
            <div className="center" style={{ marginTop: 40 }}>
              <Button
                className="btn btn-outline-secondary cancelButton"
                onClick={this.onCancel}
              >
                Cancel
              </Button>

              <Button color="primary" onClick={this.onSetFilters}>
                Search
              </Button>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
