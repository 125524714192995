import _ from "lodash";
import React from "react";
import ExerciseList from "../../views/exerciseList/exerciseList";
import LevelChangerExerciseCard from "./levelChangerExerciseCard";
const sorter = (a, b) => Number(a) - Number(b);

export default class LevelChangerExerciseList extends ExerciseList {
  constructor(props) {
    super(props);
    this.state.selectedLevel = {};
    this.state.levelsLoading = {};
    this.levelData = {};
  }

  reset(params) {
    this.levelData = {};
    this.setState(
      {
        selectedLevel: {},
        levelsLoading: {},
      },
      () => {
        super.reset(params);
      }
    );
  }

  onLevelUp(position, data) {
    this.changeLevel(position, data, 1);
  }

  onLevelDown(position, data) {
    this.changeLevel(position, data, -1);
  }

  async changeLevel(position, item, change) {
    let selectedLevel = item.level;
    let positionLevelData = this.levelData[position];
    let exerciseList = positionLevelData && positionLevelData.exerciseList;

    if (!exerciseList) {
      let levelsLoading = Object.assign({}, this.state.levelsLoading);
      levelsLoading[position] = true;

      this.setState(
        {
          levelsLoading,
          renderSignal: true,
        },
        () => {
          this.setState({
            renderSignal: false,
          });
        }
      );
      await this.loadMoreLevels(position, item);
      levelsLoading = Object.assign({}, this.state.levelsLoading);
      levelsLoading[position] = false;
      this.setState(
        {
          levelsLoading,
          renderSignal: true,
        },
        () => {
          this.setState({
            renderSignal: false,
          });
        }
      );
    }

    if (!this.levelData[position]) {
      return;
    }

    exerciseList = this.levelData[position].exerciseList;
    if (!exerciseList || Object.values(exerciseList).length < 2) {
      return;
    }

    let levels = Object.keys(exerciseList).sort(sorter);
    let indexOfLevel = levels.findIndex((item) => +item === +selectedLevel);
    let newLevel = levels[indexOfLevel + change];
    if (newLevel >= 0) {
      this._setLevel(position, newLevel);
    } else {
      this._setLevel(position, selectedLevel);
      if (change < 0) {
        window.NotificationUtils.showError(
          "You have reached the lowest level",
          undefined,
          "bottomLeft"
        );
      } else {
        window.NotificationUtils.showError(
          "You have reached the highest level",
          undefined,
          "bottomLeft"
        );
      }
    }
  }

  _setLevel(position, level) {
    let levels = Object.assign({}, this.state.selectedLevel);
    levels[position] = level;
    this.setState(
      {
        selectedLevel: levels,
        renderSignal: true,
      },
      () => {
        this.setState({
          renderSignal: false,
        });
      }
    );
  }

  async loadMoreLevels(position, exercise, callback) {
    try {
      let results = await window.FortisForma.database.loadMoreLevelsOfExercise(
        exercise
      );

      let exercises = [];
      for (let doc of results) {
        if (doc.id !== exercise.id) {
          let data = doc;
          data.sets = exercise.sets;
          data.reps = exercise.reps;
          data.weight = exercise.weight;
          data.resistance = exercise.resistance;
          data.time = exercise.time;
          data.id = doc.id;
          if (
            !data.videoURL ||
            !data.posterURL ||
            data.hidden ||
            !_.isEqual(data.equipmentCategories, exercise.equipmentCategories)
          ) {
            continue;
          } else {
            exercises.push(data);
          }
        }
      }

      let update = {};

      for (let exercise of exercises) {
        if (!update[Number(exercise.level)]) {
          update[Number(exercise.level)] = exercise;
        }
      }

      if (callback) {
        callback(update);
      }

      if (!exercises.length) {
        window.NotificationUtils.showSuccess(
          "You have viewed all levels",
          undefined,
          "bottomLeft"
        );
      }

      update[Number(exercise.level)] = exercise;

      let levels = Object.keys(update).sort(sorter);

      this.levelData[position] = {
        highestLevel: levels[levels.length - 1],
        exerciseList: update,
      };

      return;
    } catch (e) {
      console.error(e);
    }
  }

  rowRenderer({ key, index, _, style }) {
    let cardPadding = window.screen.availWidth < 1200 ? 8 : 20;
    let cardStyle = {
      position: "relative",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      height: style.height - cardPadding,
      width: style.width - cardPadding,
    };

    let data = this.props.data[index];

    let loading = this.state.levelsLoading[key];
    let selectedLevel = this.state.selectedLevel[key];

    let levelData = this.levelData[key];

    if (selectedLevel && levelData && levelData.exerciseList[selectedLevel]) {
      data = levelData.exerciseList[selectedLevel];
    }

    let highestLevel = levelData && levelData.highestLevel;

    let canLevelUp = Number(data.level) < Number(highestLevel) || !highestLevel;
    let canLevelDown = data.level > 1;

    return (
      <div style={style} key={key}>
        <LevelChangerExerciseCard
          canLevelUp={canLevelUp}
          canLevelDown={canLevelDown}
          loading={loading}
          onLevelDown={this.onLevelDown.bind(this, key, data)}
          onLevelUp={this.onLevelUp.bind(this, key, data)}
          showBadge={this.props.containerId === "exerciseEditor"}
          videoWidth={cardStyle.width}
          videoHeight={185}
          cardStyle={cardStyle}
          data={data}
          selectEnabled={this.props.selectEnabled}
          onSelect={this.props.onSelect}
          selectButtonView={this.props.selectButtonView}
          titleEditBtn={this.props.titleEditBtn}
          onTitleEditClick={this.props.onTitleEditClick}
        />
      </div>
    );
  }
}
