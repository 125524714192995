import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PublishIcon from "@material-ui/icons/PublishRounded";
import FileSaver from "file-saver";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

export default function BulkUploadModal(props) {
  const [loading, setLoading] = useState(false);

  async function onFileUpload(file) {
    setLoading(true);
    let files = Array.from(file);
    await props.onBulkFileSelect(files[0]);
    // setLoading(false);
  }

  function renderLoader() {
    return (
      <div className="flexCenter">
        <CircularProgress />
      </div>
    );
  }

  function download() {
    FileSaver.saveAs(
      "https://firebasestorage.googleapis.com/v0/b/fortisforma.appspot.com/o/sample.xlsx?alt=media&token=37b86ed2-ce5f-45d1-a415-aa411c2c2fff",
      "sample.xlsx"
    );
  }

  return (
    <React.Fragment>
      <DialogTitle>Bulk Upload</DialogTitle>
      <DialogContent>
        {loading ? (
          renderLoader()
        ) : (
          <>
            <Dropzone onDrop={onFileUpload} accept={".xlsx"}>
              {({ getRootProps, getInputProps }) => (
                <section className="pickerSection">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <PublishIcon />
                    <div className="dropAreaText">
                      Drag and Drop or{" "}
                      <Button variant="text" color="default">
                        Browse Files
                      </Button>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="flexCenter mgTop16">
              <Button variant="outlined" color="primary" onClick={download}>
                Download Sample Template
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
