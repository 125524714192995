import bodybuilder from "bodybuilder";
import React from "react";
import { Button, Col, Form, Row } from "reactstrap";
import MUIButton from "@material-ui/core/Button";
import SearchInput from "./searchInput";
import FortisFormaSelect from "../autoSuggest/dropdownSelect.js";
import { ROLES } from "../../constants";
import { Switch } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import Collapse from "@material-ui/core/Collapse";

const CLIENT_STATUS_OPTIONS = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
  { label: "All", value: null },
];

export default class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      searchButtonEnabled: true,
      coach: "",
      healthCoaches: [],
      filterLoading: true,
      inHouseClients: false,
      showFilters: false,
      clientStatusFilter: null,
    };

    this.onSetFilters = this.onSetFilters.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.fields = ["query", "coachId"];
    for (let field of this.fields) {
      this["onChange_" + field] = this.onChange.bind(this, field);
    }

    this.debouncedAfterSelect = window._.debounce(
      this.afterSelect.bind(this),
      500
    );
  }

  async componentDidMount() {
    try {
      var results = await this.props.fetchCoaches();
      let coaches = [];
      for (let coach of results) {
        var coachMeta = {
          value: coach.id,
          label: this.getCoachLabel(coach),
        };
        coaches.push(coachMeta);
      }
      this.setState({
        healthCoaches: coaches,
        filterLoading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        filterLoading: false,
      });
      window.NotificationUtils.showError("Error fetching coaches");
    }
  }

  getCoachLabel(coach) {
    var result = "";
    if (coach.name) {
      result = result + coach.name;
    }
    if (coach.designation) {
      let addition = ` (${coach.designation})`;
      result = result + addition;
    }
    return result;
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update, () => {
      this.props.inclusive && this.debouncedAfterSelect();
    });
  }

  onSetFilters(event) {
    if (event) {
      event.nativeEvent.stopPropagation();
    }
    let { filters, didApply, searchQuery } = this.prepareFilters();

    this.props.onSetFilters(filters, didApply, searchQuery);
  }

  prepareFilters() {
    let filters = bodybuilder();

    let query = this.state.query || "";

    let didApply = false;
    if (this.state.query) {
      didApply = true;
      filters.query("query_string", {
        query: "*" + query + "*",
        fields: ["name", "email"],
      });
    }
    if (this.props.filters) {
      let userId;
      if (
        this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.HEALTH_COACH
      ) {
        userId = this.props.ppEnterprise.id;
      } else {
        userId = this.props.user.id;
      }
      for (let filter in this.props.filters) {
        if (filter === "enterpriseId") {
          filters.andFilter(
            "bool",
            (q) =>
              q
                .orFilter("term", filter, this.props.filters[filter])
                .orFilter("term", "trainerId", userId)
            // .andFilter("term", "role", ROLES.CLIENT)
          );
          // filters.orFilter("term", filter, this.props.filters[filter]);
          // filters.orFilter("term", "trainerId", this.props.user.id);
        } else {
          filters.filter("term", filter, this.props.filters[filter]);
          // if (this.props.ppEnterprise) {
          //   filters.orFilter(
          //     "term",
          //     "enterpriseId",
          //     this.props.ppEnterprise.id
          //   );
          // }
          // if (this.props.ppEnterprise) {
          //   filters.andFilter("bool", (q) =>
          //     q
          //       .orFilter("term", "trainerId", userId)
          //       .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
          //   );
          // }
        }
      }
    }

    if (
      this.state.coach ||
      this.state.inHouseClients ||
      this.state.clientStatusFilter
    ) {
      didApply = true;
    }
    if (this.state.coach) {
      filters.rawOption("coachId", this.state.coach.value);
    }
    if (this.state.inHouseClients) {
      filters.filter("term", "pendingLogin", true);
    }
    if (this.state.clientStatusFilter && this.state.clientStatusFilter.value) {
      filters.filter("term", "active", true);
    }
    if (
      this.state.clientStatusFilter &&
      this.state.clientStatusFilter.value === false
    ) {
      filters.filter("term", "active", false);
    }

    if (didApply) {
      let filterQuery;
      if (this.props.user.role === ROLES.HEALTH_COACH) {
        let query = filters.build();
        filterQuery = { coachId: this.props.user.id, ...query };
      } else {
        filterQuery = filters.build();
      }
      return {
        filters: filterQuery,
        didApply: true,
        searchQuery: query,
      };
    }

    return {
      filters: null,
      didApply: false,
      searchQuery: "",
    };
  }

  onCancel(event) {
    event.nativeEvent.stopPropagation();
    this.props.onCancel();
  }

  reset = () => {
    this.setState({
      query: "",
      searchButtonEnabled: true,
      coach: "",
      inHouseClients: false,
      clientStatusFilter: null,
    });
  };

  resetFirst = () => {
    this.setState(
      {
        query: "",
        searchButtonEnabled: true,
      },
      this.afterSelect.bind(this)
    );
  };

  afterSelect = () => {
    if (this.props.inclusive) {
      this.onSetFilters();
    }
  };

  setCoach = (coach) => {
    this.setState(
      {
        coach: coach,
      },
      this.afterSelect.bind(this)
    );
  };

  handleInHouseClientsChange = (event) => {
    this.setState(
      {
        inHouseClients: event.target.checked,
      },
      this.afterSelect.bind(this)
    );
  };

  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  setClientStatusFilter = (status) => {
    this.setState(
      {
        clientStatusFilter: status,
      },
      this.afterSelect.bind(this)
    );
  };

  renderMoreFilters = () => {
    return (
      <>
        {this.props.user.role !== ROLES.HEALTH_COACH &&
          this.renderCoachFilter()}
        {this.renderClientStatusFilter()}
        {this.renderInhouseToggle()}
      </>
    );
  };

  renderCoachFilter = () => {
    return (
      <Col sm="12" lg="4" id="coachFilterDropdown" key={"coachId"}>
        <FortisFormaSelect
          id="coachFilter"
          isClearable={true}
          placeholder="Filter by coach"
          value={this.state.coach}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.setCoach}
          options={this.state.healthCoaches}
          isDisabled={
            this.state.healthCoaches && this.state.healthCoaches.length === 0
          }
          handleLoading={this.state.filterLoading}
        />
      </Col>
    );
  };

  renderClientStatusFilter = () => {
    return (
      <Col sm="12" lg="4" id="clientStatusFilter" key={"clientStatus"}>
        <FortisFormaSelect
          placeholder="Filter by client status"
          value={this.state.clientStatusFilter}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.setClientStatusFilter}
          options={CLIENT_STATUS_OPTIONS}
        />
      </Col>
    );
  };

  renderInhouseToggle = () => {
    if (this.props.user.role !== ROLES.HEALTH_COACH) {
      return (
        <Col sm="12" lg="4">
          <FormGroup id="inHouseToggleContainer" row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.inHouseClients}
                  onChange={this.handleInHouseClientsChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="In-House Clients"
            />
            <Tooltip
              placement="top"
              title="Turn on this toggle to view only In-House Clients"
            >
              <InfoIcon className="textColorGray" fontSize="small" />
            </Tooltip>
          </FormGroup>
        </Col>
      );
    }
  };

  render() {
    return (
      <Row id="clientFilters">
        <Col lg="12">
          <Form>
            <Row>
              <Col sm="12" lg="4" key={"Search"}>
                <SearchInput
                  inputId={"searchClients"}
                  placeholder={"Search Clients"}
                  value={this.state.query}
                  onChange={this["onChange_query"]}
                  onClickClear={this.resetFirst}
                ></SearchInput>
              </Col>
              <Col sm="12" lg="4">
                <div className="moreFiltersToggle">
                  <MUIButton
                    variant="outlined"
                    color="secondary"
                    style={{ height: "100%" }}
                    onClick={this.toggleFilters}
                    startIcon={<FilterListIcon />}
                  >
                    {this.state.showFilters ? "Less Filters" : "More Filters"}
                  </MUIButton>
                </div>
              </Col>
            </Row>
            <Collapse timeout={250} in={this.state.showFilters}>
              <Row
                style={{
                  marginTop: 16,
                  marginBottom: 8,
                  display: "flex",
                }}
              >
                {this.renderMoreFilters()}
              </Row>
            </Collapse>
          </Form>

          {this.props.inclusive ? null : (
            <div className="center" style={{ marginTop: 40 }}>
              <Button
                className="btn btn-outline-secondary cancelButton"
                onClick={this.onCancel}
              >
                Cancel
              </Button>

              <Button color="primary" onClick={this.onSetFilters}>
                Search
              </Button>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
