import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { isEmail } from "validator";

export default class EnterpriseDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enterpriseName: "",
      enterpriseAdminName: "",
      enterpriseAdminEmail: "",
      isNew: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onChangeEnterpriseAdminEmail = this.onChange.bind(
      this,
      "enterpriseAdminEmail"
    );
    this.onChangeEnterpriseName = this.onChange.bind(this, "enterpriseName");
    this.onChangeEnterpriseAdminName = this.onChange.bind(
      this,
      "enterpriseAdminName"
    );
  }

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    update["change"] = true;
    this.setState(update);
  }

  setData = (obj) => {
    var data = {
      enterpriseName: obj.name || "",
      enterpriseAdminEmail: obj.admin && obj.admin.email ? obj.admin.email : "",
      enterpriseAdminName: obj.admin && obj.admin.name ? obj.admin.name : "",
      isNew: obj.id ? false : true,
    };
    this.setState({
      ...data,
    });
  };

  componentWillMount() {
    this.setData(this.props.selectedEnterprise);
  }

  componentWillReceiveProps(nextProps) {
    this.setData(nextProps.selectedEnterprise);
  }

  onSave = () => {
    if (!this.state.enterpriseName) {
      window.NotificationUtils.showError("Invalid Enterprise Name");
      return;
    }

    if (!this.state.enterpriseAdminName) {
      window.NotificationUtils.showError("Invalid Enterprise Admin Name");
      return;
    }

    if (
      !this.state.enterpriseAdminEmail ||
      !isEmail(window.emailTrimAndLowerCase(this.state.enterpriseAdminEmail))
    ) {
      window.NotificationUtils.showError("Invalid Enterprise Admin Email");
      return;
    }

    var Enterprise = {};
    Enterprise.enterpriseName = this.state.enterpriseName;
    Enterprise.enterpriseAdminEmail = window.emailTrimAndLowerCase(
      this.state.enterpriseAdminEmail
    );
    Enterprise.enterpriseAdminName = this.state.enterpriseAdminName;
    Enterprise.admin = {
      name: this.state.enterpriseAdminName,
      email: window.emailTrimAndLowerCase(this.state.enterpriseAdminEmail),
    };
    this.props.onSave(Enterprise);
  };

  getCardTitle = () => {
    if (this.props.selectedEnterprise.id) {
      return "Enterprise Details";
    }
    return "Create Enterprise";
  };

  renderCardActions = () => {
    return (
      <CardActions className="justifyContentFlexEnd">
        <Button color="primary" onClick={this.onSave}>
          Create
        </Button>
      </CardActions>
    );
  };

  render() {
    return (
      <div className="flexCenter enterpriseCardContainer">
        <Card variant="outlined" className="enterpriseDetailsCard">
          <CardHeader title={this.getCardTitle()} />
          <CardContent>
            <div>
              <TextField
                className={`enterpriseDetailsField ${
                  !this.state.isNew && "pointerEventsNone"
                }`}
                required
                value={this.state.enterpriseName}
                onChange={this.onChangeEnterpriseName}
                label="Enterprise Name"
              />
            </div>
            <div>
              <TextField
                className={`enterpriseDetailsField ${
                  !this.state.isNew && "pointerEventsNone"
                }`}
                required
                value={this.state.enterpriseAdminName}
                onChange={this.onChangeEnterpriseAdminName}
                label="Enterprise Admin Name"
              />
            </div>
            <div>
              <TextField
                className={`enterpriseDetailsField ${
                  !this.state.isNew && "pointerEventsNone"
                }`}
                required
                value={this.state.enterpriseAdminEmail}
                onChange={this.onChangeEnterpriseAdminEmail}
                label="Enterprise Admin Email"
              />
            </div>
          </CardContent>

          {this.state.isNew && this.renderCardActions()}
        </Card>
      </div>
    );
  }
}
