import React from "react";

import { Container, Row, Col, Modal } from "reactstrap";

import DayItem from "./dayItem";
import ClientList from "./programClientList";
import WorkoutList from "./programWorkoutList";
import ProgramTemplateList from "./programTemplateList";
import { DB_KEYS, COLLECTIONS, LOCAL_STORAGE_KEYS } from "../../constants";

import ClientSelectorModal from "../../components/modals/clientSelectorModal";

export default class ProgramTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelector: false,
      programs: [],
      preChecked: null,
      clientList: [],
      workoutList: [],
      selectedDay: 0,
      selectedProgram: null,
      programsLoading: true,
      clientListLoading: false,
      workoutListLoading: false,
      nonSelectedFilter: null,
    };
    this.listRef = null;
    this.pageSize = 20;
  }

  componentDidMount() {
    if (this.props.user.id) {
      this.setState({ userId: this.props.user.id });
    } else {
      window.NotificationUtils.showError("Unable to fetch user data");
    }
  }

  fetchPrograms = async (query, clear) => {
    // TODO: Replace id with name while fetching & sorting
    this.setState({ programsLoading: true });
    let currentUser = this.props.user;
    if (!currentUser && !currentUser.id) {
      throw new Error("User not logged in");
    }

    let trainerId = this.props.user.id;

    if (!query) {
      query = {
        filters: [],
      };
    }

    if (!query.filter) {
      query.filters = [];
    }

    query.collection = COLLECTIONS.WORKOUT_PROGRAM;
    query.filters = [
      {
        key: DB_KEYS.CREATOR_ID_KEY,
        operator: "==",
        value: trainerId,
      },
    ].concat(query.filters);

    query.pageConfig = {
      limit: 20,
    };

    if (this.state.lastFetched) {
      query.pageConfig.orders = [
        {
          key: "id",
          after: this.state.lastFetched.id,
        },
      ];
    } else {
      query.pageConfig.orders = [
        {
          key: "id",
        },
      ];
    }

    try {
      let results;
      results = await window.FortisForma.database.queryData(query);

      let programs = clear ? results : this.state.programs.concat(results);
      programs &&
        programs.sort((a, b) => {
          let nameA = (a.name || "").toLowerCase();
          let nameB = (b.name || "").toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      let lastFetched = results[results.length - 1] || this.state.lastFetched;
      this.setState({
        lastFetched: lastFetched,
        programs,
        programsLoading: false,
      });
      return programs;
    } catch (e) {
      this.setState({ programsLoading: false });

      throw e;
    }
  };

  onDaySelect = (dayNumber) => {
    this.setState({ selectedDay: dayNumber, workoutListLoading: true });
    this.setWorkoutList(this.state.selectedProgram, dayNumber);
  };

  onProgramSelect = (program) => {
    this.setState({
      clientList: [],
      selectedProgram: program,
      workoutList: [],
      selectedDay: 0,
      clientListLoading: true,
    });
    this.getProgramClients(this.state.userId, program.id);
  };

  setWorkoutList = (program, selectedDay) => {
    var workoutList =
      program.workoutDataMap[program.workoutDaysMap[+selectedDay - 1]];
    this.setState({ workoutList, workoutListLoading: false });
  };

  getProgramClients = async (userId, programId) => {
    this.setState({ clientListLoading: true });
    try {
      let results = await window.FortisForma.database.getClientsForProgram(
        userId,
        programId
      );
      let mapped = {};
      for (let result of results) {
        mapped[result.id] = {
          pendingLogin: results.pendingLogin,
        };
      }
      this.setState({
        clientList: results,
        preChecked: mapped,
        clientListLoading: false,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to fetch related clients");
    }
    this.setState({ clientListLoading: false });
  };

  deleteProgram = (selectedProgram, event) => {
    let message = `Are you sure you want to delete ${selectedProgram.name}? This will remove the program data from all the clients that use it.`;

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    window.customConfirm(message, async () => {
      let index = window._.findIndex(this.state.programs, (item) => {
        return item.id === selectedProgram.id;
      });

      if (index >= 0) {
        let newPrograms = Object.assign([], this.state.programs);
        newPrograms.splice(index, 1);

        let update = {
          programs: newPrograms,
        };

        if (this.state.selectedProgram.id === selectedProgram.id) {
          update["selectedProgram"] = null;
        }

        this.setState(update);
      }

      window.NotificationUtils.showConfirm("Deleting program");
      try {
        await window.FortisForma.database.deleteProgram(
          selectedProgram.id,
          this.props.user.id
        );
      } catch {
        window.NotificationUtils.showError("Error deleting program");
      }
      window.NotificationUtils.showSuccess("Program deleted");
    });
  };

  editProgram = (selectedProgram, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.saveProgramToLocal(selectedProgram, () => {
      this.props.history.push("/workout-assigner/edit");
    });
  };

  copyProgram = (selectedProgram, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    this.saveProgramToLocal(selectedProgram, () => {
      this.props.history.push("/workout-assigner/copy");
    });
  };

  saveProgramToLocal = (program, callback) => {
    try {
      program = JSON.stringify({ id: program.id });
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
        program
      );
      callback();
    } catch (e) {
      console.error(e);
    }
  };

  emptyProgramsPlaceholder() {
    return (
      <div className="programListPlaceholder">
        Looks like you haven't created any program yet!
      </div>
    );
  }

  renderProgramList() {
    return (
      <ProgramTemplateList
        ref={(r) => {
          this.listRef = r;
        }}
        data={this.state.programs}
        autoSize={true}
        padding={[0, 0]}
        containerId={"programList"}
        rowHeight={60}
        columnCount={1}
        emptyPlaceholder={this.emptyProgramsPlaceholder()}
        selectedProgram={this.state.selectedProgram}
        onRemove={this.deleteProgram}
        onCopy={this.copyProgram}
        onEdit={this.editProgram}
        onProgramSelect={this.onProgramSelect}
        loadData={this.fetchPrograms}
        pageSize={this.pageSize}
      />
    );
  }

  onAddClick = () => {
    this.setState({
      showSelector: true,
    });
  };

  onCancelSelection = () => {
    this.setState({
      showSelector: false,
    });
  };

  onConfirmSelection = async (selected = {}) => {
    this.setState({
      showSelector: false,
    });
    let size = Object.keys(selected).length;
    window.NotificationUtils.showConfirm(
      `Assinging program to ${size} clients`
    );
    try {
      await window.FortisForma.database.assingProgramToClients(
        this.state.selectedProgram.id,
        selected
      );
      this.getProgramClients(this.state.userId, this.state.selectedProgram.id);
      window.NotificationUtils.showSuccess(
        `Assigned program to ${size} clients`
      );
    } catch (e) {
      window.NotificationUtils.showError(`Error while assigning program`);
      console.error(e);
    }
  };

  render() {
    return (
      <Container
        fluid
        className="programTemplates centerAlignJustify"
        style={{ flexDirection: "column" }}
      >
        <Row
          className="fullWidth fullWHListContainer programsList"
          style={{
            height: "calc(100vh - 120px)",
            marginTop: 16,
            marginRight: 0,
          }}
        >
          <Col lg="4" md="12" sm="12" xs="12" className="programListContainer">
            <div className="programListHeader">Programs</div>
            <div className="programListContent" id="programList">
              {this.renderProgramList()}
            </div>
          </Col>
          <div className="programDetailContainer">
            <div className="programDetailDayStrip">
              <DayItem
                dayNumber={1}
                selected={this.state.selectedDay === 1}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[0]
                  ]
                }
              />
              <DayItem
                dayNumber={2}
                selected={this.state.selectedDay === 2}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[1]
                  ]
                }
              />
              <DayItem
                dayNumber={3}
                selected={this.state.selectedDay === 3}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[2]
                  ]
                }
              />
              <DayItem
                dayNumber={4}
                selected={this.state.selectedDay === 4}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[3]
                  ]
                }
              />
              <DayItem
                dayNumber={5}
                selected={this.state.selectedDay === 5}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[4]
                  ]
                }
              />
              <DayItem
                dayNumber={6}
                selected={this.state.selectedDay === 6}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[5]
                  ]
                }
              />
              <DayItem
                dayNumber={7}
                selected={this.state.selectedDay === 7}
                onDaySelect={this.onDaySelect}
                restDay={
                  !this.state.selectedProgram ||
                  !this.state.selectedProgram.workoutDataMap[
                    this.state.selectedProgram.workoutDaysMap[6]
                  ]
                }
              />
            </div>
            <div className="programDetailContent">
              <ClientList
                clientList={this.state.clientList}
                clientListLoading={this.state.clientListLoading}
                selectedProgram={this.state.selectedProgram}
                onAddClick={this.onAddClick}
              />
              <WorkoutList
                workoutList={this.state.workoutList}
                workoutListLoading={this.state.workoutListLoading}
                selectedProgram={this.state.selectedProgram}
                selectedDay={this.state.selectedDay}
              />
            </div>
          </div>
        </Row>
        <Modal isOpen={this.state.showSelector} backdrop={true}>
          <ClientSelectorModal
            checkedClients={this.state.preChecked}
            user={this.props.user}
            onCancel={this.onCancelSelection}
            onConfirm={this.onConfirmSelection}
          />
        </Modal>
      </Container>
    );
  }
}
