import React, { Component } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FilesPicker from "../filePicker/filePicker";
import { Typography } from "@material-ui/core";

export default class AddImage extends Component {
  renderUploadInput = () => {
    return (
      <FilesPicker
        isMultiple={true}
        onChange={this.props.onFileChange}
        extensions={this.props.extensions || ["jpg", "jpeg", "png"]}
      >
        <div className="addNotesUploadContainer" id="fileUploader">
          <CloudUploadIcon color="primary" />
          <Typography id="uploadImageLabelText" align="center" variant="body2">
            Upload File
          </Typography>
        </div>
      </FilesPicker>
    );
  };

  render() {
    return <React.Fragment>{this.renderUploadInput()}</React.Fragment>;
  }
}
