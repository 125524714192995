import React from 'react';
import { isMobilePhone, isPostalCode } from 'validator';

const PROVINCES = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'];

window.getSuggestionValue = (suggestion) => {
  return suggestion
};

window.renderSuggestion = suggestion => (
  <div className="suggestion">
    {suggestion}
  </div>
);

export default class SignupBase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      province: "",
      provinceSuggestions: [],
      provinces: PROVINCES
    }
  }

  getCellNumber = (value) => {
    return (value ? value.match(/\d+/g).join("") : "");
  }

  getPostal = (value) => {
    return (value ? value.match(/\w+/g).join("") : "");
  }

  isCellNumberValid = (number) => {
    if (!number) {
      return false;
    }
    return isMobilePhone(number, 'en-CA');
  }

  isPostalValid = (postalCode) => {
    if (!postalCode) {
      return false;
    }
    return isPostalCode(postalCode, 'CA');
  }

  onChangePhone = (event) => {
    const formattedValue = window.formatPhone(event.target.value)
    this.setState({
      phone: formattedValue
    })
  }

  parsePostalCode(value) {
    if (!value) {
      return "";
    }
    let match = value.match(/\w+/g);
    let string = "";
    if (match) {
      string = match.join("");
    }
    let parsed = "";
    if (string.length > 3) {
      parsed = string.slice(0, 3) + " " + string.slice(3, string.length);
    } else {
      parsed = string;
    }


    return parsed.toUpperCase();
  }

  filterProvinces = (value) => {
    if (!value) return [];

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let results = inputLength === 0 ? [] : this.state.provinces.filter((name) => {
      return name.toLowerCase().includes(inputValue)
    });

    return results;
  }

  onProvinceSuggestionsFetchRequested = ({
    value
  }) => {
    this.setState({
      provinceSuggestions: this.filterProvinces(value)
    });
  }

  onProvinceSuggestionsClearRequested = () => {
    this.setState({
      provinceSuggestions: []
    });
  }

}
