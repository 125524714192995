import React, { Component } from "react";
import { isMobileOnly } from "react-device-detect";
import { Button } from "reactstrap";
import { Dialog, IconButton } from "@material-ui/core";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import StripeCard from "./stripelistCard";
import AddStripeCardModal from "../../components/modals/stripeCardModal";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

export default class StripeListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: "",
      openDialog: false,
      loading: false,
      cardDetail: null,
    };
    this.fetchStripeCard = this.fetchStripeCard.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  componentDidMount() {
    this.fetchStripeCard();
  }

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.onClose()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  renderLoading = () => {
    if (this.state.loading || this.props.loaderStripe) {
      return <OpaqueLoading />;
    }
  };

  refactorRes = (data) => {
    if (data["default_card"] && data["card_list"].length) {
      const defaultCardIndex = data["card_list"].findIndex(
        (item) => item.id === data["default_card"]
      );
      const defaultCard = data["card_list"].splice(defaultCardIndex, 1)[0];
      data["card_list"].unshift(defaultCard);
      return data;
    } else {
      return data;
    }
  };

  async fetchStripeCard() {
    this.setState({ loading: true });
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }
    try {
      const data = await window.FortisForma.database.stripeCardlisting(
        this.props.user.customerId
      );
      const resp = this.refactorRes(data);
      this.setState({
        cards: resp,
        loading: false,
      });
      try {
        const response = await window.FortisForma.database.stripeCardDetail(
          "plan/detail",
          this.props.showDowngradeModal.productId,
          this.props.user.customerId
        );
        console.log(response, "response");
        this.setState({ cardDetail: response });
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  addNewCard() {
    this.setState({ openDialog: true });
  }

  onClickSave = async (stripeData) => {
    try {
      this.setState({ loading: true });
      const resp = await window.FortisForma.database.stripeCardCall(
        "card/add/",
        stripeData,
        this.props.user.customerId
      );

      window.NotificationUtils.showSuccess(
        resp && resp.message ? resp.message : "Stripe Card added successfully"
      );

      if (resp.message !== "Card added successfully") {
        this.setState({ loading: false });
        return;
      }

      setTimeout(async () => {
        try {
          await this.fetchStripeCard();
          this.handleClose();
        } catch (e) {
          console.error(e);
          window.NotificationUtils.showError("Error in fetching stripe card");
        }
      }, 1000);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
      throw e;
    }
  };

  removeCard = (card) => {
    let data = {
      card_id: card.id,
    };

    window.FortisForma.database
      .stripeCardCall("card/delete/", data, this.props.user.customerId)
      .then((results) => {
        if (!results) {
          console.error(results.data);
          const message =
            (results.data.message.errorInfo &&
              results.data.message.errorInfo.message) ||
            (results.data.message && results.data.message.message) ||
            "Something went wrong!";
          window.NotificationUtils.showError(message);
        } else {
          setTimeout(async () => {
            try {
              await this.fetchStripeCard();
            } catch (e) {
              console.error(e);
              window.NotificationUtils.showError(
                "Error in fetching stripe card"
              );
            }
          }, 1000);
          console.info("stripe card removed", results);
          window.NotificationUtils.showSuccess("Stripe card deleted");
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  setDefaultCard = (card) => {
    let data = {
      card_id: card.id,
    };

    window.FortisForma.database
      .stripeCardCall("card/default/", data, this.props.user.customerId)
      .then((results) => {
        if (!results) {
          console.error(results.data);
          const message =
            (results.data.message.errorInfo &&
              results.data.message.errorInfo.message) ||
            (results.data.message && results.data.message.message) ||
            "Something went wrong!";
          window.NotificationUtils.showError(message);
        } else {
          setTimeout(async () => {
            try {
              await this.fetchStripeCard();
            } catch (e) {
              console.error(e);
              window.NotificationUtils.showError(
                "Error in fetching stripe card"
              );
              window.hideLoadingMessages && window.hideLoadingMessages();
            }
          }, 1000);
          console.info("stripe card default set", results);
          window.NotificationUtils.showSuccess("Now this card is default");
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  handleCheckout = () => {
    if (this.state.cards && !this.state.cards["default_card"]) {
      window.NotificationUtils.showError("Please set default card first!");
      return;
    }
    if (this.state.cards && this.state.cards["card_list"].length > 0) {
      this.props.onClickStripeCheck();
    } else {
      window.NotificationUtils.showError("Add a Card");
    }
  };

  renderFooter = () => {
    return (
      <div className="staffEditorRow stripeModalFooter">
        <Button color="primary" onClick={this.addNewCard}>
          Add New Stripe Card
        </Button>

        <Button
          disabled={this.props.btnDisable}
          color="primary"
          onClick={this.handleCheckout}
        >
          Checkout
        </Button>
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className="memberAddDialogContent trialModal">
        <div className="memberFieldsContainer">
          {this.renderCloseButton()}
          <div className="stripeHeader">
            <h4>
              You are downgrading to the {this.props.showDowngradeModal.title} (
              {this.props.showDowngradeModal.interval}ly).
            </h4>
            <h4>Choose your Default method of payment below.</h4>
          </div>
        </div>
        {this.state.cardDetail !== null && (
          <div className="staffStripeCards">
            <div className="strippe-modal-wrap">
              <div className="staff-card-detailed">
                <div className="staff-card">
                  <h5>
                    {this.state.cardDetail.plan.name}{" "}
                    {this.state.cardDetail.plan.interval === "month"
                      ? "Monthly"
                      : this.state.cardDetail.plan.interval === "year"
                      ? "Yearly"
                      : "Free"}{" "}
                    Package
                  </h5>
                  <div className="card-inf-flex">
                    <div className="flex-item">
                      <p>Package Amount</p>
                      <p>
                        CA${parseFloat(this.state.cardDetail.plan.amount)}.00
                        <sub>
                          Per{" "}
                          {this.state.cardDetail.plan.interval === "month"
                            ? "Month"
                            : "Year"}
                        </sub>
                      </p>
                    </div>
                    <div className="flex-item">
                      <p>Total Amount</p>
                      <p>
                        CA$
                        {parseFloat(
                          this.state.cardDetail.plan.amount +
                            this.state.cardDetail.plan.tax_amount
                        )}
                      </p>
                    </div>
                    <div className="flex-item">
                      <p>Deduction Duration</p>
                      <p>
                        {this.state.cardDetail.plan.interval === "month"
                          ? "30 Days"
                          : this.state.cardDetail.plan.interval === "year"
                          ? "365 Days"
                          : this.state.cardDetail.plan.interval === "day"
                          ? "N/A"
                          : "14 Days"}{" "}
                      </p>
                    </div>
                    <div className="flex-item">
                      <p>Tax</p>
                      <p>
                        CA${parseFloat(this.state.cardDetail.plan.tax_amount)}
                      </p>
                    </div>
                    <div className="flex-item">
                      <p>Discount</p>
                      <p>
                        {this.state.cardDetail.plan.interval !== "year"
                          ? "N/A"
                          : "10%"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderStripeCardDialog = () => {
    return (
      <Dialog
        fullScreen={isMobileOnly ? true : false}
        fullWidth={true}
        open={this.state.openDialog}
        onClose={() => this.handleClose()}
      >
        <AddStripeCardModal
          onClose={() => this.handleClose()}
          onClickSave={this.onClickSave}
          loading={this.state.loading}
        />
      </Dialog>
    );
  };

  renderList = () => {
    return (
      <div
        className="staffMemberListTableContainer stripeCardList"
        id="staffMemberListTableContainer"
        style={{ height: "calc(70vh - 153px)" }}
      >
        <div id="staffListContainer">
          {this.state.cards &&
            this.state.cards["card_list"].map((data) => {
              return (
                <StripeCard
                  data={data}
                  onDelete={this.removeCard}
                  changeDefCard={this.setDefaultCard}
                  default={data.id === this.state.cards["default_card"]}
                />
              );
            })}
        </div>
      </div>
    );
  };

  render() {
    console.log("this.state card", this.state);
    return (
      <>
        {this.renderLoading()}
        {this.renderHeader()}
        {this.renderList()}
        {this.renderFooter()}
        {this.renderStripeCardDialog()}
      </>
    );
  }
}
