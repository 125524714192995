import bodybuilder from "bodybuilder";
import hash from "object-hash";
import React from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import EditorPanel from "../../components/editorPanel/editorPanel.js";
import ExerciseEditCard from "../../components/exerciseEditCard/exerciseEditCard.js";
import Filters from "../../components/filters/exerciseFilters.js";
import { DB_KEYS, RESISTANCE_LEVELS, ROLES } from "../../constants.js";
import Factory from "../../modules/http/dataFetcher";
import ExerciseList from "../exerciseList/exerciseList.js";

export default class ExerciseEditor extends EditorPanel {
  constructor(props) {
    super(props);
    this.exerciseFetcher = Factory.exerciseFetcher();

    this.rootClassName = "exerciseEditor";
    this.state = Object.assign(
      {
        title: "",
        sorted: false,
        filters: null,
        filtersApplied: false,
        showFilters: false,
        errorsModal: false,
      },
      this.state
    );

    this.listRef = null;
    this.pageSize = 20;
    this.sort = false;
    this.bulkUploadErrors = [];

    this.toggleFilters = this.toggleFilters.bind(this);

    this.filtersRef = null;
    this.equipmentValueMap = {};
    this.movementValueMap = {};
    let filters = Object.assign({}, this.state.filters);

    if (!filters || !Object.keys(filters).length) {
      this.state.filters = this.createBasicFilters();
    }
  }

  createBasicFilters() {
    let filters = bodybuilder();

    if (this.props.user[DB_KEYS.ROLE] === ROLES.TRAINER) {
      let trainerId = this.props.user.id || "";
      const { ppEnterprise } = this.props;

      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", trainerId)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
        );
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", trainerId)
            .orFilter("term", "trainerId", "admin")
        );
      }
    }
    if (this.props.user[DB_KEYS.ENTERPRISE_ID]) {
      let enterpriseId = this.props.user.enterpriseId || "";
      let trainerId = this.props.user.id || "";
      if (this.props.user.role === ROLES.FACILITY_ADMIN) {
        if (this.props.ppEnterprise) {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "enterpriseId", enterpriseId)
              .orFilter("term", "trainerId", "admin")
              .orFilter("term", "trainerId", this.props.ppEnterprise.id)
          );
        }
      } else {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "enterpriseId", enterpriseId)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "trainerId", trainerId)
        );
      }
    }
    if (this.props.user.role === ROLES.ADMIN) {
      filters.andFilter("bool", (q) =>
        q.orFilter("term", "trainerId", "admin")
      );
    }

    // else {
    //     // if()
    //     // filters.filter("term", "trainerId", "admin");
    // }

    filters = filters.build();
    // console.log(filters, "filters");
    return filters;
  }

  async fetchData(cleanup = false, updatedFilters) {
    try {
      let results = await this.exerciseFetcher.fetch(
        cleanup ? updatedFilters : this.state.filters,
        cleanup,
        false
      );
      if (results.length > 0) {
        for (let i = 0; i < results.length; i++) {
          results[i].level = Number(results[i].level);
        }
      }
      if (this.state.status === true) {
        results = results.sort((a, b) => a.level - b.level);
        const duplicateIds = results
          .map((v) => v.movementId)
          .filter((v, i, vIds) => vIds.indexOf(v) !== i);
        const duplicates = results.filter((obj) =>
          duplicateIds.includes(obj.movementId)
        );
        const duplicateIds1 = duplicates
          .map((v) => v.equipmentCategories.Primary)
          .filter((v, i, vIds) => vIds.indexOf(v) !== i);
        const duplicates1 = duplicates.filter((obj) =>
          duplicateIds1.includes(obj.equipmentCategories.Primary)
        );
        results = duplicates1;
      }
      if (cleanup) {
        this.setState({
          data: results,
        });
      } else {
        this.setState({
          data: this.state.data.concat(results),
        });
      }
      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  toggleFilters() {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  }

  applyFilters = (filters, filtersData, didApply, status) => {
    this.exerciseFetcher.clearResults();
    this.setState(
      {
        filters: filters,
        status: status,
        filtersApplied: didApply,
        data: [],
      },
      () => {
        this.listRef.reset(true);
      }
    );
    this.toggleFilters();
  };

  clearFilters = () => {
    this.filtersRef.reset();
    this.exerciseFetcher.clearResults();
    this.setState(
      {
        filters: this.createBasicFilters(),
        data: [],
      },
      () => {
        this.listRef.reset(true);
      }
    );
  };

  async save(exercise) {
    this.setState({
      selected: null,
    });

    try {
      exercise.trainerId = "admin"; // TODO: convert to constant
      let results = await window.FortisForma.database.storeExercise(exercise);
      exercise = results;
      let data = Object.assign([], this.state.data);
      let index = window._.findIndex(data, (item) => item.id === exercise.id);
      if (index < 0) {
        index = data.length;
      }
      data[index] = exercise;

      this.setState({
        data: data,
      });
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to save exercise details");
      throw e;
    }
  }

  onDelete = (id) => {
    this.onClickCancel();
    this.setState({
      selected: null,
    });

    try {
      let data = Object.assign([], this.state.data);
      data = data.filter((e) => {
        return e.id !== id;
      });

      this.setState({
        data: data,
      });
    } catch (e) {
      throw e;
    }
  };

  onBulkFileReady(data) {
    let filtersData = this.filtersRef.state;
    if (!filtersData.originalMovements || !filtersData.originalEquipments) {
      setTimeout(() => {
        this.onBulkFileReady(data);
      }, 1000);
      window.NotificationUtils.showConfirm("Preparing data for upload...");
      return false;
    }
    filtersData.originalEquipments.map((e) => {
      this.equipmentValueMap[e.name] = e.type;
      return null;
    });

    filtersData.originalMovements.map((m) => {
      this.movementValueMap[m.name] = {
        category: m.movementCategory,
        movementId: m.movementId,
      };
      return null;
    });
    super.onBulkFileReady(data);
  }

  mapRowToDocument(row, index) {
    // TODO: show which row and value is missing

    let exercise = Object.assign({}, row);
    if (
      !exercise.name ||
      !exercise.level ||
      !exercise.groupId ||
      !exercise.primaryEquipment ||
      !exercise.primaryMuscle ||
      !exercise.primaryFunction ||
      !exercise.movement
    ) {
      this.bulkUploadErrors.push(`Missing values in row ${index + 2}`);
    }
    try {
      exercise.movementId = this.movementValueMap[exercise.movement].movementId;
      exercise.movementId = Number(exercise.movementId);
      if (isNaN(exercise.movementId)) {
        this.bulkUploadErrors.push(
          `Invalid movement id ${exercise.movementId}} for movement ${exercise.movement}`
        );
      }
      exercise.movementCategory =
        this.movementValueMap[exercise.movement].category;
    } catch (e) {
      if (!this.movementValueMap[exercise.movement]) {
        this.bulkUploadErrors.push(
          `Movement "${exercise.movement}" is not yet saved in database`
        );
      } else {
        throw e;
      }
    }

    // TODO: remove hardcode and use localstorage values
    exercise.muscleGroups = {
      Primary: row.primaryMuscle || "",
      Secondary: row.secondaryMuscle || "",
      Tertiary: row.tertiaryMuscle || "",
      Quarterly: row.quarterlyMuscle || "",
      Quinary: row.quinaryMuscle || "",
    };

    exercise.functionCategories = {
      Primary: row.primaryFunction || "",
      Secondary: row.secondaryFunction || "",
      Tertiary: row.tertiaryFunction || "",
    };

    exercise.equipmentCategories = {
      Primary: row.primaryEquipment || "",
      Secondary: row.secondaryEquipment || "",
      Tertiary: row.tertiaryEquipment || "",
    };

    exercise.equipmentTypes = {};
    let types = Object.keys(exercise.equipmentCategories);
    for (let type of types) {
      if (!exercise.equipmentCategories[type]) {
        continue;
      }

      let equipmentType =
        this.equipmentValueMap[exercise.equipmentCategories[type]];
      exercise.equipmentTypes[type] = equipmentType || "";
      if (!equipmentType && equipmentType !== "") {
        this.bulkUploadErrors.push(
          `Equipment "${exercise.equipmentCategories[type]}" is not yet saved in database`
        );
      }
    }

    let supportedResistanceTypes = Object.values(RESISTANCE_LEVELS);

    if (
      exercise.resistance &&
      false ===
        supportedResistanceTypes.includes(exercise.resistance.toUpperCase())
    ) {
      this.bulkUploadErrors.push("Invalid type for exercise resistance");
    }

    let config = {
      resistance: exercise.resistance ? exercise.resistance.toUpperCase() : "",
      reps: exercise.reps ? Number(exercise.reps) : "",
      sets: exercise.sets ? Number(exercise.sets) : "",
      weight: exercise.weight ? Number(exercise.weight) : "",
      time: exercise.duration ? Number(exercise.duration) : "",
    };

    if (
      exercise.alternateNames !== null &&
      typeof exercise.alternateNames !== "undefined"
    ) {
      let alternateNamesString = exercise.alternateNames;
      let alternateNamesArray = alternateNamesString.split(",");
      exercise.alternateNames = alternateNamesArray;
    }

    delete exercise.resistance;
    delete exercise.reps;
    delete exercise.sets;
    delete exercise.weight;
    delete exercise.duration;

    delete exercise.primaryMuscle;
    delete exercise.secondaryMuscle;
    delete exercise.tertiaryMuscle;
    delete exercise.quarterlyMuscle;
    delete exercise.quinaryMuscle;

    delete exercise.primaryFunction;
    delete exercise.secondaryFunction;
    delete exercise.tertiaryFunction;

    delete exercise.primaryEquipment;
    delete exercise.secondaryEquipment;
    delete exercise.tertiaryEquipment;

    // We compute hash early because to avoid frequently updating fields from contributing to hash
    if (!exercise.id) {
      exercise.id = hash(exercise);
    }

    exercise.posterAsShot = true;
    exercise.trainerId = "admin";
    exercise.config = config;
    return exercise;
  }

  async bulkSave(docs) {
    this.bulkUploadErrors = window._.uniq(this.bulkUploadErrors);

    if (this.bulkUploadErrors && this.bulkUploadErrors.length) {
      this.setState({ errorsModal: true });
      let message = `${this.bulkUploadErrors.length} errors found in upload data`;
      window.NotificationUtils.showError(message);
      return;
    }

    try {
      await window.FortisForma.database.bulkUploadExercises(docs);

      let updatedFilters = Object.assign({}, this.state.filters, { from: 0 });
      this.setState({
        filters: updatedFilters,
      });

      await this.fetchData(true, updatedFilters);
    } catch (e) {
      throw e;
    }
  }

  onCloseErrors = () => {
    this.bulkUploadErrors = [];
    this.setState({ errorsModal: false });
  };

  renderExtras() {
    return (
      <Col style={{ flex: 1 }}>
        <Filters
          ref={(ref) => {
            this.filtersRef = ref;
          }}
          isAdmin={this.props.user.role === ROLES.ADMIN}
          user={this.props.user}
          onCancel={this.toggleFilters}
          onSetFilters={this.applyFilters}
          inclusive={true}
          trainerId={this.state.trainerId}
          {...this.props}
        />
      </Col>
    );
  }

  emptyPlaceholder = () => {
    return (
      <div>
        <div>No exercises matching your criteria</div>

        {this.state.filtersApplied ? (
          <div className="filtersSearch internal">
            <Button
              className="clearFiltersButton"
              color="primary"
              onClick={this.clearFilters}
            >
              Clear Filters
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  renderContent() {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.errorsModal} id="errorsModal" backdrop={true}>
          <ModalHeader>Bulk Upload Errors</ModalHeader>
          <ModalBody>
            {this.bulkUploadErrors.map((item, index) => {
              return <Row key={index}>{item}</Row>;
            })}
            <Row>
              <Button
                className="btn btn-outline-secondary"
                onClick={this.onCloseErrors}
              >
                Close
              </Button>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.selected !== null}
          className="exerciseEditorModal"
          backdrop={true}
        >
          <ModalHeader>Exercise Editor</ModalHeader>
          <ModalBody>
            {this.state.selected ? (
              <ExerciseEditCard
                data={this.state.selected}
                onClickCancel={this.onClickCancel}
                onDelete={this.onDelete}
                onClickSave={this.save}
                user={this.props.user}
              />
            ) : null}
          </ModalBody>
        </Modal>

        <div id="exerciseEditor">
          <ExerciseList
            ref={(r) => {
              this.listRef = r;
            }}
            data={this.state.data}
            autoSize={true}
            padding={[0, 0]}
            containerId={"exerciseEditor"}
            rowHeight={296}
            columnWidth={220}
            columnCount={3}
            selectEnabled={true}
            onSelect={this.onSelect}
            selectButtonView={<i className="material-icons">edit</i>}
            loadData={this.fetchData}
            pageSize={this.pageSize}
            emptyPlaceholder={this.emptyPlaceholder()}
          ></ExerciseList>
        </div>
      </React.Fragment>
    );
  }
}
