import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { NavLink } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { ROLES, TIER } from "../../constants";
import CloseIcon from "@material-ui/icons/Close";
import TAButton from "../../utils/TAButton";

const modalContent = {
  Staff:
    "As an enterprise, you can easily manage all of your staff in one place. You can designate roles, and ensure all members of the team are on the same page (literally).",
  Documents:
    "With this feature, you have the ability to store all of the necessary documents for your business all in one place. You have access to registration form templates, assessment form templates, notes, and the ability to create your own individualized forms, helping to make your practice even more efficient.",
  "Update Public Profile":
    "When available, you are able to showcase your unique attributes/characteristics as a coach on the Public Profile page. Anyone (future clients included!) can see your profile, so make sure it up-to-date here, and will encompass your unique attributes as a coach!",
  "Facility Info":
    "Within this section, Fortis Forma can utilize the information so the system can personalize forms and PDFs with your branding when you send them to clients.",
  Messages:
    "With this feature, you can message to your respective clients at any time to get their real time workout updates.You can get this feature by upgrading your subscription.",
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      navItems: Header.getNavItems(props),
      collapsed: false,
      showModal: { value: false, modalTitle: "", button: false },
    };
    this.isEnterpriseAdminRight =
      this.props.user &&
      (this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ENTERPRISE_ADMIN);
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  logout() {
    window.FortisForma.database.signOut();
  }

  changePassword = () => {
    window.changePassword();
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  routeTo = (route) => {
    this.props.history.replace(route);
  };

  static getNavItems(props, dashboard) {
    if (props.user) {
      switch (props.user.role) {
        case ROLES.ADMIN:
          return [
            {
              icon: "device_hub",
              text: "Exercise",
              path: "/exercises",
            },
            {
              icon: "extension",
              text: "Muscle",
              path: "/muscles",
            },
            {
              icon: "rowing",
              text: "Movement",
              path: "/movements",
            },
            {
              icon: "all_inbox",
              text: "Movement Category",
              path: "/movement-categories",
            },
            {
              icon: "cloud",
              text: "Function Category",
              path: "/functions",
            },
            {
              icon: "sports_baseball",
              text: "Equipment",
              path: "/equipments",
            },
            {
              icon: "donut_small",
              text: "Pending Approvals",
              path: "/pending-approvals",
            },
            {
              icon: "account_balance",
              text: "Enterprise Accounts",
              path: "/enterprise-accounts",
            },
            {
              icon: "note_add",
              text: "Forms",
              path: "/forms-management",
            },
            {
              icon: "rss_feed",
              text: "Blogs",
              path: "/blogs",
            },
          ];
        case ROLES.ENTERPRISE_ADMIN:
          return [
            {
              icon: "supervised_user_circle",
              text: "Staff",
              path: "/staff-editor",
            },
            {
              icon: "people",
              text: "Clients",
              path: "/client-management",
            },
            {
              icon: "note_add",
              text: "Documents",
              path: "/documents",
            },
            {
              icon: "device_hub",
              text: "Exercises",
              path: "/exercises",
            },
            {
              icon: "build",
              text: "Designer",
              path: "/workout-designer",
            },
            {
              icon: "assignment_turned_in",
              text: "Workout Templates",
              path: "/workout-days",
            },
            {
              icon: "chat",
              text: "Messages",
              path: "/message-center",
            },
          ];
        case ROLES.FACILITY_ADMIN:
          return [
            {
              icon: "supervised_user_circle",
              text: "Staff",
              path: "/staff-editor",
            },
            {
              icon: "people",
              text: "Clients",
              path: "/client-management",
            },
            {
              icon: "note_add",
              text: "Documents",
              path: "/documents",
            },
            {
              icon: "device_hub",
              text: "Exercises",
              path: "/exercises",
            },
            {
              icon: "build",
              text: "Designer",
              path: "/workout-designer",
            },
            {
              icon: "assignment_turned_in",
              text: "Workout Templates",
              path: "/workout-days",
            },
            {
              icon: "chat",
              text: "Messages",
              path: "/message-center",
            },
          ];
        case ROLES.HEALTH_COACH:
          return [
            {
              icon: "people",
              text: "Clients",
              path: "/client-management",
            },
            {
              icon: "note_add",
              text: "Documents",
              path: "/documents",
            },
            {
              icon: "device_hub",
              text: "Exercises",
              path: "/exercises",
            },
            {
              icon: "build",
              text: "Designer",
              path: "/workout-designer",
            },
            {
              icon: "assignment_turned_in",
              text: "Workout Templates",
              path: "/workout-days",
            },
            {
              icon: "chat",
              text: "Messages",
              path: "/message-center",
            },
          ];
        default:
          // if (props.location.pathname === "/workout-designer") {
          //     return [
          //         {
          //             icon: "supervised_user_circle",
          //             text: "Staff",
          //             path: "/staff-editor"
          //         },
          //         {
          //             icon: "supervised_user_circle",
          //             text: "Clients",
          //             path: "/client-editor"
          //         },
          //         {
          //             icon: "note_add",
          //             text: "Documents",
          //             path: "/documents"
          //         },
          //         {
          //             icon: "build",
          //             text: "Workout Templates",
          //             path: "/workout-days"
          //         },
          //         {
          //             icon: "device_hub",
          //             text: "Exercises",
          //             path: "/exercises"
          //         },
          //         {
          //             icon: "chat",
          //             text: "Messages",
          //             path: "/message-center"
          //         }
          //     ];
          // } else if (props.location.pathname === "/workout-days") {
          //     return [
          //         {
          //             icon: "supervised_user_circle",
          //             text: "Staff",
          //             path: "/staff-editor"
          //         },
          //         {
          //             icon: "supervised_user_circle",
          //             text: "Clients",
          //             path: "/client-editor"
          //         },
          //         {
          //             icon: "note_add",
          //             text: "Documents",
          //             path: "/documents"
          //         },
          //         {
          //             icon: "build",
          //             text: "Designer",
          //             path: "/workout-designer"
          //         },
          //         {
          //             icon: "device_hub",
          //             text: "Exercises",
          //             path: "/exercises"
          //         },
          //         {
          //             icon: "chat",
          //             text: "Messages",
          //             path: "/message-center"
          //         }
          //     ];
          // } else {
          return [
            {
              icon: "supervised_user_circle",
              text: "Staff",
              path: "/staff-editor",
            },
            {
              icon: "people",
              text: "Clients",
              path: "/client-editor",
            },
            {
              icon: "note_add",
              text: "Documents",
              path: "/documents",
            },
            {
              icon: "build",
              text: "Designer",
              path: "/workout-designer",
            },
            {
              icon: "assignment_turned_in",
              text: "Workout Templates",
              path: "/workout-days",
            },
            {
              icon: "device_hub",
              text: "Exercises",
              path: "/exercises",
            },
            {
              icon: "chat",
              text: "Messages",
              path: "/message-center",
            },
          ];
        // }
      }
    } else {
      return [];
    }
  }

  isfacilityInfoCompleted() {
    if (this.props.user.enterprise) {
      const { name, address, city, fax, phone, postalCode, province } =
        this.props.user.enterprise;

      return Boolean(
        name && address && city && fax && phone && postalCode && province
      );
    } else {
      return false;
    }
  }

  isProfileCompleted() {
    const { name, address, city, email, phone, postalCode, province } =
      this.props.user;
    if (this.props.user.role === "Admin") {
      return true;
    } else {
      return Boolean(
        name && address && city && email && phone && postalCode && province
      );
    }
  }

  renderPopupModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal.value}
        style={{ maxWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          this.setState({
            showModal: {
              value: !this.state.showModal.value,
              modalTitle: "",
              button: false,
            },
          });
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                showModal: { value: false, modalTitle: "", button: false },
              });
            }}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#800520",
              fontWeight: "bold",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            {this.state.showModal.modalTitle}
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            textAlign: "center",
            paddingBottom: "44px",
          }}
        >
          <>{modalContent[this.state.showModal.modalTitle]}</>
          {this.state.showModal.button === true && (
            <TAButton
              width="60%"
              color="primary"
              variant="contained"
              height="60px"
              style={{ marginTop: "25px" }}
              // {...activeButtonProps}
              onClick={() => {
                if (this.props.history.location.pathname === "/upgrade") {
                  this.setState({
                    showModal: { value: false, modalTitle: "", button: false },
                  });
                } else {
                  this.props.history.push("/upgrade");
                }
              }}
            >
              Upgrade to Unlock this Feature
            </TAButton>
            // <div
            //   style={{
            //     padding: "20px",
            //     margin: "0 auto",
            //     borderRadius: "15px",
            //     fontSize: "14px",
            //     fontWeight: "bold",
            //     color: "white",
            //     marginTop: "25px",
            //     width: "60%",
            //     // minWidth: "max-content",
            //     backgroundColor: "#800520",
            //     letterSpacing: "1px",
            //   }}
            // >
            //   Upgrade to Unlock this Feature
            // </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  shouldShowFacilityInfoOption() {
    if (this.props.user) {
      if (
        this.props.user.role !== ROLES.ADMIN &&
        this.props.user.role !== ROLES.HEALTH_COACH
      ) {
        return true;
      }
    }
    return false;
  }

  render() {
    let component = this._render();
    return (
      <div>
        <CSSTransition timeout={30000} classNames="star">
          {component}
        </CSSTransition>
      </div>
    );
  }

  _render() {
    if (this.props.size === "lg") {
      return (
        <Container key="header" className="header header-large">
          <Row>
            <Col lg="8" md="8" sm="8" xs="8">
              <h2 id="welcomeMessage">Hello, {this.props.user.name}</h2>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" style={{ textAlign: "right" }}>
              <NavLink to={"/profile"} className="nav-link" activeClassName="">
                <Button className="btn btn-simple btn-round">
                  Edit Account
                </Button>
              </NavLink>
            </Col>
            <Col lg="2" md="2" sm="2" xs="2" style={{ textAlign: "center" }}>
              <Button className="btn btn-round" onClick={this.logout}>
                Logout
              </Button>
            </Col>
          </Row>
          <Row>
            {this.state.navItems.map((navItem) => {
              return (
                <Col key={navItem.path}>
                  <Card className="center">
                    <div className="primaryNavWithIcon">
                      <i className="icon material-icons">{navItem.icon}</i>
                      <NavLink
                        to={navItem.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <Button color="primary" className="text">
                          {navItem.text}
                        </Button>
                      </NavLink>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      );
    } else {
      return (
        <Navbar color="dark" dark expand="lg">
          <NavbarToggler onClick={this.toggleNavbar} />
          <NavbarBrand
            href="#"
            onClick={() => {
              this.routeTo("/dashboard");
            }}
            className="mr-auto nav-item"
          >
            Home
          </NavbarBrand>
          <Collapse timeout={250} isOpen={this.state.collapsed} navbar>
            <Nav className="ml-auto" navbar>
              {this.state.navItems.map((navItem) => {
                return (
                  <li className="nav-item" key={navItem.path}>
                    {this.props.user.role === ROLES.TRAINER &&
                    this.props.user.tier === TIER.FREE &&
                    (navItem.text === "Staff" ||
                      navItem.text === "Documents") ? (
                      <li
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            showModal: {
                              value: true,
                              modalTitle: navItem.text,
                              button: true,
                            },
                          })
                        }
                      >
                        {navItem.text}
                      </li>
                    ) : this.props.user.role === ROLES.TRAINER &&
                      this.props.user.tier === TIER.PRIVATE &&
                      navItem.text === "Staff" ? (
                      <li
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            showModal: {
                              value: true,
                              modalTitle: navItem.text,
                              button: true,
                            },
                          })
                        }
                      >
                        {navItem.text}
                      </li>
                    ) : this.props.user.role === ROLES.TRAINER &&
                      this.props.user.tier === TIER.FREE &&
                      (navItem.text === "Staff" ||
                        navItem.text === "Documents" ||
                        navItem.text === "Messages") ? (
                      <li
                        className="nav-link"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            showModal: {
                              value: true,
                              modalTitle: navItem.text,
                              button: true,
                            },
                          })
                        }
                      >
                        {navItem.text}
                      </li>
                    ) : (
                      <NavLink
                        to={navItem.path}
                        className={
                          "nav-link " +
                          (this.props.location.pathname.includes(navItem.path)
                            ? "active"
                            : "")
                        }
                      >
                        {navItem.text}
                      </NavLink>
                    )}
                  </li>
                );
              })}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  My Account
                </DropdownToggle>
                <DropdownMenu right>
                  {this.props.user.role &&
                    this.props.user.role !== ROLES.ADMIN && (
                      <>
                        <DropdownItem
                          style={{ fontWeight: "600", color: "black" }}
                          onClick={() => {
                            if (this.props.user.role !== ROLES.ADMIN) {
                              this.setState({
                                showModal: {
                                  value: true,
                                  modalTitle: "Update Public Profile",
                                  button: false,
                                },
                              });
                            } else {
                              // this.routeTo("/onboarding")
                            }
                          }}
                        >
                          Update Public Profile
                          <p style={{ textAlign: "center" }}>coming soon</p>
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    )}
                  <DropdownItem
                    style={{
                      fontWeight: "600",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => this.routeTo("/profile")}
                  >
                    Edit Account
                    {!this.isProfileCompleted() && (
                      <i
                        className="material-icons"
                        style={{
                          color: "#800520",
                          width: "10px",
                          fontSize: "10px",
                          padding: 0,
                          margin: 0,
                          // marginLeft: "20px",
                        }}
                      >
                        circle_icon
                      </i>
                    )}
                  </DropdownItem>
                  {this.shouldShowFacilityInfoOption() && (
                    <>
                      <DropdownItem divider />
                      <DropdownItem
                        style={{
                          fontWeight: "600",
                          color: "black",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        onClick={() => {
                          if (this.props.user.role === ROLES.TRAINER) {
                            this.setState({
                              showModal: {
                                value: true,
                                modalTitle: "Facility Info",
                                button: true,
                              },
                            });
                          } else {
                            this.routeTo("/facility-info");
                          }
                        }}
                      >
                        Facility Info
                        {this.isEnterpriseAdminRight &&
                          !this.isfacilityInfoCompleted() && (
                            <i
                              className="material-icons"
                              style={{
                                color: "#800520",
                                width: "10px",
                                fontSize: "10px",
                                padding: 0,
                                margin: 0,
                                // marginLeft: "20px",
                              }}
                            >
                              circle_icon
                            </i>
                          )}
                      </DropdownItem>
                    </>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    style={{ fontWeight: "600", color: "black" }}
                    onClick={this.changePassword}
                  >
                    Change Password
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    style={{ fontWeight: "600", color: "black" }}
                    onClick={this.logout}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
          {this.renderPopupModal()}
        </Navbar>
      );
    }
  }
}
