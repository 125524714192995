import React from "react";

import TrainerCard from "./trainerCard";

import InfiniteList from "../../components/infiniteList/list.js";

export default class TrainerList extends InfiniteList {
  loadNextPage() {
    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  rowRenderer({ key, index, isScrolling, style }) {
    let data = this.props.data[index];

    if (!this.props.selectedTrainer) {
      return (
        <div style={style} key={key}>
          <TrainerCard
            onContactTrainer={this.props.onContactTrainer}
            toggleTrainerViewability={this.props.toggleTrainerViewability}
            onSelect={this.props.onSelect}
            data={data}
          />
        </div>
      );
    } else {
      return (
        <div style={style} key={key}>
          <TrainerCard
            onContactTrainer={this.props.onContactTrainer}
            toggleTrainerViewability={this.props.toggleTrainerViewability}
            onSelect={this.props.onSelect}
            selected={this.props.selectedTrainer.id === data.id}
            data={data}
          />
        </div>
      );
    }
  }
}
