import React, { Component } from "react";
import { Button, Typography, Avatar, Chip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export default class TrainerCard extends Component {
  renderTrainerInfo = (title, info) => {
    return (
      <div className="flexColumn pd-top-16">
        <Typography variant="caption" className="trainerInfoTitle">
          {title}
        </Typography>
        <div>
          {!Array.isArray(info) ? (
            <Typography variant="body2">{info}</Typography>
          ) : (
            <ul className="educationList">
              {info.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="displayFlex">
                      <Typography variant="body2">{item.name}</Typography>
                      {Boolean(item.link) && (
                        <OpenInNewIcon
                          className="certificateIcon"
                          onClick={() => window.open(item.link, "_blank")}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  };

  render() {
    let trainerData = this.props.trainerData;
    let shortCodes = [];
    if (
      trainerData.documents &&
      trainerData.documents.educationAndCertification
    ) {
      for (let edu of trainerData.documents.educationAndCertification) {
        if (edu.shortCode) {
          shortCodes.push(edu.shortCode);
        }
      }
    }
    return (
      <div
        className={`trainerCard trainerCardRadius ${
          this.props.isFlipped && "opacityZero"
        }`}
      >
        <div className="trainerCardContentContainer">
          <div className="trainerProfileTopContainer">
            <Avatar
              alt={trainerData.name}
              src={trainerData.picURL}
              className="trainerProfilePic"
            />
            <div className="trainerMainDetails">
              <Typography variant="h6" style={{ fontWeight: "800" }}>
                {trainerData.name}
              </Typography>
              <div className="displayRow">
                {Boolean(shortCodes && shortCodes.length) &&
                  shortCodes.map((code, index) => {
                    return (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={code}
                        size="small"
                        className="trainerChip"
                      />
                    );
                  })}
              </div>
              <Typography
                variant="caption"
                style={{ color: "grey", marginBottom: 8 }}
              >
                {trainerData.valueProposition}
              </Typography>
            </div>
          </div>
          <div>
            {this.renderTrainerInfo("Bio", trainerData.bio)}
            {this.renderTrainerInfo(
              "Education, Professional Memberships & Certifications",
              trainerData.documents &&
                trainerData.documents.educationAndCertification
            )}
          </div>
        </div>

        <div className="trainerProfileButtons">
          <Button
            variant="contained"
            color="primary"
            className="mg-tp-16"
            size="small"
            onClick={this.props.showPrograms}
          >
            View Offered Services
          </Button>
          <Button
            variant="contained"
            className="mg-tp-16"
            size="small"
            onClick={this.props.onClose}
            color="secondary"
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
}
