import { Chip, Divider, Typography } from "@material-ui/core";
import React from "react";
import ExportPDFButton from "./exportPDFButton";
import ItemContent from "./noteAssessmentItem/itemContent";
import _ from "lodash";
import moment from "moment";

export default function NoteAssessmentVersionItem({
  item,
  onClickViewForm,
  onExport,
}) {
  const onClickView = () => {
    let submissionData;
    if (item.formData) {
      submissionData = {
        formData: item.formData,
      };
    }

    let formData = {
      id: item.formId,
      submissionData: submissionData || {},
      clientId: item.clientId || "",
      enterpriseId: item.enterpriseId || "",
    };
    if (item.submissionId) {
      formData.submissionId = item.submissionId;
    }

    onClickViewForm(formData);
  };

  const renderCreatorDetails = () => {
    if (item && _.has(item, "creatorId")) {
      return (
        <>
          <Typography component="span" className="lockedContentText">
            &nbsp;Created by&nbsp;
            <span className="bold500">{item.creator.name}</span>
            {item.creator && item.creator.designation && (
              <Chip
                label={item.creator.designation}
                size="small"
                style={{ marginLeft: "4px", height: "18px" }}
                variant="outlined"
                color="primary"
              />
            )}
          </Typography>
        </>
      );
    }
  };

  const renderItemContent = () => {
    return (
      <>
        <ItemContent data={item} onClickView={onClickView} />
      </>
    );
  };

  const renderExportIcon = () => {
    return (
      <ExportPDFButton
        data={item}
        onExport={onExport}
        disableExportOptions={true}
      />
    );
  };

  const renderItemActionArea = () => {
    return (
      <div>
        <Divider style={{ width: "100%", margin: "8px 0 4px 0 " }} />
        <div
          className={
            item && _.has(item, "creatorId")
              ? "displayFlex justifyContentSpaceBetween"
              : "flexEnd"
          }
        >
          {renderCreatorDetails()}
          {renderExportIcon()}
        </div>
      </div>
    );
  };

  return (
    <div
      className="noteVersionItem"
      style={{ backgroundColor: item.color || "#fff" }}
    >
      <div className="noteVersionItemHeader">
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          className="noteVersionTimestamp"
        >
          {moment
            .unix(window.getTimestampSeconds(item.updatedTime))
            .format("DD MMM, YYYY hh:mm A")}
        </div>
      </div>
      {renderItemContent()}
      {renderItemActionArea()}
    </div>
  );
}
