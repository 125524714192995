import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";

const StyledEditor = styled(Editor)`
  border: 1px solid #f1f1f1 !important;
`;

export default class RichTextField extends React.Component {
  state = {
    editorState: null,
    contentState: null,
  };

  async componentDidMount() {
    const blocksFromHtml = htmlToDraft(this.props.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
    });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    let text = editorState.getCurrentContent().getPlainText();
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onChange && this.props.onChange(text, html);
  };

  render() {
    return (
      <StyledEditor
        editorState={this.state.editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={this.onEditorStateChange}
        placeholder={this.props.placeholder || ""}
      />
    );
  }
}
