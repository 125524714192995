import {
  Button as MUIButton,
  Dialog,
  Drawer,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Fuse from "fuse.js";
import MessageIcon from "@material-ui/icons/Message";
import React from "react";
import { BubbleLoader } from "react-css-loaders";
import { isMobile, isTablet } from "react-device-detect";
import Joyride from "react-joyride";
import {
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavLink,
  Row,
} from "reactstrap";
import styled from "styled-components";
import CustomAlert from "../../components/modals/customAlert";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import {
  CLIENT_DETAIL_TABS,
  COLLECTIONS,
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  PROGRAM_TYPES,
  ROLES,
  TIER,
  WALKTHROUGH,
  WORKOUT_SHARE_URL,
  WORKOUT_TYPE,
} from "../../constants";
import { WORKOUT_ASSIGNER_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import { assignTemplateToClient } from "../workoutdesigner/workoutdesigner";
import WorkoutList from "../workoutList/workoutList";
import DayCard from "./dayCard";
import ProgramMetaForm from "./ProgramMetaForm";
import ProgramCompleteMessageEditor from "./ProgramCompleteMessageEditor";
import SearchInput from "../../components/filters/searchInput";
import ShareWorkoutModal from "../dashboard/workoutdays/shareWorkoutModal";
import copy from "copy-to-clipboard";
import { showClientDetailsToUser } from "../enterpriseClientDetails/clientDetails";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import ProgramSuccessfullyAssignedModal from "./enterpriseWorkoutAssinedModal";
import CloseIcon from "@material-ui/icons/Close";
import TAButton from "../../utils/TAButton";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";

const modalContent = {
  Notes:
    "With this feature, you are able to access all workout notes, clinical notes, or any other miscellaneous notes you have with the specified client, here, instead of in a notebook or on an Excel sheet. The notes section makes it easier to find notes regarding a client, and to update them after a session together.",
  Assessment:
    "In this section, you are able to organize all assessment forms for a particular client here. Physical assessment forms, consultation forms, strength test forms, or other individualized forms that you have created can be easily found and updated in this section to help keep your clients' information up-to-date",
  Forms:
    "Have all of the designated forms for a specific client in one place for easy organization and quick reference. You are able to filter the forms, see the status/type of forms, and add new forms for the specified client here.",
  Messages:
    "With this feature, you can message to your respective clients at any time to get their real time workout updates.You can get this feature by upgrading your subscription.",
};

const ProgramFinishMessageToggleButton = styled(IconButton)`
  margin-left: 8px !important;
`;

const MetaDrawerContent = styled.div`
  width: 600px;
  padding: 16px;
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 8px;
`;

const SwitchContainer = styled.div`
  flex: 1;
  text-align: right;
`;

const MODES = {
  ASSIGN: 0,
  EDIT: 1,
  COPY: 2,
  CREATE: 3, // Used by Admin for creating public program or challenges
};

const EDITING_WORKOUT_FROM_CLIENT = "editingWorkoutFromClient";

const EMPTY_WEEK_DAYS = new Array(7).fill(null);
const EMPTY_WEEK_NAME_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const TRAINER_ASSIGNER_ROUTE = "/workout-assigner";

const ENTERPRISE_ASSIGNER_ROUTE = "/client-details?tab=3";

export function assignWorkoutToUser(
  user,
  history,
  workoutDay,
  enterpriseId,
  currentUser
) {
  if (
    history &&
    history.location &&
    (history.location.pathname === TRAINER_ASSIGNER_ROUTE ||
      `${history.location.pathname}${history.location.search}` ===
        ENTERPRISE_ASSIGNER_ROUTE)
  ) {
    return;
  }
  let details = {};

  details.clientName = user.name;
  details.clientEmail = user.email;
  details.clientId = user.id;
  details.clientDOB = user.dob;
  details.role = user.role;
  details.chatTrainer = user.chatTrainer;
  details.clientLoginPending = user.pendingLogin;

  if (workoutDay) {
    details.workoutToAssign = workoutDay.id;
    details.workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    // details.workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    if (
      details.workoutTypeValue === WORKOUT_TYPE.CLIENT_WORKOUT &&
      user.id !== workoutDay.clientId
    ) {
      // details.workoutTypeValue = WORKOUT_TYPE.ALL;
      details.workoutTypeValue = WORKOUT_TYPE.TRAINER;
    }
  }
  if (workoutDay) {
    if (workoutDay.buttonType) {
      details.buttonStatus = workoutDay.buttonType;
    }
  }
  details = JSON.stringify(details);
  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
      details
    );

    if (enterpriseId || currentUser.tier !== TIER.FREE) {
      showClientDetailsToUser(user);
      // history && history.push(ENTERPRISE_ASSIGNER_ROUTE);

      history && history.push(ENTERPRISE_ASSIGNER_ROUTE);
    } else {
      // if()
      showClientDetailsToUser(user);

      history && history.push(TRAINER_ASSIGNER_ROUTE);
    }

    // showClientDetailsToUser(user);
    // history && history.push(ENTERPRISE_ASSIGNER_ROUTE);
  } catch (e) {
    console.error(e);
    window.NotificationUtils.showError("Unable to access local storage");
  }
}

export default class WorkoutAssigner extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      walkthrough: false,
      selectedDraftIds: [],
      selectedDeleteDrafts: [],
      clientId: "",
      selectedIndex: -1,
      trainerWorkouts: [],
      showWorkouts: false,
      loaderModal: false,
      selectedDays: EMPTY_WEEK_DAYS.slice(),
      selectedCalenderDays: EMPTY_WEEK_DAYS.slice(),
      selectedDayName: "Monday",
      displayDaysTypes: true,
      switchBwBtn: false,
      selectedWorkouts: {},
      selectedCalenderWorkouts: {},
      selectedDayWorkout: {},
      workoutCompleteMessages: {},
      workoutStartMessages: {},
      showAlert: false,
      id: "",
      name: "",
      steps: WORKOUT_ASSIGNER_WALKTHROUGH.steps,
      workoutTypeValue: WORKOUT_TYPE.CLIENT_WORKOUT,
      listLoading: true,
      repeating: true,
      showMetaEditorDialog: false,
      metadata: {},
      isPublicWorkout: false,
      programCompleteMessage: "",
      showProgramMessageEditor: false,
      searchValue: "",
      allTrainerWorkouts: [],
      pageNumber: 0,
      previousPageNumber: 0,
      lastFetched: null,
      firstFetched: null,
      openShareModal: false,
      endOfList: false,
      tabChanged: false,
      openProgramAssignedModal: false,
      workoutDataToShare: null,
      step: CLIENT_DETAIL_TABS.WORKOUTS,
      shouldOpenEditor: false,
      showModal: { value: false, modalTitle: "", button: false },
      chatTrainer: [],
    };

    this.state.mode = this.getMode();

    if (this.state.mode === MODES.CREATE) {
      this.state.workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
      this.state.isPublicWorkout = true;
    }
    this.options = {
      includeScore: true,
      keys: ["name"],
      shouldSort: true,
      threshold: 0.6,
      filter: (result, search) => {
        const nameWords = result.item.name.split(" ");
        const firstWord = nameWords[0];
        return firstWord.toLowerCase() === search.toLowerCase();
      },
    };
    this.toggleWorkouts = this.toggleWorkouts.bind(this);
    this.toggleWorkoutsForCalenderDays =
      this.toggleWorkoutsForCalenderDays.bind(this);
    this.onWorkoutSelect = this.onWorkoutSelect.bind(this);
    this.onWorkoutSelectForCalender =
      this.onWorkoutSelectForCalender.bind(this);
    this.assignToAll = this.assignToAll.bind(this);
    this.assignToAllForCalender = this.assignToAllForCalender.bind(this);
    this.addNewWorkout = this.addNewWorkout.bind(this);
    this.removeWorkout = this.removeWorkout.bind(this);
    this.removeWorkoutCalender = this.removeWorkoutCalender.bind(this);
    this._cleanState = this._cleanState.bind(this);
    this.clean = this.clean.bind(this);
    this.save = this.save.bind(this);
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.WORKOUT_ASSIGNER;

    this.selectClientWorkout = this.selectTab.bind(
      this,
      WORKOUT_TYPE.CLIENT_WORKOUT
    );
    this.selectMyWorkout = this.selectTab.bind(this, WORKOUT_TYPE.TRAINER);
    this.selectAll = this.selectTab.bind(this, WORKOUT_TYPE.ALL);
    this.selectChallengeWorkout = this.selectTab.bind(
      this,
      WORKOUT_TYPE.CHALLENGE_WORKOUT
    );
    this.pageSize = 10;
    this.fuse = new Fuse(this.state.allTrainerWorkouts, this.options);
  }

  toggleRepeating = (event) => {
    this.setState({
      repeating: event.target.checked,
    });
  };
  toggleDaysTypes = (event) => {
    this.setState({
      displayDaysTypes: event.target.checked,
    });
  };

  getMode() {
    if (this.props.match && this.props.match.params) {
      switch (this.props.match.params.mode) {
        case "copy":
          return MODES.COPY;
        case "edit":
          return MODES.EDIT;
        case "create":
          return MODES.CREATE;
        case "copy-draft":
          return MODES.COPY;
        case "edit-draft":
          return MODES.EDIT;
        case "create-draft":
          return MODES.CREATE;
        default:
          return MODES.ASSIGN;
      }
    }
  }

  setState(newState, callback) {
    super.setState(newState, () => {
      if (callback) {
        callback();
      }
      let cleanState = this._cleanState();
      let copy = Object.assign(this.state, newState, cleanState);

      this.storeStateInLocal(copy);
    });
  }

  isAdminAccess() {
    return this.props.user.role === ROLES.ADMIN;
  }

  componentDidMount() {
    this.restoreStateFromLocalStorage(
      async (storedState) => {
        let state = { ...this.state, ...storedState };

        let status = localStorage.getItem("template_name");

        if (localStorage.getItem("template_name") === "draft") {
          this.setState(
            {
              switchBwBtn: true,
            },
            () => {
              this.fetchWorkouts();
            }
          );
        } else {
          this.setState(
            {
              switchBwBtn: false,
            },
            async () => {
              await this.fetchWorkouts(state);
            }
          );
        }
        if (isMobile && !isTablet) {
          this.setState({
            showAlert: true,
          });
        }

        if (!storedState.selectedDays && this.state.displayDaysTypes) {
          await this.onStateRestored(storedState);
        } else if (!storedState.selectedCalenderDays) {
          await this.onStateRestored(storedState);
        }
        if (this.state.id === "") {
          let result = await window.FortisForma.database.getRandomDocId(
            COLLECTIONS.WORKOUT_PROGRAM
          );
          this.setState({
            id: result,
          });
        }

        await this.toggleWorkouts(0);
        await this.toggleWorkoutsForCalenderDays(0);
      },
      () => {
        // new workout selected
        let editing = window.localStorage.getItem(
          EDITING_WORKOUT_FROM_CLIENT,
          false
        );

        if (editing === "true" && !this.state.isPublicWorkout) {
          this.save(true);
          window.localStorage.removeItem(EDITING_WORKOUT_FROM_CLIENT);
        }
      }
    );
    super.componentDidMount();
  }

  componentWillUnmount() {
    this.setState({ searchValue: "" });
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  async onStateRestored(storedState) {
    switch (this.state.mode) {
      case MODES.ASSIGN:
        await this.fetchClientProgram(
          storedState.clientId,
          storedState.clientLoginPending
        );
        break;
      case MODES.COPY:
        break;
      case MODES.EDIT:
        await this.fetchProgram(storedState.id);
        break;
      case MODES.CREATE:
        break;
      default:
        throw new Error("Invalid mode");
    }
  }

  async fetchProgram(id) {
    window.NotificationUtils.showSuccess("Fetching Program");
    try {
      let result = await window.FortisForma.database.getProgram(id);

      let days = Object.assign([], result.workoutDaysMap);
      let workouts = Object.assign({}, result.workoutDataMap);
      let workoutCompleteMessages = Object.assign(
        {},
        result.workoutCompleteMessages
      );
      let workoutStartMessages = Object.assign({}, result.workoutStartMessages);
      let metadata = {};
      if (result.public) {
        metadata.name = result.name;
        metadata.about = result.about;
        metadata.value = result.value;
        metadata.picURL = result.picURL;
        metadata.price = result.price;
        metadata.prerequisites = result.prerequisites;
        metadata.trial = result.trial || false;
      }

      let repeating = result.repeating;
      if (repeating === undefined) {
        repeating = true;
      }
      if (result.displayDaysTypes === true) {
        this.setState({
          id: result.id,
          name: result.name,
          displayDaysTypes: false,
          selectedCalenderDays: days,
          selectedCalenderWorkouts: workouts,
          repeating: Boolean(repeating),
          metadata: metadata,
          isPublicWorkout: result.public,
          workoutCompleteMessages,
          workoutStartMessages,
          programCompleteMessage: result.programCompleteMessage || "",
        });
      } else {
        this.setState({
          id: result.id,
          name: result.name,
          selectedDays: days,
          displayDaysTypes: true,
          selectedWorkouts: workouts,
          repeating: Boolean(repeating),
          metadata: metadata,
          isPublicWorkout: result.public,
          workoutCompleteMessages,
          workoutStartMessages,
          programCompleteMessage: result.programCompleteMessage || "",
        });
      }

      if (result.public) {
        this.selectTab(WORKOUT_TYPE.CHALLENGE_WORKOUT);
      }
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Error Fetching Program");
    }
  }

  async fetchClientProgram(clientId, pendingLogin, callback) {
    window.NotificationUtils.showSuccess("Fetching Client's Program");
    try {
      let result = await window.FortisForma.database.getUserProgram(
        clientId,
        pendingLogin
      );

      let days = Object.assign([], result.workoutDaysMap);

      let workouts = {};
      let workoutCompleteMessages = Object.assign(
        {},
        result.workoutCompleteMessages
      );
      let workoutStartMessages = Object.assign({}, result.workoutStartMessages);

      let repeating = result.repeating;
      if (repeating === undefined) {
        repeating = true;
      }
      let status = result.workoutDisplayType;

      if (status === true) {
        workouts = Object.assign({}, result.workoutDataMap);
        this.setState(
          {
            id: result.id,
            name: result.name,
            displayDaysTypes: false,
            startDate: result.startDate || this.state.startDate || "",
            selectedCalenderDays: days,
            selectedCalenderWorkouts: workouts,
            repeating: repeating,
            workoutCompleteMessages,
            workoutStartMessages,
            programCompleteMessage: result.programCompleteMessage || "",
          },
          () => {
            if (callback) {
              callback();
            }
          }
        );
      } else {
        workouts = Object.assign({}, result.workoutDataMap);
        this.setState(
          {
            id: result.id,
            name: result.name,
            displayDaysTypes: true,
            startDate: result.startDate || this.state.startDate || "",
            selectedDays: days,
            selectedWorkouts: workouts,
            repeating: repeating,
            workoutCompleteMessages,
            workoutStartMessages,
            programCompleteMessage: result.programCompleteMessage || "",
          },
          () => {
            if (callback) {
              callback();
            }
          }
        );
      }
    } catch (e) {
      if (e.code && e.code === 404) {
        window.NotificationUtils.showConfirm(e.message);
      } else {
        console.error(e);
      }
    }
  }

  async restoreStateFromLocalStorage(callback, newWorkoutSetCallback) {
    try {
      const storedState = JSON.parse(
        window.localStorage.getItem(
          LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS
        )
      );
      if (storedState.chatTrainer) {
        this.setState({ chatTrainer: storedState.chatTrainer });
      } else {
        this.setState({ chatTrainer: [] });
      }
      if (storedState.buttonStatus) {
        this.setState({
          switchBwBtn: storedState.buttonStatus,
        });
      }
      const newWorkout = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_RESULT)
      );

      this.setState(
        {
          ...storedState,
          mode: this.getMode(),
        },
        () => {
          if (storedState) {
            if (callback) {
              callback(storedState || {});
            }
          }
          if (newWorkout) {
            this.onWorkoutSelect(newWorkout, () => {
              this.resetLocalStorage();
              newWorkoutSetCallback &&
                newWorkoutSetCallback(storedState, newWorkout);
            });
          }
        }
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  }

  removeFromLocal(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  }

  async fetchWorkouts(state) {
    this.setState({
      listLoading: true,
    });
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    let query = {};

    let pageConfig = {
      limit: this.pageSize,
    };

    pageConfig.orders = [];
    if (this.state.lastFetched && this.state.firstFetched) {
      if (this.state.pageNumber > this.state.previousPageNumber) {
        pageConfig.orders.push({
          key: "createdTime",
          after: this.state.lastFetched.createdTime,
          direction: "desc",
        });
      } else if (this.state.pageNumber < this.state.previousPageNumber) {
        pageConfig.orders.push({
          key: "createdTime",
          before: this.state.firstFetched.createdTime,
          direction: "desc",
        });
      }
    } else {
      pageConfig.orders.push({
        key: "createdTime",
        direction: "desc",
      });
    }

    query.collection = COLLECTIONS.WORKOUT_DAYS;

    if (currentUser.enterpriseId) {
      query.filters = [
        {
          key: DB_KEYS.ENTERPRISE_ID,
          operator: "==",
          value: currentUser.enterpriseId,
        },
      ];
    } else {
      query.filters = [
        {
          key: DB_KEYS.TRAINER_ID_KEY,
          operator: "==",
          value: currentUser.id,
        },
      ];
    }

    if (!state) {
      state = this.state;
    }
    if (state.workoutTypeValue === WORKOUT_TYPE.ALL) {
      //show all
    } else if (state.workoutTypeValue === WORKOUT_TYPE.TRAINER) {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.TRAINER,
      });
    } else if (state.workoutTypeValue === WORKOUT_TYPE.CHALLENGE_WORKOUT) {
      query.filters.push({
        key: "type",
        operator: "==",
        value: WORKOUT_TYPE.CHALLENGE_WORKOUT,
      });
      query.filters.push({
        key: "challengeId",
        operator: "==",
        value: state.id,
      });
    } else {
      if (
        state.workoutTypeValue !== WORKOUT_TYPE.ALL &&
        state.workoutTypeValue !== WORKOUT_TYPE.CHALLENGE_WORKOUT &&
        state.workoutTypeValue !== WORKOUT_TYPE.TRAINER
      ) {
        query.filters.push({
          key: "type",
          operator: "==",
          value: WORKOUT_TYPE.CLIENT_WORKOUT,
        });

        query.filters.push({
          key: "clientId",
          operator: "==",
          value: state.clientId,
        });
      }
    }
    try {
      query.filters = query.filters.concat([]);
      pageConfig.orders = pageConfig.orders.concat([]);
      query.pageConfig = pageConfig;
      if (this.state.switchBwBtn) {
        query.filters.push({
          key: "templateName",
          operator: "==",
          value: "draft",
        });
      } else {
        // const idxObj1 = query.filters.findIndex((object) => {
        //     return object.key === "templateName";
        // });
        // query.filters.splice(idxObj1, 1);
        query.filters.push({
          key: "templateName",
          operator: "==",
          value: "save",
        });
        // query.filters.push({
        //     key: "templateName", operator: "exists", value:false,
        // });
      }
      // else {
      //     query.filters.push({
      //         key: "templateName", operator: "==", value: 'draft',
      //     });
      // }
      // let result = await window.FortisForma.database.getWorkouts(query);

      let result1 = await window.FortisForma.database.getWorkouts(query);
      let result = [];
      if (currentUser.enterpriseId) {
        const idxObj = query.filters.findIndex((object) => {
          return object.key === "enterpriseId";
        });
        // if (idxObj) {
        query.filters.splice(idxObj, 1);
        query.filters.push({
          key: "trainerId",
          operator: "==",
          value: currentUser.id,
        });
        result = await window.FortisForma.database.getWorkouts(query);
        // }
      } else {
        if (this.props.ppEnterprise) {
          const idxObj = query.filters.findIndex((object) => {
            return object.key === "trainerId";
          });
          query.filters.splice(idxObj, 1);
          query.filters.push({
            key: "enterpriseId",
            operator: "==",
            value: this.props.ppEnterprise.id,
          });

          result = await window.FortisForma.database.getWorkouts(query);
        }
      }
      console.log(query, "qury two");
      let final;
      final = [...result1, ...result];
      let results = _.uniqBy(final, "id");

      if (results.length === 0 && !this.state.tabChanged) {
        requestAnimationFrame(() => {
          this.setState({
            firstFetched: this.state.firstFetched,
            lastFetched: this.state.lastFetched,
            trainerWorkouts: this.sortWorkouts(this.state.trainerWorkouts),
            allTrainerWorkouts: this.state.allTrainerWorkouts,
            pageNumber: this.state.previousPageNumber,
            listLoading: false,
            loaderModal: false,
            endOfList: true,
          });
        });
        return;
      }

      let selectedWorkout = results.filter(
        (item) => state.workoutToAssign && item.id === state.workoutToAssign
      );

      if (selectedWorkout && selectedWorkout.length) {
        selectedWorkout = selectedWorkout[0];

        this.setState({
          selectedDayWorkout: selectedWorkout,
        });
      }
      if (this.state.mode === MODES.ASSIGN) {
        if (selectedWorkout && Object.keys(selectedWorkout).length) {
          this.duplicateWorkout(selectedWorkout);
        }

        this.resetLocalStorage();
      }

      requestAnimationFrame(() => {
        this.setState(
          {
            firstFetched: results[0],
            lastFetched: results[results.length - 1],
            trainerWorkouts: this.sortWorkouts(results),
            allTrainerWorkouts: results,
            listLoading: false,
            loaderModal: false,
            tabChanged: false,
          },
          () => {
            if (this.state.searchValue) {
              this.setState({
                trainerWorkouts: this.filterWorkoutOnSearching(),
              });
            }
          }
        );
      });
      return _.uniqBy(results, "id");
    } catch (e) {
      console.error(e);

      this.setState({
        listLoading: false,
      });
      throw e;
    }
  }

  toggleWorkouts(index) {
    if (
      !this.state.selectedDays ||
      (this.state.selectedDays && !this.state.selectedDays.length)
    ) {
      return;
    }
    let update = [];
    update["showWorkouts"] = !this.state.showWorkouts;
    if (typeof index === "number") {
      update["selectedIndex"] = index;
    }
    if (!this.state.displayDaysTypes) {
    }
    this.setState(update);
    this.navigate();
    let selectedDayWorkout =
      this.state.selectedWorkouts[this.state.selectedDays[index]];
    if (selectedDayWorkout) {
      this.setState({ selectedDayWorkout });
    } else if (this.state.workoutToAssign) {
      let filtered = this.state.allTrainerWorkouts.filter(
        (item) => item.id === this.state.workoutToAssign
      );
      selectedDayWorkout = filtered[0] || null;

      this.setState({ selectedDayWorkout });
    } else {
      this.setState({ selectedDayWorkout: null });
    }
  }

  toggleWorkoutsForCalenderDays(index) {
    if (
      !this.state.selectedCalenderDays ||
      (this.state.selectedCalenderDays &&
        !this.state.selectedCalenderDays.length)
    ) {
      return;
    }
    let update = [];

    update["showWorkouts"] = !this.state.showWorkouts;
    if (typeof index === "number") {
      update["selectedIndex"] = index;
      this.setState({ selectedDayName: EMPTY_WEEK_NAME_DAYS[index] });
    }
    this.setState(update);
    this.navigate();
    let selectedDayWorkout =
      this.state.selectedCalenderWorkouts[
        this.state.selectedCalenderDays[index]
      ];
    if (selectedDayWorkout) {
      this.setState({ selectedDayWorkout });
    } else if (this.state.workoutToAssign) {
      let filtered = this.state.trainerWorkouts.filter(
        (item) => item.id === this.state.workoutToAssign
      );
      selectedDayWorkout = filtered[0] || null;
      this.setState({ selectedDayWorkout });
    } else {
      this.setState({ selectedDayWorkout: null });
    }
  }

  async assignToAll(selectedWorkout) {
    let days = Object.assign([], this.state.selectedDays);
    let workouts = Object.assign({}, this.state.selectedWorkouts);
    selectedWorkout = await this.duplicateWorkout(selectedWorkout);

    if (this.state.isPublicWorkout) {
      console.log("inside the condition");
      delete selectedWorkout.clientId;
    }

    workouts[selectedWorkout.id] = selectedWorkout;
    let i;
    for (i = 0; i < days.length; i++) {
      days[i] = null;
    }
    this.setState(
      {
        selectedDays: days,
        selectedWorkouts: workouts,
        loaderModal: false,
        loaderText: "",
      },
      () => {
        // First empty then again set values to show animation blinking
        for (i = 0; i < days.length; i++) {
          days[i] = selectedWorkout.id;
        }
        this.setState({
          selectedDays: days,
        });
      }
    );
  }

  async assignToAllForCalender(selectedWorkout) {
    let days = Object.assign([], this.state.selectedCalenderDays);
    let workouts = Object.assign({}, this.state.selectedCalenderWorkouts);
    selectedWorkout = await this.duplicateWorkout(selectedWorkout);

    if (this.state.isPublicWorkout) {
      console.log("public is true");
      delete selectedWorkout.clientId;
    }

    workouts[selectedWorkout.id] = selectedWorkout;
    let i;
    for (i = 0; i < days.length; i++) {
      days[i] = null;
    }
    this.setState(
      {
        selectedCalenderDays: days,
        selectedCalenderWorkouts: workouts,
        loaderModal: false,
        loaderText: "",
      },
      () => {
        // First empty then again set values to show animation blinking
        for (i = 0; i < days.length; i++) {
          days[i] = selectedWorkout.id;
        }
        this.setState({
          selectedCalenderDays: days,
        });
      }
    );
  }

  onWorkoutSelect = async (selectedWorkout, callback) => {
    if (selectedWorkout) {
      if (selectedWorkout.clientId) {
        if (selectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDraftIds];
          draftId.push(selectedWorkout.id);
          this.setState({
            selectedDraftIds: draftId,
          });
        }
      } else {
        if (selectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDeleteDrafts];
          draftId.push(selectedWorkout.id);
          this.setState({
            selectedDeleteDrafts: draftId,
          });
          await window.FortisForma.database.deleteDraftWorkouts(
            selectedWorkout.id
          );
        }
      }
    }

    if (!this.state.clientId && !this.state.isPublicWorkout) {
      console.error("Client ID not found");
      return;
    }
    let dupSelectedWorkout = await this.duplicateWorkout(selectedWorkout);

    if (dupSelectedWorkout) {
      if (dupSelectedWorkout.clientId) {
        if (dupSelectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDraftIds];
          draftId.push(dupSelectedWorkout.id);
          this.setState({
            selectedDraftIds: draftId,
          });
        }
      } else {
        if (dupSelectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDeleteDrafts];
          draftId.push(dupSelectedWorkout.id);
          this.setState({
            selectedDeleteDrafts: draftId,
          });
          await window.FortisForma.database.deleteDraftWorkouts(
            dupSelectedWorkout.id
          );
        }
      }
    } else {
      await window.FortisForma.database.deleteDraftWorkouts(selectedWorkout.id);
    }
    if (this.state.isPublicWorkout) {
      delete dupSelectedWorkout.clientId;
    }

    let days = Object.assign([], this.state.selectedDays);
    let workouts = Object.assign({}, this.state.selectedWorkouts);

    workouts[dupSelectedWorkout.id] = dupSelectedWorkout;
    days[this.state.selectedIndex] = null;
    let workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    if (this.state.mode === MODES.CREATE) {
      workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    }
    if (this.state.isPublicWorkout) {
      workoutTypeValue = WORKOUT_TYPE.CHALLENGE_WORKOUT;
    }
    this.setState(
      {
        selectedDayWorkout: dupSelectedWorkout,
        selectedDays: days,
        selectedWorkouts: workouts,
        workoutTypeValue,
        loaderModal: false,
        loaderText: "",
      },
      () => {
        days[this.state.selectedIndex] = dupSelectedWorkout.id;
        this.setState(
          {
            selectedDays: days,
          },
          () => {
            if (callback) {
              callback();
            }
          }
        );
      }
    );
  };
  onWorkoutSelectForCalender = async (selectedWorkout, callback) => {
    if (selectedWorkout) {
      if (selectedWorkout.clientId) {
        if (selectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDraftIds];
          draftId.push(selectedWorkout.id);
          this.setState({
            selectedDraftIds: draftId,
          });
        }
      } else {
        if (selectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDeleteDrafts];
          draftId.push(selectedWorkout.id);
          this.setState({
            selectedDeleteDrafts: draftId,
          });
          await window.FortisForma.database.deleteDraftWorkouts(
            selectedWorkout.id
          );
        }
      }
    }

    if (!this.state.clientId && !this.state.isPublicWorkout) {
      console.error("Client ID not found");
      return;
    }
    let dupSelectedWorkout = await this.duplicateWorkout(selectedWorkout);
    if (dupSelectedWorkout) {
      if (dupSelectedWorkout.clientId) {
        if (dupSelectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDraftIds];
          draftId.push(dupSelectedWorkout.id);
          this.setState({
            selectedDraftIds: draftId,
          });
        }
      } else {
        if (dupSelectedWorkout.templateName === "draft") {
          let draftId = [...this.state.selectedDeleteDrafts];
          draftId.push(dupSelectedWorkout.id);
          this.setState({
            selectedDeleteDrafts: draftId,
          });
          await window.FortisForma.database.deleteDraftWorkouts(
            dupSelectedWorkout.id
          );
        }
      }
    } else {
      await window.FortisForma.database.deleteDraftWorkouts(selectedWorkout.id);
    }
    if (this.state.isPublicWorkout) {
      delete dupSelectedWorkout.clientId;
    }

    dupSelectedWorkout.selectedDay = this.state.selectedDayName;
    let days = Object.assign([], this.state.selectedCalenderDays);
    let workouts = Object.assign({}, this.state.selectedCalenderWorkouts);

    workouts[dupSelectedWorkout.id] = dupSelectedWorkout;
    days[this.state.selectedIndex] = null;
    let workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    if (this.state.mode === MODES.CREATE) {
      // workoutTypeValue = WORKOUT_TYPE.TRAINER;
      workoutTypeValue = WORKOUT_TYPE.CLIENT_WORKOUT;
    }
    if (this.state.isPublicWorkout) {
      workoutTypeValue = WORKOUT_TYPE.CHALLENGE_WORKOUT;
    }
    this.setState(
      {
        selectedDayWorkout: dupSelectedWorkout,
        selectedCalenderDays: days,
        selectedCalenderWorkouts: workouts,
        workoutTypeValue,
        loaderModal: false,
        loaderText: "",
      },
      () => {
        days[this.state.selectedIndex] = dupSelectedWorkout.id;
        this.setState(
          {
            selectedCalenderDays: days,
          },
          () => {
            if (callback) {
              callback();
            }
          }
        );
      }
    );
  };
  duplicateChallengeWorkout = async (selectedWorkout) => {
    if (selectedWorkout.challengeId === this.state.id) {
      return;
    }
    this.setState({
      loaderModal: true,
      loaderText: `Adding template to ${
        this.state.name || "challenge"
      } workouts`,
    });
    try {
      await assignTemplateToClient(
        this.state.id,
        selectedWorkout,
        true,
        this.state.switchBwBtn
      );

      this.setState(
        {
          workoutTypeValue: WORKOUT_TYPE.CHALLENGE_WORKOUT,
        },
        () => {
          this.fetchWorkouts(this.state);
        }
      );
    } catch (e) {
      throw e;
    }
  };

  async duplicateWorkout(selectedWorkout) {
    if (this.state.isPublicWorkout) {
      await this.duplicateChallengeWorkout(selectedWorkout);
      return selectedWorkout;
    }
    let savedWorkout = selectedWorkout;
    if (selectedWorkout.clientId !== this.state.clientId) {
      this.setState({
        loaderModal: true,
        loaderText: `Adding template to ${this.state.clientName}'s workouts`,
      });
      try {
        savedWorkout = await assignTemplateToClient(
          this.state.clientId,
          selectedWorkout,
          false,
          this.state.switchBwBtn
        );

        this.setState(
          {
            workoutTypeValue: WORKOUT_TYPE.CLIENT_WORKOUT,
            pageNumber: 0,
            previousPageNumber: 0,
            workoutToAssign: savedWorkout.id,
          },
          () => {
            this.fetchWorkouts(this.state);
          }
        );
      } catch (e) {
        console.error(e);
      }
    }

    return savedWorkout;
  }

  addNewWorkout() {
    try {
      this.storeClientDetailInStorage();
    } catch (e) {
      console.error(e);
    }
    this.clean(() => {
      this.storeStateInLocal();
      this.routeTo("/workout-designer/assign");
    });
  }

  clean(callback) {
    this.setState(this._cleanState(), callback);
  }

  renderPopupModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal.value}
        style={{ maxWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          this.setState({
            showModal: {
              value: !this.state.showModal.value,
              modalTitle: "",
              button: false,
            },
          });
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                showModal: { value: false, modalTitle: "", button: false },
              });
            }}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#800520",
              fontWeight: "bold",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            {this.state.showModal.modalTitle}
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            textAlign: "center",
            paddingBottom: "44px",
          }}
        >
          <>{modalContent[this.state.showModal.modalTitle]}</>
          {this.state.showModal.button === true && (
            <TAButton
              width="60%"
              color="primary"
              variant="contained"
              height="60px"
              style={{ marginTop: "25px" }}
              // {...activeButtonProps}
              onClick={() => this.props.history.push("/upgrade")}
            >
              Upgrade to Unlock this Feature
            </TAButton>
            // <div
            //   style={{
            //     padding: "20px",
            //     margin: "0 auto",
            //     borderRadius: "15px",
            //     fontSize: "14px",
            //     fontWeight: "bold",
            //     color: "white",
            //     marginTop: "25px",
            //     width: "60%",
            //     // minWidth: "max-content",
            //     backgroundColor: "#800520",
            //     letterSpacing: "1px",
            //   }}
            // >
            //   Upgrade to Unlock this Feature
            // </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  handleTabChange = (e, value, redirect = false) => {
    if (
      this.props.user.role === ROLES.TRAINER &&
      this.props.user.tier === TIER.FREE &&
      !(value === 0 || value === 1)
    ) {
      switch (value) {
        case 2:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Forms",
              button: true,
            },
          });
          break;
        case 3:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Assessment",
              button: true,
            },
          });
          break;
        case 4:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Notes",
              button: true,
            },
          });
          break;
        case 5:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Messages",
              button: true,
            },
          });
          break;
        default:
          this.setState({
            showModal: {
              value: false,
              modalTitle: "",
              button: true,
            },
          });
      }
    } else {
      if (value === 1) {
        var summaryDetails = {};
        summaryDetails.clientName = this.state.name;
        summaryDetails.clientEmail = this.state.email;
        // summaryDetails.clientId = this.state.id;
        summaryDetails.clientId = this.state.clientId;
        summaryDetails.chatTrainer = this.state.chatTrainer;
        summaryDetails = JSON.stringify(summaryDetails);
        try {
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.WORKOUT_SUMMARY_DETAILS,
            summaryDetails
          );
          this.setState({
            step: value,
            shouldOpenEditor: redirect,
          });
          this.props.history.push("/workout-summary");
        } catch (e) {
          window.NotificationUtils.showError("Unable to access local storage");
        }
      }
      // else if (value === 5) {
      //     return (
      //        <div className="MessageCentertab">
      //            <ChatRoom
      //               selectedClient={{
      //                   name: this.state.clientName,
      //                   email: this.state.clientEmail,
      //                   id: this.state.clientId,
      //                   role: this.state.role
      //               }}
      //               currentUser={this.props.user}
      //            />
      //        </div>
      //     );
      // }
    }
  };

  _cleanState() {
    const days = Object.assign([], this.state.selectedDays);
    const workouts = Object.assign({}, this.state.selectedWorkouts);
    let assignedDays = Object.keys(this.state.selectedDays);
    assignedDays = assignedDays.map(Number);
    let cleanWorkouts = {};
    var i;
    for (i = 0; i < assignedDays.length; i++) {
      var index = days[assignedDays[i]];
      if (!cleanWorkouts[index]) {
        if (workouts[index]) {
          cleanWorkouts[index] = workouts[index];
        }
      }
    }
    return {
      selectedWorkouts: cleanWorkouts,
      selectedIndex: this.state.selectedIndex,
    };
  }

  storeStateInLocal(newState) {
    let stateToSave = Object.assign({}, newState || this.state);
    stateToSave.showWorkouts = false;
    delete stateToSave.trainerWorkouts;
    let stateToStore = JSON.stringify(stateToSave);
    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
        stateToStore
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  }

  getMessages() {
    switch (this.state.mode) {
      case MODES.ASSIGN:
        return {
          start: "Assigning program",
          end: "Assigned program to user",
          error: "Could not assign program to user",
        };
      case MODES.COPY:
        return {
          start: "Creating copy of program",
          end: "Program copied",
          error: "Could not copy program",
        };
      case MODES.EDIT:
        return {
          start: "Updating program",
          end: "Program updated",
          error: "Could not update program",
        };
      case MODES.CREATE:
        return {
          start: "Saving program",
          end: "Program saved",
          error: "Could not save program",
        };
      default:
        throw new Error("Invalid mode");
    }
  }

  onClickSave = () => {
    if (this.state.isPublicWorkout) {
      this.setState({
        showMetaEditorDialog: true,
      });
      return;
    }
    this.save(false);
  };

  save(noRedirect = false) {
    let messages = this.getMessages();
    window.NotificationUtils.showConfirm(messages.start);
    this.clean(async () => {
      let client = {
        id: this.state.clientId,
        programId: this.state.id,
        chatTrainer: this.state.chatTrainer,
      };
      //Convert to object as firebase doesnt support sparse key arrays
      let daysMap = {};
      let workoutDataMap = {};

      if (this.state.displayDaysTypes) {
        for (let i = 0; i < this.state.selectedDays.length; i++) {
          let id = this.state.selectedDays[i];
          if (id) {
            daysMap[i] = id;
          } else {
            daysMap[i] = null;
          }
        }

        workoutDataMap = Object.assign({}, this.state.selectedWorkouts);

        let keys = Object.keys(workoutDataMap);
        for (let key of keys) {
          let cleanupKeys = [
            "clientId",
            "_exerciseIds",
            "createdTime",
            "trainerId",
            "updatedTime",
          ];
          let original = workoutDataMap[key];
          for (let delKey of cleanupKeys) {
            delete original[delKey];
          }
          workoutDataMap[key] = original;
        }
      } else {
        for (let i = 0; i < this.state.selectedCalenderDays.length; i++) {
          let id = this.state.selectedCalenderDays[i];
          if (id) {
            daysMap[i] = id;
          } else {
            daysMap[i] = null;
          }
        }

        workoutDataMap = Object.assign({}, this.state.selectedCalenderWorkouts);

        let keys = Object.keys(workoutDataMap);
        for (let key of keys) {
          let cleanupKeys = [
            "clientId",
            "_exerciseIds",
            "createdTime",
            "trainerId",
            "updatedTime",
          ];
          let original = workoutDataMap[key];
          for (let delKey of cleanupKeys) {
            delete original[delKey];
          }
          workoutDataMap[key] = original;
        }
      }
      let programData = {};
      if (this.state.displayDaysTypes) {
        programData = {
          id: this.state.id,
          workoutDaysMap: daysMap,
          workoutDataMap: workoutDataMap,
          name: this.state.name || "",
          repeating: this.state.repeating,
          workoutDisplayType: !this.state.displayDaysTypes,
          workoutCompleteMessages: this.state.workoutCompleteMessages,
          workoutStartMessages: this.state.workoutStartMessages,
          programCompleteMessage:
            this.state.programCompleteMessage ||
            "Program is complete, please contact your trainer",
        };
      } else {
        programData = {
          id: this.state.id,
          workoutDaysMap: daysMap,
          workoutDataMap: workoutDataMap,
          name: this.state.name || "",
          repeating: this.state.repeating,
          workoutDisplayType: !this.state.displayDaysTypes,
          workoutCompleteMessages: this.state.workoutCompleteMessages,
          workoutStartMessages: this.state.workoutStartMessages,
          programCompleteMessage:
            this.state.programCompleteMessage ||
            "Program is complete, please contact your trainer",
        };
      }
      try {
        switch (this.state.mode) {
          case MODES.ASSIGN:
            await window.FortisForma.database.updateUserTrainer(
              this.props.user,
              client,
              this.state.clientLoginPending
            );
            delete programData.id;
            await window.FortisForma.database.assignProgramToClient(
              client,
              programData,
              this.state.clientLoginPending
            );
            break;
          case MODES.COPY:
            await window.FortisForma.database.updateUserTrainer(
              this.props.user,
              client,
              this.state.clientLoginPending
            );
            delete programData.id;
            delete programData.type;
            programData.type = WORKOUT_TYPE.TRAINER;
            // delete programData.clientId;
            await window.FortisForma.database.saveProgram(programData);
            break;
          case MODES.EDIT:
            if (this.state.isPublicWorkout) {
              programData = { ...programData, ...this.state.meta };
              programData.public = true;
              programData.type = PROGRAM_TYPES.CHALLENGE;
            }
            await window.FortisForma.database.updateUserTrainer(
              this.props.user,
              client,
              this.state.clientLoginPending
            );
            await window.FortisForma.database.saveProgram(programData);
            break;
          case MODES.CREATE:
            programData = { ...programData, ...this.state.meta };
            programData.public = true;
            programData.type = PROGRAM_TYPES.CHALLENGE;
            await window.FortisForma.database.updateUserTrainer(
              this.props.user,
              client,
              this.state.clientLoginPending
            );
            await window.FortisForma.database.saveProgram(programData);
            break;

          default:
            throw new Error("Invalid mode");
        }
        let array = [...this.state.selectedDraftIds];
        if (this.state.selectedDraftIds) {
          for (let i = 0; i < this.state.selectedDraftIds.length; i++) {
            let docId = this.state.selectedDraftIds[i];
            await window.FortisForma.database.convertDraftToTemplate(docId);
            let index = array.indexOf(docId);
            if (index !== -1) {
              array.splice(index, 1);
            }
          }
          this.setState({ selectedDraftIds: [] });
        }
        if (!noRedirect) {
          this.setState({
            openProgramAssignedModal: true,
          });
        }
        window.NotificationUtils.showSuccess(messages.end);
      } catch (e) {
        window.NotificationUtils.showError(messages.error);
        throw e;
      }
      this.resetLocalStorage(() => {
        if (noRedirect === true) {
          this.storeStateInLocal();
          return;
        }
        // this.afterSave();
      });
    });
  }

  afterSave() {
    if (this.props.isEnterpriseClient) {
      return;
    }
    switch (this.state.mode) {
      case MODES.ASSIGN:
        if (
          window.localStorage.hasOwnProperty(LOCAL_STORAGE_KEYS.SELECTED_USER)
        ) {
          this.routeTo("/workout-days");
        } else {
          this.routeTo("/client-editor");
        }
        break;
      case MODES.COPY:
      case MODES.EDIT:
        this.routeTo("/challenges-manager");
        break;
      case MODES.CREATE:
        this.routeTo("/challenges-manager");
        break;
      default:
        throw new Error("Invalid mode");
    }
  }

  resetLocalStorage(callback) {
    try {
      window.localStorage.removeItem(
        LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS
      );
      window.localStorage.removeItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_RESULT
      );
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE);
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS);
      if (callback) {
        callback();
      }
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  }

  routeTo(route) {
    this.props.history.replace(
      route + "?routeTo=" + this.props.history.location.pathname
    );
  }

  addWorkout = () => {
    let days = Object.assign([], this.state.selectedDays);
    days.push(null);
    this.setState({ selectedDays: days });
    // this.toggleWorkouts();
  };
  reArrangeMessages = (messages = {}, index) => {
    var result = {};
    var keys = Object.keys(messages);

    for (var key of keys) {
      if (key < index) {
        result[key] = messages[key];
      }
      if (key > index) {
        result[key - 1] = messages[key];
      }
    }

    return result;
  };

  removeWorkoutMessages = (index) => {
    var newWorkoutStartMessages = this.reArrangeMessages(
      this.state.workoutStartMessages,
      index
    );
    var newWorkoutCompleteMessages = this.reArrangeMessages(
      this.state.workoutCompleteMessages,
      index
    );
    this.setState({
      workoutStartMessages: newWorkoutStartMessages,
      workoutCompleteMessages: newWorkoutCompleteMessages,
    });
  };

  removeWorkout(index, event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let days = Object.assign([], this.state.selectedDays);

    days[index] = null;

    let array = [...this.state.selectedDraftIds];
    if (array.length) {
      array.pop();
    }

    this.setState({ selectedDays: days, selectedDraftIds: array }, () => {
      this.toggleWorkouts();
    });
  }

  removeWorkoutCalender(index, event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let days = Object.assign([], this.state.selectedCalenderDays);
    days[index] = null;
    let array = [...this.state.selectedDraftIds];
    if (array.length) {
      array.pop();
    }
    this.setState({ selectedCalenderDays: days, selectedDraftIds: array });
    this.toggleWorkoutsForCalenderDays();
  }

  deleteWorkout = (index, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    let days = Object.assign([], this.state.selectedDays);
    days.splice(index, 1);
    this.removeWorkoutMessages(index);
    let array = [...this.state.selectedDraftIds];
    if (array.length) {
      array.pop();
    }

    this.setState({ selectedDays: days, selectedDraftIds: array }, () => {
      if (this.state.selectedIndex === index) {
        this.toggleWorkouts(0);
      } else if (this.state.selectedIndex > index) {
        this.toggleWorkouts(this.state.selectedIndex - 1);
      } else {
        this.toggleWorkouts();
      }
    });
  };

  changeWorkoutMessages = (index, messages = {}) => {
    let workoutStartMessages = { ...this.state.workoutStartMessages };
    workoutStartMessages[index] = messages.start || null;

    let workoutCompleteMessages = { ...this.state.workoutCompleteMessages };
    workoutCompleteMessages[index] = messages.finish || null;

    this.setState({
      workoutStartMessages,
      workoutCompleteMessages,
    });
  };
  editWorkoutDay = (workoutDay, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    window.localStorage.setItem(EDITING_WORKOUT_FROM_CLIENT, true);
    if (
      this.state.workoutTypeValue === WORKOUT_TYPE.TRAINER &&
      this.state.isPublicWorkout
    ) {
      delete workoutDay.id;
    }
    this.saveWorkoutDayToLocal(workoutDay, () => {
      localStorage.removeItem("selectedUser");
      if (this.state.switchBwBtn) {
        this.routeTo("/workout-designer/edit-draft");
      } else {
        this.routeTo("/workout-designer/edit");
      }
    });
  };

  saveWorkoutDayToLocal(workoutDay, callback) {
    try {
      workoutDay = JSON.stringify(workoutDay);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE,
        workoutDay
      );
      this.storeClientDetailInStorage();
      callback();
    } catch (e) {
      console.error(e);
    }
  }

  storeClientDetailInStorage() {
    let clientId = this.state.clientId || "";
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.SELECTED_USER,
      JSON.stringify({ id: clientId })
    );
  }

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
  };

  onChangeProgramName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleBackPress = () => {
    this.props.history.goBack();
  };

  handUseAnywayPress = () => {
    this.hideAlert();
  };

  phoneAlertMessage = () => {
    return (
      <div className="flexColumn centerAlignJustify">
        <span style={{ marginBottom: 24 }}>
          Note: We recommend to use a desktop or tablet device to build and
          assign programs for the best experience.
        </span>
        <Button
          onClick={() => this.handleBackPress()}
          className="btn btn-outline-secondary"
          outline={true}
        >
          Go Back
        </Button>
        <Button
          onClick={() => this.handUseAnywayPress()}
          id="useAnywayButton"
          variant="contained"
          color="primary"
          fullWidth={true}
          className="btn"
        >
          Use Anyway
        </Button>
      </div>
    );
  };

  _renderClientDetails() {
    return (
      <React.Fragment>
        <strong style={{ fontSize: "1.3em", marginRight: 8, marginLeft: 18 }}>
          {this.state.clientName}
        </strong>{" "}
        {` (${this.state.clientEmail})`}
      </React.Fragment>
    );
  }

  getActionName = () => {
    switch (this.state.mode) {
      case MODES.ASSIGN:
      case MODES.CREATE:
        return "Save";
      case MODES.COPY:
        return "Create Duplicate";
      case MODES.EDIT:
        return "Update";
      default:
        throw new Error("Invalid mode");
    }
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          run={this.state.walkthrough}
          callback={this.handleJoyrideCallback}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          locale={{ last: "Done" }}
          styles={{
            options: {
              primaryColor: "#812520",
            },
          }}
        />
      </div>
    );
  };

  selectTab = (tab) => {
    this.setState(
      {
        workoutTypeValue: tab,
        pageNumber: 0,
        previousPageNumber: 0,
        firstFetched: null,
        lastFetched: null,
        endOfList: false,
        tabChanged: true,
      },
      () => {
        this.fetchWorkouts();
      }
    );
  };

  renderLoaderModal() {
    return (
      <Modal
        isOpen={this.state.loaderModal}
        className="modalLoader"
        id="modalLoader"
        backdrop={true}
      >
        <ModalBody>
          <div className="modalLoaderContainer">
            <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
            <span
              style={{
                color: "#800520",
                marginTop: 32,
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              {this.state.loaderText}
            </span>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderPriceModal() {
    const closeDrawer = () => {
      this.setState({
        showMetaEditorDialog: false,
      });
    };
    return (
      <Drawer
        onClose={closeDrawer}
        anchor="right"
        id="priceModal"
        open={this.state.showMetaEditorDialog}
      >
        <MetaDrawerContent>
          <ProgramMetaForm
            data={this.state.metadata}
            onCancel={closeDrawer}
            onSave={(metaData) => {
              this.setState(
                {
                  meta: metaData,
                  showMetaEditorDialog: false,
                },
                this.save
              );
            }}
          />
        </MetaDrawerContent>
      </Drawer>
    );
  }

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        previousPageNumber: this.state.pageNumber,
        pageNumber: newPage,
        endOfList: false,
      },
      () => {
        this.fetchWorkouts();
      }
    );
  };

  getPaginationCount = () => {
    if (
      this.state.trainerWorkouts.length === this.pageSize &&
      !this.state.endOfList
    ) {
      return -1;
    } else {
      let count =
        this.state.pageNumber * this.pageSize +
        this.state.trainerWorkouts.length;
      return count;
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.pageSize = parseInt(event.target.value, 10);
    this.setState(
      {
        previousPageNumber: 0,
        pageNumber: 0,
        firstFetched: null,
        lastFetched: null,
        endOfList: false,
      },
      () => {
        this.fetchWorkouts();
      }
    );
  };

  shareWorkout = (data, shareOption) => {
    this.setState({
      openShareModal: shareOption,
      shareWorkoutId: data.id,
      workoutDataToShare: data,
    });
  };

  onClickCopyLink = (e) => {
    e && e.stopPropagation();
    let link = this.getShareableLInk();
    copy(link);
    window.NotificationUtils.showSuccess("Link copied");
  };

  getShareableLInk = () => {
    return `${WORKOUT_SHARE_URL}${this.state.shareWorkoutId}`;
  };

  onCloseShareModalIcon = () => {
    this.setState({
      openShareModal: false,
    });
  };

  copyWorkoutDay = (workoutDay) => {
    delete workoutDay.id;
    // delete workoutDay.clientId;
    this.saveWorkoutDayToLocalForCopy(workoutDay, () => {
      if (this.state.switchBwBtn) {
        this.routeTo("/workout-designer/copy-draft");
      } else {
        this.routeTo("/workout-designer/copy");
      }
    });
  };

  saveWorkoutDayToLocalForCopy = (workoutDay, callback) => {
    try {
      this.storeSelectedOptionInState(workoutDay);
      workoutDay = JSON.stringify(workoutDay);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE,
        workoutDay
      );
      callback();
    } catch (e) {
      console.error(e);
    }
  };

  storeSelectedOptionInState = (workoutDay) => {
    console.log(workoutDay, "workoutDay");
    let user;
    if (
      this.state.workoutTypeValue !== WORKOUT_TYPE.ALL &&
      this.state.workoutTypeValue !== WORKOUT_TYPE.TRAINER
    ) {
      console.log(this.state, "this.state");
      let selectedUserData = this.state.workoutTypeValue;
      user = selectedUserData;
    } else if (workoutDay && workoutDay.clientId) {
      user = {
        id: workoutDay.clientId,
      };
    }
    if (user) {
      user = JSON.stringify(user);
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_USER, user);
    }
  };

  deleteWorkoutDay = (workoutDay) => {
    let message = `Are you sure you want to delete ${workoutDay.name}`;

    window.customConfirm(message, async () => {
      let index = window._.findIndex(this.state.trainerWorkouts, (item) => {
        return item.id === workoutDay.id;
      });

      if (index >= 0) {
        let newWorkouts = Object.assign([], this.state.trainerWorkouts);
        newWorkouts.splice(index, 1);
        let days = Object.assign([], this.state.selectedDays);
        for (let day of this.state.selectedDays) {
          if (day === workoutDay.id) {
            let indexToRemove = days.indexOf(day);
            days[indexToRemove] = null;
          }
        }
        this.setState({
          selectedDays: days,
          allTrainerWorkouts: newWorkouts,
          trainerWorkouts: newWorkouts,
        });
        this.toggleWorkouts();
      }
      await window.FortisForma.database.deleteWorkout(
        workoutDay.id,
        this.state.switchBwBtn
      );
      this.props.onUserChange();
    });
  };

  renderAssignerTabs() {
    if (this.props.isEnterpriseClient) {
      return (
        <>
          <FormControl className="fullWidth">
            <RadioGroup
              className="workoutAssignerRadioContainer"
              value={this.state.workoutTypeValue}
            >
              <FormControlLabel
                onClick={this.selectClientWorkout}
                value={WORKOUT_TYPE.CLIENT_WORKOUT}
                control={<Radio color="primary" />}
                label="Client's Workout"
              />
              <FormControlLabel
                onClick={this.selectMyWorkout}
                value={WORKOUT_TYPE.TRAINER}
                control={<Radio color="primary" />}
                label="My Templates"
              />
              <FormControlLabel
                onClick={this.selectAll}
                value={WORKOUT_TYPE.ALL}
                control={<Radio color="primary" />}
                label="All Workouts"
              />
            </RadioGroup>
          </FormControl>
        </>
      );
    }
    return (
      <>
        <Col hidden={this.state.isPublicWorkout} className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.CLIENT_WORKOUT
                ? ""
                : "active"
            }`}
            id="clientsWorkout"
            onClick={this.selectClientWorkout}
          >
            Client's Workout
          </NavLink>
        </Col>
        <Col hidden={!this.state.isPublicWorkout} className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.CHALLENGE_WORKOUT
                ? ""
                : "active"
            }`}
            id="registeredClient"
            onClick={this.selectChallengeWorkout}
          >
            Challenge Workouts
          </NavLink>
        </Col>
        <Col className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.TRAINER
                ? ""
                : "active"
            }`}
            id="myTemplates"
            onClick={this.selectMyWorkout}
          >
            My Templates
          </NavLink>
        </Col>
        <Col className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.ALL ? "" : "active"
            }`}
            id="allWorkouts"
            onClick={this.selectAll}
          >
            All Workouts
          </NavLink>
        </Col>
      </>
    );
  }

  renderDraftAssignerTabs() {
    if (this.props.isEnterpriseClient) {
      return (
        <>
          <FormControl className="fullWidth">
            <RadioGroup
              className="workoutAssignerRadioContainer"
              value={this.state.workoutTypeValue}
            >
              <FormControlLabel
                onClick={this.selectClientWorkout}
                value={WORKOUT_TYPE.CLIENT_WORKOUT}
                control={<Radio color="primary" />}
                label="Client's Draft"
              />
              <FormControlLabel
                onClick={this.selectMyWorkout}
                value={WORKOUT_TYPE.TRAINER}
                control={<Radio color="primary" />}
                label="My Drafts"
              />
              <FormControlLabel
                onClick={this.selectAll}
                value={WORKOUT_TYPE.ALL}
                control={<Radio color="primary" />}
                label="All Drafts"
              />
            </RadioGroup>
          </FormControl>
        </>
      );
    }
    return (
      <>
        <Col hidden={this.state.isPublicWorkout} className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.CLIENT_WORKOUT
                ? ""
                : "active"
            }`}
            id="clientsWorkout"
            onClick={this.selectClientWorkout}
          >
            Client's Drafts
          </NavLink>
        </Col>
        <Col hidden={!this.state.isPublicWorkout} className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.CHALLENGE_WORKOUT
                ? ""
                : "active"
            }`}
            id="registeredClient"
            onClick={this.selectChallengeWorkout}
          >
            Challenge Drafts
          </NavLink>
        </Col>
        <Col className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.TRAINER
                ? ""
                : "active"
            }`}
            id="myTemplates"
            onClick={this.selectMyWorkout}
          >
            My Drafts
          </NavLink>
        </Col>
        <Col className="nav-item">
          <NavLink
            className={`clientTabsLink ${
              this.state.workoutTypeValue !== WORKOUT_TYPE.ALL ? "" : "active"
            }`}
            id="allWorkouts"
            onClick={this.selectAll}
          >
            All Drafts
          </NavLink>
        </Col>
      </>
    );
  }

  closeProgramAssignedModal = () => {
    this.setState({
      openProgramAssignedModal: false,
    });
  };

  renderProgramSuccessfullyAssignedModal = () => {
    return (
      <Dialog open={this.state.openProgramAssignedModal} fullWidth={true}>
        <ProgramSuccessfullyAssignedModal
          closeModal={this.closeProgramAssignedModal}
          {...this.props}
        />
      </Dialog>
    );
  };

  renderProgramNameTextField = () => {
    return (
      <Input
        className="displayNone"
        id="programName"
        focus=""
        placeholder="Program Name (optional)"
        value={this.state.name}
        onChange={this.onChangeProgramName}
      />
    );
  };
  renderDraftBtn = () => {
    return (
      <Button
        className={`${this.state.switchBwBtn && "active"}`}
        id="workoutDesignerDraft"
        variant="contained"
        size="large"
        color="primary"
        onClick={() => this.switchBetweenDraftAndTemplate("draft")}
      >
        Draft
      </Button>
    );
  };
  renderTemplateBtn = () => {
    return (
      <Button
        className={`${!this.state.switchBwBtn && "active"}`}
        id="workoutDesignerTemplates"
        variant="contained"
        size="large"
        color="primary"
        onClick={() => this.switchBetweenDraftAndTemplate("template")}
      >
        Template
      </Button>
    );
  };
  switchBetweenDraftAndTemplate = (type) => {
    localStorage.setItem("template_name", type);
    if (type === "draft") {
      this.setState(
        {
          switchBwBtn: true,
        },
        () => {
          this.selectTab(this.state.workoutTypeValue);
        }
      );
    } else {
      this.setState(
        {
          switchBwBtn: false,
        },
        () => {
          this.selectTab(this.state.workoutTypeValue);
        }
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.walkthrough && this.renderJoyride()}
        {this.renderLoaderModal()}
        <Container className="fullWidth workoutAssignerContainer" fluid>
          {/* <Row
            className={
              this.props.isEnterpriseClient ? "enterpriseAssignerHeader" : ""
            }
          >
            <Col xs="12" md="12" className="verticalCenter">
              {!this.props.isEnterpriseClient && (
                <div className="mobileFullWidth">{this.renderTitle()}</div>
              )}
            </Col>
          </Row> */}
          <Row
            style={{ top: 140 }}
            className={
              this.props.user.tier !== TIER.FREE
                ? "enterpriseAssignerHeader"
                : ""
            }
          >
            <Col xs="12" lg="6" md="7" className="verticalCenter">
              {this.props.user.tier === TIER.FREE && (
                <div className="mobileFullWidth">{this.renderTitle()}</div>
              )}
              <Typography component="SwitchContainer">
                <Grid
                  component="FormControlLabel"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Calender days</Grid>
                  <Grid item>
                    <Switch
                      value={this.state.displayDaysTypes}
                      checked={this.state.displayDaysTypes}
                      onChange={this.toggleDaysTypes}
                      inputProps={{ "aria-label": "Display Days" }}
                      id="displayDaysToggle"
                      color="primary"
                    />
                  </Grid>
                  <Grid item>Numbered days</Grid>
                </Grid>
              </Typography>
              <SwitchContainer id="repeatDaysToggle">
                <FormControlLabel
                  style={{ margin: 0 }}
                  label="Repeat days"
                  control={
                    <Switch
                      value={this.state.repeating}
                      checked={this.state.repeating}
                      onChange={this.toggleRepeating}
                      inputProps={{ "aria-label": "Repeating workout" }}
                      id="repeatDaysToggle"
                      color="primary"
                    />
                  }
                />
                {
                  <Tooltip title="Edit program complete message">
                    <ProgramFinishMessageToggleButton
                      size="small"
                      aria-label="Edit program complete message"
                      onClick={() => {
                        this.setState({
                          showProgramMessageEditor: true,
                        });
                      }}
                    >
                      <MessageIcon fontSize="small" />
                    </ProgramFinishMessageToggleButton>
                  </Tooltip>
                }
              </SwitchContainer>
            </Col>
            <Col xs="12" lg="3" md="3" className="verticalCenter draftsTabsBtn">
              {this.renderDraftBtn()}
              {this.renderTemplateBtn()}
            </Col>

            <Col
              xs="12"
              lg="3"
              md="2"
              className="verticalCenter justifyContentFlexEnd"
              id="searchAssignerInput"
            >
              {this.renderSearch()}
            </Col>
          </Row>
          {this.props.user.tier === TIER.FREE && (
            <div className="TabsContainer">
              <Tabs
                value={0}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className="enterpriseClientDetailTabLabel"
                  label="Exercise Prescription"
                />
                <Tab
                  className="enterpriseClientDetailTabLabel"
                  label="Exercise Logs"
                />
                <Tab className="enterpriseClientDetailTabLabel" label="Forms" />
                <Tab
                  className="enterpriseClientDetailTabLabel"
                  label="Assessments"
                />
                <Tab className="enterpriseClientDetailTabLabel" label="Notes" />

                <Tab
                  className="enterpriseClientDetailTabLabel"
                  label="Messages"
                />
              </Tabs>
              {this.renderPopupModal()}
            </div>
          )}
          <Row
            style={{ height: "calc(100vh - 144px)" }}
            className="fullWidth fullWHListContainer mobileHeightInitial"
          >
            <Col
              lg="5"
              md="12"
              sm="12"
              xs="12"
              className="listContainer mobilOverFlowHidden"
            >
              <div
                id="workoutDaysList"
                className="fullWidth"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div id="clientEditorList" style={{ flex: 1 }}>
                  <Row className="centerAlignJustify dayRowList">
                    {this.renderDays()}
                    {this.state.displayDaysTypes && (
                      <AddButtonContainer>
                        <MUIButton
                          onClick={this.addWorkout}
                          fullWidth
                          variant="outlined"
                          color="primary"
                          id="addDayButton"
                        >
                          Add Day
                        </MUIButton>
                      </AddButtonContainer>
                    )}
                  </Row>
                </div>
                <div className="assignButtonContainer">
                  {this.renderProgramNameTextField()}
                  <MUIButton
                    color="primary"
                    variant="contained"
                    onClick={this.onClickSave}
                    size="large"
                  >
                    {this.getActionName()}
                  </MUIButton>
                  {!this.props.isEnterpriseClient && (
                    <MUIButton
                      style={{ marginLeft: "8px" }}
                      color="primary"
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        this.resetLocalStorage();
                        this.handleBackPress();
                      }}
                    >
                      Cancel
                    </MUIButton>
                  )}
                </div>
              </div>
            </Col>
            <Col
              id="workoutAssignerList"
              lg="7"
              md="12"
              sm="12"
              xs="12"
              className="clientDetailContainer"
            >
              <div
                style={{ width: "100%", textAlign: "center" }}
                id="workoutAssignerTabs"
              >
                <Nav
                  tabs
                  style={{
                    marginBottom: "16px",
                    width: "100%",
                    borderBottom: "solid 1px lightgrey",
                    paddingBottom: 0,
                  }}
                >
                  {this.state.switchBwBtn
                    ? this.renderDraftAssignerTabs()
                    : this.renderAssignerTabs()}
                </Nav>
              </div>
              {this.state.displayDaysTypes ? (
                <WorkoutList
                  loading={this.state.listLoading}
                  selectedWorkoutDay={this.state.selectedDayWorkout}
                  selectedDay={this.state.selectedIndex}
                  trainerWorkouts={this.state.trainerWorkouts}
                  editWorkout={this.editWorkoutDay}
                  assignToAll={this.assignToAll}
                  onWorkoutSelect={this.onWorkoutSelect}
                  addNewWorkout={this.addNewWorkout}
                  onCancel={this.toggleWorkouts}
                  onSetFilters={this.applyFilters}
                  pageNumber={this.state.pageNumber}
                  pageSize={this.pageSize}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleChangePage={this.handleChangePage}
                  paginationCount={this.getPaginationCount()}
                  copyWorkout={this.copyWorkoutDay}
                  shareWorkout={this.shareWorkout}
                  deleteWorkout={this.deleteWorkoutDay}
                />
              ) : (
                <WorkoutList
                  loading={this.state.listLoading}
                  selectedWorkoutDay={this.state.selectedDayWorkout}
                  calenderDay={EMPTY_WEEK_NAME_DAYS[this.state.selectedIndex]}
                  selectedDay={this.state.selectedIndex}
                  trainerWorkouts={this.state.trainerWorkouts}
                  editWorkout={this.editWorkoutDay}
                  assignToAll={this.assignToAllForCalender}
                  onWorkoutSelect={this.onWorkoutSelectForCalender}
                  addNewWorkout={this.addNewWorkout}
                  onCancel={this.toggleWorkoutsForCalenderDays}
                  onSetFilters={this.applyFilters}
                  pageNumber={this.state.pageNumber}
                  pageSize={this.pageSize}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleChangePage={this.handleChangePage}
                  paginationCount={this.getPaginationCount()}
                  copyWorkout={this.copyWorkoutDay}
                  shareWorkout={this.shareWorkout}
                  deleteWorkout={this.deleteWorkoutDay}
                />
              )}
              {/*<WorkoutList*/}
              {/*   loading={this.state.listLoading}*/}
              {/*   selectedWorkoutDay={this.state.selectedDayWorkout}*/}
              {/*   selectedDay={this.state.selectedIndex}*/}
              {/*   trainerWorkouts={this.sortWorkouts(this.state.trainerWorkouts)}*/}
              {/*   editWorkout={this.editWorkoutDay}*/}
              {/*   assignToAll={*/}
              {/*       this.state.displayDaysTypes*/}
              {/*          ? this.assignToAll*/}
              {/*          : this.assignToAllForCalender*/}
              {/*   }*/}
              {/*   onWorkoutSelect={*/}
              {/*       this.state.displayDaysTypes*/}
              {/*          ? this.onWorkoutSelect*/}
              {/*          : this.onWorkoutSelectForCalender*/}
              {/*   }*/}
              {/*   addNewWorkout={this.addNewWorkout}*/}
              {/*   onCancel={*/}
              {/*       this.state.displayDaysTypes*/}
              {/*          ? this.toggleWorkouts*/}
              {/*          : this.toggleWorkoutsForCalenderDays*/}
              {/*   }*/}
              {/*   onSetFilters={this.applyFilters}*/}
              {/*   pageNumber={this.state.pageNumber}*/}
              {/*   pageSize={this.pageSize}*/}
              {/*   handleChangeRowsPerPage={this.handleChangeRowsPerPage}*/}
              {/*   handleChangePage={this.handleChangePage}*/}
              {/*   paginationCount={this.getPaginationCount()}*/}
              {/*   copyWorkout={this.copyWorkoutDay}*/}
              {/*   shareWorkout={this.shareWorkout}*/}
              {/*   deleteWorkout={this.deleteWorkoutDay}*/}
              {/*/>*/}
            </Col>
          </Row>

          <div
            hidden={this.state.selected === null}
            className="screenNavigationHelperButton"
            onClick={this.navigate}
          >
            {this.navigationIcon()}
          </div>
          <CustomAlert
            message={this.phoneAlertMessage()}
            show={this.state.showAlert}
            onClose={this.hideAlert}
          />

          {this.renderPriceModal()}
          {this.renderProgramCompleteMessage()}
        </Container>
        <ShareWorkoutModal
          {...this.props}
          openShareModal={this.state.openShareModal}
          link={this.getShareableLInk()}
          currentUser={this.props.user}
          workout={this.state.workoutDataToShare}
          onCloseShareModalIcon={this.onCloseShareModalIcon}
          onClickCopyLink={this.onClickCopyLink}
          clientEmail={this.state.clientEmail && this.state.clientEmail}
          clientDOB={this.state.clientEmail && this.state.clientDOB}
          clientName={this.state.clientEmail && this.state.clientName}
        />
        {/*{this.props.user.tier !== TIER.FREE &&*/}
        {this.renderProgramSuccessfullyAssignedModal()}
        {/*}*/}
      </React.Fragment>
    );
  }

  sortWorkouts = (workouts) => {
    let sortList = workouts.sort(function (firstWorkout, secondWorkout) {
      return firstWorkout.name.localeCompare(secondWorkout.name);
    });
    return sortList;
  };

  handleSearchChange = (event) => {
    this.setState({ searchValue: event.target.value }, () => {
      this.filterTrainerWorkouts(this.state.searchValue);
    });
  };
  filterWorkoutOnSearching = () => {
    let fuse = new Fuse(this.state.allTrainerWorkouts, this.options);
    const results = fuse.search(this.state.searchValue);
    const exactMatchIndex = results.findIndex(
      (result) =>
        result.item.name.toLowerCase() === this.state.searchValue.toLowerCase()
    );
    if (exactMatchIndex !== -1) {
      const exactMatch = results.splice(exactMatchIndex, 1)[0];
      results.unshift(exactMatch);
    }
    return results.map((result) => result.item);
  };
  filterTrainerWorkouts = (searchValue) => {
    if (this.state.searchValue || searchValue) {
      this.setState({ trainerWorkouts: this.filterWorkoutOnSearching() });
    } else {
      this.clearSearch();
    }

    // let filteredWorkouts = this.state.allTrainerWorkouts.filter((item) => {
    //     return item.name.toLowerCase().includes(searchValue.toLowerCase());
    // });
    // this.setState({
    //     trainerWorkouts: filteredWorkouts,
    // });
  };

  clearSearch = () => {
    this.setState({
      searchValue: "",
      trainerWorkouts: this.sortWorkouts(this.state.allTrainerWorkouts),
    });
  };

  renderSearch = () => {
    return (
      <SearchInput
        inputId="searchWorkout"
        placeholder="Search"
        value={this.state.searchValue}
        onChange={this.handleSearchChange}
        onClickClear={this.clearSearch}
      />
    );
  };

  onChangeProgramCompleteMessage = (message) => {
    this.setState({
      showProgramMessageEditor: false,
      programCompleteMessage: message,
    });
  };

  onClickCancel = () => {
    this.setState({
      showProgramMessageEditor: false,
    });
  };

  renderProgramCompleteMessage() {
    return (
      <ProgramCompleteMessageEditor
        message={this.state.programCompleteMessage}
        open={this.state.showProgramMessageEditor}
        onChange={this.onChangeProgramCompleteMessage}
        onCancel={this.onClickCancel}
      />
    );
  }

  renderTitle() {
    let mode = this.state.mode;
    if (this.state.isPublicWorkout) {
      mode = MODES.CREATE;
    }
    switch (mode) {
      case MODES.ASSIGN:
        return this._renderClientDetails();
      case MODES.CREATE:
        return (
          <React.Fragment>
            <strong
              style={{
                fontSize: "1.3em",
                marginRight: 8,
                marginLeft: 18,
              }}
            >
              Create Challenges
            </strong>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  renderDays() {
    if (this.state.displayDaysTypes) {
      return (this.state.selectedDays || EMPTY_WEEK_DAYS).map(
        (value, index) => {
          return (
            <DayCard
              key={index + value}
              assignedWorkout={this.state.selectedWorkouts[value]}
              index={index}
              selected={this.state.selectedIndex === index}
              removeWorkout={this.removeWorkout}
              day={`DAY ${index + 1}`}
              displayDelete={true}
              toggleWorkouts={this.toggleWorkouts}
              deleteWorkout={this.deleteWorkout}
              onMessageChange={this.changeWorkoutMessages}
              workoutStartMessage={this.state.workoutStartMessages[index] || ""}
              workoutCompleteMessage={
                this.state.workoutCompleteMessages[index] || ""
              }
            />
          );
        }
      );
    } else {
      return (
        this.state.selectedCalenderDays.slice(0, 7) ||
        EMPTY_WEEK_DAYS.slice(0, 7)
      ).map((value, index) => {
        return (
          <DayCard
            key={index + value}
            assignedWorkout={this.state.selectedCalenderWorkouts[value]}
            index={index}
            selected={this.state.selectedIndex === index}
            removeWorkout={this.removeWorkoutCalender}
            day={EMPTY_WEEK_NAME_DAYS[index]}
            toggleWorkouts={this.toggleWorkoutsForCalenderDays}
            deleteWorkout={this.deleteWorkout}
            displayDelete={false}
            onMessageChange={this.changeWorkoutMessages}
            workoutStartMessage={this.state.workoutStartMessages[index] || ""}
            workoutCompleteMessage={
              this.state.workoutCompleteMessages[index] || ""
            }
          />
        );
      });
    }
  }
}
