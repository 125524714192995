import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/umd/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFThumbnail(props) {
  const [numPages, setNumPages] = useState(null);
  const getScreenWidth = () => {
    let width = window.innerWidth * 0.8 - 61;
    return width;
  };
  const renderLoading = () => {
    return (
      <div
        className={
          props.preview
            ? "pdfPreviewModalLoaderContainer"
            : "uploadedPDFThumbnailContainer"
        }
      >
        <CircularProgress size={props.preview ? 40 : 16} />
      </div>
    );
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const renderAllPages = () => {
    const arrayOfPages = [];
    for (let i = 1; i <= numPages; i++) {
      arrayOfPages.push(i);
    }
    return arrayOfPages.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <Page
            pageNumber={item}
            className={props.pageClassName || ""}
            width={getScreenWidth()}
          />
        </React.Fragment>
      );
    });
  };

  const showError = () => {
    return <div>Pdf load failed</div>;
  };
  return (
    <Document
      className={props.className || ""}
      loading={renderLoading}
      file={props.link}
      onLoadSuccess={onDocumentLoadSuccess}
      error={showError}
    >
      {props.preview ? (
        renderAllPages()
      ) : (
        <Page
          pageNumber={1}
          height={props.height || ""}
          className={`pdfThumbnailContainer ${
            props.pageClassName && props.pageClassName
          }`}
        />
      )}
    </Document>
  );
}
