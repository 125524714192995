import React from "react";

export default class BaseModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: "",
    };
  }

  onConfirm = () => {
    this.closeModal();
    if (this.confirmCallback) {
      this.confirmCallback();
    }
  };

  onCancel = () => {
    this.closeModal();
    if (this.cancelCallback) {
      this.cancelCallback();
    }
  };
  onSaveDraft = () => {
    this.closeModal();
    if (this.saveDraftCallBack) {
      this.saveDraftCallBack();
    }
  };

  closeModal = () => {
    this.setState({
      show: false,
      extras: {},
      cancelButtonText: "",
      okButtonText: "",
      saveDraftButtonText: "",
    });
  };

  show(
    message,
    confirmCallback,
    cancelCallback,
    extras = {},
    saveDraftCallBack
  ) {
    this.setState({
      show: true,
      message: message,
      ...extras,
    });

    this.confirmCallback = confirmCallback;
    this.cancelCallback = cancelCallback;

    this.saveDraftCallBack = saveDraftCallBack;
  }

  hide() {
    this.setState({
      show: false,
    });
  }
}
