import {
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TAButton from "../taButton/taButton";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
export default class AddStripeCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      exp_month: moment().format("M"),
      exp_year: moment().format("YYYY"),
      month_year: moment(),
      cvc: "",
      loading: this.props.loading,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  handleInputChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.onClose()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };
  errorInFieldName = (key) => {
    return !this.state[key];
  };

  checkErrors = () => {
    let error = false;
    if (!this.state.number || this.state.number.length !== 16) {
      window.NotificationUtils.showError(
        "Please enter a valid 16 digit card number"
      );
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    if (!this.state.cvc || this.state.cvc.length > 3) {
      window.NotificationUtils.showError(
        "Please enter CVC or not more than 3 digit"
      );
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    if (!this.state.exp_month) {
      window.NotificationUtils.showError("Please enter a expiry month");
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    if (!this.state.exp_year) {
      window.NotificationUtils.showError("Please enter a expiry year");
      error = true;
      this.setState({
        checkErrors: true,
      });
    }
    return error;
  };

  onCreate = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrors()) {
      return;
    }

    let stripeData = {};
    stripeData.number = this.state.number;
    stripeData.exp_month = this.state.exp_month;
    stripeData.exp_year = this.state.exp_year;
    stripeData.cvc = this.state.cvc;
    try {
      await this.props.onClickSave(stripeData);
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong");

      console.error(e);
    }
  };

  render() {
    return (
      <>
        <DialogContent className="memberAddDialog-mui">
          <Typography className="memberAddDialogTitle boldText" variant="h5">
            {"Add Stripe Card"}
          </Typography>
          <div className="memberAddDialogContent">
            <div className="memberFieldsContainer">
              {this.renderCloseButton()}
              <div className="firstRowFieldsContainer">
                <TextField
                  className="memberInputFields rightMargin"
                  fullWidth={true}
                  type={"number"}
                  onChange={(e) => this.handleInputChange(e, "number")}
                  variant="standard"
                  value={this.state.number}
                  label="Card Number"
                  required={true}
                  // disabled={this.editMode}
                  error={
                    this.state.checkErrors && this.errorInFieldName("number")
                  }
                />
                <TextField
                  variant="standard"
                  className="memberInputFields leftMargin"
                  type={"number"}
                  fullWidth={true}
                  error={this.state.checkErrors && this.errorInFieldName("cvc")}
                  required={true}
                  onChange={(e) => this.handleInputChange(e, "cvc")}
                  value={this.state.cvc}
                  label="CVC"
                  // disabled={this.editMode}
                />
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div
                  style={{ flex: 1 }}
                  className="fieldContainer onboardingFieldContainer addStripe"
                >
                  {" "}
                  <div id="yearPickerBio" style={{ display: "flex" }}>
                    <DatePicker
                      views={["month", "year"]}
                      inputVariant="outlined"
                      label="Expiry Month/Year"
                      required
                      // openTo="month"
                      format="MM/YYYY"
                      onChange={(e) => {
                        this.setState({
                          month_year: e,
                          exp_month: moment(e).format("M"),
                          exp_year: moment(e).format("YYYY"),
                        });
                      }}
                      value={this.state.month_year}
                      animateYearScrolling
                    />
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <TAButton
            onClick={this.onCreate}
            isLoading={this.state.loading}
            loadingMessage={"Creating"}
            color="primary"
            size="large"
          >
            {"Create"}
          </TAButton>
        </DialogActions>
      </>
    );
  }
}
