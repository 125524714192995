import CircularProgress from "@material-ui/core/CircularProgress";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";
import FileUploader from "react-firebase-file-uploader";

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      progress: props.fileUrl ? "100" : 0,
    };
    this.filePickerRef = React.createRef();
    this.storageRef = window.firebase.storage().ref("trainerDocuments");
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        fileUrl: this.props.fileUrl || "",
        progress: this.props.fileUrl ? "100" : 0,
      });
    }
  }

  onPickFile = () => {};

  setFileUrl = (url) => {
    this.setState({
      fileUrl: url,
    });
  };

  setProgress = (progress) => {
    this.setState({
      progress,
    });
  };

  setIsUploading = (isUploading) => {
    this.setState({
      isUploading,
    });
  };

  handleUploadStart = () => {
    this.setIsUploading(true);
    this.setProgress(0);
  };

  handleProgress = (updatedProgress) => {
    this.setProgress(updatedProgress);
  };

  handleUploadError = (error) => {
    this.setIsUploading(false);
    window.NotificationUtils.showError(error.message);
  };

  handleUploadSuccess = (file) => {
    this.setProgress(100);
    this.setIsUploading(false);
    this.storageRef
      .child(file)
      .getDownloadURL()
      .then((url) => {
        if (url) {
          this.setFileUrl(url);
          if (this.props.setFileUrl) {
            this.props.setFileUrl(url);
          }
        } else {
          console.log("Upload Failed");
        }
      });
  };

  render() {
    return (
      <div className="margin-row-default fileUploadParent">
        <div>
          {this.state.fileUrl ? (
            this.props.isImage ? (
              <img
                src={this.state.fileUrl}
                style={{ width: "250px" }}
                alt="upload certificate"
              />
            ) : (
              <a
                href={this.state.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: 14 }}
              >
                View File
              </a>
            )
          ) : this.state.isUploading ? (
            <span>
              <CircularProgress
                size={14}
                value={this.state.progress}
                color="primary"
              />
            </span>
          ) : null}
        </div>
        <div>
          <label className="filePickerLabel">
            <span style={{ textTransform: "uppercase" }}>
              {this.props.text}
            </span>
            <div
              className="MuiButtonBase-root MuiIconButton-root"
              style={{ padding: 0 }}
            >
              <PublishIcon size="medium" style={{ padding: 0 }} />
            </div>
            <FileUploader
              ref={this.filePickerRef}
              hidden
              accept={this.props.fileType || "*"}
              filename={this.props.fileName}
              randomizeFilename={this.props.fileName ? false : true}
              storageRef={this.storageRef}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
              disabled={this.state.isUploading}
            />
            <span style={{ fontSize: 10, color: "grey" }}>
              {this.props.note}
            </span>
          </label>
        </div>
      </div>
    );
  }
}
