import React from "react";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const Navbar = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  .logo {
    background-image: url("/assets/img/logo.png");
    background-repeat: no-repeat;
    text-align: center;
    background-size: contain;
    margin: 10px 0 5px 0px;
    background-position: center;
    height: 75px;
    width: 150px;
  }
`;

const HomeLink = styled.p`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 180px;
  color: #800520;
  font-weight: 600;
  cursor: pointer;
`;

const Heading = styled.p`
  color: #800520;
  font-weight: 600;
  font-size: 24px;
`;

const Subheading = styled(Heading)`
  font-weight: 500;
  font-size: 20px;
  padding-top: ${(props) => props.pt || "0"};
`;

const TextContainer = styled.div`
  width: 70%;
  min-width: 600px;
  max-width: 800px;
  margin-top: 30px;
`;

const Text = styled.p`
  margin: 5px 0;
  margin-top: ${(props) => props.pt || "5px"};
  display: flex;
  align-items: center;
  width: ${(props) => props.width || "unset"};
  cursor: ${(props) => props.cursor || "unset"};
`;

const Circle = styled.span`
  display: flex;
  justify-content: center;
  width: 17px;
  height: 17px;
  border: 1px solid #800520;
  border-radius: 50%;
  margin-right: 7px;
`;

const ButtonContainer = styled.div`
  margin-top: 80px;
  width: 400px;
  display: flex;
  justify-content: space-around;
`;

const Input = styled.input`
  margin-left: 25px;
  border-bottom: 1px solid rgb(50 50 50 / 0.8) !important;
  width: 100%;

  &:focus {
    border-bottom: 1px solid #800520 !important;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background: ${(props) => props.bg || "#6b6b6b"};
  color: white;
  border: none;
  outline: none;
  transition: 0.3s all;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;

  & > p {
    margin: 0;
    color: #eee;
  }

  &:hover {
    background: ${(props) => props.bgh || "#626262"};
  }
  &:active {
    background: ${(props) => props.bga || "#4d4d4d"};
  }
`;

const REASONS = [
  "I have a duplicate account",
  "I am not getting any value from the platform",
  "I chose to use a different platform",
  "Other (please state):",
];

const DeleteAccount = (props) => {
  const [radio, setRadio] = React.useState(0);
  const [otherValue, setOtherValue] = React.useState("");
  const inputRef = React.useRef(null);
  return (
    <Container>
      <Navbar>
        <div className="logo" />
        <HomeLink
          onClick={() => {
            props.history.push("/dashboard");
          }}
        >
          Back to Fortis Forma
          <i className="icon material-icons" style={{ color: "#800520" }}>
            {"account_circle"}
          </i>
        </HomeLink>
      </Navbar>
      <Heading>Close Account</Heading>
      <Subheading>{props.user.name}, we are sad to see you go</Subheading>
      <TextContainer>
        <Text>
          By closing your account, you'll no longer be able to access any of
          information/documents/clients that you have stored with us on your
          account.
        </Text>
        <Text pt={"15px"}>
          You cannot regain access to this account once it is deleted.
        </Text>
        <Subheading pt={"30px"}>
          Tell us why you are closing your account:
        </Subheading>
        {REASONS.map((reason, index) => {
          return (
            <Text
              onClick={() => {
                if (index === 3) {
                  inputRef.current.focus();
                }
                setRadio(index);
              }}
              cursor={"pointer"}
              width={"max-content"}
            >
              <Circle>
                {radio === index && (
                  <CheckCircleIcon
                    style={{ fontSize: "15px", color: "#800520" }}
                  />
                )}
              </Circle>
              {reason}
            </Text>
          );
        })}
        <Input
          type="text"
          ref={inputRef}
          value={otherValue}
          onChange={(e) => setOtherValue(e.target.value)}
          onClick={() => {
            setRadio(3);
          }}
        />
      </TextContainer>
      <ButtonContainer>
        <Button
          onClick={async () => {
            await window.FortisForma.database.closeAccount();
            window.NotificationUtils.showSuccess(
              "Account deleted successfully"
            );
            props.history.push("/login");
          }}
        >
          {" "}
          Close My Account
        </Button>
        <Button
          bg={"#800520"}
          bgh={"#6c041b"}
          bga={"#580416"}
          onClick={() => {
            props.history.goBack();
          }}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DeleteAccount;
