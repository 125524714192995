import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import WorkoutCard from "./workoutCard";
import { BubbleLoader } from "react-css-loaders";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

export default class WorkoutList extends Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(workout) {
    this.props.onWorkoutSelect(workout);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedWorkoutDay !== prevProps.selectedWorkoutDay) {
      if (this.props.selectedWorkoutDay) {
        this.scrollToWorkout(this.props.selectedWorkoutDay);
      }
    }
  }

  scrollToWorkout(selectedWorkoutDay) {
    let element = document.querySelector(`#workout${selectedWorkoutDay.id}`);
    if (!element) {
      return setTimeout(() => {
        this.scrollToWorkout(selectedWorkoutDay);
      }, 1000);
    }

    requestAnimationFrame(() => {
      try {
        if (element.scrollIntoViewIfNeeded) {
          element.scrollIntoViewIfNeeded({
            behavior: "smooth",
            block: "nearest",
          });
        } else {
          element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      } catch (e) {}
    });
  }

  renderLoader() {
    return (
      <div className="spinnerContainer">
        <BubbleLoader style={{ margin: 0 }} color={"#800520"} size={4} />
      </div>
    );
  }

  renderList = () => {
    return (
      <Container className="workoutList">
        <Row className="workoutListHeader">
          <Col lg="4" md="4" sm="4" xs="2">
            <strong>Workout Name</strong>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2" style={{ marginLeft: -10 }}>
            <strong>Exercises</strong>
          </Col>
          <Col lg="5" md="5" sm="5" xs="5" />
        </Row>
        <Row className="workoutContainer">
          {this.props.trainerWorkouts && this.props.trainerWorkouts.length
            ? this.props.trainerWorkouts.map((value, i) => {
                return (
                  <WorkoutCard
                    key={i}
                    data={value}
                    onSelect={this.onSelect}
                    isSelected={
                      this.props.selectedWorkoutDay &&
                      this.props.selectedWorkoutDay.id === value.id
                    }
                    calenderDay={this.props.calenderDay}
                    selectedDay={this.props.selectedDay}
                    onCancel={this.props.onCancel}
                    editWorkout={this.props.editWorkout}
                    assignToAll={this.props.assignToAll}
                    copyWorkout={this.props.copyWorkout}
                    shareWorkout={this.props.shareWorkout}
                    deleteWorkout={this.props.deleteWorkout}
                  />
                );
              })
            : this.renderPlaceholder()}
        </Row>
        <Row>
          <Col
            lg="4"
            md="4"
            sm="4"
            xs="4"
            style={{ textAlign: "left", paddingTop: 15 }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={(event) =>
                this.props.addNewWorkout(this.props.selectedIndex, event)
              }
            >
              Create New
            </Button>
          </Col>
          <div style={{ textAlign: "right", paddingTop: 15 }}>
            <TablePagination
              component="div"
              count={this.props.paginationCount}
              page={this.props.pageNumber}
              onChangePage={this.props.handleChangePage}
              rowsPerPage={this.props.pageSize}
              onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </Row>
      </Container>
    );
  };

  render() {
    return this.props.loading ? this.renderLoader() : this.renderList();
  }

  renderPlaceholder = () => {
    return (
      <div style={{ width: "100%", margin: 16 }}>
        No workouts found matching your criteria
      </div>
    );
  };
}
