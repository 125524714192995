import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { Component } from "react";
import ReactPlayer from "react-player";

export default class VideoUploader extends Component {
  renderUploadingVideo = () => {
    return (
      <React.Fragment>
        <div className="uploadImageContainer uploadImageContainer">
          <ReactPlayer
            ref={(ref) => {
              this.playerRef = ref;
            }}
            controls={true}
            url={this.props.videoURL && this.props.videoURL}
            width={310}
            height={164}
          />
          <div className="uploadIconContainer imageActions">
            {this.props.videoProgress < 100 ? this.renderVideoLoading() : null}
            {this.props.videoLoading &&
              this.props.videoProgress !== 100 &&
              this.renderContinuousLoader()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  getVideoURL = () => {
    if (!this.props.videoURL) {
      let videoData = new File([this.props.videoData], "video");
      let url = URL.createObjectURL(videoData);
      return url;
    }
    return false;
  };

  renderDelete = () => {
    return (
      <div>
        <IconButton size="small" onClick={this.deleteImage}>
          <DeleteIcon fontSize="small" className="imageActionButton" />
        </IconButton>
      </div>
    );
  };

  renderContinuousLoader = () => {
    return (
      <CircularProgress
        size={16}
        variant="static"
        id="uploadingImageCircularProgress"
      />
    );
  };

  renderVideoLoading = () => {
    return (
      <CircularProgress
        size={16}
        variant="static"
        id="uploadingImageCircularProgress"
        value={this.props.videoProgress}
      />
    );
  };

  render() {
    return <React.Fragment>{this.renderUploadingVideo()}</React.Fragment>;
  }
}
