import { Typography } from '@material-ui/core'
import React from 'react'

export default function ErrorItem({messages}) {
    return (
        <div className="displayFlex">
            {
                messages.map((message, index) => {
                    return (
                        <div key={index}>
                            <Typography>
                                {message}{index === messages.length -1 ? "" : ", "}
                            </Typography>
                        </div>
                    )
                })
            }
        </div>
    )
}
