import classNames from 'classnames';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Tick from '../../../assets/tick.png';
import Cross from '../../../assets/close.png';

export default class TrainerCard extends Component {

	onSelect = () => {
		this.props.onSelect(this.props.data)
	}

	render() {
		var rowClass = classNames ({
			'selectedCard': this.props.selected,
			'clientSMCard': !this.props.selected,
			'centerAlignJustify': true
		})
		return (
			<Row className={rowClass} onClick={this.onSelect}>
                <Col lg="1" md="1" sm="1" xs="1" />
				<Col lg="3" md="3" sm="3" xs="3"  className="decFontSize">
					<p style={{marginBottom: 0}}>{this.props.data.name}</p>
				</Col>
				<Col lg="3" md="3" sm="3" xs="3"  className="centerAlignJustify decFontSize emailMultiline">
					<p className="emailMultiline">{this.props.data.email}</p>
				</Col>
				<Col lg="3" md="3" sm="3" xs="3" className="centerAlignJustify decFontSize" style={{paddingLeft: 20}}>
					<p>{this.props.data.phone}</p>
				</Col>
                <Col lg="2" md="2" sm="2" xs="2" className="centerAlignJustify" style={{paddingLeft: 0}}>
					<div className="centerAlignJustify approvalButtonAccept" style={{ marginLeft: -40, marginTop: -5, height: 30, width: 30, border: 'solid', borderRadius: 30, borderWidth: '0.2px'}} onClick={(event) => this.props.approveTrainer(this.props.data, event)}>
                        <img style={{pointerEvents: "all"}} src={Tick} alt="Yes" height={15} width={15} />
                    </div>
                    <div className="centerAlignJustify approvalButtonReject" style={{ marginTop: -5, marginLeft: 10, height: 30, width: 30, border: 'solid', borderRadius: 30, borderWidth: '0.2px'}} onClick={(event) => this.props.rejectTrainer(this.props.data, event)}>
						<img style={{ pointerEvents: "all" }} src={Cross} alt="No" height={13} width={13} />
                    </div>
				</Col>
			</Row>
		);
	}
}
