import React from 'react';
import {
    Button,
    Input
} from 'reactstrap';

import _ from 'underscore';

export const INITIAL_STATE = {
    didApply: false,
    filters: {
        text: '',
    }
}
export default class GenericFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
        this.debouncedApply = _.debounce(this.applyFilters, 400);
    }

    applyFilters = () => {
        this.props.applyFilters({ filters: this.prepareFilters(), didApply: this.state.didApply });
    }

    prepareFilters() {
        return this.state.filters
    }

    onChangeText = (e) => {
        let filters = Object.assign({}, this.state.filters, { text: e.target.value });
        this.setState({ filters: filters, didApply: true }, this.debouncedApply)
    }

    clearFilters = (clearAll) => {
        this._clearFilters(clearAll)
    }
    
    _clearFilters(clearAll) {
        this.setState(INITIAL_STATE, this.applyFilters)
    }

    renderExtras() {
        return null;
    }

    renderContent() {
        return (
            <div className="genericFilters">
                <div className="filtersSearch internal">
                    <Input style={{
                        cursor: 'initial',
                        display: "inline-block",
                        width: 200,
                        padding: 0,
                        color: 'black'
                    }}
                        className="textRightPadding"
                        value={this.state.filters.text}
                        onChange={this.onChangeText}
                        type="text"
                        placeholder={'Search'}></Input>
                    <Button className="clearFiltersButton" color="primary" onClick={this.clearFilters}>x</Button>
                </div>
                {this.renderExtras()}
            </div>
        )
    }
    render() {
        return (
            this.renderContent()
        )
    }
}