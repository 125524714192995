import React from "react";
import moment from "moment";

const FORM = "form";

export default class NoteAssessmentList extends React.Component {
  getFormData = (formId) => {
    for (let form of this.props.clientForms) {
      if (formId === form.formId) {
        return form.filled;
      }
    }
  };

  formattedDataWithTime = () => {
    let data = Object.assign([], this.props.data);
    for (let item of data) {
      if (item.type === FORM || item.formId) {
        item.filled = this.getFormData(item.formId);
      }
      item.timestamp = moment
        .unix(item.updatedTime._seconds)
        .format("hh:mm a DD/MM/YYYY");
    }
    return data;
  };

  renderNoteAssessmentItem = () => {
    if (this.props.loading) {
      return this.props.loader();
    }
    const data = this.formattedDataWithTime();
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <React.Fragment key={index}>{this.renderItem(item)}</React.Fragment>
        );
      });
    } else {
      return this.renderEmptyPlaceholder();
    }
  };

  render() {
    return (
      <div
        className={
          this.props.loading
            ? "loadingAssessmentListContainer"
            : "assessmentListContainer"
        }
        id="assessmentListContainer"
      >
        {this.renderNoteAssessmentItem()}
      </div>
    );
  }
}
