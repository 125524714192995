import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import TimerModal from "../../components/modals/timerModal";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
      countdown: this.props.time,
      running: false,
      progress: 0,
      open: false,
    };
    this.timer = null;
    this.sound = new Audio(require("../../assets/sounds/pristine.mp3"));
  }

  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.time !== this.props.time) {
      this.setState({
        time: this.props.time,
        countdown: this.props.time,
        running: false,
        progress: 0,
      });
    }
  }

  startTimer = () => {
    this.setState(
      {
        running: true,
        open: true,
        countdown: this.state.countdown,
        progress: this.state.progress,
        time: this.props.time,
      },
      () => {
        this.resumeTimer();
      }
    );
  };

  resumeTimer = () => {
    this.timer = setInterval(() => {
      let countdown = this.state.countdown;
      let progress;
      if (countdown === 0) {
        clearInterval(this.timer);
        this.sound.play();
        this.setState({
          running: false,
          countdown: this.state.time,
          progress: 0,
        });
        return;
      }
      if (!this.state.running) {
        clearInterval(this.timer);
        this.setState({
          running: false,
          countdown: this.state.time,
          progress: this.state.progress,
        });
      }
      countdown = countdown - 1;
      progress = 100 - (countdown / this.state.time) * 100;
      this.setState({
        countdown,
        progress,
      });
    }, 1000);
  };
  stopTimer = () => {
    this.setState({
      running: false,
      time: this.state.time,
      progress: this.state.progress,
    });
    clearInterval(this.timer);
  };
  restartTimer = () => {
    clearInterval(this.timer);
    this.setState(
      {
        running: true,
        time: this.props.time,
        countdown: this.props.time,
        progress: 0,
      },
      () => {
        this.resumeTimer();
      }
    );
  };
  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div className="displayFlex" id="timerContainer">
        {/*{!this.state.running */}
        {/*? */}
        {/*<IconButton size="small" onClick={this.startTimer} disabled={this.props.disabled}>*/}
        {/*    <PlayCircleOutlineIcon />*/}
        {/*</IconButton>*/}
        {/*:*/}
        {/*<IconButton size="small" onClick={this.stopTimer} disabled={this.props.disabled}>*/}
        {/*    <PauseCircleOutlineIcon />*/}
        {/*</IconButton>*/}
        {/*}*/}
        {/*<Box position="relative" display="inline-flex" id="timer">*/}
        {/*    <CircularProgress color="primary" variant="determinate" value={this.state.progress} />*/}
        {/*    <Box*/}
        {/*        top={0}*/}
        {/*        left={0}*/}
        {/*        bottom={0}*/}
        {/*        right={0}*/}
        {/*        position="absolute"*/}
        {/*        display="flex"*/}
        {/*        alignItems="center"*/}
        {/*        justifyContent="center"*/}
        {/*    >*/}
        {/*        <Typography variant="body1" component="div">*/}
        {/*            {this.state.countdown}*/}
        {/*        </Typography>*/}
        {/*    </Box>*/}
        {/*</Box>*/}
        <>
          {!this.state.running ? (
            <IconButton
              size="small"
              style={{
                background: "#802520",
                color: "white",
                padding: 11,
              }}
              onClick={this.startTimer}
              disabled={this.props.disabled}
            >
              <PlayArrowIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              style={{
                background: "#802520",
                color: "white",
                padding: 11,
              }}
              onClick={this.stopTimer}
              disabled={this.props.disabled}
            >
              <PauseIcon />
            </IconButton>
          )}
          {this.state.countdown > 0 && (
            <Box position="relative" display="inline-flex" id="timer">
              <Typography variant="body1" component="div">
                {this.state.countdown} Sec
              </Typography>
            </Box>
          )}
          <TimerModal
            running={this.state.running}
            isOpen={this.state.open}
            onClose={this.handleModal}
            stopTimer={this.stopTimer}
            countdown={this.state.countdown}
            progress={this.state.progress}
            pauseTimer={this.stopTimer}
            startTimer={this.startTimer}
            restartTimer={this.restartTimer}
          />
          {/*</div>*/}
        </>
      </div>
    );
  }
}
