import React, { Component } from "react";
import { Button, ModalBody, ModalFooter, Modal } from "reactstrap";

export default class EditFieldModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick.bind(this);
  }

  handleClick(value) {
    this.props.selectedSection(value);
  }

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} className="repeatCircuitModal">
          <ModalBody>
            <div className="content">
              <h1>Enter new title for circuit</h1>
              <input
                type="text"
                value={this.props.workoutType}
                onChange={(e) => this.handleClick(e.target.value)}
                placeholder="type here for the title"
              ></input>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginTop: "14px" }}>
            <Button
              onClick={this.props.onCancel}
              className="btn btn-outline-secondary btn btn-secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.props.fieldEditHandler(this.props.workoutType)
              }
            >
              Replace
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
