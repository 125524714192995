import Noty from 'noty';

window.NotificationUtils = {};
const ERROR_ANIMATION = "shake";
const DEFAULT_NOTIFICATION_POSTION = "topRight";
const DEFAULT_NOTIFICATION_TIMEOUT = 1500;
const DEFAULT_QUEUE_CONFIG = {};

window.NotificationUtils.showErrorInForm = function (node, identifier, errorText, autoHideInterval, disableAnimation) {

  try {
    if(!node || !node.classList) {
      throw new Error()
    }

    if (!autoHideInterval && autoHideInterval !== false) {
      autoHideInterval = DEFAULT_NOTIFICATION_TIMEOUT;
    }

    if (!errorText) {
      errorText = 'This field is required';
    }

    if (node.classList.value.match(/valid/)) {
      node.classList.remove('valid')
    }
    if (!node.classList.value.match(/error/)) {
      node.className += ' error'
      window.$(node).after(
        `<label class="error ${identifier}">${errorText}.</label>`
      );
    }

    node.classList.add(ERROR_ANIMATION);
    if (disableAnimation !== true) {
      node.classList.add('animated');
    }

    if (autoHideInterval) {
      setTimeout(
        this.hideErrorFromForm.bind(window, node, identifier),
        autoHideInterval
      );
    }
  } catch (e) {
    window.NotificationUtils.showError(errorText);
    console.error(`${e.toString()} while showing error ${errorText}`)
  }
}

window.NotificationUtils.hideErrorFromForm = function (node, identifier) {
  if (node && node.classList && node.classList.value.match(/error/)) {
    node.classList.remove('error');
    node.classList.remove(ERROR_ANIMATION);
    node.classList.remove('animated');
    let errorNode = window.$(`.error.${identifier || node.id}`);
    errorNode && errorNode.remove();
  }
}

window.NotificationUtils.showError = (error, timeout, position, queueConfig) => {
  if (!position) {
    position = DEFAULT_NOTIFICATION_POSTION;
  }
  if (!queueConfig) {
    queueConfig = DEFAULT_QUEUE_CONFIG;
  }

  return window.NotificationUtils._showNotification({
    text: error,
    type: 'error',
    timeout: timeout || DEFAULT_NOTIFICATION_TIMEOUT,
    position: position,
    id: queueConfig.id,
    queue: queueConfig.queue
  });
}

window.NotificationUtils.showConfirm = (message, timeout, position, queueConfig) => {
  if (!position) {
    position = DEFAULT_NOTIFICATION_POSTION;
  }
  if (!queueConfig) {
    queueConfig = DEFAULT_QUEUE_CONFIG;
  }

  return window.NotificationUtils._showNotification({
    text: message,
    type: 'confirm',
    timeout: timeout || DEFAULT_NOTIFICATION_TIMEOUT,
    position: position,
    id: queueConfig.id,
    queue: queueConfig.queue
  });
}

window.NotificationUtils.showSuccess = (message, timeout, position, queueConfig) => {
  if (!position) {
    position = DEFAULT_NOTIFICATION_POSTION;
  }
  if (!queueConfig) {
    queueConfig = DEFAULT_QUEUE_CONFIG;
  }

  return window.NotificationUtils._showNotification({
    text: message,
    type: 'success',
    timeout: timeout || DEFAULT_NOTIFICATION_TIMEOUT,
    position: position,
    id: queueConfig.id,
    queue: queueConfig.queue
  })
}

window.NotificationUtils._showNotification = (notificationConfig) => {
  let notification = new Noty({
    text: notificationConfig.text,
    type: notificationConfig.type,
    layout: notificationConfig.position,
    timeout: notificationConfig.timeout,
    id: notificationConfig.id || false,
    queue: notificationConfig.queue || "global",
    theme: 'semanticui'
  }).show()

  if (notification.showing || notification.shown) {
    notification.setText(notificationConfig.text);
    notification.setType(notificationConfig.type);
    notification.setTimeout(notificationConfig.timeout);
  }
  return notification;  
}

