import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import PasswordInput from "../../components/passwordInput/passwordInput";
import { isMobileOnly } from "react-device-detect";
import { ACTION_STATES, DB_KEYS, ROLES } from "../../constants";
const TIMER = 5000;

export default class EnterpriseInvite extends React.Component {
  state = {
    loading: true,
    data: null,
    password: "",
    confirmPassword: "",
    checkErrors: false,
    showSuccessComponent: false,
    remaining: TIMER / 1000,
  };

  async componentDidMount() {
    window.addEventListener("focus", this.onFocus);
    try {
      window.FortisForma.database.signOut();
      let enterpriseInviteId = this.getURLRoute();
      let enterpriseInvite = await this.getEnterpriseInvite(enterpriseInviteId);
      this.setState({
        data: enterpriseInvite.data,
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    //To prevent Error: Failed to execute 'transaction' on 'IDBDatabase'
    // window.location.reload();
    console.log("focus");
  };

  getEnterpriseInvite = async (enterpriseInviteId) => {
    try {
      let result = await window.FortisForma.database.fetchEnterpriseInvite(
        enterpriseInviteId
      );
      return result;
    } catch (e) {
      throw e;
    }
  };

  getURLRoute = () => {
    let queryParams = "";
    try {
      let queryIndex = window.location.hash.indexOf("?");
      if (queryIndex >= 0) {
        queryParams = window.location.hash.substring(queryIndex + 1);
      }
    } catch (e) {
      console.error(e);
    }
    return queryParams;
  };

  gotoLogin = () => {
    this.props.history.push("/login");
  };

  onChangeTextField = (e, key) => {
    let enterpriseData = this.state.data;
    enterpriseData[key] = e.target.value;
    this.setState({
      data: enterpriseData,
    });
  };

  onChangePassword = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  checkErrorsInForm = () => {
    if (!this.state.data.name) {
      window.NotificationUtils.showError("Please enter name");
      return true;
    }
    if (!this.state.password || this.state.password.length < 6) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector("#password"),
        "password",
        "Password length should be greater than 5"
      );
      return true;
    }
    if (
      !this.state.confirmPassword ||
      this.state.password !== this.state.confirmPassword
    ) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector(`#confirmPassword`),
        "confirmPassword",
        `Passwords do not match`
      );
      return true;
    }
  };

  onClickCreateAccount = async (e) => {
    e.preventDefault();
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrorsInForm()) {
      return;
    }
    this.setState({
      loading: true,
    });
    let data = {
      ...this.state.data,
      password: this.state.password,
    };
    try {
      window.localStorage.setItem(
        DB_KEYS.SIGN_UP_STATE,
        ACTION_STATES.IN_PROCESS
      );
      await window.FortisForma.database.createEnterpriseAdmin(data);
      window.setEmailInLocalStorage(data.email);
      this.setState(
        {
          loading: false,
          showSuccessComponent: true,
        },
        this.startTimer
      );
      window.localStorage.setItem(
        DB_KEYS.SIGN_UP_STATE,
        ACTION_STATES.COMPLETE
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
      this.setState({
        loading: false,
      });
    }
  };

  startTimer() {
    window.FortisForma.database.signOut();
    this.timer = setTimeout(() => {
      this.gotoLogin();
    }, TIMER - 1000);

    this.interval = setInterval(() => {
      this.setState({
        remaining: this.state.remaining - 1,
      });
    }, TIMER / (TIMER / 1000));
  }

  clearTimers() {
    clearTimeout(this.timer);
    clearInterval(this.interval);
  }

  getRole = (role) => {
    switch (role) {
      case ROLES.ENTERPRISE_ADMIN:
        return "Enterprise Admin";
      case ROLES.FACILITY_ADMIN:
        return "Facility Admin";
      case ROLES.HEALTH_COACH:
        return "Health Coach";
      default:
        return "Enterprise Admins";
    }
  };

  renderLoading = () => {
    return (
      <div id="enterpriseInviteLoadingContainer">
        <CircularProgress color="primary" size={40} />
      </div>
    );
  };

  renderAppBar() {
    return (
      <div id="landingAppBar">
        <div className="flexCenter">
          <img alt="Logo" src={"/assets/img/arrow-logo.png"} id="arrowLogo" />
          <img alt="Logo" src={"/assets/img/text-logo.png"} id="textLogo" />
        </div>
        <div>
          <Button
            style={{ marginTop: 1 }}
            variant="outlined"
            color="primary"
            onClick={this.gotoLogin}
          >
            Log in
          </Button>
        </div>
      </div>
    );
  }

  renderCreateAccountCard = () => {
    let enterpriseData = this.state.data;
    return (
      <Card
        className="enterpriseInviteCreateAccountContainer"
        elevation={0}
        variant={isMobileOnly ? "" : "outlined"}
      >
        <Typography variant="h4" className="boldText">
          Set a password to complete the sign up process
        </Typography>
        <div className="mgTop32 enterpriseInviteNameContainer">
          <Typography className="textColorGray" variant="h6">
            Enterprise
            <span id="enterpriseName" className="boldText primaryColor">
              {enterpriseData.enterpriseName}
            </span>
          </Typography>
          <Typography
            className={`textColorGray ${isMobileOnly ? "mgTop16" : ""}`}
            variant="h6"
          >
            Role
            <span id="enterpriseName" className="boldText primaryColor">
              {this.getRole(enterpriseData.role)}
            </span>
          </Typography>
        </div>
        <TextField
          className="mgTopAndBottom"
          value={enterpriseData.email}
          placeholder="Email"
          disabled
          fullWidth={true}
        />
        <PasswordInput
          showIndicator={true}
          placeholder={"Password"}
          value={this.state.password}
          onChange={(e) => this.onChangePassword(e, "password")}
          name="password"
          type="password"
          inputId="password"
        />
        <PasswordInput
          showIndicator={true}
          placeholder={"Confirm Password"}
          value={this.state.confirmPassword}
          onChange={(e) => this.onChangePassword(e, "confirmPassword")}
          name="confirmPassword"
          inputId="confirmPassword"
          type="password"
        />
        <div className="enterpriseCardActions">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => this.onClickCreateAccount(e)}
          >
            Create Account
          </Button>
        </div>
      </Card>
    );
  };

  renderCreateAccountComponent = () => {
    return (
      <div className="enterpriseInvitePageContainer">
        {this.renderAppBar()}
        <div className="enterPriseInviteContainer">
          {this.renderCreateAccountCard()}
        </div>
      </div>
    );
  };

  renderInvalidIdComponent = () => {
    return (
      <div className="enterpriseComponentContainer">
        <img
          alt="Logo"
          src={"/assets/img/invalid.svg"}
          className="notFoundImage"
        />
        <Typography
          variant="h4"
          className="mgTop32 boldText textTransformInitial"
        >
          Invalid Invite ID
        </Typography>
        <Typography
          variant="subtitle1"
          className="textColorGray mgTop32 textTransformInitial"
        >
          Could not find invite associated with your link. Please try again with
          a valid invite link
        </Typography>
      </div>
    );
  };
  renderSuccessComponent = () => {
    return (
      <div className="enterpriseComponentContainer">
        <img
          alt="Logo"
          src={"/assets/img/success.svg"}
          className="notFoundImage"
        />
        <Typography
          variant="h3"
          className="mgTop32 boldText textTransformInitial"
        >
          Welcome to Fortis Forma !
        </Typography>
        <Typography
          variant="subtitle1"
          className="mgTop32 textTransformInitial mgBottom16"
        >
          Your account has been created successfully
        </Typography>
        {this.state.remaining > 0 && (
          <Typography
            className="textColorGray textTransformInitial"
            variant="body2"
          >
            Taking you to login page in {this.state.remaining} seconds...
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          className="mgTop16 textTransformInitial mgBottom16"
        >
          OR
        </Typography>
        <Button variant="contained" color="primary" onClick={this.gotoLogin}>
          Go to Login
        </Button>
      </div>
    );
  };
  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }
    if (this.state.showSuccessComponent) {
      return this.renderSuccessComponent();
    }
    if (!this.state.data) {
      return this.renderInvalidIdComponent();
    }
    return this.renderCreateAccountComponent();
  }
}
