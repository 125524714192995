import { UPLOADED_IMAGE_TYPE, GET_ENT_ID, GET_USER_ID } from "../../constants";
import formImagesModal from "./formImagesModal";
import React from "react";
import ImageUploader from "../uploaderImage/imageUploader";
import TAButton from "../taButton/taButton";

export default class FormImagesSelector extends formImagesModal {
  state = {
    selectedImages: [],
    ...this.state,
  };

  fetchEnterpriseImages = async () => {
    let results = [];
    try {
      let enterpriseId = GET_ENT_ID(this.props);
      const userID = GET_USER_ID(this.props);
      results = await window.FortisForma.database.fetchFormImages(
        enterpriseId,
        userID
        // this.props.userId
      );
    } catch (e) {
      throw e;
    }
    return results;
  };

  onSelect = (imageObject) => {
    if (this.state.selectedImages[0] === imageObject.url) {
      return this.setState({ selectedImages: [] });
    }
    this.setState({ selectedImages: [imageObject.url] });
  };

  onSave = () => {
    if (this.state.selectedImages.length) {
      return this.props.handleSave(this.state.selectedImages);
    }
  };

  renderDialogContent = () => {
    const { images } = this.state;
    return (
      <div>
        <ImageUploader
          uploadButtonText="Upload Image"
          disableSaveButton={() => this.setState({ disableButtons: true })}
          selectedImages={(images && images.length > 0 && images) || []}
          onChange={this.onChangeImages}
          setPreviewOpened={(value) => this.setState({ showPreview: value })}
          pathToStore={"formImages/"}
          extensions={["png", "jpg", "jpeg"]}
          uploadedImageType={UPLOADED_IMAGE_TYPE.IMAGE_SELECTOR}
          onSelect={this.onSelect}
          checkedImages={this.state.selectedImages || []}
        />
      </div>
    );
  };

  renderDialogActions = () => {
    return (
      <TAButton
        onClick={this.onSave}
        color="primary"
        disabled={Boolean(!this.state.selectedImages.length)}
      >
        Select
      </TAButton>
    );
  };

  getDialogTitle = () => "Select Image";
}
