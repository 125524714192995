import React, { Component } from "react";
import ProfileImageUploader from "../../components/profileImageUploader";
import LocationAutoComplete from "../../components/autoComplete/AutoComplete";
import TextField from "./onboardField";
import moment from "moment";
import NumberFormat from "react-number-format";
export default class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetails: {
        picURL: "",
      },
    };
  }

  componentDidMount() {
    this.setState({
      basicDetails: this.props.data,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        basicDetails: this.props.data,
      });
    }
  }

  updateValue(key, value) {
    let basicDetails = Object.assign({}, this.state.basicDetails);
    basicDetails[key] = value;
    this.setState({
      basicDetails,
    });
  }

  checkErrorsInForm = () => {
    if (!this.state.basicDetails.name) {
      window.NotificationUtils.showError("Please enter a valid name");
      return true;
    }
    if (!this.state.basicDetails.email) {
      window.NotificationUtils.showError("Please enter a valid email");
      return true;
    }
    return false;
  };

  setImageUrl = (val) => {
    this.updateValue("picURL", val);
  };

  getData = (checkErrors = true) => {
    if (checkErrors && this.checkErrorsInForm()) {
      return false;
    }
    let data = Object.assign({}, this.state.basicDetails);
    data.picURL = data.picURL || "";
    return data;
  };

  renderAreaOfOperation = () => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ flex: 1, padding: "0 20px" }}>
          <LocationAutoComplete
            id="areaOfOperation"
            label="Area of Operation"
            fullWidth={true}
            value={this.state.basicDetails.areaOfOperation || {}}
            required={this.state.basicDetails.hasAreaOfOperation ? true : false}
            onChange={(value) => {
              this.updateValue("areaOfOperation", value);
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="onBoardingHeading onBoardingSectionHeader">
          <span className="letsCreateText">Let's Create</span>Your Profile
        </div>
        <div className="stepOneOnboardMainContainer">
          <div className="stepOneOnboardInnerContainer">
            <div style={{ flex: 1 / 5 }} className="fieldContainer">
              <div style={{ textAlign: "left" }}>
                <ProfileImageUploader
                  storageRef={"profilePictures"}
                  fileName={
                    this.props.user &&
                    `${this.props.user.id}_${moment().unix()}`
                  }
                  fileUrl={this.state.basicDetails.picURL}
                  setFileUrl={this.setImageUrl}
                />
              </div>
            </div>
            <div
              style={{ flex: 1, width: "100%", display: "flex", marginTop: -8 }}
              className="fieldContainer"
            >
              <span style={{ fontSize: 12, color: "grey" }}>
                <span style={{ fontWeight: "600", color: "black" }}>
                  {" "}
                  Recommendations :
                </span>{" "}
                From our experience professional headshots work best.
                <br />
                <ul style={{ marginLeft: -20 }}>
                  If you don’t have professional headshots keep the following
                  things in mind :
                  <li style={{ color: "grey" }}>
                    Reducing background clutter can keep the focus on you
                  </li>
                  <li style={{ color: "grey" }}>
                    Using natural sun light often gives even colouring
                  </li>
                  <li style={{ color: "grey" }}>
                    Sometimes using a greyscale or moonlight filter can make
                    unprofessional photos feel more professional
                  </li>
                </ul>
              </span>
            </div>
          </div>

          <div className="fieldContainer onboardingFieldContainer">
            <TextField
              id="name"
              label="Name"
              required
              fullWidth={true}
              helperText="Displaying your first and last name gives you more credibility"
              value={this.state.basicDetails.name || ""}
              onChange={(ev) => {
                this.updateValue("name", ev.target.value);
              }}
            />
          </div>
          <div
            style={{ display: "flex" }}
            className="fieldContainer onboardingFieldContainer"
          >
            <div style={{ flex: 0.45 }}>
              <TextField
                id="email"
                label="Email ID"
                required
                disabled={true}
                fullWidth={true}
                helperText="Use the email address you would contact your clients through"
                value={this.state.basicDetails.email || ""}
                onChange={(ev) => {
                  this.updateValue("email", ev.target.value);
                }}
              />
            </div>
            <div style={{ flex: 0.05 }} />
            <div style={{ flex: 0.5 }}>
              <NumberFormat
                type="tel"
                format="+1 ### ### ####"
                mask=" "
                placeholder="e.g. +1 999 999 9999"
                customInput={TextField}
                id="pno"
                label="Phone Number"
                fullWidth={true}
                helperText="Use the phone number you would contact your clients through"
                value={this.state.basicDetails.phone || ""}
                onChange={(ev) => this.updateValue("phone", window.formatPhone(ev.target.value))}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
