import { Badge, CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import React, { Component } from "react";
import PreviewImage from "./previewImageModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default class SelectableUploadedImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPreviewModal: false,
      deleting: false,
    };
  }

  handleSelect = (URL) => {
    this.props.onSelect(URL);
  };

  renderImageAction = () => {
    return (
      <div className="imageActionsNotes">
        {this.renderPreviewIcon()}
        {this.state.deleting
          ? this.renderLoadingIcon()
          : this.renderDeleteIcon()}
      </div>
    );
  };

  removeImage = async () => {
    this.props.removeImageLocally(this.props.index);
  };

  renderLoadingIcon = () => {
    return (
      <div className="verticalCenter">
        <CircularProgress size={14} className="imageActionButton" />
      </div>
    );
  };

  renderPreviewIcon = () => {
    return (
      <IconButton size="small" onClick={this.onClickPreview}>
        <VisibilityIcon fontSize="small" className="imageActionButton" />
      </IconButton>
    );
  };

  renderDeleteIcon = () => {
    if (!this.props.imageId && !this.props.hideRemove) {
      return (
        <div>
          <IconButton size="small" onClick={this.removeImage}>
            <ClearIcon fontSize="small" className="imageActionButton" />
          </IconButton>
        </div>
      );
    }
  };

  onClickPreview = (e) => {
    e.stopPropagation && e.stopPropagation();
    this.props.setPreviewOpened(true);
    this.setState({
      openPreviewModal: true,
    });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
    this.props.setPreviewOpened(false);
  };

  renderPreviewModal = () => {
    return (
      <PreviewImage
        openPreviewModal={this.state.openPreviewModal}
        closePreviewModal={this.closePreviewModal}
        image={this.props.image}
      />
    );
  };

  render() {
    const shouldShowBadge = this.props.image === this.props.selectedImage;
    return (
      <Badge
        badgeContent={shouldShowBadge && <CheckCircleIcon />}
        id="imageSelectedBadge"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="uploadedImageContainer" onClick={this.props.onSelect}>
          <div
            className="noteImage"
            style={{
              backgroundImage: `url("${CSS.escape(this.props.image)}")`,
              width: "100%",
            }}
          />
          {this.renderImageAction()}
        </div>
        {this.renderPreviewModal()}
      </Badge>
    );
  }
}
