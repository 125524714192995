import classNames from "classnames";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";

export default class EnterpriseCard extends Component {
  onSelect = () => {
    this.props.onSelect(this.props.data);
  };

  render() {
    var rowClass = classNames({
      selectedCard: this.props.selected,
      clientSMCard: !this.props.selected,
      centerAlignJustify: true,
    });
    return (
      <Row className={rowClass} onClick={this.onSelect}>
        <Col lg="1" md="1" sm="1" xs="1" />
        <Col lg="5" md="5" sm="5" xs="5" className="decFontSize">
          <p style={{ marginBottom: 0 }}>{this.props.data.name}</p>
        </Col>
        <Col lg="5" md="5" sm="5" xs="5" className="decFontSize">
          <p style={{ marginBottom: 0 }}>
            {this.props.data.admin && this.props.data.admin.name
              ? this.props.data.admin.name
              : ""}
          </p>
        </Col>
        <Col lg="1" md="1" sm="1" xs="1" />
      </Row>
    );
  }
}
