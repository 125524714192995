import React from "react";
import Joyride from "react-joyride";
import ReactPlayer from "react-player";
import ReactTooltip from "react-tooltip";
import Button from "@material-ui/core/Button";
import { Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap";
import {
  INPUT_FIELD_KEYS,
  RESISTANCE_LEVELS,
  WALKTHROUGH,
} from "../../constants";
import { EXERCISE_DETAIL_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import WalkthroughEventDispatcher from "../walkthroughEventDispatcher/walkthroughEventDispatcher";
import { BubbleSpinLoader } from "react-css-loaders";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const collapsiblesTypes = {
  NOTE: "expandNote",
  DESC: "expandDescription",
};

export default class ExerciseDetailsEditCard extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      walkthrough: false,
      showJoyride: this.props.showJoyride,
      expand: false,
      note: props.exercise.note,
      description: props.exercise.description,
      videoNote: props.exercise.videoNote,
      steps: EXERCISE_DETAIL_WALKTHROUGH.steps,
    };
    this.onCheckChange = this.onCheckChange.bind(this);
    this.resistanceLevels = Object.values(RESISTANCE_LEVELS);
    this.displayOptions = {};
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.DESIGNER_EXERCISE;
    this.displayOptions[this.resistanceLevels[0]] = "Ex. Light";
    this.displayOptions[this.resistanceLevels[1]] = "Light";
    this.displayOptions[this.resistanceLevels[2]] = "Medium";
    this.displayOptions[this.resistanceLevels[3]] = "Heavy";
    this.displayOptions[this.resistanceLevels[4]] = "Ex. Heavy";

    this.numericFields = ["sets", "reps", "weight", "time"];

    for (let field of this.numericFields) {
      this["onChange_" + field] = this.onChangeValue.bind(this, field);
    }

    this.onChange_resistance = this.onChangeValue.bind(this, "resistance");
    this.onChange_note = (e) => {
      var scroll_height = e.target.scrollHeight;
      e.target.style.height = `${scroll_height}px`;
      this.onChangeValue("note", e);
    };
    this.onChange_videoNote = (e) => {
      var scroll_height = e.target.scrollHeight;
      e.target.style.height = `${scroll_height}px`;
      this.onChangeValue("videNode", e);
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.exercise && prevProps.exercise.description) !==
        (this.props.exercise && this.props.exercise.description) ||
      (prevProps.exercise && prevProps.exercise.note) !==
        (this.props.exercise && this.props.exercise.note)
    ) {
      this.setState({
        description:
          (this.props.exercise && this.props.exercise.description) || "",
        note: (this.props.exercise && this.props.exercise.note) || "",
      });
    }
  }

  onChangeNote = (e) => {
    this.setState({
      note: e.target.value,
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  onChangeVideoNote = (e) => {
    this.setState({
      videoNote: e.target.value,
    });
  };

  onCheckChange(event) {
    this.props.onCheckExercise(this.props.exercise, event.target.checked);
  }

  checkTimeValue = (event) => {
    if (event.target.value % 1 !== 0) {
      event.target.value = Math.floor(Number(event.target.value));
    }
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  };

  onChangeValue(key, event) {
    if (this.numericFields.includes(key) && isNaN(event.target.value)) {
      event.target.value = 0;
    }
    if (key === INPUT_FIELD_KEYS.TIME) {
      this.checkTimeValue(event);
    }
    this.props.onChangeValue(this.props.exercise, key, event.target.value, {
      sectionIndex: this.props.sectionIndex,
      exerciseIndex: this.props.itemIndex,
    });
  }

  isEmptyEquipmentType() {
    if (this.isEmptyType) {
      return this.isEmptyType;
    }
    let types = Object.values(this.props.exercise.equipmentTypes);
    this.isEmptyType = true;
    for (let type of types) {
      if (type) {
        this.isEmptyType = false;
      }
    }
    return this.isEmptyType;
  }

  toggleCollapsable = (key) => {
    this.setState(
      {
        expand: key || false,
      },
      () => {
        this.handleMenuClose();
      }
    );
  };

  onSaveNote = () => {
    if (!this.state.note && !this.state.videoNote) {
      window.NotificationUtils.showError("Invalid Note!");
      return;
    }
    if (this.state.note) {
      this.props.onSaveNote(
        this.props.index,
        this.props.sectionIndex,
        this.state.note
      );
    } else if (this.state.videoNote) {
      this.props.onSaveVideoNote(
        this.props.index,
        this.props.sectionIndex,
        this.state.videoNote
      );
    } else {
      this.props.onSaveNote(
        this.props.index,
        this.props.sectionIndex,
        this.state.note
      );
      this.props.onSaveVideoNote(
        this.props.index,
        this.props.sectionIndex,
        this.state.videoNote
      );
    }

    this.toggleCollapsable(false);
  };

  onSaveDescription = () => {
    this.props.onSaveDescription(
      this.props.index,
      this.props.sectionIndex,
      this.state.description
    );
    this.toggleCollapsable(false);
  };

  renderButtonGroup = (onAdd = () => {}) => {
    return (
      <div>
        <Button
          color="primary"
          size="small"
          onClick={this.toggleCollapsable}
          style={{ marginLeft: 8, height: 36, marginTop: 8 }}
          variant="outlined"
        >
          Hide
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onAdd}
          style={{ marginLeft: 8, height: 36, marginTop: 8 }}
        >
          Add
        </Button>
      </div>
    );
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          locale={{ last: "Done" }}
        />
      </div>
    );
  };

  handleMenuOpenClick = (event) => {
    event && event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = (event) => {
    event && event.stopPropagation();
    this.setState({
      anchorEl: null,
    });
  };

  renderMoreMenu() {
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
      >
        <MenuItem
          onClick={() => this.toggleCollapsable(collapsiblesTypes.DESC)}
        >
          Description
        </MenuItem>
        <MenuItem
          onClick={() => this.toggleCollapsable(collapsiblesTypes.NOTE)}
        >
          Note
        </MenuItem>
      </Menu>
    );
  }

  renderLoading() {
    return (
      <BubbleSpinLoader
        color={"#800520"}
        size={2}
        style={{
          margin: "0",
          position: "relative",
          right: "-58px",
          top: "-8px",
        }}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.walkthrough && this.renderJoyride()}
        <div
          id="exerciseDetailEditCard"
          style={{ flex: 1, paddingBottom: 6, paddingTop: 6 }}
        >
          <ReactTooltip />
          <div className="exerciseEditCard">
            <Row className="exerciseEditCardDetails">
              <Col xs="2" md="1" lg="1" id="dragHandleAndNumber">
                <div
                  className="form-check index"
                  style={{ marginTop: 0, marginLeft: 4, marginRight: 4 }}
                >
                  <IconButton size="small">
                    <DragIndicatorIcon color="secondary" />
                  </IconButton>
                </div>

                <div className="index">{this.props.index + 1}</div>
              </Col>

              <Col className="videoContainer">
                {!this.props.exercise.videoURL &&
                this.props.exercise.posterURL ? (
                  <img
                    src={this.props.exercise.posterURL}
                    width={62}
                    height={62}
                    className="posterImg"
                    alt="Poster"
                  />
                ) : (
                  <ReactPlayer
                    playing={true}
                    playsinline={true}
                    controls={false}
                    muted={true}
                    url={this.props.exercise.videoURL}
                    width={62}
                    height={62}
                    light={CSS.escape(this.props.exercise.posterURL) || true}
                  />
                )}
              </Col>

              <Col lg="6" style={{ paddingLeft: "0" }}>
                <div className="title">
                  {this.props.exercise.name}{" "}
                  <IconButton
                    size="small"
                    onClick={() =>
                      this.props.editExerciseNameModal(
                        this.props.sectionIndex,
                        this.props.itemIndex
                      )
                    }
                  >
                    <i className="material-icons smallIcon">edit</i>
                  </IconButton>
                </div>
                <Col xs="12" className="levelChangeContainer level">
                  {/* <div
                    className="levelChangerButton noselect"
                    id="levelLeftArrow"
                    disabled={!this.props.canLevelDown}
                    onClick={this.props.onLevelDown}
                  >
                    <i className="material-icons">keyboard_arrow_left</i>
                  </div> */}
                  <div
                    style={{
                      minHeight: 18,
                      minWidth: 58,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    // onClick={() => {
                    //   this.props.onExerciseInfo();
                    //   this.props.showModal();
                    // }}
                  >
                    Level {` `}{" "}
                    {/* {this.props.loading
                      ? this.renderLoading()
                      : this.props.exercise.level}{" "} */}
                    {this.props.exercise.level}
                  </div>
                  {/* <div
                    className="levelChangerButton noselect"
                    id="levelRightArrow"
                    disabled={!this.props.canLevelUp}
                    onClick={this.props.onLevelUp}
                  >
                    <i className="material-icons">keyboard_arrow_right</i>
                  </div> */}
                  <div
                    className="levelChangerButton noselect"
                    id="levelInfoIcon"
                    onClick={() => {
                      // this.props.onLevelUp();
                      this.props.onExerciseInfo();
                      this.props.showModal();
                    }}
                  >
                    <i className="material-icons">info_icon</i>
                  </div>
                </Col>
              </Col>
            </Row>

            <div className="exerciseDetailsInputs" id="exerciseConfig">
              <Row style={{ width: "100%", justifyContent: "space-between" }}>
                <FormGroup className="rowForm">
                  <Label>Sets</Label>
                  <Input
                    type="text"
                    id="setsInput"
                    valid={window.isValidNumber(this.props.exercise.sets)}
                    className="sets rowInput"
                    value={this.props.exercise.sets}
                    onChange={this.onChange_sets}
                  />
                </FormGroup>
                <FormGroup className="rowForm" style={{ marginLeft: 8 }}>
                  <Label>Resistance</Label>
                  <Input
                    style={{ textIndent: "0", maxWidth: 100 }}
                    type="select"
                    value={
                      !this.props.exercise.resistance
                        ? ""
                        : this.props.exercise.resistance
                    }
                    onChange={this.onChange_resistance}
                    className={`resistance rowInput ${this.props.exercise.resistance}`}
                  >
                    <option value={"NA"}>N.A</option>
                    <option value={""}>-</option>
                    {this.resistanceLevels.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {this.displayOptions[value]}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>

                <FormGroup className="rowForm">
                  <Label>Reps</Label>
                  <Input
                    type="text"
                    id="repsInput"
                    valid={window.isValidNumber(this.props.exercise.reps)}
                    className="reps rowInput"
                    value={this.props.exercise.reps}
                    onChange={this.onChange_reps}
                  />
                </FormGroup>

                <FormGroup className="rowForm">
                  <Label>Weight</Label>
                  <Input
                    type="text"
                    className="weight rowInput"
                    valid={window.isValidNumber(this.props.exercise.weight)}
                    value={this.props.exercise.weight}
                    onChange={this.onChange_weight}
                  />
                </FormGroup>

                <FormGroup className="rowForm">
                  <Label>Time</Label>
                  <Input
                    type="text"
                    className="time rowInput"
                    valid={window.isValidNumber(this.props.exercise.time)}
                    value={this.props.exercise.time}
                    onChange={this.onChange_time}
                  />
                </FormGroup>
              </Row>
            </div>

            <div
              className="editCardDetailButton"
              id="exerciseDetailsButtons"
              style={{
                flex: 0.25,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                className="rowForm editExerciseIconButton"
                style={{
                  padding: "0 8px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.props.onRemove();
                }}
              >
                <i style={{ fontSize: "18px" }} className="icon material-icons">
                  delete
                </i>
                <div
                  className="iconLabel"
                  style={{ fontSize: "8px", marginTop: "8px" }}
                >
                  Delete
                </div>
              </div>

              <div
                className="rowForm editExerciseIconButton"
                style={{
                  paddingRight: "8px",
                  paddingLeft: "2px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.props.onDuplicate();
                }}
              >
                <i style={{ fontSize: "18px" }} className="icon material-icons">
                  file_copy
                </i>
                <div className="iconLabel">Copy</div>
              </div>
              <div
                className="rowForm editExerciseIconButton"
                id="exerciseCardNoteIcon"
                style={{
                  padding: "0 0",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.stopPropagation && e.stopPropagation();
                  this.props.onLoadSimilar();
                }}
              >
                <i style={{ fontSize: "18px" }} className="icon material-icons">
                  refresh
                </i>
                <div className="iconLabel">Alternate</div>
              </div>
              <div
                className="rowForm editExerciseIconButton"
                id="exerciseCardNoteIcon"
                style={{
                  padding: "0 0",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={this.handleMenuOpenClick}
              >
                <MoreVertIcon className="icon material-icons" />
                <div className="iconLabel">More</div>
                {this.renderMoreMenu()}
              </div>
            </div>
          </div>
          <Collapse
            // timeout="auto"
            isOpen={this.state.expand === collapsiblesTypes.NOTE}
          >
            <div className="collapsedContainer">
              <Input
                className="editCardNote"
                id={
                  "noteTextarea" +
                  this.props.sectionIndex +
                  this.props.itemIndex
                }
                type="textarea"
                placeholder="Note"
                style={{ width: "100%", height: 42 }}
                value={this.state.note}
                onChange={this.onChangeNote}
              />

              <Input
                className="editVideoCardNote"
                type="text"
                placeholder="Video Note Link"
                style={{ width: "100%", height: 42 }}
                value={this.state.videoNote}
                onChange={this.onChangeVideoNote}
              />
              {this.renderButtonGroup(this.onSaveNote)}
            </div>
          </Collapse>
          <Collapse isOpen={this.state.expand === collapsiblesTypes.DESC}>
            <div className="collapsedContainer">
              <Input
                className="editCardNote"
                id={
                  "descriptionTextarea" +
                  this.props.sectionIndex +
                  this.props.itemIndex
                }
                type="textarea"
                placeholder="Description"
                style={{ width: "100%", height: 42 }}
                value={this.state.description}
                onChange={this.onChangeDescription}
              />
              {this.renderButtonGroup(this.onSaveDescription)}
            </div>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }
}
