import { Badge, Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Dialog } from "@material-ui/core";
import React from "react";
import Joyride from "react-joyride";
import { NavLink } from "react-router-dom";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
// import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/header/index";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import {
  CLIENT_FORMS_PORTAL_LINK,
  COLLECTIONS,
  ROLES,
  TIER,
  WALKTHROUGH,
} from "../../constants";
import { DASHBOARD_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import TAButton from "../../utils/TAButton";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const drawerWidth = 240;

const styles = (theme) => ({
  "@global": {
    typography: {
      fontFamily: "Montserrat",
    },
  },
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 32,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const ClientRequest = () => {
  return (
    <>
      This feature will be associated with the "Public Profile" feature.
      <br />
      When available, ensure your information is filled in properly on the
      platform; from there, your information will be added to our public
      directory of coaches. Clients can browse coaches on the platform, and
      request to work with ones whose profiles seem to resonate with their
      requirements! You will be able to see these "Client Requests" here, when
      it becomes available.
    </>
  );
};

const modalContent = {
  "Client Requests": <ClientRequest />,
  "Public Profile":
    "When available, you will be able to showcase your unique attributes/characteristics as a coach here. Anyone can see your profile from here, and future clients will be able to see if you would be a good fit for them and their goals.",
  Staff:
    "As an enterprise, you can easily manage all of your staff in one place. You can designate roles, and ensure all members of the team are on the same page (literally).",
  Documents:
    "With this feature, you have the ability to store all of the necessary documents for your business all in one place. You have access to registration form templates, assessment form templates, notes, and the ability to create your own individualized forms, helping to make your practice even more efficient.",
  Messages:
    "With this feature, you can message to your respective clients at any time to get their real time workout updates.You can get this feature by upgrading your subscription.",
};

class DashboardComponent extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      clientHC: 0,
      mobileOpen: false,
      navItems: Header.getNavItems(props),
      steps: DASHBOARD_WALKTHROUGH.steps,
      messageCount: 0,
      walkthrough: false,
      showModal: { value: false, modalTitle: "", button: false },
      trailModal:
        (this.props.user && this.props.user.tier == "Enterprise Solution") ||
        (this.props.user &&
          this.props.user.tier == "Private Practice Solution"),
    };
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.DASHBOARD;
    this.isAdmin =
      this.props.user &&
      this.props.user.role &&
      this.props.user.role === ROLES.ADMIN;
    this.isEnterpriseAdminRight =
      this.props.user &&
      (this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ENTERPRISE_ADMIN);
  }
  componentDidMount() {
    if (this.props.user.tier != TIER.FREE) {
      this.fetchUnreadMsgCount();
    }
    if (this.props.user.role === ROLES.HEALTH_COACH) {
      this.clientCount();
    }
  }
  clientCount = async () => {
    const count = await window.FortisForma.database.fetchclientCountHC();
    this.setState({ clientHC: count.count });
  };

  routeTo(index) {
    switch (index) {
      case 1:
        this.props.history.replace("/client-editor");
        break;
      case 2:
        this.props.history.replace("/pending-clients");
        break;
      case 3:
        this.props.history.replace("/pending-requests");
        break;
      case 4:
        this.props.history.replace("/workout-days");
        break;
      case 5:
        this.props.history.replace("/pending-approvals");
        break;
      case 6:
        this.props.history.replace("/programs");
        break;
      case 7:
        this.props.history.replace("/manage-trainers");
        break;
      case 8:
        this.props.history.replace("/manage-public-trainers");
        break;
      case 9:
        this.props.history.replace("/challenges-manager");
        break;
      case 10:
        this.props.history.replace("/workout-assigner/create");
        break;
      case 11:
        this.props.history.replace("/enterprise-accounts");
        break;
      case 12:
        this.props.history.replace("/staff-editor");
        break;
      case 13:
        this.props.history.replace("/client-management");
        break;
      case 14:
        this.props.history.replace("/documents");
        break;
      case 15:
        this.props.history.replace("/message-center");
        break;
      default:
        break;
    }
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({
      mobileOpen: !state.mobileOpen,
    }));
  };

  logout = () => {
    window.FortisForma.database.signOut();
  };

  completeOnboarding = () => {
    this.props.history.push("/onboarding");
  };

  shouldShowPublicProfileEditButton() {
    if (!this.props.user) {
      return false;
    }
    // this.props.user.role === ROLES.TRAINER &&
    return (
      this.props.user.tier !== TIER.FREE &&
      this.props.user.role === ROLES.TRAINER &&
      this.props.user.incomplete === true
    );
  }

  shouldShowFacilityInfoOption() {
    if (this.props.user) {
      if (
        this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ENTERPRISE_ADMIN
      ) {
        return true;
      }
    }
    return false;
  }

  showSubscription() {
    if (this.props.user) {
      if (
        this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ADMIN ||
        this.props.user.role === ROLES.HEALTH_COACH
      ) {
        return false;
      }
    }
    return true;
  }

  isfacilityInfoCompleted() {
    if (this.props.user.enterprise) {
      const { name, address, city, fax, phone, postalCode, province } =
        this.props.user.enterprise;

      return Boolean(
        name && address && city && fax && phone && postalCode && province
      );
    } else {
      return false;
    }
  }

  isProfileCompleted() {
    const { name, address, city, email, phone, postalCode, province } =
      this.props.user;
    if (this.props.user.role === "Admin") {
      return true;
    } else {
      return Boolean(
        name && address && city && email && phone && postalCode && province
      );
    }
  }

  fetchUnreadMsgCount = () => {
    window.firebase
      .firestore()
      .collection(COLLECTIONS.MESSAGE)
      .where("receiver", "==", this.props.user.role)
      .onSnapshot((doc) => {
        let totalCount = 0;
        doc.docs.map((subItem) => {
          let data = subItem.data();
          if (
            (data.trainer_read_status === false ||
              data.coach_read_status === false) &&
            (data.trainerId === this.props.user.id ||
              data.clientId === this.props.user.id)
          ) {
            totalCount++;
          }
        });
        this.setState({ messageCount: totalCount });
      });
  };
  msgCountText = () => {
    return (
      <p className="unreadMsg" style={{ top: "2px", right: "88px" }}>
        {this.state.messageCount}
      </p>
    );
  };

  renderToolbarRightContent() {
    return (
      <>
        {this.shouldShowPublicProfileEditButton() && (
          <Button
            id="appBarProfileButton"
            variant="outlined"
            color="primary"
            onClick={this.completeOnboarding}
            size="small"
          >
            Update Profile
          </Button>
        )}
        {this.props.user && this.props.user.enterpriseId && (
          <Button
            onClick={() => window.open(CLIENT_FORMS_PORTAL_LINK, "_blank")}
            className="clientFormPortalButton"
            color="primary"
          >
            Client Registeration Portal
          </Button>
        )}
      </>
    );
  }

  renderPopupModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal.value}
        style={{ maxWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          this.setState({
            showModal: {
              value: !this.state.showModal.value,
              modalTitle: "",
              button: false,
            },
          });
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                showModal: { value: false, modalTitle: "", button: false },
              });
            }}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#800520",
              fontWeight: "bold",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            {this.state.showModal.modalTitle}
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            textAlign: "center",
            paddingBottom: "44px",
          }}
        >
          <>{modalContent[this.state.showModal.modalTitle]}</>
          {this.state.showModal.button === true && (
            <TAButton
              width="60%"
              color="primary"
              variant="contained"
              height="60px"
              style={{ marginTop: "25px" }}
              // {...activeButtonProps}
              onClick={() => this.props.history.push("/upgrade")}
            >
              Upgrade to Unlock this Feature
            </TAButton>
            // <div
            //   style={{
            //     padding: "20px",
            //     margin: "0 auto",
            //     borderRadius: "15px",
            //     fontSize: "14px",
            //     fontWeight: "bold",
            //     color: "white",
            //     marginTop: "25px",
            //     width: "60%",
            //     // minWidth: "max-content",
            //     backgroundColor: "#800520",
            //     letterSpacing: "1px",
            //   }}
            // >
            //   Upgrade to Unlock this Feature
            // </div>
          )}
          {this.renderbetaButton() &&
            // {
            this.state.showModal.modalTitle === "Public Profile" && (
              <div
                style={{
                  width: "max-content",
                  margin: "0 auto",
                  marginTop: "15px",
                }}
              >
                <NavLink
                  className="stats nav-link"
                  to={"/onboarding"}
                  style={{ marginBottom: 0 }}
                >
                  <Button color="primary" className="text">
                    Beta coming soon
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
              // }
            )}
        </ModalBody>
      </Modal>
    );
  };
  renderbetaButton = () => {
    //add this line where you called this function if there's an error
    // this.state.showModal.button === true &&
    return this.props.user && this.props.user.tier !== "Coaching Solution";
  };
  renderToolbarLeftContent = () => {
    if (
      this.props.user &&
      this.props.user.enterpriseId &&
      this.props.user.enterpriseId
    ) {
      let isLogoAvailable =
        this.props.user.enterprise &&
        this.props.user.enterprise.logo &&
        this.props.user.enterprise.logo.url;
      return (
        <>
          {isLogoAvailable && (
            <img
              id="enterpriseLogo"
              src={this.props.user.enterprise.logo.url}
              alt="company logo"
            />
          )}
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            style={{ color: "black", textTransform: "initial" }}
            id="enterpriseNameHeading"
            className={isLogoAvailable ? "hideOnMobile" : ""}
          >
            {this.props.user &&
              this.props.user.enterprise &&
              this.props.user.name}
          </Typography>
        </>
      );
    }
    return (
      <Typography
        variant="h6"
        color="inherit"
        noWrap
        style={{ color: "black", textTransform: "initial" }}
      >
        Hello, {this.props.user.name}
      </Typography>
    );
  };

  render() {
    const { classes, theme, user } = this.props;

    const drawer = (
      <div id="dashboardDrawer" className="drawer">
        <div className="logo" />

        <List>
          {this.state.navItems.map((navItem) => {
            if (
              user.role === ROLES.TRAINER &&
              user.tier === TIER.FREE &&
              (navItem.text === "Staff" || navItem.text === "Documents")
            ) {
              return (
                <ListItem
                  className={"nav-link"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      showModal: {
                        value: true,
                        modalTitle: navItem.text,
                        button: true,
                      },
                    })
                  }
                >
                  <i
                    className="icon material-icons"
                    style={{ color: "#800520" }}
                  >
                    {navItem.icon}
                  </i>
                  <ListItemText
                    style={{ marginLeft: 16, color: "#282828" }}
                    primary={navItem.text}
                  />
                </ListItem>
              );
            } else if (
              user.role === ROLES.TRAINER &&
              user.tier === TIER.PRIVATE &&
              navItem.text === "Staff"
            ) {
              return (
                <ListItem
                  className={"nav-link"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      showModal: {
                        value: true,
                        modalTitle: navItem.text,
                        button: true,
                      },
                    })
                  }
                >
                  <i
                    className="icon material-icons"
                    style={{ color: "#800520" }}
                  >
                    {navItem.icon}
                  </i>
                  <ListItemText
                    style={{ marginLeft: 16, color: "#282828" }}
                    primary={navItem.text}
                  />
                </ListItem>
              );
            } else if (
              user.role === ROLES.TRAINER &&
              user.tier === TIER.FREE &&
              (navItem.text === "Staff" ||
                navItem.text === "Documents" ||
                navItem.text === "Messages")
            ) {
              return (
                <ListItem
                  className={"nav-link"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      showModal: {
                        value: true,
                        modalTitle: navItem.text,
                        button: true,
                      },
                    })
                  }
                >
                  <i
                    className="icon material-icons"
                    style={{ color: "#800520" }}
                  >
                    {navItem.icon}
                  </i>
                  <ListItemText
                    style={{ marginLeft: 16, color: "#282828" }}
                    primary={navItem.text}
                  />
                </ListItem>
              );
            } else {
              return (
                <NavLink
                  key={navItem.text}
                  id={`${navItem.text}walkthrough`}
                  to={navItem.path}
                  className={"nav-link"}
                >
                  <ListItem className={this.isAdmin ? "adminListItem" : ""}>
                    <i
                      className="icon material-icons"
                      style={{ color: "#800520" }}
                    >
                      {navItem.icon}
                    </i>
                    <ListItemText
                      style={{ marginLeft: 16, color: "#282828" }}
                      primary={navItem.text}
                    />
                    {navItem.text === "Messages" &&
                      this.state.messageCount !== 0 &&
                      this.msgCountText()}
                  </ListItem>
                </NavLink>
              );
            }
          })}
        </List>

        <List
          className="accountOptions"
          style={{ position: "absolute", bottom: 6, width: "100%" }}
        >
          {this.showSubscription() && (
            <NavLink to={"/subscriptions"} className={"nav-link"}>
              <ListItem>
                {/* <img
                alt="icon"
                src={"/assets/img/file-invoice-solid.svg"}
                style={{ width: "20px", height: "20px" }}
              /> */}

                <i className="icon material-icons" style={{ color: "#800520" }}>
                  {"text_snippet"}
                </i>

                <ListItemText
                  style={{ marginLeft: 16, color: "#282828" }}
                  id="subscriptions"
                  primary={"Subscriptions & Payments"}
                />
              </ListItem>
            </NavLink>
          )}

          {this.shouldShowFacilityInfoOption() && (
            <NavLink
              id="drawerFacilityInfoButton"
              to={"/facility-info"}
              className={"nav-link"}
            >
              <ListItem>
                <i className="icon material-icons" style={{ color: "#800520" }}>
                  {"business"}
                </i>
                <ListItemText
                  style={{ marginLeft: 16, color: "#282828" }}
                  id="facillityInfoText"
                  primary={"Facility Info"}
                />
                {this.isEnterpriseAdminRight &&
                  !this.isfacilityInfoCompleted() && (
                    <i
                      className="material-icons"
                      style={{
                        color: "#800520",
                        width: "20px",
                        fontSize: "10px",
                        marginRight: "60px",
                      }}
                    >
                      circle_icon
                    </i>
                  )}
              </ListItem>
            </NavLink>
          )}
          <ListItem
            className="nav-link"
            onClick={() =>
              this.setState({ mobileOpen: false }) || window.changePassword()
            }
            style={{ cursor: "pointer" }}
          >
            <i className="icon material-icons" style={{ color: "#800520" }}>
              {"security"}
            </i>
            <ListItemText
              style={{ marginLeft: 16, color: "#282828" }}
              primary={"Change Password"}
            />
          </ListItem>

          {this.shouldShowPublicProfileEditButton() && (
            <NavLink
              id="drawerProfileButton"
              to={"/onboarding"}
              className={"nav-link"}
            >
              <ListItem>
                <i className="icon material-icons" style={{ color: "#800520" }}>
                  {"account_circle"}
                </i>
                <ListItemText
                  style={{ marginLeft: 16, color: "#282828" }}
                  id="editProfile"
                  primary={"Update Profile"}
                />
              </ListItem>
            </NavLink>
          )}

          <NavLink to={"/profile"} className={"nav-link"}>
            <ListItem>
              <i className="icon material-icons" style={{ color: "#800520" }}>
                {"account_circle"}
              </i>
              <ListItemText
                style={{ marginLeft: 16, color: "#282828" }}
                id="editProfile"
                primary={"Edit Account"}
              />
              {!this.isProfileCompleted() && (
                <i
                  className="material-icons"
                  style={{
                    color: "#800520",
                    width: "20px",
                    fontSize: "10px",
                    marginRight: "60px",
                  }}
                >
                  circle_icon
                </i>
              )}{" "}
            </ListItem>
          </NavLink>

          <ListItem
            className="nav-link"
            onClick={this.logout}
            style={{ cursor: "pointer" }}
          >
            <i className="icon material-icons" style={{ color: "#800520" }}>
              {"power_settings_new"}
            </i>
            <ListItemText
              style={{ marginLeft: 16, color: "#282828" }}
              primary={"Logout"}
            />
          </ListItem>
        </List>

        <span className="versionText">
          Version {process.env.REACT_APP_VERSION}
        </span>
      </div>
    );

    return (
      <div className={classes.root} id="dashboard">
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          elevation={0}
          style={{
            background: "white",
            borderBottom: "1px solid #eee",
            padding: "10px 16px 10px 0px",
          }}
        >
          <Toolbar>
            <IconButton
              color="default"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <i className="icon material-icons">dehaze</i>
            </IconButton>
            {this.renderToolbarLeftContent()}
          </Toolbar>
          {this.renderToolbarRightContent()}
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.renderMain()}
        </main>
      </div>
    );
  }

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          locale={{ last: "Done" }}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
            spotlight: {
              maxWidth: "250px",
            },
          }}
        />
      </div>
    );
  };

  renderTrainerContent() {
    return (
      <React.Fragment>
        {this.state.walkthrough && this.renderJoyride()}
        <Row className="statsRow">
          {this.props.user.tier !== TIER.FREE && (
            <Col
              sm="12"
              // hidden={true}
              hidden={this.props.user.incomplete === false}
            >
              <div
                id="completeProfileCard"
                className="stats outlinedCard center"
                onClick={this.completeOnboarding}
              >
                <div className="userStats">
                  <p className="subtitle">Incomplete Profile</p>
                  <div id="incompleteProfileText">
                    <i className="icon material-icons">account_circle</i>
                    <p>
                      Complete your profile to start connecting to new clients
                    </p>
                  </div>
                  <div className="action">
                    <Badge
                      color="error"
                      className="profileBadge"
                      badgeContent=" "
                      variant="dot"
                    >
                      <NavLink className="nav-link" to={"/onboarding"}>
                        <Button color="primary" className="text">
                          Complete Now
                        </Button>
                        <i className="icon material-icons">
                          keyboard_arrow_right
                        </i>
                      </NavLink>
                    </Badge>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col lg="4">
            <div
              className="stats outlinedCard center"
              onClick={() => this.routeTo(1)}
            >
              <div className="userStats">
                <p className="subtitle">Total Clients</p>
                <div className="details">
                  <i className="icon material-icons">person</i>
                  <span className="value">
                    {this.props.user.totalClients || 0}
                  </span>
                </div>
                <div className="action">
                  <NavLink
                    id="viewClientButton"
                    className="nav-link"
                    to={"/client-editor"}
                  >
                    <Button className="text">View Clients</Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
          {this.props.user.tier !== TIER.FREE && (
            <Col lg="4">
              <div
                className="stats outlinedCard center"
                onClick={() => this.routeTo(14)}
              >
                <div className="userStats">
                  <p className="subtitle">Documents</p>
                  <div className="details">
                    <i className="icon material-icons">note_add</i>
                    <span className="value">
                      {this.props.user &&
                      this.props.user.tier !== TIER.FREE &&
                      this.props.user.totalDocuments
                        ? this.props.user.totalDocuments
                        : "-"}
                    </span>
                  </div>
                  <div className="action">
                    <NavLink className="nav-link" to={"/client-editor"}>
                      <Button color="primary" className="text">
                        manage documents
                      </Button>
                      <i className="icon material-icons">
                        keyboard_arrow_right
                      </i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Col lg="4">
            <div
              className="stats outlinedCard center"
              onClick={() => this.routeTo(2)}
            >
              <div className="userStats">
                <p className="subtitle">Pending Invites</p>
                <div className="details">
                  <i className="icon material-icons">mail</i>
                  <span className="value">
                    {this.props.user.pendingClients || 0}
                  </span>
                </div>
                <div className="action">
                  <NavLink
                    id="viewPendingInvitesButton"
                    className="nav-link"
                    to={"/client-editor"}
                  >
                    <Button color="primary" className="text">
                      View Pending Invites
                    </Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="4">
            <div
              className="stats outlinedCard center"
              onClick={() => this.routeTo(3)}
            >
              <div className="userStats">
                <p className="subtitle">Client Requests</p>
                <div className="details">
                  <i className="icon material-icons">mail</i>
                  <span className="value">
                    {this.props.user.pendingRequests || 0}
                  </span>
                </div>
                <div className="action">
                  <NavLink
                    id="viewClientRequestButton"
                    className="nav-link"
                    to={"/client-editor"}
                  >
                    <Button color="primary" className="text">
                      View Client Requests
                    </Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="4">
            <div
              className="stats outlinedCard center"
              onClick={() => this.routeTo(4)}
            >
              <div className="userStats">
                <p className="subtitle">Workout Templates</p>
                <div className="details">
                  <i className="icon material-icons">assignment</i>
                  <span className="value">
                    {this.props.user.workoutdayCount || 0}
                  </span>
                </div>
                <div className="action">
                  <NavLink
                    id="viewPlannedWorkoutButton"
                    className="nav-link"
                    to={"/client-editor"}
                  >
                    <Button color="primary" className="text">
                      View Planned Workouts
                    </Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="statsRowTwo">
          <Col lg="6">
            <div
              className="stats card center"
              onClick={() =>
                this.setState({
                  showModal: {
                    value: true,
                    modalTitle: "Client Requests",
                    button: false,
                  },
                })
              }
            >
              <div className="userStats">
                <p className="subtitle">Client Requests</p>
                <div className="details">
                  <span className="value">New Feature Coming Soon!</span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div
              className="stats card center"
              onClick={() =>
                this.setState({
                  showModal: {
                    value: true,
                    modalTitle: "Public Profile",
                    button: false,
                  },
                })
              }
            >
              <div className="userStats">
                <p className="subtitle">Public Profile</p>
                <div className="details">
                  <span className="value">New Feature Coming Soon!</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  renderAdminContent() {
    return (
      <Row className="statsRow">
        <Col>
          <div className="stats card center" onClick={() => this.routeTo(5)}>
            <div className="userStats">
              <p className="subtitle">Pending Approvals</p>
              <div className="details">
                <i className="icon material-icons">person</i>
                <span className="value">
                  {this.props.user.pendingApprovals || 0}
                </span>
              </div>
              <div className="action">
                <NavLink className="nav-link" to={"/client-editor"}>
                  <Button color="primary" className="text">
                    Approve Trainers
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="stats card center" onClick={() => this.routeTo(7)}>
            <div className="userStats">
              <p className="subtitle">Trainers</p>
              <div className="details">
                <i className="icon material-icons">person</i>
                <span className="value">{this.props.user.trainers || 0}</span>
              </div>
              <div className="action">
                <NavLink className="nav-link" to={"/client-editor"}>
                  <Button color="primary" className="text">
                    Manage Trainers
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="stats card center" onClick={() => this.routeTo(8)}>
            <div className="userStats">
              <p className="subtitle">Public Trainers</p>
              <div className="details">
                <i className="icon material-icons">person</i>
                <span className="value">{"-"}</span>
              </div>
              <div className="action">
                <NavLink className="nav-link" to={"/client-editor"}>
                  <Button color="primary" className="text">
                    Manage Public Trainers
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="stats card center" onClick={() => this.routeTo(9)}>
            <div className="userStats">
              <p className="subtitle">Challenges</p>
              <div className="details">
                <i className="icon material-icons">person</i>
                <span className="value">{"-"}</span>
              </div>
              <div className="action">
                <NavLink className="nav-link" to={"/workout-assigner/create"}>
                  <Button color="primary" className="text">
                    Manage Challenges
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="stats card center" onClick={() => this.routeTo(11)}>
            <div className="userStats">
              <p className="subtitle">Enterprise Accounts</p>
              <div className="details">
                <i className="icon material-icons">account_balance</i>
                <span className="value">{"-"}</span>
              </div>
              <div className="action">
                <NavLink className="nav-link" to={"/enterprise-accounts"}>
                  <Button color="primary" className="text">
                    Manage Enterprises
                  </Button>
                  <i className="icon material-icons">keyboard_arrow_right</i>
                </NavLink>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderEnterpriseUserContent = () => {
    let isEnterpriseAdmin = Boolean(
      this.props.user.role === ROLES.ENTERPRISE_ADMIN
    );
    let isFacilityAdmin = Boolean(
      this.props.user.role === ROLES.FACILITY_ADMIN
    );
    let isHealthCoach = Boolean(this.props.user.role === ROLES.HEALTH_COACH);
    return (
      <>
        <Row className="statsRow">
          {(isEnterpriseAdmin || isFacilityAdmin) && (
            <Col lg="4">
              <div
                className="stats card center"
                onClick={() => this.routeTo(12)}
              >
                <div className="userStats">
                  <p className="subtitle">Total Staff</p>
                  <div className="details">
                    <i className="icon material-icons">person</i>
                    <span className="value">
                      {this.props.user &&
                      this.props.user.enterprise &&
                      this.props.user.enterprise.totalStaff
                        ? this.props.user.enterprise.totalStaff
                        : "-"}
                    </span>
                  </div>
                  <div className="action">
                    <NavLink className="nav-link" to={"/client-editor"}>
                      <Button color="primary" className="text">
                        View Staff
                      </Button>
                      <i className="icon material-icons">
                        keyboard_arrow_right
                      </i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col lg={isHealthCoach ? "6" : "4"}>
            <div className="stats card center" onClick={() => this.routeTo(13)}>
              <div className="userStats">
                <p className="subtitle">Total Clients</p>
                <div className="details">
                  <i className="icon material-icons">person</i>
                  <span className="value">
                    {this.props.user.role === ROLES.HEALTH_COACH
                      ? this.state.clientHC
                      : this.props.user &&
                        this.props.user.enterprise &&
                        this.props.user.enterprise.totalClients
                      ? this.props.user.enterprise.totalClients
                      : this.props.user.totalClients
                      ? this.props.user.totalClients
                      : "-"}
                  </span>
                </div>
                <div className="action">
                  <NavLink className="nav-link" to={"/client-editor"}>
                    <Button color="primary" className="text">
                      view clients
                    </Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={isHealthCoach ? "6" : "4"}>
            <div className="stats card center" onClick={() => this.routeTo(14)}>
              <div className="userStats">
                <p className="subtitle">Documents</p>
                <div className="details">
                  <i className="icon material-icons">note_add</i>
                  <span className="value">
                    {this.props.user &&
                    this.props.user.enterprise &&
                    this.props.user.enterprise.totalDocuments
                      ? this.props.user.enterprise.totalDocuments
                      : "-"}
                  </span>
                </div>
                <div className="action">
                  <NavLink className="nav-link" to={"/client-editor"}>
                    <Button color="primary" className="text">
                      manage documents
                    </Button>
                    <i className="icon material-icons">keyboard_arrow_right</i>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="statsRowTwo">
          <Col lg="6">
            <div
              className="stats card center"
              onClick={() =>
                this.setState({
                  showModal: { value: true, modalTitle: "Client Requests" },
                })
              }
            >
              <div className="userStats">
                <p className="subtitle">Client Requests</p>
                <div className="details">
                  <span className="value">New Feature Coming Soon!</span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div
              className="stats card center"
              onClick={() =>
                this.setState({
                  showModal: { value: true, modalTitle: "Public Profile" },
                })
              }
            >
              <div className="userStats">
                <p className="subtitle">Public Profile</p>
                <div className="details">
                  <span className="value">New Feature Coming Soon!</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  renderRoleSpecificContent = () => {
    switch (this.props.user.role) {
      case ROLES.ADMIN:
        return this.renderAdminContent();
      case ROLES.ENTERPRISE_ADMIN:
      case ROLES.FACILITY_ADMIN:
      case ROLES.HEALTH_COACH:
        return this.renderEnterpriseUserContent();
      case ROLES.TRAINER:
        return this.renderTrainerContent();
      case ROLES.PRACTITIONER:
        return this.renderTrainerContent();
      default:
        window.NotificationUtils.showError("Something went wrong");
        this.logout();
    }
  };

  renderMain() {
    return (
      <Container className="dashboard">
        {this.renderRoleSpecificContent()}
        {this.renderPopupModal()}
      </Container>
    );
  }
}

const Dashboard = withStyles(styles, {
  withTheme: true,
})(DashboardComponent);

export default class DashboardWrapper extends React.Component {
  render() {
    return <Dashboard {...this.props}></Dashboard>;
  }
}
