import {
  ANALYTIC_EVENTS,
  COLLECTIONS,
  DB_KEYS,
  FORM_TYPES,
  INITIALIZE_WALKTHROUGH,
  INTERVAL,
  PDF_TEMPLATES,
  ROLES,
  TIER,
  USER_STATES,
  WORKOUT_SECTIONS_NAMES,
} from "../../constants";
// import { useAuthState } from "react-firebase-hooks/auth";
import hash from "object-hash";
import bodybuilder from "bodybuilder";
import { saveAs } from "file-saver";
import { sendRequest } from "../http/HttpHelper";
import memoryCacheWrapper from "./memoryCacheWrapper";
import moment from "moment";
import XLSX from "xlsx";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_APP_ID,
};

window.firebase.initializeApp(config);
const firebase = window.firebase;
export const auth = firebase.auth();
const datastore = firebase.firestore();
const storage = firebase.storage();
var storageRef = storage.ref();

const functions = firebase.functions();
const analytics = firebase.analytics();

// export const messaging = firebase.messaging();
const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
//......
//Using Emulator
if (window.location.hostname === "localhost") {
  console.log("localhost detected!");
  // auth.useEmulator("http://localhost:9099");
  // db.useEmulator("localhost", 8080);
}

// functions.useFunctionsEmulator("http://localhost:5001");

var currentUserData;
const createNewClientFN = functions.httpsCallable("createClientAccount");
const sendInviteFN = functions.httpsCallable("resendInvite");
const removeClientFN = functions.httpsCallable("removeClient");
const requestExerciseFN = functions.httpsCallable("requestExercise");
const approveTrainerRequestFN = functions.httpsCallable(
  "approveTrainerRequest"
);
const declineTrainerRequestFN = functions.httpsCallable(
  "declineTrainerRequest"
);
const approveClientRequestFN = functions.httpsCallable("approveClientRequest");
const rejectClientRequestFN = functions.httpsCallable("rejectClientRequest");
const showTrainerFN = functions.httpsCallable("showTrainer");
const hideTrainerFN = functions.httpsCallable("hideTrainer");
const clientEmailVerificationFN = functions.httpsCallable(
  "clientEmailVerification"
);
const sendMessageFN = functions.httpsCallable("sendMessage");
const notifyTrainerVerification = functions.httpsCallable("onTrainerVerify");
const confirmEnterpriseInviteFN = functions.httpsCallable(
  "confirmEnterpriseInvite"
);
const fetchEnterpriseInviteFN = functions.httpsCallable(
  "fetchEnterpriseInvite"
);
const checkIfEmailPresentFN = functions.httpsCallable("checkIfEmailPresent");
const sendOtpFN = functions.httpsCallable("sendOtp");
const verifyOtpFN = functions.httpsCallable("verifyOtp");
const generateFormVersionPDFFN = functions.httpsCallable(
  "generateFormVersionPDF"
);
const sendWorkoutEmailFN = functions.httpsCallable("sendWorkoutEmail");

function createUserWithEmailAndPassword(email, password) {
  analytics.logEvent(ANALYTIC_EVENTS.SIGNUP);
  return auth.createUserWithEmailAndPassword(email, password);
}

// export const onMessageListener = () => {
//   console.log("hellox");
//   messaging.onMessage((payload) => {
//     console.log("payload in database un dasbopard", payload);
//     const notificationTitle = payload.notification.title;
//     const notificationOptions = {
//       body: payload.notification.body,
//       icon: payload.notification.icon,
//     };
//     // eslint-disable-next-line no-restricted-globals
//     self.registration.showNotification(notificationTitle, notificationOptions);
//     // ServiceWorkerRegistration.showNotification(notificationTitle, notificationOptions)
//     // new Notification(notificationTitle, notificationOptions);
//   });
// };

async function pushNotificationApi(name, text, currentToken) {
  let API_URL = `https://fcm.googleapis.com/fcm/send`;
  let notification = {
    registration_ids: currentToken,
    requireInteraction: true,
    content_available: true,
    priority: "high",
    notification: {
      title: name,
      body: text,
      click_action: "https://appstaging.fortisforma.com/#/login",
      icon: "https://staging.fortisforma.com" + "/assets/img/logo.png",
      badge: 1,
    },
    fcm_options: {
      link: "https://appstaging.fortisforma.com/#/",
    },
    data: {
      pathname: "https://appstaging.fortisforma.com/#/",
      name,
      text,
    },
  };
  try {
    let response = fetch(API_URL, {
      method: "POST",
      headers: {
        Authorization: `key=${process.env.REACT_APP_WEB_PUSH_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notification),
    });
  } catch (e) {
    throw e;
  }
}

// export const askForPermissioToReceiveNotifications = async (registration) => {
//   let user = currentUser();
//   try {
//     // const registration = await navigator.serviceWorker
//     //   .register("firebase-message-sw.js", {
//     //     scope: "/",
//     //     updateViaCache: "none",
//     //   })
//     //   .then((registration) => {
//     //     return registration;
//     //   })
//     //   .catch((e) => {});
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       if (localStorage.getItem("notiToken") !== null) {
//         // const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
//         // let notificationToken = localStorage.getItem("notiToken");
//         // try {
//         //     ref.set({ notificationToken }, { merge: true });
//         // } catch (e) {
//         //     throw e;
//         // }
//       } else {
//         const token = await messaging.getToken({
//           vapidKey: publicKey,
//           serviceWorkerRegistration: registration,
//         });
//         // const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
//         // let notificationToken = token;
//         // try {
//         //     ref.set({ notificationToken }, { merge: true });
//         // } catch (e) {
//         //     throw e;
//         // }
//         localStorage.setItem("notiToken", token);
//       }
//     } else {
//       if (localStorage.getItem("notiToken") !== null) {
//         // const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
//         // let notificationToken = localStorage.getItem("notiToken");
//         // try {
//         //     ref.set({ notificationToken }, { merge: true });
//         // } catch (e) {
//         //     throw e;
//         // }
//       } else {
//         const token = await messaging.getToken({
//           vapidKey: publicKey,
//           serviceWorkerRegistration: registration,
//         });
//         // const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
//         // let notificationToken = token;
//         // try {
//         //     ref.set({ notificationToken }, { merge: true });
//         // } catch (e) {
//         //     throw e;
//         // }
//         localStorage.setItem("notiToken", token);
//       }
//     }

//     // return token;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const sendMessageofNotification = async ({ name, text }, user) => {
  if (user.notificationToken) {
    await pushNotificationApi(name, text, user.notificationToken);
  }
  // else {
  //     messaging.getToken({ vapidKey: publicKey }).then((currentToken) => {
  //         if (currentToken) {
  //             const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.id);
  //             let notificationToken = currentToken;
  //             try {
  //                 ref.set({ notificationToken }, { merge: true });
  //             } catch (e) {
  //                 throw e;
  //             }
  //             pushNotificationApi(name, text, currentToken);
  //         } else {
  //             // Show permission request UI
  //
  //             console.log("No registration token available. Request permission to generate one.");
  //             // ...
  //         }
  //     }).catch((err) => {
  //         console.log("An error occurred while retrieving token. ", err);
  //
  //     });
  // }
};

export async function currentUserMessages(trainerId, clientId) {
  let data = [];
  return new Promise(async (resolve, reject) => {
    try {
      if (trainerId.id && clientId.id) {
        if (clientId.role !== "Client") {
          let id = trainerId + "-" + clientId;
          let id2 = clientId + "-" + trainerId;
          if (trainerId && clientId) {
            datastore
              .collection(COLLECTIONS.MESSAGE)
              .where("type", "in", [id, id2])
              .orderBy("createdAt")
              .get()
              .then((doc) => {
                doc.docs.map((item, index) => {
                  data.push(item.data());
                });
                resolve(data);
              });
          }
        } else {
          datastore
            .collection(COLLECTIONS.MESSAGE)
            .where("trainerId", "==", trainerId.id)
            .where("clientId", "==", clientId.id)
            .orderBy("createdAt")
            .get()
            .then((doc) => {
              doc.docs.map((item) => {
                data.push(item.data());
              });

              resolve(data);
            });

          datastore
            .collection(COLLECTIONS.MESSAGE)
            .where("trainerId", "==", trainerId)
            .where("clientId", "==", clientId)
            .where("trainer_read_status", "==", false)
            .get()
            .then((doc) => {
              doc.docs.map((item) => {
                let cityRef = datastore
                  .collection(COLLECTIONS.MESSAGE)
                  .doc(item.id);
                cityRef.set(
                  {
                    trainer_read_status: true,
                  },
                  { merge: true }
                );
              });
            });
        }
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function addUserMessages(values, user, client, msgId) {
  let date = new Date().getTime();
  try {
    const messagesRef = datastore.collection(COLLECTIONS.MESSAGE);
    if (client.role === "Client") {
      let obj = {
        _id: msgId,
        text: values,
        createdAt: date,
        trainerId: user.id,
        clientId: client.id,
        sender: user.role,
        receiver: client.role,
        client_read_status: false,
        user: {
          _id: user.id,
        },
      };
      let result = await messagesRef.add(obj);
      return Promise.resolve(result);
    } else {
      let obj = {
        _id: msgId,
        text: values,
        createdAt: date,
        type: user.id + "-" + client.id,
        trainerId: user.id,
        clientId: client.id,
        sender: user.role,
        receiver: client.role,
        coach_read_status: false,
        user: {
          _id: user.id,
        },
      };
      let result = await messagesRef.add(obj);
      return Promise.resolve(result);
    }
  } catch (e) {
    throw e;
  }
}

async function storeUserProfile(profile) {
  if (!profile.id) {
    let user = currentUser();
    let id = user.uid;
    profile.id = id;
  }
  let clientId = profile.id;
  try {
    let ref = datastore
      .collection(
        profile.pendingLogin ? COLLECTIONS.INVITES : COLLECTIONS.USER_DATA
      )
      .doc(profile.id);
    if (profile.role === ROLES.CLIENT && !clientId) {
      analytics.logEvent(ANALYTIC_EVENTS.NEW_CLIENT);
    }
    let results = await ref.set(profile, { merge: true });
    return results;
  } catch (e) {
    throw e;
  }
}

async function updateUserData(update) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  try {
    await datastore
      .collection(COLLECTIONS.USER_DATA)
      .doc(user.uid)
      .update(update);
  } catch (e) {
    throw e;
  }
}

async function getProgramHistory(programId) {
  let ref = datastore.collection(COLLECTIONS.PROGRAM_HISTORY).doc(programId);
  try {
    let results = await ref.get();
    return results.data();
  } catch (e) {
    throw e;
  }
}

function signInWithEmailAndPassword(email, password) {
  analytics.logEvent(ANALYTIC_EVENTS.LOGIN);
  return auth.signInWithEmailAndPassword(email, password);
}

function sendPasswordResetEmail(email, actionCodeSettings) {
  return auth.sendPasswordResetEmail(email, actionCodeSettings);
}

function updatePassword(password) {
  return auth.currentUser.updatePassword(password);
}

function signOut() {
  token = null;
  currentUserData = null;
  return auth.signOut();
}

function getUserData(forceFetch, userId) {
  const authCurrentUser = currentUser();
  if (!authCurrentUser || !authCurrentUser.uid) {
    signOut();
    throw new Error("Current user is null");
  }
  if (!userId) {
    if (currentUserData && !forceFetch) {
      return Promise.resolve(currentUserData);
    }
  }
  userId = userId || authCurrentUser.uid;
  const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(userId);

  return new Promise(async (resolve, reject) => {
    try {
      const user = await ref.get();
      if (!user.exists) {
        let error = {
          code: 404,
          message: "User details do not exist",
        };
        throw error;
      }
      let data = user.data();
      if (userId === authCurrentUser.uid) {
        data.id = userId;
        currentUserData = data;
        currentUserData.emailVerified = emailVerified();
      }
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

async function stripeCallForNewUser(email) {
  const authToken = await getUserToken();
  const authCurrentUser = currentUser();

  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  };
  let userEmail = email ? email : authCurrentUser.email;
  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}check/subscription/${userEmail}`;

  return fetch(apiURL, fetchOptions)
    .then((res) => res.json())
    .then((nw) => {
      return nw;
    })
    .catch((e) => {
      throw e;
    });
}

async function stripeCardDetail(endpoint, priceId, customerId) {
  const authToken = await getUserToken();
  const authCurrentUser = currentUser();

  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  };

  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}${endpoint}/${priceId}/${customerId}`;

  return fetch(apiURL, fetchOptions)
    .then((res) => res.json())
    .then((nw) => {
      return nw;
    })
    .catch((e) => {
      throw e;
    });
}

async function stripeCall(
  endpoint,
  customerId,
  priceId,
  interval,
  type,
  currentTier,
  prevTier,
  subscription
) {
  const authToken = await getUserToken();
  const authCurrentUser = currentUser();

  let obj = {
    price_id: priceId,
    type: type,
    currentTier,
    prevTier,
    trial: subscription.trial,
    subscription: subscription,
  };
  if (priceId === null) {
    delete obj.price_id;
  }
  if (interval) {
    obj.interval = interval;
  }
  if (customerId) {
    obj.cus_id = customerId;
  } else {
    if (priceId !== null) {
      obj.cus_name = authCurrentUser.name;
      obj.cus_email = authCurrentUser.email;
    }
  }

  let fetchOptions = {
    body: JSON.stringify(obj),
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  if (!customerId && !priceId) {
    fetchOptions.method = "POST";
  }
  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}${endpoint}`;

  return fetch(apiURL, fetchOptions)
    .then((res) => res.json())
    .then((nw) => {
      return nw;
    })
    .catch((e) => {
      throw e;
    });
}

function getPurchaseHistory(endpoint) {
  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}${endpoint}`;
  return fetch(apiURL)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      throw error;
    });
}

function getEnterpriseData(enterpriseId) {
  if (!enterpriseId) {
    throw new Error("enterprise id provided is null");
  }

  const ref = datastore.collection(COLLECTIONS.ENTERPRISE).doc(enterpriseId);

  return new Promise(async (resolve, reject) => {
    try {
      const enterpriseData = await ref.get();
      if (!enterpriseData.exists) {
        let error = {
          code: 404,
          message: "Enterprise details do not exist",
        };
        throw error;
      }
      let data = enterpriseData.data();
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

function getProgram(id) {
  const ref = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc(id);

  return new Promise(async (resolve, reject) => {
    try {
      const program = await ref.get();
      if (!program.exists) {
        let error = {
          code: 404,
          message: "program not found",
        };
        throw error;
      }
      let programData = program.data();
      resolve(programData);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

function getInviteData(userId) {
  if (!auth.currentUser) {
    throw new Error("Current user is null");
  }
  const ref = datastore.collection(COLLECTIONS.INVITES).doc(userId);

  return new Promise(async (resolve, reject) => {
    try {
      const user = await ref.get();
      if (!user.exists) {
        let error = {
          code: 404,
          message: "User details do not exist",
        };
        throw error;
      }
      resolve(user.data());
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

async function getCustomClaims(force = false) {
  if (!auth.currentUser) {
    throw new Error("Current user is null");
  }
  const idTokenResult = await auth.currentUser.getIdTokenResult(force);
  if (!idTokenResult || !idTokenResult.claims) {
    throw new Error("Claims not found");
  }
  return idTokenResult.claims;
}

async function updateUserLoginTime() {
  if (!auth.currentUser) {
    throw new Error("Current user is null");
  }
  let user = currentUser();
  if (!user || !user.uid) {
    signOut();
    throw new Error("uid is null");
  }
  let id = user.uid;
  const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
  let login = {};
  login[DB_KEYS.LAST_LOGIN_KEY] = window.firebase.firestore.Timestamp.fromDate(
    new Date()
  );
  try {
    // let resultn = await window.FortisForma.database.stripeCallForNewUser();

    await ref.set(login, { merge: true });
  } catch (e) {
    throw e;
  }
}

async function saveRegistrationToken(token) {
  // Make an API call to your app's backend server to save the token
}

function registerServiceWorker() {
  return navigator.serviceWorker.register("/service-worker.js");
}

async function registerPushNotifications() {
  const messaging = firebase.messaging();
  // await registerServiceWorker();
  const result = await getRegistrationToken();

  messaging.onTokenRefresh(async () => {
    const token = await messaging.getToken();
    await saveRegistrationToken(token);
  });

  window.addEventListener("push", (event) => {
    if (event.data) {
      const data = event.data.json();
      const { title, body } = data;
      event.waitUntil(window.registration.showNotification(title, { body }));
    }
  });
}

async function getRegistrationToken() {
  const messaging = firebase.messaging();
  await requestPermission();
  const token = await messaging.getToken();
  return token;
}

function requestPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    if (permissionResult !== "granted") {
      console.log("We weren't granted permission");
      // throw new Error("We weren't granted permission.");
    }
  });
}

async function updateUserTrainer(trainer, clientId, pendingLogin) {
  if (!auth.currentUser) {
    throw new Error("Current user is null");
  }
  let user = currentUser();
  if (user.role === "Admin") {
    return;
  }

  if (clientId.role === ROLES.HEALTH_COACH) {
    if (trainer.chatTrainer !== undefined) {
      let chatTrainerArr = [];
      if (
        typeof trainer.chatTrainer === "object" &&
        !Array.isArray(trainer.chatTrainer)
      ) {
        chatTrainerArr.push(trainer.chatTrainer);
      }

      const found = chatTrainerArr.length
        ? chatTrainerArr.some((item) => item.id === clientId.id)
        : trainer.chatTrainer.some((item) => item.id === clientId.id);
      let uniqueArray = [];
      if (chatTrainerArr.length > 0) {
        uniqueArray = [
          ...new Map(chatTrainerArr.map((m) => [m.id, m])).values(),
        ];
      } else {
        uniqueArray = [
          ...new Map(trainer.chatTrainer.map((m) => [m.id, m])).values(),
        ];
      }

      if (!found) {
        let ref;
        if (pendingLogin) {
          ref = datastore.collection(COLLECTIONS.INVITES).doc(trainer.id);
        } else {
          ref = datastore.collection(COLLECTIONS.USER_DATA).doc(trainer.id);
        }

        let chatTrainer = [];
        chatTrainer.push(...uniqueArray, {
          role: clientId.role,
          id: clientId.id,
          name: clientId.name,
          tier: clientId.tier ? clientId.tier : clientId.role,
          notificationToken: localStorage.getItem("notiToken"),
        });

        try {
          await ref.set({ chatTrainer }, { merge: true });
        } catch (e) {
          throw e;
        }
      }
    } else {
      let ref;
      if (pendingLogin) {
        ref = datastore.collection(COLLECTIONS.INVITES).doc(trainer.id);
      } else {
        ref = datastore.collection(COLLECTIONS.USER_DATA).doc(trainer.id);
      }
      let chatTrainer = [];
      chatTrainer.push({
        role: clientId.role,
        id: clientId.id,
        name: clientId.name,
        tier: clientId.tier ? clientId.tier : clientId.role,
        notificationToken: localStorage.getItem("notiToken"),
      });

      try {
        await ref.set({ chatTrainer }, { merge: true });
      } catch (e) {
        throw e;
      }
    }
  }
  if (user) {
    const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
    let notificationToken = localStorage.getItem("notiToken");
    try {
      ref.set({ notificationToken }, { merge: true });
    } catch (e) {
      throw e;
    }
  }
  if (!user || !user.uid) {
    signOut();
    throw new Error("uid is null");
  }

  if (clientId.chatTrainer) {
    let chatTrainerArr = [];

    if (
      typeof clientId.chatTrainer === "object" &&
      !Array.isArray(clientId.chatTrainer)
    ) {
      chatTrainerArr.push(clientId.chatTrainer);
    }

    const found =
      chatTrainerArr.length > 0
        ? chatTrainerArr.some((item) => item.id === trainer.id)
        : clientId.chatTrainer.some((item) => item.id === trainer.id);
    let uniqueArray = [];
    if (chatTrainerArr.length > 0) {
      uniqueArray = [...new Map(chatTrainerArr.map((m) => [m.id, m])).values()];
    } else {
      uniqueArray = [
        ...new Map(clientId.chatTrainer.map((m) => [m.id, m])).values(),
      ];
    }

    if (!found) {
      let ref;
      if (pendingLogin) {
        ref = datastore.collection(COLLECTIONS.INVITES).doc(clientId.id);
      } else {
        ref = datastore.collection(COLLECTIONS.USER_DATA).doc(clientId.id);
      }

      let chatTrainer = [];
      chatTrainer.push(...uniqueArray, {
        role: trainer.role,
        id: trainer.id,
        name: trainer.name,
        tier: trainer.tier ? trainer.tier : trainer.role,
        notificationToken: localStorage.getItem("notiToken"),
      });

      try {
        await ref.set({ chatTrainer }, { merge: true });
      } catch (e) {
        throw e;
      }
    } else {
      let ref;
      if (pendingLogin) {
        ref = datastore.collection(COLLECTIONS.INVITES).doc(clientId.id);
      } else {
        ref = datastore.collection(COLLECTIONS.USER_DATA).doc(clientId.id);
      }
      let chatTrainer = [];

      chatTrainer.push({
        role: trainer.role,
        id: trainer.id,
        name: trainer.name,
        tier: trainer.tier ? trainer.tier : trainer.role,
        notificationToken: localStorage.getItem("notiToken"),
      });

      try {
        await ref.set({ chatTrainer }, { merge: true });
      } catch (e) {
        throw e;
      }
    }
  } else {
    let ref;
    if (pendingLogin) {
      ref = datastore.collection(COLLECTIONS.INVITES).doc(clientId.id);
    } else {
      ref = datastore.collection(COLLECTIONS.USER_DATA).doc(clientId.id);
    }
    let chatTrainer = [];

    chatTrainer.push({
      role: trainer.role,
      id: trainer.id,
      name: trainer.name,
      tier: trainer.tier ? trainer.tier : trainer.role,
      notificationToken: localStorage.getItem("notiToken"),
    });

    try {
      await ref.set({ chatTrainer }, { merge: true });
    } catch (e) {
      throw e;
    }
  }
}

async function getUserProgram(clientId, pendingLogin) {
  if (!auth.currentUser) {
    try {
      await currentUserPromise();
    } catch (e) {
      throw new Error("Current user is null");
    }
  }

  let user;
  if (pendingLogin) {
    try {
      user = await getInviteData(clientId);
    } catch (e) {
      throw e;
    }
  } else {
    try {
      user = await getUserData(true, clientId);
    } catch (e) {
      throw e;
    }
  }
  if (!user.programId) {
    let error = {
      message: "No program assigned",
      code: 404,
    };
    throw error;
  }

  let programId = "";
  if (
    user.programId &&
    user.programs &&
    user.programs.length > 0 &&
    user.programs[0].id
  ) {
    if (user.programs[0].id === user.programId) {
      programId = user.programId;
    } else {
      programId = user.programs[0].id;
    }
  }

  if (!programId) {
    return {};
  }

  const ref = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc(programId);

  return new Promise(async (resolve, reject) => {
    try {
      const program = await ref.get();
      if (!program.exists) {
        throw new Error("Workout program does not exist");
      }
      let data = program.data();
      data.id = programId;

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

async function getExercisesWithProperty(propertyName, propertyValue) {
  return queryData({
    collection: COLLECTIONS.EXERCISES,
    filters: [
      {
        key: propertyName,
        operator: "==",
        value: propertyValue,
      },
    ],
  });
}

async function getSimilarExercises(groupId) {
  return queryData({
    collection: COLLECTIONS.EXERCISES,
    filters: [
      {
        key: DB_KEYS.GROUP_ID_KEY,
        operator: "==",
        value: groupId,
      },
      {
        key: DB_KEYS.TRAINER_ID_KEY,
        operator: "==",
        value: "",
      },
    ],
  });
}

async function getWorkouts(query) {
  let results = await queryData(query);
  for (let result of results) {
    if (result.exercises) {
      result.workoutSections = [
        {
          workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
          sets: 1,
          exercises: result.exercises,
        },
      ];
      delete result.exercises;
    }
  }
  return results;
}

async function getPrograms(trainerId) {
  return queryData({
    collection: COLLECTIONS.WORKOUT_PROGRAM,
    filters: [
      {
        key: DB_KEYS.CREATOR_ID_KEY,
        operator: "==",
        value: trainerId,
      },
    ],
  });
}

async function getClientsForProgram(trainerId, programId) {
  return queryData({
    collection: COLLECTIONS.USER_DATA,
    filters: [
      {
        key: DB_KEYS.ROLE,
        operator: "==",
        value: "Client",
      },
      {
        key: DB_KEYS.TRAINER_ID_KEY,
        operator: "==",
        value: trainerId,
      },
      {
        key: DB_KEYS.PROGRAM_ID_KEY,
        operator: "==",
        value: programId,
      },
    ],
  });
}

function exists(item, array) {
  let filtered = array.filter((arrayItem) => {
    return arrayItem === item;
  });

  if (filtered && filtered.length > 0) {
    return true;
  }
  return false;
}

async function addBlogs(blogs, isNew, docId) {
  let blogRef;
  if (isNew) {
    blogRef = datastore.collection(COLLECTIONS.BLOGS).doc().set(blogs);
  } else {
    blogRef = datastore.collection(COLLECTIONS.BLOGS).doc(docId).update(blogs);
  }
  return blogRef;
}

async function deleteBlog(docId) {
  let blogRef;

  blogRef = await datastore.collection(COLLECTIONS.BLOGS).doc(docId).delete();

  return blogRef;
}

async function convertDraftToTemplate(docId) {
  try {
    let draftRef = await datastore
      .collection(COLLECTIONS.WORKOUT_DAYS)
      .doc(docId);
    return datastore.runTransaction(async (transaction) => {
      await transaction.update(draftRef, "templateName", "save");
    });
    // let result= datastore.runTransaction(async (transaction) => {
    //     await transaction.delete(draftRef);
    // });
  } catch (e) {
    throw new Error(e);
  }
}

async function deleteDraftWorkouts(docId) {
  try {
    let draftRef = await datastore
      .collection(COLLECTIONS.WORKOUT_DAYS)
      .doc(docId);
    return datastore.runTransaction(async (transaction) => {
      await transaction.delete(draftRef);
    });
    // let result= datastore.runTransaction(async (transaction) => {
    //     await transaction.delete(draftRef);
    // await transaction.update(draftRef, "templateName", "save");
    // });
  } catch (e) {
    throw new Error(e);
  }
}

async function storeWorkout(workoutDay, type) {
  let user = currentUser();
  let id = user.uid;
  let isEnterpriseWorkout = workoutDay.enterpriseId;
  if (!workoutDay.createdTime) {
    workoutDay.createdTime = window.firebase.firestore.Timestamp.fromDate(
      new Date()
    );
  }

  workoutDay.updatedTime = window.firebase.firestore.Timestamp.fromDate(
    new Date()
  );

  if (!id) {
    throw new Error("User id missing");
  }
  let _exerciseIds = [];
  workoutDay.workoutSections =
    workoutDay.workoutSections &&
    workoutDay.workoutSections.map((section) => {
      section.exercises.map((e) => {
        if (!exists(e.id, _exerciseIds)) {
          _exerciseIds.push(e.id);
        }
        return e;
      });
      return section;
    });
  workoutDay._exerciseIds = _exerciseIds;
  workoutDay.templateName = type;
  let workoutdayDocRef;

  if (workoutDay.id) {
    workoutdayDocRef = datastore
      .collection(COLLECTIONS.WORKOUT_DAYS)
      .doc(workoutDay.id);
  } else {
    analytics.logEvent(ANALYTIC_EVENTS.NEW_PROGRAM);
    workoutdayDocRef = datastore.collection(COLLECTIONS.WORKOUT_DAYS).doc();
  }
  let trainerDocRef;
  if (isEnterpriseWorkout) {
    trainerDocRef = datastore
      .collection(COLLECTIONS.ENTERPRISE)
      .doc(workoutDay.enterpriseId);
  } else {
    if (user.pendingLogin) {
      trainerDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
    } else {
      trainerDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
    }
  }

  return new Promise(async (resolve, reject) => {
    datastore
      .runTransaction((transaction) => {
        return transaction.get(trainerDocRef).then(async (trainerDoc) => {
          if (!trainerDoc.exists) {
            if (isEnterpriseWorkout) {
              throw new Error("Enterprise data does not exist");
            } else {
              throw new Error("Trainer data does not exist");
            }
          }
          let op = workoutDay.id
            ? transaction.update.bind(transaction)
            : transaction.set.bind(transaction);
          if (workoutDay.id) {
            delete workoutDay.createdTime;
          }

          let selectedClient = JSON.parse(localStorage.getItem("selectedUser"));

          let workoutToSave = Object.assign({}, workoutDay, {
            id: workoutdayDocRef.id,
          });

          const workoutSaveTask = op(workoutdayDocRef, workoutToSave);

          let workoutDayCount = trainerDoc.data().workoutdayCount || 0;
          if (!workoutDay.id) {
            workoutDayCount++;
          }

          let trainerUpdateTask = transaction.update(trainerDocRef, {
            workoutdayCount: workoutDayCount,
          });

          await Promise.all([workoutSaveTask, trainerUpdateTask]);

          return Promise.resolve(workoutSaveTask);
        });
      })
      .then((results) => {
        results = workoutDay;
        results.id = workoutdayDocRef.id;

        resolve(results);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

async function deleteProgram(programId, trainerId) {
  let programDocRef = datastore
    .collection(COLLECTIONS.WORKOUT_PROGRAM)
    .doc(programId);
  return datastore.runTransaction(async (transaction) => {
    if (trainerId) {
      await updateProgramCount(trainerId, transaction, -1);
    }
    await transaction.delete(programDocRef);
  });
}

async function deleteWorkout(workoutId, type) {
  let user = currentUser();
  let id = user.uid;

  if (!id) {
    throw new Error("User id missing");
  }
  let workoutdayDocRef = "";

  workoutdayDocRef = datastore
    .collection(COLLECTIONS.WORKOUT_DAYS)
    .doc(workoutId);

  let trainerDocRef;
  if (user) {
    if (user.pendingLogin) {
      trainerDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
    } else {
      trainerDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
    }
  }

  return new Promise(async (resolve, reject) => {
    datastore
      .runTransaction((transaction) => {
        return transaction.get(trainerDocRef).then((trainerDoc) => {
          if (!trainerDoc.exists) {
            throw new Error("Trainer data does not exist");
          }
          const workoutDeleteTask = transaction.delete(workoutdayDocRef);
          const newWorkoutdayCount = trainerDoc.data().workoutdayCount
            ? trainerDoc.data().workoutdayCount - 1
            : 0;
          const trainerUpdateTask = transaction.update(trainerDocRef, {
            workoutdayCount: newWorkoutdayCount,
          });

          return Promise.all([workoutDeleteTask, trainerUpdateTask]);
        });
      })
      .then((results) => {
        resolve(results);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

async function getWorkoutSummary(clientId, programId) {
  let summaryId = clientId + "_" + programId;
  const ref = datastore.collection(COLLECTIONS.WORKOUT_SUMMARY).doc(summaryId);

  return new Promise(async (resolve, reject) => {
    try {
      const summary = await ref.get();
      if (!summary.exists) {
        throw new Error("Workout summary does not exist");
      }
      resolve(summary.data());
    } catch (error) {
      reject(error);
    }
  });
}

/*
Query: {
  pageConfig: {
    orders: [Order],
    limit: Number
  },
  collection: string,
  filters: [Filter],
  or: boolean (Is using or, all filters will be applied as different query and count towards quota)
}

Order: {
  key: string,
  direction: string, "desc" or empty for ascending by default
  after: any,
  before: any
}

Filter: {
    "key": string,
    "operator": refer https://firebase.google.com/docs/firestore/query-data/queries,
    "value": any
  }

Note: Filters are applied in order of array
*/
async function queryData(query) {
  if (query.or && query.filters && query.filters.length) {
    let exercises = [];

    for (let filter of query.filters) {
      let subQuery = Object.assign({}, query);
      subQuery.filters = [filter];

      try {
        let results = await _executeQuery(subQuery);
        exercises = exercises.concat(results);
      } catch (e) {
        throw e;
      }
    }

    exercises = window._.uniq(exercises, false, (e) => {
      return e.id;
    });
    return exercises;
  } else {
    return _executeQuery(query);
  }
}

async function replaceMovementFromExercises(oldMovement, newMovement) {
  try {
    let exercises = await getExercisesWithProperty(
      DB_KEYS.MOVEMENT,
      oldMovement.name
    );
    for (let exercise of exercises) {
      await addMovementToExercise(exercise.id, newMovement);
    }
  } catch (e) {
    throw e;
  }
}

async function addMovementToExercise(exId, movement) {
  try {
    let update = {
      movement: movement.name,
      movementId: movement.movementId,
      movementCategory: movement.movementCategory,
    };
    await datastore
      .collection(COLLECTIONS.EXERCISES)
      .doc(exId)
      .set(update, { merge: true });
  } catch (e) {
    throw e;
  }
}

async function _executeQuery(query) {
  const ref = datastore.collection(query.collection);
  let queryRef = ref;

  if (!query) {
    query = {};
  }

  if (query.filters) {
    for (let filter of query.filters) {
      try {
        let _queryRef = queryRef.where(
          filter.key,
          filter.operator,
          filter.value
        );
        queryRef = _queryRef;
      } catch (e) {
        console.error(e);
      }
    }
  }
  if (query.pageConfig) {
    let config = query.pageConfig;
    let beforePresent = false;
    if (config.orders) {
      let afters = [];
      let befores = [];

      for (let order of config.orders) {
        queryRef = queryRef.orderBy(order.key, order.direction);
        if (order.after) {
          afters.push(order.after);
        }
        if (order.before) {
          befores.push(order.before);
        }
      }

      if (afters.length) {
        queryRef = queryRef.startAfter(...afters);
      }
      if (befores.length) {
        queryRef = queryRef.endBefore(...befores);
        beforePresent = true;
      }
    }

    if (config.limit) {
      if (beforePresent) {
        queryRef = queryRef.limitToLast(config.limit);
      } else {
        queryRef = queryRef.limit(config.limit);
      }
    }
  }
  return new Promise(async (resolve, reject) => {
    try {
      let firebaseResults = await queryRef.get();
      let data = [];
      for (let doc of firebaseResults.docs) {
        let datum = doc.data();
        datum.id = doc.id;
        data.push(datum);
      }

      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
}

function currentUser() {
  return firebase.auth().currentUser;
}

async function getHealthCoaches() {
  try {
    const api = "enterprise/healthCoaches";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function checkUserForms(id, email) {
  try {
    const api = "forms/clientForms/create";
    let results = await new Promise((resolve, reject) =>
      sendRequest(
        api,
        "post",
        {
          clientId: id,
          clientEmail: email,
        },
        resolve,
        {
          errorCallback: reject,
          dontShowMessage: true,
        }
      )
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function getAssignedCoaches(data) {
  try {
    const api = "enterprise/assignedCoaches";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function createNewClient(clientProfile) {
  try {
    let results = await createNewClientFN(clientProfile);
    return results;
  } catch (e) {
    throw e;
  }
}

async function removeClient(clientProfile) {
  try {
    let results = await removeClientFN(clientProfile);
    return results;
  } catch (e) {
    throw e;
  }
}

async function sendInvite(email, name) {
  let data = {
    email,
    displayName: name,
  };
  try {
    let results = await sendInviteFN(data);

    return results;
  } catch (e) {
    throw e;
  }
}

async function requestExercise(request) {
  try {
    let results = await requestExerciseFN(request);
    return results;
  } catch (e) {
    throw e;
  }
}

async function storeExercise(exercise) {
  return store(COLLECTIONS.EXERCISES, exercise);
}

/*
Config: {
  data: File data,
  storageRefPath: string,
  onProgress: Function(progress),
  onError: Function(error),
  onComplete: Function(downloadURL)
}
*/

function startUploadFileTask(config) {
  var storageRef = window.firebase.storage().ref();
  var dataRef = storageRef.child(config.storageRefPath);

  let uploadTask = dataRef.put(config.data);

  uploadTask.on(
    "state_changed",
    function (snapshot) {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      config.onProgress(progress);
    },
    function (error) {
      config.onError(error);
    },
    function () {
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        config.onComplete(downloadURL);
      });
    }
  );

  return uploadTask;
}

async function uploadDocument(fileToUpload, pathToStore, onProgress) {
  if (!fileToUpload) {
    return Promise.reject("No file to upload");
  }
  let file = fileToUpload;
  let fileName = fileToUpload.name;

  let ref = storage.ref(pathToStore + fileName);
  try {
    let uploadTask = ref.put(file);
    if (onProgress) {
      uploadTask.on("state_changed", function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress, uploadTask);
      });
    }
    return uploadTask;
  } catch (e) {
    throw e;
  }
}

async function saveProgram(programData) {
  let user = currentUser();
  let id = user.uid;

  if (!id) {
    throw new Error("User id missing");
  }

  programData.creatorId = id;

  programData._workoutDayIds = Object.keys(programData.workoutDataMap || {});

  let programDayRef = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM).doc();
  if (programData.id) {
    programDayRef = datastore
      .collection(COLLECTIONS.WORKOUT_PROGRAM)
      .doc(programData.id);
    programData.updatedTime = window.firebase.firestore.Timestamp.fromDate(
      new Date()
    );
  }

  let isUpdate = programData.id ? true : false;

  programData.id = programDayRef.id;

  return datastore.runTransaction(async (transaction) => {
    await _saveProgram(
      { programData, programDayRef, trainerId: id, isUpdate },
      transaction
    );
  });
}

async function _saveProgram(params, transaction) {
  let programData = params.programData;
  let programDayRef = params.programDayRef;
  // let change = 0;
  // if (!params.isUpdate) {
  //   change++;
  // }
  // await updateProgramCount(trainerId, transaction, change)
  await transaction.set(programDayRef, programData);
  programData.id = programDayRef.id;
}

async function updateProgramCount(trainerId, transaction, change = 0) {
  let trainerRef = datastore.collection(COLLECTIONS.USER_DATA).doc(trainerId);
  let trainerDataDoc = await transaction.get(trainerRef);
  if (!trainerDataDoc.exists) {
    throw new Error("Trainer does not exist");
  }
  let trainerData = trainerDataDoc.data();
  let programCount = trainerData.programCount || 0;
  programCount += change;
  await transaction.update(trainerRef, { programCount });
}

function assingProgramToClients(programId, clients, trainer) {
  let batch = datastore.batch();
  let clientDocRef;
  for (let id of Object.keys(clients)) {
    let state = clients[id];
    if (state.pendingLogin) {
      clientDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
    } else {
      clientDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
    }

    batch.update(clientDocRef, {
      programId: programId,
      programs: [
        {
          id: programId,
        },
      ],
    });
  }
  return batch.commit();
}

function removeProgramfromClients(clients) {
  let batch = datastore.batch();
  let clientDocRef;

  for (let id of Object.keys(clients)) {
    let state = clients[id];
    if (state.pendingLogin) {
      clientDocRef = datastore.collection(COLLECTIONS.INVITES).doc(id);
    } else {
      clientDocRef = datastore.collection(COLLECTIONS.USER_DATA).doc(id);
    }
    batch.update(clientDocRef, {
      programId: "",
      programs: [],
    });
  }
  return batch.commit();
}

async function addWorkoutDayToClientProgram(
  clientData,
  workoutDay,
  dayIndexes = []
) {
  // TODO: remove workout id of previous day if no longer used
  let clientProgramId = clientData.programId;

  if (!clientProgramId) {
    let workoutDaysMap = {};
    for (let dayIndex of dayIndexes) {
      workoutDaysMap[dayIndex] = workoutDay.id;
    }
    let programData = {
      workoutDaysMap,
      workoutDataMap: { [workoutDay.id]: workoutDay },
    };
    await assignProgramToClient(
      clientData,
      programData,
      clientData.pendingLogin
    );
    analytics.logEvent(ANALYTIC_EVENTS.PROGRAM_ASSIGN);
  } else {
    let programUpdate = {
      _workoutDayIds: window.firebase.firestore.FieldValue.arrayUnion(
        workoutDay.id
      ),
      [`workoutDataMap.${workoutDay.id}`]: workoutDay,
    };
    for (let dayIndex of dayIndexes) {
      programUpdate[`workoutDaysMap.${dayIndex}`] = workoutDay.id;
    }

    await datastore
      .collection(COLLECTIONS.WORKOUT_PROGRAM)
      .doc(clientProgramId)
      .update(programUpdate);
  }
}

async function assignProgramToClient(assignTo, programData, pendingLogin) {
  let user;
  try {
    user = await getUserData();
  } catch (e) {
    throw e;
  }
  let id = user.id;
  if (!id) {
    throw new Error("User id missing");
  }
  if (user.enterpriseId) {
    programData.creatorId = user.enterpriseId;
    programData.enterprise = true;
  } else {
    programData.creatorId = id;
    programData.privatePractice = true;
  }
  programData._workoutDayIds = Object.keys(programData.workoutDataMap || {});

  let programDayRef = datastore.collection(COLLECTIONS.WORKOUT_PROGRAM);
  if (assignTo.programId) {
    programDayRef = programDayRef.doc(assignTo.programId);
    programData.updatedTime = window.firebase.firestore.Timestamp.fromDate(
      new Date()
    );
  } else {
    programDayRef = programDayRef.doc();
  }
  let collectionName = pendingLogin
    ? COLLECTIONS.INVITES
    : COLLECTIONS.USER_DATA;

  const clientDocRef = datastore.collection(collectionName).doc(assignTo.id);
  return new Promise(async (resolve, reject) => {
    return datastore
      .runTransaction(async (transaction) => {
        programData.id = programDayRef.id;
        programData.clientId = assignTo.id;

        let params = {
          trainerId: id,
          programData,
          programDayRef,
          isUpdate: assignTo.programId,
        };

        await _saveProgram(params, transaction);

        let programId = programDayRef.id;
        return transaction.update(clientDocRef, {
          programId: programId,
          programs: [
            {
              id: programId,
            },
          ],
          programStartDate: null,
        });
      })
      .then((results) => {
        resolve(results);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

async function bulkUploadExercises(exercises) {
  for (let exercise of exercises) {
    exercise.createdTime = window.firebase.firestore.Timestamp.fromDate(
      new Date()
    );
    exercise.updatedTime = window.firebase.firestore.Timestamp.fromDate(
      new Date()
    );
  }
  return bulkUpload(COLLECTIONS.EXERCISES, exercises);
}

async function bulkUploadMovementCategories(data) {
  return bulkUpload(COLLECTIONS.MOVEMENT_CATEGORIES, data);
}

async function bulkUploadMovements(data) {
  return bulkUpload(COLLECTIONS.MOVEMENTS, data);
}

async function bulkUploadEquipments(data) {
  return bulkUpload(COLLECTIONS.EQUIPMENTS, data);
}

async function bulkUploadMuscles(data) {
  return bulkUpload(COLLECTIONS.MUSCLES, data);
}

async function bulkUploadMuscleGroups(data) {
  return bulkUpload(COLLECTIONS.MUSCLE_GROUPS, data);
}

async function bulkUploadFunctions(data) {
  return bulkUpload(COLLECTIONS.FUNCTIONS, data);
}

async function bulkUploadFunctionCategories(data) {
  return bulkUpload(COLLECTIONS.FUNCTION_CATEGORIES, data);
}

async function bulkUpload(collection, data) {
  return new Promise(async (resolve, reject) => {
    datastore
      .runTransaction((transaction) => {
        let tasks = [];

        for (let datum of data) {
          // eslint-disable-next-line no-loop-func
          let task = new Promise(async (resolve, reject) => {
            try {
              delete datum.createdTime;
              delete datum.updatedTime;
              let id = datum.id;
              let ref = datastore.collection(collection).doc(id);

              const doc = await ref.get();
              if (!doc.exists) {
                datum.createdTime =
                  window.firebase.firestore.Timestamp.fromDate(new Date());
                await transaction.set(ref, datum);
              } else {
                datum.updatedTime =
                  window.firebase.firestore.Timestamp.fromDate(new Date());
                await transaction.set(ref, datum, { merge: true });
              }

              datum.id = ref.id;
              resolve(datum);
            } catch (e) {
              reject(e);
            }
          });

          tasks.push(task);
        }

        return Promise.all(tasks);
      })
      .then((results) => {
        window.FortisForma.Cache.clearCache(collection);
        resolve(results);
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

async function storeMovementCategory(data) {
  return store(COLLECTIONS.MOVEMENT_CATEGORIES, data);
}

async function storeMovement(data) {
  return store(COLLECTIONS.MOVEMENTS, data);
}

async function storeEquipment(data) {
  return store(COLLECTIONS.EQUIPMENTS, data);
}

async function storeMuscle(data) {
  return store(COLLECTIONS.MUSCLES, data);
}

async function storeBlogs(data) {
  return store(COLLECTIONS.BLOGS, data);
}

async function storeMuscleGroups(data) {
  return store(COLLECTIONS.MUSCLE_GROUPS, data);
}

async function storeFunction(data) {
  return store(COLLECTIONS.FUNCTIONS, data);
}

async function storeFunctionCategory(data) {
  return store(COLLECTIONS.FUNCTION_CATEGORIES, data);
}

function storeFeedback(data) {
  return store(COLLECTIONS.FEEDBACK, data);
}

function trimObject(row) {
  if (typeof row === "string") {
    return row.trim();
  }
  if (typeof row !== "object") {
    return row;
  }
  for (let key of Object.keys(row)) {
    let value = row[key];
    if (value && typeof value === "string") {
      row[key] = value.trim();
    }
    if (value && typeof value === "object") {
      row[key] = trimObject(value);
    }
  }

  return row;
}

async function store(collection, data) {
  data = trimObject(data);
  let ref;
  let update = true;

  if (!data.createdTime) {
    update = false;
  }

  if (data.id) {
    ref = datastore.collection(collection).doc(data.id);
  } else {
    let createdTime = data.createdTime;
    delete data.createdTime;
    let id = hash(data);
    data.createdTime = createdTime;
    ref = datastore.collection(collection).doc(id);
  }

  if (!data.createdTime) {
    data.createdTime = window.firebase.firestore.Timestamp.fromDate(new Date());
  }
  data.updatedTime = window.firebase.firestore.Timestamp.fromDate(new Date());

  try {
    data.id = ref.id;
    let fn = update ? ref.update : ref.set;
    let results = await fn.call(ref, data);
    results = data;
    results.id = ref.id;
    if (update) {
      window.FortisForma.Cache.updateDoc(collection, data.id, data);
    } else {
      window.FortisForma.Cache.clearCache(collection);
    }
    return results;
  } catch (e) {
    throw e;
  }
}

async function deleteDocument(collection, doc) {
  datastore
    .collection(collection)
    .doc(doc)
    .delete()
    .then(function () {
      window.FortisForma.Cache.removeDoc(collection, doc);
    })
    .catch(function (error) {
      console.error("Error removing document: ", error);
    });
}

var token;

async function getUserToken(forceFetch) {
  if (!forceFetch) {
    if (token) {
      return token;
    } else {
      token = window.localStorage.getItem(DB_KEYS.USER_TOKEN);
      if (token) {
        return token;
      }
    }
  }

  let user = await currentUserPromise();
  if (user) {
    let t = await user.getIdToken(forceFetch || false);
    if (token !== t) {
    }
    token = t;
    window.localStorage.setItem(DB_KEYS.USER_TOKEN, token);
    // if (localStorage.getItem("notiToken") !== null) {
    //   const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
    //   let notificationToken = localStorage.getItem("notiToken");
    //   try {
    //     ref.set({ notificationToken }, { merge: true });
    //   } catch (e) {
    //     throw e;
    //   }
    // } else {
    //   if (user.notificationToken !== null) {
    //     localStorage.setItem("notiToken", user.notificationToken);
    //   } else {
    //     const Ntoken = await messaging.getToken({
    //       vapidKey: publicKey,
    //     });
    //     const ref = datastore.collection(COLLECTIONS.USER_DATA).doc(user.uid);
    //     let notificationToken = Ntoken;
    //     try {
    //       ref.set({ notificationToken }, { merge: true });
    //     } catch (e) {
    //       throw e;
    //     }
    //     localStorage.setItem("notiToken", Ntoken);
    //   }
    // }
    return token;
  } else {
    throw new Error("No user is logged in");
  }
}

function currentUserPromise() {
  let user = currentUser();
  if (user) {
    return Promise.resolve(user);
  }

  return new Promise((resolve, reject) => {
    let unsubscribe = window.firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      if (user) {
        resolve(user);
      } else {
        reject(null);
      }
    });
  });
}

function getPendingApprovals(filters = [], pageConfig = { limit: 20 }) {
  let query = {};
  query.collection = COLLECTIONS.USER_DATA;
  query.filters = filters.concat([
    {
      key: DB_KEYS.USER_STATE,
      operator: "==",
      value: USER_STATES.PENDING,
    },
    {
      key: DB_KEYS.ROLE,
      operator: "==",
      value: ROLES.TRAINER,
    },
  ]);

  query.pageConfig = pageConfig;

  return queryData(query);
}

function getTrainersList(filters = [], pageConfig = { limit: 20 }) {
  let query = {};
  query.collection = COLLECTIONS.USER_DATA;
  query.filters = filters.concat([
    {
      key: DB_KEYS.ROLE,
      operator: "==",
      value: ROLES.TRAINER,
    },
  ]);

  query.pageConfig = pageConfig;

  return queryData(query);
}

function getPublicTrainersList(filters = [], pageConfig = { limit: 20 }) {
  let query = {};
  query.collection = COLLECTIONS.TRAINERS;
  query.filters = filters;
  query.filters = filters.concat([]);
  query.pageConfig = pageConfig;
  return queryData(query);
}

function showTrainer(trainerId, isPublic = false) {
  if (!trainerId) {
    throw new Error("Trainer id invalid");
  }

  return showTrainerFN({ trainerId, isPublic });
}

function hideTrainer(trainerId, isPublic = false) {
  if (!trainerId) {
    throw new Error("Trainer id invalid");
  }

  return hideTrainerFN({ trainerId, isPublic });
}

function approveSignupRequest(trainerId) {
  if (!trainerId) {
    throw new Error("Trainer id invalid");
  }

  return approveTrainerRequestFN({ trainerId });
}

function declineSignupRequest(trainerId) {
  if (!trainerId) {
    throw new Error("Client id invalid");
  }

  return declineTrainerRequestFN({ trainerId });
}

function verifyPasswordResetCode(code) {
  return auth.verifyPasswordResetCode(code);
}

function confirmPasswordReset(code, newPassword) {
  return auth.confirmPasswordReset(code, newPassword);
}

function sendEmailVerification(actionCodeSettings) {
  if (!auth.currentUser) {
    return Promise.resolve();
  }
  if (actionCodeSettings && actionCodeSettings.url) {
    actionCodeSettings.url += `?uid=${auth.currentUser.uid}`;
  }
  return auth.currentUser.sendEmailVerification(actionCodeSettings);
}

function checkActionCode(code) {
  return auth.checkActionCode(code);
}

function applyActionCode(code) {
  return auth.applyActionCode(code);
}

function emailVerified() {
  return auth.currentUser && auth.currentUser.emailVerified;
}

function clientEmailVerification(userData) {
  return clientEmailVerificationFN(userData);
}

function approveClientRequest(request) {
  try {
    return approveClientRequestFN(request);
  } catch (e) {
    throw e;
  }
}

function rejectClientRequest(request) {
  try {
    return rejectClientRequestFN(request);
  } catch (e) {
    throw e;
  }
}

async function initializeWalkthrough() {
  let walkthrough = INITIALIZE_WALKTHROUGH;
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  let id = user.uid;
  try {
    await datastore
      .collection(COLLECTIONS.USER_DATA)
      .doc(id)
      .update({ walkthrough });
  } catch (e) {
    throw e;
  }
}

async function updateWalkthroughStatus(walkthroughKey) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  let id = user.uid;
  let update = {
    [`${DB_KEYS.WALKTHROUGH}.${walkthroughKey}`]: false,
  };
  try {
    await datastore.collection(COLLECTIONS.USER_DATA).doc(id).update(update);
  } catch (e) {
    throw e;
  }
}

async function sendMessage(message) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  try {
    return sendMessageFN(message);
  } catch (e) {
    throw e;
  }
}

async function onTrainerVerify(uid) {
  try {
    await notifyTrainerVerification({ uid });
  } catch (e) {
    console.error(e);
  }
}

async function updatePublicTrainer(id, update) {
  try {
    await datastore.collection(COLLECTIONS.TRAINERS).doc(id).update(update);
  } catch (e) {
    throw e;
  }
}

async function loadMoreLevelsOfExercise(exercise) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  //TODO: replace this call with elastic search
  return new Promise(async (resolve, reject) => {
    try {
      let filters = {
        movement: exercise.movement,
        equipmentCategories: exercise.equipmentCategories,
      };
      let query = await createQuery(filters, user.uid);

      let results = await new Promise((resolve, reject) =>
        sendRequest("exercises", "post", query, resolve, {
          errorCallback: reject,
        })
      );
      let exercises = results.map((result) => {
        return result._source;
      });
      resolve(exercises);
    } catch (e) {
      reject(e);
    }
  });
  // return new Promise(async (resolve, reject) => {
  //   try {
  //     let query = datastore
  //       .collection(COLLECTIONS.EXERCISES)
  //       .where("movement", "==", exercise.movement)
  //       .where(
  //         "equipmentCategories.Primary",
  //         "==",
  //         exercise.equipmentCategories.Primary || "Bodyweight"
  //       )
  //       .where(DB_KEYS.TRAINER_ID_KEY, "in", ["admin", user.uid]);

  //     let results = await query.get();
  //     resolve(results.docs);
  //   } catch (e) {
  //     reject(e);
  //   }
  // });
}

async function createQuery(filters, trainerId) {
  let userData;
  try {
    userData = await getUserData();
  } catch (e) {
    throw e;
  }
  let query = bodybuilder();
  if (filters.equipmentCategories) {
    let primaryEquipment = filters.equipmentCategories.Primary || "Bodyweight";
    query.andQuery("term", "equipmentCategories.Primary", primaryEquipment);
  }
  if (filters.movement) {
    query.filter("term", "movement", filters.movement);
  }
  if (userData && userData.enterpriseId) {
    query.andFilter("bool", (q) =>
      q
        .orFilter("term", DB_KEYS.ENTERPRISE_ID, userData.enterpriseId)
        .orFilter("term", "trainerId", "admin")
        .orFilter("term", "trainerId", userData.id)
    );
  } else {
    query.andFilter("bool", (q) =>
      q
        .orFilter("term", "trainerId", trainerId)
        .orFilter("term", "trainerId", "admin")
    );
  }
  query = query.build();
  let newQuery = convertToScoreQuery(query, filters);
  newQuery.size = 100;
  newQuery.from = 0;
  return newQuery;
}

function convertToScoreQuery(query, filters) {
  let functions = [];
  functions.push({
    filter: {
      match: {
        "equipmentCategories.Secondary":
          filters.equipmentCategories.Secondary || "",
      },
    },
    weight: 1.5,
  });

  functions.push({
    filter: {
      match: {
        "equipmentCategories.Tertiary":
          filters.equipmentCategories.Tertiary || "",
      },
    },
    weight: 1,
  });

  let functionScore = {
    function_score: {
      ...query,
      boost: 1,
      functions: functions,
      score_mode: "multiply",
      boost_mode: "sum",
    },
  };
  return {
    query: functionScore,
  };
}

const getProgramFromHistory = (subcollectionId, programId) => {
  let ref = datastore.collection(COLLECTIONS.PROGRAM_HISTORY).doc(programId);
  return ref
    .collection(subcollectionId)
    .doc(programId)
    .get()
    .then((snapshot) => {
      return snapshot.data();
    })
    .catch((err) => {
      throw err;
    });
};

async function getTrainerData(id) {
  try {
    let data = await datastore.collection(COLLECTIONS.USER_DATA).doc(id).get();
    return data.data();
  } catch (e) {
    throw e;
  }
}

async function getPublicTrainerData(id) {
  try {
    let trainers = await datastore
      .collection(COLLECTIONS.TRAINERS)
      .where(DB_KEYS.USER_ID, "==", id)
      .get();
    trainers = trainers.docs;
    if (!trainers[0]) {
      return null;
    }
    return trainers[0].data();
  } catch (e) {
    throw e;
  }
}

async function saveOnBoardingDetails(details) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  details.id = user.uid;
  details.userId = user.uid;
  try {
    let ref = datastore.collection(COLLECTIONS.TRAINERS).doc(user.uid);
    let results = await ref.set(details, { merge: true });
    return results;
  } catch (e) {
    throw e;
  }
}

async function updateIncompleteStatus(flagValue) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  try {
    return datastore.runTransaction(async (transaction) => {
      await updateIncompleteFlag(COLLECTIONS.USER_DATA, flagValue, transaction);
      await updateIncompleteFlag(COLLECTIONS.TRAINERS, flagValue, transaction);
    });
  } catch (e) {
    throw e;
  }
}

async function updateIncompleteFlag(collectionName, flagValue, transaction) {
  let user = currentUser();
  let ref = datastore.collection(collectionName).doc(user.uid);
  return transaction.update(ref, { incomplete: flagValue });
}

async function deleteFileFromStroage(refPath) {
  var fileRef = storageRef.child(refPath);
  try {
    await fileRef.delete();
  } catch (e) {
    throw e;
  }
}

async function getDownloadURL(path) {
  var pathReference = storage.ref(path);
  try {
    let url = pathReference.getDownloadURL();
    return url;
  } catch (e) {
    throw e;
  }
}

async function getRandomDocId(collectionName) {
  try {
    let ref = datastore.collection(collectionName).doc();
    return ref.id;
  } catch (e) {
    console.error(e);
  }
}

async function createEnterprise(enterpriseData) {
  try {
    const api = "enterprise";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", enterpriseData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function getEnterprises() {
  return new Promise(async (resolve, reject) => {
    try {
      let enterpriseRef = datastore.collection(COLLECTIONS.ENTERPRISE);
      let firebaseResults = await enterpriseRef.get();
      let data = [];
      for (let doc of firebaseResults.docs) {
        let datum = doc.data();
        datum.id = doc.id;
        data.push(datum);
      }
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
}

async function fetchEnterpriseInvite(enterpriseInviteId) {
  let data = {
    id: enterpriseInviteId,
  };
  try {
    let results = await fetchEnterpriseInviteFN(data);
    return results;
  } catch (e) {
    throw e;
  }
}

async function createEnterpriseAdmin(data) {
  try {
    await this.createUserWithEmailAndPassword(data.email, data.password);
    delete data.password;
    let results = await confirmEnterpriseInviteFN(data);
    return results;
  } catch (e) {
    throw e;
  }
}

async function updateEnterprise(data, enterpriseId) {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }
  try {
    let ref = datastore.collection(COLLECTIONS.ENTERPRISE).doc(enterpriseId);
    let results = await ref.set(data, { merge: true });
    return results;
  } catch (e) {
    throw e;
  }
}

async function createStaffInvite(memberData) {
  try {
    const api = "enterprise/staffInvite";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", memberData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function resendStaffInvite(memberData) {
  try {
    const api = "enterprise/resendStaffInvite";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", memberData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function removeEnterpriseStaffMember(data) {
  try {
    const api = "enterprise/staffMembers";
    let result = await new Promise((resolve, reject) =>
      sendRequest(api, "delete", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return result;
  } catch (e) {
    throw e;
  }
}

async function fetchEnterpriseForms(enterpriseId, isAdmin, role, userId) {
  if (!enterpriseId && !userId && !isAdmin) {
    // if (!enterpriseId && !isAdmin) {
    return;
  }
  try {
    let forms;
    if (isAdmin) {
      forms = datastore
        .collection(COLLECTIONS.FORMS)
        .where(DB_KEYS.GLOBAL, "==", true)
        .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true);
    } else {
      if (role) {
        forms = datastore
          .collection(COLLECTIONS.FORMS)
          .where(DB_KEYS.GLOBAL, "==", true)
          .where(DB_KEYS.ENTERPRISE_FORM, "==", false)
          .where(DB_KEYS.ACTIVE_STATUS, "==", true);
        // .where(DB_KEYS.ACTIVE_STATUS, "==", true);
        // .where(DB_KEYS.USER_ID, "==", enterpriseId)
        // .where(DB_KEYS.ENTERPRISE_FORM, "==", false)
        // .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        // .where(DB_KEYS.OLD, "==", false);
      } else {
        if (enterpriseId) {
          forms = datastore
            .collection(COLLECTIONS.FORMS)
            .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
            .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
            .where(DB_KEYS.ACTIVE_STATUS, "==", true)
            .where(DB_KEYS.OLD, "==", false);
        }
      }
    }
    let formsSnapshot;
    if (forms) {
      formsSnapshot = await forms.get();
    }

    let response;
    if ((formsSnapshot && formsSnapshot.empty) || !formsSnapshot) {
      response = [];
    } else {
      let docs = formsSnapshot.docs;
      if (docs.length > 0) {
        response = docs.map((doc) => {
          let data = doc.data();
          data.id = doc.id;
          return data;
        });
      }
    }

    if (userId) {
      const prevForms = await fetchUserForms(userId);

      const finalRes = [...prevForms, ...response];

      response = finalRes;
      return response;
    } else {
      return response;
    }
  } catch (e) {
    throw e;
  }
}

async function addSeqNoInDocs(forms, seqNo) {
  const collectionRef = datastore.collection(COLLECTIONS.FORMS);
  const batch = datastore.batch();
  const docsToUpdate = forms;
  let num = seqNo;
  docsToUpdate.forEach((docId) => {
    const docRef = collectionRef.doc(docId);
    batch.update(docRef, { sequence: num });
    num++;
  });

  return batch.commit();
}

async function fetchGlobalForms() {
  try {
    const api = "forms/globalForms";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function saveForms(formData) {
  try {
    const api = "forms";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", formData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function deleteGlobalForm(formData) {
  try {
    const api = "forms";
    let results = await new Promise((resolve, reject) => {
      sendRequest(api, "delete", formData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      });
    });
    return results;
  } catch (error) {
    throw error;
  }
}

async function removeForm(formId) {
  let ref = datastore.collection(COLLECTIONS.FORMS).doc(formId);
  try {
    await ref.delete();
  } catch (e) {
    throw e;
  }
}

async function assignCoach(data) {
  try {
    const api = "enterprise/assignCoach";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function removeCoach(data) {
  try {
    const api = "enterprise/healthCoaches";
    let result = await new Promise((resolve, reject) =>
      sendRequest(api, "delete", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return result;
  } catch (e) {
    throw e;
  }
}

async function swapSequence(data) {
  try {
    var firstFormRef = datastore
      .collection(COLLECTIONS.FORMS)
      .doc(data.firstFormId);
    var secondFormRef = datastore
      .collection(COLLECTIONS.FORMS)
      .doc(data.secondFormId);
    return datastore.runTransaction(async (transaction) => {
      await transaction.update(firstFormRef, {
        sequence: data.secondFormSequence,
      });
      await transaction.update(secondFormRef, {
        sequence: data.firstFormSequence,
      });
    });
  } catch (error) {
    throw error;
  }
}

async function fetchFormsByFormId(formId) {
  if (!formId) {
    return;
  }
  let ref = datastore.collection(COLLECTIONS.FORMS).doc(formId);
  try {
    let results = await ref.get();
    let data = results.data();
    if (data.active) {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

async function saveFormSubmissionData(data) {
  try {
    const api = "forms/submissions";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function updateData(data, collection, id) {
  if (!id) {
    return;
  }
  try {
    let ref = datastore.collection(collection).doc(id);
    let results = await ref.set(data, { merge: true });
    return results;
  } catch (e) {
    throw e;
  }
}

async function fetchFormData(submissionId) {
  if (!submissionId) {
    return;
  }
  let ref = datastore.collection(COLLECTIONS.SUBMISSIONS).doc(submissionId);
  try {
    let results = await ref.get();
    return results.data();
  } catch (e) {
    throw e;
  }
}

async function fetchClientForms(data) {
  try {
    const api = "forms/clientForms";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results.forms;
  } catch (e) {
    throw e;
  }
}

async function updateClientFormsData(data) {
  try {
    const api = "forms/clientForms";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "put", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function deleteClientForms(data) {
  try {
    const api = "forms/clientForms";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "delete", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    if (!results) {
      throw new Error("Unable to delete form");
    }
    return results;
  } catch (e) {
    throw e;
  }
}

async function fetchNotes(data) {
  try {
    let results = await new Promise((resolve, reject) =>
      sendRequest("notes", "get", data, resolve, {
        errorCallback: reject,
      })
    );
    return results.data;
  } catch (e) {
    throw e;
  }
}

async function saveNoteData(noteData) {
  try {
    let results = await new Promise((resolve, reject) =>
      sendRequest("notes", "post", noteData, resolve, {
        errorCallback: reject,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function deleteNote(noteId) {
  try {
    await new Promise((resolve, reject) =>
      sendRequest("notes", "delete", { noteId }, resolve, {
        errorCallback: reject,
      })
    );
  } catch (e) {
    throw e;
  }
}

function uploadNoteAttachments(fileToUpload, onProgress) {
  return uploadAttachments(fileToUpload, "noteAttachments/", onProgress);
}

async function uploadAttachments(fileToUpload, pathToStore, onProgress) {
  if (!fileToUpload) {
    return Promise.reject("No file to upload");
  }
  let file = fileToUpload;
  let fileName = fileToUpload.name;
  let ref = storage.ref(pathToStore + moment().unix() + fileName);
  try {
    let uploadTask = ref.put(file);
    if (onProgress) {
      uploadTask.on("state_changed", function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress, uploadTask);
      });
    }
    return uploadTask;
  } catch (e) {
    throw e;
  }
}

// async function uploadBase64Attachmen

async function saveAssessment(assessmentData) {
  try {
    let results = await new Promise((resolve, reject) =>
      sendRequest("assessments", "post", assessmentData, resolve, {
        errorCallback: reject,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function fetchEnterpriseClients(data) {
  try {
    const api = "enterpriseClients";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", data, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    if (results.length) {
      results = results.map((item) => {
        item._source._score = item._score;
        item._source.sort = item.sort;
        return item._source;
      });
    }

    return results;
  } catch (e) {
    throw e;
  }
}

async function fetchEnterpriseDetailsForStaff(enterpriseId) {
  const db = firebase.firestore();
  const userDoc = db.collection("enterprises").doc(enterpriseId);

  try {
    const userSnapshot = await userDoc.get();
    if (userSnapshot.exists) {
      // User exists, extract user data
      const userData = userSnapshot.data();
      const query = db
        .collection("users")
        .where("email", "==", userData.admin.email);

      try {
        const querySnapshot = await query.get();
        if (!querySnapshot.empty) {
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          // if (data.length) {
          //     let object={
          //         admin:{
          //             email:data[0].email,
          //             name:data[0].name,
          //         },
          //         id:data[0].id,
          //         name:data[0].name,
          //     }
          //     return object;
          // } else {
          // return null;

          return data[0];
          // }
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
      return null;
    } else {
      // User does not exist

      return null;
    }
  } catch (error) {
    return null;
  }
}

async function fetchEnterpriseInPPFree() {
  try {
    const api = `enterprise/details`;
    return await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
      })
    );
  } catch (e) {
    throw e;
  }
}

async function fetchclientCountHC() {
  try {
    const api = `enterprise/clientCountHC`;
    return await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
      })
    );
  } catch (e) {
    throw e;
  }
}

async function fetchAssessments(data) {
  try {
    let results = await new Promise((resolve, reject) =>
      sendRequest("assessments", "get", data, resolve, {
        errorCallback: reject,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function deleteAssessment(assessmentId) {
  try {
    await new Promise((resolve, reject) =>
      sendRequest("assessments", "delete", { assessmentId }, resolve, {
        errorCallback: reject,
      })
    );
  } catch (e) {
    throw e;
  }
}

async function checkIfEmailPresent(email) {
  try {
    let results = await checkIfEmailPresentFN(email);
    return results.data;
  } catch (e) {
    throw e;
  }
}

async function bulkClientsUpload(data) {
  for (let singleData of data) {
    await createNewClient(singleData);
  }
}

async function fetchDocumentsByArrayOfIds(Ids, collection) {
  if (!Ids.length) {
    return [];
  }
  try {
    let ref = datastore
      .collection(collection)
      .where(window.firebase.firestore.FieldPath.documentId(), "in", Ids);
    let data = await ref.get();
    if (data.empty) {
      return [];
    }
    let docs = data.docs;
    docs = docs.map((doc) => {
      let data = doc.data();
      return data;
    });
    return docs;
  } catch (error) {
    throw error;
  }
}

async function getPDF(templateId, data, documentName, emailOnly = false) {
  let pdfData = {};
  pdfData.header = {
    isHtml: false,
  };
  pdfData.templateId = templateId;

  if (data.enterpriseData !== null) {
    const { enterpriseData } = data;
    pdfData.header = enterpriseData;
  }

  pdfData.main = {};
  pdfData.main.filter = data.filter;
  pdfData.main.clientData = data.clientData;
  pdfData.main.mainData = data.mainData;
  pdfData.main.isHtml = false;
  pdfData.footer = {
    image: "https://fortisforma.com/assets/text-logo.png",
    isHtml: false,
  };
  const authToken = await getUserToken();
  const fetchOptions = {
    body: JSON.stringify(pdfData),
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };

  const apiURL = `${process.env.REACT_APP_SERVER_ADDRESS}generate/pdf`;
  return fetch(apiURL, fetchOptions)
    .then((res) => {
      if (!res.ok || !res.status === 200) {
        throw new Error("Something went wrong");
      } else {
        return res
          .arrayBuffer()
          .then((res) => {
            const blob = new Blob([res], { type: "application/pdf" });
            if (emailOnly) {
              return blob;
            }
            saveAs(blob, `${documentName}.pdf`);
            return { saved: true };
          })
          .catch((e) => {
            throw e;
          });
      }
    })
    .catch((e) => {
      throw e;
    });
}

const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      key !== "equipmentCategories" &&
      key !== "alternateNames" &&
      key !== "equipmentTypes" &&
      key !== "muscleGroups" &&
      key !== "functionCategories"
    ) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

async function exportExercises(movementCategory) {
  try {
    let exercisesArray = [];

    const exercises = await datastore.collection(COLLECTIONS.EXERCISES);

    const exercisesSnapshot = await exercises
      .where("movementCategory", "==", movementCategory)
      .where("trainerId", "==", "admin")
      .get();

    if (exercisesSnapshot.empty) {
      console.log("No matching documents.");
    } else {
      exercisesSnapshot.forEach((doc) => {
        const flattenDocumentObject = flattenObject(doc.data());

        const transformedObject = {
          id: flattenDocumentObject["id"],
          name: flattenDocumentObject["name"],
          reps: flattenDocumentObject["reps"],
          duration: flattenDocumentObject["duration"],
          resistance: flattenDocumentObject["resistance"],
          level: flattenDocumentObject["level"],
          groupId: flattenDocumentObject["groupId"],
          weight: flattenDocumentObject["weight"],
          description: flattenDocumentObject["description"],
          alternateNames:
            flattenDocumentObject["alternateNames"] &&
            flattenDocumentObject["alternateNames"].toString(),
          movementCategory: flattenDocumentObject["movementCategory"],
          movement: flattenDocumentObject["movement"],
          primaryFunction:
            flattenDocumentObject["functionCategories"]["Primary"],
          secondaryFunction:
            flattenDocumentObject["functionCategories"]["Secondary"],
          tertiaryFunction:
            flattenDocumentObject["functionCategories"]["Tertiary"],
          primaryMuscle: flattenDocumentObject["muscleGroups"]["Primary"],
          secondaryMuscle: flattenDocumentObject["muscleGroups"]["Secondary"],
          tertiaryMuscle: flattenDocumentObject["muscleGroups"]["Tertiary"],
          quinaryMuscle: flattenDocumentObject["muscleGroups"]["Quinary"],
          quarterlyMuscle: flattenDocumentObject["muscleGroups"]["Quarterly"],
          primaryEquipment:
            flattenDocumentObject["equipmentCategories"]["Primary"],
          secondaryEquipment:
            flattenDocumentObject["equipmentCategories"]["Secondary"],
          tertiaryEquipment:
            flattenDocumentObject["equipmentCategories"]["Tertiary"],
        };
        delete transformedObject.equipmentCategories;
        delete transformedObject.muscleGroups;
        delete transformedObject.functionCategories;
        delete transformedObject.equipmentTypes;

        exercisesArray.push(transformedObject);
      });
      const xlsxData = XLSX.utils.json_to_sheet(exercisesArray);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, xlsxData);

      const fileName = movementCategory.replace(/\s/g, "");
      XLSX.writeFile(workBook, `${fileName}.xlsx`);
    }
  } catch (e) {
    throw e;
  }
}

async function sendOtp() {
  try {
    const result = await sendOtpFN();
    return result;
  } catch (e) {
    throw e;
  }
}

async function verifyOtp(token) {
  try {
    const result = await verifyOtpFN(token);

    if (result) {
      return result.data;
    }
  } catch (e) {
    throw e;
  }
}

async function changeWorkoutStartDate(clientId, programId, pendingLogin) {
  if (!programId) {
    return false;
  }

  try {
    const summaryRef = datastore
      .collection(COLLECTIONS.WORKOUT_SUMMARY)
      .doc(clientId + "_" + programId);
    let update = {};
    let timestamp = moment().startOf("day").unix();
    update[DB_KEYS.START_DATES] =
      firebase.firestore.FieldValue.arrayUnion(timestamp);
    let userRef;
    if (pendingLogin) {
      userRef = datastore.collection(COLLECTIONS.INVITES).doc(clientId);
    } else {
      userRef = datastore.collection(COLLECTIONS.USER_DATA).doc(clientId);
    }
    return new Promise((resolve, reject) => {
      datastore
        .runTransaction((transaction) => {
          let tasks = [];
          tasks.push(
            transaction.update(userRef, { programStartDate: timestamp })
          );
          tasks.push(transaction.update(summaryRef, update));
          return Promise.all(tasks);
        })
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  } catch (e) {
    throw e;
  }
}

async function addWorkoutLog(params, setStartDate, pendingLogin) {
  const clientId = params.clientId;
  const programId = params.programId;
  const logDate = params.logDate;
  const log = params.log;
  const sectionIndex = params.sectionIndex;
  const exerciseIndex = params.exerciseIndex;
  const workoutId = params.workoutId;

  if (
    !programId ||
    !log ||
    !logDate ||
    sectionIndex === undefined ||
    exerciseIndex === undefined ||
    !workoutId ||
    !clientId
  ) {
    throw new Error(`Invalid params`);
  }

  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);

  let key = `logs.${logDate}.${workoutId}.${sectionIndex}.${exerciseIndex}`;
  let update = {};
  update[key] = log;
  if (setStartDate) {
    await changeWorkoutStartDate(clientId, programId, pendingLogin);
  }

  await summaryRef.update(update);
  return log;
}

async function removeWorkoutLog(params) {
  const clientId = params.clientId;
  const programId = params.programId;
  const logDate = params.logDate;
  const sectionIndex = params.sectionIndex;
  const exerciseIndex = params.exerciseIndex;
  const workoutId = params.workoutId;
  if (
    !programId ||
    !logDate ||
    sectionIndex === undefined ||
    exerciseIndex === undefined ||
    !workoutId ||
    !clientId
  ) {
    throw new Error(`Invalid params`);
  }
  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);
  let key = `logs.${logDate}.${workoutId}.${sectionIndex}.${exerciseIndex}`;
  let update = {};
  update[key] = firebase.firestore.FieldValue.delete();
  return summaryRef.update(update);
}

async function addWorkoutSectionLog(
  logData,
  setStartDate,
  clientId,
  pendingLogin
) {
  if (!logData[0]) {
    return false;
  }
  const programId = logData[0].programId;
  const logDate = logData[0].logDate;
  const sectionIndex = logData[0].sectionIndex;
  const workoutId = logData[0].workoutId;
  let log = {};
  for (let exerciseLog of logData) {
    log[exerciseLog.exerciseIndex] = exerciseLog.log;
    if (exerciseLog.feedback) {
      log[exerciseLog.exerciseIndex].feedback = exerciseLog.feedback;
    }
  }

  if (
    !programId ||
    !log ||
    !logDate ||
    sectionIndex === undefined ||
    !workoutId ||
    !clientId
  ) {
    throw new Error(`Invalid params`);
  }
  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);
  let key = `logs.${logDate}.${workoutId}.${sectionIndex}`;
  let update = {};
  update[key] = log;
  if (setStartDate) {
    await changeWorkoutStartDate(clientId, programId, pendingLogin);
  }
  try {
    await summaryRef.update(update);
  } catch (e) {
    throw e;
  }
  return log;
}

async function removeWorkoutSectionLog(logData, clientId) {
  if (!logData[0]) {
    return false;
  }
  const programId = logData[0].programId;
  const logDate = logData[0].logDate;
  const sectionIndex = logData[0].sectionIndex;
  const workoutId = logData[0].workoutId;
  if (
    !programId ||
    !logDate ||
    sectionIndex === undefined ||
    !workoutId ||
    !clientId
  ) {
    throw new Error(`Invalid params`);
  }
  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);
  let key = `logs.${logDate}.${workoutId}.${sectionIndex}`;
  let update = {};
  update[key] = firebase.firestore.FieldValue.delete();
  try {
    await summaryRef.update(update);
  } catch (e) {
    throw e;
  }
  return true;
}

async function updateFeedbackDate(summaryRef) {
  const todayUnix = moment().startOf("day").unix();
  return summaryRef.update({ feedbackDate: todayUnix });
}

async function saveWorkoutLogMeta(data, updateDate) {
  const clientId = data.clientId;
  const programId = data.programId;
  const logDate = data.logDate;
  const workoutId = data.workoutId;
  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);
  let update = {};
  if (data.feedback) {
    let key = `logs.${logDate}.${workoutId}.feedback`;
    update[key] = data.feedback;
  }
  if (data.note) {
    let key = `logs.${logDate}.notes.${workoutId}`;
    update[key] = firebase.firestore.FieldValue.arrayUnion(data.note);
  }
  // if (goalUpdate) {
  //   if (Object.keys(goalUpdate).length) {
  //     let goalKey = `goals.${goalUpdate.weekUnix}.${goalUpdate.day}`;
  //     update[goalKey] = GOAL_STATUS.COMPLETE;
  //   }
  // }

  try {
    if (updateDate) {
      await updateFeedbackDate(summaryRef);
    }
    await summaryRef.update(update);
  } catch (e) {
    throw e;
  }
  return true;
}

async function sendWorkoutMail(pdfData, fileName, workoutLink, clientData) {
  if (!workoutLink || (clientData && !clientData.email)) {
    throw new Error("Invalid params");
  }
  try {
    let blob = await getPDF(PDF_TEMPLATES.WORKOUT, pdfData, null, true);
    blob.name = fileName + ".pdf";
    const enterpriseName = pdfData.enterpriseData.enterpriseName || "";
    const pathToStore = `workoutsDocuments/`;
    const result = await uploadDocument(blob, pathToStore);
    const pdfUrl = await window.FortisForma.database.getDownloadURL(
      result.metadata.fullPath
    );
    const data = {
      clientName: clientData.name,
      enterpriseName: enterpriseName,
      workoutLink: workoutLink,
      workoutPDFLink: pdfUrl,
      clientEmail: clientData.email,
    };
    const response = await sendWorkoutEmailFN(data);
    return response;
  } catch (error) {
    throw error;
  }
}

async function removeDayLogs(clientId, programId) {
  if (!programId || !clientId) {
    throw new Error(`Invalid params`);
  }

  const summaryRef = datastore
    .collection(COLLECTIONS.WORKOUT_SUMMARY)
    .doc(clientId + "_" + programId);
  const todayDate = moment().format("MM-DD-YYYY");

  try {
    let clientSummary = await summaryRef.get();

    if (!clientSummary.exists) {
      throw new Error("Workout summary not found");
    }

    clientSummary = clientSummary.data();
    const loggedWorkouts =
      clientSummary.logs &&
      clientSummary.logs[todayDate] &&
      clientSummary.logs[todayDate];
    let loggedWorkoutsCopy = {};
    const key = `logs.${todayDate}`;
    let update = {};

    if (loggedWorkouts && loggedWorkouts.notes) {
      loggedWorkoutsCopy.notes = { ...loggedWorkouts.notes };
      update[key] = loggedWorkoutsCopy;
    } else {
      update[key] = firebase.firestore.FieldValue.delete();
    }

    await summaryRef.update(update);
  } catch (e) {
    throw e;
  }
}

// async function fetchAssessmentForms(enterpriseId, role) {
//   if (!enterpriseId) {
//     throw new Error("Invalid Params");
//   }

//   try {
//     let forms;
//     if (role === "pp") {
//       forms = datastore
//         .collection(COLLECTIONS.FORMS)
//         .where(DB_KEYS.USER_ID, "==", enterpriseId)
//         .where(DB_KEYS.ENTERPRISE_FORM, "==", false)
//         .where(DB_KEYS.ACTIVE_STATUS, "==", true)
//         .where(DB_KEYS.OLD, "==", false)
//         .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.ASSESSMENTS);
//     } else {
//       forms = datastore
//         .collection(COLLECTIONS.FORMS)
//         .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
//         .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
//         .where(DB_KEYS.ACTIVE_STATUS, "==", true)
//         .where(DB_KEYS.OLD, "==", false)
//         .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.ASSESSMENTS);
//     }
//     let formsSnapshot = await forms.get();
//     if (formsSnapshot.empty) {
//       return [];
//     }
//     let docs = formsSnapshot.docs;
//     return docs.map((doc) => {
//       let data = doc.data();
//       data.id = doc.id;
//       return data;
//     });
//   } catch (e) {
//     throw e;
//   }
// }
async function fetchAssessmentForms(userId, enterpriseId) {
  if (!enterpriseId && !userId) {
    throw new Error("Invalid Params");
  }
  try {
    const db = datastore.collection(COLLECTIONS.FORMS);
    let arr = [];
    if (userId) {
      const privateAss = db
        .where(DB_KEYS.USER_ID, "==", userId)
        .where(DB_KEYS.ENTERPRISE_FORM, "==", false)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.ASSESSMENTS)
        .get();
      arr.push(privateAss);
    }
    if (enterpriseId) {
      const enterpriseAss = db
        .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
        .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        .where(DB_KEYS.OLD, "==", false)
        .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.ASSESSMENTS)
        .get();
      arr.push(enterpriseAss);
    }
    const [privateAssSnapshot, enterpriseAssSnapshot] = await Promise.all(arr);
    const privateAssDocs = privateAssSnapshot ? privateAssSnapshot.docs : [];
    const enterpriseDocs = enterpriseAssSnapshot
      ? enterpriseAssSnapshot.docs
      : [];
    const allData = [...privateAssDocs, ...enterpriseDocs];

    if (allData.length === 0) {
      return [];
    }
    return allData.map((doc) => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
  } catch (e) {
    throw e;
  }
}

// async function fetchNoteForms(enterpriseId, role) {
//   if (!enterpriseId) {
//     throw new Error("Invalid Params");
//   }
//   let forms;
//   console.log("jajaajaj", enterpriseId);
//   try {
//     if (role === "pp") {
//       forms = datastore
//         .collection(COLLECTIONS.FORMS)
//         .where(DB_KEYS.USER_ID, "==", enterpriseId)
//         .where(DB_KEYS.ACTIVE_STATUS, "==", true)
//         .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.NOTES);
//       console.log("ppppp", forms);
//     } else {
//       forms = datastore
//         .collection(COLLECTIONS.FORMS)
//         .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
//         .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
//         .where(DB_KEYS.ACTIVE_STATUS, "==", true)
//         .where(DB_KEYS.OLD, "==", false)
//         .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.NOTES);
//     }

//     let formsSnapshot = await forms.get();
//     if (formsSnapshot.empty) {
//       return [];
//     }
//     let docs = formsSnapshot.docs;
//     return docs.map((doc) => {
//       let data = doc.data();
//       data.id = doc.id;
//       return data;
//     });
//   } catch (e) {
//     throw e;
//   }
// }

async function fetchNoteForms(userId, enterpriseId) {
  if (!enterpriseId && !userId) {
    throw new Error("Invalid Params");
  }
  try {
    let arr = [];
    const db = datastore.collection(COLLECTIONS.FORMS);
    if (userId) {
      const privateNote = db
        .where(DB_KEYS.USER_ID, "==", userId)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.NOTES)
        .get();
      arr.push(privateNote);
    }
    if (enterpriseId) {
      const enterpriseNote = db
        .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
        .where(DB_KEYS.ENTERPRISE_FORM, "==", true)
        .where(DB_KEYS.ACTIVE_STATUS, "==", true)
        .where(DB_KEYS.OLD, "==", false)
        .where(DB_KEYS.FORM_STEP, "==", FORM_TYPES.NOTES)
        .get();
      arr.push(enterpriseNote);
    }
    const [privateNoteSnapshot, enterpriseNoteSnapshot] = await Promise.all(
      arr
    );
    const privateNoteDocs = privateNoteSnapshot ? privateNoteSnapshot.docs : [];
    const enterpriseNoteDocs = enterpriseNoteSnapshot
      ? enterpriseNoteSnapshot.docs
      : [];
    const allData = [...privateNoteDocs, ...enterpriseNoteDocs];

    if (allData.length === 0) {
      return [];
    }
    return allData.map((doc) => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
  } catch (e) {
    throw e;
  }
}

async function fetchArchives(itemId, collection) {
  try {
    let key;

    if (collection === COLLECTIONS.NOTE_ARCHIVES) {
      key = DB_KEYS.NOTE_ID;
    }

    if (collection === COLLECTIONS.ASSESSMENT_ARCHIVES) {
      key = DB_KEYS.ASSESSMENT_ID;
    }

    let archives = datastore.collection(collection).where(key, "==", itemId);

    let archivesSnapshot = await archives.get();
    if (archivesSnapshot.empty) {
      return [];
    }
    let docs = archivesSnapshot.docs;
    return docs.map((doc) => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
  } catch (e) {
    throw e;
  }
}

async function fetchEnterpriseStaffDetails(data) {
  try {
    let results = await new Promise((resolve, reject) =>
      sendRequest("getCreatorsData", "post", { data }, resolve, {
        errorCallback: reject,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function generateFormVersion(ids, documentName) {
  try {
    const response = await generateFormVersionPDFFN(ids);
    let pdf = decodeBase64(response.data);
    const blob = new Blob([pdf], { type: "application/pdf" });
    saveAs(blob, `${documentName}.pdf`);
  } catch (e) {
    throw e;
  }
}

function decodeBase64(data) {
  // base64 string
  const base64str = data;

  // decode base64 string, remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ""));
  const length = binary.length;
  const buffer = new ArrayBuffer(length);
  let view = new Uint8Array(buffer);

  for (let i = 0; i < length; i++) {
    view[i] = binary.charCodeAt(i);
  }

  return view;
}

// async function fetchFormImages(enterpriseId) {
//   try {
//     if (!enterpriseId) {
//       throw new Error("Invalid Params");
//     }

//     let formImages = datastore
//       .collection(COLLECTIONS.FORM_IMAGES)
//       .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId);
//     let formImagesSnapshot = await formImages.get();

//     if (formImagesSnapshot.empty) {
//       return [];
//     }

//     let docs = formImagesSnapshot.docs;
//     return docs.map((doc) => doc.data());
//   } catch (e) {
//     throw e;
//   }
// }

async function fetchFormImages(enterpriseId = null, userId = null) {
  try {
    if (!enterpriseId && !userId) {
      throw new Error("Invalid Params");
    }
    const db = datastore.collection(COLLECTIONS.FORM_IMAGES);
    let arr = [];
    if (enterpriseId) {
      const enterpriseImage = db
        .where(DB_KEYS.ENTERPRISE_ID, "==", enterpriseId)
        .get();
      arr.push(enterpriseImage);
    }
    if (userId) {
      const privateImage = db.where(DB_KEYS.TRAINER_ID_KEY, "==", userId).get();
      arr.push(privateImage);
    }
    const [entImageSnapshot, privateImageSnapshot] = await Promise.all(arr);
    const ppImageDocs = entImageSnapshot ? entImageSnapshot.docs : [];
    const entImageDocs = privateImageSnapshot ? privateImageSnapshot.docs : [];
    const allData = [...ppImageDocs, ...entImageDocs];
    //

    if (allData.length === 0) {
      return [];
    }

    return allData.map((doc) => doc.data());
  } catch (e) {
    throw e;
  }
}

async function saveFormImages(images) {
  try {
    if (images && images.length) {
      for (let image of images) {
        const imageRef = datastore.collection(COLLECTIONS.FORM_IMAGES).doc();
        image.id = imageRef.id;
        image.createdTime = window.firebase.firestore.Timestamp.fromDate(
          new Date()
        );
        await imageRef.set(image);
      }
    }
  } catch (e) {
    throw e;
  }
}

async function deleteFormImage(id) {
  if (!id) {
    throw new Error("Invalid Params");
  }
  const api = "enterprise/formImages";
  try {
    await new Promise((resolve, reject) =>
      sendRequest(api, "delete", { id }, resolve, {
        errorCallback: reject,
      })
    );
  } catch (e) {
    throw e;
  }
}

// async function getHealthCoaches() {
//   try {
//     const api = "enterprise/healthCoaches";
//     let results = await new Promise((resolve, reject) =>
//       sendRequest(api, "get", {}, resolve, {
//         errorCallback: reject,
//         dontShowMessage: true,
//       })
//     );
//     return results;
//   } catch (e) {
//     throw e;
//   }
// }

// async function createEnterprise(enterpriseData) {
//   try {
//     const api = "enterprise";
//     let results = await new Promise((resolve, reject) =>
//       sendRequest(api, "post", enterpriseData, resolve, {
//         errorCallback: reject,
//         dontShowMessage: true,
//       })
//     );
//     return results;
//   } catch (e) {
//     throw e;
//   }
// }

async function getSubscriptionData() {
  try {
    const api = "tier";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function customerPortal() {
  try {
    const api = "customer-portal";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function createSubscriptionSession(payload) {
  try {
    const api = "create-subscription";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", payload, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function stripeSuccess(query) {
  try {
    const api = `stripe/success${query}`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function transactionsHistory() {
  try {
    const api = `transactions`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function coachingSolution(payload) {
  try {
    const api = `coaching-solution`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", payload, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );

    return results;
  } catch (e) {
    throw e;
  }
}

async function checkTier() {
  let user = currentUser();
  if (!user || !user.uid) {
    return;
  }

  try {
    const api = `tier`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );

    let ref = await datastore
      .collection(COLLECTIONS.USER_DATA)
      .doc(user.uid)
      .get();
    let data = await ref.data();
    let role = data.role;
    if (!data.tier) {
      switch (role) {
        case ROLES.TRAINER:
          await window.FortisForma.database.updateUserData({
            tier: TIER.FREE,
            interval: INTERVAL.FREE,
            active: false, // privatePractice: false,
          });
          break;
        case ROLES.ENTERPRISE_ADMIN:
          await updateEnterprise(
            {
              tier: TIER.ENTERPRISE,
              interval: INTERVAL.MONTH,
              active: false,
            },
            data.enterpriseId
          );
          await window.FortisForma.database.updateUserData({
            tier: TIER.ENTERPRISE,
            interval: INTERVAL.MONTH,
            active: false,
          });
          break;
        case ROLES.PRACTITIONER:
          await window.FortisForma.database.updateUserData({
            tier: TIER.PRIVATE,
            interval: INTERVAL.MONTH,
            active: false,
          });
          break;
        default:
          console.log("end of user");
      }
    }
    return results;
  } catch (e) {
    throw e;
  }
}

async function getPaymentInfo(payload) {
  try {
    const api = `charge`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", payload, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function fetchUserForms(userId) {
  if (!userId) {
    return;
  }
  try {
    let forms = datastore
      .collection(COLLECTIONS.FORMS)
      .where(DB_KEYS.USER_ID, "==", userId)
      .where(DB_KEYS.ACTIVE_STATUS, "==", true)
      .where(DB_KEYS.ENTERPRISE_FORM, "==", false);
    // .where(DB_KEYS.CLIENT_SPECIFIC, "==", true)
    // .orderBy(DB_KEYS.CLIENT_SPECIFIC)
    // .oR(where(DB_KEYS.CLIENT_SPECIFIC, "==", false))
    // .where(DB_KEYS.CLIENT_SPECIFIC, "==", false)
    // .where('clientId', "==", false);

    let formsSnapshot = await forms.get();
    if (formsSnapshot.empty) {
      return [];
    }
    let docs = formsSnapshot.docs;
    const finalRes = docs.map((doc) => {
      let data = doc.data();
      data.id = doc.id;
      return data;
    });
    return finalRes.filter((data) => data.clientSpecific != true);
  } catch (e) {
    throw e;
  }
}

async function enterpriseSignup(enterpriseData) {
  try {
    const api = "enterprise-signup";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", enterpriseData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function createTrialForNewAccount(payload) {
  let payloadData = payload;
  try {
    if (!payloadData) {
      let user = currentUser();
      if (!user || !user.uid) {
        return;
      }
      let ref = await datastore
        .collection(COLLECTIONS.USER_DATA)
        .doc(user.uid)
        .get();
      let data = await ref.data();
      payloadData = {
        plan: data.tier,
        interval: data.interval,
        uid: user.uid,
      };
    }

    const api = "subscription/trial";
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", payloadData, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function getSubscription() {
  try {
    const api = `subscription`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "get", {}, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

async function closeAccount(payload) {
  try {
    const api = `close`;
    let results = await new Promise((resolve, reject) =>
      sendRequest(api, "post", payload, resolve, {
        errorCallback: reject,
        dontShowMessage: true,
      })
    );
    return results;
  } catch (e) {
    throw e;
  }
}

// async function updateEnterprise(data, enterpriseId) {
//   let user = currentUser();
//   if (!user || !user.uid) {
//     return;
//   }
//   try {
//     let ref = datastore.collection(COLLECTIONS.ENTERPRISE).doc(enterpriseId);
//     let results = await ref.set(data, { merge: true });
//     return results;
//   } catch (e) {
//     throw e;
//   }
// }

async function stripeCardlisting(customerId) {
  if (!customerId) {
    return;
  }
  const authToken = await getUserToken();

  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  };

  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}card/list/${customerId}/`;
  // const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}card/list/cus_NR2PGxEeB2vzaA/`;

  return fetch(apiURL, fetchOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((e) => {
      throw e;
    });
}

async function stripeCardCall(url, data, customerId) {
  if (!customerId) {
    return;
  }
  const authToken = await getUserToken();

  let fetchOptions = {
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };

  const apiURL = `${process.env.REACT_APP_STRIPE_BACKEND}${url}${customerId}/`;

  return fetch(apiURL, fetchOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((e) => {
      throw e;
    });
}

let exportedFunctions = {
  uploadNoteAttachments,
  deleteNote,
  saveNoteData,
  fetchNotes,
  updateClientFormsData,
  getEnterpriseData,
  getPurchaseHistory,
  store,
  createUserWithEmailAndPassword: createUserWithEmailAndPassword,
  storeUserProfile: storeUserProfile,
  signInWithEmailAndPassword: signInWithEmailAndPassword,
  sendPasswordResetEmail: sendPasswordResetEmail,
  currentUser: currentUser,
  updatePassword: updatePassword,
  updateUserLoginTime: updateUserLoginTime,
  updateUserTrainer: updateUserTrainer,
  getUserProgram: getUserProgram,
  getUserData: getUserData,
  signOut: signOut,
  getSimilarExercises: getSimilarExercises,
  queryData: queryData,
  storeWorkout: storeWorkout,
  convertDraftToTemplate: convertDraftToTemplate,
  deleteDraftWorkouts: deleteDraftWorkouts,
  deleteWorkout: deleteWorkout,
  deleteProgram: deleteProgram,
  createNewClient: createNewClient,
  removeClient: removeClient,
  sendInvite: sendInvite,
  storeExercise: storeExercise,
  startUploadFileTask: startUploadFileTask,
  assignProgramToClient: assignProgramToClient,
  bulkUploadExercises: bulkUploadExercises,
  storeMovementCategory: storeMovementCategory,
  storeMovement: storeMovement,
  bulkUploadMovementCategories: bulkUploadMovementCategories,
  bulkUploadMovements: bulkUploadMovements,
  storeEquipment: storeEquipment,
  bulkUploadEquipments: bulkUploadEquipments,
  storeMuscle: storeMuscle,
  storeBlogs: storeBlogs,
  storeMuscleGroups: storeMuscleGroups,
  bulkUploadMuscles: bulkUploadMuscles,
  bulkUploadMuscleGroups: bulkUploadMuscleGroups,
  requestExercise: requestExercise,
  storeFunction: storeFunction,
  storeFunctionCategory: storeFunctionCategory,
  bulkUploadFunctionCategories: bulkUploadFunctionCategories,
  bulkUploadFunctions: bulkUploadFunctions,
  deleteDocument: deleteDocument,
  getUserToken: getUserToken,
  getWorkoutSummary: getWorkoutSummary,
  getPendingApprovals: getPendingApprovals,
  approveSignupRequest: approveSignupRequest,
  declineSignupRequest: declineSignupRequest,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendEmailVerification,
  checkActionCode,
  applyActionCode,
  emailVerified,
  getProgramHistory,
  getCustomClaims,
  storeFeedback,
  getPrograms,
  getClientsForProgram,
  saveProgram,
  getProgram,
  assingProgramToClients,
  removeProgramfromClients,
  approveClientRequest,
  rejectClientRequest,
  initializeWalkthrough,
  updateWalkthroughStatus,
  addWorkoutDayToClientProgram,
  getTrainersList,
  showTrainer,
  hideTrainer,
  clientEmailVerification,
  sendMessage,
  onTrainerVerify,
  loadMoreLevelsOfExercise,
  getProgramFromHistory,
  getWorkouts,
  getPublicTrainersList,
  updatePublicTrainer,
  getTrainerData,
  getPublicTrainerData,
  saveOnBoardingDetails,
  updateUserData,
  uploadDocument,
  deleteFileFromStroage,
  getDownloadURL,
  updateIncompleteStatus,
  getRandomDocId,
  getExercisesWithProperty,
  replaceMovementFromExercises,
  createEnterprise,
  getEnterprises,
  fetchEnterpriseInvite,
  createEnterpriseAdmin,
  updateEnterprise,
  createStaffInvite,
  removeEnterpriseStaffMember,
  getHealthCoaches,
  fetchEnterpriseForms,
  saveForms,
  removeForm,
  getAssignedCoaches,
  assignCoach,
  removeCoach,
  fetchGlobalForms,
  swapSequence,
  fetchFormsByFormId,
  saveFormSubmissionData,
  updateData,
  fetchFormData,
  fetchClientForms,
  saveAssessment,
  fetchAssessments,
  uploadAttachments,
  deleteAssessment,
  deleteGlobalForm,
  checkIfEmailPresent,
  bulkClientsUpload,
  fetchDocumentsByArrayOfIds,
  fetchEnterpriseClients,
  fetchEnterpriseInPPFree,
  fetchclientCountHC,
  getPDF,
  exportExercises,
  sendOtp,
  verifyOtp,
  deleteClientForms,
  addWorkoutLog,
  removeWorkoutLog,
  addWorkoutSectionLog,
  removeWorkoutSectionLog,
  saveWorkoutLogMeta,
  changeWorkoutStartDate,
  removeDayLogs,
  fetchAssessmentForms,
  fetchNoteForms,
  fetchArchives,
  fetchEnterpriseStaffDetails,
  generateFormVersion,
  sendWorkoutMail,
  fetchFormImages,
  saveFormImages,
  deleteFormImage,
  resendStaffInvite,
  getSubscriptionData,
  createSubscriptionSession,
  stripeSuccess,
  transactionsHistory,
  checkTier,
  coachingSolution,
  getPaymentInfo,
  customerPortal,
  fetchUserForms,
  fetchEnterpriseDetailsForStaff,
  enterpriseSignup,
  createTrialForNewAccount,
  getSubscription,
  closeAccount,
  stripeCall,
  addBlogs,
  deleteBlog,
  stripeCallForNewUser,
  checkUserForms,
  addSeqNoInDocs,
  stripeCardCall,
  stripeCardlisting,
  stripeCardDetail,
  registerPushNotifications,
};

const wrapped = memoryCacheWrapper(exportedFunctions);
export default wrapped;
