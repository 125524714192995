import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CanvasDraw from "react-canvas-draw";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LZString from "lz-string";
import { isMobile } from "react-device-detect";

const COLORS = [
  "#000000",
  "#EE5551",
  "#FCBC00",
  "#6CCA54",
  "#14B0FF",
  "#126c99",
  "#DE8AF8",
  "#8D6E63",
];

const SIZES = [
  { size: "4px", brushSize: 0.5 },
  { size: "6px", brushSize: 2 },
  { size: "10px", brushSize: 4 },
  { size: "16px", brushSize: 5 },
  { size: "20px", brushSize: 6 },
  { size: "24px", brushSize: 7 },
  { size: "28px", brushSize: 8 },
  { size: "30px", brushSize: 12 },
  { size: "32px", brushSize: 14 },
  { size: "34px", brushSize: 20 },
];

const ImageEditorModal = forwardRef(
  (
    {
      onClose,
      handleSave,
      title,
      imageMode,
      backgroundImage,
      canvasData,
      isPortrait,
    },
    ref
  ) => {
    const [brushColor, setBrushColor] = useState(COLORS[0]);
    const [brushSize, setBrushSize] = useState(SIZES[0].brushSize);
    const [showBrushSizeMenu, setShowBrushSizeMenu] = useState(false);
    const [showBrushColorMenu, setShowBrushColorMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [canvasImage, setCanvasImage] = useState(null);
    const [canvasHeight, setCanvasHeight] = useState(null);
    const [canvasDataState, setCanvasDataState] = useState(canvasData);
    const [canvasWidth, setCanvasWidth] = useState(null);
    const [portraitMode, setPortraitMode] = useState(isPortrait);
    const [scale, setScale] = useState(1);

    useImperativeHandle(ref, () => ({ confirmClose }));

    let canvasRef = React.useRef(null);
    let canvasRef2 = React.useRef(null);

    useEffect(() => {
      init();
    }, []);

    useEffect(() => {
      //If device orientation changed
      if (portraitMode !== isPortrait) {
        if (canvasRef2 && canvasRef2.lines && canvasRef2.lines.length) {
          let canvasData = canvasRef2 && canvasRef2.getSaveData();
          let compressedData = LZString.compressToBase64(canvasData);
          setCanvasDataState(compressedData);
        }
        init(true);
        setPortraitMode(isPortrait);
      }
    }, [isPortrait]);

    const init = (orientationChanged) => {
      if (imageMode) {
        scaleBackgroundImage(orientationChanged);
      } else {
        setCanvasHeight(getHeight());
        setCanvasWidth(getWidth());
      }
    };

    const confirmClose = (callback, message) => {
      if (
        !loading &&
        canvasRef2 &&
        canvasRef2.lines &&
        canvasRef2.lines.length
      ) {
        window.customConfirm(message, callback, null, {
          cancelButtonText: "Cancel",
          okButtonText: "Yes",
        });
        return;
      }
      callback();
    };

    const getHeight = () => {
      let height = window.innerHeight;
      height = isMobile ? window.innerHeight - 180 : (height * 7) / 10;
      return height;
    };

    const getWidth = () => {
      let dialog = document.getElementById("previewFormContent");
      if (isMobile) {
        return dialog.offsetWidth;
      }
      let width = (window.innerWidth * 4.5) / 5;
      if (width > 1230) {
        width = 1230;
      }
      return width;
    };

    async function scaleBackgroundImage(orientationChanged = false) {
      try {
        setLoading(true);
        let maxHeight = getHeight();
        let maxWidth = getWidth();
        let newHeight = maxHeight;
        let newWidth = maxWidth;
        await new Promise((done) => {
          var img = document.getElementById("canvasBackground");
          const calculateRatio = () => {
            var scale;
            var hRatio = maxWidth / img.width;
            var vRatio = maxHeight / img.height;
            scale = Math.min(hRatio, vRatio);
            newWidth = img.width * scale;
            newHeight = img.height * scale;
            done(true);
          };
          if (orientationChanged) {
            calculateRatio();
          } else {
            img.onload = calculateRatio;
          }
        });
        setCanvasWidth(newWidth);
        setCanvasHeight(newHeight);
        setCanvasImage(backgroundImage);
        setLoading(false);
      } catch (error) {
        onClose();
        window.NotificationUtils.showError("Something went wrong");
        console.error(error);
      }
    }

    const onSave = async () => {
      try {
        canvasRef2 && canvasRef2.resetView();
        let canvasImage = canvasRef2 && canvasRef2.getDataURL();
        let canvasData = canvasRef2 && canvasRef2.getSaveData();
        let data = {};

        if (canvasRef2 && canvasRef2.lines && canvasRef2.lines.length) {
          data.canvasData = LZString.compressToBase64(canvasData);
          data.canvasImage = canvasImage;
        }

        if (backgroundImage) {
          data.backgroundImageURL = backgroundImage;
        }
        return handleSave(data);
      } catch (error) {
        window.NotificationUtils.showError("Something went wrong");
        console.error(error);
      }
    };

    const renderCloseButton = () => {
      return (
        <IconButton className="closeIconButton" onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      );
    };

    const handleCloseBrushSizeMenu = () => {
      setShowBrushSizeMenu(false);
      setAnchorEl(null);
    };

    const handleCloseBrushColorMenu = () => {
      setShowBrushColorMenu(false);
      setAnchorEl(null);
    };

    const handleOpenBrushSizeMenu = (e) => {
      setShowBrushSizeMenu(true);
      setAnchorEl(e.currentTarget);
    };

    const handleOpenBrushColorMenu = (e) => {
      setShowBrushColorMenu(true);
      setAnchorEl(e.currentTarget);
    };

    const renderBrushSizeMenu = () => {
      return (
        <div className="verticalCenter" style={{ padding: "8px 16px" }}>
          {SIZES.map((item, index) => {
            return (
              <div
                className="brushSizeIconButtonContainer"
                style={{
                  border:
                    brushSize === item.brushSize
                      ? `1px solid ${brushColor}`
                      : "none",
                }}
              >
                <IconButton
                  key={index}
                  onClick={() => setBrushSize(item.brushSize)}
                  size="small"
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      height: item.size,
                      width: item.size,
                      backgroundColor: brushColor,
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </div>
            );
          })}
        </div>
      );
    };

    const renderBrushColorMenu = () => {
      return (
        <div className="verticalCenter" style={{ padding: "8px 16px" }}>
          {COLORS.map((color, index) => {
            const isSelected = Boolean(color === brushColor);
            return (
              <div className="brushColorPaletteIconContainer" key={index}>
                <button
                  style={{ backgroundColor: color }}
                  className={
                    isSelected
                      ? "brushColorPaletteIconSelected"
                      : "brushColorPaletteIcon"
                  }
                  onClick={() => setBrushColor(color)}
                />
              </div>
            );
          })}
        </div>
      );
    };

    const renderPopoverContent = () => {
      if (showBrushSizeMenu) return renderBrushSizeMenu();
      if (showBrushColorMenu) return renderBrushColorMenu();
    };

    const handleClosePopover = () => {
      if (showBrushSizeMenu) return handleCloseBrushSizeMenu();
      if (showBrushColorMenu) return handleCloseBrushColorMenu();
    };

    const renderPopover = () => {
      return (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {renderPopoverContent()}
        </Popover>
      );
    };

    const renderBrushSizeIcon = () => {
      return (
        <>
          <IconButton
            style={{ borderRadius: "2px" }}
            onClick={(e) => handleOpenBrushSizeMenu(e)}
          >
            <LineWeightIcon fontSize="large" />
          </IconButton>
          <Divider orientation="vertical" flexItem />
        </>
      );
    };
    const renderBrushColorIcon = () => {
      return (
        <>
          <IconButton
            style={{ borderRadius: "2px" }}
            onClick={(e) => handleOpenBrushColorMenu(e)}
          >
            <FiberManualRecordIcon
              style={{ color: brushColor }}
              fontSize="large"
            />
          </IconButton>
          <Divider orientation="vertical" flexItem />
        </>
      );
    };

    const renderUndoButton = () => {
      return (
        <>
          <Button color="primary" onClick={() => canvasRef2.undo()}>
            Undo
          </Button>
          <Divider orientation="vertical" flexItem />
        </>
      );
    };

    const renderClearButton = () => {
      return (
        <>
          <Button color="primary" onClick={() => canvasRef2.clear()}>
            Clear
          </Button>
          <Divider orientation="vertical" flexItem />
        </>
      );
    };

    const renderResetZoomButton = () => {
      return (
        <>
          <Button
            color="primary"
            onClick={() => {
              canvasRef.resetTransform();
              setScale(1);
            }}
          >
            Reset Zoom
          </Button>
          <Divider orientation="vertical" flexItem />
        </>
      );
    };

    const renderEditorToolbar = () => {
      return (
        <div className="imageEditorToolbarContainer">
          {renderBrushSizeIcon()}
          {renderBrushColorIcon()}
          {renderResetZoomButton()}
          {renderUndoButton()}
          {renderClearButton()}
        </div>
      );
    };

    const renderImageEditor = () => {
      if (!canvasWidth || !canvasHeight) {
        return null;
      }
      const saveData =
        canvasDataState && LZString.decompressFromBase64(canvasDataState);
      return (
        <div className="freehandNoteEditorContainer noselect">
          <TransformWrapper
            doubleClick={{ disabled: true }}
            ref={(ref) => (canvasRef = ref)}
            // initialScale={scale}
            // onZoom={(ref) => setScale(ref.state.scale)}
            limitToBounds={true}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            panning={{ disabled: true }}
            zoomAnimation={{ disabled: true }}
          >
            <TransformComponent>
              <CanvasDraw
                ref={(ref) => (canvasRef2 = ref)}
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
                lazyRadius={0}
                brushRadius={brushSize}
                brushColor={brushColor}
                catenaryColor={brushColor}
                hideGrid={!imageMode}
                saveData={saveData || ""}
                immediateLoading={true}
                imgSrc={canvasImage}
                enablePanAndZoom={isMobile}
                clampLinesToDocument={false}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      );
    };

    const renderNote = () => {
      if (!isMobile) {
        return (
          <Typography variant="body2" className="marginTop8">
            Note: To zoom use <strong>Ctrl+Mouse Wheel</strong>. To Pan use{" "}
            <strong>Ctrl+Click</strong>.
          </Typography>
        );
      }
    };

    const renderActions = () => {
      return (
        <div style={{ float: "right", paddingTop: isMobile ? 16 : 0 }}>
          <Button color="primary" onClick={onSave}>
            Save
          </Button>
        </div>
      );
    };

    const renderContent = () => {
      return (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {renderPopover()}
            {renderEditorToolbar()}
            {renderImageEditor()}
            {renderNote()}
            {renderActions()}
          </DialogContent>
        </>
      );
    };

    const renderLoader = () => {
      return (
        <div style={{ padding: "200px 300px" }}>
          <CircularProgress color="primary" />
        </div>
      );
    };

    return (
      <>
        {renderCloseButton()}
        {loading ? renderLoader() : renderContent()}
        {imageMode && (
          <div style={{ display: "none" }}>
            <img
              id="canvasBackground"
              src={backgroundImage}
              alt="canvas background"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
      </>
    );
  }
);

export default ImageEditorModal;
