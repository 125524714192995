import { Avatar, IconButton, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { ROLES, ROLES_LABEL_MAP } from "../../constants";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function StaffMemberCard(props) {
  function onDelete() {
    window.customConfirm(
      "Are you sure you want to remove " + props.data.email + " ?",
      () => {
        props.onDelete(props.data);
      }
    );
  }

  const getInitials = (name) => {
    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  };

  const onResendStaffInvite = async () => {
    let memberData = props.data;
    if (
      !memberData.id ||
      !memberData.name ||
      !memberData.email ||
      !memberData.role
    ) {
      throw new Error("Missing Info");
    }
    let data = { memberData };
    try {
      window.NotificationUtils.showConfirm("Sending Invite");
      await window.FortisForma.database.resendStaffInvite(data);
      window.NotificationUtils.showSuccess("Invite sent");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Could not send invite");
    }
  };

  return (
    <div className="staffMemberCardRow">
      <div style={{ flex: 0.1 }}>
        <Avatar
          src={props.data.picURL && props.data.picURL}
          className="staffMemberAvatar"
        >
          {getInitials(props.data.name)}
        </Avatar>
      </div>

      <Typography
        className="staffListCellEntry staffMemberCardName"
        display="inline"
      >
        {props.data.name}
      </Typography>

      <Typography className="staffListCellEntry staffMemberCardEmail">
        {props.data.email}
      </Typography>

      <div
        className={
          props.data.designation
            ? "staffListCellEntry roleDesignationTableCell"
            : "staffListCellEntry"
        }
      >
        <Typography>
          {props.data.role && ROLES_LABEL_MAP[props.data.role]}
        </Typography>
        <Typography
          className="staffMemberDesignation"
          variant="caption"
          color="textSecondary"
        >
          {props.data.designation}
        </Typography>
      </div>

      <div className="staffListCellEntry staffListActionButtonsContainer">
        <Tooltip placement="top" title="Edit" arrow>
          <IconButton
            onClick={() => props.onEdit(props.data)}
            className="memberListActionCellIcon"
            disabled={
              props.data.id === props.user.id ||
              (props.user.role === ROLES.FACILITY_ADMIN &&
                props.data.role === ROLES.ENTERPRISE_ADMIN)
            }
            color="primary"
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
        {props.showResendInviteOption && (
          <Tooltip placement="top" title="Resend Invite" arrow>
            <IconButton color="primary" onClick={onResendStaffInvite}>
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip placement="top" title="Delete" arrow>
          <IconButton
            disabled={
              props.data.id === props.user.id ||
              (props.user.role === ROLES.FACILITY_ADMIN &&
                props.data.role === ROLES.ENTERPRISE_ADMIN)
            }
            className="staffListDeleteButton"
            onClick={onDelete}
            color="primary"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
        {props.data.id !== props.user.id && !props.showResendInviteOption && (
          <Tooltip title="Message">
            <IconButton
              aria-label="message"
              color="primary"
              disabled={props.data.id === props.user.id}
            >
              <i
                className="icon material-icons pointer"
                onClick={() => {
                  window.FortisForma.database
                    .updateUserTrainer(props.user, props.data)
                    .then((r) => {
                      setTimeout(() => {
                        props.history.push({
                          pathname: "/message-center",
                          state: props.data,
                        });
                      }, 1000);
                    });
                }}
                alt="message"
              >
                message
              </i>
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
