import React from "react";
import styled from "styled-components";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Typography } from "@material-ui/core";

const TAB_OPTIONS = {
  REGISTER: 0,
  PENDING: 1,
};

const LabelText = styled(Typography)`
  font-size: 13px !important;
  letter-spacing: 0.5px;
`;

const CustomToggle = (props) => {
  const handleChange = (event, value) => {
    if (value === TAB_OPTIONS.PENDING || value === TAB_OPTIONS.REGISTER) {
      props.onChange(value);
    }
  };

  return (
    <div className="customPricingToggle">
      <ToggleButtonGroup
        exclusive
        value={props.selected}
        onChange={handleChange}
      >
        <ToggleButton value={TAB_OPTIONS.REGISTER}>
          <LabelText variant="h6">Registered</LabelText>
        </ToggleButton>
        <ToggleButton value={TAB_OPTIONS.PENDING}>
          <LabelText variant="h6">Pending</LabelText>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default CustomToggle;
