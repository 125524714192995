import React from 'react';

import ProgramTemplateCard from './programTemplateCard.js'

import InfiniteList from '../../components/infiniteList/list.js';


export default class ProgramList extends InfiniteList {

  loadNextPage() {

    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    })

  }

  rowRenderer({
    key,
    index,
    isScrolling,
    style
  }) {
    let program = this.props.data[index];

      return (
        <div style={style} key={key}>
            <ProgramTemplateCard innerKey={key} program={program} selected={this.props.selectedProgram && this.props.selectedProgram.id === program.id} onProgramSelect={this.props.onProgramSelect}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
            onCopy={this.props.onCopy} />
        </div>
        );
    
  }
}
