import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import { FastField, FieldArray, Form, Formik } from "formik";
import lodash from "lodash";
import React from "react";
import * as Yup from "yup";
import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect";
import TrainerProgram from "../trainerOnboarding/previewComponents/trainerPrograms";

const packageSchema = Yup.object().shape({
  name: Yup.string().required("required").min(4, "too short"),
  price: Yup.number().required("required").min(5, "too low"),
  per: Yup.object()
    .shape({
      value: Yup.string().required("required"),
      label: Yup.string().required("required"),
    })
    .required("required"),
  extras: Yup.array().of(Yup.string()),
});

const programSchema = Yup.object().shape({
  name: Yup.string().required("required").min(4, "too short"),
  description: Yup.string().required("required").min(10, "too short"),
  packages: Yup.array()
    .of(packageSchema)
    .required("Must have at-least one package"),
});

const programsSchema = Yup.object().shape({
  programs: Yup.array()
    .of(programSchema)
    .required("Must have at-least 1 package"),
});

const DROPDOWN_OPTION = [
  { value: "one-time", label: "One Time" },
  { value: "month", label: "Month" },
  { value: "session", label: "Session" },
  { value: "week", label: "Week" },
];

const EMPTY_PACKAGE = {
  name: "",
  price: 100,
  per: DROPDOWN_OPTION[1],
  extras: [],
};

const EMPTY_PROGRAM = {
  name: "",
  description: "",
  packages: [EMPTY_PACKAGE],
};

const SAMPLE_PROGRAMS = [
  {
    name: "Welcome to the Gun Show",
    description:
      "Tri's for the guys and curls for the girls. This program is designed to help you improve, strength, size and tone of your arms over 12 weeks and is offered virtually or in-person at our studio.",
    packages: [
      {
        name: "In-person - 24 Sessions",
        price: "1800",
        per: "One Time",
        extras: ["HST"],
      },
      {
        name: "Virtual - 24 Sessions",
        price: "50",
        per: "Session",
      },
    ],
  },
];

const mapExtraValuesToOptions = (values) => {
  return values.map((value) => {
    return { value, label: value };
  });
};
class Programs extends React.Component {
  state = {
    data: {
      programs: [EMPTY_PROGRAM],
    },
    viewExample: false,
  };

  componentDidMount() {
    this.setState({
      data: {
        programs: this.mapDataToState(this.props.data),
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: {
          programs: this.mapDataToState(this.props.data),
        },
      });
    }
  }

  mapDataToState(programs) {
    if (!programs) {
      return [EMPTY_PROGRAM];
    }
    return programs.map((p) => {
      p = { ...p };
      p.packages = p.packages.map((pack) => {
        pack = { ...pack };
        pack.per = DROPDOWN_OPTION.find((option) => option.value === pack.per);
        return pack;
      });
      return p;
    });
  }

  getData = (checkErrors = true) => {
    if (checkErrors && this.errors.programs && this.errors.programs.length) {
      return null;
    }
    let data = this.values || [EMPTY_PROGRAM];

    data = { ...data };
    data.programs = data.programs.map((program) => {
      program = { ...program };
      program.packages = program.packages.map((p) => {
        p = { ...p };
        if (p.per) {
          p.per = p.per.value;
        } else {
          p.per = DROPDOWN_OPTION[2].value;
        }
        return p;
      });
      return program;
    });
    return data;
  };

  viewExampleProgram = () => {
    this.setState({
      viewExample: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div id="programsHeader">
          <span className="onBoardingHeading onBoardingSectionHeader">
            Services
          </span>
          <Button
            onClick={this.viewExampleProgram}
            variant="outlined"
            color="secondary"
            size="small"
          >
            View Sample
          </Button>
        </div>

        {this.renderExampleProgramDialog()}

        <Formik
          enableReinitialize
          initialValues={this.state.data}
          validationSchema={programsSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
          }) => (
            <Form id="programForm">
              <FieldArray name={`programs`}>
                {({ insert, remove, push }) => (
                  <div>
                    {this.renderProgramForm({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      insert,
                      remove,
                      push,
                      setFieldValue,
                      validateForm,
                    })}
                    <div className="mg-tp-bt-32" />
                    <Button
                      fullWidth={true}
                      color="primary"
                      variant="outlined"
                      aria-label="add program"
                      onClick={() => {
                        push(EMPTY_PROGRAM);
                        this.afterAddProgram(values.programs);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Package
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }

  closeExampleProgram = () => {
    this.setState({
      viewExample: false,
    });
  };

  renderExampleProgramDialog() {
    return (
      <Dialog
        open={this.state.viewExample}
        onClose={this.closeExampleProgram}
        aria-labelledby="example-title"
      >
        <DialogTitle id="example-title">Service Example</DialogTitle>
        <DialogContent style={{ padding: 0 }} id="exampleProgram">
          <TrainerProgram
            onClose={this.closeExampleProgram}
            isStandAlone={true}
            selectedTrainer={{ programs: SAMPLE_PROGRAMS }}
          />
        </DialogContent>
      </Dialog>
    );
  }

  afterAddProgram(programs) {
    let id = `#programs-${programs.length}`;
    setTimeout(
      () => [
        requestAnimationFrame(() => {
          let previousProgramDiv = document.querySelector(id);
          if (previousProgramDiv && previousProgramDiv.scrollIntoView) {
            previousProgramDiv.scrollIntoView({
              behavior: "smooth",
            });
          }
        }),
      ],
      250
    );
  }

  renderTextField = (inputProps) => {
    return <CustomTextField {...inputProps} />;
  };

  renderProgramForm({
    values,
    handleChange,
    handleBlur,
    remove,
    push,
    setFieldValue,
    errors,
    validateForm,
  }) {
    this.errors = errors;
    this.values = values;
    this._validateForm = validateForm;

    if (!values.programs.length) {
      return this.noProgramView(push);
    }
    return values.programs.map((program, index) => {
      return (
        <div id={`programs-${index}`} key={index}>
          {index > 0 && <Divider className="mg-tp-bt-32 fullWidth" />}
          <div className="displayColumn marginTop16">
            <div id="programHeading" className="marginBottom8 headingContainer">
              <Typography variant="h6" className="boldFont">
                Package {index + 1}
              </Typography>
              {index > 0 && (
                <Button
                  variant="text"
                  color="default"
                  onClick={() => remove(index)}
                >
                  Remove
                </Button>
              )}
            </div>

            {this.renderTextField({
              name: `programs.${index}.name`,
              label: "Package Name",
              value: program.name,
              onChange: handleChange,
              onBlur: handleBlur,
              className: "nameTextField",
              autoFocus: true,
            })}
            {this.renderTextField({
              name: `programs.${index}.description`,
              label: "Package Description",
              value: program.description,
              onChange: handleChange,
              onBlur: handleBlur,
              rows: "6",
              multiline: true,
              required: true,
              placeholder:
                "A good description explains the benefits a person will receive from choosing this package as well as indicates what a client can expect, short and to the point is usually best. Save the details for follow-ups and phone calls.",
            })}
          </div>

          <Typography variant="h6" color="initial" className="mg-tp-bt-16">
            Add Service Variations
          </Typography>

          {this.renderPackages({
            values,
            handleChange,
            handleBlur,
            index,
            setFieldValue,
            errors,
          })}
        </div>
      );
    });
  }

  noProgramView(push) {
    return <div className="displayColumn alignCenter">No package added</div>;
  }

  renderPackages({
    values,
    handleChange,
    handleBlur,
    index,
    setFieldValue,
    errors,
  }) {
    let program = values.programs[index];
    let packages = program.packages;

    return (
      <FieldArray name={`programs.${index}.packages`}>
        {({ insert, remove, push }) => {
          if (!packages.length) {
            return this.renderEmptyPackages(push);
          }
          return (
            <>
              {packages.map((trainerPackage, packageIndex) => {
                return (
                  <React.Fragment key={packageIndex}>
                    <div
                      id={`programs-${index}-packages-${packageIndex}`}
                      className="marginBottom8 headingContainer"
                    >
                      <Typography variant="h6">
                        Variation {packageIndex + 1}
                      </Typography>
                      {packageIndex > 0 && (
                        <Button
                          variant="text"
                          color="default"
                          onClick={() => remove(packageIndex)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>

                    {this.renderPackageForm({
                      trainerPackage,
                      handleChange,
                      handleBlur,
                      programIndex: index,
                      packageIndex,
                      setFieldValue,
                      errors,
                    })}
                  </React.Fragment>
                );
              })}

              <Button
                className="marginBottom"
                fullWidth={true}
                variant="outlined"
                color="secondary"
                aria-label="add package"
                onClick={() => {
                  push(EMPTY_PACKAGE);
                }}
                startIcon={<AddIcon />}
              >
                Add Variation
              </Button>
            </>
          );
        }}
      </FieldArray>
    );
  }

  renderEmptyPackages(push) {
    return <div className="displayColumn alignCenter">No variation added</div>;
  }

  renderPackageForm({
    trainerPackage,
    handleChange,
    handleBlur,
    programIndex,
    packageIndex,
    setFieldValue,
  }) {
    let extras = mapExtraValuesToOptions(trainerPackage.extras || []);
    let extraOptions = extras.map((item) => item.value);
    if (!extraOptions.includes("HST")) {
      extraOptions.push("HST");
    }
    return (
      <div className="flexWrapClass marginBottom justifyContentSpaceBetween">
        {this.renderTextField({
          name: `programs.${programIndex}.packages.${packageIndex}.name`,
          label: "Name",
          value: trainerPackage.name,
          onChange: handleChange,
          onBlur: handleBlur,
          className: "textFieldHalfWidth programPackageFieldWidth",
          required: true,
        })}
        {this.renderTextField({
          name: `programs.${programIndex}.packages.${packageIndex}.price`,
          label: "Price",
          value: trainerPackage.price,
          onChange: handleChange,
          onBlur: handleBlur,
          className: "textFieldHalfWidth programPackageFieldWidth",
          inputType: "number",
          required: true,
        })}
        <div className="textFieldHalfWidth programPackageFieldWidth marginBottom">
          <FortisFormaSelect
            className="programDropdownHeight"
            placeholder="Per (month/session/week/one-time)"
            value={trainerPackage.per}
            classNamePrefix="fortisFormaDropDownSelect"
            onChange={(value) => {
              setFieldValue(
                `programs.${programIndex}.packages.${packageIndex}.per`,
                value
              );
            }}
            options={DROPDOWN_OPTION}
            isClearable={false}
          />
        </div>

        <div className="textFieldHalfWidth programPackageFieldWidth marginBottom">
          <FortisFormaSelect
            className="programDropdownHeight"
            creatable
            isClearable
            isMulti
            formatCreateLabel={(input) => `Add "${input}"`}
            value={extras}
            onChange={(newValue) => {
              let selected = newValue ? newValue.map((item) => item.value) : [];
              setFieldValue(
                `programs.${programIndex}.packages.${packageIndex}.extras`,
                selected
              );
            }}
            options={mapExtraValuesToOptions(extraOptions)}
          />
        </div>
      </div>
    );
  }
}

class CustomTextField extends React.Component {
  render() {
    let superProps = { ...this.props };
    superProps.type = superProps.inputType;
    delete superProps.inputType;

    return (
      <FastField name={this.props.name}>
        {({ field, form }) => {
          return (
            <TextField
              {...superProps}
              variant="outlined"
              className={`marginBottom ${superProps.className}`}
              color="primary"
              error={
                lodash.has(form.touched, superProps.name) &&
                lodash.has(form.errors, superProps.name)
              }
              helperText={
                lodash.get(form.touched, superProps.name) &&
                lodash.get(form.errors, superProps.name)
              }
              {...field}
            />
          );
        }}
      </FastField>
    );
  }
}
export default Programs;
