import React from 'react';

import {WELCOME_STATES, CONTINUE_URL} from '../../constants.js';

import {
    Spinner
} from 'reactstrap';


const TIMER = 30000;

function getDomainName(hostName) {
    if (hostName === "localhost") {
        return "localhost:3000"
    }
    if (hostName === "fapp-57adc.web.app") {
        return hostName;
    }
    return hostName.substring(hostName.indexOf(".") + 1);
}

export default class WelcomeMessage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            remaining: TIMER / 1000,
            status: WELCOME_STATES.LOADING
        }
    }

    componentDidMount() {
        window.FortisForma.database.sendEmailVerification({
            url: CONTINUE_URL,
            handleCodeInApp: false
        }).then((results)=>{
            this.setState({
                status: WELCOME_STATES.EMAIL_SENT
            })
            this.startTimer();
            window.FortisForma.database.signOut().then((results)=> {
            }).catch((e)=> {
                console.error(e);
            })
        }).catch((e) => {
            console.error(e);
            this.clearTimers();
            this.setState({
                status: WELCOME_STATES.EMAIL_SENDING_FAILED
            })
        })
    }

    startTimer() {
        this.timer = setTimeout(() => {
            window.location = window.location.protocol + "//" + getDomainName(window.location.hostname);
        }, TIMER - 1000);

        this.interval = setInterval(() => {
            this.setState({
                remaining: this.state.remaining - 1
            })
        }, TIMER / (TIMER / 1000));
    }

    clearTimers() {
        clearTimeout(this.timer)
        clearInterval(this.interval)
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    renderSuccessfulContent() {
        return (
            <React.Fragment>
                <h4 className="" style={{color: 'red', marginBottom: 40}}>Note: We recommend to use a desktop device to build and assign programs for the best experience..</h4>
                <h3>Thanks for Joining!</h3>
                <h4>Please verify your account using the link sent to your email.</h4>
                {this.state.remaining > 0 && <p className="redirect">Taking you back in {this.state.remaining} seconds...</p>}
            </React.Fragment>
        )
    }

    renderFailedContent() {
        return (
            <React.Fragment>
                <h3>Email Sending Failed</h3>
                <p>Please try again in some time.</p>
            </React.Fragment>
        )
    }

    renderLoading() {
        return (
            <Spinner color="primary" />
        )
    }

    _render() {
        switch (this.state.status) {
            case WELCOME_STATES.EMAIL_SENDING_FAILED:
                return this.renderFailedContent();
            
            case WELCOME_STATES.EMAIL_SENT:
                return this.renderSuccessfulContent();

            case WELCOME_STATES.LOADING:
                return this.renderLoading();

            default:
                break;
        }
    }

    

    render() {
        return (
            <div className="welcomeMessage">
                {this._render()}
            </div>
        )
    }
}