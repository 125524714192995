import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
  Chip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function FormDetailsCard(props) {
  function renderFormDescription(title, desc) {
    const maxLength = 110;
    const titleLength = (title && title.length) || 0;
    const descLength = desc && desc.length;
    if (!descLength) {
      return "";
    }
    const totalLength = titleLength + descLength;
    if (totalLength < maxLength) {
      return desc;
    }
    let cutOffLength = totalLength - maxLength;
    const result = desc.substring(0, descLength - cutOffLength);
    return `${result}...`;
  }

  function renderFormFilledStatus(){
    if(props.showChip){
      return (
        <div className="formDetailCardChip">
          {props.submissionDataLoading ? (
            <Skeleton variant="rect" className="skeletonChipComponent">
              <Chip
                variant="outlined"
                label={props.filled ? "COMPLETE" : "PENDING"}
                size="small"
              />
            </Skeleton>
          ) : (
            <Chip
              label={props.filled ? "COMPLETE" : "PENDING"}
              style={{ color: props.filled ? "green" : "red" }}
              variant="outlined"
              className={`chipComponent ${
                props.filled ? "greenBackground" : "redBackground"
              }`}
              size="small"
            />
          )}
        </div>
      )
    }
  }

  function renderFormCardContent(){
    return(
      <CardContent className="formDetailsCardContent">
        <Typography
          className="cardformTitle textTransformInitial"
          align="left"
          variant="h6"
          gutterBottom
          color={props.data.title ? "initial" : "textSecondary"}
        >
          {props.data.title ? props.data.title : "No Title"}
        </Typography>
        <Typography
          className="cardFormDescription textTransformInitial"
          align="left"
          variant="subtitle2"
          paragraph={true}
        >
          {renderFormDescription(props.data.title, props.data.description)}
        </Typography>
      </CardContent>
    )
  }

  function renderViewButton(){
    return(
      <Button
        color="primary"
        onClick={props.onClickPreview}
        disabled={
          (props.loading && props.isGlobalFormCard) ||
          props.submissionDataLoading
        }
      >
        View
      </Button>
    )
  } 

  function renderEditButton(){
    return (
      <IconButton
        className="cardEditIconButton formDetailCardIcon"
        onClick={props.onClickEdit}
        size="small"
      >
        <EditIcon color="primary" fontSize="small" />
      </IconButton>
    )
  }

  function renderDeleteButton() {
    return(
      <IconButton
        className="formDetailCardIcon cardDeleteIconButton"
        onClick={props.onClickDelete}
        size="small"
      >
        <DeleteIcon color="primary" fontSize="small" />
      </IconButton>
    )
  }

  function renderAddButton() {
    return(
      <Button
        color="primary"
        onClick={props.onClickCopy}
        disabled={props.loading}
      >
        Add
      </Button>
    )
  }

  function renderFillButton() {
    return (
      <Button
      color="primary"
      onClick={props.onFill}
      disabled={props.loading && props.isGlobalFormCard}
    >
      Fill
    </Button>
    )
  }

  function renderFormCardActions(){
    return (
      <CardActions
        className={
          props.isGlobalFormCard
            ? "formDetailCardActions"
            : props.onClickDelete
            ? "formDetailsCardActionsRight"
            : ""
        }
      >
        {props.isGlobalFormCard && renderViewButton()}
        <div>
          {props.onClickEdit && renderEditButton()}
          {props.onClickDelete && renderDeleteButton()}
          {props.onClickCopy && renderAddButton()}
          {props.onFill && renderFillButton()}
        </div>
      </CardActions>
    )
  }

  return (
    <>
      <Card
        className={
          props.isGlobalFormCard
            ? "formDetailsCardContainer"
            : "formDetailsCardContainer formDetailsCardHover"
        }
        variant="outlined"
      >
        <div
          className={props.isGlobalFormCard ? "" : "formDetailsCardActionArea"}
          onClick={props.isGlobalFormCard ? null : props.onClickPreview}
        >
          {renderFormFilledStatus()}
          {renderFormCardContent()}
          {renderFormCardActions()}
        </div>
      </Card>
    </>
  );
}
