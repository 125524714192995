import React from "react";
import ClientEditor from "../clientEditor/clientEditor";
import EnterpriseClientFilters from "../../components/filters/enterpriseClientFilters";
import { DB_KEYS, ROLES } from "../../constants";
import { Col } from "reactstrap";
import bodybuilder from "bodybuilder";

const TAB_OPTIONS = {
  REGISTER: 0,
  PENDING: 1,
  REQUEST: 2,
};

export default class ClientManagement extends ClientEditor {
  getHealthCoaches = async () => {
    try {
      let results = await window.FortisForma.database.getHealthCoaches();
      this.setState({
        healthCoaches: results,
      });
      return results;
    } catch (error) {
      throw error;
    }
  };

  // renderTabs() {
  //   return <></>;
  // }

  //staging filter working f9
  // prepareSearchFilters() {
  //   let filters = bodybuilder();
  //   let query = "";
  //   if (this.state.filters) {
  //     return this.state.filters;
  //   } else {
  //     filters.query("query_string", {
  //       query: "*" + query + "*",
  //       fields: ["name", "email"],
  //     });
  //     filters.filter("term", "role", ROLES.CLIENT);
  //     if (this.props.user.role === ROLES.FACILITY_ADMIN) {
  //       if (this.props.ppEnterprise) {
  //         filters.orFilter(
  //           "term",
  //           "enterpriseId",
  //           this.props.user.enterpriseId
  //         );
  //         filters.orFilter("term", "trainerId", this.props.ppEnterprise.id);
  //       } else {
  //         filters.orFilter(
  //           "term",
  //           "enterpriseId",
  //           this.props.user.enterpriseId
  //         );
  //       }
  //     } else {
  //       if (this.props.ppEnterprise) {
  //         filters.orFilter("term", "enterpriseId", this.props.ppEnterprise.id);
  //       } else {
  //         filters.orFilter(
  //           "term",
  //           "enterpriseId",
  //           this.props.user.enterpriseId
  //         );
  //       }
  //     }

  //     filters.orFilter("term", "trainerId", this.props.user.id);
  //   }
  //   if (this.props.user.role === ROLES.HEALTH_COACH) {
  //     let query = filters.build();
  //     return { coachId: this.props.user.id, ...query };
  //   } else {
  //     return filters.build();
  //   }
  // }

  //change staging filter due to version diff
  prepareSearchFilters() {
    let filters = bodybuilder();
    let query = "";
    if (this.state.filters) {
      return this.state.filters;
    } else {
      filters.query("query_string", {
        query: "*" + query + "*",
        fields: ["name", "email"],
      });
      filters.filter("term", "role", ROLES.CLIENT);
      if (
        this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.HEALTH_COACH
      ) {
        if (this.props.ppEnterprise) {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "enterpriseId", this.props.user.enterpriseId)
              .orFilter("term", "trainerId", this.props.ppEnterprise.id)
          );
        } else {
          filters.andFilter("bool", (q) =>
            q.orFilter("term", "enterpriseId", this.props.user.enterpriseId)
          );
        }
      } else {
        if (this.props.ppEnterprise) {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
              .orFilter("term", "trainerId", this.props.user.id)
          );
        } else {
          filters.andFilter("bool", (q) =>
            q
              .orFilter("term", "enterpriseId", this.props.user.enterpriseId)
              .orFilter("term", "trainerId", this.props.user.id)
          );
        }
      }
    }
    if (this.props.user.role === ROLES.HEALTH_COACH) {
      let query = filters.build();
      return { coachId: this.props.user.id, ...query };
    } else {
      return filters.build();
    }
  }

  // /*
  fetchClients = async (clear) => {
    try {
      if (clear) {
        this.enterpriseClientsFetcher.clearResults();
      }
      this.enterpriseClientsFetcher.abort();
      this.loading = true;
      let query = this.prepareSearchFilters();
      if (this.props.user && this.props.user.role === ROLES.HEALTH_COACH) {
        query.size = 100;
      }

      // let clients = await this.enterpriseClientsFetcher.fetch(query);

      let clients = [];
      if (this.state.filtersApplied && this.state.searchQuery) {
        clients = await this.enterpriseClientsFetcher.fetch(query, false, true);
      } else if (this.state.selectedTab === TAB_OPTIONS.REGISTER) {
        clients = await this.enterpriseClientsFetcher.fetch(query, false, true);
      } else if (this.state.selectedTab === TAB_OPTIONS.REQUEST) {
        // query = {};
        // query.collection = COLLECTIONS.REQUESTS;
        // query.filters = [
        //   {
        //     key: DB_KEYS.ACTIVE_STATUS,
        //     operator: "==",
        //     value: true,
        //   },
        //   {
        //     key: DB_KEYS.VISIBLE,
        //     operator: "==",
        //     value: true,
        //   },

        //   {
        //     key: DB_KEYS.TRAINER_ID_KEY,
        //     operator: "==",
        //     value: this.props.user.id,
        //   },
        // ];

        // if (this.state.searchQuery) {
        //   query.filters.push({
        //     key: "email",
        //     operator: "==",
        //     value: this.state.searchQuery,
        //   });
        // }

        // let pageConfig = {};
        // if (this.state.lastFetched) {
        //   pageConfig.orders = [
        //     {
        //       key: "name",
        //       after: this.state.lastFetched.name,
        //     },
        //   ];
        // } else {
        //   pageConfig.orders = [
        //     {
        //       key: "name",
        //     },
        //   ];
        // }

        // query.pageConfig = pageConfig;

        // results = await window.FortisForma.database.queryData(query);
        clients = [];
      } else if (this.state.selectedTab === TAB_OPTIONS.PENDING) {
        let pendingInvites = await this.invitesFetcher.fetch(query);
        for (let invite of pendingInvites) {
          invite.pendingLogin = true;
        }
        clients = pendingInvites;
      } else {
        throw Error("Invalid");
      }

      clients = clear ? clients : this.state.clients.concat(clients);
      const values = new Set();
      const uniqueClients = [];
      for (const singleClient of clients) {
        if (!values.has(singleClient.id)) {
          values.add(singleClient.id);
          uniqueClients.push(singleClient);
        }
      }
      clients = uniqueClients;
      clients.sort((a, b) => {
        let nameA = (a.name || "").toLowerCase();
        let nameB = (b.name || "").toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      this.loading = false;

      let lastFetched = clients[clients.length - 1] || this.state.lastFetched;

      if (
        this.state.filters &&
        this.state.filters.coachId &&
        this.state.filters.query.bool.must
      ) {
        let newClients = clients.filter(
          (client) =>
            client.name
              .toLowerCase()
              .includes(
                this.state.filters.query.bool.must.query_string.query.slice(
                  1,
                  -1
                )
              ) ||
            client.email
              .toLowerCase()
              .includes(
                this.state.filters.query.bool.must.query_string.query.slice(
                  1,
                  -1
                )
              )
        );
        this.setState({
          clients: newClients,
          lastFetched: lastFetched,
        });
        return newClients;
      } else {
        this.setState({
          clients,
          lastFetched: lastFetched,
        });
        return clients;
      }
    } catch (e) {
      this.loading = false;
      throw e;
    }
  };

  // */

  /*
          fetchClients = async (clear) => {
            try {
              if (clear) {
                this.enterpriseClientsFetcher.clearResults();
              }
              this.enterpriseClientsFetcher.abort();
              this.loading = true;
              let query = this.prepareSearchFilters();
              if (this.props.user && this.props.user.role === ROLES.HEALTH_COACH) {
                query.size = 100;
              }
              let clients = [];
              if (this.state.selectedTab === TAB_OPTIONS.REGISTER) {
                clients = await this.enterpriseClientsFetcher.fetch(query);
              }
              clients = clear ? clients : this.state.clients.concat(clients);
              const values = new Set();
              const uniqueClients = [];
              for (const singleClient of clients) {
                if (!values.has(singleClient.id)) {
                  values.add(singleClient.id);
                  uniqueClients.push(singleClient);
                }
              }
              clients = uniqueClients;
              clients.sort((a, b) => {
                let nameA = (a.name || "").toLowerCase();
                let nameB = (b.name || "").toLowerCase();
                if (nameA < nameB)
                  //sort string ascending
                  return -1;
                if (nameA > nameB) return 1;
                return 0;
              });
              this.loading = false;
        
              let lastFetched = clients[clients.length - 1] || this.state.lastFetched;
        
              this.setState({
                clients,
                lastFetched: lastFetched,
              });
              return clients;
            } catch (e) {
              this.loading = false;
              throw e;
            }
          };
        
          */

  renderSearch() {
    return (
      <Col id="clientsFilterContainer">
        <div id="clientFilterFields" style={{ maxWidth: 680 }}>
          <EnterpriseClientFilters
            ppEnterprise={this.props.ppEnterprise}
            ref={(ref) => {
              this.filtersRef = ref;
            }}
            filters={{
              [DB_KEYS.ENTERPRISE_ID]: this.props.user.enterpriseId,
              [DB_KEYS.ROLE]: ROLES.CLIENT,
            }}
            inclusive={true}
            onSetFilters={this.applyFilters}
            onCancel={this.clearFilters}
            fetchCoaches={this.getHealthCoaches}
            user={this.props.user}
          ></EnterpriseClientFilters>
        </div>
      </Col>
    );
  }
}
