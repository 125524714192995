import { Modal } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import PDFThumbnail from "../PDFThumbnail/PDFThumbnail";

export default class PreviewImage extends Component {
  render() {
    return (
      <React.Fragment>
        <Modal
          open={this.props.openPreviewModal}
          onClose={this.props.closePreviewModal}
          onClick={(e) => e.stopPropagation && e.stopPropagation()}
        >
          <div className="imagePreviewContainer">
            <div className="previewContentContainer">
              {window.isPDF(this.props.image) ? (
                <div className="previewModalPDFContainer">
                  <PDFThumbnail
                    className="previewPDFModal"
                    pageClassName="previewImage"
                    link={this.props.image}
                    preview={true}
                  />
                </div>
              ) : (
                <div className="previewModalImageContainer">
                  <img id="previewImage" alt="" src={this.props.image} />
                </div>
              )}
              <div id="previewCloseButton">
                <IconButton
                  color="inherit"
                  id="previewClose"
                  onClick={this.props.closePreviewModal}
                >
                  <CloseIcon color="inherit" fontSize="large" />
                </IconButton>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
