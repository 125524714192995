import React, { Component } from 'react';
import { Button, ModalBody, ModalFooter, Nav, NavItem, NavLink } from 'reactstrap';
import { COLLECTIONS, DB_KEYS } from '../../constants';
import ClientSelectionList from '../clientSelectionList';


export default class ClientSelectorModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      clients: [],
      filters: [],
      checkedClients: props.checkedClients || {},
      showPending: false
    }
    this.pageSize = 20;
    this.fetchClients = this.fetchClients.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }


  async fetchClients(query, clear) {
    let currentUser = this.props.user;
    if (!currentUser) {
      throw new Error("User not logged in");
    }

    if (!query) {
      query = {
        filters: []
      }
    }

    if (!query.filter) {
      query.filters = [];
    }

    query.collection = COLLECTIONS.USER_DATA;
    query.filters = query.filters.concat(this.state.filters);
    query.filters = [{
      key: DB_KEYS.TRAINER_ID_KEY,
      operator: "==",
      value: currentUser.id
    }].concat(query.filters);

    if (this.props.filters) {
      query.filters = query.filters.concat(this.props.filters);
    }

    query.pageConfig = {
      limit: 20
    }

    if (this.state.lastFetched) {
      query.pageConfig.orders = [{
        key: "name",
        after: this.state.lastFetched.name
      }]
    } else {
      query.pageConfig.orders = [{
        key: "name"
      }]
    }

    try {
      let results;
      if (!this.state.showPending) {
        results = await window.FortisForma.database.queryData(query);
      } else {
        query.collection = COLLECTIONS.INVITES;
        let pendingInvites = await window.FortisForma.database.queryData(query);
        for (let invite of pendingInvites) {
          invite.pendingLogin = true;
        }
        results = pendingInvites;
      }

      let clients = clear ? results : this.state.clients.concat(results);
      clients && clients.sort((a, b) => {
        let nameA = (a.name || '').toLowerCase();
        let nameB = (b.name || '').toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      })

      this.setState({
        lastFetched: results[results.length - 1],
        clients
      })
      return clients;
    } catch (e) {
      console.error(e)
      throw e;
    }
  }

  emptyPlaceholder = () => {
    return <div>
      <div>
        No clients found matching your criteria
      </div>

      {
        this.state.filters.length ?
          (
            <div className="filtersSearch internal">
              <Button className="clearFiltersButton" color="primary" onClick={this.clearFilters}>Clear Filters</Button>
            </div>
          ) : null
      }

    </div>
  }


  onSelect(selectedClient) {
    let newCheckClient = Object.assign({}, this.state.checkedClients)
    let value = { pendingLogin: selectedClient.pendingLogin }
    if (newCheckClient[selectedClient.id]) {
      delete newCheckClient[selectedClient.id]
    } else {
      newCheckClient[selectedClient.id] = value;
    }

    this.setState({
      checkedClients: newCheckClient
    })
  }

  toggle = () => {
    this.setState({
      clients: [],
      lastFetched: null,
      showPending: !this.state.showPending
    }, () => {
      this.listRef.reset(true);
    })
  }

  onClickConfirm = () => {
    let newCheckClient = Object.assign({}, this.state.checkedClients)
    this.props.onConfirm(newCheckClient);
  }
  renderTabContentModal() {
    return (
      <div id="clientSelectorModal">
        {this.renderModalList(this.state.showPending)}
      </div>
    )
  }

  renderModalList() {
    return (
      <div id="clientSelectionList" style={{ flex: 1 }}>
        <ClientSelectionList ref={(r) => {
          this.listRef = r;
        }} data={this.state.clients} autoSize={true}
          padding={[0, 0]} containerId={"clientSelectionList"} rowHeight={50} columnCount={1} checkedClients={this.state.checkedClients} onRemove={this.removeClient} onSelect={this.onSelect} loadData={this.fetchClients} pageSize={this.pageSize}
        />
      </div>
    )
  }


  render() {
    return (
      <React.Fragment>
        <ModalBody style={{ padding: 0 }}>


          <React.Fragment>
            <Nav tabs className="clientSelectorModalTabs">
              <NavItem>
                <NavLink
                  className={`clientTabsLink ${this.state.showPending ? '' : 'active'}`}
                  onClick={this.toggle}
                >
                  Registered
                  </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`clientTabsLink ${!this.state.showPending ? '' : 'active'}`}
                  onClick={this.toggle}
                >
                  Pending
                  </NavLink>
              </NavItem>
            </Nav>

            {this.renderTabContentModal()}

          </React.Fragment>


        </ModalBody>
        <ModalFooter style={{ marginTop: "14px" }}>
          <Button className="btn btn-outline-secondary btn btn-secondary" onClick={this.props.onCancel} >Cancel</Button>
          <Button onClick={this.onClickConfirm} >Confirm</Button>
        </ModalFooter>
      </React.Fragment>
    )
  }
}