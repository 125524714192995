import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TrainerExerciseEditCard from "../../components/exerciseEditCard/trainerExerciseEditCard";
import ExerciseList from "../exerciseList/exerciseList.js";
import ExerciseEditor from "./exerciseEditor";
import Dialog from "@material-ui/core/Dialog";
import ExerciseDesigner from "../../components/exerciseDesigner/exerciseDesigner";
import { DB_KEYS } from "../../constants";

export default class TrainerExerciseEditor extends ExerciseEditor {
  constructor(props) {
    super(props);
    this.state = Object.assign(
      {
        newExerciseFilters: null,
        createExercise: true,
        newExerciseModal: false,
        withBasicFilters: false,
        basicFilters: this.createBasicFilters(),
        trainerId: this.props.user.trainerId,
      },
      this.state
    );
  }

  componentDidMount() {
    let querySearchParams = this.props.location.search;
    let params = new URLSearchParams(querySearchParams);
    let urlFilters = {};
    for (let param of params.keys()) {
      if (param === "mode") continue;
      urlFilters[param] = params.get(param);
    }

    this.setState({
      newExerciseFilters: urlFilters,
    });

    let isInEditMode = params.get("mode") === "create";
    if (isInEditMode) {
      this.setState({
        createExercise: true,
        newExerciseModal: true,
      });
    }
  }

  routeToDesigner = () => {
    let filters = Object.assign({}, this.state.newExerciseFilters);
    let params = null;
    if (filters) {
      params = "/?";
      for (let filter of Object.keys(filters)) {
        if (filters[filter]) {
          params = `${params}${filter}=${filters[filter]}&`;
        }
      }
      params = params.slice(0, -1);
    }

    this.props.history.replace(`/workout-designer${params}`);
  };

  onConfirm = (alternateNames) => {
    if (!this.state.selected) {
      return;
    }
    let id = this.state.selected.id;
    let exercise = Object.assign({}, this.state.selected);
    this.setState({
      newExerciseModal: false,
      selected: null,
    });
    let data = this.state.data;
    let index = window._.findIndex(this.state.data, (item) => item.id === id);
    if (index < 0) {
      return;
    }
    exercise.alternateNames = exercise.alternateNames || [];
    exercise.alternateNames = exercise.alternateNames.concat(alternateNames);
    data[index] = exercise;
    this.setState({
      data: data,
    });
  };

  onClickCancel = () => {
    this.filtersRef.applyTrainerFilter();
    this.setState({
      newExerciseModal: false,
      selected: null,
    });

    if (
      this.state.newExerciseFilters &&
      Object.keys(this.state.newExerciseFilters).length
    ) {
      this.routeToDesigner();
    }
  };

  onClickSave = (exercise) => {
    let exercises = Object.assign([], this.state.data);
    let index = exercises.findIndex((ex) => ex.id === exercise.id);
    if (index < 0) {
      exercises.push(exercise);
    } else {
      exercises[index] = exercise;
    }
    this.setState({
      data: exercises,
    });
    if (
      this.state.newExerciseFilters &&
      Object.keys(this.state.newExerciseFilters).length
    ) {
      this.routeToDesigner();
    }
  };

  onDelete = (id, closeEditor = false) => {
    let exercises = Object.assign([], this.state.data);
    let index = exercises.findIndex((ex) => ex.id === id);
    if (index > -1) {
      exercises.splice(index, 1);
    }
    this.setState({
      data: exercises,
    });
    if (closeEditor) {
      this.onClickCancel();
    }
  };

  closeExerciseDesignerModal = () => {
    this.setState({
      newExerciseModal: false,
    });
  };

  onClickCancelAlternateModal = () => {
    this.setState({
      selected: null,
    });
  };

  renderContent() {
    return (
      <React.Fragment>
        {this.state.selected &&
          this.state.selected.trainerId === DB_KEYS.ADMIN && (
            <Modal isOpen={true} backdrop={true}>
              <ModalHeader>Exercise Editor</ModalHeader>
              <ModalBody>
                <TrainerExerciseEditCard
                  user={this.props.user}
                  data={this.state.selected}
                  onClickCancel={this.onClickCancelAlternateModal}
                  onConfirm={this.onConfirm}
                  onDelete={this.onDelete}
                  onClickSave={this.save}
                />
              </ModalBody>
            </Modal>
          )}

        {this.state.createExercise && (
          <Dialog
            fullScreen
            open={Boolean(
              this.state.newExerciseModal ||
                (this.state.selected &&
                  this.state.selected.trainerId !== DB_KEYS.ADMIN)
            )}
            onClose={this.onClickCancel}
          >
            <ExerciseDesigner
              onDelete={this.onDelete}
              user={this.props.user}
              data={this.state.selected}
              onClickSave={this.onClickSave}
              onClose={this.onClickCancel}
              newExerciseFilters={this.state.newExerciseFilters}
            />
          </Dialog>
        )}

        <div id="exerciseEditor">
          <ExerciseList
            ref={(r) => {
              this.listRef = r;
            }}
            data={this.state.data}
            autoSize={true}
            padding={[0, 0]}
            containerId={"exerciseEditor"}
            rowHeight={296}
            columnWidth={220}
            columnCount={3}
            selectEnabled={true}
            onSelect={this.onSelect}
            selectButtonView={<i className="material-icons smallIcon">edit</i>}
            loadData={this.fetchData}
            basicFilters={this.state.basicFilters}
            newExerciseModal={this.state.newExerciseModal}
            pageSize={this.pageSize}
            emptyPlaceholder={this.emptyPlaceholder()}
          ></ExerciseList>
        </div>
      </React.Fragment>
    );
  }
}
