import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  opacity: isDragging ? 0.4 : 1,
  backgroundColor: isDragging ? "#F9F3F4" : "transparent",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  width: "100%",
  backgroundColor: isDraggingOver ? "#eeeeee" : "transparent",
});

export default class DNDList extends Component {
  itemRenderer = (value, itemIndex) => {
    return this.props.itemRenderer({
      value,
      itemIndex,
      sectionIndex: this.props.listIndex,
    });
  };

  render() {
    return (
      <Droppable droppableId={`droppable${this.props.listIndex}`} type="CARD">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            style={{ flexGrow: 1, minHeight: 100 }}
          >
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="droppableSection"
            >
              {this.props.items.length > 0 ? (
                this.props.items.map((item, index) => {
                  return (
                    <Draggable
                      key={`item-${index}${this.props.listIndex}${item.id}`}
                      draggableId={`item-${index}${this.props.listIndex}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {this.itemRenderer(item, index)}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })
              ) : (
                <div className="sectionPlaceholder">
                  Press '+' on an exercise to add it to this section
                </div>
              )}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    );
  }
}
