import React, { Component } from "react";
import { FormGroup } from "reactstrap";
import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect.js";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Factory from "../../modules/http/dataFetcher";
import { EQUIPMENT_TYPES, DB_KEYS } from "../../constants";
import TAButton from "../taButton/taButton.js";
import hash from "object-hash";

const MODE = {
  Movement: 0,
  Equipment: 1,
  Function: 2,
};

export default class CreateEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      value: "",
      equipmentType: EQUIPMENT_TYPES.FREEWEIGHT,
      movementCategories: [],
      mode: 0,
      saving: false,
    };
  }

  componentDidMount() {
    let label;
    if (this.props.entity.includes("movement")) {
      label = "Movement";
      this.fetchMovementCategories();
    }
    if (this.props.entity.includes("Equipment")) {
      label = "Equipment";
    }
    if (this.props.entity.includes("Function")) {
      label = "Function";
    }
    let mode = MODE[label];
    this.setState({
      label,
      mode,
      value: this.props.entityValue.value,
    });
  }

  async fetchMovementCategories() {
    try {
      let data = await Factory.movementCategoryFetcher().fetch();
      this.setState({
        originalMovementCategories: data,
      });
      data = data.map((category) => {
        return category.name;
      });
      data.sort();
      this.setMovementCategories(data);
    } catch (e) {
      throw e;
    }
  }

  setMovementCategories(data) {
    data = window._.uniq(data);
    data = data.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    this.setState({
      movementCategories: data,
    });
  }

  onChangeValue = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  onCreate = async () => {
    this.setState({
      nextPressed: true,
    });
    if (!this.state.value) {
      return;
    }
    this.setState({
      nextPressed: false,
    });
    let data = {
      name: this.state.value,
    };
    if (this.state.mode === MODE.Equipment) {
      if (!this.state.equipmentType) {
        window.NotificationUtils.showError("Please select an equipment type");
        return;
      }
      data.type = this.state.equipmentType;
    }
    if (this.state.mode === MODE.Movement) {
      if (
        !this.state.movementCategory ||
        !Object.keys(this.state.movementCategory).length
      ) {
        window.NotificationUtils.showError("Please select a movement category");
        return;
      }
      data[DB_KEYS.MOVEMENT_ID] = hash(this.state.movementCategory.value);
      data[DB_KEYS.MOVEMENT_CATEGORY] = this.state.movementCategory.value;
    }
    this.setState({ saving: true });
    await this.props.onCreate(this.props.entity, data);
    this.setState({ saving: false });
  };

  handleMovementCategorySelect = (selectedOption) => {
    this.setState({
      movementCategory: selectedOption,
    });
  };

  handleTypeChange = (selectedOption) => {
    this.setState({
      equipmentType: selectedOption,
    });
  };

  renderEquipmentTypeSelect() {
    return (
      <TextField
        select
        style={{ width: "100%" }}
        variant="outlined"
        value={this.state.equipmentType}
        onChange={(e) => this.handleTypeChange(e.target.value)}
        label="Equipment Type"
      >
        <MenuItem value={EQUIPMENT_TYPES.FREEWEIGHT}>Free weight</MenuItem>
        <MenuItem value={EQUIPMENT_TYPES.RESISTANCE}>Resistance</MenuItem>
        <MenuItem value={EQUIPMENT_TYPES.SUPPORT}>Support</MenuItem>
      </TextField>
    );
  }

  renderMovementCategorySelect() {
    return (
      <FormGroup>
        <FortisFormaSelect
          id="movementCategory"
          isClearable={true}
          className="dropdownHeight"
          placeholder="Movement Category"
          value={this.state.movementCategory}
          classNamePrefix="fortisFormaDropDownSelect"
          menuPortalTarget={document.getElementById("createEntityModal")}
          onChange={this.handleMovementCategorySelect}
          options={this.state.movementCategories}
        />
      </FormGroup>
    );
  }

  render() {
    return (
      <React.Fragment>
        <DialogTitle>Create {this.state.label}</DialogTitle>
        <DialogContent style={{ marginTop: 2 }}>
          <TextField
            error={this.state.nextPressed && !this.state.name}
            className="textFieldFullWidth marginBottom"
            style={{ minWidth: 400 }}
            onChange={this.onChangeValue}
            placeholder={this.state.label}
            variant="outlined"
            value={this.state.value}
          />
          {this.state.mode === MODE.Equipment &&
            this.renderEquipmentTypeSelect()}
          {this.state.mode === MODE.Movement &&
            this.renderMovementCategorySelect()}
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              className="marginRight"
              variant="contained"
              color="secondary"
              onClick={this.props.onClose}
            >
              Close
            </Button>
            <TAButton
              variant="contained"
              color="primary"
              onClick={this.onCreate}
              isLoading={this.state.saving}
              loadingMessage="Creating"
            >
              Create
            </TAButton>
          </div>
        </DialogActions>
      </React.Fragment>
    );
  }
}
