import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export default class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderUploadingImage = () => {
    return (
      <React.Fragment>
        <div className="uploadImageContainer uploadImageContainer">
          <img
            src={this.getImageSrc() || this.props.posterURL}
            width="100%"
            alt="imagePreview"
          />
          <div className="uploadIconContainer imageActions">
            {this.props.imageProgress < 100 ? this.renderImageLoading() : null}
          </div>
        </div>
      </React.Fragment>
    );
  };

  getImageSrc = () => {
    if (!this.props.posterURL) {
      let imageData = new File([this.props.imageData], "image");
      let src = URL.createObjectURL(imageData);
      return src;
    }
    return false;
  };

  renderDelete = () => {
    return (
      <div>
        <IconButton size="small" onClick={this.deleteImage}>
          <DeleteIcon fontSize="small" className="imageActionButton" />
        </IconButton>
      </div>
    );
  };

  renderImageLoading = () => {
    return (
      <CircularProgress
        size={16}
        variant="static"
        id="uploadingImageCircularProgress"
        value={this.props.imageProgress}
      />
    );
  };

  render() {
    return <React.Fragment>{this.renderUploadingImage()}</React.Fragment>;
  }
}
