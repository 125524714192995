import React from "react";
import SectionedFeedbackCard from "./sectionedFeedbackCard";
import { WORKOUT_SECTIONS_NAMES } from "../../constants";

export default class FeedbackSection extends React.Component {
  render() {
    let section = this.props.section;
    return (
      <div
        className="viewWorkoutSection"
        onClick={(e) => e.stopPropagation && e.stopPropagation()}
      >
        <div className="viewWorkoutSectionHeader">
          <div className="viewWorkoutSectionName">
            {`${section.workoutType}${
              section.workoutType === WORKOUT_SECTIONS_NAMES.CIRCUIT
                ? ` ${
                    this.props.firstSection.workoutType ===
                    WORKOUT_SECTIONS_NAMES.CIRCUIT
                      ? this.props.listIndex + 1
                      : this.props.listIndex
                  }`
                : ""
            }`}
            <div className="viewSetsCounter" style={{ display: "none" }}>
              {section.sets && `x ${section.sets}`}
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          {section.exercises &&
            section.exercises.length > 0 &&
            section.exercises.map((exercise, index) => {
              let loggedSection = this.props.loggedData[
                this.props.loggedWorkoutId
              ][this.props.listIndex];
              let logged = loggedSection && loggedSection[index];
              if (!logged || (logged && !logged.feedback)) {
                // REVIEW: Render not completed view
                return (
                  <div
                    key={index}
                    className="sectionedExerciseCard"
                    id="feedbackNotLogged"
                  >
                    Feedback not logged
                  </div>
                );
              }
              return (
                <SectionedFeedbackCard
                  key={index}
                  index={index}
                  data={logged}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
