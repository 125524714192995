import React, { Component } from "react";
import ImageUploaderSection from "./imageUploaderSection";
import UploadedImage from "./uploadedImage";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SelectableUploadedImage from "./selectedableUploadedImage";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f0f0f0" : "white",
  display: "flex",
  overflow: "hidden",
  flexWrap: "wrap",
  width: "100%",
});

export default class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileForUpload: null,
      selectedImages: props.selectedImages || [],
    };
  }

  onUploadComplete = () => {
    this.setState({
      selectedFileForUpload: null,
    });
  };

  selectedFilesForUpload = (file) => {
    let files = Array.from(file);
    this.setState(
      {
        selectedFileForUpload: files,
      },
      () => this.props.disableSaveButton()
    );
  };

  removeImageLocally = (index) => {
    let copySelectedImages = [...this.props.selectedImages];
    copySelectedImages.splice(index, 1);
    this.props.onChange && this.props.onChange(copySelectedImages);
  };

  onDone = (selectedImages) => {
    this.props.onChange && this.props.onChange(selectedImages);
  };

  renderUploaderSection = () => {
    return (
      <ImageUploaderSection
        onDone={this.onDone}
        selectedImages={this.props.selectedImages || []}
        onUploadComplete={this.onUploadComplete}
        onFileChange={this.selectedFilesForUpload}
        selectedFileForUpload={this.state.selectedFileForUpload}
        disableDoneButton={this.state.disableDoneButton}
        pathToStore={this.props.pathToStore}
        extensions={this.props.extensions && this.props.extensions}
      />
    );
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let images = this.reorder(
      this.props.selectedImages,
      result.source.index,
      result.destination.index
    );
    this.props.onChange && this.props.onChange(images);
  };

  renderImages = () => {
    const UploadedImageComponent = this.props.uploadedImageType
      ? SelectableUploadedImage
      : UploadedImage;
    let images = this.props.selectedImages || [];
    if (this.props.isDraggable) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {images.map((item, index) => (
                  <Draggable
                    key={item.url}
                    draggableId={item.url}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <UploadedImage
                          key={index}
                          image={item.url}
                          index={index}
                          removeImageLocally={this.removeImageLocally}
                          setPreviewOpened={this.props.setPreviewOpened || null}
                          hideRemove={this.props.hideRemove || false}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
    return images.map((item, index) => {
      return (
        <UploadedImageComponent
          key={index}
          image={item.url}
          index={index}
          removeImageLocally={this.removeImageLocally}
          setPreviewOpened={this.props.setPreviewOpened || null}
          hideRemove={this.props.hideRemove || false}
          onDelete={this.props.onDelete || null}
          imageId={item.id || null}
          onSelect={() => this.props.onSelect(item)}
          selectedImage={
            (this.props.checkedImages && this.props.checkedImages[0]) || ""
          }
        />
      );
    });
  };

  render() {
    return (
      <div className="imageUploadMainContainer">
        <div className="selectedImagesContainer">
          {this.props.selectedImages !== null && this.renderImages()}
        </div>
        {this.renderUploaderSection()}
      </div>
    );
  }
}
