import React, {Component} from 'react'
import { Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


export default class LevelDecider extends Component{
    constructor(props){
        super(props)
        this.state={
            currentValue: +props.currentValue + 1,
            lastUpdatedValue: +props.currentValue + 1
        }
    }

    decreaseLevel = () => {
        if(+this.state.currentValue === +this.state.lastUpdatedValue){
            return
        }else{
            this.setState({
                currentValue : this.state.currentValue - 1,
            })
        }
    }

    increaseLevel = () => {
        this.setState({
            currentValue : this.state.currentValue + 1,
        })
    }

    onDoneClick = () => {
        let selectedLevel = this.state.currentValue
        this.props.onDoneClick(selectedLevel)
        this.props.onClickCancel()
    }

    render(){
        return(
            <React.Fragment>
                <DialogTitle>
                    {this.props.editMode ? "Change exercise level" : "New Level"}
                </DialogTitle>
                 <DialogContent>
                    <div id="levelDeciderContainer">
                        <Button className="marginRight" variant="contained" color="secondary" onClick={this.decreaseLevel}><RemoveIcon size="small" /></Button>
                        <div id="levelDeciderContent">{`Level ${this.state.currentValue}`}</div>
                        <Button className="marginLeft" variant="contained" color="secondary" onClick={this.increaseLevel}><AddIcon size="small" /></Button>
                    </div>
               </DialogContent>
               <DialogActions >
                    <div>
                        <Button className="marginRight" variant="contained" color="secondary" onClick={this.props.onClickCancel}>Close</Button>
                        <Button variant="contained" color="primary" onClick={this.onDoneClick} >Done</Button>
                    </div>
                </DialogActions>
            </React.Fragment>
        )
    }
}