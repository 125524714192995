import React from "react";
import WorkoutSection from "./workoutSection";
import ExerciseCard from "./exerciseCard";
import { Button, Dialog } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NoteEditor from "../noteAssessments/noteAssessmentEditor/noteEditor";
import {
  getClientFormsdata,
  updateClientForms,
} from "../../views/enterpriseClientDetails/noteAssessmentCommon";
import { FORM_TYPES } from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckCircle from "@material-ui/icons/CheckCircle";

export default class SectionedWorkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExercise: null,
      selectedExerciseIndex: null,
      selectedSectionIndex: null,
      openExerciseCard: false,
      openExerciseDetailModal: false,
      showAddNote: false,
      selectedExerciseLogIndex: null,
      nextBtnStatus: false,
      previousBtnStatus: false,
      circuitChange: { status: false, currentSec: "", prevSec: "" },
      modalTop: "",
      modalBottom: "",
    };
    this.currentDayUnix = window.moment().startOf("day").unix();
    this.editorRef = React.createRef();
    this.workoutSectionRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      selectedExercise: this.props.workoutSection[0].data[0].exercise,
      selectedExerciseIndex: 0,
      selectedSectionIndex: 0,
      logId: this.props.workoutSection[0].data[0].logId,
    });
  };
  // componentWillMount=()=>{
  //   if(this.props.showCompleteModal){
  //     this.setState({
  //       circuitChange:{status:false}
  //     })
  //   }
  // }
  componentDidUpdate = (prevProps) => {
    if (
      Number(this.state.selectedSectionIndex) === 0 &&
      Number(this.state.selectedExerciseIndex) === 0 &&
      (prevProps.workoutLog !== this.props.workoutLog ||
        prevProps.workoutLog.sets !== this.props.workoutLog.sets ||
        prevProps.workoutLog.reps !== this.props.workoutLog.reps ||
        prevProps.workoutLog.weight !== this.props.workoutLog.weight ||
        prevProps.workoutLog.time !== this.props.workoutLog.time)
    ) {
      this.setState({
        selectedExercise: this.props.workoutSection[0].data[0].exercise,
        selectedExerciseIndex: 0,
        selectedSectionIndex: 0,
        logId: this.props.workoutSection[0].data[0].logId,
      });
    }

    if (this.props.showCompleteModal && this.state.circuitChange.status) {
      this.setState({
        circuitChange: { status: false },
        openExerciseDetailModal: false,
      });
    }
  };

  onClickExerciseMiniCard = (
    exercise,
    sectionIndex,
    exerciseIndex,
    logId,
    logIndex
  ) => {
    let workout = this.props.workoutSection;

    if (sectionIndex === 0 && exerciseIndex === 0) {
      this.setState({
        previousBtnStatus: true,
        nextBtnStatus: false,
        circuitChange: { status: false },
      });
    } else {
      this.setState({
        previousBtnStatus: false,
        nextBtnStatus: false,
        circuitChange: { status: false },
      });
    }
    this.setState({
      selectedExercise: exercise,
      selectedSectionIndex: sectionIndex,
      selectedExerciseIndex: exerciseIndex,
      logId: logId,
      openExerciseDetailModal: true,
      selectedExerciseLogIndex: logIndex,
    });
  };

  onClickCloseExerciseCard = () => {
    this.setState({
      openExerciseCard: false,
    });
  };

  onClickExerciseDetail = () => {
    this.setState({
      openExerciseDetailModal: true,
    });
  };

  onClickCloseEditExerciseModal = () => {
    this.setState({
      openExerciseDetailModal: false,
    });
  };

  handleCloseEditor = () => {
    this.setState({
      showAddNote: false,
    });
  };

  onCloseEditor = () => {
    if (this.editorRef.current && this.editorRef.current.confirmClose) {
      return this.editorRef.current.confirmClose(this.handleCloseEditor);
    }
  };

  updateSelectedExerciseFromParent = (sectionIndex, exerciseIndex) => {
    if (!this.props.workoutSection[sectionIndex]) {
      return;
    }
    if (!this.props.workoutSection[sectionIndex].data[exerciseIndex]) {
      return;
    }
    let exercise =
      this.props.workoutSection[sectionIndex].data[exerciseIndex].exercise;
    let logId =
      this.props.workoutSection[sectionIndex].data[exerciseIndex].logId;
    this.setState({
      selectedExercise: exercise,
      selectedExerciseIndex: exerciseIndex,
      selectedSectionIndex: sectionIndex,
      logId: logId,
      selectedExerciseLogIndex:
        this.props.workoutSection[sectionIndex].data[exerciseIndex].index,
    });
  };

  onAddNoteClick = () => {
    this.setState({
      showAddNote: true,
    });
  };

  fetchClientForms = async () => {
    try {
      let forms = await window.FortisForma.database.fetchClientForms({
        clientId: this.props.clientId,
        email: this.props.clientEmail || "",
      });
      forms = forms[FORM_TYPES.NOTES] || [];
      return forms;
    } catch (e) {
      throw e;
    }
  };

  onAddNote = async (noteData) => {
    try {
      let submissionData = { ...noteData.submissionData } || null;
      const result = await window.FortisForma.database.saveNoteData(noteData);
      if (result.formId) {
        const forms = await this.fetchClientForms();
        await updateClientForms(
          submissionData,
          result,
          forms,
          FORM_TYPES.NOTES
        );
      }
      let logData = {
        clientId: this.props.clientId,
        programId: this.props.programId,
        logDate: window.workoutLogsKey(window.moment.unix(this.currentDayUnix)),
        note: result.id,
        workoutId: this.props.workoutId,
      };
      await window.FortisForma.database.saveWorkoutLogMeta(logData);
    } catch (e) {
      throw e;
    }
  };

  deletePendingForm = async (formId) => {
    try {
      let forms = await this.fetchClientForms();
      forms = forms.filter((f) => f.formId !== formId);
      const updatedData = getClientFormsdata(
        forms,
        this.props.clientId,
        FORM_TYPES.NOTES,
        this.props.clientEmail
      );
      await Promise.all([
        window.FortisForma.database.updateClientFormsData(updatedData),
        window.FortisForma.database.removeForm(formId),
      ]);
    } catch (e) {
      throw e;
    }
  };

  getFeedbackLog = () => {
    const { logId } = this.state;
    const { workoutLog } = this.props && this.props;
    let split = logId && logId.split("_");
    if (split) {
      let index = split[3];

      if (split && workoutLog && workoutLog[index]) {
        return workoutLog[index] && workoutLog[index].feedback;
      }
    } else {
      return {};
    }
  };

  onPressNext(sectionIndex, exerciseIndex) {
    let workouts = this.props.workoutSection;

    if (
      workouts[sectionIndex].data.length - 1 === exerciseIndex &&
      sectionIndex !== workouts.length - 1 &&
      !this.state.circuitChange.status
    ) {
      this.setState({
        circuitChange: { status: true },
        modalTop: sectionIndex,
        modalBottom: sectionIndex + 1,
        previousBtnStatus: false,
      });
      this.props.addSectionLog(workouts[sectionIndex]);

      // this.workoutSectionRef.current.markExercises();
    } else if (this.state.circuitChange.status) {
      this.setState({
        selectedExercise: workouts[sectionIndex + 1].data[0].exercise,
        selectedSectionIndex: sectionIndex + 1,
        selectedExerciseIndex: 0,
        selectedExerciseLogIndex: workouts[sectionIndex + 1].data[0].index,
        logId: workouts[sectionIndex + 1].data[0].logId,
        circuitChange: { status: false },
      });
    } else if (
      workouts[sectionIndex].data.length - 1 === exerciseIndex &&
      sectionIndex === workouts.length - 1 &&
      !this.state.circuitChange.status
    ) {
      this.setState({
        circuitChange: { status: true },
        previousBtnStatus: false,
        nextBtnStatus: true,
        modalTop: sectionIndex,
      });
      this.props.addSectionLog(workouts[sectionIndex]);
    } else {
      if (this.state.selectedExerciseIndex + 1 !== 0) {
        this.setState({
          previousBtnStatus: false,
          nextBtnStatus: false,
        });
      }
      this.setState({
        selectedExercise:
          workouts[sectionIndex].data[exerciseIndex + 1].exercise,
        selectedSectionIndex: sectionIndex,
        selectedExerciseIndex: exerciseIndex + 1,
        selectedExerciseLogIndex:
          workouts[sectionIndex].data[exerciseIndex + 1].index,
        logId: workouts[sectionIndex].data[exerciseIndex + 1].logId,
      });
    }
    if (this.props.workoutComplete) {
      this.setState({
        circuitChange: { status: false },
      });
    }
  }

  onPressPrevious(sectionIndex, exerciseIndex) {
    let workouts = this.props.workoutSection;
    if (sectionIndex === 0 && exerciseIndex === 0) {
      this.setState({
        previousBtnStatus: true,
        nextBtnStatus: false,
      });
    } else {
      // if (sectionIndex === 0 && exerciseIndex-1 === 0) {
      //   console.log("xxx2");
      //   this.setState({
      //     previousBtnStatus: false,
      //     nextBtnStatus: false,
      //   });
      // } else {
      this.setState({
        previousBtnStatus: false,
        nextBtnStatus: false,
      });
      // }
    }

    if (
      exerciseIndex === 0 &&
      sectionIndex !== 0 &&
      !this.state.circuitChange.status
    ) {
      let index = workouts[sectionIndex - 1].data.length - 1;
      this.setState({
        selectedExercise: workouts[sectionIndex - 1].data[index].exercise,
        selectedSectionIndex: sectionIndex - 1,
        selectedExerciseIndex: index,
        selectedExerciseLogIndex: workouts[sectionIndex - 1].data[index].index,
        logId: workouts[sectionIndex - 1].data[index].logId,
        circuitChange: { status: true },
        modalTop: sectionIndex - 1,
        modalBottom: sectionIndex,
      });
    } else if (this.state.circuitChange.status) {
      this.setState({
        selectedExercise: workouts[sectionIndex].data[exerciseIndex].exercise,
        selectedSectionIndex: sectionIndex,
        selectedExerciseIndex: exerciseIndex,
        selectedExerciseLogIndex:
          workouts[sectionIndex].data[exerciseIndex].index,
        logId: workouts[sectionIndex].data[exerciseIndex].logId,
        circuitChange: { status: false },
      });
    } else {
      if (sectionIndex === 0 && exerciseIndex - 1 === 0) {
        this.setState({
          previousBtnStatus: true,
          nextBtnStatus: false,
        });
      }
      this.setState({
        selectedExercise:
          workouts[sectionIndex].data[exerciseIndex - 1].exercise,
        selectedSectionIndex: sectionIndex,
        selectedExerciseIndex: exerciseIndex - 1,
        selectedExerciseLogIndex:
          workouts[sectionIndex].data[exerciseIndex - 1].index,
        logId: workouts[sectionIndex].data[exerciseIndex - 1].logId,
      });
    }
  }

  renderForward(sectionIndex, exerciseIndex) {
    return (
      <IconButton
        className="modalArrow"
        size="large"
        onClick={() => this.onPressNext(sectionIndex, exerciseIndex)}
        disabled={this.state.nextBtnStatus}
      >
        <ArrowForwardIosIcon color={"primary"} fontSize="large" />
      </IconButton>
    );
  }

  renderBackward(sectionIndex, exerciseIndex) {
    return (
      <IconButton
        className="modalArrow"
        size="large"
        onClick={() => this.onPressPrevious(sectionIndex, exerciseIndex)}
        disabled={this.state.previousBtnStatus}
      >
        <ArrowBackIosIcon color={"primary"} fontSize="large" />
      </IconButton>
    );
  }

  renderPrevCircuitName() {
    let workouts = this.props.workoutSection;
    if (workouts[this.state.modalTop].workoutType === "Circuit") {
      return (
        <h2>
          {workouts[this.state.modalTop].workoutType}{" "}
          {workouts[this.state.modalTop].index}
        </h2>
      );
    } else {
      return <h2>{workouts[this.state.modalTop].workoutType} </h2>;
    }
  }

  renderCircuitModal(openModal) {
    let workouts = this.props.workoutSection;
    return (
      <Dialog
        open={openModal}
        onClose={this.onClickCloseEditExerciseModal}
        maxWidth="lg"
        disableBackdropClick={true}
        className="clienCircuitModal clientWorkModal"
      >
        {this.renderBackward(
          this.state.selectedSectionIndex,
          this.state.selectedExerciseIndex
        )}
        <div className="circuitCont">
          {this.renderPrevCircuitName()}
          <h2>Complete</h2>
          <CheckCircle color={"white"} fontSize="large" />
          {workouts.length - 1 === this.state.selectedSectionIndex ? null : (
            <div className="nextUp">
              <p>Nextup:</p>
              {workouts[this.state.modalBottom].workoutType == "Circuit" ? (
                <p>
                  {workouts[this.state.modalBottom].workoutType}{" "}
                  {workouts[this.state.modalBottom].index}
                </p>
              ) : (
                <p>{workouts[this.state.modalBottom].workoutType}</p>
              )}
            </div>
          )}

          <button
            onClick={this.onClickCloseEditExerciseModal}
            className="closeModalBtn"
          >
            Close
          </button>
        </div>
        {this.renderForward(
          this.state.selectedSectionIndex,
          this.state.selectedExerciseIndex
        )}
      </Dialog>
    );
  }

  renderSelectedExerciseCard() {
    const {
      openExerciseDetailModal,
      selectedExerciseLogIndex,
      selectedExercise,
      logId,
    } = this.state;

    return (
      <div id="homePageDetailContainer">
        {this.state.circuitChange.status ? (
          this.renderCircuitModal(Boolean(openExerciseDetailModal))
        ) : (
          <Dialog
            open={Boolean(openExerciseDetailModal)}
            onClose={this.onClickCloseEditExerciseModal}
            maxWidth="lg"
            disableBackdropClick={true}
            className="clientWorkModal"
          >
            {this.renderBackward(
              this.state.selectedSectionIndex,
              this.state.selectedExerciseIndex
            )}

            <ExerciseCard
              onClose={this.onClickCloseEditExerciseModal}
              data={selectedExercise}
              onDonePress={this.props.onDonePress}
              onRemoveLog={this.props.onRemoveLog}
              logId={logId}
              feedbackLog={this.getFeedbackLog()}
              disabled={!this.props.isEnabled}
              workoutLog={this.props.workoutLog}
              isComplete={Boolean(
                this.props.workoutLog[selectedExerciseLogIndex]
              )}
            />
            {this.renderForward(
              this.state.selectedSectionIndex,
              this.state.selectedExerciseIndex
            )}
          </Dialog>
        )}

        {/* <Dialog
          open={Boolean(openExerciseDetailModal)}
          onClose={this.onClickCloseEditExerciseModal}
          maxWidth="lg"
          className="clienCircuitModal"
        >
         
          <h2>Warmup</h2>
          <h2>Complete</h2>
          <CheckCircle color={"white"} fontSize="large" />
          <div className="nextUp">
            <p>Nextup:</p>
            <p>Circuit1</p>
          </div>
          <button className="closeModalBtn">Close</button>
        </Dialog> */}
      </div>
    );
  }

  renderAddNoteButton() {
    return (
      <div id="workoutAddNoteButton">
        <Button
          color="primary"
          variant="contained"
          onClick={this.onAddNoteClick}
          startIcon={<AddIcon />}
        >
          Add note
        </Button>
      </div>
    );
  }

  renderNoteEditor() {
    return (
      <Dialog
        fullWidth={true}
        open={this.state.showAddNote}
        onClose={this.onCloseEditor}
      >
        <NoteEditor
          ref={this.editorRef}
          onClose={this.onCloseEditor}
          onSave={this.onAddNote}
          enterpriseId={this.props.user.enterpriseId}
          clientId={this.props.clientId}
          clientLoginPending={this.props.clientLoginPending}
          programId={this.props.programId}
          workoutId={this.props.workoutId}
          clientName={this.props.clientName}
          clientEmail={this.props.clientEmail || ""}
          user={this.props.user}
          deletePendingForm={this.deletePendingForm}
        />
      </Dialog>
    );
  }

  render() {
    let workout = this.props.workoutSection && this.props.workoutSection;
    return (
      <div id="homePage">
        {/* client workouts start here */}
        <div className="sectionedWorkoutSession">
          {workout &&
            workout.length > 0 &&
            workout.map((section, index) => {
              return (
                <WorkoutSection
                  ref={this.workoutSectionRef}
                  key={index}
                  section={section}
                  listIndex={index}
                  firstSection={workout[0]}
                  selectedExercise={this.state.selectedExercise}
                  selectedExerciseIndex={this.state.selectedExerciseIndex}
                  selectedSectionIndex={this.state.selectedSectionIndex}
                  onRemoveLog={this.props.onRemoveLog}
                  // getEnabledFields={this.getEnabledFields}
                  onClickExerciseMiniCard={this.onClickExerciseMiniCard}
                  onDonePress={this.props.onDonePress}
                  currentDayUnix={this.props.currentDayUnix}
                  todayUnix={this.props.todayUnix}
                  workoutLog={this.props.workoutLog}
                  addSectionLog={this.props.addSectionLog}
                  removeSectionLog={this.props.removeSectionLog}
                  disabled={!this.props.isEnabled}
                />
              );
            })}
        </div>
        {this.state.selectedExercise &&
          this.state.selectedExercise.id &&
          this.renderSelectedExerciseCard()}
        <Button
          variant="contained"
          color="primary"
          className="mgBottom16"
          onClick={this.props.goBack}
        >
          End Workout
        </Button>
        {this.renderAddNoteButton()}
        {this.renderNoteEditor()}
      </div>
    );
  }
}
