import Button from "@material-ui/core/Button";
import bodybuilder from "bodybuilder";
import pluralize from "pluralize";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import Joyride from "react-joyride";
import {
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Fab from "@material-ui/core/Fab";
import ExerciseEditCard from "../../components/exerciseEditCard/exerciseEditCard.js";
import FeedbackForm from "../../components/feedbackForm/feedbackForm";
import Filters from "../../components/filters/exerciseFilters.js";
import ExerciseList from "../../components/levelChangerExerciseList/levelChangerExerciseList.js";
import CustomAlert from "../../components/modals/customAlert";
import WorkoutTypeModal from "../../components/modals/workoutTypeModal";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher.js";
import {
  DB_KEYS,
  LEVEL_UPDATE_TYPES,
  LOCAL_STORAGE_KEYS,
  ROLES,
  TIER,
  WALKTHROUGH,
  WORKOUT_SECTIONS_NAMES,
  WORKOUT_TYPE,
} from "../../constants";
import Factory from "../../modules/http/dataFetcher";
import { DESIGNER_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import RightSide from "./rightSide";
import _ from "lodash";
import ExerciseCard from "../exerciseList/exerciseCard.js";
import { CircularProgress } from "@material-ui/core";
import TrainerExerciseEditCard from "../../components/exerciseEditCard/trainerExerciseEditCard.js";

let DefaultQuery = {
  sort: [
    {
      _id: {
        order: "asc",
      },
    },
  ],
  query: {
    bool: {
      filter: {
        bool: {
          must_not: [
            {
              term: {
                hidden: true,
              },
            },
          ],
        },
      },
      must: {
        match_all: {},
      },
    },
  },
};

const MODES = {
  CREATE: 0,
  CREATE_TRAINER_TEMPLATE: 1,
  CREATE_CLIENT_TEMPLATE: 2,
  EDIT_CLIENT_TEMPLATE: 3,
  EDIT_TRAINER_TEMPLATE: 4,
  COPY: 5,
  ASSIGN_CREATE: 6,
  ASSIGN_EDIT: 7,
};

const DEFAULT_SECTIONS_COUNT = 4;

export function assignTemplateToClient(
  clientId,
  workoutDay,
  challenge = false,
  type
) {
  delete workoutDay.id;
  delete workoutDay.createdTime;
  if (challenge) {
    workoutDay[DB_KEYS.CHALLENGE_ID] = clientId;
    workoutDay[DB_KEYS.WORKOUT_TYPE] = WORKOUT_TYPE.CHALLENGE_WORKOUT;
  } else {
    workoutDay[DB_KEYS.CLIENT_ID] = clientId;
    workoutDay[DB_KEYS.WORKOUT_TYPE] = WORKOUT_TYPE.CLIENT_WORKOUT;
  }
  if (!type) {
    return window.FortisForma.database.storeWorkout(workoutDay, "save");
  } else {
    return window.FortisForma.database.storeWorkout(workoutDay, "draft");
  }
}

export default class WorkoutDesigner extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);

    this.exerciseFetcher = Factory.exerciseFetcher();

    this.state = {
      listCopy: false,
      copyIndex: null,
      walkthrough: false,
      demoDialog: true,
      createdTime: "",
      name: "",
      id: null,
      exercises: [],
      selectedCount: 0,
      selectedExercisesMap: {},
      selectedExercises: [],
      workoutSections: this.getDefaultWorkoutSections(),
      filters: null,
      filtersData: null,
      filtersApplied: false,
      namePlaceholder: "Workout Name",
      showFilters: false,
      lastFetched: null,
      selectedSection: 0,
      showModal: false,
      modalData: [],
      loading: false,
      exerciseFromModalData: {},
      alternateTitleModal: false,
      currentlySelected: {},
      buttonState: "save",
    };

    this.state.steps = DESIGNER_WALKTHROUGH.steps;

    this.state.walkthrough = false;

    this.state.mode = this.getMode();

    this.feedbackFormRef = React.createRef();

    this.globalWalkthrough = {};

    this.walkthroughKey = WALKTHROUGH.DESIGNER;

    this.state.workoutTypeModal = false;

    this.finalRef = null;
    this.listRef = null;
    this.pageSize = 20;

    this.onChange_name = this.onChange_name.bind(this);
    this.saveState = this.saveState.bind(this);
    this.getLocalStorageWorkoutHistory =
      this.getLocalStorageWorkoutHistory.bind(this);
    this.fetchExercises = this.fetchExercises.bind(this);

    this.onAddFromModal = this.onAddFromModal.bind(this);

    this.onTitleEditClick = this.onTitleEditClick.bind(this);

    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);

    this.toggleFilters = this.toggleFilters.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);

    this.saveWorkout = this.saveWorkout.bind(this);

    this.filtersRef = null;

    this.isEnterpriseMember =
      this.props.user && this.props.user.enterpriseId ? true : false;

    let filters = Object.assign({}, this.state.filters);
    if (!filters || !Object.keys(filters).length) {
      this.state.filters = this.createBasicFilters();
    }
  }

  saveState() {
    // this.setState({ workoutSections: { usman: "usman" } });
  }

  createBasicFilters() {
    let filters = bodybuilder();

    if (this.props.user[DB_KEYS.ROLE] === ROLES.TRAINER) {
      let trainerId = this.props.user.id;
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", "trainerId", trainerId)
          .orFilter("term", "trainerId", "admin")
      );
      if (this.props.ppEnterprise) {
        filters.andFilter("bool", (q) =>
          q
            .orFilter("term", "trainerId", trainerId)
            .orFilter("term", "trainerId", "admin")
            .orFilter("term", "enterpriseId", this.props.ppEnterprise.id)
        );
      }
    }
    if (this.isEnterpriseMember) {
      let trainerId = this.props.user.id;
      let enterpriseId = this.props.user.enterpriseId;
      filters.andFilter("bool", (q) =>
        q
          .orFilter("term", DB_KEYS.ENTERPRISE_ID, enterpriseId)
          .orFilter("term", "trainerId", "admin")
          .orFilter("term", "trainerId", trainerId)
      );
    }
    filters = filters.build();
    return filters;
  }

  getMode(savedState) {
    if (this.props.match && this.props.match.params) {
      switch (this.props.match.params.mode) {
        case "copy":
          return MODES.COPY;
        case "copy-draft":
          return MODES.COPY;
        case "edit":
          if (
            savedState &&
            savedState.selectedUser &&
            savedState.selectedUser.id
          ) {
            return MODES.EDIT_CLIENT_TEMPLATE;
          }
          return MODES.EDIT_TRAINER_TEMPLATE;
        case "edit-draft":
          if (
            savedState &&
            savedState.selectedUser &&
            savedState.selectedUser.id
          ) {
            return MODES.EDIT_CLIENT_TEMPLATE;
          }
          return MODES.EDIT_TRAINER_TEMPLATE;
        case "assign":
          if (
            savedState &&
            savedState.selectedUser &&
            savedState.selectedUser.id
          ) {
            return MODES.ASSIGN_EDIT;
          }
          return MODES.ASSIGN_CREATE;
        default:
          if (
            savedState &&
            savedState.selectedUser &&
            savedState.selectedUser.id
          ) {
            return MODES.CREATE_CLIENT_TEMPLATE;
          } else {
            return MODES.CREATE;
          }
      }
    }
  }

  componentDidMount() {
    if (isMobile && !isTablet) {
      this.setState({
        showAlert: true,
      });
    }
    this.scrollToBottom();
    if (this.nameRef) {
      this.nameRef.focus();
    }

    try {
      let restored = this.restore();

      let mode = this.getMode(restored);
      if (
        restored &&
        mode !== MODES.EDIT_CLIENT_TEMPLATE &&
        mode !== MODES.EDIT_TRAINER_TEMPLATE &&
        mode !== MODES.COPY &&
        mode !== MODES.ASSIGN_EDIT
      ) {
        this.confirmDraft();
      }
    } catch (e) {
      console.error(e);
    }

    let querySearchParams = this.props.location.search;
    let params = new URLSearchParams(querySearchParams);
    let urlFilters = {};
    for (let param of params.keys()) {
      urlFilters[param] = params.get(param);
    }
    // update filters
    this.filtersRef && this.filtersRef.updateFilters(urlFilters);
    super.componentDidMount();
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  modalData = (data) => {
    if (data) {
      data.sort((a, b) => a.level - b.level);
    }
    this.setState({ modalData: data });
  };

  modalDataLoading = (data: false) => {
    this.setState({ loading: data });
  };

  exerciseFromModalData = (data) => {
    this.setState({
      exerciseFromModalData: {
        position: data.position,
        data: data.data,
        sectionIndex: data.sectionIndex,
      },
    });
  };

  onClickCancelAlternateModal = () => {
    this.setState({
      exercises: this.state.exercises,
      onTitleEditClick: false,
    });
  };

  onConfirm = (alternateNames) => {
    // this is for when clicking on Save in the modal
    if (!this.state.currentlySelected) {
      return;
    }
    let id = this.state.currentlySelected.id;
    let exercise = Object.assign({}, this.state.currentlySelected);
    // this.setState({
    //   newExerciseModal: false,
    //   currentlySelected: null,
    // });
    let data = this.state.exercises;
    let index = window._.findIndex(
      this.state.exercises,
      (item) => item.id === id
    );
    if (index < 0) {
      return;
    }
    exercise.alternateNames = exercise.alternateNames || [];
    exercise.alternateNames = exercise.alternateNames.concat(alternateNames);
    data[index] = exercise;
    this.setState({
      exercises: data,
    });
    this.setState({ onTitleEditClick: false });
  };

  hideAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  confirmDraft() {
    if (!window.customConfirm) {
      this.draftTimer = setTimeout(() => {
        this.confirmDraft();
      }, 1000);
      return;
    }

    let confirmCallback = () => {
      let emptyState = {
        name: "",
        id: null,
        selectedCount: 0,
        selectedExercisesMap: {},
        selectedExercises: [],
        workoutSections: this.getDefaultWorkoutSections(),
      };
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS);
      this.setState(emptyState);
    };

    let cancelCallback = () => {
      // NOTHING to do here
    };
    let saveDraftCallBack = () => {
      this.setState({ buttonState: "draft" }, () => {
        this.confirmSave("draft");
      });
    };
    window.customConfirm(
      "We found a workout draft, what would you like to do with it?",
      confirmCallback,
      cancelCallback,
      {
        cancelButtonText: "Continue Editing Workout",
        okButtonText: "Permanently Delete",
        saveDraftButtonText: "Save to Drafts",
      },
      saveDraftCallBack
    );
  }

  componentWillUnmount() {
    // this.resetLocalStorage();
    if (this.draftTimer) {
      clearTimeout(this.draftTimer);
    }
    super.componentWillUnmount();
  }

  _restore = () => {
    try {
      let savedState = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE
      );

      let selectedUser = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.SELECTED_USER
      );
      if (selectedUser) {
        try {
          selectedUser = JSON.parse(selectedUser);
        } catch (e) {
          console.error(e);
        }
      }

      if (savedState) {
        savedState = JSON.parse(savedState);
        savedState.selectedExercises = savedState.exercises;
        delete savedState.exercises;
        savedState.selectedCount = savedState.selectedExercises.length;

        if (selectedUser) {
          savedState.selectedUser = selectedUser;
        }

        let selectedExercisesMap = Object.assign(
          {},
          this.state.selectedExercisesMap
        );

        let exercises = savedState.selectedExercises;
        for (let e of exercises) {
          let count = selectedExercisesMap[e.id] || 0;
          count++;
          selectedExercisesMap[e.id] = count;
        }
        savedState.selectedExercisesMap = selectedExercisesMap;

        this.setState({
          ...savedState,
          mode: this.getMode(savedState),
        });

        return (
          ((exercises && exercises.length) || savedState.name) &&
          !savedState.id &&
          savedState
        );
      } else if (selectedUser) {
        let state = {
          selectedUser,
        };
        this.setState({
          ...state,
          mode: this.getMode(state),
        });
      }
      return savedState;
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  getDefaultWorkoutSections = () => {
    return [
      {
        workoutType: WORKOUT_SECTIONS_NAMES.WARMUP,
        exercises: [],
      },
      {
        sets: 1,
        workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
        exercises: [],
      },
      {
        sets: 1,
        workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
        exercises: [],
      },
      {
        workoutType: WORKOUT_SECTIONS_NAMES.COOLDOWN,
        exercises: [],
      },
    ];
  };

  getDummySection = () => {
    return {
      sets: 1,
      workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
      exercises: [],
    };
  };

  restore = () => {
    try {
      let savedState = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE
      );

      let selectedUser = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.SELECTED_USER
      );
      if (selectedUser) {
        try {
          selectedUser = JSON.parse(selectedUser);
        } catch (e) {
          console.error(e);
        }
      }

      if (savedState) {
        savedState = JSON.parse(savedState);

        if (selectedUser) {
          savedState.selectedUser = selectedUser;
        }
        if (
          this.getSelectedExercisesCount(savedState.workoutSections) < 1 &&
          savedState.workoutSections.length < DEFAULT_SECTIONS_COUNT.length
        ) {
          savedState.workoutSections = this.getDefaultWorkoutSections();
        }
        this.setState({
          ...savedState,
          mode: this.getMode(savedState),
        });
        return (
          (this.getSelectedExercisesCount(savedState.workoutSections) > 0 ||
            savedState.name) &&
          !savedState.id &&
          savedState
        );
      } else if (selectedUser) {
        let state = {
          selectedUser,
        };
        this.setState({
          ...state,
          mode: this.getMode(state),
        });
      }
      return savedState;
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  getSelectedExercisesCount = (workoutSections = []) => {
    let count = 0;
    for (let section of workoutSections) {
      if (section.exercises && section.exercises.length > 0) {
        for (let exercise of section.exercises) {
          if (exercise) {
            count++;
          }
        }
      }
    }

    return count;
  };

  onChange = () => {
    this.saveStateToLocal();
    this.saveStateToLocalForUndo();
  };

  setState(newState, callback) {
    super.setState(newState, () => {
      if (callback) {
        callback();
      }
      this.saveStateToLocal();
    });
  }

  getWorkoutState = () => {
    var workoutState = {
      name: this.state.name,
      id: this.state.id,
      createdTime: this.state.createdTime,
      type: this.state.type,
      workoutSections: this.state.workoutSections,
    };

    if (this.isEnterpriseMember) {
      workoutState.enterpriseId = this.props.user.enterpriseId;
    } else {
      workoutState.trainerId = this.props.user.id;
    }
    return workoutState;
  };

  saveStateToLocal() {
    if (!this.finalRef) {
      return;
    }
    try {
      let stateToSave = this.getWorkoutState();
      stateToSave = JSON.stringify(stateToSave);
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE,
        stateToSave
      );
    } catch (e) {
      console.error(e);
    }
  }

  saveStateToLocalForUndo() {
    if (!this.finalRef) {
      return;
    }
    try {
      let oldState = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS)
      );
      let stateToSave = this.getWorkoutState();
      let newState = this.getWorkoutState();
      delete newState["createdTime"];
      delete newState["enterpriseId"];
      delete newState["id"];
      delete newState["name"];
      delete newState["type"];
      if (oldState !== null) {
        const index = oldState.history.length;
        let lastElement = oldState.history[index - 1];
        let value = _.isEqual(
          lastElement.workoutSections,
          stateToSave.workoutSections
        );
        if (!value) {
          oldState.history.push(newState);
          oldState = JSON.stringify(oldState);
          window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS,
            oldState
          );
        }
      } else {
        delete stateToSave["workoutSections"];
        stateToSave.history = [];
        stateToSave.history.push(newState);
        stateToSave = JSON.stringify(stateToSave);
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS,
          stateToSave
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  getLocalStorageWorkoutHistory() {
    let stateToGet = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS)
    );
    if (stateToGet) {
      stateToGet.history.pop();
      if (stateToGet.history.length !== 0) {
        let data =
          stateToGet.history[stateToGet.history.length - 1].workoutSections;
        this.setState({ workoutSections: data });
        stateToGet = JSON.stringify(stateToGet);
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS,
          stateToGet
        );
      } else {
        this.setState({
          workoutSections: this.getDefaultWorkoutSections(),
        });
        window.localStorage.removeItem(
          LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS
        );
      }
    }
  }

  onChange_name(event) {
    this.setState({ name: event.target.value });
  }

  async fetchExercises() {
    try {
      let query = this.state.filters;
      if (!query) {
        query = DefaultQuery;
      }
      let results = await this.exerciseFetcher.fetch(query);
      this.setState({
        exercises: this.state.exercises.concat(results),
      });
      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  onReplace = (newExercises) => {
    let update = {
      selectedExercises: newExercises,
    };

    let selectedExercisesMap = {};
    let exercises = update.selectedExercises;
    for (let e of exercises) {
      let count = selectedExercisesMap[e.id] || 0;
      count++;
      selectedExercisesMap[e.id] = count;
    }
    update.selectedExercisesMap = selectedExercisesMap;

    this.setState(update, () => {
      this.saveStateToLocalForUndo();
    });
  };

  _onAdd(exercise) {
    let selectedExercisesMap = Object.assign(
      {},
      this.state.selectedExercisesMap
    );
    selectedExercisesMap[exercise.id] =
      (selectedExercisesMap[exercise.id] || 0) + 1;
    let selectedExercises = Object.assign([], this.state.selectedExercises);
    selectedExercises.push(exercise);

    this.setState(
      {
        selectedCount: selectedExercises.length,
        selectedExercisesMap: selectedExercisesMap,
        selectedExercises: selectedExercises,
      },
      () => {
        this.scrollToBottom();
      }
    );
  }

  onAddFromModal(exercise) {
    this.finalRef.setNewLevelfromModal(
      this.state.exerciseFromModalData.position,
      this.state.exerciseFromModalData.data,
      exercise.level,
      this.state.exerciseFromModalData.sectionIndex
    );
    this.setState({ showModal: false });
  }

  onTitleEditClick(data) {
    this.setState({ currentlySelected: data, onTitleEditClick: true });
  }

  onAdd(exercise) {
    exercise = Object.assign({}, exercise, exercise.config);
    let workoutSections = Object.assign([], this.state.workoutSections);
    if (workoutSections.length < 1) {
      window.NotificationUtils.showError(
        "Please create a section to add exercise"
      );
      return;
    }
    if (!this.state.selectedSection && this.state.selectedSection !== 0) {
      window.NotificationUtils.showError(
        "Please select a section to add exercise"
      );
      return;
    }
    let selectedSection = Object.assign(
      {},
      this.state.workoutSections[this.state.selectedSection]
    );
    let exercises = Object.assign([], selectedSection.exercises);
    exercises.push(exercise);
    selectedSection.exercises = exercises;
    workoutSections[this.state.selectedSection] = selectedSection;
    this.setState(
      {
        workoutSections,
      },
      () => {
        this.saveStateToLocalForUndo();
      }
    );
    this.finalRef.expandSection(this.state.selectedSection);
  }

  onRemove(exercise) {
    if (!this.state.selectedExercisesMap[exercise.id]) {
      return;
    }

    let selectedExercisesMap = Object.assign(
      {},
      this.state.selectedExercisesMap
    );

    let exerciseCount = selectedExercisesMap[exercise.id];
    let selectedExercises = this.state.selectedExercises.filter((e) => {
      let keep = true;
      if (e.id === exercise.id) {
        if (selectedExercisesMap[exercise.id] === exerciseCount) {
          keep = false;
          selectedExercisesMap[exercise.id] =
            selectedExercisesMap[exercise.id] - 1;
        }
      }
      return keep;
    });

    let count = selectedExercises.length;

    this.setState(
      {
        selectedCount: count,
        selectedExercisesMap: selectedExercisesMap,
        selectedExercises: selectedExercises,
      },
      () => {
        this.saveStateToLocalForUndo();
      }
    );
  }

  getMessage() {
    if (this.state.selectedCount === 0) {
      return `Workout Template Name : `;
    }
    return `${this.state.selectedCount} ${pluralize(
      "exercise",
      this.state.selectedCount
    )} added to `;
  }

  getWorkoutDayNamePlaceholder() {
    return `Workout Template Name`;
  }

  toggleFilters() {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  }

  applyFilters(filters, filtersData, didApply) {
    this.exerciseFetcher.clearResults();
    this.setState(
      {
        filters: filters,
        filtersData: filtersData,
        filtersApplied: didApply,
        exercises: [],
      },
      () => {
        this.listRef && this.listRef.reset(true);
      }
    );

    this.toggleFilters();
  }

  clearFilters() {
    this.filtersRef.reset();
    this.exerciseFetcher.clearResults();
    this.setState(
      {
        filters: this.createBasicFilters(),
        exercises: [],
      },
      () => {
        this.listRef.reset(true);
      }
    );
  }

  getMessages() {
    switch (this.state.mode) {
      case MODES.CREATE:
      case MODES.CREATE_TRAINER_TEMPLATE:
      case MODES.CREATE_CLIENT_TEMPLATE:
      case MODES.ASSIGN_CREATE:
        return {
          start: "Saving workout",
          end: "Workout saved",
          error: "Could not save workout",
        };
      case MODES.COPY:
        return {
          start: "Creating copy of workout",
          end: "Workout copied",
          error: "Could not copy workout",
        };
      case MODES.EDIT_TRAINER_TEMPLATE:
      case MODES.EDIT_CLIENT_TEMPLATE:
      case MODES.ASSIGN_EDIT:
        return {
          start: "Updating workout",
          end: "Workout updated",
          error: "Could not update workout",
        };
      default:
        throw new Error("Invalid mode");
    }
  }

  renderWorkoutTypeModal = () => {
    this.setState({ workoutTypeModal: true });
  };

  confirmSave = (type) => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.UNDO_WORKOUT_SECTIONS);
    if (!this.state.name) {
      window.NotificationUtils.showError("Please set a name first");
      return;
    }

    if (!this.state.exercises.length) {
      if (this.getSelectedExercisesCount(this.state.workoutSections) < 1) {
        window.NotificationUtils.showError(
          "You have no exercise in this workout"
        );
        return;
      }
    }

    switch (this.state.mode) {
      case MODES.CREATE_CLIENT_TEMPLATE:
      case MODES.CREATE:
      case MODES.CREATE_TRAINER_TEMPLATE:
        return this.renderWorkoutTypeModal();
      case MODES.COPY:
      case MODES.EDIT_TRAINER_TEMPLATE:
      case MODES.ASSIGN_EDIT:
      case MODES.EDIT_CLIENT_TEMPLATE:
      case MODES.ASSIGN_CREATE:
        if (this.props.user.role === ROLES.ADMIN) {
          this.saveWorkout(WORKOUT_TYPE.TRAINER);
        } else {
          console.log("in else pta nai kya kar rhaa hai idhr", this.state.mode);
          if (this.state.mode === MODES.COPY) {
            this.saveWorkout(WORKOUT_TYPE.TRAINER);
          } else {
            this.saveWorkout(WORKOUT_TYPE.CLIENT_WORKOUT);
          }
        }
        return;

      default:
        throw new Error("Invalid Mode");
    }
  };

  updateWorkoutAndAddExerciseIds(workout) {
    if (workout.workoutSections) {
      let sections = workout.workoutSections.map((section) => {
        let exercises = section.exercises.map((e) => {
          e.resistance =
            e.resistance || (e.config && e.config.resistance) || "";
          e.reps = e.reps || (e.config && e.config.reps) || "";
          e.sets = e.sets || (e.config && e.config.sets) || "";
          e.weight = e.weight || (e.config && e.config.weight) || "";
          e.time = e.time || (e.config && e.config.time) || "";
          e.note = e.note || "";
          e.videoNote = e.videoNote || "";
          delete e.config;
          return e;
        });
        section.exercises = exercises;
        return section;
      });
      workout.workoutSections = sections;
      return workout;
    }

    return workout;
  }

  removeEmptySections(workout) {
    if (workout.workoutSections) {
      let sections = workout.workoutSections.filter((section) => {
        return section.exercises && section.exercises.length > 0;
      });
      workout.workoutSections = sections;
      return workout;
    }

    return workout;
  }

  saveWorkout(workoutType, assignerRef) {
    console.log("Usman is sending");
    this.closeWorkoutTypeModal();
    let messages = this.getMessages();
    try {
      if (!this.state.name) {
        window.NotificationUtils.showError("Please set a name first");
        return;
      }
      let workout = this.getWorkoutState();
      workout = this.updateWorkoutAndAddExerciseIds(workout);
      workout = this.removeEmptySections(workout);
      if (_.isEmpty(workout.workoutSections)) {
        window.NotificationUtils.showError(
          "You have no exercise in this workout"
        );
        return;
      }
      let update = (propogate) => {
        window.NotificationUtils.showConfirm(messages.start);
        if (propogate) {
          workout.propogate = true;
        }
        if (
          this.props.location.pathname === "/workout-designer/edit" ||
          this.props.location.pathname === "/workout-designer/edit-draft"
        ) {
        } else {
          workout.type = workoutType;
        }

        localStorage.setItem("template_name", this.state.buttonState);
        if (workoutType === WORKOUT_TYPE.CLIENT_WORKOUT) {
          if (assignerRef) {
            workout.clientId = assignerRef.getSelectedClient().id;
          } else if (this.state.selectedUser && this.state.selectedUser.id) {
            workout.clientId = this.state.selectedUser.id;
          }
          // else {
          //     localStorage.removeItem("template_name");
          //     throw new Error("Invalid state for designer");
          // }
        }
        console.log(workout, "workout created");
        window.FortisForma.database
          .storeWorkout(workout, this.state.buttonState)
          .then(async (savedResult) => {
            requestAnimationFrame(() => {
              if (workout.propogate) {
                window.NotificationUtils.showSuccess(
                  "Program updated successfully"
                );
              } else {
                window.NotificationUtils.showSuccess(messages.end);
              }
              this.onSaveComplete(savedResult, assignerRef, messages);
            });
          })
          .catch((error) => {
            window.NotificationUtils.showError(messages.error);
            console.error(error);
          });
      };
      if (
        this.state.mode === MODES.EDIT_CLIENT_TEMPLATE &&
        this.state.selectedUser &&
        this.state.selectedUser.id
      ) {
        window.customConfirm(
          "Do you want to update the active programs of the client as well?",
          () => {
            update(false);
          },
          () => {
            update(true);
          },
          {
            cancelButtonText: "Update Active Program",
            okButtonText: "Update Workout Only",
          }
        );
      } else {
        update(false);
      }
    } catch (error) {
      window.NotificationUtils.showError(messages.error);
      console.error(error);
    }
  }

  async onSaveComplete(savedWorkout, assignerRef, messages) {
    this.resetLocalStorage();
    let details = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS)
    );

    if (this.props.location.pathname === "/workout-designer/assign") {
      let route = "/workout-assigner";
      //comment for draft override when create new one from client details
      if (details) {
        details.workoutToAssign = savedWorkout.id;

        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
          JSON.stringify(details)
        );
        // this.saveToLocal(savedWorkout);
      }
      if (this.props.user.tier !== TIER.FREE) {
        route = "/client-details?tab=3";
      }
      if (this.props.user.role === ROLES.ADMIN) {
        let search = this.props.history.location.search;
        route = new URLSearchParams(search).get("routeTo");
      }
      this.props.history.replace(route);
    } else {
      if (assignerRef) {
        assignerRef.save(savedWorkout);
      } else {
        let searchParams = this.props.history.location.search;
        let routeTo = new URLSearchParams(searchParams).get("routeTo");
        if (routeTo) {
          if (
            routeTo === "/client-details" &&
            this.props.user.tier !== TIER.FREE
          ) {
            routeTo = "/client-details?tab=3";
          }
          this.props.history.replace(routeTo);
        } else {
          if (this.state.buttonState === "draft") {
            this.props.history.replace("/workout-days?draft");
          } else {
            this.props.history.replace("/workout-days");
          }
        }
      }
    }
  }

  resetLocalStorage() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_STATE);
    if (
      this.state.mode === MODES.ASSIGN_CREATE ||
      this.state.mode === MODES.ASSIGN_EDIT
    )
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_USER);
  }

  saveToLocal(savedWorkout) {
    let newDayWorkout = JSON.stringify(savedWorkout);
    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_DESIGNER_RESULT,
        newDayWorkout
      );
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  }

  toggleRequest = () => {
    this.feedbackFormRef.current && this.feedbackFormRef.current.show();
    this.feedbackFormRef.current.setState({
      selection: "MISSING_EXERCISE",
      showFooter: false,
      showBack: true,
      isDirect: true,
    });
    if (this.state.isDirect) {
      this.onCancel();
    }
  };

  showFeedbackForm = () => {
    this.feedbackFormRef.current && this.feedbackFormRef.current.show();
  };

  scrollToBottom = () => {
    try {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    } catch (e) {
      console.error(e);
    }
  };

  walkthroughStop = () => {
    if (this.state.selectedExercises.length >= 1) {
      return this.setState({ walkthrough: false });
    }
  };

  handleBackPress = () => {
    let routeTo = "";
    if (this.props.history.length === 2) {
      routeTo = "/dashboard";
    } else if (
      this.state.mode === MODES.ASSIGN_EDIT ||
      this.state.mode === MODES.EDIT_CLIENT_TEMPLATE ||
      this.state.mode === MODES.ASSIGN_CREATE
    ) {
      routeTo = "/workout-assigner";
    }
    try {
      let searchParams = this.props.history.location.search;
      routeTo = new URLSearchParams(searchParams).get("routeTo");
    } catch (e) {
      console.error(e);
    }
    if (!routeTo) {
      this.props.history.goBack();
    } else {
      this.props.history.replace(routeTo);
    }
  };

  createCustomExercise = () => {
    let filters = Object.assign({}, this.state.filtersData);
    let params = null;
    if (filters) {
      params = "?mode=create";
      for (let filter of Object.keys(filters)) {
        if (filters[filter]) {
          params += `&${filter}=${filters[filter]}`;
        }
      }
    }
    this.props.history.replace(`/exercises${params}`);
  };

  updateSections = (workoutSections, callback) => {
    this.setState(
      {
        workoutSections,
      },
      () => {
        this.saveStateToLocalForUndo();
        if (callback) {
          callback();
        }
      }
    );
  };

  onSelectSection = (index) => {
    this.setState({
      selectedSection: index,
    });
    this.finalRef.expandSection(index);
  };

  copySection = (index, e) => {
    e.stopPropagation();

    let workoutSections = this.state.workoutSections;

    let newWork = workoutSections[index];
    workoutSections.splice(index + 1, 0, newWork);
    let clone = JSON.parse(JSON.stringify(workoutSections));
    this.setState(
      {
        workoutSections: clone,
      },
      () => {
        this.saveStateToLocalForUndo();
      }
    );
  };

  addNewSection = () => {
    let dummySection = this.getDummySection();
    let workoutSections = Object.assign([], this.state.workoutSections);

    let index;
    if (workoutSections.length < 1) {
      index = 0;
    } else {
      index =
        workoutSections[workoutSections.length - 1].workoutType ===
        WORKOUT_SECTIONS_NAMES.COOLDOWN
          ? workoutSections.length - 1
          : workoutSections.length;
    }

    dummySection.position = index;

    workoutSections.splice(index, 0, dummySection);
    this.finalRef.updateLevelData(LEVEL_UPDATE_TYPES.ADD, index);
    this.setState(
      {
        workoutSections,
      },
      () => {
        this.saveStateToLocalForUndo();
      }
    );
    this.onSelectSection(index);
  };

  deleteSection = (index, e) => {
    e.stopPropagation();
    window.customConfirm(
      "Are you sure you want to remove this section ?",
      async () => {
        let workoutSections = Object.assign([], this.state.workoutSections);
        workoutSections.splice(index, 1);
        this.setState(
          {
            workoutSections,
          },
          () => {
            this.saveStateToLocalForUndo();
          }
        );
        this.finalRef.updateLevelData(LEVEL_UPDATE_TYPES.DELETE, index);
        if (this.state.selectedSection === index) {
          this.onSelectSection(0);
        }
        if (this.state.selectedSection > index) {
          this.onSelectSection(this.state.selectedSection - 1);
        }
      }
    );
  };

  ipadRotateAlertMessage = () => {
    return (
      <div className="flexColumn centerAlignJustify">
        <i
          style={{ fontSize: 100, marginBottom: 32 }}
          className="icon material-icons formaRed"
        >
          refresh
        </i>
        <span style={{ marginBottom: 24 }}>
          Please rotate your device to landscape mode to use this feature.
        </span>
        <Button
          onClick={() => this.handleBackPress()}
          className="btn btn-outline-secondary"
          outline={true}
        >
          Go Back
        </Button>
      </div>
    );
  };

  phoneAlertMessage = () => {
    return (
      <div className="flexColumn centerAlignJustify">
        <span style={{ marginBottom: 24 }}>
          Note: We recommend to use a desktop or tablet device to build and
          assign programs for the best experience.
        </span>
        <Button
          onClick={() => this.handleBackPress()}
          className="btn btn-outline-secondary"
          outline={true}
        >
          Go Back
        </Button>

        <Button
          onClick={() => this.handUseAnywayPress()}
          id="useAnywayButton"
          variant="contained"
          color="primary"
          fullWidth={true}
          className="btn"
        >
          Use Anyway
        </Button>
      </div>
    );
  };

  handUseAnywayPress = () => {
    this.hideAlert();
  };

  emptyPlaceholder = () => {
    return (
      <div>
        <div>No exercises matching your criteria</div>

        {this.state.filtersApplied ? (
          <div className="filtersSearch internal">
            <Button
              className="clearFiltersButton"
              color="primary"
              onClick={this.clearFilters}
            >
              Clear Filters
            </Button>
          </div>
        ) : null}

        <button
          className="btn btn-primary"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 12,
            display: "block",
            textDecorationColor: "white",
          }}
          onClick={this.createCustomExercise}
        >
          Create Exercise
        </button>
        {/* <Link id="requestExerciseLink" component="button" variant="body2" onClick={this.toggleRequest}> Request Exercise</Link> */}
      </div>
    );
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          run={
            this.state.walkthrough === true &&
            !this.getSelectedExercisesCount(this.state.workoutSections)
          }
          callback={this.handleJoyrideCallback}
          steps={this.state.steps}
          continuous={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          showSkipButton={true}
          locale={{
            last: "Done",
          }}
        />
      </div>
    );
  };

  closeWorkoutTypeModal = () => {
    this.setState({ workoutTypeModal: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.renderJoyride()}
        <Container fluid className="workoutDesigner">
          <WorkoutTypeModal
            {...this.props}
            isOpen={this.state.workoutTypeModal}
            onClickConfirm={this.saveWorkout}
            onCancel={this.closeWorkoutTypeModal}
            user={this.props.user}
            history={this.props.history}
            type={this.state.buttonState}
          />

          <Modal
            className="exerciseEditorModal"
            backdrop={true}
            style={{
              top: "50%",
            }}
          >
            <ModalHeader>Request Exercise</ModalHeader>
            <ModalBody>
              <ExerciseEditCard
                data={{}}
                user={this.props.user}
                onClickCancel={this.toggleRequest}
                storageRef={DB_KEYS.REQUEST_STORAGE_KEY}
                request={true}
              />
            </ModalBody>
          </Modal>

          {this._prepareLayout()}
          <div
            id="helpButton"
            className="feedbackButton"
            onClick={this.showFeedbackForm}
          >
            Request Exercise
          </div>
          <FeedbackForm
            user={this.props.user}
            ref={(ref) => {
              this.feedbackFormRef = {
                current: ref,
              };
            }}
          />
        </Container>
        <CustomAlert
          message={this.phoneAlertMessage()}
          show={this.state.showAlert}
          onClose={this.hideAlert}
        />
        <CustomAlert
          message={this.ipadRotateAlertMessage()}
          show={isTablet && this.props.isPortrait}
          disableActions={false}
          fullPage={true}
        />
      </React.Fragment>
    );
  }

  _finaliseLayout() {
    return (
      <RightSide
        ref={(ref) => {
          this.finalRef = ref;
        }}
        {...this.props}
        setCopy={this.setCopy}
        copyIndex={this.state.copyIndex}
        listCopy={this.state.listCopy}
        selectedSection={this.state.selectedSection}
        name={this.state.name}
        onReplace={this.onReplace}
        exercises={this.state.selectedExercises}
        workoutSections={this.state.workoutSections}
        onRemove={this.onRemove}
        onDuplicate={this.onAdd}
        updateSections={this.updateSections}
        onSelectSection={this.onSelectSection}
        addNewSection={this.addNewSection}
        copySection={this.copySection}
        onChange={this.onChange}
        recentStateChange={this.saveStateToLocalForUndo}
        deleteSection={this.deleteSection}
        showModal={this.showModal}
        modalData={this.modalData}
        modalDataLoading={this.modalDataLoading}
        onAddFromModal={this.onAddFromModal}
        exerciseFromModalData={this.exerciseFromModalData}
      />
    );
  }

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".selectedExercisesContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: "end", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
  };
  renderDraftBtn = () => {
    return (
      <div id="draftFilterButton">
        <Button
          id="workoutDesignerDraftSave"
          variant="contained"
          size="large"
          color="primary"
          onClick={() => {
            this.setState({ buttonState: "draft" }, () => {
              this.confirmSave("draft");
            });
          }}
        >
          Draft
        </Button>
      </div>
    );
  };

  _prepareLayout() {
    let cardPadding = window.screen.availWidth < 1200 ? 8 : 20;
    let cardStyle = {
      position: "relative",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: "200px",
    };
    return (
      <React.Fragment>
        <Row
          style={{
            flex: "1 1",
          }}
          className="fullContainer"
        >
          <Col
            lg="12"
            className="fullContainer"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div id="deisgnerTopRow">
              <div id="exerciseFiltersContainer">
                <Filters
                  ref={(ref) => {
                    this.filtersRef = ref;
                  }}
                  parentComponent="designer"
                  showMissingFilter={false}
                  onCancel={this.toggleFilters}
                  onSetFilters={this.applyFilters}
                  inclusive={true}
                  user={this.props.user}
                  {...this.props}
                />
              </div>

              <Col id="workoutDesignerDetails" lg="2">
                <Input
                  id="exerciseName"
                  innerRef={(ref) => (this.nameRef = ref)}
                  focus=""
                  style={{
                    flex: 1,
                  }}
                  placeholder={this.getWorkoutDayNamePlaceholder()}
                  value={this.state.name}
                  onChange={this.onChange_name}
                />
              </Col>
              {this.props.match.params.mode === "copy-draft" ||
              this.props.match.params.mode === "edit-draft" ? (
                this.renderDraftBtn()
              ) : this.props.match.params.mode === "copy" ||
                this.props.match.params.mode === "edit" ? (
                <div id="saveFilterButton">
                  <Button
                    id="workoutDesignerSave"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({ buttonState: "save" }, () => {
                        this.confirmSave("save");
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <>
                  {this.renderDraftBtn()}
                  <div id="saveFilterButton">
                    <Button
                      id="workoutDesignerSave"
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({ buttonState: "save" }, () => {
                          this.confirmSave("save");
                        });
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}

              <Modal
                isOpen={this.state.showModal}
                backdrop={true}
                toggle={() => {
                  this.setState({ showModal: !this.state.showModal });
                }}
                zIndex="1300"
                scrollable={true}
              >
                <ModalBody
                  className="designerModal"
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      justifyContent: "space-around",
                      alignItems: "center",
                      overflowY: "scroll",
                      minHeight: "300px",
                    }}
                  > */}
                  {this.state.loading && (
                    <CircularProgress color="primary" size={32} />
                  )}
                  {this.state.loading === false &&
                    this.state.modalData &&
                    this.state.modalData.length < 1 && (
                      <p>No levels available</p>
                    )}
                  {!this.state.modalData && <p>No levels available</p>}
                  {this.state.modalData &&
                    this.state.modalData.map((item) => (
                      <div style={{ width: "220px", height: "300px" }}>
                        <ExerciseCard
                          videoWidth={cardStyle.width}
                          videoHeight={185}
                          cardStyle={cardStyle}
                          data={item}
                          selectEnabled={true}
                          onSelect={this.onAddFromModal}
                          selectButtonView={"+"}
                          {...this.props}
                        />
                      </div>
                    ))}
                  {/* </div> */}
                </ModalBody>
              </Modal>
            </div>
            <Row
              style={{
                justifyContent: "space-around",
                flex: 1,
                height: "100%",
              }}
              id="designerContainer"
            >
              {this.state.onTitleEditClick && (
                // this.state.selected.trainerId === DB_KEYS.ADMIN &&
                <Modal isOpen={true} backdrop={true}>
                  <ModalHeader>Exercise Editor</ModalHeader>
                  <ModalBody>
                    <TrainerExerciseEditCard
                      user={this.props.user}
                      data={this.state.currentlySelected}
                      onClickCancel={this.onClickCancelAlternateModal}
                      onConfirm={this.onConfirm}
                      onDelete={this.onDelete}
                      onClickSave={this.save}
                      {...this.props}
                    />
                  </ModalBody>
                </Modal>
              )}
              <Col
                id="workoutDesigner"
                className="fullContainer workoutDesignerListContainer"
              >
                <ExerciseList
                  ref={(r) => {
                    this.listRef = r;
                  }}
                  data={this.state.exercises}
                  autoSize={true}
                  padding={[30, 0]}
                  containerId={"workoutDesigner"}
                  rowHeight={320}
                  columnWidth={220}
                  columnCount={2}
                  selectEnabled={true}
                  onSelect={this.onAdd}
                  selectButtonView={"+"}
                  titleEditBtn={
                    <i className="material-icons smallIcon">edit</i>
                  }
                  onTitleEditClick={this.onTitleEditClick}
                  selectedMap={this.state.selectedExercisesMap}
                  loadData={this.fetchExercises}
                  pageSize={this.pageSize}
                  emptyPlaceholder={this.emptyPlaceholder()}
                  {...this.props}
                ></ExerciseList>
              </Col>

              <Col
                id="exerciseList"
                className="fullContainer selectedExercisesContainer"
              >
                {this._finaliseLayout()}
                <div
                  style={{
                    float: "left",
                    clear: "both",
                  }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                ></div>
                <div className="floatingButton">
                  <Fab
                    disabled={this.state.workoutSections.length <= 0}
                    variant="extended"
                    color="primary"
                    onClick={this.getLocalStorageWorkoutHistory}
                  >
                    Undo
                  </Fab>

                  <Fab
                    variant="extended"
                    color="primary"
                    onClick={this.handleBackPress}
                  >
                    Cancel
                  </Fab>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div
          // hidden={this.state.selectedExercises.length === 0}
          className="screenNavigationHelperButton"
          onClick={this.navigate}
        >
          {this.navigationIcon()}
        </div>
      </React.Fragment>
    );
  }
}
