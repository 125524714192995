import React, { Component } from "react";
import { Button, Checkbox, Popover } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

export default class NoteFilters extends Component {
  state = {
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  renderFilters = () => {
    return (
      <div className="noteFilters">
        <div>
          <div style={{marginBottom: 12}}>
          {this.props.children}
          </div>
          <div className="notesSingleFilterContainer" id="workout-notes-filter">
            Show workout notes:
            <Checkbox
              name="showWorkoutNotes"
              checked={this.props.showWorkoutNotes}
              onChange={this.props.onFilterChange}
              color="primary"
              inputProps={{ "aria-labelledby": "workout-notes-filter" }}
            />
          </div>
          <div className="notesSingleFilterContainer" id="clinical-notes-filter">
            Show clinical notes:
            <Checkbox
              name="showClinicalNotes"
              checked={this.props.showClinicalNotes}
              onChange={this.props.onFilterChange}
              color="primary"
              inputProps={{ "aria-labelledby": "clinical-notes-filter" }}
            />
          </div>
          <div className="notesSingleFilterContainer" id="note-attachments-filter">
            Show only notes with attachments:
            <Checkbox
              name="showAttachments"
              checked={this.props.showAttachments}
              onChange={this.props.onFilterChange}
              color="primary"
              inputProps={{ "aria-labelledby": "note-attachments-filter" }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Button
          startIcon={<FilterListIcon />}
          variant="outlined"
          color="primary"
          aria-describedby={id}
          onClick={(e) => {
            this.setState({ anchorEl: e.currentTarget });
          }}
        >
          Filters
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {this.renderFilters()}
        </Popover>
      </>
    );
  }
}
