import React, { Component } from 'react';
import {
    BubbleLoader
} from 'react-css-loaders';

export default class ProgramClientList extends Component {
    renderList() {
        return (
            <React.Fragment>
                {this.props.clientList.map((client, index) => {
                    return (
                        <div key={index} className="programClientListItem">
                            <div className="programClientListItemText">
                                <span style={{ fontWeight: '600', marginRight: 8 }}>
                                    {client.name}
                                </span>
                                ({client.email})
                            </div>
                            {false && <div className="programClientListItemCheckboxContainer">
                                <div className="form-check text-left">
                                    <input className="form-check-input" id="termsCheck" type="checkbox" />
                                    <span className="form-check-sign"></span>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }

    renderListPlaceholder() {
        if (!this.props.selectedProgram) {
            return (
                <div className="clientListPlaceholder">Select a program to see its clients here.</div>
            )
        }
        if (this.props.clientListLoading) {
            return (
                <div className="clientListPlaceholder"><BubbleLoader color={"#800520"} size={4} /></div>
            )
        }
        else {
            return (
                <div className="clientListPlaceholder">Assign this program to clients by clicking on Add More button.</div>
            )
        }
    }

    render() {
        return (
            <div className="programDetailClientContainer">
                <div className="programDetailHeader">
                    Assigned Clients
                </div>
                <div className="programDetailClientList">
                    {this.props.clientList && this.props.clientList.length ? this.renderList() : this.renderListPlaceholder()}
                </div>
                {this.props.selectedProgram && <div className="addMoreContainer" onClick={this.props.onAddClick}><i className="icon material-icons">add_circle</i> Add More</div>}
            </div>
        )
    }
}