import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';

import PropertyEditCard from '../../components/propertyEditCard/propertyEditCard';
import EditorPanel from '../../components/editorPanel/editorPanel.js';
import hash from 'object-hash';

import {
  COLLECTIONS
} from '../../constants';

export default class MuscleEditorNew extends EditorPanel {

  constructor(props) {
    super(props);
    this.rootClassName = "exerciseEditor";
    this.state = Object.assign({
      title: "Muscles Editor",
    }, this.state);

    this.fetchData = this.fetchData.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData(query) {
    if (!query) {
      query = {}
    }

    query.collection = COLLECTIONS.MUSCLES;
    if (this.state.filters) {
      query.filters = this.state.filters;
    }

    query.pageConfig = {};

    if (this.state.lastFetched) {
      query.pageConfig.orders = [{
        key: "name",
        after: this.state.lastFetched.name
      }]
    } else {
      query.pageConfig.orders = [{
        key: "name"
      }]
    }

    try {
      let results = await window.FortisForma.database.queryData(query);
      this.setState({
        lastFetched: results[results.length - 1],
        data: this.state.data.concat(results),
      })
      return results
    } catch (e) {
      console.error(e)
      throw e;
    }
  }

  async save(item) {
    this.setState({
      selected: null
    })

    try {
      let results = await window.FortisForma.database.storeMuscle(item);
      let data = Object.assign([], this.state.data);
      let index = window._.findIndex(data, (i) => i.id === results.id);
      if (index < 0) {
        index = data.length;
      }
      data[index] = results;

      this.setState({
        data: data
      })
      window.NotificationUtils.showSuccess(`Saved ${item.name} successfully`);
    } catch (e) {
      window.NotificationUtils.showSuccess(`Unable to save ${item.name}`);
      throw e;
    }
  }
  mapRowToDocument(row) {
    let document = Object.assign({}, row);
    if (!document || !document['Individual Muscle']) {
      throw new Error("Missing values in row");
    }

    let alternateNames = []
    if (document['Secondary Name']) {
      alternateNames.push(document['Secondary Name']);
    }

    let joints = [];
    for (let joint of [document['Joint 1'], document['Joint 2'], document['Joint 3']]) {
      if (joint) {
        joints.push(joint)
      }
    }

    let muscle = {
      name: document['Individual Muscle'],
      alternateNames,
      joints,
      muscleFunction: {
        primary: document['Individual Muscle Function'] || '',
        secondary: document['Individual Muscle Secondary Function'] || '',
        tertiary: document['Individual Muscle Tertiary Function'] || '',
        quarterly: document['Individual Muscle Quarterly Function'] || '',
      },
      muscleGroup: {
        primary: {
          name: document['Muscle Group'] || '',
          function: document['Muscle Group Function'] || '',
        },
        secondary: {
          name: document['Secondary Muscle Group'] || '',
          function: document['Secondary Muscle Group Function'] || ''
        }
      },
      functionalGroup: {
        primary: document['Functional Muscle Group'] || '',
        secondary: document['Secondary Functional Muscle Group'] || '',
      }
    }

    let muscleHashTarget = {
      name: document['Individual Muscle'],
    }

    muscle.id = hash(muscleHashTarget);
    return muscle;
  }

  async bulkSave(docs) {
    try {
      let results = await window.FortisForma.database.bulkUploadMuscles(docs);
      let data = Object.assign([], this.state.data);
      data = data.concat(results);
      this.setState({
        data: data
      })
    } catch (e) {
      throw e;
    }
  }

  onSelect = (item) => {
    super.onSelect(item);
    this.navigate();
  }

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown ? "keyboard_arrow_up" : "keyboard_arrow_down"
    return (<div className="icon material-icons">{iconName}</div>);
  }
  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node =
        document.querySelector(".clientDetailContainer");
    }

    if (node) {
      node = node || node[0];
      try {
        node && node.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
      catch (e) {

      }
    }
    this.setState({
      didNavigateDown: !didNavigateDown
    })
  }

  renderContent() {
    return (
      <React.Fragment>
        <Row className="editorPanelContainer" style={{ alignItems: "center", marginBottom: 16 }}>

          <Col id="musclesList" lg="7" sm="12" className="listContainer editorPanelList">

            {
              (!this.state.data.length && this.state.filtered) ? this.emptyPlaceholder() : null
            }


            {
              this.state.data.map((item) => {
                return (
                  <Card className="admin-component-card margin-zero" key={item.id} onClick={() => {
                    this.onSelect(item)
                  }}>
                    <CardBody className="admin-component-body padding-zero">
                      {item.name}
                      <div className="editIconContainer">
                        <i className="editIcon icon material-icons">edit</i>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
            }
          </Col>
          <Col lg="5" sm="12" className="clientDetailContainer">
            {this.state.selected !== null ?
              <PropertyEditCard onDelete={this.onDelete} collection={COLLECTIONS.MUSCLES} placeholder={"Ex. Triceps"} data={this.state.selected} onClickCancel={this.onClickCancel} onClickSave={this.save}></PropertyEditCard> :
              null
            }
          </Col>
        </Row>
        <div hidden={this.state.selected === null} className="screenNavigationHelperButton" onClick={this.navigate}>{this.navigationIcon()}</div>
      </React.Fragment>
    )
  }
}
