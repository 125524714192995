import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AddImage from "../uploaderImage/addImageComponent";
import PDFThumbnail from "../PDFThumbnail/PDFThumbnail";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { Col } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import Toggle from "react-toggle";
import CircularProgress from "@material-ui/core/CircularProgress";

const PDF = "application/pdf";
export default class BlogDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      image: "",
      video: "",
      loading: true,
      uploadingProgress: 0,
      downloadURL: "",
      uploadSuccess: false,
      uploadFailed: false,
      fileData: [],
      urlType: false,
      uploadTask: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onChangeBlogDescription = this.onChange.bind(this, "description");

    this.onChangeBlogName = this.onChange.bind(this, "title");
    this.onChangeBlogVideo = this.onChange.bind(this, "video");
  }

  uploadImage = async () => {
    let imageData = this.state.image && this.state.image;
    let onProgress = (progress, uploadTask) => {
      this.uploadTask = uploadTask; //TODO:- clean code of fuction for canceling upload
      if (progress <= 100) {
        this.setState({
          uploadingProgress: Math.max(progress, 40),
        });
      }
    };

    try {
      let result = await window.FortisForma.database.uploadAttachments(
        imageData,
        "/blogs/",
        onProgress
      );
      this.setState({
        loading: false,
        uploadSuccess: true,
      });

      this.saveImageWithDownloadURL(result);
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        uploadFailed: true,
        uploadingProgress: 0,
      });
    }
  };
  renderImageLoading = () => {
    return (
      <CircularProgress
        color="primary"
        size={16}
        variant="static"
        id="uploadingImageCircularProgress"
        value={this.state.uploadingProgress}
      />
    );
  };
  saveImageWithDownloadURL = async (result) => {
    let path = result.metadata.fullPath;
    try {
      let downloadURL = await window.FortisForma.database.getDownloadURL(path);
      this.setState({ image: downloadURL }, () => {
        let blog = {};
        blog.title = this.state.title;
        blog.description = this.state.description;
        blog.image = downloadURL;
        blog.video = null;
        this.props.onSave(blog, this.props.isNew);
      });
    } catch (e) {
      console.error(e);
    }
  };

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    update["change"] = true;
    this.setState(update);
  }

  setData = (obj) => {
    if (this.props.isNew) {
      let data = {
        title: null,
        description: null,
        video: null,
        image: null,
        urlType: false,
      };
      this.setState({
        ...data,
      });
    } else {
      if (obj.video !== null) {
        let urlRegex =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        let status = obj.video.match(urlRegex);
        if (status !== null) {
          let data = {
            title: obj.title || null,
            description: obj.description || null,
            video: obj.video || null,
            image: null,
            urlType: true,
          };
          this.setState({
            ...data,
          });
        } else {
          let data = {
            title: obj.title || null,
            description: obj.description || null,
            video: obj.video || null,
            image: null,
            urlType: true,
          };
          this.setState({
            ...data,
          });
        }
      } else {
        let data = {
          title: obj.title || null,
          description: obj.description || null,
          video: null,
          image: obj.image || null,
          urlType: false,
        };
        this.setState({
          ...data,
        });
      }
    }
  };

  componentWillMount() {
    this.setData(this.props.selected);
  }

  componentWillReceiveProps(nextProps) {
    this.setData(nextProps.selected);
  }

  onSave = () => {
    if (!this.state.title) {
      window.NotificationUtils.showError("Invalid Blog Name");
      return;
    }
    if (this.state.urlType) {
      let blog = {};
      blog.title = this.state.title;
      blog.description = this.state.description;
      blog.image = null;
      blog.video = this.state.video;
      this.props.onSave(blog, this.props.isNew);
    } else {
      if (typeof this.state.image === "string") {
        let blog = {};
        blog.title = this.state.title;
        blog.description = this.state.description;
        blog.image = this.state.image;
        blog.video = null;
        this.props.onSave(blog, this.props.isNew);
      } else {
        this.uploadImage();
      }
    }
  };

  selectedFilesForUpload = (file) => {
    let files = Array.from(file);

    this.setState({
      image: files[0],
    });
  };
  getImageSrc = () => {
    if (typeof this.state.image === "string") {
      return this.state.image;
    } else {
      let imageData = new File([this.state.image], "image");
      let src = URL.createObjectURL(imageData);
      return src;
    }
  };
  cancelDownload = () => {
    this.uploadTask && this.uploadTask.cancel();
    this.setState({ image: null });
    window.NotificationUtils.showSuccess("Uploading canceled");
  };
  deleteBlog = () => {
    this.props.onDelete(this.props.selected.id);
  };

  renderCrossButton = () => {
    return (
      <div className="imageActionButton">
        <IconButton size="small" onClick={this.cancelDownload}>
          <ClearIcon fontSize="small" className="imageActionButton" />
        </IconButton>
      </div>
    );
  };
  handleToggle = () => {
    this.setState({
      urlType: !this.state.urlType,
    });
  };

  render() {
    return (
      <div className="flexCenter enterpriseCardContainer">
        <Card variant="outlined" className="enterpriseDetailsCard">
          <CardHeader title={this.props.isNew ? "New Blog" : "Blog Detail"} />

          <Col sm="12" lg="1" className="verticalCenter" id="mediaTypeToggle">
            <Tooltip title="Change the File type">
              <label
                className="d-flex align-items-center"
                style={{ margin: 0, gap: "8px" }}
              >
                <span>Image</span>
                <Toggle
                  checked={this.state.urlType}
                  icons={false}
                  onChange={this.handleToggle}
                />
                <span>Video</span>
              </label>
            </Tooltip>
          </Col>

          <CardContent>
            {!this.state.urlType ? (
              <div className="uploaderContainer UploadBlogImage">
                <span>Upload Blog Image</span>
                {this.state.image === null && (
                  <AddImage
                    onFileChange={this.selectedFilesForUpload}
                    extensions={["png", "jpg", "jpeg"]}
                  />
                )}

                {this.state.image !== null && (
                  <div className="uploadingImageContainer">
                    {this.state.image.type === PDF ? (
                      <PDFThumbnail
                        height={56}
                        className="noteImage"
                        link={this.getImageSrc()}
                      />
                    ) : (
                      <div
                        className="noteImage"
                        style={{
                          backgroundImage: `url("${this.getImageSrc()}")`,
                          width: "100%",
                        }}
                      />
                    )}

                    <div className="uploadIconContainer imageActions">
                      {this.state.image !== null &&
                      this.state.uploadingProgress === 0
                        ? this.renderCrossButton()
                        : this.state.loading && this.renderImageLoading()}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <TextField
                  className={`enterpriseDetailsField `}
                  value={this.state.video}
                  onChange={this.onChangeBlogVideo}
                  label="Video Link"
                />
              </div>
            )}

            <div>
              <TextField
                className={`enterpriseDetailsField `}
                required
                value={this.state.title}
                onChange={this.onChangeBlogName}
                label="Name"
              />
            </div>
            <div>
              <TextField
                className={`enterpriseDetailsField`}
                value={this.state.description}
                onChange={this.onChangeBlogDescription}
                label="Description"
              />
            </div>
          </CardContent>
          <CardActions className="justifyContentFlexEnd">
            {this.props.onDelete && (
              <Button color="primary" onClick={this.deleteBlog}>
                Delete
              </Button>
            )}
            <Button color="primary" onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.onSave}>
              Save
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}
