import React from "react";
import { FormControlLabel, Typography, Checkbox } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Autosuggest from "react-autosuggest";
import SignupBase from "../../components/base/signupBase.js";
import TextField from "@material-ui/core/TextField";
import AdvancedFileUpload from "../../components/advancedFileUploader";
import moment from "moment";
import RenderImage from "../../components/renderImage/renderImage";
import TAButton from "../../components/taButton/taButton";
import { isMobileOnly } from "react-device-detect";
import { ROLES } from "../../constants.js";
import NumberFormat from "react-number-format";

export default class FacilityInfo extends SignupBase {
  constructor(props) {
    super(props);
    this.fields = [
      "name",
      "fax",
      "phone",
      "address",
      "city",
      "postalCode",
      "province",
      "logo",
      "enterpriseLogoURLName",
      "enterpriseLogoURL",
      "enableStaffMFA",
      "sameInfo",
      "oldState",
    ];

    for (let field of this.fields) {
      this.state[field] = "";

      this["onChange_" + field] = this.onChange.bind(this, field);
    }
    this.state = Object.assign(
      this.state,
      this.props.user &&
        this.props.user.enterprise &&
        this.props.user.enterprise
    );
    if (this.state.logo && this.state.logo.url) {
      this.state.enterpriseLogoURL = this.state.logo.url;
      this.state.enterpriseLogoURLName = this.state.logo.name;
    }
    this.state.checkErrors = false;
    this.state.saveButtonEnabled = true;
    this.isFacilityAdmin =
      this.props.user && this.props.user.role === ROLES.FACILITY_ADMIN
        ? true
        : false;
    this.isEnterpriseAdminRight =
      this.props.user &&
      (this.props.user.role === ROLES.FACILITY_ADMIN ||
        this.props.user.role === ROLES.ENTERPRISE_ADMIN);
  }

  checkSimilarInfo() {
    const { name, phone, address, city, postalCode, province } = this.state;
    const facility = this.props.user;
    if (
      name === facility.name &&
      phone === facility.phone &&
      address === facility.address &&
      city === facility.city &&
      postalCode === facility.postalCode &&
      province === facility.province
    ) {
      this.setState({
        sameInfo: true,
      });
    } else {
      this.setState({
        sameInfo: false,
      });
    }
  }

  componentDidMount() {
    this.checkSimilarInfo();
  }

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    this.setState(update, () => {
      this.checkSimilarInfo();
    });
  }

  checkErrorsInForm() {
    if (!this.state["name"]) {
      window.NotificationUtils.showError("Please enter a valid name");
      return true;
    }

    return false;
  }

  goToHome = () => {
    this.props.history.replace("/dashboard");
  };

  onClickSave = async () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrorsInForm()) {
      return;
    }
    this.setState({
      saveButtonEnabled: false,
    });
    let enterpriseDetails = {
      name: this.state.name,
      fax: this.state.fax,
      phone: this.getCellNumber(this.state.phone),
      address: this.state.address,
      city: this.state.city,
      postalCode: this.getPostal(this.state.postalCode),
      province: this.state.province,
      logo: {
        name: this.state.enterpriseLogoURLName,
        url: this.state.enterpriseLogoURL,
      },
      enableStaffMFA: this.state.enableStaffMFA,
    };
    try {
      await window.FortisForma.database.updateEnterprise(
        enterpriseDetails,
        this.props.user.enterpriseId
      );
      window.NotificationUtils.showSuccess(
        "Enterprise Details Updated Successfully"
      );
      await this.props.onUserChange();
      this.goToHome();
      this.setState({
        saveButtonEnabled: true,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        saveButtonEnabled: true,
      });
      window.NotificationUtils.showError("Could not update profile");
    }
  };

  getFileName() {
    return (
      this.props.user &&
      this.props.user.enterprise &&
      `enterpriseLogos/${this.props.user.enterprise.id}_${moment().unix()}`
    );
  }

  updateValue(key, val) {
    this.setState({
      [key]: val,
    });
  }

  handleMFAChange = (event) => {
    this.setState({
      enableStaffMFA: event.target.checked,
    });
  };
  handleSameInfoChange = (event) => {
    this.setState({
      sameInfo: event.target.checked,
      oldState: {
        name: this.state.name,
        phone: this.getCellNumber(this.state.phone),
        address: this.state.address,
        city: this.state.city,
        postalCode: this.getPostal(this.state.postalCode),
        province: this.state.province,
      },
    });
    const {
      name = "",
      address = "",
      city = "",
      phone = "",
      postalCode = "",
      province = "",
    } = this.props.user;
    if (event.target.checked) {
      this.setState({
        name,
        phone,
        address,
        city,
        postalCode,
        province,
      });
    } else {
      this.setState({
        ...this.state.oldState,
      });
    }
  };

  renderLogoUploader = () => {
    if (this.isFacilityAdmin) {
      return;
    }
    return (
      <div className="uploaderContainer">
        <AdvancedFileUpload
          onFailure={(e) =>
            window.NotificationUtils.showError("Failed to upload")
          }
          uploadLocation={this.getFileName()}
          onComplete={(files) => {
            this.updateValue("enterpriseLogoURL", files[0].url);
            this.updateValue("enterpriseLogoURLName", files[0].name);
          }}
          placeholder={`Enterprise Logo`}
          buttonProps={{
            variant: "outlined",
            color: "primary",
            loadingMessage: "Uploading ...",
          }}
          filePickerProps={{
            extensions: ["png", "jpeg", "jpg"],
          }}
          onDelete={() => this.updateValue("enterpriseLogoURL", null)}
        />
        <div style={{ width: "100%", marginTop: 16 }}>
          {this.state.enterpriseLogoURL && (
            <div className="certificateImgContainer">
              <img
                alt="Enterprise Logo"
                src={this.state.enterpriseLogoURL}
                className="certificateImg"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  renderTextFields = () => {
    const inputProps = {
      placeholder: "Province",
      value: this.state.province,
      className: "form-control",
      onChange: this.onChange_province,
      disabled: this.isFacilityAdmin,
    };
    return (
      <div className="facilityInfoTextFieldContainer">
        <TextField
          id="name"
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.state.name}
          placeholder="Name"
          fullWidth={true}
          onChange={this.onChange_name}
          error={this.state.checkErrors && !this.state.name}
          inputProps={{
            autoComplete: "company-name",
          }}
        />
        <NumberFormat
          type="tel"
          format="+1 ### ### ####"
          mask=" "
          placeholder="e.g. +1 999 999 9999"
          customInput={TextField}
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.state.phone}
          fullWidth={true}
          onChange={this.onChangePhone}
        />
        <TextField
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.state.fax}
          placeholder="Fax"
          fullWidth={true}
          onChange={this.onChange_fax}
        />
        <TextField
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.state.address}
          placeholder="Address"
          fullWidth={true}
          onChange={this.onChange_address}
        />
        <TextField
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.state.city}
          placeholder="City"
          fullWidth={true}
          onChange={this.onChange_city}
        />
        <TextField
          disabled={this.isFacilityAdmin}
          className="facilityInfoTextField"
          value={this.parsePostalCode(this.state.postalCode)}
          placeholder="Ex. K2B 7W1"
          fullWidth={true}
          onChange={this.onChange_postalCode}
        />
        <div className="facilityInfoTextField">
          <Autosuggest
            suggestions={this.state.provinceSuggestions}
            onSuggestionsFetchRequested={
              this.onProvinceSuggestionsFetchRequested
            }
            onSuggestionsClearRequested={
              this.onProvinceSuggestionsClearRequested
            }
            getSuggestionValue={window.getSuggestionValue}
            renderSuggestion={window.renderSuggestion}
            inputProps={inputProps}
          />
        </div>
        <div id="staffMFACheckbox">
          <FormControlLabel
            control={
              <Checkbox
                disabled={this.isFacilityAdmin}
                checked={this.state.enableStaffMFA}
                onChange={this.handleMFAChange}
                name="enableStaffMFA"
                color="primary"
              />
            }
            label="Enable email based two-step verification for staff members"
          />
        </div>
        <div id="">
          <FormControlLabel
            control={
              <Checkbox
                disabled={!(this.props.user.role === ROLES.ENTERPRISE_ADMIN)}
                checked={this.state.sameInfo}
                onChange={this.handleSameInfoChange}
                color="primary"
              />
            }
            label="Same as profile info"
          />
        </div>
      </div>
    );
  };

  showUploadedLogo = () => {
    return (
      <div className="mgBottom32">
        <RenderImage
          imageURL={this.state.enterpriseLogoURL}
          imageName={this.state.enterpriseLogoURLName}
          onClickDelete={() => this.updateValue("enterpriseLogoURL", null)}
          disableDelete={this.isFacilityAdmin}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="facilityInfoMainContainer">
        <Typography variant="h4" className="facilityInfoHeaderText">
          Enterprise Details
        </Typography>
        <Card
          elevation={0}
          className="facilityInfoCardContainer"
          variant={isMobileOnly ? "" : "outlined"}
        >
          <CardContent className="facilityInfoCardContentContainer">
            {this.state.enterpriseLogoURL
              ? this.showUploadedLogo()
              : this.renderLogoUploader()}
            {this.renderTextFields()}
          </CardContent>
          <CardActions className="facilityInfoButtonContainer">
            {!this.isFacilityAdmin && (
              <TAButton
                size="large"
                color="primary"
                variant="contained"
                loadingMessage="Saving"
                onClick={this.onClickSave}
                isLoading={!this.state.saveButtonEnabled}
              >
                Save
              </TAButton>
            )}
          </CardActions>
        </Card>
      </div>
    );
  }
}
