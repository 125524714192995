import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import TAButton from "../../utils/TAButton";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { ROLES, TIER } from "../../constants";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import StripeListing from "../stripe/stripe";

const PRICING_TYPES = {
  MONTHLY: 0,
  YEARLY: 1,
};
const BUTTON_STATE = {
  START_FREE_TRIAL: "START FREE TRIAL",
  START_FOR_FREE: "START FOR FREE",
  UPGRADE: "UPGRADE",
  DOWNGRADE_SUBSCRIPTION: "DOWNGRADE SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL SUBSCRIPTION",
  SWITCH_TO_MONTHLY: "SWITCH TO MONTHLY PLAN",
  SWITCH_TO_YEARLY: "SWITCH TO YEARLY PLAN",
  RESUBSCRIBE_BEFORE_DATE: "RESUBSCRIBE BEFORE",
  CURRENT_PLAN: "CURRENT PLAN",
  RENEW: "RENEW SUBSCRIPTION",
};
const PRICING_CARDS = [
  {
    title: "Coaching Solution",
    monthly_amount: 0,
    yearly_amount: 0,
    interval: "free",
    points: [
      "Access to 5000+ exercises",
      "Build custom routines and templates",
      "Easy client management",
      "Create your own personalized exercise database",
      "Clients can view their workout through our app, a straight link, or PDF.",
      "Workout tracking",
      "Ongoing support",
    ],
    buttonText: "START FOR FREE",
  },
  {
    title: "Private Practice Solution",
    monthly_amount: 20,
    yearly_amount: 200,
    0: {
      id: process.env.REACT_APP_PRODUCT_ID_PRIVATE_PRACTICE_SOLUTION_MONTHLY,
      interval: "month",
    },
    1: {
      id: process.env.REACT_APP_PRODUCT_ID_PRIVATE_PRACTICE_SOLUTION_YEARLY,
      interval: "year",
    },
    2: {
      id: process.env.REACT_APP_PRODUCT_ID_TRIAL_PRIVATE_PRACTICE_SOLUTION,
      interval: "month",
    },
    points: [
      "<b>All Coaching Solution Features +</b>",
      "Build intake forms and assessments",
      "Customizable note taking",
      "Public profile on our client connection Portal",
    ],
    buttonText: "START FREE TRIAL",
  },
  {
    title: "Enterprise Solution",
    monthly_amount: 40,
    yearly_amount: 400,
    0: {
      id: process.env.REACT_APP_PRODUCT_ID_ENTERPRISE_SOLUTION_MONTHLY,
      interval: "month",
    },
    1: {
      id: process.env.REACT_APP_PRODUCT_ID_ENTERPRISE_SOLUTION_YEARLY,
      interval: "year",
    },
    2: {
      id: process.env.REACT_APP_PRODUCT_ID_TRIAL_ENTERPRISE_SOLUTION,
      interval: "month",
    },

    points: [
      "<b>All Private Practice Features +</b>",
      "Unlimited Coaches on the Platform",
      "Multiple Coaches with Client",
      "Shared exercise client",
    ],
    buttonText: "START FREE TRIAL",
  },
];

const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 30px !important;
  font-size: 2.4em;
`;

const CloseButton = styled(Button)`
  position: absolute !important;
  right: -25px;
  top: -25px;
  background-color: #800020;
  border-radius: 50%;
  padding: 20px;
`;

const StyledTypography = styled(Typography)`
  font-size: 21px;
  font-weight: bold;
  padding: 0 20px;
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 10px;

  &.MuiButton-root {
    font-size: 13px;
    text-transform: none;
    padding: 10px 16px;
    font-weight: 500;
  }

  &.MuiButtonGroup-grouped {
    min-width: 190px;
  }

  &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
    margin-left: -2px;
  }

  &.MuiButton-outlinedPrimary {
    border: 2px solid #802520;
  }

  &:hover {
    border: 2px solid #802520 !important;
  }
`;

const INITIAL_STATE = {
  enterpriseName: "",
  enterpriseAdminName: "",
  businessName: "",
  email: "",
  url: "",
  title: "Coaching Solution",
  productId: "",
  interval: "",
};

const OurPricing = (props) => {
  //   const classes = useStyles();
  const [billingType, setBillingType] = useState(PRICING_TYPES.MONTHLY);
  const [showModal, setShowModal] = useState({ value: false });
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState({
    value: false,
    title: "Coaching Solution",
  });
  const [showResubscribeModal, setResubscribeModal] = useState({
    value: false,
    title: "Coaching Solution",
  });
  const [showDowngradeModal, setDowngradeModal] = useState({
    value: false,
    title: "Coaching Solution",
  });
  const [formDetails, setFormDetails] = useState(INITIAL_STATE);
  const [privateSolutions, setPrivateSolutions] = useState([]);
  const [enterpriseSolutions, setEnterpriseSolutions] = useState([]);
  const [productDetails, setProductDetails] = useState(PRICING_CARDS);
  const [loader, setLoader] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loaderStripe, setLoaderStripe] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({
    interval: "free",
    plan: "Coaching Solutions",
    endDate: "",
    startDate: "",
  });
  const fetchSubscriptions = async () => {
    let result = await window.FortisForma.database.getSubscription();
    if (result) {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchTier();
  }, []);
  const fetchTier = async () => {
    try {
      let privateSolutionsArr = [];
      let enterpriseSolutionsArr = [];
      let result = await window.FortisForma.database.getSubscriptionData();

      setCurrentPlan({
        interval: result.interval,
        plan: result.tier,
        cancelled: result.cancelled,
        active: result.active,
      });
      if (result.interval === "year") {
        setBillingType(1);
      } else {
        setBillingType(0);
      }
      setTimeout(() => {
        fetchSubscriptions();
      }, 2000);
    } catch (e) {
      console.log("error price details", e);
    }
  };

  useEffect(() => {
    // subscriptions collection {
    // currentPlan:
    // duration
    // }
  }, []);

  const showModalHandler = async (productId, interval, title, buttonText) => {
    let result = "";
    if (
      productId ===
        process.env.REACT_APP_PRODUCT_ID_TRIAL_PRIVATE_PRACTICE_SOLUTION ||
      productId === process.env.REACT_APP_PRODUCT_ID_TRIAL_ENTERPRISE_SOLUTION
    ) {
      console.log("if case");
      result = await window.FortisForma.database.stripeCall(
        "create_checkout_session/",
        props.user.customerId,
        productId,
        interval,
        buttonText,
        currentPlan,
        formDetails,
        props.subscription
      );
    } else {
      console.log("else case");
      result = await window.FortisForma.database.stripeCall(
        "create_checkout_session/",
        props.user.customerId,
        productId,
        interval,
        buttonText,
        currentPlan,
        formDetails,
        props.subscription
      );
    }
    setShowModal({ ...showModal, value: true });
    if (props.user) {
      const { name, email, businessName } = props.user;
      setFormDetails({
        ...formDetails,
        enterpriseName: name,
        enterpriseAdminName: name,
        businessName,
        url: result.checkout_url,
        title: title,
        email,
        productId,
        interval,
        buttonText,
      });
    }
  };
  const showDowngradeModalhandler = (
    productId = "",
    interval = "",
    title,
    buttonText
  ) => {
    setDowngradeModal({
      ...showDowngradeModal,
      value: true,
      title,
      productId,
      interval,
      buttonText,
    });
  };
  const showConfirmModalHandler = (
    productId = "",
    interval = "",
    title,
    buttonText
  ) => {
    // setShowModal({ ...showModal, value: true });

    setConfirmModal({
      ...showConfirmModal,
      value: true,
      title,
      productId,
      interval,
      buttonText,
    });
    // if (props.user) {
    //   const { name, email } = props.user;
    //   setFormDetails({
    //     ...formDetails,
    //     enterpriseName: name,
    //     enterpriseAdminName: name,
    //     email,
    //     productId,
    //     interval,
    //   });
    // }
  };
  const showResubscribeModalHandler = (
    productId = "",
    interval = "",
    title,
    buttonText
  ) => {
    // setShowModal({ ...showModal, value: true });
    setResubscribeModal({
      ...showResubscribeModal,
      value: true,
      title,
      productId,
      interval,
      buttonText,
    });
  };
  const handleCheckout = async () => {
    const PRODUCT_ID = formDetails.productId;
    const INTERVAL = formDetails.interval;
    try {
      // let result = await window.FortisForma.database.customerPortal();
      let result = await window.FortisForma.database.createSubscriptionSession({
        priceID: PRODUCT_ID,
        interval: INTERVAL,
      });

      if (result.success === "payment") {
        window.open(result.url, "_self");
      } else if (result.success === "schedule") {
        window.NotificationUtils.showSuccess(
          "You have successfully applied for the tier"
        );
      } else {
        window.NotificationUtils.showError("Subscriptions queue limit reached");
      }
    } catch (e) {
      console.log("error handle checkout", e);
    }
  };
  const renderDowngradeModal = () => {
    const handleClose = () => {
      setDowngradeModal({ ...showDowngradeModal, value: false });
    };

    return (
      <Modal
        isOpen={showDowngradeModal.value}
        style={{ width: "80%", maxWidth: "1000px", minWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          setDowngradeModal((prev) => ({
            ...prev,
            value: !prev.value,
            title: "",
            button: false,
          }));
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#6E6E6E",
              fontSize: "22px",
              lineHeight: "30px",
              textAlign: "center",
              fontWeight: 700,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Are you sure you want to downgrade your {currentPlan.plan} (
            {currentPlan.interval}ly) subscription to the{" "}
            {showDowngradeModal.title} ({showDowngradeModal.interval}ly)
            subscription.
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            padding: " 20px 30px ",
            paddingBottom: "44px",
          }}
        >
          {showDowngradeModal.title === "Enterprise Solution" ? (
            <p style={{ padding: "0 40px 20px 40px" }}>
              Your {showDowngradeModal.title} subscription will not be renewed.
              {/*<br />*/}
              You will be downgraded to {showDowngradeModal.title} after your
              subscription period ends, you'll lose access to all{" "}
              {currentPlan.plan} features, including the Staff feature.
            </p>
          ) : showDowngradeModal.title === "Private Practice Solution" ? (
            <p style={{ padding: "0 40px 20px 40px" }}>
              Your subscription will not be renewed.
              <br />
              You will be downgraded to {showDowngradeModal.title} after your
              subscription period ends, you'll lose access to all{" "}
              {currentPlan.plan} features and you'll lose access to all paid
              features.
            </p>
          ) : (
            <p style={{ padding: "0 40px 20px 40px" }}>
              Your subscription will not be renewed.
              <br />
              You will be downgraded to {showDowngradeModal.title} after your
              subscription period ends, you'll lose access to all{" "}
              {currentPlan.plan} features and you'll lose access to all paid
              features.
            </p>
          )}

          <Box width="100%" display="flex" justifyContent="space-around">
            <TAButton
              variant="contained"
              onClick={async () => {
                handleClose();

                if (showDowngradeModal.title !== "Coaching Solution") {
                  setShowStripeModal(true);

                  // let result = await window.FortisForma.database.stripeCall(
                  //   "create_checkout_session/",
                  // props.user.customerId,
                  // showDowngradeModal.productId,
                  // showDowngradeModal.interval,
                  // showDowngradeModal.buttonText,
                  // currentPlan,
                  // showDowngradeModal,
                  // props.subscription
                  // );
                  // setShowModal({ ...showModal, value: true });
                  // if (props.user) {
                  //   const { name, email } = props.user;
                  //   setFormDetails({
                  //     ...formDetails,
                  //     enterpriseName: name,
                  //     enterpriseAdminName: name,
                  //     url: result.checkout_url,
                  //     title: showDowngradeModal.title,
                  //     email,
                  //     productId: showDowngradeModal.productId,
                  //     interval: currentPlan.interval,
                  //   });
                  // }
                } else {
                  console.log("fghjk else");
                  let result = await window.FortisForma.database.stripeCall(
                    "create_checkout_session/",
                    props.user.customerId,
                    null,
                    currentPlan.interval,
                    showDowngradeModal.buttonText,
                    currentPlan,
                    showDowngradeModal,
                    props.subscription
                  );
                  window.location.href = "/";
                  window.NotificationUtils.showSuccess(
                    "Plan Changed Successfully"
                  );
                }
              }}
              style={{
                backgroundColor: "white",
                border: "1px solid #800520",
                color: "#800520",
                width: "120px",
                fontWeight: 600,
              }}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Confirm
            </TAButton>
            <TAButton
              variant="contained"
              onClick={() => handleClose()}
              style={{ width: "120px", fontWeight: 600 }}
              // disabled={submitDisabled || loading}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Cancel
            </TAButton>
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  const stripeModalClose = () => {
    setShowStripeModal(false);
  };

  const onClickStripeCheck = async () => {
    console.log("onClickStripeCheck");
    try {
      setBtnDisable(true);
      setLoaderStripe(true);
      let result = await window.FortisForma.database.stripeCall(
        "create_checkout_session/",
        props.user.customerId,
        showDowngradeModal.productId,
        showDowngradeModal.interval,
        showDowngradeModal.buttonText,
        currentPlan,
        showDowngradeModal,
        props.subscription
      );
      window.NotificationUtils.showSuccess("Plan Changed Successfully!");
      window.location.href = "/";
    } catch (e) {
      setBtnDisable(false);
      setLoaderStripe(false);
      window.NotificationUtils.showError("Something went wrong!");
    }
  };

  const renderStripeModal = () => {
    return (
      <Dialog
        // fullScreen={isMobileOnly ? true : false}
        fullWidth={true}
        open={showStripeModal}
        onClose={() => stripeModalClose()}
      >
        <StripeListing
          {...props}
          loaderStripe={loaderStripe}
          btnDisable={btnDisable}
          showDowngradeModal={showDowngradeModal}
          onClose={stripeModalClose}
          onClickStripeCheck={onClickStripeCheck}
        />
      </Dialog>
    );
  };
  const renderConfirmModal = () => {
    const handleClose = () => {
      setConfirmModal({ ...showConfirmModal, value: false });
    };

    return (
      <Modal
        isOpen={showConfirmModal.value}
        style={{ width: "80%", maxWidth: "1000px", minWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          setConfirmModal((prev) => ({
            ...prev,
            value: !prev.value,
            modalTitle: "",
            button: false,
          }));
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#6E6E6E",
              fontSize: "22px",
              lineHeight: "30px",
              textAlign: "center",
              fontWeight: 700,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Are you sure you want to cancel your {currentPlan.plan} (
            {currentPlan.interval}ly subscription?)
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            padding: " 20px 30px ",
            paddingBottom: "44px",
          }}
        >
          {showConfirmModal.title === "Enterprise Solution" ? (
            <p style={{ padding: "0 40px 20px 40px" }}>
              Your subscription will not be renewed.
              <br />
              You will be downgraded to {showConfirmModal.title} version after
              your subscription period ends, you'll lose access to all paid
              features, and you will no longer be able to manage your team.
            </p>
          ) : (
            <p style={{ padding: "0 40px 20px 40px" }}>
              Your subscription will not be renewed.
              <br />
              You will be downgraded to {showConfirmModal.title} version after
              your subscription period ends, and you'll lose access to all paid
              features.
            </p>
          )}

          <Box width="100%" display="flex" justifyContent="space-around">
            <TAButton
              variant="contained"
              onClick={async () => {
                handleClose();
                let result = await window.FortisForma.database.stripeCall(
                  `downgrade/subscription/${props.user.customerId}`,
                  null,
                  null,
                  currentPlan.interval,
                  showConfirmModal.buttonText,
                  currentPlan,
                  showConfirmModal,
                  props.subscription
                );
                window.location.href = "/";
                window.NotificationUtils.showSuccess(
                  "Plan Changed Successfully"
                );
              }}
              style={{
                backgroundColor: "white",
                border: "1px solid #800520",
                color: "#800520",
                width: "120px",
                fontWeight: 600,
              }}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Confirm
            </TAButton>
            <TAButton
              variant="contained"
              onClick={() => handleClose()}
              style={{ width: "120px", fontWeight: 600 }}
              // disabled={submitDisabled || loading}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Cancel
            </TAButton>
          </Box>
        </ModalBody>
      </Modal>
    );
  };
  const renderPopupModal = () => {
    const renderCloseButton = () => (
      <CloseButton
        color="#FFFFFF"
        style={{
          backgroundColor: "#802520",
          borderRadius: "50%",
          width: "80px",
          height: "80px",
        }}
        onClick={handleClose}
      >
        <CloseRoundedIcon
          fontSize="medium"
          style={{ marginTop: 20, marginRight: 20, color: "#FFFFFF" }}
        />
      </CloseButton>
    );

    const renderForm = () => (
      <Box width="100%" my={4} style={{ borderRadius: "15px" }}>
        <Input
          label={
            formDetails.title === "Enterprise Solution" // ? "Enterprise Name"
              ? "Business Name"
              : "Business Name"
            // : "Private Practice Name"
          }
          value={formDetails.enterpriseName}
          // error={error.name}
          onChange={(e) =>
            setFormDetails({ ...formDetails, enterpriseName: e.target.value })
          }
          variant="standard"
        />
        <Input
          label={
            formDetails.title === "Enterprise Solution"
              ? "Enterprise Admin Name"
              : "Name"
            // : "Private Practice Solution Name"
          }
          value={formDetails.enterpriseAdminName}
          // error={error.email}
          onChange={(e) =>
            setFormDetails({
              ...formDetails,
              enterpriseAdminName: e.target.value,
            })
          }
          variant="standard"
        />
        <Input
          label={
            formDetails.title === "Enterprise Solution"
              ? "Enterprise Email"
              : "Email"
            // : "Private Practice Email"
          }
          value={formDetails.email}
          // error={error.email}
          onChange={(e) =>
            setFormDetails({ ...formDetails, email: e.target.value })
          }
          variant="standard"
        />
        {/* <Input
          label="Password"
          value={email}
          error={error.email}
          onChange={e => setEmail(e.target.value)}
          variant="standard"
        /> */}
        {/* <Input
          label="What can we do for you?"
          value={message}
          multiline={true}
          rows="5"
          onChange={e => setMessage(e.target.value)}
          variant="standard"
        /> */}
      </Box>
    );

    const handleClose = () => {
      setShowModal((prev) => ({
        ...prev,
        value: !prev.value,
        modalTitle: "",
        button: false,
      }));
    };

    return (
      <Modal
        isOpen={showModal.value}
        style={{ width: "90%", maxWidth: "1200px", minWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          setShowModal((prev) => ({
            ...prev,
            value: !prev.value,
            modalTitle: "",
            button: false,
          }));
        }}
      >
        {/* <ModalHeader>Test Header</ModalHeader>
         <ModalBody>Test Body</ModalBody> */}
        <Grid container style={{ overflow: "hidden", position: "relative" }}>
          {renderCloseButton()}
          <Box
            width="54%"
            display={{ xs: "none", sm: "none", md: "block" }}
            position="relative"
          >
            <Box
              bgcolor={"#802520" + "40"}
              width="100%"
              height="100%"
              position="absolute"
            />
            <img
              src="assets/img/contactFormSide.png"
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box width={{ xs: "100%", sm: "100%", md: "46%" }}>
            <Box padding={6} pt={8}>
              <Typography color="primary" style={{ fontSize: "24px" }}>
                Fill in the form below
              </Typography>
              {/* <Typography style={{ color: "#ADADAD", fontSize: "2.6em" }}>
                We will get back soon
              </Typography> */}
              {renderForm()}
              <Box width="100%" display="flex" justifyContent="flex-end">
                <TAButton
                  variant="contained"
                  // onClick={() => handleCheckout()}
                  disabled={formDetails.url === ""}
                  color="primary"
                >
                  {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
                  {/*Checkout*/}
                  <Link className="checkoutbtn" href={formDetails.url}>
                    Checkout
                  </Link>
                </TAButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Modal>
    );
  };
  const renderResubscribeModal = (props) => {
    const {
      trial = {},
      currentTier = {},
      upcomingTier = {},
    } = props.subscription;
    const handleClose = () => {
      setResubscribeModal({ ...showResubscribeModal, value: false });
    };
    return (
      <Modal
        isOpen={showResubscribeModal.value}
        style={{ width: "80%", maxWidth: "1000px", minWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          setResubscribeModal((prev) => ({
            ...prev,
            value: !prev.value,
            title: "",
            button: false,
          }));
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#6E6E6E",
              fontSize: "22px",
              lineHeight: "30px",
              textAlign: "center",
              fontWeight: 700,
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            Would you like to resubscribe to the {showResubscribeModal.title} (
            {currentPlan.interval}ly)?
            <br />
            If you wish to resubscribe, the next charge to your credit card will
            be {moment(currentTier.endDate * 1000).format("MMM DD, YYYY")}
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            padding: " 20px 30px ",
            paddingBottom: "44px",
          }}
        >
          {/*{showResubscribeModal.title === "Enterprise Solution" ? (*/}
          {/*   <p style={{ padding: "0 40px 20px 40px" }}>*/}
          {/*       Would you like to resubscribe to the {showResubscribeModal.title} ({currentPlan.interval}ly)?*/}
          {/*       <br />*/}
          {/*       If you wish to resubscribe, the next charge to your credit card will be {moment(*/}
          {/*      currentPlan.endDate * 1000*/}
          {/*   ).format("MMM DD, YYYY")}*/}
          {/*   </p>*/}
          {/*) : (*/}
          {/*   <p style={{ padding: "0 40px 20px 40px" }}>*/}
          {/*       Your subscription will not be renewed.*/}
          {/*       <br />*/}
          {/*       You will be downgraded to 'Free' version after your subscription*/}
          {/*       period ends, and you'll lose access to all paid features.*/}
          {/*   </p>*/}
          {/*)}*/}

          <Box width="100%" display="flex" justifyContent="space-around">
            <TAButton
              variant="contained"
              onClick={async () => {
                handleClose();
                let result = await window.FortisForma.database.stripeCall(
                  `downgrade/subscription/${props.user.customerId}`,
                  null,
                  null,
                  currentPlan.interval,
                  showResubscribeModal.buttonText,
                  currentPlan,
                  showResubscribeModal,
                  props.subscription
                );

                window.location.href = "/";
                window.NotificationUtils.showSuccess(
                  "Plan Changed Successfully"
                );
              }}
              style={{
                width: "215px",
                fontSize: "20px",
              }}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Yes, Resubscribe
            </TAButton>
            <TAButton
              style={{
                backgroundColor: "white",
                border: "1px solid #800520",
                color: "#800520",
                width: "215px",
                fontSize: "20px",
              }}
              variant="contained"
              onClick={() => handleClose()}
              // disabled={submitDisabled || loading}
              color="primary"
            >
              {/* {loading ? <CircularProgress size={20}/> : "Request a callback"} */}
              Cancel
            </TAButton>
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  const renderPlanButton = () => {
    const isMonthlySelected = billingType === PRICING_TYPES.MONTHLY;

    let monthlyBtnStyle = {};
    let yearlyBtnStyle = {};
    const inactiveBtn = {
      color: "#6B6B6B",
      fontWeight: 400,
    };

    isMonthlySelected
      ? (yearlyBtnStyle = inactiveBtn)
      : (monthlyBtnStyle = inactiveBtn);

    return (
      <ButtonGroup color="primary" style={{ margin: "0 auto" }}>
        <StyledButton
          onClick={() => setBillingType(PRICING_TYPES.MONTHLY)}
          style={monthlyBtnStyle}
        >
          Monthly Billing
        </StyledButton>
        <StyledButton
          onClick={() => setBillingType(PRICING_TYPES.YEARLY)}
          style={yearlyBtnStyle}
        >
          Yearly Billing (save 10%)
        </StyledButton>
      </ButtonGroup>
    );
  };
  const progressIndicator = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  };
  return (
    <>
      <Box width="100%" mb={2.5} mt={2}>
        <Typography
          color="primary"
          style={{
            fontWeight: "bold",
            fontSize: "21px",
            lineHeight: 1.2,
            // fontWeight: "bold"
          }}
          align="center"
        >
          Manage Subscription
        </Typography>
      </Box>
      <Box
        width="100%"
        mb={{ xs: 5, sm: 8.5 }}
        style={{
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {renderPlanButton()}
      </Box>
      {loader ? (
        progressIndicator()
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingBottom: "30px",
          }}
        >
          {PRICING_CARDS.map((data, index) => (
            <PlanCard
              data={data}
              billingType={billingType}
              showModalHandler={showModalHandler}
              showResubscribeModalHandler={showResubscribeModalHandler}
              showConfirmModalHandler={showConfirmModalHandler}
              currentPlan={currentPlan}
              showDowngradeModalhandler={showDowngradeModalhandler}
              // key={`${data.title} ${index}`}
              {...props}
            />
          ))}
        </div>
      )}

      {renderPopupModal()}
      {renderConfirmModal()}
      {renderDowngradeModal()}
      {renderResubscribeModal(props)}
      {renderStripeModal()}
    </>
  );
};

export default OurPricing;

const PlanCard = (props) => {
  const { data, billingType, currentPlan, user, subscription } = props;
  const {
    trial = {},
    currentTier = {},
    upcomingTier = {},
  } = props.subscription;
  //   const classes = useStyles();

  let trialEndDate = null;

  const isFree = props.user.tier && props.user.tier === "Coaching Solution";
  const isFreePlan = data.title === "Coaching Solution";
  const isFreeTier = data.monthly_amount === 0;

  let interval =
    currentPlan.interval === "month"
      ? 0
      : currentPlan.interval === "year"
      ? 1
      : "free";
  const isActive =
    (interval === billingType || interval === "free") &&
    currentPlan.plan === data.title;

  const isBillingMonthly = billingType === PRICING_TYPES.MONTHLY;
  const isSubscriptionsActive = props.user.active;
  const isEnterpriseAdmin = props.user.role === ROLES.ENTERPRISE_ADMIN;
  const isPractitioner = props.user.role === ROLES.PRACTITIONER;
  const isTrainer = props.user.role === ROLES.TRAINER;

  // const background = isActive ? "#801422" : "#FFF";
  let background = "#FFF";
  let titleBackground = "#F8F8F8";
  let buttonText = data.buttonText;
  let bottomBorder = `1px solid #CDCDCD`;
  let whatsIncludedStyle = {
    color: "#D3ADB2",
    fontSize: "18.5px",
  };
  let pointsTextStlye = "#383838";
  let borderStyle = "none";

  let activeButtonProps = {};
  let textColor;
  if (isActive && (isSubscriptionsActive || isFree)) {
    if (isFreePlan) {
      buttonText = "Current Plan";
    }
    // if (!isFreePlan && isSubscriptionsActive) {
    //     buttonText = "Cancel Subscription";
    // }
    background = isActive ? "#80142255" : "#FFF";
    titleBackground = isActive ? "#690F1B" : "#F8F8F8";
    bottomBorder = `1px solid ${isActive ? "#6D111D" : "#CDCDCD"}`;
    whatsIncludedStyle = {
      color: isActive ? "#FFFF" : "#D3ADB2",
      fontSize: "18.5px",
    };
    pointsTextStlye = { color: isActive ? "#FFFF" : "#383838" };

    activeButtonProps = isActive
      ? { backgroundColor: "white", textColor: "#802520" }
      : {};
    borderStyle =
      isActive && interval === billingType ? "1px solid #800520" : "none";
    textColor =
      isActive && (isSubscriptionsActive || isFree) ? "#fff" : "#802520";
  }

  if (!isSubscriptionsActive && isFreePlan) {
    if (isFreePlan) {
      buttonText = "Current Plan";
    }
    // if (!isFreePlan && isSubscriptionsActive) {
    //     buttonText = "Cancel Subscription";
    // }
    background = "#80142255";
    titleBackground = "#690F1B";
    bottomBorder = `1px solid #6D111D`;
    whatsIncludedStyle = {
      color: "#FFFF",
      fontSize: "18.5px",
    };
    pointsTextStlye = { color: "#FFFF" };

    activeButtonProps = { backgroundColor: "white", textColor: "#802520" };

    borderStyle = "1px solid #800520";
    textColor = "#fff";
  }

  const price = isBillingMonthly ? data.monthly_amount : data.yearly_amount;
  const productId = isBillingMonthly ? data.monthly_id : data.yearly_id;
  const monthlyTextStyle = {
    color: isActive && isSubscriptionsActive ? "#00000cc" : "#A5A5A5",
    display: "flex",
    alignItems: "end",
    lineHeight: 2.2,
    fontSize: "12px",
  };

  const buttonDisable = () => {
    if (data.title === "Coaching Solution") {
      if (
        props.user.role === ROLES.TRAINER &&
        props.user.tier === "Coaching Solution"
      );
      return false;
      // if (props.user.role === ROLES.TRAINER && !props.user.privatePractice)
      // return false;
    }

    if (data.title === "Enterprise Solution") {
      if (!isSubscriptionsActive && props.user.role === ROLES.ENTERPRISE_ADMIN)
        return false;
      if (
        props.user.role === ROLES.ENTERPRISE_ADMIN &&
        interval === billingType &&
        isSubscriptionsActive
      )
        return false;
    }

    if (data.title === "Private Practice Solution") {
      if (
        !isSubscriptionsActive &&
        (props.user.tier === TIER.PRIVATE || props.user.role === ROLES.TRAINER)
      )
        return false;
      if (
        (props.user.tier === TIER.PRIVATE ||
          props.user.role === ROLES.TRAINER) &&
        interval === billingType &&
        isSubscriptionsActive
      )
        return false;
    }

    return true;
  };

  const hideInfoTab = () => {
    let selectedInterval = {
      0: "month",
      1: "year",
    };
    if (data.title === "Coaching Solution") {
      return true;
    }

    if (data.title === "Private Practice Solution") {
      if (
        trial.active &&
        trial.current.tier === data.title &&
        trial.current.interval === selectedInterval[billingType]
      ) {
        trialEndDate = moment(trial.current.endDate).format("MMM DD, YYYY");
        if (
          trial.current.tier.startTime === currentTier.startTime &&
          trial.current.tier.endTime === currentTier.endTime
        ) {
          buttonText = "Start Subscription";
        } else {
        }
        return false;
      }
    }

    if (data.title === "Enterprise Solution") {
      if (
        trial.active &&
        trial.current.tier === data.title &&
        trial.current.interval === selectedInterval[billingType]
      ) {
        trialEndDate = moment(trial.current.endDate).format("MMM DD, YYYY");
        if (
          trial.current.tier.startTime === currentTier.startTime &&
          trial.current.tier.endTime === currentTier.endTime
        )
          buttonText = "Start Subscription";
        return false;
      }
    }

    return true;
  };

  let hideTab = true;
  let tabText = "";
  let disableButton = true;
  const planCardSettings = () => {
    let selectedInterval = {
      0: "month",
      1: "year",
    };

    if (data.title === "Coaching Solution") {
      if (user.tier === "Coaching Solution" || user.active === false) {
        buttonText = "CURRENT TIER";
        disableButton = true;
      } else {
        if (user.active) {
          buttonText = "DOWNGRADE SUBSCRIPTION";
          disableButton = false;
        } else {
          buttonText = "START FOR FREE";
          disableButton = false;
        }
      }
      if (upcomingTier) {
        if (upcomingTier.tier === TIER.FREE) {
          buttonText = "UPCOMING TIER";
          disableButton = true;
        }
      }

      hideTab = true;
    }

    if (data.title === "Private Practice Solution") {
      disableButton = false;
      if (
        trial.privatePractice === true &&
        props.subscription.privatePractice
      ) {
        buttonText = "START FREE TRIAL";
      } else {
        if (user.tier === "Enterprise Solution" && user.active) {
          buttonText = "DOWNGRADE SUBSCRIPTION";
        } else {
          // if (!trial.privatePractice && !trial.enterprise) {
          //   buttonText = "START FREE TRIAL";
          // }
          if (props.subscription.privatePractice === false) {
            buttonText = "UPGRADE";
            disableButton = false;
          } else {
            buttonText = "START FREE TRIAL";
          }
        }
      }
      if (trial.active) {
        if (
          trial.current.tier === "Private Practice Solution" &&
          trial.current.interval === selectedInterval[billingType]
        ) {
          hideTab = false;
          if (currentTier.is_paid && !trial.active) {
            tabText = `Subscription ends on ${moment(
              trial.current.endDate * 1000
            ).format("MMM DD, YYYY")}`;
          } else {
            tabText = `Trial ends on ${moment(
              trial.current.endDate * 1000
            ).format("MMM DD, YYYY")}`;
          }
          if (
            trial.current.startDate === currentTier.startDate &&
            trial.current.endDate === currentTier.endDate &&
            !currentTier.cancelled
          ) {
            if (currentTier.is_paid) {
              buttonText = "CANCEL SUBSCRIPTION";
              disableButton = false;
            } else {
              buttonText = "START SUBSCRIPTION";
              disableButton = false;
            }
            // buttonText = "START SUBSCRIPTION";
            // buttonText = "CANCEL SUBSCRIPTION";
            // disableButton = false;
          } else {
            if (
              currentTier.tier === "Private Practice Solution" &&
              currentTier.active &&
              !currentTier.cancelled
            ) {
              if (currentTier.is_paid) {
                buttonText = "CANCEL SUBSCRIPTION";
                disableButton = false;
              } else {
                buttonText = "START SUBSCRIPTION";
                disableButton = false;
              }
              // buttonText = "CANCEL SUBSCRIPTION";
              // disableButton = false;
            }

            if (
              currentTier.tier === "Private Practice Solution" &&
              currentTier.active &&
              currentTier.cancelled === true
            ) {
              tabText = "";
              hideTab = true;
              buttonText = `RESUBSCRIBE BEFORE ${moment(
                trial.current.endDate * 1000
              ).format("MMM DD, YYYY")}`;
              disableButton = false;
            }
          }
        }

        if (
          trial.current.tier === "Private Practice Solution" &&
          trial.current.interval !== selectedInterval[billingType]
        ) {
          hideTab = true;
          tabText = "";
          // buttonText = "UPGRADE";
          buttonText = `SWITCH TO ${selectedInterval[
            billingType
          ].toUpperCase()}LY PLAN`;
          disableButton = false;
        }
      } else {
        if (
          currentTier.tier === "Private Practice Solution" &&
          currentTier.interval === selectedInterval[billingType]
        ) {
          if (currentTier.active || currentTier.is_paid) {
            hideTab = false;
            tabText = `Valid until ${moment(currentTier.endDate * 1000).format(
              "MMM DD, YYYY"
            )}`;

            if (currentTier.cancelled === false) {
              buttonText = "CANCEL SUBSCRIPTION";
              disableButton = false;
              // if (upcomingTier !== null) {
              //     buttonText = "RENEW SUBSCRIPTION";
              //     disableButton = false;
              // } else {
              //     buttonText = "CANCEL SUBSCRIPTION";
              //     disableButton = false;
              // }
            } else {
              hideTab = true;
              buttonText = `RESUBSCRIBE BEFORE ${moment(
                currentTier.endDate * 1000
              ).format("MMM DD, YYYY")}`;
              disableButton = false;
            }
          } else {
            hideTab = true;
            tabText = "";
            disableButton = false;
            if (trial.privatePractice) {
              buttonText = "START FREE TRIAL";
            } else {
              buttonText = "START SUBSCRIPTION";
            }
          }
        }
        if (
          currentTier.tier === "Private Practice Solution" &&
          currentTier.interval !== selectedInterval[billingType]
        ) {
          hideTab = true;
          tabText = "";
          // buttonText = "UPGRADE";
          buttonText = `SWITCH TO ${selectedInterval[
            billingType
          ].toUpperCase()}LY PLAN`;
          disableButton = false;
        }
      }
      if (upcomingTier) {
        if (
          upcomingTier.tier === TIER.PRIVATE &&
          upcomingTier.interval === selectedInterval[billingType]
        ) {
          buttonText = "UPCOMING TIER";
          disableButton = true;
          hideTab = true;
          // tabText = "Usman";
        }
      }
    }

    if (data.title === "Enterprise Solution") {
      disableButton = false;
      if (trial.enterprise && props.subscription.enterprise) {
        buttonText = "START FREE TRIAL";
      } else {
        if (props.subscription.enterprise === false) {
          buttonText = "UPGRADE";
          disableButton = false;
        } else {
          buttonText = "START FREE TRIAL";
        }
        // if (!trial.privatePractice && !trial.enterprise) {
        //   buttonText = "START FREE TRIAL";
        // } else {
        //   buttonText = "UPGRADE";
        // }
      }
      if (trial.active) {
        if (
          trial.current.tier === "Enterprise Solution" &&
          trial.current.interval === selectedInterval[billingType]
        ) {
          hideTab = false;
          if (currentTier.is_paid && trial.active === false) {
            tabText = `Subscription ends on ${moment(
              trial.current.endDate * 1000
            ).format("MMM DD, YYYY")}`;
          } else {
            tabText = `Trial ends on ${moment(
              trial.current.endDate * 1000
            ).format("MMM DD, YYYY")}`;
          }

          if (
            trial.current.startDate === currentTier.startDate &&
            trial.current.endDate === currentTier.endDate &&
            !currentTier.cancelled
          ) {
            if (currentTier.is_paid) {
              buttonText = "CANCEL SUBSCRIPTION";
              disableButton = false;
            } else {
              buttonText = "START SUBSCRIPTION";
              disableButton = false;
            }
          } else {
            if (
              currentTier.tier === "Enterprise Solution" &&
              currentTier.active &&
              !currentTier.cancelled
            ) {
              if (currentTier.is_paid) {
                buttonText = "CANCEL SUBSCRIPTION";
                disableButton = false;
              } else {
                buttonText = "START SUBSCRIPTION";
                disableButton = false;
              }
              // buttonText = "CANCEL SUBSCRIPTION";
              // disableButton = false;
            }

            if (
              currentTier.tier === "Enterprise Solution" &&
              currentTier.active &&
              currentTier.cancelled
            ) {
              tabText = "";
              hideTab = true;
              buttonText = `RESUBSCRIBE BEFORE ${moment(
                trial.current.endDate * 1000
              ).format("MMM DD, YYYY")}`;
              disableButton = false;
            }
          }
        }
        if (
          trial.current.tier === "Enterprise Solution" &&
          trial.current.interval !== selectedInterval[billingType]
        ) {
          hideTab = true;
          tabText = "";
          // buttonText = "UPGRADE";
          buttonText = `SWITCH TO ${selectedInterval[
            billingType
          ].toUpperCase()}LY PLAN`;
          disableButton = false;
        }
      } else {
        if (
          currentTier.tier === "Enterprise Solution" &&
          currentTier.interval === selectedInterval[billingType]
        ) {
          if (currentTier.active || currentTier.is_paid) {
            hideTab = false;
            tabText = `Valid until ${moment(currentTier.endDate * 1000).format(
              "MMM DD, YYYY"
            )}`;

            if (!currentTier.cancelled) {
              // if (upcomingTier !== null) {
              //     buttonText = "RENEW SUBSCRIPTION";
              //     disableButton = false;
              // } else {
              //     buttonText = "CANCEL SUBSCRIPTION";
              //     disableButton = false;
              // }
              buttonText = "CANCEL SUBSCRIPTION";
              disableButton = false;
            } else {
              hideTab = true;
              buttonText = `RESUBSCRIBE BEFORE ${moment(
                currentTier.endDate * 1000
              ).format("MMM DD, YYYY")}`;
              disableButton = false;
            }
          } else {
            hideTab = true;
            tabText = "";
            buttonText = `RESUBSCRIBE BEFORE ${moment(
              currentTier.endDate * 1000
            ).format("MMM DD, YYYY")}`;
            disableButton = false;
          }
        }
        if (
          currentTier.tier === "Enterprise Solution" &&
          currentTier.interval !== selectedInterval[billingType]
        ) {
          hideTab = true;
          tabText = "";
          // buttonText = "UPGRADE";
          buttonText = `SWITCH TO ${selectedInterval[
            billingType
          ].toUpperCase()}LY PLAN`;
          disableButton = false;
        }
      }
      if (upcomingTier) {
        if (
          upcomingTier.tier === TIER.ENTERPRISE &&
          upcomingTier.interval === selectedInterval[billingType]
        ) {
          buttonText = "UPCOMING TIER";
          disableButton = true;
          hideTab = true;
          // tabText = "Usman";
        }
      }
    }
  };
  planCardSettings();

  const planButtonHandler = async () => {
    if (data.title === "Coaching Solution" && disableButton === false) {
      if (buttonText === "DOWNGRADE SUBSCRIPTION") {
        props.showDowngradeModalhandler(
          null,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }

      disableButton = true;
    }

    if (data.title === "Private Practice Solution" && disableButton === false) {
      if (buttonText === "CANCEL SUBSCRIPTION") {
        props.showConfirmModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }
      if (buttonText === "START FREE TRIAL") {
        if (props.user.tier === TIER.ENTERPRISE) {
          props.showDowngradeModalhandler(
            data[billingType].id,
            isBillingMonthly ? "month" : "year",
            data.title,
            buttonText
          );
        } else {
          props.showModalHandler(
            data[2].id,
            isBillingMonthly ? "month" : "year",
            data.title,
            buttonText
          );
        }
      }
      if (
        buttonText === "START SUBSCRIPTION" ||
        buttonText === "UPGRADE" ||
        buttonText === "RESUBSCRIBE SUBSCRIPTION" ||
        buttonText === "SWITCH TO MONTHLY PLAN" ||
        buttonText === "SWITCH TO YEARLY PLAN" ||
        buttonText === "RENEW SUBSCRIPTION"
      ) {
        props.showModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      } else if (buttonText === "DOWNGRADE SUBSCRIPTION") {
        props.showDowngradeModalhandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      } else if (
        buttonText !== "CANCEL SUBSCRIPTION" &&
        buttonText !== "START FREE TRIAL"
      ) {
        props.showResubscribeModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }
    }

    if (data.title === "Enterprise Solution" && disableButton === false) {
      if (buttonText === "CANCEL SUBSCRIPTION") {
        props.showConfirmModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }
      if (buttonText === "START FREE TRIAL") {
        props.showModalHandler(
          data[2].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }
      if (
        buttonText === "START SUBSCRIPTION" ||
        buttonText === "UPGRADE" ||
        buttonText === "RESUBSCRIBE SUBSCRIPTION" ||
        buttonText === "SWITCH TO MONTHLY PLAN" ||
        buttonText === "SWITCH TO YEARLY PLAN" ||
        buttonText === "RENEW SUBSCRIPTION"
      ) {
        props.showModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      } else if (buttonText === "DOWNGRADE SUBSCRIPTION") {
        props.showDowngradeModalhandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      } else if (
        buttonText !== "CANCEL SUBSCRIPTION" &&
        buttonText !== "START FREE TRIAL"
      ) {
        props.showResubscribeModalHandler(
          data[billingType].id,
          isBillingMonthly ? "month" : "year",
          data.title,
          buttonText
        );
      }
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "8px", // maxWidth: "1000px",
        boxShadow: "0 0 20px rgb(0 0 0 / 0.2)",
        borderRadius: "10px",
        maxWidth: "380px",
        minHeight: "742.25px",
        border: borderStyle,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor={background}
        borderRadius={10}
        height="100%"
        overflow="hidden"
        className="planCard"
        textAlign="left"
      >
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            // background-color: rgb(89, 25, 22);
          }}
        >
          <Box bgcolor={titleBackground} padding="20px 30px">
            <StyledTypography
              style={{
                fontSize: "20px",
                color: textColor,
                padding: " 0 10px",
              }}
              // className={`${classes.planCardTitle} ${isActive ? classes.whiteColor : classes.primaryColor}`}
            >
              {data.title}
            </StyledTypography>
          </Box>
          <Box mx={3} pb={1} pt={4} borderBottom={bottomBorder} display="flex">
            <StyledTypography
              //   className={`noMargin ${classes.titleText} ${
              //     isActive ? classes.whiteColor : ""
              //   }`}
              style={{
                display: "flex",
                flexGrow: 1,
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {data.title === "Coaching Solution" ? "Free" : `$${price}`}
            </StyledTypography>
            {!isFreePlan && (
              <StyledTypography style={monthlyTextStyle}>
                {isBillingMonthly ? "/month" : "/year, billed anually"}
              </StyledTypography>
            )}
          </Box>
          <Box mx={3} borderBottom={bottomBorder} py={2.5}>
            <StyledTypography
              // className={classes.bold}
              style={{ ...whatsIncludedStyle, fontWeight: "bold" }}
            >
              What's Included
            </StyledTypography>
          </Box>
          {data.points.map((point) => (
            <Box mx={3} borderBottom={bottomBorder} py={2}>
              <StyledTypography
                style={{ fontSize: "13px" }}
                // className={classes.planCardPoints}
                dangerouslySetInnerHTML={{ __html: point }}
              />
            </Box>
          ))}
        </Grid>
        <Grid container>
          <Box width="100%" mx={3} mt={5} mb={2.5}>
            {!hideTab && (
              <TAButton
                width="100%"
                color="primary"
                variant="contained"
                height="60px"
                style={{ marginBottom: "15px", cursor: "unset" }}
                disabled={hideTab}
                {...activeButtonProps}
              >
                {tabText}
              </TAButton>
            )}
            <TAButton
              width="100%"
              color="primary"
              variant="contained"
              height="60px"
              border={buttonText === "START SUBSCRIPTION"}
              disabled={disableButton}
              {...activeButtonProps}
              onClick={planButtonHandler}
            >
              {buttonText}
            </TAButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
