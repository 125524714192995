import React from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { BubbleLoader } from "react-css-loaders";

export default class FortisFormaSelect extends React.Component {
  render() {
    if (!this.props.options.length && this.props.handleLoading !== false) {
      return (
        <BubbleLoader
          style={{
            marginLeft: 55,
            marginTop: this.props.loaderPadding || 0,
            marginBottom: this.props.loaderPadding || 0,
          }}
          color={"#800520"}
          size={4}
        />
      );
    }
    if (this.props.creatable) {
      return (
        <Creatable
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "rgba(189, 0, 0, 0.3)",
              primary: "#800520",
            },
          })}
          isClearable={true}
          classNamePrefix={
            this.props.classNamePrefix || "fortisFormaDropDownSelect"
          }
          {...this.props}
        />
      );
    }
    return (
      <Select
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "rgba(189, 0, 0, 0.3)",
            primary: "#800520",
          },
        })}
        isClearable={true}
        classNamePrefix={
          this.props.classNamePrefix || "fortisFormaDropDownSelect"
        }
        {...this.props}
      />
    );
  }
}
