import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import PasswordInput from '../../components/passwordInput/passwordInput';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
        this["onChange_oldPassword"] = this.onChange.bind(this, "oldPassword");
        this["onChange_newPassword"] = this.onChange.bind(this, "newPassword");
        this["onChange_confirmPassword"] = this.onChange.bind(this, "confirmPassword");
    }

    onChange(key, event, value) {
        let update = {};
        update[key] = event.target.value || (value ? value.newValue : "");
        this.setState(update);
    }

    checkErrorsForPasswordChange = () => {
        if(!this.props.user.email || this.props.user.email === "") {
          window.NotificationUtils.showError("Email not found");
          return true;
        }
    
        if(!this.state.oldPassword || this.state.oldPassword === "") {
          window.NotificationUtils.showErrorInForm(document.querySelector("#oldPassword"), "oldPassword", "Please enter old password");
          return true;
        }
    
        if(!this.state.newPassword || this.state.newPassword === "") {
          window.NotificationUtils.showErrorInForm(document.querySelector("#newPassword"), "newPassword", "Please enter new password");
          return true;
        }
    
        if(!this.state.confirmPassword || this.state.confirmPassword === "") {
          window.NotificationUtils.showErrorInForm(document.querySelector("#confirmPassword"), "confirmPassword", "Please enter confirm password");
          return true;
        }
    
        if(this.state.oldPassword === this.state.newPassword) {
          window.NotificationUtils.showErrorInForm(document.querySelector("#newPassword"), "newPassword", "Please enter a different password");
          return true;
        }
    
        if(this.state.newPassword.length < 6) {
          window.NotificationUtils.showErrorInForm(document.querySelector("#newPassword"), "newPassword", "Password length should be greater than 6");
          return true;
        }
    
        if(this.state.newPassword !== this.state.confirmPassword) {
          window.NotificationUtils.showErrorInForm(document.querySelector("#confirmPassword"), "confirmPassword", "Passwords don't match");
          return true;
        }
    }
    
    show = () => {
        this.setState({changePasswordModal: true})
    }
    
    hide = () => {
        this.setState({changePasswordModal: false, oldPassword: "", newPassword: "", confirmPassword: "", modalLoader: false})
    }
    
    changePassword = () => {
        var email = this.props.user.email;
        var oldPassword = this.state.oldPassword;
        var newPassword = this.state.newPassword;
        const _this = this;
        if(this.checkErrorsForPasswordChange()) {
            this.setState({modalLoader: false});
            return;
        }
        this.setState({modalLoader: true})
        window.FortisForma.database.signInWithEmailAndPassword(email, oldPassword).then(() => {
          window.FortisForma.database.updatePassword(newPassword).then(() => {
            _this.hide();
            window.NotificationUtils.showSuccess("Password change successful");
          }).catch(function(error) {
            console.error(error)
            window.NotificationUtils.showError("Password change unsuccessful");
          });
        }).catch(function(error) {
          console.error(error)
          window.NotificationUtils.showError("Incorrect password");
          _this.setState({modalLoader: false});
        });
    }
    renderModalContent() {
        return(
            <ModalBody>
              <div className="crossIcon" onClick={this.hide}>x</div>
              <PasswordInput showIndicator={false} style={{marginTop: 0}} label={"Old Password"} value={this.state.oldPassword} onChange={this.onChange_oldPassword} name="oldPassword" type="password" inputId="oldPassword" />
             
              <br />
              <PasswordInput showIndicator={true} style={{marginTop: 0}}  label={"New Password"} value={this.state.newPassword} onChange={this.onChange_newPassword} name="newPassword" type="password" inputId="newPassword" />
              
              <br />
              <PasswordInput showIndicator={true} style={{marginVertical: '0px !important', paddingTop: '0px !important', paddingBottom: '0px !important'}} label={"Confirm Password"} value={this.state.confirmPassword} onChange={this.onChange_confirmPassword} name="confirmPassword" type="password" inputId="confirmPassword" />

              <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', paddingTop: 20}}><Button onClick={this.changePassword} color="primary">Save</Button></div>
            </ModalBody>
        )
    }
    
    renderLoader() {
        return(
          <ModalBody style={{height: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Spinner color="primary" />
          </ModalBody>
        )
    }
    
    render() {
        return (
            <Modal isOpen={this.state.changePasswordModal} id="changePasswordModal" backdrop={true}>
                <ModalHeader>Change Password</ModalHeader>
                { this.state.modalLoader ? (this.renderLoader()) : (this.renderModalContent()) }
            </Modal>
        )
    }
}