import React, { Component } from "react";

import ReactPlayer from "react-player";

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

export default class ExerciseCard extends Component {
  constructor(props) {
    super(props);
    this.posterURL = props.data.posterURL;
    this.onSelectButtonClick = this.onSelectButtonClick.bind(this);
    this.state = {
      playing: true,
      showReplay: false,
    };
    this.playerRef = null;
  }

  componentDidUpdate() {
    // HACK placeholder not refreshing
    if (this.posterURL !== this.props.data.posterURL) {
      this.posterURL = this.props.data.posterURL;
      let showPreview =
        this.playerRef &&
        this.playerRef.state &&
        this.playerRef.state.showPreview;
      if (showPreview) {
        this.playerRef.setState(
          {
            showPreview: !showPreview,
          },
          () => {
            this.playerRef.setState({
              showPreview: showPreview,
            });
          }
        );
      }
    }
  }

  onFinishVideo = () => {
    this.playerRef && this.playerRef.seekTo(0);
    this.setState({
      playing: false,
      showReplay: true,
    });
  };

  onPressReplay = () => {
    this.setState({
      playing: true,
      showReplay: false,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!window.areEqualShallow) {
      return true;
    }

    if (!window.areEqualShallow(this.state, nextState)) {
      return true;
    }

    if (this.props.selectEnabled !== nextProps.selectEnabled) {
      return true;
    }

    if (!window.areEqualShallow(this.props.data, nextProps.data)) {
      return true;
    }

    if (!window.areEqualShallow(this.props.cardStyle, nextProps.cardStyle)) {
      return true;
    }

    if (
      this.props.videoWidth !== nextProps.videoWidth ||
      this.props.videoHeight !== nextProps.videoHeight
    ) {
      return true;
    }

    if (this.props.selectEnabled !== nextProps.selectEnabled) {
      return true;
    }

    if (this.props.loading !== nextProps.loading) {
      return true;
    }

    return false;
  }

  onSelectButtonClick() {
    this.props.onSelect(this.props.data);
  }

  renderExtras() {}

  renderIcon() {
    return this.props.selectEnabled ? (
      <div style={{ paddingBottom: 10 }}>
        <div
          id="addExercisePlusButton"
          className="selectButtonContainer"
          style={
            this.props.cardType === "similarExercise"
              ? {
                  width: "initial",
                  height: "initial",
                  borderRadius: "10%",
                  padding: "0 6px",
                  fontSize: 10,
                  fontWeight: 600,
                }
              : null
          }
          onClick={this.onSelectButtonClick}
        >
          <span className="selectButton">{this.props.selectButtonView}</span>
        </div>
      </div>
    ) : null;
  }

  render() {
    return (
      <Card
        className={`exerciseCard ${this.props.klassName || ""} ${
          this.className || ""
        }`}
        style={this.props.cardStyle}
        id={`card${this.props.data.id}`}
      >
        <CardTitle>
          <Row style={{ paddingBottom: 2, paddingTop: 4 }}>
            <Col
              lg="9"
              xs="9"
              id={`name${this.props.data.id}`}
              className="exerciseName"
            >
              {this.props.data.name.length > 56
                ? `${this.props.data.name.substring(0, 56)}...`
                : this.props.data.name}
              {this.props.data.name.length > 50 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={`name${this.props.data.id}`}
                >
                  {this.props.data.name}
                </UncontrolledTooltip>
              ) : null}
            </Col>
            <Col lg="3" xs="3" className="level leftPadding">
              <p>
                <span className="meta exerciseCardLevel">Level</span>{" "}
                {this.props.data.level}
              </p>
            </Col>
          </Row>
        </CardTitle>
        <CardBody style={{ position: "relative" }}>
          <Row
            className={`videoContainer ${
              this.state.showReplay ? "blurVideo" : ""
            }`}
            style={{
              width: this.props.videoWidth,
              height: this.props.videoHeight,
            }}
          >
            {!this.props.data.videoURL && this.props.data.posterURL ? (
              <img
                src={this.props.data.posterURL}
                width={this.props.videoWidth}
                height={this.props.videoHeight}
                className="posterImg"
                alt="Poster"
              />
            ) : (
              <ReactPlayer
                ref={(ref) => {
                  this.playerRef = ref;
                }}
                autoPlay={this.state.playing}
                playing={this.state.playing}
                playsinline={this.state.playing}
                controls={false}
                muted={true}
                url={this.props.data.videoURL}
                width={this.props.videoWidth}
                height={this.props.videoHeight}
                light={CSS.escape(this.props.data.posterURL) || true}
                onEnded={this.onFinishVideo}
              />
            )}
          </Row>

          {this.state.showReplay ? (
            <Button className="videoReplayButton" onClick={this.onPressReplay}>
              {" "}
              <i className="material-icons videReplayIcon">replay</i>
            </Button>
          ) : null}

          <div className="exerciseInfo">
            <div style={{ fontSize: "0.55rem" }}>
              <p className="details" id={`movement${this.props.data.id}`}>
                <span className="meta">Movement</span>
                {this.props.data.movement}
              </p>

              {this.props.data.movement &&
              this.props.data.movement.length > 20 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={`movement${this.props.data.id}`}
                >
                  {this.props.data.movement}
                </UncontrolledTooltip>
              ) : null}

              <p className="details" id={`equipment${this.props.data.id}`}>
                <span className="meta">Equipment</span>
                {this.props.data.equipmentCategories &&
                  this.props.data.equipmentCategories.Primary}
              </p>

              {this.props.data.equipmentCategories &&
              this.props.data.equipmentCategories.Primary &&
              this.props.data.equipmentCategories.Primary.length > 20 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={`equipment${this.props.data.id}`}
                >
                  {this.props.data.equipmentCategories.Primary}
                </UncontrolledTooltip>
              ) : null}
            </div>

            {this.renderIcon()}
          </div>

          {this.renderExtras()}
        </CardBody>
      </Card>
    );
  }
}
