import React from "react";

import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";

import Delete from "../../assets/delete.png";
import { COLLECTIONS } from "../../constants";

export default class PropertyEditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.data.name || "",
    };

    this.onChangeName = this.onChange.bind(this, "name");
  }

  componentWillReceiveProps(props) {
    this.setState({
      name: props.data.name || "",
    });
  }

  onChange(key, event) {
    let update = {};
    update[key] = event.target.value;
    this.setState(update);
  }

  onClickSave = () => {
    if (this.checkErrors()) {
      return;
    }
    this.props.onClickSave(
      Object.assign({}, this.props.data, {
        name: this.state.name,
      })
    );
  };

  onClickDelete = () => {
    window.customConfirm(
      "Are you sure you want to remove " + this.props.data.name + " ?",
      async () => {
        try {
          var result = await window.FortisForma.database.deleteDocument(
            this.props.collection,
            this.props.data.id
          );
          if (result) {
          }
          this.props.onDelete(this.props.data.id);
        } catch (e) {
          console.error(e);
        }
      }
    );
  };
  renderExportExercisesButton = () => {
    if (this.props.collection === COLLECTIONS.MOVEMENT_CATEGORIES) {
      return (
        <Button color="primary" onClick={this.onExportExercises}>
          Export Exercises
        </Button>
      );
    }
  };
  onExportExercises = async () => {
    if (!this.checkErrors()) {
      try {
        window.NotificationUtils.showConfirm("Exporting Exercises");
        await window.FortisForma.database.exportExercises(this.state.name);
      } catch (e) {
        window.NotificationUtils.showError("Error");
        console.error(e);
      }
    }
  };
  checkErrors() {
    if (!this.state.name) {
      let node = document.querySelector("#name");
      window.NotificationUtils.showErrorInForm(
        node,
        "name",
        "Name is required"
      );
      return true;
    }

    return false;
  }

  render() {
    return (
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label>Name</Label>
            <Input
              type={"text"}
              id="name"
              placeholder={this.props.placeholder}
              value={this.state.name}
              onChange={this.onChangeName}
            />
          </FormGroup>
        </Col>

        <Col lg="12">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {this.props.data.id ? (
              <img
                alt="Delete"
                onClick={this.onClickDelete}
                src={Delete}
                className="deleteIcon"
              />
            ) : null}
            <Button
              className="btn btn-outline-secondary"
              onClick={this.props.onClickCancel}
            >
              Cancel
            </Button>
            {this.renderExportExercisesButton()}
            <Button color="primary" onClick={this.onClickSave}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
