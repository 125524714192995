import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import PDFThumbnail from "../PDFThumbnail/PDFThumbnail";

const PDF = "application/pdf";
export default class UploadingImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      uploadingProgress: 0,
      cancelDownload: false,
      uploadTask: null,
    };
  }

  componentDidMount() {
    this.uploadImage();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  uploadImage = async () => {
    let imageData = this.props.imageData && this.props.imageData;
    let onProgress = (progress, uploadTask) => {
      this.uploadTask = uploadTask; //TODO:- clean code of fuction for canceling upload
      if (progress <= 100) {
        this.setState({
          uploadingProgress: Math.max(progress, 40),
        });
      }
    };

    try {
      let result = await window.FortisForma.database.uploadAttachments(
        imageData,
        this.props.pathToStore,
        onProgress
      );
      this.setState({
        loading: false,
      });

      this.saveImageWithDownloadURL(result);
    } catch (e) {
      console.error(e);
    }

    this.props.onUploadComplete();
  };

  saveImageWithDownloadURL = async (result) => {
    let path = result.metadata.fullPath;
    try {
      let downloadURL = await window.FortisForma.database.getDownloadURL(path);
      this.saveImageInNotes(downloadURL);
    } catch (e) {
      console.error(e);
    }
  };

  saveImageInNotes = async (url) => {
    let imageObject = {};
    imageObject.url = url;
    this.props.showNewUploadInCollection &&
      this.props.showNewUploadInCollection(imageObject);
  };

  cancelDownload = () => {
    this.uploadTask && this.uploadTask.cancel();
    window.NotificationUtils.showSuccess("Uploading canceled");
  };

  renderCrossButton = () => {
    return (
      <div className="imageActionButton" onClick={this.cancelDownload}>
        <IconButton size="small" onClick={this.cancelDownload}>
          <ClearIcon fontSize="small" className="imageActionButton" />
        </IconButton>
      </div>
    );
  };

  renderImageLoading = () => {
    return (
      <CircularProgress
        color="primary"
        size={16}
        variant="determinate"
        id="uploadingImageCircularProgress"
        value={this.state.uploadingProgress}
      />
    );
  };

  getImageSrc = () => {
    let imageData = new File([this.props.imageData], "image");
    let src = URL.createObjectURL(imageData);
    return src;
  };

  render() {
    return (
      <>
        <div className="uploadingImageContainer">
          {this.props.imageData.type === PDF ? (
            <PDFThumbnail
              height={56}
              className="noteImage"
              link={this.getImageSrc()}
            />
          ) : (
            <div
              className="noteImage"
              style={{
                backgroundImage: `url("${this.getImageSrc()}")`,
                width: "100%",
              }}
            />
          )}
          <div className="uploadIconContainer imageActions">
            {this.renderCrossButton()}
            {this.state.loading && this.renderImageLoading()}
          </div>
        </div>
      </>
    );
  }
}
