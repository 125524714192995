import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "../onboardField";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import AdvancedFileUpload from "../../../components/advancedFileUploader";

export default class EducationCertificationEditCard extends Component {
  state = {
    name: "",
    link: "",
    shortCode: "",
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        name: this.props.data.name || "",
        link: this.props.data.link || "",
        shortCode: this.props.data.shortCode || "",
        reference: this.props.data.reference || "",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && prevProps.data !== this.props.data) {
      this.setState({
        name: this.props.data.name || "",
        link: this.props.data.link || "",
        shortCode: this.props.data.shortCode || "",
        reference: this.props.data.reference || "",
      });
    }
  }

  updateValue(key, value) {
    this.setState({
      [key]: value,
    });
  }

  getFileName = () => {
    return (
      this.props.user &&
      `trainerDocuments/${this.props.user.id}/${this.props.fieldName}/${this.props.index}/`
    );
  };

  checkErrorsInForm = () => {
    if (!this.state.name) {
      window.NotificationUtils.showError("Please enter a valid name");
      return true;
    }
    return false;
  };

  onSave = () => {
    if (this.checkErrorsInForm()) {
      return false;
    }
    let data = {
      name: this.state.name || "",
      link: this.state.link || "",
      shortCode: this.state.shortCode || "",
      reference: this.state.reference || "",
    };
    this.props.onSave(data);
  };

  render() {
    return (
      <Dialog onClose={this.props.onClose} open={true}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <div className="educationFieldsContainer">
          <div className="educationEditCancel">
            <IconButton size="small" onClick={this.props.onClose}>
              <CancelIcon color="primary" />
            </IconButton>
          </div>
          <div className="educationField">
            <TextField
              id={this.props.fieldName}
              label={this.props.fieldLabel}
              fullWidth={true}
              value={this.state.name || ""}
              required
              onChange={(ev) => {
                this.updateValue("name", ev.target.value);
              }}
            />
          </div>
          <div className="educationField">
            <TextField
              id={`${this.props.fieldName}shortCode`}
              label="Abbreviation"
              fullWidth={true}
              value={this.state.shortCode || ""}
              placeholder="Example: R.Kin (Registered Kinesiologist)"
              onChange={(ev) => {
                this.updateValue("shortCode", ev.target.value);
              }}
            />
          </div>
          <div className="educationField">
            <AdvancedFileUpload
              onFailure={(e) =>
                window.NotificationUtils.showError("Failed to upload")
              }
              uploadLocation={this.getFileName()}
              onComplete={(files) => this.updateValue("link", files[0].url)}
              placeholder={`Upload document`}
              buttonProps={{
                variant: "outlined",
                color: "primary",
                loadingMessage: "Uploading ...",
              }}
              filePickerProps={{
                extensions: ["pdf", "png", "jpeg", "jpg"],
              }}
              onDelete={() => this.updateValue("link", null)}
            />
          </div>
          <span className="orText">OR</span>
          <div className="educationField">
            <TextField
              id={`${this.props.fieldName}Reference`}
              label="Registeration Number OR Certificate Link"
              fullWidth={true}
              value={this.state.reference || ""}
              placeholder="College/Registeration Number"
              helperText="Incase you don't have a certificate file to upload, you can share your College/Registeration Number here or a link to database of registered service provider"
              onChange={(ev) => {
                this.updateValue("reference", ev.target.value);
              }}
            />
          </div>
          <Button
            onClick={this.onSave}
            color="primary"
            variant="contained"
            style={{ width: "100%", marginTop: 16 }}
          >
            {this.props.buttonText}
          </Button>
        </div>
      </Dialog>
    );
  }
}
