import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TextField,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import MessageIcon from "@material-ui/icons/Message";
import classNames from "classnames";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

const Space = styled.div`
  margin: 14px 0;
`;

export default class DayCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workoutChanged: false,
      showMessageEditor: false,
      workoutCompleteMessage: props.workoutCompleteMessage,
      workoutStartMessage: props.workoutStartMessage,
    };
  }

  static getDefaultStateFromProps(props) {}

  isChanged(prevProps, newProps) {
    if (
      newProps.assignedWorkout !== prevProps.assignedWorkout &&
      newProps.assignedWorkout &&
      prevProps.assignedWorkout &&
      newProps.assignedWorkout.id !== prevProps.assignedWorkout.id
    ) {
      return true;
    } else if (prevProps.assignedWorkout && !newProps.assignedWorkout) {
      return true;
    } else if (!prevProps.assignedWorkout && newProps.assignedWorkout) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.isChanged(prevProps, this.props)) {
      this.setState({ workoutChanged: true });
      setTimeout(() => this.setState({ workoutChanged: false }), 600);
    }
    if (
      this.props.workoutCompleteMessage !== prevProps.workoutCompleteMessage
    ) {
      this.setState({
        workoutCompleteMessage: this.props.workoutCompleteMessage,
      });
    }
    if (this.props.workoutStartMessage !== prevProps.workoutStartMessage) {
      this.setState({
        workoutStartMessage: this.props.workoutStartMessage,
      });
    }
  }

  showMessageEditor = () => {
    this.setState({
      showMessageEditor: true,
    });
  };

  closeMessageEditor = () => {
    this.setState({
      showMessageEditor: false,
    });
  };

  render() {
    var rowClass = classNames({
      selectedDayRow: this.props.selected,
      dayRow: !this.props.selected,
      centerAlignJustify: true,
      fullWidth: true,
    });

    return (
      <>
        <Row
          className={rowClass}
          onClick={(event) =>
            this.props.toggleWorkouts(this.props.index, event)
          }
        >
          <Col
            xs="4"
            className="centerAlignJustify dayText"
            style={{ flexDirection: "column" }}
          >
            {this.props.day}
          </Col>
          <Col
            xs="5"
            className={
              this.state.workoutChanged
                ? "workoutChanged assignedWorkoutText"
                : "assignedWorkoutText"
            }
          >
            {this.props.assignedWorkout ? (
              this.props.assignedWorkout.name
            ) : (
              <div>
                <span style={{ color: "grey" }}>Rest Day</span>
                <div className="lightText">Tap To Assign</div>
              </div>
            )}
          </Col>
          <Col xs="3" className="centerAlignJustify fullWidth">
            <Row className="fullWidth">
              <Col xs="12" className="centerAlignJustify fullWidth">
                {this.props.displayDelete && (
                  <Tooltip title="Delete day">
                    <IconButton
                      color="default"
                      aria-label="delete"
                      onClick={(event) => {
                        this.props.deleteWorkout(this.props.index, event);
                      }}
                      className="addDayAssignerCard"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Make Rest Day">
                  <span>
                    <IconButton
                      color="default"
                      disabled={!Boolean(this.props.assignedWorkout)}
                      aria-label="clear"
                      onClick={(event) => {
                        this.props.removeWorkout(this.props.index, event);
                      }}
                      id={`restDayIcon${this.props.index}`}
                      className="addDayAssignerCard"
                    >
                      <ClearIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Edit Workout Complete Message">
                  <IconButton
                    aria-label="delete"
                    onClick={this.showMessageEditor}
                    id={`messageIcon${this.props.index}`}
                    className="addDayAssignerCard"
                  >
                    <MessageIcon />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.renderMessageEditor()}
      </>
    );
  }

  renderMessageEditor() {
    return (
      <Dialog
        open={this.state.showMessageEditor}
        onClose={this.closeMessageEditor}
        aria-labelledby="message-dialog"
      >
        <DialogTitle id="message-dialog">
          Workout start and end message
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Start Message"
            placeholder="Message to show before user starts working out"
            value={this.state.workoutStartMessage}
            onChange={(e) => {
              this.setState({
                workoutStartMessage: e.target.value,
              });
            }}
          />
          <Space />
          <TextField
            fullWidth
            label="End Message"
            placeholder="Awesome! You are done for the day"
            value={this.state.workoutCompleteMessage}
            onChange={(e) => {
              this.setState({
                workoutCompleteMessage: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeMessageEditor} color="default">
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.closeMessageEditor();
              this.props.onMessageChange(this.props.index, {
                start: this.state.workoutStartMessage,
                finish: this.state.workoutCompleteMessage,
              });
            }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
