import React from "react";
import NumberFormat from "react-number-format";
import { NavLink } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { isEmail } from "validator";
import { isMobileOnly } from "react-device-detect";
import { Dialog } from "@material-ui/core";
import PricePlanModal from "../../components/modals/pricePlanModal";
import SignupBase from "../../components/base/signupBase.js";
import PasswordInput from "../../components/passwordInput/passwordInput";
import {
  ACTION_STATES,
  DB_KEYS,
  ERROR_CODES,
  getWalkthrough,
  INITIALIZE_WALKTHROUGH,
  ROLES,
  USER_STATES,
} from "../../constants";
import TermsConditions from "./termsConditions";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TierContainer = styled.div`
  width: 70%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 300px) and (max-width: 767px) {
    width: 100%;
  }
`;

const TierBox = styled.div`
  padding: 10px;
  width: 290px;
  height: 80px;
`;

const Tier = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 0.1);
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  & p {
    margin: 0;
  }
`;

export default class Signup extends SignupBase {
  constructor(props) {
    super(props);

    this.fields = ["name", "email", "phone", "password", "confirmPassword"];

    this.state = Object.assign(this.state, {
      signupButtonEnabled: false,
      loading: false,
      coachingSolution: false,
      privatePracticeSolution: false,
      enterpriseSolution: false,
      plan: 0,
      interval: "month",
      pricing: false,
      enterpriseAdminName: "",
      enterpriseAdminEmail: "",
      enterpriseName: "",
      businessName: "",
      openDialog: false,
      selectedIndex: 0,
    });

    for (let field of this.fields) {
      this.state[field] = "";

      this["onChange_" + field] = this.onChange.bind(this, field);
    }

    this.state.role = ROLES.TRAINER;
    // this.state.role = ROLES.PRACTITIONER;

    this.signup = this.signup.bind(this);
  }

  getURLRoute = (search) => {
    const urlParams = new URLSearchParams(search);
    const plan = urlParams.get("plan");
    const interval = urlParams.get("interval");
    let tier = {
      "coaching-solution": 0,
      "private-practice-solution": 1,
      "enterprise-solution": 2,
    };

    let tierState = {
      "coaching-solution": "coachingSolution",
      "private-practice-solution": "privatePracticeSolution",
      "enterprise-solution": "enterpriseSolution",
    };
    if (plan) {
      this.setState({
        plan: tier[plan],
        interval,
        pricing: true,
        [tierState[plan]]: true,
      });
    } else {
      this.setState({
        plan: 0,
        interval: "month",
        pricing: false,
        coachingSolution: true,
      });
    }
  };

  componentDidMount() {
    try {
      window.FortisForma.database.signOut();
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong!");
      console.error(e);
    }
    this.getURLRoute(this.props.history.location.search);
    this.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.setState({
            signupButtonEnabled: true,
          });
        },
        "expired-callback": () => {
          this.setState({
            signupButtonEnabled: false,
          });
        },
      }
    );
    this.recaptchaVerifier.render();
  }

  onSave = async () => {
    let termsChecked = document.querySelector("#termsCheck").checked;

    // if (!this.state.enterpriseName) {
    //   window.NotificationUtils.showError("Invalid Enterprise Name");
    //   return;
    // }

    if (!this.state.enterpriseAdminName) {
      window.NotificationUtils.showError("Invalid Enterprise Admin Name");
      return;
    }

    if (
      !this.state.enterpriseAdminEmail ||
      !isEmail(window.emailTrimAndLowerCase(this.state.enterpriseAdminEmail))
    ) {
      window.NotificationUtils.showError("Invalid Enterprise Admin Email");
      return;
    }

    if (!termsChecked) {
      window.NotificationUtils.showError(
        "Please check terms and conditions",
        1500,
        "bottomCenter"
      );
      return true;
    }

    var Enterprise = {};
    Enterprise.enterpriseName = this.state.enterpriseName;
    Enterprise.enterpriseAdminEmail = window.emailTrimAndLowerCase(
      this.state.enterpriseAdminEmail
    );
    Enterprise.enterpriseAdminName = this.state.enterpriseAdminName;
    Enterprise.admin = {
      name: this.state.enterpriseAdminName,
      email: window.emailTrimAndLowerCase(this.state.enterpriseAdminEmail),
    };
    // Enterprise.enterpriseName = "Dante New";
    // Enterprise.enterpriseAdminEmail = window.emailTrimAndLowerCase(
    //   "dantenew@mailinator.com"
    // );
    // Enterprise.enterpriseAdminName = "Dante New";
    // Enterprise.admin = {
    //   name: "Dante",
    //   email: window.emailTrimAndLowerCase("dantenew@mailinator.com"),
    // };

    // this.props.onSave(Enterprise);
    // ===============================================
    const LOADING_MESSAGES = ["Creating enterprise"];
    const LOADING_CHANGE_DURATION = 5000;
    window.showLoadingMessages &&
      window.showLoadingMessages(LOADING_MESSAGES, LOADING_CHANGE_DURATION);
    try {
      let result = await window.FortisForma.database.enterpriseSignup(
        Enterprise
      );
      if (!result) {
        window.hideLoadingMessages && window.hideLoadingMessages();
        window.NotificationUtils.showError("Something went wrong");
      } else {
        // this.setState(
        //   {
        //     selectedEnterprise: this.state.emptyEnterprise,
        //   },
        //   () => {
        //     setTimeout(() => {
        //       this.fetchEnterprises();
        //     }, 1000);
        //   }
        // );
        window.hideLoadingMessages && window.hideLoadingMessages();
        window.NotificationUtils.showSuccess("Enterprise created successfully");
        window.setEmailInLocalStorage(Enterprise.enterpriseAdminEmail);
        this.onSuccess();
      }
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong!");
      var message = e.message ? e.message : "Something went wrong";
      window.hideLoadingMessages && window.hideLoadingMessages();
      window.NotificationUtils.showError(message);
    }
  };

  onChange(key, event, value) {
    let update = {};
    update[key] = event.target.value || (value ? value.newValue : "");
    this.setState(update);
  }

  signup(e) {
    if (!this.state.signupButtonEnabled) {
      throw new Error("Invalid state");
    }
    if (this.state.enterpriseSolution) {
      if (this.state.enterpriseName === "") {
        window.NotificationUtils.showError("Please fill enterprise name");
        return;
      }
    }
    e.nativeEvent.stopPropagation();

    if (this.checkErrorsInForm()) {
      return;
    }

    this.setState({
      signupButtonEnabled: false,
      loading: true,
    });

    let { email, password } = this.state;
    email = window.emailTrimAndLowerCase(email);
    window.localStorage.setItem(
      DB_KEYS.SIGN_UP_STATE,
      ACTION_STATES.IN_PROCESS
    );
    try {
      window.FortisForma.database
        .createUserWithEmailAndPassword(email, password)
        .then((results) => {
          this.createUserProfile(results.user.uid);
          window.setEmailInLocalStorage(email);
        })
        .catch((error) => {
          this.showError(error);
          window.NotificationUtils.showError("Something went wrong");
          console.error(error);
          this.setState({
            signupButtonEnabled: true,
            loading: false,
          });
        });
    } catch (error) {
      window.NotificationUtils.showError("Something went wrong!");
    }
  }

  checkErrorsInForm() {
    let termsChecked = document.querySelector("#termsCheck").checked;

    for (let field of this.fields) {
      if (field === "phone") {
        continue;
      }
      if (!this.state[field]) {
        let name = field;
        if (name === "postalCode") {
          name = "postal code";
        } else if (name === "confirmPassword") {
          name = "password";
        }
        window.NotificationUtils.showErrorInForm(
          document.querySelector(`#${field}`),
          field,
          `Please enter valid ${name}`
        );
        return true;
      }
    }
    if (this.state.password.length < 6) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector("#password"),
        "password",
        "Password length should be greater than 5"
      );
      return true;
    }
    if (this.state.password !== this.state.confirmPassword) {
      window.NotificationUtils.showErrorInForm(
        document.querySelector(`#confirmPassword`),
        "confirmPassword",
        `Passwords do not match`
      );
      return true;
    }

    if (!termsChecked) {
      window.NotificationUtils.showError(
        "Please check terms and conditions",
        1500,
        "bottomCenter"
      );
      return true;
    }

    return false;
  }

  async createUserProfile(uid) {
    let profile = {};
    let tier = {
      0: "Coaching Solution",
      1: "Private Practice Solution",
      2: "Enterprise Solution",
    };

    for (let field of this.fields) {
      profile[field] = this.state[field];
    }

    if (this.state.coachingSolution) {
      profile.role = ROLES.TRAINER;
      profile.tier = "Coaching Solution";
    } else if (this.state.privatePracticeSolution) {
      profile.role = ROLES.TRAINER;
      profile.privatePractice = true;
      profile.active = true;
      profile.interval = this.state.interval;
      profile.tier = "Private Practice Solution";
      profile.businessName = this.state.businessName;
    } else if (this.state.enterpriseSolution) {
      delete profile.phone;
      profile.role = ROLES.ENTERPRISE_ADMIN;
      profile.enterpriseName = this.state.enterpriseName;

      let enterpriseId = await window.FortisForma.database.enterpriseSignup({
        ...profile,
        uid,
      });

      profile.enterpriseId = enterpriseId;
      profile.active = true;
      profile.interval = this.state.interval;
      profile.tier = "Enterprise Solution";

      // name: reqData.enterpriseName,
      // id: enterpriseDocId,
      // enableStaffMFA: true,
    } else {
      profile.role = ROLES.TRAINER;
    }

    // profile.role = ROLES.PRACTITIONER;

    delete profile.password;
    delete profile.confirmPassword;
    profile[DB_KEYS.USER_STATE] = USER_STATES.APPROVED;
    // profile[DB_KEYS.WALKTHROUGH] = INITIALIZE_WALKTHROUGH;
    profile[DB_KEYS.WALKTHROUGH] = getWalkthrough(profile.tier);
    profile[DB_KEYS.CREATED_TIME] = window.moment().startOf("day").unix();
    try {
      await window.FortisForma.database.storeUserProfile(profile, true);
      if (this.state.privatePracticeSolution || this.state.enterpriseSolution) {
        // await window.FortisForma.database.createTrialForNewAccount({
        //   plan: profile.tier,
        //   interval: this.state.interval,
        //   uid,
        // });
      }
      this.onSuccess();
      // this.onSuccess(profile, uid);
    } catch (e) {
      window.NotificationUtils.showError("Something went wrong!");
      throw e;
    }

    window.localStorage.removeItem(DB_KEYS.SIGN_UP_STATE);
  }

  onSuccess() {
    this.props.history.replace("/welcome");
    // if (this.state.privatePracticeSolution || this.state.enterpriseSolution) {
    //   // setTimeout(async () => {
    //   try {
    //     let result = window.FortisForma.database.createTrialForNewAccount({
    //       plan: profile.tier,
    //       interval: this.state.interval,
    //       uid,
    //     });
    //     console.log(result, "resultresult");
    //   } catch (e) {
    //     throw e;
    //   }

    //   // }, 2000)
    // }
  }

  showError(error) {
    console.error(error);

    switch (error.code) {
      case ERROR_CODES.DENIED:
        return window.NotificationUtils.showError("Something went wrong");

      case ERROR_CODES.WEAK_PASSWORD:
        window.NotificationUtils.showError(
          "Please select a strong password",
          null,
          "bottomCenter"
        );
        return;
      default:
        window.NotificationUtils.showError(
          "Unknown error code during signup",
          null,
          "bottomCenter"
        );
        console.error(error);
    }
  }

  toggleTermsConditions = () => {
    this.setState({
      showTermsConditions: !this.state.showTermsConditions,
    });
  };

  progressIndicator() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          margin: "auto",
          textAlign: "center",
          height: 50,
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  getModalvalues = (name, interval, amount) => {
    this.handleClose();
    if (name === "Private Practice Solution") {
      this.setState({
        coachingSolution: false,
        privatePracticeSolution: true,
        enterpriseSolution: false,
        interval: interval,
      });
    } else {
      this.setState({
        coachingSolution: false,
        privatePracticeSolution: false,
        enterpriseSolution: true,
        interval: interval,
      });
    }
  };

  render() {
    return (
      <Container fluid className="signupBody">
        {!this.state.loading ? (
          <React.Fragment>
            <Row>
              <Col className="center">
                <img
                  alt="FortisForma Logo"
                  className="logo"
                  src="/assets/img/logo.png"
                />
              </Col>
            </Row>
            {/* {this.state.pricing && ( */}
            <TierContainer>
              <TierBox>
                <Tier
                  className="form-check text-left"
                  style={{ margin: 0 }}
                  onClick={() => {
                    this.setState({
                      coachingSolution: true,
                      privatePracticeSolution: false,
                      enterpriseSolution: false,
                    });
                  }}
                >
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={this.state.coachingSolution}
                    />
                    <span className="form-check-sign"></span>
                    <div>
                      <b>Free Tier</b>
                      <p>($0.00)</p>
                    </div>
                  </label>
                </Tier>
              </TierBox>
              <TierBox>
                <Tier
                  className="form-check text-left"
                  style={{ margin: 0 }}
                  onClick={() => {
                    this.setState({
                      openDialog: true,
                      selectedIndex: 0,
                    });
                  }}
                >
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={this.state.privatePracticeSolution}
                    />
                    <span className="form-check-sign"></span>
                    <div>
                      <b>Private Practice Tier</b>
                      <p>
                        {this.state.interval === "month"
                          ? "($20.00 + applicable taxes)"
                          : "($200.00 + applicable taxes)"}
                      </p>
                    </div>
                  </label>
                </Tier>
              </TierBox>
              <TierBox>
                <Tier
                  className="form-check text-left"
                  onClick={() => {
                    this.setState({
                      openDialog: true,
                      selectedIndex: 1,
                    });
                  }}
                >
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={this.state.enterpriseSolution}
                    />
                    <span className="form-check-sign"></span>
                    <div>
                      <b>Enterprise Tier</b>
                      <p>
                        {this.state.interval === "month"
                          ? "($40.00 + applicable taxes)"
                          : "($400.00 + applicable taxes)"}
                      </p>
                    </div>
                  </label>
                </Tier>
              </TierBox>
            </TierContainer>
            {/* )} */}

            <Card className="loginCard signupCard">
              <CardBody>
                <Form autoComplete="off">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.onChange_name}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.onChange_email}
                          autoComplete="off"
                          valid={isEmail(this.state.email)}
                          invalid={
                            this.state.email !== "" &&
                            isEmail(this.state.email) === false
                          }
                        />
                        <div className="customHelperText">
                          Use the email address you would contact your clients
                          through
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      {this.state.enterpriseSolution ? (
                        <FormGroup>
                          <Label for="enterpriseName">Enterprise Name</Label>
                          <Input
                            type="text"
                            name="enterpriseName"
                            placeholder="Business Name"
                            // style={{ marginLeft: "0" }}
                            value={this.state.enterpriseName}
                            onChange={(e) => {
                              this.setState({
                                enterpriseName: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <Label for="phone">Phone</Label>
                          <NumberFormat
                            type="tel"
                            format="+1 ### ### ####"
                            mask=" "
                            placeholder="e.g. +1 999 999 9999"
                            customInput={Input}
                            name="phone"
                            id="phone"
                            value={this.state.phone}
                            onChange={this.onChangePhone}
                          />
                          <div className="customHelperText">
                            Use the phone number you would contact your clients
                            through
                          </div>
                        </FormGroup>
                      )}
                    </Col>

                    {this.state.privatePracticeSolution && (
                      <Col lg="4">
                        <FormGroup>
                          <Label for="name">Business Name</Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Business Name"
                            value={this.state.businessName}
                            onChange={(e) => {
                              this.setState({ businessName: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col lg={this.state.privatePracticeSolution ? "4" : "6"}>
                      <PasswordInput
                        showIndicator={true}
                        label={"password"}
                        placeholder={"Password"}
                        value={this.state.password}
                        onChange={this.onChange_password}
                        name="password"
                        type="password"
                        inputId="password"
                      />
                    </Col>

                    <Col lg={this.state.privatePracticeSolution ? "4" : "6"}>
                      <PasswordInput
                        showIndicator={true}
                        label={"confirmPassword"}
                        placeholder={"Confirm Password"}
                        value={this.state.confirmPassword}
                        onChange={this.onChange_confirmPassword}
                        name="confirmPassword"
                        inputId="confirmPassword"
                        type="password"
                      />
                    </Col>

                    {/* {this.state.enterpriseSolution && (
                      <>
                        <Col lg="4">
                          <FormGroup>
                            <Label for="enterpriseName">Enterprise Name</Label>
                            <Input
                              type="text"
                              name="enterpriseName"
                              id="enterpriseName"
                              placeholder="Enterprise Name"
                              value={this.state.enterpriseName}
                              onChange={(e) => {
                                this.setState({
                                  enterpriseName: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <Label for="enterpriseAdminName">
                              Enterprise Admin Name
                            </Label>
                            <Input
                              type="text"
                              name="enterpriseAdminName"
                              id="enterpriseAdminName"
                              placeholder="Enterprise Admin Name"
                              value={this.state.enterpriseAdminName}
                              onChange={(e) => {
                                this.setState({
                                  enterpriseAdminName: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4" style={{ marginBottom: "15px" }}>
                          <FormGroup>
                            <Label for="enterpriseAdminEmail">
                              Enterprise Admin Email
                            </Label>
                            <Input
                              type="email"
                              name="enterpriseAdminEmail"
                              id="enterpriseAdminEmail"
                              placeholder="Enterprise Admin Email"
                              value={this.state.enterpriseAdminEmail}
                              onChange={(e) => {
                                this.setState({
                                  enterpriseAdminEmail: e.target.value,
                                });
                              }}
                              autoComplete="off"
                              valid={isEmail(this.state.enterpriseAdminEmail)}
                              invalid={
                                this.state.enterpriseAdminEmail !== "" &&
                                isEmail(this.state.enterpriseAdminEmail) ===
                                  false
                              }
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )} */}

                    <Col lg="12" className="termsAndCaptchaContainer">
                      <div className="form-check text-left">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            id="termsCheck"
                            type="checkbox"
                          />
                          <span className="form-check-sign"></span>I agree to
                          the{" "}
                          <span
                            className="termsConditionButton"
                            onClick={this.toggleTermsConditions}
                          >
                            Terms {"&"} Conditions
                          </span>{" "}
                          of Fortis Forma
                        </label>
                      </div>
                      <div id="recaptcha-container" />
                    </Col>

                    <Col className="center" lg="12">
                      <div
                        style={{
                          marginTop: 12,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        id="mobileStyleCreatePractitioner"
                      >
                        <Button
                          id="CreatePractitionerButton"
                          disabled={this.state.signupButtonEnabled === false}
                          onClick={(e) => {
                            // if (this.state.enterpriseSolution) {
                            //   this.onSave();
                            // } else {
                            //   this.signup(e);
                            // }
                            this.signup(e);
                          }}
                          color="primary"
                        >
                          Create Account
                        </Button>

                        <NavLink
                          style={{
                            marginLeft: "16px",
                          }}
                          to={"/login"}
                          className="nav-link"
                          id="mobileStyleLink"
                        >
                          Already have an account? Log In
                        </NavLink>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <TermsConditions
              open={this.state.showTermsConditions}
              toggleTermsConditions={this.toggleTermsConditions}
            />
            <Dialog
              fullScreen={isMobileOnly ? true : false}
              fullWidth={true}
              open={this.state.openDialog}
              onClose={() => this.handleClose()}
            >
              <PricePlanModal
                onClose={() => this.handleClose()}
                index={this.state.selectedIndex}
                getModalvalues={this.getModalvalues}
              />
            </Dialog>
          </React.Fragment>
        ) : (
          this.progressIndicator()
        )}
      </Container>
    );
  }
}
