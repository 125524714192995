import React from "react";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import SearchInput from "../components/filters/searchInput";
import { LOCAL_STORAGE_KEYS } from "../constants";

// TODO: Show client basic details on same page
export default class AdminSupport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
    };

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChangeQuery = (event) => {
    let update = {};
    update["query"] = event.target.value;
    this.setState(update);
  };

  reset = () => {
    this.setState({
      query: "",
    });
  };

  onCancel(event) {
    event.nativeEvent.stopPropagation();
  }

  onSearchClick(event) {
    if (event) {
      event.nativeEvent.stopPropagation();
    }

    this.fetchUser();
  }

  async fetchUser() {
    try {
      let user = await window.FortisForma.database.getUserData(
        true,
        this.state.query
      );
      this.setState({
        user,
      });
    } catch (e) {
      throw e;
    }
  }

  onWorkoutClick = async () => {
    var workout = {};
    if (!this.state.user) {
      window.NotificationUtils.showError("First search for some client.");
      return;
    }

    await window.FortisForma.database.getProgram(this.state.user.programId);

    workout.clientName = this.state.user.name;
    workout.clientEmail = this.state.user.email;
    workout.clientId = this.state.user.id;
    workout = JSON.stringify(workout);

    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS,
        workout
      );
      window.open("/#/admin-support-workout-assigner", "_blank");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  };

  onWorkoutSummaryClick = async () => {
    let summary = {};
    summary.clientName = this.state.user.name;
    summary.clientEmail = this.state.user.email;
    summary.clientId = this.state.user.id;
    summary = JSON.stringify(summary);
    try {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.WORKOUT_SUMMARY_DETAILS,
        summary
      );
      window.open("/#/admin-support-workout-summary", "_blank");
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to access local storage");
    }
  };

  renderClientDetails = (user) => {
    return (
      <React.Fragment>
        <div className="requestModalContainer">
          <Card style={{ width: "80%" }} id="serviceClientDetails">
            <CardBody>
              <div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>ID : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.id ? user.id : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Role : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.role ? user.role : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 8 }}>
                  <span style={{ color: "#292929" }}>Name : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.name ? user.name : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Email : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.email ? user.email : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Phone : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.phone ? user.phone : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Pending Login : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.pendingLogin ? "true" : "false"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Last Login : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.lastLoginTime
                      ? window.moment.unix(user.lastLoginTime.seconds).format()
                      : ""}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>
                    Program Start Date :{" "}
                  </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.programStartDate
                      ? window.moment.unix(user.programStartDate).format()
                      : ""}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Service : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.service ? user.service : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Program ID : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.programId ? user.programId : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Tradiner ID : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.tradinerId ? user.tradinerId : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Province : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.province ? user.province : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>Address : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.address ? user.address : "-"}
                  </span>
                </div>
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: "#292929" }}>State : </span>
                  <span style={{ color: "#800520" }}>
                    {" "}
                    {user.state ? user.state : "-"}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div>
        <Row id="clientFilters">
          <Col lg="12">
            <Form>
              <Row>
                <Col sm="12" lg="4" key={"Search"}>
                  <SearchInput
                    inputId={"searchClients"}
                    placeholder={this.props.placeholder || "Search Clients"}
                    value={this.state.query}
                    onChange={this.onChangeQuery}
                    onClickClear={this.reset}
                  ></SearchInput>
                </Col>
              </Row>
            </Form>

            {this.props.inclusive ? null : (
              <div className="center" style={{ marginTop: 40 }}>
                <Button
                  className="btn btn-outline-secondary cancelButton"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>

                <Button color="primary" onClick={this.onSearchClick}>
                  Search
                </Button>

                <Button color="primary" onClick={this.onWorkoutClick}>
                  Workouts
                </Button>

                <Button color="primary" onClick={this.onWorkoutSummaryClick}>
                  WorkoutSummary
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {this.state.user && this.renderClientDetails(this.state.user)}
      </div>
    );
  }
}
