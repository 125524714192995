import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';

export default class ClientDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <Card className="clientDetailCard">
  				    <CardBody style={{padding: 10}}>
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Name :</span> {this.props.selectedTrainer.name}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Email :</span> {this.props.selectedTrainer.email}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Phone No. :</span> {this.props.selectedTrainer.phone}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Role :</span> {this.props.selectedTrainer.role}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Address :</span> {this.props.selectedTrainer.address}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Postal Code :</span> {this.props.selectedTrainer.postalCode}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>City :</span> {this.props.selectedTrainer.city}<br />
                        <span style={{color: 'darkgrey', fontWeight: '600'}}>Province :</span> {this.props.selectedTrainer.province}
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}