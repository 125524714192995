import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { ROLES } from "../../constants";
import TAButton from "../../utils/TAButton";

const StyledButton = styled(Button)`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 8px;
  &.MuiButton-root {
    font-size: 15px;
    text-transform: none;
    padding: 8px 20px;
    font-weight: 500;
    width: 100%;
  }
  &.MuiButton-outlinedPrimary {
    border: none;
    outline: 3px solid #802520;
    outline-offset: -2px;
  }
  &.MuiButton-textPrimary:hover {
    color: #802520 !important;
  }
`;

const SubsContainer = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;
`;

const Heading = styled.p`
  margin-top: 50px;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: #800520;
`;

const SubHeading = styled(Heading)`
  font-size: 28px;
  text-align: left;
`;

const ButtonContainer = styled.div`
  width: 500px;
  margin: 25px 0;
`;

const Container = styled.div`
  height: 400px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledPara = styled.p`
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
`;

const checkEnterprise = () => {};

const checkRole = (user) => {
  if (user.enterpriseId) {
    return `${user.enterprise.name} Enterprise`;
  } else {
    return `${user.name} Coach`;
  }
};

const renderHeading = (user) => {
  return <SubHeading className="subtitle">{checkRole(user)}</SubHeading>;
};

const style = {
  height: "60px",
  border: "2px solid #800520",
  fontSize: "18px",
  fontWeight: "bold",
  color: "#800520",
};

const Subscriptions = (props) => {
  return (
    <SubsContainer>
      {renderHeading(props.user)}
      <Heading>Subscriptions & Payments</Heading>
      <Container>
        <ButtonContainer>
          <StyledButton
            style={style}
            onClick={() => {
              props.history.push("/upgrade");
            }}
          >
            Manage Subscriptions
          </StyledButton>
          <StyledPara>
            See your billing information, and alter subscriptions here.
          </StyledPara>
        </ButtonContainer>
        <ButtonContainer>
          <StyledButton
            style={style}
            onClick={() => {
              props.history.push("/purchase-history");
            }}
          >
            See Purchase History
          </StyledButton>
          <StyledPara>
            See your previous purchase history with Fortis Forma here.
          </StyledPara>
        </ButtonContainer>
      </Container>
    </SubsContainer>
  );
};

export default Subscriptions;
