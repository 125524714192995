import React from "react";
import ReactPlayer from "react-player";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ExerciseCard from "../../views/exerciseList/exerciseCard";
import { BubbleSpinLoader } from "react-css-loaders";

export default class LevelChangerExerciseCard extends ExerciseCard {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.movementRef = React.createRef();
    this.equipmentRef = React.createRef();
    this.onTitleEditClick = this.onTitleEditClick.bind(this);
  }

  renderLoading() {
    return (
      <BubbleSpinLoader
        color={"#800520"}
        size={2}
        style={{
          margin: "0",
          position: "relative",
          right: "-46px",
          top: "-8px",
        }}
      />
    );
  }

  onTitleEditClick() {
    this.props.onTitleEditClick(this.props.data);
  }

  renderTitleIcon() {
    return this.props.selectEnabled ? (
      <div style={{ paddingBottom: 10, paddingRight: 5 }}>
        <div
          id="addExercisePlusButton"
          className="selectButtonContainer"
          style={
            this.props.cardType === "similarExercise"
              ? {
                  width: "initial",
                  height: "initial",
                  borderRadius: "10%",
                  padding: "0 6px",
                  fontSize: 10,
                  fontWeight: 600,
                }
              : null
          }
          onClick={this.onTitleEditClick}
        >
          <span className="selectButton">{this.props.titleEditBtn}</span>
        </div>
      </div>
    ) : null;
  }

  render() {
    return (
      <Card
        className={`exerciseCard small ${this.className || ""}`}
        style={this.props.cardStyle}
        id={`card${this.props.data.id}`}
      >
        <CardTitle>
          <Row style={{ paddingBottom: 2, paddingTop: 4 }}>
            <Col
              xs="12"
              className="exerciseName"
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
              }}
            >
              <div ref={this.nameRef}>{this.props.data.name}</div>
              {/* <i className="material-icons smallIcon">edit</i> */}
              {this.props.data.name.length > 50 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={this.nameRef}
                >
                  {this.props.data.name}
                </UncontrolledTooltip>
              ) : null}
              {this.renderTitleIcon()}
            </Col>
          </Row>
        </CardTitle>
        <CardBody style={{ position: "relative" }}>
          <Row
            className={`videoContainer small ${
              this.state.showReplay ? "blurVideo" : ""
            }`}
            style={{
              width: this.props.videoWidth,
              height: this.props.videoHeight,
            }}
          >
            {!this.props.data.videoURL && this.props.data.posterURL ? (
              <img
                src={this.props.data.posterURL}
                width={this.props.videoWidth}
                height={this.props.videoHeight}
                className="posterImg"
                alt="Poster"
              />
            ) : (
              <ReactPlayer
                ref={(ref) => {
                  this.playerRef = ref;
                }}
                autoPlay={this.state.playing}
                playing={this.state.playing}
                playsinline={this.state.playing}
                controls={false}
                muted={true}
                url={this.props.data.videoURL}
                width={this.props.videoWidth}
                height={this.props.videoHeight}
                light={CSS.escape(this.props.data.posterURL) || true}
                onEnded={this.onFinishVideo}
              />
            )}
          </Row>

          {this.state.showReplay ? (
            <Button className="videoReplayButton" onClick={this.onPressReplay}>
              {" "}
              <i className="material-icons videReplayIcon">replay</i>
            </Button>
          ) : null}

          <Col xs="12" className="levelChangeContainer">
            <div
              className="levelChangerButton noselect"
              disabled={!this.props.canLevelDown}
              onClick={this.props.onLevelDown}
            >
              <i className="material-icons">keyboard_arrow_left</i>
            </div>
            <div style={{ minHeight: 18 }}>
              Level {` `}{" "}
              {this.props.loading
                ? this.renderLoading()
                : this.props.data.level}{" "}
            </div>
            <div
              className="levelChangerButton noselect"
              disabled={!this.props.canLevelUp}
              onClick={this.props.onLevelUp}
            >
              <i className="material-icons">keyboard_arrow_right</i>
            </div>
          </Col>

          <div className="exerciseInfo">
            <div style={{ fontSize: "0.55rem" }}>
              <p className="details" ref={this.movementRef}>
                <span className="meta">Movement</span>
                {this.props.data.movement}
              </p>

              {this.props.data.movement.length > 20 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={this.movementRef}
                >
                  {this.props.data.movement}
                </UncontrolledTooltip>
              ) : null}

              <p className="details" ref={this.equipmentRef}>
                <span className="meta">Equipment</span>
                {this.props.data.equipmentCategories.Primary}
              </p>

              {this.props.data.equipmentCategories &&
              this.props.data.equipmentCategories.Primary &&
              this.props.data.equipmentCategories.Primary.length > 20 ? (
                <UncontrolledTooltip
                  innerClassName="tooltipInnerBody"
                  placement="top"
                  target={this.equipmentRef}
                >
                  {this.props.data.equipmentCategories.Primary}
                </UncontrolledTooltip>
              ) : null}
            </div>

            {this.renderIcon()}
          </div>

          {this.renderExtras()}
        </CardBody>
      </Card>
    );
  }
}
