import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import FilesPicker from '../filePicker/filePicker';
import ImageUploader from './imageUploader';
import VideoUploader from './videoUploader';

export default class AddImage extends Component {

    onFileChange = (file) => {
        this.props.onFileChange(file, this.props.fileType)
    }

    renderUploadInput = () => {
        return (
            <FilesPicker
                onChange={this.onFileChange}
                extensions={this.props.extensions}
            >
                <div className="addUploadContainer" >
                    <div className="uploadIconContainer">
                        <CloudUploadIcon color="primary" />
                        <span id="uploadImageText" >{this.props.text}</span>
                    </div>
                </div>
            </FilesPicker>
        )
    }

    renderUploadingImage = () => {
        return (
            <React.Fragment>
                <div className="uploadImageContainer uploadImageContainer">
                    {/* <img src={this.getImageSrc()} width="100%" alt="imagePreview" /> */}
                    <div className="uploadIconContainer imageActions">
                         
                    </div>
                </div>
            </React.Fragment>
        )
    }

    getImageSrc = () => {
        let imageData = this.props.imageData && this.props.imageData
        let src = URL.createObjectURL(imageData);
        return src
    }

    renderDelete = () => {
        return (
            <div >
                <IconButton size="small" onClick={this.deleteImage} >
                    <DeleteIcon fontSize="small" className="imageActionButton" />
                </IconButton>
            </div>
        )
    }

    renderImageLoading = () => {
        return (
            <CircularProgress size={16} variant="static" id="uploadingImageCircularProgress" value={this.state.uploadingProgress} />
        )
    }

    renderVideoUploader = () => {
        if (this.props.uploading || this.props.fileURL) {
            return <VideoUploader videoLoading={this.props.videoLoading} videoProgress={this.props.videoProgress} videoData={this.props.videoData} videoURL={this.props.fileURL} />
        } else {
            return this.renderUploadInput()
        }
    }

    renderImageUploader = () => {
        if (this.props.uploading || this.props.fileURL) {
            return <ImageUploader imageProgress={this.props.imageProgress} imageData={this.props.imageData} posterURL={this.props.fileURL}/>
        } else {
            return this.renderUploadInput()
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.imageType ? this.renderImageUploader() : this.renderVideoUploader()}
            </React.Fragment>
        )
    }
}