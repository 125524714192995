import React, {Component} from 'react';
import {FormGroup, Label, Input} from 'reactstrap';
import { strengthIndicator, strengthColor } from './strength' 

const TEXT = 'text'
const PASSWORD = 'password' 

export default class PasswordInput extends Component {
     
  
    constructor(props) {
        super(props);
        this.state = {
            type: PASSWORD,
            
        }
    }   

    togglePasswordType = () => {
        let type = this.state.type === PASSWORD  ? TEXT : PASSWORD;
        this.setState({
            "type": type
        });
    }

    renderEye() {
        return (
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <i style={{ fontSize: '1.1rem', cursor: 'pointer', userSelect: 'none' }} onClick={this.togglePasswordType} className="icon material-icons">
                    {this.state.type === PASSWORD ? 'visibility_off' : 'visibility'}
                </i>
            </div>
        )
    }

//TODO :- conditional inline style for input tag  


    renderInput() {
        return (
            <FormGroup style={this.props.style}>
                {
                    this.props.label && <Label for={this.props.inputId}>{this.props.label}</Label>
                }
                <Input  type={this.state.type} name={this.props.name} id={this.props.inputId} value={this.props.value} onChange={this.props.onChange} placeholder={this.props.placeholder} />
                {
                    this.renderEye()
                }
                
            </FormGroup>
        )
    }

    _renderStrength() {

        const strength = strengthIndicator(this.props.value)
        const str = strengthColor(strength)
        
        return (

            <span className="password-strength-label"
            style={{ color: `${str.color}` }}
            >{str.label}
        </span>
        )
    }
    
    render () {
        return (
            <React.Fragment>
                {this.renderInput()}
                {
                    this.props.showIndicator && this._renderStrength()
                }
            </React.Fragment>              
                
        ) 
    }
}