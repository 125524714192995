import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";

const AccDisableModal = ({ disableAcc, closeModal, hist }) => {
  const renderCloseButton = () => {
    return (
      <IconButton className="closeIconButton" onClick={() => closeModal()}>
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        fullWidth={true}
        open={disableAcc}
        onClose={() => closeModal()}
      >
        <DialogContent className="memberAddDialog-mui">
          <div className="memberAddDialogContent trialModal">
            <div className="memberFieldsContainer">
              {renderCloseButton()}
              <div>
                <h4 style={{ marginBottom: "20px" }}>
                  Oops!
                  <br />
                  Looks like your Enterprise admin has downgraded.
                  <br />
                  They will need to upgrade again to see all features, and for
                  you to access this account.
                </h4>
                <h4>Contact your Enterprise admin now,</h4>
                <h4 style={{ marginBottom: "20px" }}>or</h4>
                <label>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      hist.push("/signup");
                      closeModal();
                    }}
                  >
                    {"Create your own account here"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(AccDisableModal);
