import React from "react";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import _ from "lodash";

const currentDate = moment().format("YYYY-MM-DD");
export default function DatePickerWidget(props) {
  const { value, onChange, schema, required, options } = props;
  React.useEffect(() => {
    if (!value && _.has(options, "autoFill") && options.autoFill) {
      setTimeout(() => onChange(currentDate), 0);
    }
  });

  const getLabel = () => {
    return `${schema.title}${required ? "*" : ""}`;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        id="datePickerWidget"
        style={{ width: "100%" }}
        margin="normal"
        disableFuture
        openTo="year"
        format="MM/DD/YYYY"
        label={getLabel()}
        value={value || null}
        onChange={(date) => onChange(moment(date).format("YYYY-MM-DD"))}
      />
    </MuiPickersUtilsProvider>
  );
}
