import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

export default class OnBoardingTextField extends Component {
  render() {
    return (
      <TextField
        {...this.props}
        style={{ fontSize: "12px !important" }}
        variant="outlined"
      />
    );
  }
}
