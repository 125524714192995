import { Component } from "react";
import {Subject} from 'rxjs';

export default class SubscriptionBase extends Component {

    constructor(props) {
        super(props)
        this.end$ = new Subject();
    }

    componentWillUnmount() {
        this.end$.next("Unmount")
        this.end$ = null;
    }
}