import React from "react";
import MUIDataTable from "mui-datatables";

export default function Datatable(props) {
  const [filterPresent, setFilterPresent] = React.useState(false);

  const isFilterPresent = (filters) => {
    let flatFilters = filters.flat();
    if (flatFilters.length) {
      setFilterPresent(true);
    } else {
      setFilterPresent(false);
    }
  };

  const OPTIONS = {
    filter: true,
    filterType: "dropdown",
    print: false,
    download: false,
    selectableRows: "none",
    elevation: 0,
    tableBodyHeight: `${
      filterPresent ? "calc(100vh - 304px)" : "calc(100vh - 264px)"
    }`,
    onFilterChange: (field, filters) => isFilterPresent(filters),
    onRowClick: (data, index) => props.onRowClick(props.data[index.dataIndex]),
    customToolbar: () => props.customToolbar && props.customToolbar(),
    textLabels: {
      body: {
        noMatch: <div>No forms found, click + to add a new form.</div>,
      },
    },
  };

  return (
    <React.Fragment>
      <MUIDataTable
        title={props.title || ""}
        data={props.data}
        columns={props.columns}
        options={OPTIONS}
      />
    </React.Fragment>
  );
}
