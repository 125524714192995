import React, { useState } from "react";
import { Input, Button } from "reactstrap";
import PropTypes from "prop-types";
import RichTextField from "../../components/RichTextEditor/RichTextEditor";
import ProfileImageUploader from "../../components/profileImageUploader";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const DescriptionPlaceholder = (
  <div>
    <p>Write a description for your Challenge</p>
    <p>Example:</p>
    <ul>
      <li>Difficulty: Beginner to Intermediate</li>
      <li>Equipment: Bodyweight and Staircase</li>
      <li>Time: 10 to 15 minutes a day</li>
      <li>Frequency: 6 days a week</li>
    </ul>
  </div>
);

const ToggleFormControlLabel = styled(FormControlLabel)`
  margin-top: 8px;
  margin-bottom: 0px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

const ImageContainer = styled.div`
  .proPicContainer {
    width: 200px !important;
    border: initial !important;
    box-shadow: initial !important;

    & > div {
      border-radius: 4px !important;
    }

    & > img {
      border-radius: 4px !important;
    }
  }
`;

export default function ProgramMetaForm(props) {
  const [name, setName] = useState(props.data.name || "");
  const [about, setAbout] = useState(props.data.about || "");
  const [value, setValue] = useState(props.data.value || "");
  const [picURL, setPicURL] = useState(props.data.picURL || "");
  const [price, setPrice] = useState(props.data.price || "");
  const [trial, setTrial] = useState(props.data.trial || false);
  const [prerequisites, setPrerequiste] = useState(
    props.data.prerequisites || ""
  );

  const onChangePreRequisite = (ignore, html) => {
    setPrerequiste(html);
  };

  const mapData = () => {
    return {
      name,
      about,
      value,
      picURL,
      price,
      prerequisites,
      trial,
    };
  };

  return (
    <div>
      <p>Please add additional information for the challenge</p>
      <ImageContainer>
        <ProfileImageUploader
          storageRef={"programPictures"}
          fileUrl={picURL}
          setFileUrl={setPicURL}
        />
      </ImageContainer>
      <Input
        label="Name"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label="Value Proposition"
        placeholder="Value Proposition"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Input
        label="About"
        placeholder="About"
        value={about}
        onChange={(e) => setAbout(e.target.value)}
      />
      <Input
        type="number"
        label="Price"
        placeholder="Price ($ CAD)"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <ToggleFormControlLabel
        control={
          <Switch
            checked={trial}
            onChange={() => setTrial(!trial)}
            color="primary"
          />
        }
        label="Offer Trial"
      />
      <div style={{ flex: 1 }}>
        <RichTextField
          content={prerequisites}
          onChange={onChangePreRequisite}
          placeholder={DescriptionPlaceholder}
        />
      </div>
      <ActionButtons>
        <Button className="btn btn-link" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onSave(mapData());
          }}
        >
          Save
        </Button>
      </ActionButtons>
    </div>
  );
}

ProgramMetaForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
