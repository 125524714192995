import React from "react";
import WorkoutExerciseCard from '../../workoutSummary/workoutExerciseCard'

export default class WorkoutDayDetails extends React.Component {
	render() {
		if(!this.props.exercises) {
			return(
				null
			)
		}
		else {
			let cardStyle={
				margin: 10,
				height: 420,
				paddingRight: "8px !important",
				minWidth: 250,
				width: 250
			}
			return (
				<div className="centerAlignJustify rightExerciseCardsContainer">
					{this.props.exercises.map((value, i) => {
						return (<React.Fragment key={i}>
									<WorkoutExerciseCard videoWidth={250} videoHeight={250} cardStyle={cardStyle} data={value}  />
								</React.Fragment>
								)
					})}
				</div>
			);
		}
	}
}