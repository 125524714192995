import {
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import moment from "moment";
import React from "react";
import AdvancedFileUpload from "../../components/advancedFileUploader";
import { DB_KEYS, FORM_TYPES, GET_USER_ID, ROLES, TIER } from "../../constants";
import TAButton from "../taButton/taButton";
import Chip from "@material-ui/core/Chip";
import { getTotalQuestions } from "../../views/enterpriseClientDetails/forms";
import _ from "lodash";
import { shouldHandleLongText } from "../../utils/formUtils";
import FormImagesSelector from "../formImagesModal/formImagesSelector";
import FormPDFImagesSelector from "../formImagesModal/formPDFImagesSelector";
import SelectableUploadedImage from "../uploaderImage/selectedableUploadedImage";

export const FIELD_TYPES = {
  TEXTFIELD: "Textfield",
  PHONE: "Phone Number",
  DROPDOWN: "Dropdown",
  CHECKBOX: "Checkbox",
  SIGNATURE: "Signature",
  PLAINTEXT: "Plain text",
  DATE: "Date",
  MULTIPLECHOICE: "Multiple Choice",
  SECTION: "Section",
  TEXTAREA: "Text Area",
  PDF: "PDF",
  FREEHAND_NOTE: "Freehand Note",
  ADD_IMAGE: "Add Image",
};

const FIELD_TYPE_OPTIONS = [
  { value: FIELD_TYPES.TEXTFIELD, label: FIELD_TYPES.TEXTFIELD },
  { value: FIELD_TYPES.PHONE, label: FIELD_TYPES.PHONE },
  { value: FIELD_TYPES.DROPDOWN, label: FIELD_TYPES.DROPDOWN },
  { value: FIELD_TYPES.CHECKBOX, label: FIELD_TYPES.CHECKBOX },
  { value: FIELD_TYPES.PLAINTEXT, label: FIELD_TYPES.PLAINTEXT },
  { value: FIELD_TYPES.SIGNATURE, label: FIELD_TYPES.SIGNATURE },
  { value: FIELD_TYPES.DATE, label: FIELD_TYPES.DATE },
  { value: FIELD_TYPES.MULTIPLECHOICE, label: FIELD_TYPES.MULTIPLECHOICE },
  { value: FIELD_TYPES.TEXTAREA, label: FIELD_TYPES.TEXTAREA },
  { value: FIELD_TYPES.PDF, label: FIELD_TYPES.PDF },
  { value: FIELD_TYPES.FREEHAND_NOTE, label: FIELD_TYPES.FREEHAND_NOTE },
  { value: FIELD_TYPES.ADD_IMAGE, label: FIELD_TYPES.ADD_IMAGE },
  { value: FIELD_TYPES.SECTION, label: FIELD_TYPES.SECTION },
];

export const UI_ORDER = "ui:order";
export const UI_WIDGET = "ui:widget";
export const UI_OPTIONS = "ui:options";
export const UI_DESCRIPTION = "ui:description";
export const UI_PLACEHOLDER = "ui:placeholder";
const UPLOADER_CATEGORY = { PDF: "pdf", LOGO: "logo" };
const ADD_IMAGE_MODE = { PDF: "pdfImageSelector", IMAGE: "imageSelector" };
let schema = {
  type: "object",
  properties: {},
  required: [],
};

let uiSchema = {};

const getClassString = (text) => {
  // This function will append a class if string is too long
  let classString = "flex1 mgRight12";
  const isWithLongText = shouldHandleLongText(false, text);
  if (isWithLongText) {
    return classString + " withLongText";
  }
  return classString;
};

export default class CreateFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      fieldType: FIELD_TYPES.TEXTFIELD,
      fieldOptions: [""],
      schema: { ...schema },
      required: false,
      uiSchema: { ...uiSchema },
      fieldName: "",
      editFieldEnabled: false,
      checkErrors: false,
      checkErrorsInForm: false,
      headerLogoMetaData: {},
      isNumber: false,
      anchorEl: null,
      menuData: {},
      loading: false,
      pdf: [],
      questionTitleName: "",
      selectedSchemaSectionKey: "",
      parentSchema: {},
      copySection: {},
      placeholderSwitch: false,
      placeholderText: "",
      facilityLogoAsHeader: true,
      autoFill: false,
      backgroundImages: [],
      imageSelector: false,
      pdfImageSelector: false,
    };
    this.isAdmin =
      this.props.user &&
      this.props.user.role &&
      this.props.user.role === ROLES.ADMIN;
    this.isClientForm = this.props.isClientForm ? true : false;
    this.facilityLogo =
      (this.props.user &&
        this.props.user.enterprise &&
        this.props.user.enterprise.logo &&
        this.props.user.enterprise.logo.url &&
        this.props.user.enterprise.logo.name && {
          ...this.props.user.enterprise.logo,
        }) ||
      {};
    this.PDFSelectorModal = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.data &&
      this.props.data.schema &&
      this.props.data.schema.properties
    ) {
      this.setState({
        schema: _.cloneDeep(this.props.data.schema) || { ...schema },
        title: this.props.data.schema.title || "",
        description: this.props.data.schema.description || "",
        uiSchema: _.cloneDeep(this.props.data.uiSchema) || { ...uiSchema },
        headerLogoMetaData:
          this.props.data.formMetaData.headerLogoMetaData || {},
      });
    }
  }

  componentWillUnmount() {
    this.setState({});
  }

  clearSchema = () => {
    schema = {
      type: "object",
      properties: {},
      required: [],
    };
    uiSchema = {};
  };

  resetFields = (callback) => {
    this.setState(
      {
        fieldType: FIELD_TYPES.TEXTFIELD,
        fieldName: "",
        editFieldEnabled: false,
        fieldOptions: [""],
        editSectionIndex: null,
        required: false,
        questionTitleName: "",
        selectedSchemaSectionKey: "",
        placeholderText: "",
        isNumber: false,
        url: "",
        copySection: {},
        autoFill: false,
        backgroundImages: [],
      },
      () => {
        callback && callback();
      }
    );
  };

  scrollToBottom = () => {
    let target = document.querySelector(".addFieldDataContainer");
    target.scrollIntoView &&
      target.scrollIntoView({
        behavior: "smooth",
      });
  };

  onClickAddQuestionOption = (key, schemaSection) => {
    this.resetFields(() => {
      this.setState(
        {
          questionTitleName: schemaSection.title,
          selectedSchemaSectionKey: key,
        },
        () => {
          this.handleMenuClose(this.scrollToBottom);
        }
      );
    });
  };

  isSectionSelected = () => {
    if (
      this.state.parentSchema &&
      Object.keys(this.state.parentSchema).length
    ) {
      return true;
    }
    return false;
  };

  onClickEditIcon = (key, schemaSection, required, action) => {
    this.resetFields(() => {
      let makeCopy = false;
      if (action === "copy") {
        makeCopy = true;
      }
      let index, uiSchemaSection;
      if (this.isSectionSelected()) {
        index = this.getSchemaIndex(key, this.state.parentSchema);
        uiSchemaSection = this.state.uiSchema[this.state.parentSchema.key];
        uiSchemaSection = uiSchemaSection[key];
      } else {
        index = this.getSchemaIndex(key, this.state.schema);
        uiSchemaSection = this.state.uiSchema[key];
      }
      const uiWidget = uiSchemaSection && uiSchemaSection[UI_WIDGET];

      if (schemaSection.type === "number") {
        this.setState(
          {
            fieldType: FIELD_TYPES.TEXTFIELD,
            fieldName: schemaSection.title,
            editFieldEnabled: !makeCopy && true,
            editSectionIndex: !makeCopy && index,
            required: required,
            placeholderText: uiSchemaSection[UI_PLACEHOLDER] || "",
            isNumber: true,
            questionTitleName:
              this.state.parentSchema && this.state.parentSchema.title,
            selectedSchemaSectionKey:
              this.state.parentSchema && this.state.parentSchema.key,
          },
          () => {
            this.handleMenuClose(this.scrollToBottom);
          }
        );
        return;
      }
      if (schemaSection.type === "string") {
        if (uiWidget === "radio") {
          this.setState(
            {
              fieldType: FIELD_TYPES.MULTIPLECHOICE,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: schemaSection && schemaSection.enum,
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (uiWidget === "signatureField") {
          this.setState(
            {
              fieldType: FIELD_TYPES.SIGNATURE,
              fieldName: uiSchemaSection[UI_DESCRIPTION],
              editFieldEnabled: !makeCopy && true,
              fieldOptions: [""],
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (uiWidget === "pdfViewer") {
          this.setState(
            {
              fieldType: FIELD_TYPES.PDF,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: [""],
              editSectionIndex: !makeCopy && index,
              required: required,
              url: schemaSection.data,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (uiWidget === "addImageWidget") {
          this.setState(
            {
              fieldType: FIELD_TYPES.ADD_IMAGE,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              editSectionIndex: !makeCopy && index,
              required: required,
              backgroundImages: schemaSection.backgroundImages,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (uiWidget === "freehandNote") {
          this.setState(
            {
              fieldType: FIELD_TYPES.FREEHAND_NOTE,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (schemaSection.enum) {
          this.setState(
            {
              fieldType: FIELD_TYPES.DROPDOWN,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: schemaSection && schemaSection.enum,
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (schemaSection && schemaSection.format === "date") {
          this.setState(
            {
              fieldType: FIELD_TYPES.DATE,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: [""],
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
              autoFill:
                (uiSchemaSection &&
                  uiSchemaSection[UI_OPTIONS] &&
                  uiSchemaSection[UI_OPTIONS].autoFill) ||
                false,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }
        if (uiWidget === "textarea") {
          this.setState(
            {
              fieldType: FIELD_TYPES.TEXTAREA,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: [""],
              editSectionIndex: !makeCopy && index,
              required: required,
              placeholderText: uiSchemaSection[UI_PLACEHOLDER] || "",
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }

        if (uiWidget === "phoneField") {
          this.setState(
            {
              fieldType: FIELD_TYPES.PHONE,
              fieldName: schemaSection.title,
              editFieldEnabled: !makeCopy && true,
              fieldOptions: [""],
              editSectionIndex: !makeCopy && index,
              required: required,
              questionTitleName:
                this.state.parentSchema && this.state.parentSchema.title,
              selectedSchemaSectionKey:
                this.state.parentSchema && this.state.parentSchema.key,
            },
            () => {
              this.handleMenuClose(this.scrollToBottom);
            }
          );
          return;
        }

        this.setState(
          {
            fieldType: FIELD_TYPES.TEXTFIELD,
            fieldName: schemaSection.title,
            editFieldEnabled: !makeCopy && true,
            fieldOptions: [""],
            editSectionIndex: !makeCopy && index,
            required: required,
            isNumber: false,
            placeholderText: uiSchemaSection[UI_PLACEHOLDER] || "",
            questionTitleName:
              this.state.parentSchema && this.state.parentSchema.title,
            selectedSchemaSectionKey:
              this.state.parentSchema && this.state.parentSchema.key,
          },
          () => {
            this.handleMenuClose(this.scrollToBottom);
          }
        );
      }
      if (schemaSection.type === "array") {
        this.setState(
          {
            fieldType: FIELD_TYPES.CHECKBOX,
            fieldName: schemaSection.title,
            editFieldEnabled: !makeCopy && true,
            fieldOptions:
              schemaSection && schemaSection.items && schemaSection.items.enum,
            editSectionIndex: !makeCopy && index,
            required: required,
            questionTitleName:
              this.state.parentSchema && this.state.parentSchema.title,
            selectedSchemaSectionKey:
              this.state.parentSchema && this.state.parentSchema.key,
          },
          () => {
            this.handleMenuClose(this.scrollToBottom);
          }
        );
      }
      if (schemaSection.type === "object" && !schemaSection.title) {
        this.setState(
          {
            fieldType: FIELD_TYPES.PLAINTEXT,
            fieldName: schemaSection.description,
            editFieldEnabled: !makeCopy && true,
            editSectionIndex: !makeCopy && index,
            required: required,
            fieldOptions: [""],
            questionTitleName:
              this.state.parentSchema && this.state.parentSchema.title,
            selectedSchemaSectionKey:
              this.state.parentSchema && this.state.parentSchema.key,
          },
          () => {
            this.handleMenuClose(this.scrollToBottom);
          }
        );
        return;
      }
      if (schemaSection.type === "object") {
        this.setState(
          {
            fieldType: FIELD_TYPES.SECTION,
            fieldName: schemaSection.title,
            editFieldEnabled: !makeCopy && true,
            editSectionIndex: !makeCopy && index,
            required: required,
            fieldOptions: [""],
            questionTitleName:
              this.state.parentSchema && this.state.parentSchema.title,
            selectedSchemaSectionKey:
              this.state.parentSchema && this.state.parentSchema.key,
            copySection: schemaSection,
          },
          () => {
            this.handleMenuClose(this.scrollToBottom);
          }
        );
        return;
      }
    });
  };

  getSectionStateData = (key) => {
    const stateSchema = { ...this.state.schema };
    const stateUiSchema = { ...this.state.uiSchema };
    const stateSectionUIOrder =
      (stateUiSchema[key] &&
        stateUiSchema[key][UI_ORDER] && [...stateUiSchema[key][UI_ORDER]]) ||
      [];
    const stateSectionSchema = stateSchema.properties[key];
    const stateSectionSchemaProperties = { ...stateSectionSchema.properties };
    return {
      stateSchema,
      stateUiSchema,
      stateSectionUIOrder,
      stateSectionSchema,
      stateSectionSchemaProperties,
    };
  };

  getStateData = () => {
    const stateSchema = { ...this.state.schema };
    const stateUiSchema = { ...this.state.uiSchema };
    const stateUiOrder =
      (stateUiSchema[UI_ORDER] && [...stateUiSchema[UI_ORDER]]) || [];
    const stateSchemaProperties = { ...stateSchema.properties };
    return { stateSchema, stateUiSchema, stateUiOrder, stateSchemaProperties };
  };

  onClickDeleteSectionComponent = (key, orderIndex) => {
    let {
      stateSchema,
      stateUiSchema,
      stateSectionUIOrder,
      stateSectionSchema,
      stateSectionSchemaProperties,
    } = this.getSectionStateData(
      this.state.parentSchema && this.state.parentSchema.key
    );
    delete stateSectionSchemaProperties[key];
    stateSectionSchema.properties = stateSectionSchemaProperties;
    if (
      stateSectionSchema &&
      stateSectionSchema.required &&
      stateSectionSchema.required.includes(key)
    ) {
      const i = stateSectionSchema && stateSectionSchema.required.indexOf(key);
      if (i > -1) {
        stateSectionSchema && stateSectionSchema.required.splice(i, 1);
      }
    }
    if (stateUiSchema[this.state.parentSchema.key][key]) {
      delete stateUiSchema[this.state.parentSchema.key][key];
    }
    stateSectionUIOrder.splice(orderIndex, 1);
    stateUiSchema[this.state.parentSchema.key][UI_ORDER] = stateSectionUIOrder;
    stateSchema.properties[this.state.parentSchema.key] = stateSectionSchema;
    this.setState(
      {
        schema: stateSchema,
        uiSchema: stateUiSchema,
      },
      this.handleMenuClose
    );
  };

  checkIfCopyInProgress = (questionKey) => {
    if (this.state.copySection && Object.keys(this.state.copySection).length) {
      if (this.state.copySection.key === questionKey) {
        return true;
      }
    }
    return false;
  };

  onClickDeleteIcon = (key, orderIndex) => {
    if (this.isSectionSelected()) {
      return this.onClickDeleteSectionComponent(key, orderIndex);
    }

    let { stateSchema, stateUiSchema, stateUiOrder, stateSchemaProperties } =
      this.getStateData();

    if (
      this.state.selectedSchemaSectionKey &&
      key === this.state.selectedSchemaSectionKey
    ) {
      this.resetFields();
    }

    if (this.checkIfCopyInProgress(key)) {
      window.NotificationUtils.showError("Copy in progress");
      this.handleMenuClose(this.scrollToBottom);
      return;
    }

    delete stateSchemaProperties[key];
    stateSchema.properties = stateSchemaProperties;
    if (
      stateSchema &&
      stateSchema.required &&
      stateSchema.required.includes(key)
    ) {
      const i = stateSchema && stateSchema.required.indexOf(key);
      if (i > -1) {
        stateSchema && stateSchema.required.splice(i, 1);
      }
    }
    if (stateUiSchema[key]) {
      delete stateUiSchema[key];
    }
    stateUiOrder.splice(orderIndex, 1);
    stateUiSchema[UI_ORDER] = stateUiOrder;
    this.setState(
      {
        schema: stateSchema,
        uiSchema: stateUiSchema,
      },
      this.handleMenuClose
    );
  };

  handleChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleDropdownFieldChange = (e, index) => {
    let stateFieldOptions = Object.assign([], this.state.fieldOptions);
    stateFieldOptions[index] = e.target.value;
    this.setState({
      fieldOptions: stateFieldOptions,
    });
  };

  onClickAddOption = () => {
    let stateFieldOptions = Object.assign([], this.state.fieldOptions);
    stateFieldOptions.push("");
    this.setState({
      fieldOptions: stateFieldOptions,
    });
  };

  onClickDeleteDropdownOption = (index) => {
    let stateFieldOptions = Object.assign([], this.state.fieldOptions);
    stateFieldOptions.splice(index, 1);

    this.setState({
      fieldOptions: stateFieldOptions,
    });
  };

  errorInFieldName = () => {
    return !this.state.fieldName;
  };

  errorInFieldOptions = (index) => {
    return !this.state.fieldOptions[index];
  };

  errorInPdfUploader = () => {
    if (!this.state.pdfMetaData || !this.state.pdfMetaData.url) {
      window.NotificationUtils.showError("No PDF uploaded");
    }
    return !this.state.pdfMetaData || !this.state.pdfMetaData.url;
  };

  errorInAddImageField = () => {
    if (!(this.state.backgroundImages && this.state.backgroundImages.length)) {
      window.NotificationUtils.showError("No File uploaded");
      return true;
    }
    return false;
  };

  checkErrorInSection = () => {
    let hasErrors = false;
    if (this.state.fieldType === FIELD_TYPES.PDF) {
      if (this.errorInPdfUploader()) {
        hasErrors = true;
      }
      return hasErrors;
    }
    if (this.state.fieldType === FIELD_TYPES.ADD_IMAGE) {
      if (this.errorInAddImageField()) {
        hasErrors = true;
      }
    }
    if (this.errorInFieldName()) {
      hasErrors = true;
    }
    if (
      this.state.fieldType === FIELD_TYPES.DROPDOWN ||
      this.state.fieldType === FIELD_TYPES.CHECKBOX ||
      this.state.fieldType === FIELD_TYPES.MULTIPLECHOICE
    ) {
      for (let i = 0; i < this.state.fieldOptions.length; i++) {
        if (this.errorInFieldOptions(i)) {
          hasErrors = true;
        }
      }
    }
    return hasErrors;
  };

  checkIfKeyIsPresent = (key, schema) => {
    let stateSchemaProperties = (schema && schema.properties) || {};
    let stateSchemaPropertyKeys = Object.keys(stateSchemaProperties);
    if (stateSchemaPropertyKeys.includes(key)) {
      return true;
    } else {
      return false;
    }
  };

  getQuestionKey = (length, schema) => {
    let key = `question_${length + 1}`;
    if (this.checkIfKeyIsPresent(key, schema)) {
      let newLength = length + 1;
      let result = this.getQuestionKey(newLength, schema);
      if (result) {
        return result;
      }
    } else {
      return key;
    }
  };

  getFieldSchema = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.TEXTFIELD:
      case FIELD_TYPES.TEXTAREA:
      case FIELD_TYPES.PHONE:
        return {
          type: this.state.isNumber ? "number" : "string",
          title: this.state.fieldName,
        };

      case FIELD_TYPES.DROPDOWN:
        return {
          title: this.state.fieldName,
          enum: this.state.fieldOptions,
          default: this.state.fieldOptions[0],
          type: "string",
        };

      case FIELD_TYPES.CHECKBOX:
        return {
          type: "array",
          title: this.state.fieldName,
          items: {
            type: "string",
            enum: this.state.fieldOptions,
          },
          uniqueItems: true,
        };

      case FIELD_TYPES.SIGNATURE:
        return {
          type: "string",
        };

      case FIELD_TYPES.PLAINTEXT:
        const fieldName = window.textTrim(this.state.fieldName);
        return {
          type: "object",
          title: "",
          description: fieldName,
        };

      case FIELD_TYPES.DATE:
        return {
          type: "string",
          format: "date",
          title: this.state.fieldName,
        };

      case FIELD_TYPES.MULTIPLECHOICE:
        return {
          type: "string",
          title: this.state.fieldName,
          description: "",
          enum: this.state.fieldOptions,
        };

      case FIELD_TYPES.SECTION:
        return {
          type: "object",
          title: this.state.fieldName,
          description: "",
          properties: {},
          required: [],
        };

      case FIELD_TYPES.PDF:
        return {
          type: "string",
          title: this.state.pdfMetaData.name,
          data: this.state.pdfMetaData.url,
        };

      case FIELD_TYPES.FREEHAND_NOTE:
        return {
          type: "string",
          title: this.state.fieldName,
        };

      case FIELD_TYPES.ADD_IMAGE:
        return {
          type: "string",
          title: this.state.fieldName,
          backgroundImages: this.state.backgroundImages,
        };

      default:
        return {};
    }
  };

  getFieldUiSchema = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.MULTIPLECHOICE:
        return {
          [UI_WIDGET]: "radio",
        };

      case FIELD_TYPES.CHECKBOX:
        return {
          [UI_WIDGET]: "checkboxes",
        };

      case FIELD_TYPES.SIGNATURE:
        return {
          [UI_WIDGET]: "signatureField",
          [UI_DESCRIPTION]: this.state.fieldName,
        };

      case FIELD_TYPES.TEXTAREA:
        return {
          [UI_WIDGET]: "textarea",
          [UI_OPTIONS]: { ROWS: 5 },
          [UI_PLACEHOLDER]: this.state.placeholderText,
        };

      case FIELD_TYPES.PDF:
        return {
          [UI_WIDGET]: "pdfViewer",
        };

      case FIELD_TYPES.TEXTFIELD:
        return {
          [UI_PLACEHOLDER]: this.state.placeholderText,
        };

      case FIELD_TYPES.PHONE:
        return {
          [UI_WIDGET]: "phoneField",
        };

      case FIELD_TYPES.DATE:
        return {
          [UI_OPTIONS]: {
            autoFill: this.state.autoFill || false,
          },
        };

      case FIELD_TYPES.FREEHAND_NOTE:
        return {
          [UI_WIDGET]: "freehandNote",
        };

      case FIELD_TYPES.ADD_IMAGE:
        return {
          [UI_WIDGET]: "addImageWidget",
        };

      default:
        return {};
    }
  };

  addFieldInSchemaSection = () => {
    let {
      stateSchema,
      stateUiSchema,
      stateSectionUIOrder,
      stateSectionSchema,
      stateSectionSchemaProperties,
    } = this.getSectionStateData(this.state.selectedSchemaSectionKey);

    let stateSchemaProperties = Object.assign({}, stateSchema.properties);
    let length = Object.keys(stateSectionSchemaProperties).length;
    let sectionUIStateSchema = {
      ...stateUiSchema[this.state.selectedSchemaSectionKey],
    };

    let key = "";

    if (this.state.editFieldEnabled) {
      let stateSchemaPropertyKeys = Object.keys(stateSectionSchemaProperties);

      key = stateSchemaPropertyKeys[this.state.editSectionIndex];
      delete sectionUIStateSchema[key];
    } else {
      key = this.getQuestionKey(length, stateSectionSchema);
      stateSectionUIOrder.push(key);
    }

    sectionUIStateSchema[key] = this.getFieldUiSchema();

    stateSectionSchemaProperties[key] = this.getFieldSchema();

    stateSchemaProperties[this.state.selectedSchemaSectionKey].properties =
      stateSectionSchemaProperties;
    stateSchema.properties = stateSchemaProperties;

    if (this.shouldPushRequiredKey(key, stateSectionSchema.required)) {
      stateSectionSchema.required.push(key);
    } else if (this.shouldDeleteRequiredKey(key, stateSectionSchema.required)) {
      const index =
        stateSectionSchema &&
        stateSectionSchema.required &&
        stateSectionSchema.required.indexOf(key);

      if (index > -1) {
        stateSectionSchema && stateSectionSchema.required.splice(index, 1);
      }
    }

    sectionUIStateSchema[UI_ORDER] = stateSectionUIOrder;
    stateUiSchema[this.state.selectedSchemaSectionKey] = sectionUIStateSchema;

    this.setState({
      schema: stateSchema,
      fieldName: "",
      fieldType: FIELD_TYPES.TEXTFIELD,
      fieldOptions: [""],
      uiSchema: stateUiSchema,
      editFieldEnabled: false,
      editSectionIndex: null,
      checkErrors: false,
      required: false,
      isNumber: false,
      selectedSchemaSectionKey: "",
      questionTitleName: "",
      pdfMetaData: {},
      placeholderText: "",
      autoFill: false,
      backgroundImages: [],
    });
  };

  onClickAdd = () => {
    this.setState({
      checkErrors: true,
    });
    if (this.checkErrorInSection()) {
      return this.scrollToBottom();
    }
    if (this.state.selectedSchemaSectionKey) {
      return this.addFieldInSchemaSection();
    }

    let { stateSchema, stateUiSchema, stateUiOrder, stateSchemaProperties } =
      this.getStateData();
    let stateSchemaPropertiesLength = Object.keys(stateSchemaProperties).length;
    let uiStateSchemaCopy = { ...stateUiSchema };
    let key = "";

    if (this.state.editFieldEnabled) {
      let stateSchemaPropertyKeys = Object.keys(stateSchemaProperties);
      key = stateSchemaPropertyKeys[this.state.editSectionIndex];
      delete stateUiSchema[key];
    } else {
      key = this.getQuestionKey(stateSchemaPropertiesLength, this.state.schema);
      stateUiOrder.push(key);
    }

    if (this.state.fieldType !== FIELD_TYPES.SECTION) {
      stateUiSchema[key] = this.getFieldUiSchema();
    }

    stateSchemaProperties[key] = this.getFieldSchema();

    if (
      this.state.fieldType === FIELD_TYPES.SECTION &&
      Object.keys(this.state.copySection).length
    ) {
      let section = JSON.parse(JSON.stringify(this.state.copySection));
      if (section.key && uiStateSchemaCopy[section.key]) {
        stateUiSchema[key] = JSON.parse(
          JSON.stringify(uiStateSchemaCopy[section.key])
        );
        delete section.key;
      }
      section.title = this.state.fieldName;
      stateSchemaProperties[key] = section;
    }
    stateSchema.properties = stateSchemaProperties;
    if (this.shouldPushRequiredKey(key, stateSchema.required)) {
      stateSchema.required.push(key);
    } else if (this.shouldDeleteRequiredKey(key, stateSchema.required)) {
      const index =
        stateSchema &&
        stateSchema.required &&
        stateSchema.required.indexOf(key);
      if (index > -1) {
        stateSchema && stateSchema.required.splice(index, 1);
      }
    }

    stateUiSchema[UI_ORDER] = stateUiOrder;
    this.setState({
      schema: stateSchema,
      fieldName: "",
      fieldType: FIELD_TYPES.TEXTFIELD,
      fieldOptions: [""],
      uiSchema: stateUiSchema,
      editFieldEnabled: false,
      editSectionIndex: null,
      checkErrors: false,
      required: false,
      isNumber: false,
      pdfMetaData: {},
      copySection: {},
      placeholderText: "",
      url: "",
      autoFill: false,
      backgroundImages: [],
    });
  };

  shouldPushRequiredKey = (key, requiredFields) => {
    if (this.state.required && !requiredFields.includes(key)) {
      return true;
    } else {
      return false;
    }
  };

  shouldDeleteRequiredKey = (key, requiredFields) => {
    if (!this.state.required && requiredFields.includes(key)) {
      return true;
    } else {
      return false;
    }
  };

  noQuestionAdded = () => {
    var questionCount =
      (this.state.uiSchema &&
        this.state.uiSchema["ui:order"] &&
        this.state.uiSchema["ui:order"].length) ||
      0;
    if (questionCount > 0) {
      return false;
    }
    return true;
  };

  getSchemaQuestions = (properties) => {
    let questions = 0;

    if (properties && !Object.keys(properties).length) {
      return questions;
    }

    for (let key in properties) {
      const question = properties[key];

      if (question.properties) {
        let sectionQuestions = 0;
        sectionQuestions = this.getSchemaQuestions(question.properties);
        questions += sectionQuestions;
      }

      questions++;
    }
    return questions;
  };

  getUiSchemaQuestions = (uiSchema) => {
    let questions = 0;
    if (uiSchema && uiSchema[UI_ORDER].length) {
      for (let key in uiSchema) {
        if (uiSchema[key][UI_ORDER]) {
          questions += this.getUiSchemaQuestions(uiSchema[key]);
        }
      }
      questions += uiSchema[UI_ORDER].length;
      return questions;
    }
  };

  checkErrorsInFormProperties = (schema, uiSchema, formId) => {
    let schemaQuestions = 0;
    let uiSchemaQuestions = 0;

    schemaQuestions = this.getSchemaQuestions(schema.properties);
    uiSchemaQuestions = this.getUiSchemaQuestions(uiSchema);
    if (schemaQuestions !== uiSchemaQuestions) {
      console.error(
        `Error in form: ${formId}, schema#: ${schemaQuestions}, uiSchema#: ${uiSchemaQuestions}`
      );
    }
  };

  checkErrorInForm = () => {
    let hasErrors = false;
    if (this.noQuestionAdded()) {
      window.NotificationUtils.showError("Atleast one question is necessary");
      hasErrors = true;
    }

    if (!this.state.title) {
      window.NotificationUtils.showError("Form Name is necessary");
      hasErrors = true;
    }

    return hasErrors;
  };

  getHeaderLogoMetaData = () => {
    let headerLogoMetaData = this.state.headerLogoMetaData;

    if (
      Object.keys(headerLogoMetaData).length &&
      headerLogoMetaData.url &&
      headerLogoMetaData.name
    ) {
      return headerLogoMetaData;
    }

    if (
      !Object.keys(headerLogoMetaData).length &&
      this.state.facilityLogoAsHeader
    ) {
      headerLogoMetaData = this.facilityLogo;
      return headerLogoMetaData;
    }

    return headerLogoMetaData;
  };
  //onClickSaveForm for PP to ENT & ENT to PP =========
  onClickSaveForm = async () => {
    this.setState({
      checkErrorsInForm: true,
      loading: true,
    });
    if (this.checkErrorInForm()) {
      let target = document.querySelector(".headerTitleContainer");
      target.scrollIntoView &&
        target.scrollIntoView({
          behavior: "smooth",
        });
      this.setState({
        loading: false,
      });
      return false;
    }

    let formData = {
      formMetaData: {
        headerLogoMetaData: this.getHeaderLogoMetaData(),
      },
      schema: this.state.schema,
      uiSchema: this.state.uiSchema,
      step: this.props.tabSelected,
      sequence: this.props.sequenceNumber,
      active: true,
    };

    let enterpriseId;
    if (this.isAdmin) {
      formData.global = true;
      formData[DB_KEYS.ENTERPRISE_FORM] = true;
    } else if (
      this.props.user.role === ROLES.TRAINER &&
      this.props.user.tier !== TIER.FREE
    ) {
      enterpriseId = this.props.ppEnterprise.id;
      if (
        this.props.data.enterpriseForm &&
        this.props.data.id &&
        this.props.data.enterpriseId
      ) {
        formData.enterpriseId = enterpriseId;
        formData[DB_KEYS.ENTERPRISE_FORM] = true;
      } else {
        formData.userId = GET_USER_ID(this.props);
        // formData.userId = this.props.user.id;
        formData[DB_KEYS.ENTERPRISE_FORM] = false;
      }
    } else {
      enterpriseId = this.props.user.enterpriseId;
      if (!this.props.data.enterpriseForm && this.props.data.id) {
        formData.userId = GET_USER_ID(this.props);
        // formData.userId = this.props.user.id;
        formData[DB_KEYS.ENTERPRISE_FORM] = false;
      } else {
        formData.enterpriseId = enterpriseId;
        formData[DB_KEYS.ENTERPRISE_FORM] = true;
      }
    }
    //New Condition ENT TO PP

    if (this.isClientForm && !this.isAdmin) {
      formData.clientId = this.props.clientId;
      formData.clientEmail = this.props.clientEmail;
    }
    if (this.props.editFormId) {
      formData.id = this.props.editFormId;
      formData.sequence = this.props.data.sequence;
    }
    try {
      const totalQuestions = getTotalQuestions(
        this.state.schema && this.state.schema.properties
      );

      let filled = Boolean(!totalQuestions);

      if (!this.isAdmin) {
        formData.filled = filled;
      }

      var result = await window.FortisForma.database.saveForms(formData);

      this.checkErrorsInFormProperties(
        this.state.schema,
        this.state.uiSchema,
        result.id
          ? result.id
          : result._writeBatch &&
              result._writeBatch._ops[0] &&
              result._writeBatch._ops[0].docPath
      );

      window.NotificationUtils.showSuccess("Form saved successfully");
      this.clearSchema();
      this.setState(
        {
          checkErrorInForm: false,
          title: "",
          description: "",
          schema: { ...schema },
          uiSchema: { ...uiSchema },
          headerLogoMetaData: {},
          loading: false,
        },
        this.props.closeCreateForm
      );

      this.props.onSaveForm(result, totalQuestions);
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  //Old onClickSaveForm for PP To ENT Working =========
  // onClickSaveForm = async () => {
  //   this.setState({
  //     checkErrorsInForm: true,
  //     loading: true,
  //   });
  //   if (this.checkErrorInForm()) {
  //     let target = document.querySelector(".headerTitleContainer");
  //     target.scrollIntoView &&
  //       target.scrollIntoView({
  //         behavior: "smooth",
  //       });
  //     this.setState({
  //       loading: false,
  //     });
  //     return false;
  //   }

  //   let formData = {
  //     formMetaData: {
  //       headerLogoMetaData: this.getHeaderLogoMetaData(),
  //     },
  //     schema: this.state.schema,
  //     uiSchema: this.state.uiSchema,
  //     step: this.props.tabSelected,
  //     sequence: this.props.sequenceNumber,
  //     active: true,
  //   };
  //   if (this.isAdmin) {
  //     formData.global = true;
  //     formData[DB_KEYS.ENTERPRISE_FORM] = true;
  //   }
  //   else if (
  //     this.props.user.role === ROLES.TRAINER &&
  //     this.props.user.tier !== TIER.FREE
  //   ) {

  //     formData.userId = this.props.user.id;
  //     formData[DB_KEYS.ENTERPRISE_FORM] = false;
  //     if (this.isClientForm) {
  //       formData.clientId = this.props.clientId;
  //       formData.clientEmail = this.props.clientEmail;
  //     }
  //   } else {
  //     if (!this.props.data.enterpriseForm && this.props.data.id) {
  //       formData.userId = this.props.user.id;
  //       formData[DB_KEYS.ENTERPRISE_FORM] = false;
  //       if (this.isClientForm) {
  //         formData.clientId = this.props.clientId;
  //         formData.clientEmail = this.props.clientEmail;
  //       }
  //     } else {
  //       formData.enterpriseId = this.props.user.enterpriseId;

  //       if (this.isClientForm) {
  //         formData.clientId = this.props.clientId;
  //         formData.clientEmail = this.props.clientEmail;
  //       }
  //       formData[DB_KEYS.ENTERPRISE_FORM] = true;
  //     }
  //   }

  //   if (this.props.editFormId) {
  //     formData.id = this.props.editFormId;
  //     formData.sequence = this.props.data.sequence;
  //   }
  //   try {
  //     const totalQuestions = getTotalQuestions(
  //       this.state.schema && this.state.schema.properties
  //     );

  //     let filled = Boolean(!totalQuestions);

  //     if (!this.isAdmin) {
  //       formData.filled = filled;
  //     }
  //     console.log('dfghjkl;',formData)
  //     var result = await window.FortisForma.database.saveForms(formData);

  //     this.checkErrorsInFormProperties(
  //       this.state.schema,
  //       this.state.uiSchema,
  //       result.id
  //         ? result.id
  //         : result._writeBatch &&
  //             result._writeBatch._ops[0] &&
  //             result._writeBatch._ops[0].docPath
  //     );

  //     window.NotificationUtils.showSuccess("Form saved successfully");
  //     this.clearSchema();
  //     this.setState(
  //       {
  //         checkErrorInForm: false,
  //         title: "",
  //         description: "",
  //         schema: { ...schema },
  //         uiSchema: { ...uiSchema },
  //         headerLogoMetaData: {},
  //         loading: false,
  //       },
  //       this.props.closeCreateForm
  //     );

  //     this.props.onSaveForm(result, totalQuestions);
  //   } catch (e) {
  //     console.error(e);
  //     this.setState({
  //       loading: false,
  //     });
  //     window.NotificationUtils.showError("Something went wrong");
  //   }
  // };

  onChangeSwitch = (event, key) => {
    this.setState({
      [key]: event.target.checked,
    });
  };

  onClickArrowIcon = (index, key) => {
    let stateUiSchema = { ...this.state.uiSchema };
    let uiSchemaOrder;
    if (this.isSectionSelected()) {
      uiSchemaOrder = [...stateUiSchema[this.state.parentSchema.key][UI_ORDER]];
    } else {
      uiSchemaOrder = [...stateUiSchema[UI_ORDER]];
    }
    let keyOfMovingObject = uiSchemaOrder[index];
    let keyOfNextObject = "";
    if (key === "up") {
      keyOfNextObject = uiSchemaOrder[index - 1];
      uiSchemaOrder[index - 1] = keyOfMovingObject;
    } else {
      keyOfNextObject = uiSchemaOrder[index + 1];
      uiSchemaOrder[index + 1] = keyOfMovingObject;
    }
    if (this.isSectionSelected()) {
      uiSchemaOrder[index] = keyOfNextObject;
      stateUiSchema[this.state.parentSchema.key][UI_ORDER] = uiSchemaOrder;
    } else {
      uiSchemaOrder[index] = keyOfNextObject;
      stateUiSchema[UI_ORDER] = uiSchemaOrder;
    }
    this.setState(
      {
        uiSchema: stateUiSchema,
      },
      this.handleMenuClose
    );
  };

  getSchemaIndex = (key, schema) => {
    let stateSchemaPropertiesKeys = Object.keys(schema && schema.properties);
    if (stateSchemaPropertiesKeys.length > 0) {
      const index = stateSchemaPropertiesKeys.indexOf(key);
      if (index > -1) {
        return index;
      }
    }
  };

  renderMenu = () => {
    let schemaSection =
      this.state.menuData && this.state.menuData.schemaSection;
    let index = this.state.menuData && this.state.menuData.index;
    let required = this.state.menuData && this.state.menuData.required;
    if (!schemaSection) {
      return;
    }
    let stateSchemaProperties = Object.assign(
      {},
      this.state.schema && this.state.schema.properties
    );
    let key = this.state.uiSchema[UI_ORDER][index];
    if (this.isSectionSelected()) {
      stateSchemaProperties =
        stateSchemaProperties[this.state.parentSchema.key].properties;
      key = this.state.uiSchema[this.state.parentSchema.key][UI_ORDER][index];
    }
    let stateSchemaPropertiesLength = Object.keys(stateSchemaProperties).length;
    let disableUpArrow = !(stateSchemaPropertiesLength > 1 && index > 0);
    let disableDownArrow = !(index < stateSchemaPropertiesLength - 1);
    let isSection = Boolean(
      schemaSection.type === "object" && schemaSection.title
    );
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={(e) => this.handleMenuClose()}
      >
        <MenuItem
          disabled={this.state.editFieldEnabled}
          onClick={() => this.onClickDeleteIcon(key, index)}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() =>
            this.onClickEditIcon(key, schemaSection, required, "edit")
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={disableUpArrow || this.state.editFieldEnabled}
          onClick={() => this.onClickArrowIcon(index, "up")}
        >
          Move Up
        </MenuItem>
        <MenuItem
          disabled={disableDownArrow || this.state.editFieldEnabled}
          onClick={() => this.onClickArrowIcon(index, "down")}
        >
          Move Down
        </MenuItem>
        <MenuItem
          onClick={() =>
            this.onClickEditIcon(key, schemaSection, required, "copy")
          }
        >
          Copy
        </MenuItem>
        {isSection && (
          <MenuItem
            onClick={() => this.onClickAddQuestionOption(key, schemaSection)}
          >
            Add Question
          </MenuItem>
        )}
      </Menu>
    );
  };

  renderSchemaSectionActionButtons = (
    schemaSection,
    index,
    required,
    parentSchema = {}
  ) => {
    return (
      <div className="moreIconContainer">
        <IconButton
          onClick={(e) =>
            this.onClickMoreIcon(
              e,
              schemaSection,
              index,
              required,
              parentSchema
            )
          }
        >
          <MoreVertIcon color="primary" />
        </IconButton>
      </div>
    );
  };

  onClickMoreIcon = (e, schemaSection, index, required, parentSchema) => {
    let menuData = {
      schemaSection: schemaSection,
      index: index,
      required: required,
    };
    this.setState({
      menuData,
      anchorEl: e.currentTarget,
      parentSchema: parentSchema,
    });
  };

  handleMenuClose = (callback) => {
    this.setState(
      {
        anchorEl: null,
        menuData: {},
        parentSchema: {},
      },
      () => callback && callback()
    );
  };

  renderFormFields = (
    schemaSection,
    uiSchemaSection,
    index,
    required,
    parentSchema = {}
  ) => {
    const uiWidget = uiSchemaSection && uiSchemaSection[UI_WIDGET];
    if (schemaSection.type === "number") {
      return (
        <div
          className="displayFlex mgBottom16 padding8 alignItemsCenter"
          key={index}
        >
          <TextField
            label={schemaSection.title}
            onChange={(e) => this.handleDropdownFieldChange(e, index)}
            className={getClassString(schemaSection.title)}
            required={required}
            disabled
            value=""
          />
          {this.renderSchemaSectionActionButtons(
            schemaSection,
            index,
            required,
            parentSchema
          )}
        </div>
      );
    }
    if (schemaSection.type === "string") {
      if (uiWidget === "radio") {
        return (
          <div className="mgBottom16 padding8 alignItemsCenter" key={index}>
            <div className="displayFlex">
              <Typography variant="h6" className="flex1 questionText">
                {schemaSection.title} {required && "*"}
              </Typography>
              {this.renderSchemaSectionActionButtons(
                schemaSection,
                index,
                required,
                parentSchema
              )}
            </div>
            <div className="checkBoxOptionContainer">
              {schemaSection &&
                schemaSection.enum &&
                schemaSection.enum.map((option) => {
                  return (
                    <div className="checkBoxContainer mgRight12" key={option}>
                      <IconButton>
                        <RadioButtonUncheckedIcon />
                      </IconButton>
                      <Typography variant="subtitle2">{option}</Typography>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }
      if (uiWidget === "signatureField") {
        return (
          <div
            className="displayFlex mgBottom16 padding8 alignItemsCenter"
            key={index}
          >
            <div className="flex1 mgRight12">
              <div className="signaturePad" />
              <Typography
                className="textColorGrey textTransformInitial"
                variant="subtitle2"
              >
                {uiSchemaSection[UI_DESCRIPTION]}
              </Typography>
            </div>

            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required,
              parentSchema
            )}
          </div>
        );
      }
      if (uiWidget === "pdfViewer") {
        return (
          <div
            className="displayFlex mgBottom16 padding8 alignItemsCenter"
            key={index}
          >
            <TextField
              label={schemaSection.title}
              onChange={(e) => this.handleDropdownFieldChange(e, index)}
              className={getClassString(schemaSection.title)}
              disabled
              value=""
            />
            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required,
              parentSchema
            )}
          </div>
        );
      }
      if (schemaSection && schemaSection.enum) {
        return (
          <div
            className="displayFlex mgBottom16 padding8 alignItemsCenter"
            key={index}
          >
            <TextField
              className={getClassString(schemaSection.title)}
              required={required}
              select
              label={schemaSection.title}
              value={schemaSection && schemaSection.default}
            >
              {schemaSection &&
                schemaSection.enum.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required,
              parentSchema
            )}
          </div>
        );
      }
      if (schemaSection && schemaSection.format === "date") {
        return (
          <div
            className="displayFlex mgBottom16 padding8 alignItemsCenter"
            key={index}
          >
            <TextField
              className={getClassString(schemaSection.title)}
              id="date"
              label={schemaSection.title}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              required={required}
            />
            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required,
              parentSchema
            )}
          </div>
        );
      }
      return (
        <div
          className="displayFlex mgBottom16 padding8 alignItemsCenter"
          key={index}
        >
          <TextField
            label={schemaSection.title}
            onChange={(e) => this.handleDropdownFieldChange(e, index)}
            className={getClassString(schemaSection.title)}
            required={required}
            disabled
            value=""
          />
          {this.renderSchemaSectionActionButtons(
            schemaSection,
            index,
            required,
            parentSchema
          )}
        </div>
      );
    } else if (schemaSection.type === "array") {
      return (
        <div className="mgBottom16 padding8 alignItemsCenter" key={index}>
          <div className="displayFlex">
            <Typography variant="h6" className="flex1" questionText>
              {schemaSection.title} {required && "*"}
            </Typography>
            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required,
              parentSchema
            )}
          </div>
          <div className="checkBoxOptionContainer">
            {schemaSection &&
              schemaSection.items &&
              schemaSection.items.enum.map((option) => {
                return (
                  <div className="checkBoxContainer mgRight12" key={option}>
                    <IconButton>
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                    <Typography variant="subtitle2">{option}</Typography>
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else if (schemaSection.type === "object" && !schemaSection.title) {
      return (
        <div className="displayFlex mgBottom16 padding8" key={index}>
          <Typography
            className="flex1 mgRight12 whiteSpacePreLine"
            variant="subtitle2"
          >
            {schemaSection.description}
          </Typography>
          {this.renderSchemaSectionActionButtons(
            schemaSection,
            index,
            required,
            parentSchema
          )}
        </div>
      );
    } else if (schemaSection.type === "object") {
      return (
        <div className="displayFlex mgBottom16 padding8 " key={index}>
          <div className="displayFlex fullWidth">
            <div className="sectionContainer fullWidth marginRight">
              <Typography className="flex1 mgRight12 whiteSpacePreLine sectionTitle">
                {schemaSection.title}
              </Typography>

              <div className="formDataMainContainer">
                {uiSchemaSection &&
                  uiSchemaSection[UI_ORDER].map((section, i) => {
                    let sectionSchema = schemaSection.properties[section];
                    if (!sectionSchema) {
                      return false;
                    }
                    let required = Boolean(
                      schemaSection &&
                        schemaSection.required &&
                        schemaSection.required.includes(section)
                    );
                    let uiSectionSchema = uiSchemaSection[section];
                    schemaSection.key = this.state.uiSchema[UI_ORDER][index];
                    return this.renderFormFields(
                      sectionSchema,
                      uiSectionSchema,
                      i,
                      required,
                      schemaSection
                    );
                  })}
              </div>
            </div>
            {this.renderSchemaSectionActionButtons(
              schemaSection,
              index,
              required
            )}
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  renderFormData = () => {
    let uiSchemaOrder = this.state.uiSchema[UI_ORDER] || [];
    return (
      <div className="formDataMainContainer">
        {uiSchemaOrder &&
          uiSchemaOrder.map((section, index) => {
            let schemaSection = this.state.schema.properties[section];
            let uiSchemaSection = this.state.uiSchema[section];
            if (!schemaSection) {
              return false;
            }
            let required = Boolean(
              this.state.schema &&
                this.state.schema.required &&
                this.state.schema.required.includes(section)
            );
            return this.renderFormFields(
              schemaSection,
              uiSchemaSection,
              index,
              required
            );
          })}
      </div>
    );
  };

  handleFormHeaderChange = (e, key) => {
    let stateSchema = Object.assign({}, this.state.schema);
    stateSchema[key] = e.target.value;
    this.setState({
      [key]: e.target.value,
      schema: stateSchema,
    });
  };

  renderFormHeaderTextField = () => {
    return (
      <>
        <div className="headerTitleContainer">
          <TextField
            fullWidth={true}
            placeholder="Form Name"
            value={this.state.title}
            onChange={(e) => this.handleFormHeaderChange(e, "title")}
            className="titleTextField"
            multiline={true}
          />
        </div>
        <TextField
          placeholder="Form Description"
          value={this.state.description}
          onChange={(e) => this.handleFormHeaderChange(e, "description")}
          className="descriptionTextField"
          multiline={true}
          fullWidth={true}
        />
      </>
    );
  };

  renderDropdownFields = () => {
    return (
      <div>
        {this.state.fieldOptions.map((option, index) => {
          return (
            <div
              className="mgTop16 displayFlex alignItemsCenter"
              key={`Option_${index + 1}`}
            >
              <Typography variant="subtitle2" className="mgRight12 ">
                {index + 1}.
              </Typography>
              <TextField
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => this.handleDropdownFieldChange(e, index)}
                className="flex1"
                error={
                  this.state.checkErrors && this.errorInFieldOptions(index)
                }
              />
              {this.state.fieldOptions.length > 1 && (
                <Button
                  className="mgLeft12"
                  color="primary"
                  onClick={() => this.onClickDeleteDropdownOption(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          );
        })}
        <Button
          className="mgTop12"
          variant="contained"
          color="primary"
          onClick={this.onClickAddOption}
        >
          Add Option
        </Button>
      </div>
    );
  };

  renderCheckboxFields = () => {
    return (
      <div>
        {this.state.fieldOptions.map((option, index) => {
          return (
            <div
              className="mgTop16 displayFlex alignItemsCenter"
              key={`Option_${index + 1}`}
            >
              <CheckBoxOutlineBlankIcon className="mgRight12" />
              <TextField
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => this.handleDropdownFieldChange(e, index)}
                className="flex1"
                error={
                  this.state.checkErrors && this.errorInFieldOptions(index)
                }
              />
              {this.state.fieldOptions.length > 1 && (
                <Button
                  className="mgLeft12"
                  color="primary"
                  onClick={() => this.onClickDeleteDropdownOption(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          );
        })}
        <Button
          className="mgTop12"
          variant="contained"
          color="primary"
          onClick={this.onClickAddOption}
        >
          Add Option
        </Button>
      </div>
    );
  };
  renderRadioFields = () => {
    return (
      <div>
        {this.state.fieldOptions &&
          this.state.fieldOptions.map((option, index) => {
            return (
              <div
                className="mgTop16 displayFlex alignItemsCenter"
                key={`Option_${index + 1}`}
              >
                <RadioButtonUncheckedIcon className="mgRight12" />
                <TextField
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => this.handleDropdownFieldChange(e, index)}
                  className="flex1"
                  error={
                    this.state.checkErrors && this.errorInFieldOptions(index)
                  }
                />
                {this.state.fieldOptions.length > 1 && (
                  <Button
                    className="mgLeft12"
                    color="primary"
                    onClick={() => this.onClickDeleteDropdownOption(index)}
                  >
                    Delete
                  </Button>
                )}
              </div>
            );
          })}
        <Button
          className="mgTop12"
          variant="contained"
          color="primary"
          onClick={this.onClickAddOption}
        >
          Add Option
        </Button>
      </div>
    );
  };

  openImageSelectorModal = (key) => {
    this.setState({
      [key]: true,
    });
  };

  onCloseImageSelector = (key) => {
    if (
      key === ADD_IMAGE_MODE.PDF &&
      this.PDFSelectorModal &&
      this.PDFSelectorModal.state.saving
    ) {
      window.NotificationUtils.showConfirm("Upload in progress, please wait.");
      return;
    }
    this.setState({
      [key]: false,
    });
  };

  handleAddImageSave = (files, mode) => {
    this.setState({
      backgroundImages: [...files],
      [mode]: false,
    });
  };

  renderSelectedImages = () => {
    if (this.state.backgroundImages && this.state.backgroundImages.length) {
      return (
        <div style={{ display: "flex" }}>
          {this.state.backgroundImages.map((image, index) => {
            return (
              <SelectableUploadedImage
                key={index}
                index={index}
                image={image}
                hideRemove={true}
                setPreviewOpened={() => {}}
              />
            );
          })}
        </div>
      );
    }
  };

  renderAddImageSection = () => {
    const { backgroundImages } = this.state;
    const imagesPresent = Boolean(backgroundImages && backgroundImages.length);
    return (
      <div
        className="addImageSectionContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {imagesPresent && this.renderSelectedImages()}
        <ButtonGroup style={{ marginTop: "16px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.openImageSelectorModal(ADD_IMAGE_MODE.IMAGE)}
          >
            {imagesPresent ? "Change Image" : "Add Image"}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.openImageSelectorModal(ADD_IMAGE_MODE.PDF)}
          >
            {imagesPresent ? "Change PDF" : "Add PDF"}
          </Button>
        </ButtonGroup>
        {this.renderImageSelectorModal()}
        {this.renderPDFImageSelectorModal()}
      </div>
    );
  };

  renderImageSelectorModal = () => {
    return (
      <Dialog
        open={this.state.imageSelector}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => this.onCloseImageSelector(ADD_IMAGE_MODE.IMAGE)}
      >
        <FormImagesSelector
          backgroundImages={this.state.backgroundImages || []}
          enterpriseId={this.props.user.enterpriseId}
          userId={this.props.user.id}
          onClose={() => this.onCloseImageSelector(ADD_IMAGE_MODE.IMAGE)}
          handleSave={(files) =>
            this.handleAddImageSave(files, ADD_IMAGE_MODE.IMAGE)
          }
          {...this.props}
        />
      </Dialog>
    );
  };

  renderPDFImageSelectorModal = () => {
    return (
      <Dialog
        open={this.state.pdfImageSelector}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => this.onCloseImageSelector("pdfImageSelector")}
      >
        <FormPDFImagesSelector
          ref={(ref) => (this.PDFSelectorModal = ref)}
          backgroundImages={this.state.backgroundImages || []}
          onClose={() => this.onCloseImageSelector("pdfImageSelector")}
          handleSave={(files) =>
            this.handleAddImageSave(files, ADD_IMAGE_MODE.PDF)
          }
        />
      </Dialog>
    );
  };

  renderPdfUploader = () => {
    return (
      <div className="pdfUploaderContainer">
        <AdvancedFileUpload
          onFailure={(e) =>
            window.NotificationUtils.showError("Failed to upload")
          }
          uploadLocation={this.getFileName()}
          onComplete={(files) => {
            this.updateValue("url", files[0].url, UPLOADER_CATEGORY.PDF);
            this.updateValue("name", files[0].name, UPLOADER_CATEGORY.PDF);
          }}
          placeholder={`Upload`}
          buttonProps={{
            variant: "outlined",
            color: "primary",
            loadingMessage: "Uploading ...",
          }}
          filePickerProps={{
            extensions: ["pdf"],
          }}
          onDelete={() => this.updateValue("url", null, UPLOADER_CATEGORY.PDF)}
        />
      </div>
    );
  };

  getLabel = () => {
    if (this.state.fieldType === FIELD_TYPES.PLAINTEXT) {
      return "Text";
    } else {
      return "Question Name";
    }
  };

  shouldShowRequired = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.PLAINTEXT:
      case FIELD_TYPES.SECTION:
      case FIELD_TYPES.PDF:
        return false;
      default:
        return true;
    }
  };

  shouldShowPlaceholderTextField = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.TEXTAREA:
      case FIELD_TYPES.TEXTFIELD:
        return true;
      default:
        return false;
    }
  };

  renderPlaceholderTextField = () => {
    return (
      <TextField
        label="Add Placeholder"
        value={this.state.placeholderText}
        onChange={(e) => this.handleChange(e, "placeholderText")}
        className="fieldNameContainer"
      />
    );
  };

  onClickCancel = () => {
    this.setState({
      selectedSchemaSectionKey: "",
      questionTitleName: "",
      editFieldEnabled: false,
      editSectionIndex: null,
      checkErrors: false,
    });
  };

  renderAddFieldTextField = () => {
    return (
      <TextField
        label={this.getLabel()}
        multiline={
          this.state.fieldType === FIELD_TYPES.PLAINTEXT ? true : false
        }
        value={this.state.fieldName}
        onChange={(e) => this.handleChange(e, "fieldName")}
        className="fieldNameContainer"
        error={this.state.checkErrors && this.errorInFieldName()}
      />
    );
  };

  renderQuestionInputSection = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.PDF:
        return this.renderPdfUploader();
      default:
        return this.renderAddFieldTextField();
    }
  };

  renderExtras = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.DROPDOWN:
        return this.renderDropdownFields();
      case FIELD_TYPES.CHECKBOX:
        return this.renderCheckboxFields();
      case FIELD_TYPES.MULTIPLECHOICE:
        return this.renderRadioFields();
      case FIELD_TYPES.ADD_IMAGE:
        return this.renderAddImageSection();
      default:
        return null;
    }
  };

  renderAddFieldData = () => {
    let showRequired = this.shouldShowRequired();
    let options = Object.assign([], FIELD_TYPE_OPTIONS);
    if (this.state.selectedSchemaSectionKey) {
      options.splice(options.length - 1, 1);
    }

    return (
      <div className="addFieldDataContainer mgTop12">
        {this.state.questionTitleName && (
          <div className="textAlignLeft marginBottom">
            <Chip
              label={`${this.state.questionTitleName} Question`}
              onDelete={this.onClickCancel}
              variant="outlined"
            />
          </div>
        )}
        <div className="displayFlex flexDirectionColumn addFieldContainer">
          {this.renderQuestionInputSection()}
          <TextField
            className="fieldTypeOptions"
            required
            select
            value={this.state.fieldType}
            onChange={(e) => this.handleChange(e, "fieldType")}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {this.renderExtras()}
        <div className="addFieldActionsContainer">
          {this.shouldShowPlaceholderTextField() && (
            <div className="displayFlex alignItemsCenter">
              {this.renderPlaceholderTextField()}
            </div>
          )}
          {this.state.fieldType === FIELD_TYPES.TEXTFIELD && (
            <div className="numericContainer">
              <Typography className="mgTop12 " variant="subtitle2">
                Numeric Information
              </Typography>
              <Switch
                className="mgTop12 mgRight12"
                checked={this.state.isNumber}
                onChange={(e) => this.onChangeSwitch(e, "isNumber")}
                color="primary"
              />
            </div>
          )}
          {this.state.fieldType === FIELD_TYPES.DATE && (
            <div className="autoFillSwitchContainer">
              <Typography className="mgTop12 " variant="subtitle2">
                Autofill Current Date
              </Typography>
              <Switch
                className="mgTop12 mgRight12"
                checked={this.state.autoFill}
                onChange={(e) => this.onChangeSwitch(e, "autoFill")}
                color="primary"
              />
            </div>
          )}
          {showRequired && (
            <div className="requiredContainer">
              <Typography className="mgTop12 " variant="subtitle2">
                Required Information
              </Typography>
              <Switch
                className="mgTop12 mgRight12"
                checked={this.state.required}
                onChange={(e) => this.onChangeSwitch(e, "required")}
                color="primary"
              />
            </div>
          )}
          {this.state.fieldName && (
            <Button
              className="mgTop12 mgRight12"
              onClick={(e) => this.resetFields()}
              color="primary"
            >
              Cancel
            </Button>
          )}
          <Button
            className="mgTop12"
            variant="contained"
            color="primary"
            onClick={this.onClickAdd}
          >
            {this.state.editFieldEnabled ? "Save" : "Create"}
          </Button>
        </div>
      </div>
    );
  };

  renderUploadedHeaderLogo = () => {
    return (
      <>
        <Typography variant="h6" className="mgBottom32 headerTitle">
          Header Logo
        </Typography>
        <div className="headerLogoContainer">
          <div className="createFormLogoAndNameContainer">
            <img
              src={
                this.state.headerLogoMetaData &&
                this.state.headerLogoMetaData.url
              }
              alt="imgPreview"
              className="createFormLogoImageContainer"
            />
            <Typography className="logoFileName" variant="subtitle2">
              {this.state.headerLogoMetaData &&
                this.state.headerLogoMetaData.name}
            </Typography>
          </div>
          <div className="binDeleteContainer">
            <IconButton onClick={this.onClickDeleteHeaderLogo}>
              <DeleteOutlineIcon color="primary" />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  renderImageUploder = () => {
    return (
      <div className="formImageUploaderContainer">
        <AdvancedFileUpload
          onFailure={(e) =>
            window.NotificationUtils.showError("Failed to upload")
          }
          uploadLocation={this.getFileName()}
          onComplete={(files) => {
            this.updateValue("url", files[0].url, UPLOADER_CATEGORY.LOGO);
            this.updateValue("name", files[0].name, UPLOADER_CATEGORY.LOGO);
          }}
          placeholder={`Upload Header Logo`}
          buttonProps={{
            variant: "outlined",
            color: "primary",
            loadingMessage: "Uploading ...",
          }}
          filePickerProps={{
            extensions: ["png", "jpeg", "jpg"],
          }}
          onDelete={() => this.updateValue("url", null, UPLOADER_CATEGORY.LOGO)}
        />
      </div>
    );
  };

  renderFacilityLogo = () => {
    if (Object.keys(this.facilityLogo).length) {
      return (
        <div className="useFacilityLogoContainer">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={this.state.facilityLogoAsHeader}
                onChange={(event) =>
                  this.onChangeSwitch(event, event.target.name)
                }
                name="facilityLogoAsHeader"
              />
            }
            label="Use Facility Logo in header"
            style={{ margin: "0 8px 0 0" }}
          />
          <img
            src={this.facilityLogo.url}
            alt="imgPreview"
            className="formFacilityLogo"
          />
        </div>
      );
    }
  };

  headerLogoSectionDivider = () => {
    if (Object.keys(this.facilityLogo).length) {
      return (
        <div className="headerLogoSectionDividerContainer">
          <hr className="headerLogoVerticalDivider" />
          OR
          <hr className="headerLogoVerticalDivider" />
        </div>
      );
    }
  };

  renderHeaderLogoSection = () => {
    if (
      Object.keys(this.state.headerLogoMetaData).length &&
      this.state.headerLogoMetaData.url
    ) {
      return this.renderUploadedHeaderLogo();
    }
    return (
      <div className="displayFlex">
        {this.renderFacilityLogo()}
        {this.headerLogoSectionDivider()}
        {this.renderImageUploder()}
      </div>
    );
  };

  getFileName(category) {
    if (category === UPLOADER_CATEGORY.LOGO) {
      return (
        this.props.user &&
        this.props.user.enterprise &&
        `formHeaderLogos/${this.props.user.enterprise.id}_${moment().unix()}`
      );
    }
    return (
      this.props.user &&
      this.props.user.enterprise &&
      `formAttachments/$${this.props.user.enterprise.id}_${moment().unix()}`
    );
  }

  updateValue(key, val, category) {
    if (category === UPLOADER_CATEGORY.LOGO) {
      let headerLogoMetaData = Object.assign({}, this.state.headerLogoMetaData);
      headerLogoMetaData[key] = val;
      this.setState({
        headerLogoMetaData,
        facilityLogoAsHeader: false,
      });
    }
    let pdfMetaData = Object.assign({}, this.state.pdfMetaData);
    pdfMetaData[key] = val;
    this.setState({
      pdfMetaData,
    });
  }

  onClickDeleteHeaderLogo = () => {
    this.setState({
      headerLogoMetaData: {},
      facilityLogoAsHeader: true,
    });
  };

  renderDialogContentData = () => {
    return (
      <>
        {this.renderHeaderLogoSection()}
        <Divider className="headerLogoDivider" />
        {this.renderFormHeaderTextField()}
        {this.renderFormData()}
        <div className="dialogContentDivider" />
        {this.renderAddFieldData()}
        {this.renderMenu()}
      </>
    );
  };

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.closeCreateForm()}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  getSelectedTabName = () => {
    switch (this.props.tabSelected) {
      case FORM_TYPES.REGISTRATION:
        return "Registration Form";
      case FORM_TYPES.GENERAL_INFO:
        return "General Information Form";
      case FORM_TYPES.MISC:
        return "Miscellaneous Form";
      case FORM_TYPES.ASSESSMENTS:
        return "Assessment Form";
      case FORM_TYPES.NOTES:
        return "Note Form";
      default:
        return "Invalid Form";
    }
  };

  render() {
    return (
      <>
        <DialogContent className="createFormModalContent">
          <Typography
            className="mgTopAndBottom24 boldText modalFormTitle"
            variant="h5"
          >
            {this.props.editFormId ? "Edit" : "New"} {this.getSelectedTabName()}
          </Typography>
          {this.renderCloseButton()}
          {this.renderDialogContentData()}
        </DialogContent>
        <DialogActions>
          <TAButton
            onClick={this.onClickSaveForm}
            isLoading={this.state.loading}
            loadingMessage={"Saving Form"}
            color="primary"
          >
            Save
          </TAButton>
        </DialogActions>
      </>
    );
  }
}
