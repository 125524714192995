import React, { Component } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";

export default class ContactDetails extends Component {
  render() {
    if (this.props.loading) {
      return <CircularProgress color="primary" style={{ padding: 14 }} />;
    }
    return (
      <React.Fragment>
        <DialogTitle>Contact Details</DialogTitle>
        <DialogContent>
          <Typography>
            <span className="boldFont">Name : </span>
            {this.props.contactDetails.name || "-"}
          </Typography>
          <Typography>
            <span className="boldFont">Email : </span>
            {this.props.contactDetails.email || "-"}
          </Typography>
          <Typography>
            <span className="boldFont">Phone : </span>
            {this.props.contactDetails.phone || "-"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}
