import React from "react";
import { DialogContent, Typography } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

export default class AssignCoachModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coaches: props.coaches || [],
      assignedCoaches: [],
      autocompleteValue: [],
      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.selectedClient && this.props.selectedClient.id) {
      try {
        this.setState({
          loading: true,
        });
        let data = {
          clientId: this.props.selectedClient.id,
          pendingLogin: this.props.selectedClient.pendingLogin || false,
        };
        let results = await window.FortisForma.database.getAssignedCoaches(
          data
        );
        let defaultValue = this.extractDefaultValues(results);
        this.setState({
          assignedCoaches: results,
          loading: false,
          autocompleteValue: defaultValue,
        });
      } catch (error) {
        console.error(error);
        window.NotificationUtils.showError("Error fetching assigned coaches");
        this.setState({
          loading: false,
        });
      }
    }
  }

  extractDefaultValues(assignedCoaches) {
    var results = [];
    if (
      this.state.coaches &&
      this.state.coaches.length > 0 &&
      assignedCoaches &&
      assignedCoaches.length > 0
    ) {
      for (let coach of assignedCoaches) {
        var value = this.state.coaches.find(
          (element) => element.id === coach.coachId
        );
        if (value) {
          results.push(value);
        }
      }
    }
    return results;
  }

  renderCloseButton = () => {
    return (
      <IconButton
        className="closeIconButton"
        onClick={() => this.props.closeAssignCoachModal()}
        disabled={this.state.loading}
      >
        <CloseOutlinedIcon />
      </IconButton>
    );
  };

  comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.id === current.id;
        }).length === 0
      );
    };
  }

  onChangeOption = async (e, value) => {
    this.setState({
      loading: true,
    });
    try {
      var data;
      if (value.length > this.state.autocompleteValue.length) {
        //Coach added
        let addedOptions = value.filter(
          this.comparer(this.state.autocompleteValue)
        );
        if (addedOptions[0].id === this.props.user.id) {
          this.setState({
            loading: false,
          });
          return;
        }
        data = {
          clientId: this.props.selectedClient.id || "",
          coachId: addedOptions[0].id || "",
          pendingLogin: this.props.selectedClient.pendingLogin || false,
        };
        await window.FortisForma.database.assignCoach(data);
        window.NotificationUtils.showSuccess("Coach assigned successfully");
      } else {
        //Coach deleted
        let deletedOptions = this.state.autocompleteValue.filter(
          this.comparer(value)
        );
        if (deletedOptions[0].id === this.props.user.id) {
          this.setState({
            loading: false,
          });
          return;
        }
        data = {
          clientId: this.props.selectedClient.id || "",
          coachId: deletedOptions[0].id || "",
          pendingLogin: this.props.selectedClient.pendingLogin || false,
        };
        await window.FortisForma.database.removeCoach(data);
        window.NotificationUtils.showSuccess("Coach removed successfully");
      }
      this.setState({
        loading: false,
        autocompleteValue: value,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
      });
      window.NotificationUtils.showError("Error occured, please try again");
    }
  };

  renderDialogContentData = () => {
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="assignCoachModalLoading">
            <CircularProgress color="primary" size={32} />
          </div>
        )}
        <div className={`assignCoachContentContainer `}>
          <Autocomplete
            disabled={this.state.loading}
            multiple={true}
            id="assignCoachInput"
            options={this.state.coaches}
            getOptionLabel={(option) =>
              `${option.name}${
                option.designation && ` (${option.designation})`
              }`
            }
            onChange={this.onChangeOption}
            disableClearable={true}
            value={this.state.autocompleteValue}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Assign Coaches"
                placeholder="Assign Coaches"
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={`${option.name}${
                    option.designation && ` (${option.designation})`
                  }`}
                  {...getTagProps({ index })}
                  disabled={option.id === this.props.user.id}
                />
              ))
            }
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <DialogContent
        className={`assignCoachModalContent ${
          this.state.loading ? "backgroundLight" : ""
        }`}
      >
        <Typography
          className="mgTopAndBottom24 modalFormTitle boldText"
          variant="h5"
        >
          Assigned Coaches
        </Typography>
        {this.renderCloseButton()}
        {this.renderDialogContentData()}
      </DialogContent>
    );
  }
}
