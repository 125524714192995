import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { CircularProgress, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import Joyride from "react-joyride";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FortisFormaSelect from "../../components/autoSuggest/dropdownSelect";
import SectionedFeedback from "../../components/sectionedFeedback/sectionedFeedback";
import SectionedWorkout from "../../components/sectionedWorkout/sectionedWorkout";
import SlidingPane from "../../components/slidingPane/slidingPane";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import constants, {
  COLLECTIONS,
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  ROLES,
  TIER,
  WALKTHROUGH,
  WORKOUT_SECTIONS_NAMES,
} from "../../constants";
import { WORKOUT_SUMMARY_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import CalendarDetailCard from "./calendarDetailCard";
import WorkoutExerciseCard from "./workoutExerciseCard";

import CloseIcon from "@material-ui/icons/Close";
import TAButton from "../../utils/TAButton";

const modalContent = {
  Notes:
    "With this feature, you are able to access all workout notes, clinical notes, or any other miscellaneous notes you have with the specified client, here, instead of in a notebook or on an Excel sheet. The notes section makes it easier to find notes regarding a client, and to update them after a session together.",
  Assessment:
    "In this section, you are able to organize all assessment forms for a particular client here. Physical assessment forms, consultation forms, strength test forms, or other individualized forms that you have created can be easily found and updated in this section to help keep your clients' information up-to-date",
  Forms:
    "Have all of the designated forms for a specific client in one place for easy organization and quick reference. You are able to filter the forms, see the status/type of forms, and add new forms for the specified client here.",
  Messages:
    "With this feature, you can message to your respective clients at any time to get their real time workout updates.You can get this feature by upgrading your subscription.",
};

const cardStyle = {
  margin: 10,
  minHeight: window.innerWidth < 767 && window.innerWidth > 300 ? 150 : 420,
  width: window.innerWidth < 767 && window.innerWidth > 300 ? 150 : 250,
};

const videoStyle = {
  width: window.innerWidth < 767 && window.innerWidth > 300 ? 150 : 250,
  height: window.innerWidth < 767 && window.innerWidth > 300 ? 150 : 250,
};

export default class WorkoutSummary extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);

    let summaryDetails = {};
    try {
      let savedState = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.WORKOUT_SUMMARY_DETAILS,
        "{}"
      );
      savedState = JSON.parse(savedState);

      summaryDetails = Object.assign(summaryDetails, savedState);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Cannot access local storage");
      this.props.history.replace("/dashboard");
    }
    this.workoutsData = {};
    this.dateVSWorkoutIds = {};
    this.summaryLogs = {};
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.WORKOUT_SUMMARY;
    this.state = {
      walkthrough: false,
      clientName: summaryDetails.clientName,
      clientEmail: summaryDetails.clientEmail,
      clientId: summaryDetails.clientId,
      dates: [],
      selectedDate: null,
      loading: false,
      startDate: window.moment().startOf("month"),
      endDate: window.moment(),
      steps: WORKOUT_SUMMARY_WALKTHROUGH.steps,
      loggedWorkoutDropdownOption: null,
      selectedLoggedWorkout: null,
      showModal: { value: false, modalTitle: "", button: false },
    };
  }

  isAdminAccess() {
    return this.props.user.role === ROLES.ADMIN;
  }

  _createQuery() {
    let filters = [
      {
        key: DB_KEYS.CLIENT_ID,
        operator: "==",
        value: this.state.clientId,
      },
    ];
    if (!this.isAdminAccess()) {
      filters.push({
        key: DB_KEYS.TRAINER_ID_KEY,
        operator: "==",
        value: this.props.user.id,
      });
    }
    return {
      collection: COLLECTIONS.WORKOUT_SUMMARY,
      filters,
    };
  }

  async fetchSummary() {
    this.setState({
      dates: [],
      loading: true,
    });
    this.workoutsData = {};
    this.dateVSWorkoutIds = {};
    this.summaryLogs = {};

    let startDate = this.state.startDate.startOf("day");
    let endDate = this.state.endDate.startOf("day");

    const assignDateQuery = this._createQuery();
    const endDateQuery = this._createQuery();
    const unfinishedProgramsQuery = this._createQuery();
    let assignDateQueryUp = this._createQuery();
    let endDateQueryUp = this._createQuery();
    let unfinishedProgramsQueryUp = this._createQuery();
    let assignDateQueryDown = this._createQuery();
    let endDateQueryDown = this._createQuery();
    let unfinishedProgramsQueryDown = this._createQuery();
    if (this.props) {
      if (this.props.ppEnterprise) {
        const idxObj = assignDateQueryDown.filters.findIndex((object) => {
          return object.key === "trainerId";
        });
        assignDateQueryDown.filters.splice(idxObj, 1);
        assignDateQueryDown.filters.push({
          key: "enterpriseId",
          operator: "==",
          value: this.props.ppEnterprise.id,
        });
        const idxObj1 = endDateQueryDown.filters.findIndex((object) => {
          return object.key === "trainerId";
        });
        endDateQueryDown.filters.splice(idxObj1, 1);
        endDateQueryDown.filters.push({
          key: "enterpriseId",
          operator: "==",
          value: this.props.ppEnterprise.id,
        });
        const idxObj2 = unfinishedProgramsQueryDown.filters.findIndex(
          (object) => {
            return object.key === "trainerId";
          }
        );
        unfinishedProgramsQueryDown.filters.splice(idxObj2, 1);
        unfinishedProgramsQueryDown.filters.push({
          key: "enterpriseId",
          operator: "==",
          value: this.props.ppEnterprise.id,
        });
      }
    }

    if (this.props.user) {
      if (this.props.user.enterpriseId) {
        const idxObj = assignDateQueryUp.filters.findIndex((object) => {
          return object.key === "enterpriseId";
        });
        assignDateQueryUp.filters.splice(idxObj, 1);
        assignDateQueryUp.filters.push({
          key: "trainerId",
          operator: "==",
          value: this.props.user.id,
        });
        const idxObj1 = endDateQueryUp.filters.findIndex((object) => {
          return object.key === "enterpriseId";
        });
        endDateQueryUp.filters.splice(idxObj1, 1);
        endDateQueryUp.filters.push({
          key: "trainerId",
          operator: "==",
          value: this.props.user.id,
        });
        const idxObj2 = unfinishedProgramsQueryUp.filters.findIndex(
          (object) => {
            return object.key === "enterpriseId";
          }
        );
        unfinishedProgramsQueryUp.filters.splice(idxObj2, 1);
        unfinishedProgramsQueryUp.filters.push({
          key: "trainerId",
          operator: "==",
          value: this.props.user.id,
        });
      }
    }

    //Find all summaries which were assigned bw start and end date
    //Find all summaries wh
    assignDateQuery.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    assignDateQuery.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: "<=",
      value: endDate.unix(),
    });

    assignDateQueryUp.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    assignDateQueryUp.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: "<=",
      value: endDate.unix(),
    });
    endDateQueryUp.filters.push({
      key: DB_KEYS.END_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    endDateQueryUp.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "<=",
      value: window.moment.unix(endDate.unix()).add(-7, "days").unix(),
    });
    unfinishedProgramsQueryUp.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "==",
      value: null,
    });

    assignDateQueryDown.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    assignDateQueryDown.filters.push({
      key: DB_KEYS.ASSIGN_DATE,
      operator: "<=",
      value: endDate.unix(),
    });
    endDateQuery.filters.push({
      key: DB_KEYS.END_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    endDateQuery.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "<=",
      value: window.moment.unix(endDate.unix()).add(-7, "days").unix(),
    });

    endDateQueryDown.filters.push({
      key: DB_KEYS.END_DATE,
      operator: ">=",
      value: startDate.unix(),
    });
    endDateQueryDown.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "<=",
      value: window.moment.unix(endDate.unix()).add(-7, "days").unix(),
    });
    unfinishedProgramsQuery.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "==",
      value: null,
    });

    unfinishedProgramsQueryDown.filters.push({
      key: DB_KEYS.END_DATE,
      operator: "==",
      value: null,
    });

    const tasks = [];
    tasks.push(window.FortisForma.database.queryData(assignDateQuery));
    tasks.push(window.FortisForma.database.queryData(endDateQuery));
    tasks.push(window.FortisForma.database.queryData(unfinishedProgramsQuery));
    // if (this.props.user.enterpriseId) {
    tasks.push(window.FortisForma.database.queryData(assignDateQueryUp));
    tasks.push(window.FortisForma.database.queryData(endDateQueryUp));
    tasks.push(
      window.FortisForma.database.queryData(unfinishedProgramsQueryUp)
    );
    // }
    // if (this.props.ppEnterprise) {
    tasks.push(window.FortisForma.database.queryData(assignDateQueryDown));
    tasks.push(window.FortisForma.database.queryData(endDateQueryDown));
    tasks.push(
      window.FortisForma.database.queryData(unfinishedProgramsQueryDown)
    );
    // }

    const combinedResults = await Promise.all(tasks);

    const startDateResults = combinedResults[0];
    const endDateResults = combinedResults[1];
    const unfinishedResults = combinedResults[2];
    // let startDateResults1;
    // let endDateResults1;
    // let unfinishedResults1;
    // if (this.props.user.enterpriseId) {
    const startDateResults1 = combinedResults[3];
    const endDateResults1 = combinedResults[4];
    const unfinishedResults1 = combinedResults[5];
    // }
    const startDateResultsDown = combinedResults[6];
    const endDateResultsDown = combinedResults[7];
    const unfinishedResultsDown = combinedResults[8];
    // if (this.props.ppEnterprise) {
    //   startDateResultsDown = combinedResults[6];
    //   endDateResultsDown = combinedResults[7];
    //   unfinishedResultsDown = combinedResults[8];
    // }

    let results = [];
    if (startDateResults) {
      results = results.concat(startDateResults);
    }
    if (endDateResults) {
      results = results.concat(endDateResults);
    }
    if (unfinishedResults) {
      results = results.concat(unfinishedResults);
    }
    if (startDateResults1) {
      results = results.concat(startDateResults1);
    }
    if (endDateResults1) {
      results = results.concat(endDateResults1);
    }
    if (unfinishedResults1) {
      results = results.concat(unfinishedResults1);
    }
    if (startDateResultsDown) {
      results = results.concat(startDateResultsDown);
    }
    if (endDateResultsDown) {
      results = results.concat(endDateResultsDown);
    }
    if (unfinishedResultsDown) {
      results = results.concat(unfinishedResultsDown);
    }
    results = window._.uniq(results, "programId");

    if (results) {
      for (let summary of results) {
        Loop1: for (let date of Object.keys(summary.logs)) {
          let dateSummary = summary.logs[date];
          for (let logId of Object.keys(dateSummary)) {
            let exerciseLogs = dateSummary[logId];
            for (let exIndex of Object.keys(exerciseLogs)) {
              if (!exerciseLogs[exIndex].exerciseId) {
                break Loop1;
              }
            }
            dateSummary[logId] = [exerciseLogs];
          }
          summary.logs[date] = dateSummary;
        }

        await this.addSummary(summary);
      }
    } else {
      // TODO: handle missing summary
      window.NotificationUtils.showError("No workout summary for this period");
    }

    this.setState({
      loading: false,
    });
  }

  dateKeyFormat(moment) {
    return moment.format("L").replace(/\//g, "-");
  }

  async fetchProgramHistory(programId) {
    try {
      let results = await window.FortisForma.database.getProgramHistory(
        programId
      );
      return results;
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Unable to fetch program history");
    }
  }

  breakInPairs(array) {
    const pairs = [];
    for (var i = 0; i < array.length; i++) {
      pairs.push([array[i], array[i + 1]]);
    }
    return pairs.reverse();
  }

  async addSummary(summary) {
    let programHistory;
    try {
      programHistory = await this.fetchProgramHistory(summary.programId);
    } catch (e) {
      console.error(e);
      throw e;
    }

    let startDates = summary.startDates || [
      window.moment.unix(summary.assignDate).startOf("day").unix(),
    ];
    let summaryEndDate = window.moment
      .unix(summary.endDate || new Date().getTime() / 1000)
      .startOf("day");
    startDates.push(summaryEndDate.unix());

    let pairs = this.breakInPairs(startDates);
    let selectedStartDate = window.moment(this.state.startDate).startOf("day");
    let selectedEndDate = window.moment(this.state.endDate).startOf("day");

    for (let pair of pairs) {
      let startDate = window.moment.unix(pair[0]).startOf("day");
      let endDate = window.moment.unix(pair[1]).startOf("day");

      let programData;
      let dateVsWorkoutIds = {};

      let diff = Math.abs(endDate.diff(startDate, "days"));

      for (let day = 0; day <= diff; day++) {
        let date = window.moment
          .unix(startDate.unix())
          .add(day, "days")
          .startOf("day");
        if (date.isBefore(selectedStartDate) || date.isAfter(selectedEndDate)) {
          continue;
        }

        let timeStamp = date.unix();

        let dateKey = this.dateKeyFormat(date);

        this.setDayLoading(dateKey, true);
        programData = await this.getProgramForDate(
          programHistory,
          timeStamp,
          summary.programId
        );

        if (!programData) {
          console.info("Rest day", dateKey);
          this.setRestDay(dateKey);
          continue;
        }

        let workoutDaysCount = Object.keys(
          programData.workoutDaysMap || {}
        ).length;
        let i = day;
        if (programData.repeating) {
          i = i % workoutDaysCount;
        }

        if (programData.workoutDaysMap[i]) {
          dateVsWorkoutIds[dateKey] =
            programData.workoutDaysMap[i] + "_" + timeStamp;
        } else if (!this.dateVSWorkoutIds[dateKey]) {
          dateVsWorkoutIds[dateKey] = null;
        }

        let workoutDataMap = Object.assign({}, programData.workoutDataMap);

        let workoutKeys = Object.keys(workoutDataMap);
        for (let key of workoutKeys) {
          workoutDataMap[key + "_" + timeStamp] = workoutDataMap[key];
        }
        this.workoutsData = Object.assign(
          {},
          this.workoutsData,
          workoutDataMap
        );

        this.dateVSWorkoutIds = Object.assign(
          this.dateVSWorkoutIds,
          dateVsWorkoutIds
        );
        this.summaryLogs = Object.assign(this.summaryLogs, summary.logs);

        let dates = Object.keys(this.dateVSWorkoutIds);
        let currentDates = this.state.dates;
        currentDates = Object.assign([], currentDates, dates);
        currentDates.sort();
        let workoutLogs = Object.assign([], this.state.workoutLogs);
        workoutLogs = this.mapEvents(workoutLogs, dates);
        await new Promise((resolve) => {
          this.setState(
            {
              workoutLogs,
            },
            () => {
              requestAnimationFrame(resolve);
            }
          );
        });
      }
    }
  }

  setDayLoading(date, loading) {
    let workoutLogs = Object.assign([], this.state.workoutLogs);
    let eventDate = window.moment(date, "L").format("YYYY-MM-DD");
    if (loading) {
      workoutLogs.push({
        start: eventDate,
        loading: true,
        backgroundColor: "transparent",
        borderColor: "transparent",
      });
    } else {
      workoutLogs = workoutLogs.filter((event) => event.start !== eventDate);
    }

    this.setState({ workoutLogs });
  }

  setRestDay(date) {
    let workoutLogs = Object.assign([], this.state.workoutLogs);
    let eventDate = window.moment(date, "L").format("YYYY-MM-DD");

    workoutLogs = workoutLogs.filter((event) => event.start !== eventDate);

    workoutLogs.push({
      start: eventDate,
      rest: true,
      backgroundColor: "transparent",
      borderColor: "transparent",
    });

    this.setState({ workoutLogs });
  }

  mapEvents(workoutLogs, dates) {
    for (let date of dates) {
      let eventDate = window.moment(date, "L").format("YYYY-MM-DD");
      workoutLogs = workoutLogs.filter((event) => event.start !== eventDate);

      let workouts = this.getLoggedWorkouts(date);
      if (workouts && workouts.loggedWorkoutDropdownOption) {
        workoutLogs = workoutLogs.concat(
          this.mapOptionsToEvent(
            workouts.loggedWorkoutDropdownOption,
            eventDate,
            date
          )
        );
      } else {
        workoutLogs = workoutLogs.concat([
          {
            start: eventDate,
            rest: true,
            backgroundColor: "transparent",
            borderColor: "transparent",
          },
        ]);
      }
    }
    return workoutLogs;
  }

  mapOptionsToEvent(workoutOptions, date, originalDate) {
    return workoutOptions.map((option) => {
      return {
        start: date,
        allDay: true,
        title: option.label,
        backgroundColor: "transparent",
        borderColor: "#800520",
        data: option.value,
        originalDate,
        dropDownOptions: workoutOptions,
      };
    });
  }

  getLoggedWorkouts(date) {
    let loggedData = this.summaryLogs[date];

    if (!loggedData) {
      return {
        selectedLoggedWorkout: null,
        loggedWorkoutDropdownOption: [],
      };
    }
    let workoutId = this.dateVSWorkoutIds[date];
    if (!workoutId && !loggedData) {
      return null;
    }
    let loggedWorkoutIds = Object.keys(loggedData);
    if (!loggedWorkoutIds) {
      throw new Error("Invalid state");
    }
    let loggedWorkoutDropdownOption = [];
    loggedWorkoutIds.map((item) => {
      let data;
      if (loggedWorkoutIds && workoutId && workoutId.includes(item)) {
        data = this.workoutsData[workoutId];
      } else {
        const timestamp = window
          .moment(date, "MM-DD-YYYY")
          .startOf("day")
          .unix();
        data = this.workoutsData[item + "_" + timestamp];
      }
      if (data) {
        let optionData = {};
        optionData.value = {};
        optionData.value.data = data;
        optionData.value.logId = item;
        optionData.label = data.name;
        optionData.value.loggedData = loggedData[item];
        loggedWorkoutDropdownOption.push(optionData);
      }
      return null;
    });

    return {
      loggedWorkoutDropdownOption: loggedWorkoutDropdownOption,
      selectedLoggedWorkout: loggedWorkoutDropdownOption[0], //For pre selecting 1st option
    };
  }

  async getProgramForDate(programHistory, date, programId) {
    let keys = Object.keys(programHistory);
    keys = keys.filter((key) => {
      return isNaN(key) === false;
    });
    let keysMap = {};

    keys = keys.map((key) => {
      let value = window.moment.unix(key).startOf("day").unix();
      keysMap[value] = key;
      return value;
    });
    let timestamp = Math.max.apply(
      Math,
      keys.filter((key) => key <= date)
    );
    let program = programHistory[keysMap[timestamp]] || null;
    if (typeof program === "string" || program instanceof String) {
      if (!this.programCache) {
        this.programCache = {};
      }
      if (this.programCache[program + programId]) {
        program = this.programCache[program + programId];
      } else {
        let newProgram =
          await window.FortisForma.database.getProgramFromHistory(
            program,
            programId
          );
        this.programCache[program + programId] = newProgram;
        program = newProgram;
      }

      programHistory[keysMap[timestamp]] = program;
      for (let workoutId of Object.keys(program.workoutDataMap)) {
        let exercises = Object.assign(
          [],
          program.workoutDataMap[workoutId].exercises
        );
        let workoutSections = Object.assign(
          [],
          program.workoutDataMap[workoutId].workoutSections
        );
        if (!workoutSections || workoutSections.length < 1) {
          program.workoutDataMap[workoutId].workoutSections = [
            {
              workoutType: WORKOUT_SECTIONS_NAMES.CIRCUIT,
              sets: 1,
              exercises,
            },
          ];
          delete program.workoutDataMap[workoutId].exercises;
        }
      }
    }
    return program || null;
  }

  onChangeLoggedWorkoutDropdown = (selectedOption) => {
    this.setState({
      selectedLoggedWorkout: selectedOption,
    });
  };

  navigate = () => {
    let didNavigateDown = this.state.didNavigateDown || false;
    let node;
    if (this.state.didNavigateDown) {
      node = document.querySelector("nav");
    } else {
      node = document.querySelector(".summaryWorkoutColumn");
    }

    if (node) {
      node = node || node[0];

      try {
        node && node.scrollIntoView({ block: "start", behavior: "smooth" });
      } catch (e) {}
    }
    this.setState({
      didNavigateDown: !didNavigateDown,
    });
    return null;
  };

  navigationIcon = () => {
    let iconName = this.state.didNavigateDown
      ? "keyboard_arrow_up"
      : "keyboard_arrow_down";
    return <div className="icon material-icons">{iconName}</div>;
  };

  onSelectDateRange = (start, end) => {
    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      async () => {
        try {
          await this.fetchSummary();
        } catch (e) {
          console.error(e);
        }
      }
    );
  };

  datePickerProps = () => {
    return {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      maxDate: window.moment(),
      ranges: {
        "Last Week": [window.moment().add(-7, "days"), window.moment()],
        "Last 30 days": [window.moment().add(-30, "days"), window.moment()],
      },
    };
  };

  onSelectWorkout = (selected) => {
    if (!selected) {
      return;
    }
    let selectedOptions = (selected.dropDownOptions || []).filter(
      (item) => item.value.logId === selected.data.logId
    );
    if (!selectedOptions.length) {
      return;
    }
    this.setState({
      loggedWorkoutDropdownOption: selected.dropDownOptions,
      selectedDate: selected.originalDate,
      selectedLoggedWorkout: selectedOptions[0],
    });
  };

  onPaneClose = () => {
    this.setState({
      selectedDate: null,
    });
  };

  renderJoyride = () => {
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.walkthrough}
          steps={this.state.steps}
          continuous={true}
          showSkipButton={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          locale={{ last: "Done" }}
        />
      </div>
    );
  };

  renderEventContent = (eventInfo) => {
    return (
      <>
        <CalendarDetailCard
          onClick={this.onSelectWorkout}
          title={eventInfo.event.title}
          data={eventInfo.event.extendedProps}
        />
      </>
    );
  };

  fetchEvensOnDateChange = (fetchInfo, successCallback) => {
    this.onSelectDateRange(
      window.moment(fetchInfo.startStr),
      window.moment(fetchInfo.endStr)
    );
    successCallback([]);
  };

  renderPopupModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal.value}
        style={{ maxWidth: "650px" }}
        backdrop={true}
        toggle={() => {
          this.setState({
            showModal: {
              value: !this.state.showModal.value,
              modalTitle: "",
              button: false,
            },
          });
        }}
      >
        <ModalHeader style={{ textAlign: "right" }}>
          <CloseIcon
            color="default"
            aria-label="close modal"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                showModal: { value: false, modalTitle: "", button: false },
              });
            }}
          >
            <i className="close material-icons">close</i>
          </CloseIcon>
        </ModalHeader>
        <ModalHeader style={{ paddingTop: 0 }}>
          <p
            style={{
              color: "#800520",
              fontWeight: "bold",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            {this.state.showModal.modalTitle}
          </p>
        </ModalHeader>
        <ModalBody
          style={{
            color: "black",
            fontSize: "14px",
            textAlign: "center",
            paddingBottom: "44px",
          }}
        >
          <>{modalContent[this.state.showModal.modalTitle]}</>
          {this.state.showModal.button === true && (
            <TAButton
              width="60%"
              color="primary"
              variant="contained"
              height="60px"
              style={{ marginTop: "25px" }}
              // {...activeButtonProps}
              onClick={() => this.props.history.push("/upgrade")}
            >
              Upgrade to Unlock this Feature
            </TAButton>
            // <div
            //   style={{
            //     padding: "20px",
            //     margin: "0 auto",
            //     borderRadius: "15px",
            //     fontSize: "14px",
            //     fontWeight: "bold",
            //     color: "white",
            //     marginTop: "25px",
            //     width: "60%",
            //     // minWidth: "max-content",
            //     backgroundColor: "#800520",
            //     letterSpacing: "1px",
            //   }}
            // >
            //   Upgrade to Unlock this Feature
            // </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  handleTabChange = (e, value, redirect = false) => {
    if (
      this.props.user.role === ROLES.TRAINER &&
      !(value === 0 || value === 1)
    ) {
      switch (value) {
        case 2:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Forms",
              button: true,
            },
          });
          break;
        case 3:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Assessment",
              button: true,
            },
          });
          break;
        case 4:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Notes",
              button: true,
            },
          });
          break;
        case 5:
          this.setState({
            showModal: {
              value: true,
              modalTitle: "Messages",
              button: true,
            },
          });
          break;
        default:
          this.setState({
            showModal: {
              value: false,
              modalTitle: "",
              button: true,
            },
          });
      }
    } else {
      if (value === 0) {
        this.props.history && this.props.history.push("/workout-assigner");
      }
    }
  };

  renderLoader() {
    return (
      <div id="workoutSummaryLoader">
        <CircularProgress color="primary" size={28} />
        <Typography id="summaryLoadingText" variant="body1">
          Fetching Summary ...
        </Typography>
      </div>
    );
  }

  renderTabs() {
    let client = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS)
    );

    return (
      <div className="TabsContainer">
        <div style={{ paddingLeft: "15px" }}>
          <strong style={{ fontSize: "18px", margin: "0 18px" }}>
            {client ? client.clientName : ""}
          </strong>
          <span>{client ? `(${client.clientEmail})` : ""}</span>
        </div>
        <Tabs
          value={1}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            className="enterpriseClientDetailTabLabel"
            label="Exercise Prescription"
          />
          <Tab
            className="enterpriseClientDetailTabLabel"
            label="Exercise Logs"
          />
          <Tab className="enterpriseClientDetailTabLabel" label="Forms" />
          <Tab className="enterpriseClientDetailTabLabel" label="Assessments" />
          <Tab className="enterpriseClientDetailTabLabel" label="Notes" />

          <Tab className="enterpriseClientDetailTabLabel" label="Messages" />
        </Tabs>
        {this.renderPopupModal()}
      </div>
    );
  }

  renderWorkoutDaySummary = () => {
    return (
      <Row
        className={`fullWidth fullWHListContainer ${
          this.props.user.enterpriseId ? "enterpriseWorkoutDaySummary" : ""
        }`}
        style={{ height: "calc(100vh - 150px)", marginTop: 16, border: 0 }}
      >
        <Col
          id="workoutSummaryLog"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{ width: "100%", height: "100%", overflowY: "scroll" }}
        >
          <Row>
            <Col lg="4" md="4" sm="4" xs="4" className="summaryWorkoutColumn">
              {this.state.selectedDate && (
                <div style={{ width: "98%" }}>
                  {this.renderAssignedWorkout(this.state.selectedDate)}
                </div>
              )}
            </Col>
            <Col lg="4" md="4" sm="4" xs="4" className="summaryWorkoutColumn">
              {this.state.selectedDate && (
                <div style={{ width: "98%" }}>
                  {this.renderLoggedWorkout(this.state.selectedDate)}
                </div>
              )}
            </Col>
            <Col lg="4" md="4" sm="4" xs="4" className="summaryWorkoutColumn">
              {this.state.selectedDate && (
                <div style={{ width: "98%" }}>
                  {this.renderFeedbackList(this.state.selectedDate)}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <>
        {this.state.walkthrough && this.renderJoyride()}
        {this.state.loading && this.renderLoader()}
        {this.props.user.role === ROLES.TRAINER &&
          this.props.user.tier === TIER.FREE &&
          this.renderTabs()}
        <div
          id="summaryCalendarContainer"
          className={
            this.props.user.role === ROLES.TRAINER
              ? "tabContentTrainerWorkoutSummary"
              : ""
          }
        >
          <FullCalendar
            buttonText={{ today: "Today" }}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            eventSources={[
              this.state.workoutLogs || [],
              this.fetchEvensOnDateChange,
            ]}
            eventContent={this.renderEventContent}
          />
        </div>

        <SlidingPane
          open={Boolean(this.state.selectedDate)}
          renderPaneData={this.renderWorkoutDaySummary}
          onClose={this.onPaneClose}
          KlassName="workoutSummaryExPane"
        />
        {/* {this.reder} */}
      </>
    );
  }

  renderAssignedWorkout(date) {
    let selectedValue =
      this.state.selectedLoggedWorkout &&
      this.state.selectedLoggedWorkout.value;
    let assignedWorkoutId =
      (selectedValue && selectedValue.data.id) || this.dateVSWorkoutIds[date];
    // this.dateVSWorkoutIds[date];

    if (!assignedWorkoutId) {
      return (
        <div id="noWorkoutText">
          <div className="summaryContentHeading">Assigned Workout</div>
          <div
            style={{ marginTop: 100, color: "darkgrey", textAlign: "center" }}
          >
            Rest Day
          </div>
        </div>
      );
    }
    const timestamp = window.moment(date, "MM-DD-YYYY").startOf("day").unix();
    let data = this.workoutsData[assignedWorkoutId + "_" + timestamp];

    return (
      <div className="assignedSummaryContent">
        <div className="AssignedsummaryContentHeading">
          Assigned Workout ({data.name})
        </div>

        {Object.keys(data).length > 0 &&
          (data.exercises ? (
            data.exercises.map((exercise, index) => {
              return this.workoutExerciseCard(
                exercise,
                { showNote: true },
                `assigned${index}`
              );
            })
          ) : (
            <div style={{ marginTop: 19 }}>
              <SectionedWorkout
                workoutSections={data.workoutSections}
                collapsible={false}
                size="small"
              />
            </div>
          ))}
      </div>
    );
  }

  workoutExerciseCard(data, options = {}, key) {
    return (
      <WorkoutExerciseCard
        key={`${data.id}${key}`}
        videoWidth={videoStyle.width}
        videoHeight={videoStyle.height}
        cardStyle={cardStyle}
        data={data}
        {...options}
      />
    );
  }

  renderFeedbackList(date) {
    let loggedData = this.summaryLogs[date];
    let selectedValue =
      this.state.selectedLoggedWorkout &&
      this.state.selectedLoggedWorkout.value;
    let loggedWorkoutId = selectedValue && selectedValue.logId;
    let data = selectedValue && selectedValue.data;
    let logged = Object.assign({}, loggedData[loggedWorkoutId]);
    delete logged.feedback;
    if (!loggedData || !loggedWorkoutId || !data) {
      return (
        <div style={{ marginTop: "19px" }}>
          <div className="AssignedsummaryContentHeading">Exercise Feedback</div>
          <div
            style={{ marginTop: 100, color: "darkgrey", textAlign: "center" }}
          >
            No feedback
          </div>
        </div>
      );
    }
    if (data.exercises) {
      return (
        <div style={{ marginTop: "19px" }}>
          <div className="AssignedsummaryContentHeading">Exercise Feedback</div>
          <div className="viewWorkoutSection summaryExerciseFeedback">
            {data.exercises.map((exercise, index) => {
              let logged = loggedData[loggedWorkoutId][index];
              if (logged.exercise) {
                return <div key={index} id="feedbackLogged"></div>;
              }

              return (
                <div key={index} id="feedbackNotLogged">
                  Feedback not logged
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="workoutSummaryExercises">
        <div className="feedbackContentHeading">Exercise Feedback</div>
        <div style={{ marginTop: 10 }}>
          <SectionedFeedback
            workoutSections={data.workoutSections}
            size="small"
            workoutType="logged"
            loggedWorkoutId={loggedWorkoutId}
            loggedData={loggedData}
          />
        </div>
      </div>
    );
  }

  renderLoggedWorkout(date) {
    let loggedData = this.summaryLogs[date];
    let selectedValue =
      this.state.selectedLoggedWorkout &&
      this.state.selectedLoggedWorkout.value;
    let loggedWorkoutId = selectedValue && selectedValue.logId;
    let data = selectedValue && selectedValue.data;

    if (!loggedData || !loggedWorkoutId || !data) {
      return (
        <div id="noWorkoutText">
          <div className="summaryContentHeading">Logged Workout</div>
          <div
            style={{ marginTop: 100, color: "darkgrey", textAlign: "center" }}
          >
            No workout performed on this date
          </div>
        </div>
      );
    }

    if (!loggedWorkoutId) {
      throw new Error("Invalid state");
    }

    if (data.exercises) {
      return (
        <div className="workoutSummaryExercises">
          <div className="summaryContentHeading">
            Logged Workout ({data.name})
          </div>
          {data.exercises.map((exercise, index) => {
            let logged = loggedData[loggedWorkoutId][index];
            if (!logged) {
              // REVIEW: Render not completed view
              return (
                <div
                  key={index}
                  style={{
                    margin: 10,
                    minHeight: 420,
                    width: 250,
                    backgroundColor: "#F0F0F0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Workout not logged
                </div>
              );
            }
            if (logged.exercise) {
              return this.workoutExerciseCard(
                logged.exercise,
                {},
                `logged${index}`
              );
            }
            let data = Object.assign({}, exercise, logged);
            return this.workoutExerciseCard(data, {}, `logged${index}`);
          })}
        </div>
      );
    }
    return (
      <div className="workoutSummaryExercises">
        {/*data.name*/}
        <div className="summaryContentHeading extrasummaryContentHeading">
          Logged Workout
          <FortisFormaSelect
            // placeholder={field.placeholder}
            id="loggedWorkoutDropdown"
            // inputId={field.name}
            value={this.state.selectedLoggedWorkout}
            classNamePrefix="fortisFormaDropDownSelect"
            onChange={this.onChangeLoggedWorkoutDropdown}
            options={
              this.state.loggedWorkoutDropdownOption &&
              this.state.loggedWorkoutDropdownOption
            }
            isClearable={false}
            // handleLoading={false}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <SectionedWorkout
            workoutSections={data.workoutSections}
            collapsible={false}
            size="small"
            workoutType="logged"
            loggedWorkoutId={loggedWorkoutId}
            loggedData={loggedData}
          />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.FortisForma.Cache.clearCache(constants.COLLECTIONS.WORKOUT_SUMMARY);
    window.FortisForma.Cache.clearCache(constants.COLLECTIONS.PROGRAM_HISTORY);
  }
}
