import React, { Component } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export default class SlidingModal extends Component {
  render() {
    return (
      <SlidingPane
        title={this.props.title || ""}
        isOpen={this.props.open}
        onRequestClose={this.props.onClose}
        className={this.props.KlassName}
      >
        {this.props.renderPaneData()}
      </SlidingPane>
    );
  }
}
