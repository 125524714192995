import React from "react";

import ExerciseCard from "./exerciseCard";

import InfiniteList from "../../components/infiniteList/list.js";

export default class ExerciseList extends InfiniteList {
  loadNextPage() {
    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        await this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
  rowRenderer({ key, index, isScrolling, style }) {
    let cardPadding = window.screen.availWidth < 1200 ? 8 : 20;
    let cardStyle = {
      position: "relative",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      height: style.height - cardPadding,
      width: style.width - cardPadding,
    };

    let data = this.props.data[index];

    return (
      <div style={style} key={key}>
        <ExerciseCard
          showBadge={this.props.containerId === "exerciseEditor"}
          videoWidth={cardStyle.width}
          videoHeight={185}
          cardStyle={cardStyle}
          data={data}
          selectEnabled={this.props.selectEnabled}
          onSelect={this.props.onSelect}
          selectButtonView={this.props.selectButtonView}
        />
      </div>
    );
  }
}
