import { Dialog } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { isMobile, withOrientationChange } from "react-device-detect";
import { FORM_WIDGET_CONFIRMATION_TEXT } from "../../constants";
import ImageEditorModal from "../imageEditorModal/imageEditorModal";

function AddImageWidget(props) {
  const { value, onChange, schema, required } = props;
  const [showEditor, setShowEditor] = useState(false);
  const [values, setValues] = useState(false);
  const [imageData, setImageData] = useState({});
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(-1);
  const editorModalRef = useRef(null);

  React.useEffect(() => {
    if (!value) {
      let submission = [];
      let images = schema && schema.backgroundImages;
      for (let i = 0; i < images.length; i++) {
        submission[i] = {
          backgroundImageURL: images[i],
        };
      }
      setValues(submission);
    } else {
      setValues(value);
    }
  }, []);

  const onCloseEditor = () => {
    setShowEditor(false);
  };

  const handleCloseEditor = () => {
    editorModalRef.current &&
      editorModalRef.current.confirmClose(
        onCloseEditor,
        FORM_WIDGET_CONFIRMATION_TEXT.UNSAVED_DATA
      );
  };

  const handleSave = (val) => {
    let submission = [];
    submission = [...values];
    submission.splice(backgroundImageIndex, 1, val);
    onChange(submission);
    setValues(submission);
    setShowEditor(false);
  };

  const onClickEdit = (item, index) => {
    setImageData(item);
    setBackgroundImageIndex(index);
    setShowEditor(true);
  };

  const renderPreview = (item, index, length) => {
    return (
      <div
        className="freehandNotePreviewContainer"
        style={{ marginBottom: length > 1 && "16px" }}
        onClick={() => onClickEdit(item, index)}
        key={index}
      >
        <div
          className="addImageWidgetBackgroundImage"
          style={{
            backgroundImage: `url(${CSS.escape(item.backgroundImageURL)})`,
          }}
        >
          {item.canvasImage && (
            <img
              src={item.canvasImage}
              className="addImageCanvasImage"
              alt="widget-preview"
            />
          )}
        </div>
        {`Click to edit ${length > 1 ? `page ${index + 1} of ${length} ` : ""}`}
      </div>
    );
  };

  const renderEditor = () => {
    return (
      <Dialog
        open={showEditor}
        maxWidth={isMobile ? false : "lg"}
        fullScreen={isMobile ? true : false}
        onClose={handleCloseEditor}
      >
        <ImageEditorModal
          ref={editorModalRef}
          title="Image Editor"
          onClose={handleCloseEditor}
          imageMode={true}
          handleSave={handleSave}
          backgroundImageIndex={backgroundImageIndex}
          backgroundImage={imageData.backgroundImageURL}
          canvasData={imageData.canvasData || null}
          isPortrait={props.isPortrait}
        />
      </Dialog>
    );
  };

  const renderImages = () => {
    if (values && values.length) {
      return values.map((item, index) => {
        return renderPreview(item, index, values.length);
      });
    }
  };

  return (
    <div>
      {showEditor && renderEditor()}
      <div
        style={{ marginBottom: "4px", fontSize: "0.9rem" }}
        className="widgetQuestionText"
      >
        {`${schema.title}${required ? "*" : ""}`}
      </div>
      {renderImages()}
    </div>
  );
}

export default withOrientationChange(AddImageWidget);
