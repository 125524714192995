import React from "react";
import BaseModal from "./baseModal";

import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

export default class ConfirmModal extends BaseModal {
  render() {
    return (
      <Modal isOpen={this.state.show} backdrop={true} zIndex="1300">
        <ModalBody>{this.state.message}</ModalBody>
        <ModalFooter>
          <Button className="btn btn-outline-secondary" onClick={this.onCancel}>
            {this.state.cancelButtonText || "Cancel"}
          </Button>{" "}
          {this.state.saveDraftButtonText && (
            <Button className="primary" onClick={this.onSaveDraft}>
              {this.state.saveDraftButtonText}
            </Button>
          )}
          <Button color="primary" onClick={this.onConfirm}>
            {this.state.okButtonText || "Yes"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
