import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import { SHARE_WORKOUT_OPTIONS } from "../../constants";

export const sourcePropTypes = {
  WORKOUT_DAYS: "workout-days",
  WORKOUT_ASSIGNER: "workout-assigner",
};

const menuOptions = [
  SHARE_WORKOUT_OPTIONS.VIA_LINK,
  SHARE_WORKOUT_OPTIONS.VIA_EMAIL,
  SHARE_WORKOUT_OPTIONS.DOWNLOAD_PDF,
];

export default function ShareWorkoutButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpenClick = (event) => {
    event && event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event && event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuOption = (option) => {
    setAnchorEl(null);
    props.onSelectShareOption(option);
  };

  const renderShareOptions = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuOptions &&
          menuOptions.map((menuItem) => (
            <MenuItem onClick={() => handleMenuOption(menuItem)}>
              {menuItem}
            </MenuItem>
          ))}
      </Menu>
    );
  };

  const renderWorkoutDaysShareButton = () => {
    return (
      <Tooltip title="Share Workout" placement={"top"} arrow>
        <i
          className="icon material-icons"
          id="shareWorkoutIcon"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuOpenClick}
        >
          share
        </i>
      </Tooltip>
    );
  };

  const renderWorkoutAssignerShareButton = () => {
    return (
      <Tooltip title="Share Workout" placement={"top"} arrow>
        <IconButton onClick={handleMenuOpenClick} size="small">
          <ShareIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      {props.source === sourcePropTypes.WORKOUT_DAYS
        ? renderWorkoutDaysShareButton()
        : renderWorkoutAssignerShareButton()}
      {renderShareOptions()}
    </>
  );
}

ShareWorkoutButton.defaultProps = {
  onSelectShareOption: () => {},
  source: sourcePropTypes.WORKOUT_DAYS,
};

ShareWorkoutButton.propTypes = {
  onSelectShareOption: PropTypes.func,
  source: PropTypes.oneOf(Object.values(sourcePropTypes)),
};
