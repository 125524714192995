import React, { Component } from "react";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";

const MODES = {
  studioOrGym: "Studio, Clinic Or Gym",
  inHome: "In-Home",
  online: "Online",
  other: "Other",
};

const TYPES = {
  private: "1 on 1",
  semiPrivate: "Small Group or Couples",
  largeGroup: "Large Group Training",
  other: "Other",
};

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openServiceEditCard: false,
      selectedServiceName: null,
      services: {
        mode: {},
        type: {},
      },
      modeOtherText: "",
      typeOtherText: "",
    };
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length > 0) {
      let services = this.props.data;
      let mappedData = this.mapServices(services);
      this.setState({
        services: mappedData.mappedServices,
        modeOtherText: mappedData.modeOtherText,
        typeOtherText: mappedData.typeOtherText,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && prevProps.data !== this.props.data) {
      let services = this.props.data;
      let mappedData = this.mapServices(services);
      this.setState({
        services: mappedData.mappedServices,
        modeOtherText: mappedData.modeOtherText,
        typeOtherText: mappedData.typeOtherText,
      });
    }
  }

  mapServices = (services) => {
    let mappedServices = {
      mode: {},
      type: {},
    };
    let modeOtherText = "";
    let typeOtherText = "";
    if (services.mode) {
      for (let mode of services.mode) {
        if (!MODES[mode]) {
          modeOtherText = mode;
          mappedServices.mode["other"] = true;
        } else {
          mappedServices.mode[mode] = true;
        }
      }
    }
    if (services.type) {
      for (let type of services.type) {
        if (!TYPES[type]) {
          typeOtherText = type;
          mappedServices.type["other"] = true;
        } else {
          mappedServices.type[type] = true;
        }
      }
    }
    return { mappedServices, modeOtherText, typeOtherText };
  };

  checkErrorsInForm = (activatedServices) => {
    if (!activatedServices) {
      window.NotificationUtils.showError(
        "Please select atleast one service mode"
      );
      return true;
    }

    if (!Object.keys(activatedServices.mode || {}).length) {
      window.NotificationUtils.showError(
        "Please select atleast one service mode"
      );
      return true;
    }

    if (activatedServices.type.other && !this.state.typeOtherText) {
      window.NotificationUtils.showError("Please specify a valid service type");
      return true;
    }

    if (!Object.keys(activatedServices.type || {}).length) {
      window.NotificationUtils.showError(
        "Please select atleast one service type"
      );
      return true;
    }

    if (activatedServices.mode.other && !this.state.modeOtherText) {
      window.NotificationUtils.showError("Please specify a valid service mode");
      return true;
    }

    return false;
  };

  getSaveData = (activatedServices) => {
    let mode = [];
    let type = [];
    if (
      Object.keys(activatedServices.mode) &&
      Object.keys(activatedServices.mode).length
    ) {
      for (let service of Object.keys(activatedServices.mode)) {
        if (activatedServices.mode[service] === true) {
          mode.push(service);
        }
      }
    }
    if (
      Object.keys(activatedServices.type) &&
      Object.keys(activatedServices.type).length
    ) {
      for (let service of Object.keys(activatedServices.type)) {
        if (activatedServices.type[service] === true) {
          type.push(service);
        }
      }
    }
    if (activatedServices.mode.other) {
      mode.push(this.state.modeOtherText);
    }
    if (activatedServices.type.other) {
      type.push(this.state.typeOtherText);
    }
    return { mode, type };
  };

  getData = (checkErrors = true) => {
    let activatedServices = Object.assign({}, this.state.services);
    if (checkErrors && this.checkErrorsInForm(activatedServices)) {
      return false;
    }
    activatedServices = this.getSaveData(activatedServices);
    let data = { services: activatedServices };
    return data;
  };

  updateValue = (key, value) => {
    let update = { [key]: value };
    this.setState(update);
  };

  handleChange = (key, subKey, value) => {
    let services = Object.assign({}, this.state.services);
    services[key][subKey] = value;
    this.setState({
      services,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="onBoardingHeading onBoardingSectionHeader">
          My Services
        </div>
        <div className="servicesContainer">
          <div className="servicesInnerContainer">
            <div className="onBoardingServicesSubHeader">
              <Typography variant="h6" className="pd-bottom-16" color="primary">
                Mode
              </Typography>
              <FormGroup className="mg-left-16">
                {Object.keys(MODES).map((mode, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={this.state.services.mode[mode] || false}
                          color="primary"
                          onChange={(e) =>
                            this.handleChange("mode", mode, e.target.checked)
                          }
                          name={mode}
                        />
                      }
                      label={MODES[mode]}
                    />
                  );
                })}
                {this.state.services.mode.other && (
                  <div className="fieldContainer onboardingFieldContainer">
                    <TextField
                      id="modeOtherField"
                      label="Other Mode"
                      required
                      fullWidth={true}
                      variant="outlined"
                      value={this.state.modeOtherText}
                      onChange={(ev) => {
                        this.updateValue("modeOtherText", ev.target.value);
                      }}
                    />
                  </div>
                )}
              </FormGroup>
            </div>
            <div className="onBoardingServicesSubHeader">
              <Typography variant="h6" className="pd-bottom-16" color="primary">
                Type
              </Typography>
              <FormGroup className="mg-left-16">
                {Object.keys(TYPES).map((typeName, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={this.state.services.type[typeName] || false}
                          color="primary"
                          onChange={(e) =>
                            this.handleChange(
                              "type",
                              typeName,
                              e.target.checked
                            )
                          }
                          name={typeName}
                        />
                      }
                      label={TYPES[typeName]}
                    />
                  );
                })}
                {this.state.services.type.other && (
                  <div className="fieldContainer onboardingFieldContainer">
                    <TextField
                      id="typeOtherField"
                      label="Other Type"
                      required
                      fullWidth={true}
                      variant="outlined"
                      value={this.state.typeOtherText}
                      onChange={(ev) => {
                        this.updateValue("typeOtherText", ev.target.value);
                      }}
                    />
                  </div>
                )}
              </FormGroup>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
