import React, { Component } from "react";


export default class DayItem extends Component {
    render() {
        if (this.props.restDay) {
            return (
                <div className="disabledDayItem">
                    Day {this.props.dayNumber}
                </div>
            )
        }
        return (
            <div className={this.props.selected ? "dayItemSelected" : "dayItem"} onClick={() => this.props.onDaySelect(this.props.dayNumber)}>
                Day {this.props.dayNumber}
            </div>
        )
    }
}