import React from "react";
import Reps from "../../assets/repsD.png";
import Sets from "../../assets/setsD.png";
import Weight from "../../assets/weightD.png";
import Clock from "../../assets/timeD.png";
import Resistance from "../../assets/resistanceD.png";
import { Typography, TextField, MenuItem, Select } from "@material-ui/core";

import {
  CONFIG_TYPES,
  EQUIPMENT_TYPES,
  EXERCISE_CONFIG_UNITS,
  RESISTANCE_DROPDOWN_DATA,
} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default class SectionedListExerciseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      showReplay: false,
      reps: this.props.data.reps,
      sets: this.props.data.sets,
      time: this.props.data.time,
      weight: this.props.data.weight,
      resistance: this.props.data.resistance,
    };
    this.playerRef = null;
    this.posterURL = props.data.posterURL;
  }

  onPressReplay = () => {
    this.setState({
      playing: true,
      showReplay: false,
    });
  };

  onFinishVideo = () => {
    this.playerRef && this.playerRef.seekTo(0);
    this.setState({
      playing: false,
      showReplay: true,
    });
  };

  onChangeConfigValue = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  componentDidUpdate(prevProps) {
    const prevData = prevProps && prevProps.data && prevProps.data;
    const currentData = this.props && this.props.data && this.props.data;
    if (prevData !== currentData) {
      this.setState({
        reps: currentData.reps || "",
        sets: currentData.sets || "",
        weight: currentData.weight || "",
        time: currentData.time || "",
        resistance: currentData.resistance || "",
      });
    }
    // HACK placeholder not refreshing
    if (this.posterURL !== this.props.data.posterURL) {
      this.posterURL = this.props.data.posterURL;
      let showPreview =
        this.playerRef &&
        this.playerRef.state &&
        this.playerRef.state.showPreview;
      if (showPreview && this.playerRef) {
        this.playerRef.setState(
          {
            showPreview: !showPreview,
          },
          () => {
            if (this.playerRef) {
              this.playerRef.setState({
                showPreview: showPreview,
              });
            }
          }
        );
      }
    }
  }

  isEmptyEquipmentType() {
    let types = Object.values(this.props.data.equipmentTypes);
    let isEmptyType = true;
    for (let type of types) {
      if (type) {
        isEmptyType = false;
      }
    }
    return isEmptyType;
  }

  isRepsDisabled = () => {
    if (this.repsDisabled !== undefined) {
      return this.repsDisabled;
    }
    this.repsDisabled = !this.props.data.reps;
    return this.repsDisabled;
  };
  isSetsDisabled = () => {
    if (this.setsDisabled !== undefined) {
      return this.setsDisabled;
    }
    this.setsDisabled = !this.props.data.sets;
    return this.setsDisabled;
  };
  isTimeDisabled = () => {
    if (this.timeDisabled !== undefined) {
      return this.timeDisabled;
    }
    this.timeDisabled = !this.props.data.time;
    return this.timeDisabled;
  };

  isResistanceDisabled = () => {
    let equipmentTypes = Object.values(this.props.data.equipmentTypes);
    let resistanceDisabled = !equipmentTypes.includes(
      EQUIPMENT_TYPES.RESISTANCE
    );
    if (resistanceDisabled && this.isEmptyEquipmentType()) {
      resistanceDisabled = !this.props.data.resistance;
    }
    return resistanceDisabled;
  };

  isWeightDisabled = () => {
    if (this.weightDisabled !== undefined) {
      return this.weightDisabled;
    }
    let equipmentTypes = Object.values(this.props.data.equipmentTypes);
    this.weightDisabled = !equipmentTypes.includes(EQUIPMENT_TYPES.FREEWEIGHT);
    if (this.weightDisabled && this.isEmptyEquipmentType()) {
      this.weightDisabled = !this.props.data.weight;
    }
    return this.weightDisabled;
  };

  onDonePress = (e, removeLog = false) => {
    e.stopPropagation();
    let params = this.prepareParameters();
    if (removeLog === true) {
      this.props.onRemoveLog(params.exercise, params.data);
      return;
    }
    this.props.onDonePress(params.exercise, params.data);
  };

  prepareParameters = () => {
    let exercise = {};
    exercise.logId = this.props.logId;
    exercise.name = this.props.data.name;
    exercise.index = this.props.index;
    exercise.log = {};
    exercise.log.reps = this.state.reps;
    exercise.log.sets = this.state.sets;
    exercise.log.time = this.state.time;
    exercise.log.weight = this.state.weight;
    exercise.log.resistance = this.state.resistance;
    return { exercise, data: this.props.data };
  };

  prepareParametersForSectionLog = () => {
    let params = {};
    params.reps = this.state.reps;
    params.sets = this.state.sets;
    params.time = this.state.time;
    params.weight = this.state.weight;
    params.resistance = this.state.resistance;
    return params;
  };

  renderConfigValueTextfield = (key, options) => {
    const newKey = key.toLowerCase();
    return (
      <>
        <TextField
          className="sectionedExerciseCardConfigValueTextfield"
          value={this.state[newKey]}
          onChange={(e) => this.onChangeConfigValue(e, newKey)}
          variant="outlined"
          disabled={this.props.disabled || this.props.isComplete}
        ></TextField>
        {options && <span className="optionsUnit">{options}</span>}
      </>
    );
  };

  renderConfigValueDropdown = (key) => {
    const newKey = key.toLowerCase();
    return (
      <>
        <Select
          className="sectionedExerciseCardConfigValueDropdown"
          value={this.state.resistance}
          onChange={(e) => this.onChangeConfigValue(e, newKey)}
          variant="outlined"
          disabled={this.props.disabled || this.props.isComplete}
        >
          {RESISTANCE_DROPDOWN_DATA.map((option, index) => {
            return (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  renderExtra(key, icon, options) {
    const isResistance = key === CONFIG_TYPES.RESISTANCE;
    return (
      <div className="workoutSessionConfigMainContainer">
        <div className="workoutSessionConfigImageKey">
          {icon && (
            <img
              style={{
                width: 14,
                height: 14,
                marginRight: 5,
              }}
              src={icon}
              alt={key}
            />
          )}
          <span className="workoutSessionConfigKey">{key}:</span>
        </div>
        <div
          className="workoutSessionConfigValue"
          onClick={(e) => e.stopPropagation()}
        >
          {isResistance
            ? this.renderConfigValueDropdown(key)
            : this.renderConfigValueTextfield(key, options)}
        </div>
      </div>
    );
  }

  renderConfig() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {this.props.data.resistance && !this.isResistanceDisabled() && (
          <div>{this.renderExtra(CONFIG_TYPES.RESISTANCE, Resistance)}</div>
        )}
        {Boolean(Number(this.props.data.sets)) && !this.isSetsDisabled() && (
          <div>{this.renderExtra(CONFIG_TYPES.SETS, Sets)}</div>
        )}
        {Boolean(Number(this.props.data.reps)) && !this.isRepsDisabled() && (
          <div>{this.renderExtra(CONFIG_TYPES.REPS, Reps)}</div>
        )}
        {Boolean(Number(this.props.data.weight)) &&
          !this.isWeightDisabled() && (
            <div>
              {this.renderExtra(
                CONFIG_TYPES.WEIGHT,
                Weight,
                EXERCISE_CONFIG_UNITS.WEIGHT
              )}
            </div>
          )}
        {Boolean(Number(this.props.data.time)) && !this.isTimeDisabled() && (
          <div>
            {this.renderExtra(
              CONFIG_TYPES.TIME,
              Clock,
              EXERCISE_CONFIG_UNITS.TIME
            )}
          </div>
        )}
      </div>
    );
  }

  renderExerciseLevel() {
    return (
      <Typography style={{ fontWeight: "600", fontSize: 12 }} color="primary">
        Level {this.props.data.level}
      </Typography>
    );
  }

  render() {
    return (
      <div
        id={`exerciseCard_${this.props.data.section}_${this.props.data.exerciseIndexInSection}`}
        className={
          !this.props.selected
            ? "workoutSessionSectionedExerciseCard"
            : "workoutSessionSectionedExerciseCard workoutSessionSectionedExerciseCard"
        }
        onClick={this.props.onClickExerciseMiniCard}
      >
        <div className="workoutSessionSectionedVideoPlayer">
          {this.props.data.posterURL ? (
            <img
              alt="Poster"
              src={this.props.data.posterURL}
              className="miniCardPoster"
            />
          ) : (
            <div className="miniCardPoster" />
          )}
        </div>
        <div className="workoutSessionSectionedCardContent">
          <div className="workoutSessionSectionedExerciseNameLevel">
            <Typography variant="body1">{this.props.data.name}</Typography>
          </div>
          <div className="workoutSessionSectionedExerciseConfig">
            {this.renderConfig()}
          </div>
          <div>{this.renderExerciseLevel()}</div>
        </div>
        <div id="miniCardDoneContainer">
          {this.props.isComplete ? (
            <IconButton
              size="small"
              onClick={(e) => this.onDonePress(e, true)}
              disabled={this.props.disabled}
            >
              <CheckCircleIcon
                color={this.props.disabled ? "disabled" : "primary"}
                fontSize="large"
                className="miniCardDoneIcon"
              />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={(e) => this.onDonePress(e)}
              disabled={this.props.disabled}
            >
              <CheckCircleOutlineIcon
                color={"primary"}
                fontSize="large"
                className="miniCardDoneIcon"
              />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}
