import { Typography } from "@material-ui/core";
import React from "react";
import NoteAssessmentItem from "./noteAssessmentItem";
import ExportPDFButton from "../exportPDFButton";
import Attachment from "./attachment";

export default class AssessmentItem extends NoteAssessmentItem {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  renderPDFIcon = () => {
    return (
      <div className="exportIconContainer">
        <ExportPDFButton
          data={this.props.data}
          onExport={this.props.onExport}
        />
      </div>
    );
  };

  getSummaryText = () => {
    const { data } = this.props;
    if (data.summary) {
      return <b>{data.summary}</b>;
    }
  };

  renderAttachmentsList = () => {
    const { data } = this.props;
    if (data.attachments && data.attachments.length > 0) {
      return (
        <div className="attachmentListContainer">
          {data.attachments.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Attachment item={item} index={index} />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  renderItemContent = () => {
    return (
      <div className="assessmentSummaryAndTimeContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography className="assessmentSummaryText">
            {this.getSummaryText()}
          </Typography>
        </div>
      </div>
    );
  };

  getItemtitle = (data) => {
    if (data.summary) {
      return <b>{data.summary}</b>;
    }
  };

  renderItemContentContainer = () => {
    return (
      <div className="noteItemInnerContainer" onClick={this.clickEdit}>
        {this.renderPDFIcon()}
        {this.renderItemContent()}
        {this.renderAttachmentsList()}
      </div>
    );
  };
}
