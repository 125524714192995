import React from "react";
import { CircularProgress } from "@material-ui/core";
const FEEDBACK_KEY_MAP = {
  energized: "Energy Level",
  intensity: "Intensity Level",
  length: "Workout Length",
  proudness: "Proudness",
};
class CalendarDetailCard extends React.Component {
  state = {
    feedbackGiven: false,
  };

  onWorkoutClick = () => {
    this.props.onClick(this.props.data);
  };

  componentDidMount() {
    if (
      !this.props.data.data ||
      (this.props.data.data && !this.props.data.data.loggedData)
    ) {
      return;
    }

    if (
      this.props.data.data.loggedData.feedback &&
      Object.keys(this.props.data.data.loggedData.feedback).length
    ) {
      this.setState({
        feedbackGiven: true,
      });
    }
  }

  getFeedback() {
    return this.props.data.data.loggedData.feedback;
  }

  renderFeedback() {
    let data = this.props.data.data.loggedData.feedback;
    let keys = Object.keys(data);
    return (
      <>
        <div className="feedbackTitle">Feedback</div>
        {keys.map((key) => {
          return (
            <div key={key}>{`${FEEDBACK_KEY_MAP[key]}: ${data[key]}`}</div>
          );
        })}
      </>
    );
  }

  render() {
    if (this.props.data && this.props.data.loading) {
      return (
        <div>
          <CircularProgress size={12} color="primary" />
        </div>
      );
    }

    if (this.props.data && this.props.data.rest) {
      return (
        <div className="workoutLogCalendarCard">
          <div className="heading">Rest Day</div>
        </div>
      );
    }
    return (
      <div className="workoutLogCalendarCard" onClick={this.onWorkoutClick}>
        <div className="heading">{this.props.title}</div>
        <div className="feedback">
          {this.state.feedbackGiven && this.renderFeedback()}
        </div>
      </div>
    );
  }
}

export default CalendarDetailCard;
