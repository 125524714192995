import React, {Component} from 'react';
import {
    BubbleLoader
  } from 'react-css-loaders';
import WorkoutCard from '../workoutSummary/workoutExerciseCard'

const cardStyle = {
    margin: 10,
    minHeight: 500,
    width: 300
}

export default class ProgramWorkoutList extends Component {

    renderList() {
        return (
            <React.Fragment>
                {this.props.workoutList.exercises.map((exercise, index) => {
                    return (
                        <div key={index} className="programWorkoutListItem"><WorkoutCard videoWidth={300} videoHeight={300} cardStyle={cardStyle} data={exercise} /></div>
                    )
                })}
            </React.Fragment>
        )
    }

    renderListPlaceholder() {
        if(!this.props.selectedProgram) {
            return (
                <div className="workoutListPlaceholder">Select a program to see its workouts here.</div>
            )
        }

        if(this.props.selectedProgram && this.props.selectedDay < 1) {
            return (
                <div className="workoutListPlaceholder">Now select a day to see its workouts.</div>
            )
        }

        if(this.props.workoutListLoading) {
            return (
                <div className="workoutListPlaceholder"><BubbleLoader color={"#800520"} size={4} /></div>
            )
        }

        return (
            <div className="workoutListPlaceholder">Selected day has no exercise!</div>
        )
    }
    render() {
        return (
            <div className="programDetailWorkoutContainer">
                <div className="programDetailHeader">{`Workout Details ${this.props.workoutList && this.props.workoutList.name ? `(${this.props.workoutList.name})` : ''}`}</div>
                    <div className="programDetailWorkoutList">
                        {this.props.workoutList && this.props.workoutList.exercises && this.props.workoutList.exercises.length ? this.renderList() : this.renderListPlaceholder()}
                    </div>
            </div>
        )
    }
}