import React from "react";

import ClientCard from "./clientCard";

import ClientImage from "../../assets/clientImg.jpg";

import InfiniteList from "../../components/infiniteList/list.js";

export default class ClientList extends InfiniteList {
  loadNextPage() {
    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  rowRenderer({ key, index, isScrolling, style }) {
    let data = this.props.data[index];
    if (!this.props.selectedClient) {
      return (
        <div className="customListing" style={style} key={key}>
          <ClientCard
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
            data={data}
            clientImage={ClientImage}
            isEnterpriseMember={this.props.isEnterpriseMember}
            role={this.props.role || ""}
          />
        </div>
      );
    } else {
      return (
        <div
          className="customListing"
          id={index === 0 ? "clientRow" : ""}
          style={style}
          key={key}
        >
          <ClientCard
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
            selected={this.props.selectedClient.id === data.id}
            data={data}
            clientImage={ClientImage}
            isEnterpriseMember={this.props.isEnterpriseMember}
            role={this.props.role || ""}
          />
        </div>
      );
    }
  }
}
