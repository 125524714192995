import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import PDFThumbnail from "../../../components/PDFThumbnail/PDFThumbnail";
import Typography from "@material-ui/core/Typography";

export default class EducationCard extends Component {
  renderContent = () => {
    return (
      <React.Fragment>
        <div className="educationCardMainText">
          {this.props.data.name}{" "}
          {this.props.data.shortCode && (
            <span className="educationCardSecText">
              ({this.props.data.shortCode})
            </span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            color: "grey",
            fontSize: 11,
            marginTop: 4,
            flex: 1,
          }}
        >
          {this.props.data.type === "education" ? "Education" : "Certificate"}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {this.props.data.link &&
            (window.isPDF(this.props.data.link) ? (
              <PDFThumbnail
                link={this.props.data.link}
                height={150}
                className="fortisformaPDF"
              />
            ) : (
              <div className="certificateImgContainer">
                <img
                  alt={this.props.data.name}
                  src={this.props.data.link}
                  className="certificateImg"
                />
              </div>
            ))}
          {this.props.data.reference && (
            <Typography
              className="educationReferenceLink"
              variant="body2"
              color="secondary"
            >
              {this.props.data.reference}
            </Typography>
          )}
        </div>
        <div className="certificationEdit">
          <IconButton size="small">
            <EditIcon color="primary" fontSize="small" />
          </IconButton>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Card
        className="educationCard"
        variant="outlined"
        onClick={() =>
          this.props.onEducationEditClick(this.props.data, this.props.index)
        }
      >
        <div className="educationCancel">
          <IconButton
            size="small"
            onClick={(e) => {
              this.props.removeEducation(
                this.props.index,
                `${this.props.data.type}s`
              );
              e.stopPropagation();
            }}
          >
            <CancelIcon color="primary" />
          </IconButton>
        </div>
        <div className="educationCardContent">{this.renderContent()}</div>
      </Card>
    );
  }
}
