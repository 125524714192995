import Dashboard from "../views/dashboard/";
import WorkoutDays from "../views/dashboard/workoutdays/workoutdays";
import Exercises from "../views/exercises/exerciseEditor";
import TrainerExerciseEditor from "../views/exercises/trainerExerciseEditor";
import Profile from "../views/profile/profile";
import WorkoutDesigner from "../views/workoutdesigner/workoutdesigner.js";
import WorkoutAssigner from "../views/workoutAssigner/workoutAssigner";
import ClientEditor from "../views/clientEditor/clientEditor";
import PendingClientEditor from "../views/clientEditor/pendingClientEditor";
import PendingRequestEditor from "../views/clientEditor/pendingRequestEditor";
import MovementCategoryEditor from "../views/movementCategories/movementCategoryEditor";
import MovementEditor from "../views/movements/movementEditor";
import EquipmentEditor from "../views/equipments/equipmentEditor";
import MuscleEditor from "../views/muscles/muscleEditorAdvanced";
import MuscleGroups from "../views/muscles/muscleGroup";
import FunctionEditor from "../views/functions/functionEditor";
import FunctionGroups from "../views/functions/functionCategory";
import WorkoutSummary from "../views/workoutSummary/workoutSummary";
import Welcome from "../views/signup/welcome";
import PendingApprovals from "../views/dashboard/pendingApprovals/pendingApprovals";
import ManageTrainers from "../views/manageTrainers/manageTrainers";
import ManagePublicTrainers from "../views/managePublicTrainers/managePublicTrainers";
import ForgotPassword from "../views/forgotPassword/forgotPassword";
import AccountAction from "../views/accountAction/accountAction";
import EmailVerification from "../views/emailVerification/emailVerification";
import ResendEmailVerification from "../views/emailVerification/resendVerificationLink";
import ProgramTemplate from "../views/programTemplate/programTemplate";
import PrivacyPolicy from "../views/privacypolicy";
import ClientEmailVerified from "../components/appComponent/clientEmailVerified";
import AdminSupport from "../views/adminSupport";
import Login from "../views/login/";
import Signup from "../views/signup/";
import RedirectPage from "../components/staticPage/redirectPage";
import ChallengesManager from "../views/Challenges/Manager";
import InvalidLogin from "./../views/invalidLogin/InvalidLogin";
import EnterpriseAccounts from "./../views/enterpriseAccounts/enterpriseAccounts";
import AllBlogs from "../components/blogs/allBlogs";
import { ROLES, TIER } from "../constants";
import OnBoarding from "../views/trainerOnboarding/onBoarding";
import EnterpriseInvite from "./../views/enterpriseInvites/enterpriseInvite";
import FacilityInfo from "./../views/facilityInfo/facilityInfo";
import StaffEditor from "./../views/staffEditor/staffEditor";
import StripeListing from "./../views/stripe/stripe";
import ClientManagement from "./../views/clientManagement/clientManagement";
import Documents from "./../views/documents/documents";
import ClientDetails from "../views/enterpriseClientDetails/clientDetails";
import LoginAuthentication from "../views/loginAuthentication/loginAuthentication";
import WorkoutSession from "../views/workoutSession/workoutSession";
import FormExport from "../views/formExport/formExport";
import OurPricing from "../views/ourPricing/ourPricing";
import StripeSuccess from "../views/stripeSuccess/stripeSuccess";
import StripeCancel from "../views/stripeCancel/stripeCancel";
import Subscriptions from "../views/subscriptions/subscriptions";
import PurchaseHistory from "../views/purchaseHistory/purchaseHistory";
import DeleteAccount from "../views/deleteAccount/deleteAccount";
import ChatSystem from "../components/chatRoom/ChatSystem";

function routes(isLoggedIn, user) {
  return [
    {
      path: "/redirect-app",
      name: "Redirect Info Page",
      component: RedirectPage,
      hideHeader: true,
    },
    {
      path: "/account-action/action",
      name: "AccountAction",
      component: AccountAction,
      hideHeader: true,
    },
    {
      path: "/client-email-verified/",
      name: "Client Email Verified",
      component: ClientEmailVerified,
      hideHeader: true,
    },
    {
      path: "/forgot-password/action",
      name: "Forgot Password",
      component: ForgotPassword,
      hideHeader: true,
    },
    {
      path: "/verify-email/action",
      name: "Email Verification",
      component: EmailVerification,
      hideHeader: true,
    },
    {
      path: "/resend-email/",
      name: "Resend Email Verification",
      component: ResendEmailVerification,
      hideHeader: true,
      redirect: !user,
      pathTo: "/login",
    },
    {
      path: "/privacy-policy",
      name: "Privacy Policy",
      hideHeader: true,
      component: PrivacyPolicy,
    },
    {
      path: "/enterpriseInvite",
      name: "Enterprise Invite",
      component: EnterpriseInvite,
      hideHeader: true,
    },
    {
      path: "/form-export",
      name: "Form Export",
      component: FormExport,
      hideHeader: true,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      hideHeader: true,
      redirect: user && user.role !== ROLES.ADMIN && !user.emailVerified,
      pathTo: "/resend-email",
    },
    {
      path: "/login-authentication",
      name: "Login Authentication",
      component: LoginAuthentication,
      hideHeader: true,
      redirect: !user,
      pathTo: "/login",
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
      hideHeader: true,
    },
    {
      path: "/welcome",
      name: "Welcome",
      component: Welcome,
      hideHeader: true,
    },
    {
      path: "/invalid-login",
      name: "Invalid Login",
      component: InvalidLogin,
      hideHeader: true,
    },
    {
      redirect: isLoggedIn === false,
      path: "*",
      pathTo: "/login",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      hideHeader: true,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/delete-account",
      name: "Delete Account",
      hideHeader: true,
      redirect: !user || user.role === ROLES.ADMIN,
      component: DeleteAccount,
    },
    {
      path: "/exercises",
      name: "Exercises",
      component:
        user &&
        (user.role === ROLES.TRAINER ||
          user.enterpriseId ||
          user.role === ROLES.PRACTITIONER)
          ? TrainerExerciseEditor
          : Exercises,
      redirect:
        !user ||
        (user.role !== ROLES.TRAINER &&
          user.role !== ROLES.PRACTITIONER &&
          user.role !== ROLES.ADMIN &&
          !user.enterpriseId),
      pathTo: "/dashboard",
    },
    {
      path: "/movement-categories",
      name: "Movement Category Editor",
      component: MovementCategoryEditor,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/movements",
      name: "Movements",
      component: MovementEditor,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/upgrade",
      name: "Upgrade",
      component: OurPricing,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.HEALTH_COACH ||
        user.role === ROLES.FACILITY_ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/subscriptions",
      name: "Subscriptions",
      component: Subscriptions,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.HEALTH_COACH ||
        user.role === ROLES.FACILITY_ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/purchase-history",
      name: "Purchase History",
      component: PurchaseHistory,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.HEALTH_COACH ||
        user.role === ROLES.FACILITY_ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/stripe/success",
      name: "Stripe Success",
      component: StripeSuccess,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.HEALTH_COACH ||
        user.role === ROLES.FACILITY_ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/stripe/cancel",
      name: "Stripe Cancel",
      component: StripeCancel,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.HEALTH_COACH ||
        user.role === ROLES.FACILITY_ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/equipments",
      name: "Equipment Editor",
      component: EquipmentEditor,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/muscles",
      name: "Muscles",
      component: MuscleEditor,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/muscle-groups",
      name: "Muscle Groups",
      component: MuscleGroups,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/functions",
      name: "Functions",
      component: FunctionEditor,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/function-categories",
      name: "Function Categories",
      component: FunctionGroups,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-days",
      name: "Workout Days",
      component: WorkoutDays,
      redirect: !user || user.role === ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/challenges-manager",
      name: "ChallengesManager",
      component: ChallengesManager,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/programs",
      name: "Programs",
      component: ProgramTemplate,
      redirect: !user || user.role === ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-designer/:mode",
      name: "Workout Designer",
      component: WorkoutDesigner,
      redirect: !user,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-designer",
      name: "Workout Designer",
      component: WorkoutDesigner,
      redirect: !user,
      pathTo: "/dashboard",
    },
    {
      path: "/client-editor",
      name: "Client Editor",
      component: ClientEditor,
      redirect: !user || user.role === ROLES.ADMIN || user.enterpriseId,
      pathTo: "/dashboard",
    },
    {
      path: "/message-center",
      name: "Messages",
      component: ChatSystem,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        (user.role === ROLES.TRAINER && user.tier === TIER.FREE),
      pathTo: "/dashboard",
    },
    {
      path: "/pending-clients",
      name: "Pending Clients",
      component: PendingClientEditor,
      redirect: !user || user.role === ROLES.ADMIN || user.enterpriseId,
      pathTo: "/dashboard",
    },
    {
      path: "/pending-requests",
      name: "Pending Requests",
      component: PendingRequestEditor,
      redirect: !user || user.role === ROLES.ADMIN || user.enterpriseId,
      pathTo: "/dashboard",
    },
    {
      path: "/pending-approvals",
      name: "Pending Approvals",
      component: PendingApprovals,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/blogs",
      name: "Blogs",
      component: AllBlogs,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/manage-trainers",
      name: "Manage Trainers",
      component: ManageTrainers,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/manage-public-trainers",
      name: "Manage Public Trainers",
      component: ManagePublicTrainers,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-assigner",
      name: "Workout Assigner",
      component: WorkoutAssigner,
      redirect: !user || user.enterpriseId || user.tier === TIER.PRIVATE,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-assigner/:mode",
      name: "Workout Assigner",
      component: WorkoutAssigner,
      redirect: !user,
      pathTo: "/dashboard",
    },
    {
      path: "/workout-summary",
      name: "Workout Summary",
      component: WorkoutSummary,
      redirect:
        !user ||
        user.role === ROLES.ADMIN ||
        user.enterpriseId ||
        user.role === ROLES.PRACTITIONER,
      pathTo: "/dashboard",
    },
    {
      path: "/onboarding",
      name: "Onboarding",
      component: OnBoarding,
      redirect: !user || user.role === ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/admin-support-workout-assigner",
      name: "Workout Assigner",
      component: WorkoutAssigner,
      redirect: !user || user.role === ROLES.TRAINER,
      pathTo: "/dashboard",
    },
    {
      path: "/admin-support-workout-designer/:mode",
      name: "Workout Designer",
      component: WorkoutDesigner,
      redirect: !user || user.role === ROLES.TRAINER,
      pathTo: "/dashboard",
    },
    {
      path: "/admin-support-workout-summary",
      name: "Workout Summary",
      component: WorkoutSummary,
      redirect: !user || user.role === ROLES.TRAINER,
      pathTo: "/dashboard",
    },
    {
      path: "/admin-support",
      name: "Admin Support",
      component: AdminSupport,
      redirect: !user || user.role === ROLES.TRAINER,
      pathTo: "/dashboard",
    },
    {
      path: "/enterprise-accounts",
      name: "Enterprise Accounts",
      component: EnterpriseAccounts,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/facility-info",
      name: "Facility Info",
      component: FacilityInfo,
      redirect:
        !user ||
        !(
          user.role === ROLES.ENTERPRISE_ADMIN ||
          user.role === ROLES.FACILITY_ADMIN
        ),
      pathTo: "/dashboard",
    },
    {
      path: "/staff-editor",
      name: "Staff",
      component: StaffEditor,
      redirect:
        !user ||
        !(
          user.role === ROLES.ENTERPRISE_ADMIN ||
          user.role === ROLES.FACILITY_ADMIN
        ),
      pathTo: "/dashboard",
    },
    {
      path: "/stripe-card-listing",
      name: "Stripe",
      component: StripeListing,
      redirect: !user,
      pathTo: "/dashboard",
    },
    {
      path: "/documents",
      name: "Documents",
      component: Documents,
      redirect: !user || user.role === ROLES.ADMIN || user.tier === TIER.FREE,
      pathTo: "/dashboard",
    },
    {
      path: "/client-management",
      name: "Client Management",
      component: ClientManagement,
      redirect:
        !user || !user.enterpriseId || !user.role === ROLES.PRACTITIONER,
      pathTo: "/dashboard",
    },
    {
      path: "/client-details",
      name: "Client Details",
      component: ClientDetails,
      redirect: !user,
      // ||
      // !(
      //   user.enterpriseId ||
      //   user.role === ROLES.PRACTITIONER ||
      //   (user.role === ROLES.TRAINER && user.privatePractice)
      // ),
      pathTo: "/dashboard",
    },
    {
      path: "/forms-management",
      name: "Forms Management",
      component: Documents,
      redirect: !user || user.role !== ROLES.ADMIN,
      pathTo: "/dashboard",
    },
    {
      path: "/client-workout",
      name: "Client Workout",
      component: WorkoutSession,
      redirect: !user,
      pathTo: "/dashboard",
    },
    {
      redirect: isLoggedIn,
      path: "*",
      pathTo: "/dashboard",
    },
  ];
}

export default routes;
