const DB_KEYS = {
  USER_PROFILE_KEY: "userProfile",
  PP_ENT: "ppent",
  TRAINER_ID_KEY: "trainerId",
  GROUP_ID_KEY: "groupId",
  CREATOR_ID_KEY: "creatorId",
  PROGRAM_ID_KEY: "programId",
  CREATED_TIME: "createdTime",
  CLIENT_ID_KEY: "id",
  LAST_LOGIN_KEY: "lastLoginTime",
  PENDING_LOGIN_KEY: "pendingLogin",
  REQUEST_STORAGE_KEY: "requests",
  SIGN_UP_STATE: "signupState",
  USER_TOKEN: "token",
  ASSIGN_DATE: "assignDate",
  START_DATES: "startDates",
  END_DATE: "endDate",
  CLIENT_ID: "clientId",
  USER_STATE: "state",
  ROLE: "role",
  COUNTERS: "counters",
  EMAIL_VERIFIED: "emailVerified",
  ACTIVE_STATUS: "active",
  WALKTHROUGH: "walkthrough",
  WORKOUT_TYPE: "type",
  VISIBLE: "visible",
  MOVEMENT_CATEGORY: "movementCategory",
  MOVEMENT_ID: "movementId",
  MOVEMENT: "movement",
  ADMIN: "admin",
  USER_ID: "userId",
  INCOMPLETE: "incomplete",
  PUBLIC: "public",
  CHALLENGE_ID: "challengeId",
  LAST_USER_EMAIL: "lastUserEmail",
  ENTERPRISE_ID: "enterpriseId",
  ENTERPRISE_FORM: "enterpriseForm",
  GLOBAL: "global",
  ID_KEY: "id",
  OLD: "old",
  CLIENT_SPECIFIC: "clientSpecific",
  FORM_STEP: "step",
  NOTE_ID: "noteId",
  ASSESSMENT_ID: "assessmentId",
};

const USER_STATES = {
  INVALID: 0,
  PENDING: 1,
  APPROVED: 2,
  BLOCKED: 3,
  INVALID_ENTERPRISE_MEMBER: 4,
};

const ACTION_STATES = {
  PENDING: "PENDING",
  IN_PROCESS: "IN_PROCESS",
  FAILED: "FAILED",
  COMPLETE: "COMPLETE",
};

const ERROR_CODES = {
  INVALID_EMAIL: "auth/invalid-email",
  USER_DISABLED: "auth/user-disabled",
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASS: "auth/wrong-password",
  WEAK_PASSWORD: "auth/weak-password",
  DENIED: "auth/email-already-in-use",
  TOO_MANY_ATTEMPTS: "auth/too-many-requests",
  MISSING_INFO: "Missing Information",
  PERMISSION_DENIED: "permission-denied",
  MFA_VERIFIED: "account-already-verified",
};

const LOCAL_STORAGE_KEYS = {
  WORKOUT_ASSIGNMENT_DETAILS: "assignmentDetails",
  WORKOUT_DESIGNER_RESULT: "savedWorkout",
  WORKOUT_DESIGNER_STATE: "savedWorkoutState",
  UNDO_WORKOUT_SECTIONS: "workoutSectionHistory",
  WORKOUT_LEVEL_DATA: "workoutLevelData",
  WORKOUT_GUIDE_COMPLETE: "workoutGuideComplete",
  FILTER_TOOLTIP_SHOWN: "filterTooltipShown",
  MUSCLE_GROUPS: "muscleGroups",
  EQUIPMENT_CATEGORIES: "equipmentCategories",
  FUNCTION_CATEGORIES: "functionCategories",
  WORKOUT_SUMMARY_DETAILS: "workoutSummaryDetails",
  VERIFICATION_PENDING: "verificationPending",
  SELECTED_USER: "selectedUser",
  WORKOUT_DAY_STATE: "workoutDayState",
  CLIENT_INFO_DETAILS: "clientInfoDetails",
  WORKOUT_SESSION_DETAILS: "workoutSessionDetails",
  TRIALMODAL: "trialModal",
};

const ROLES = {
  ADMIN: "Admin",
  TRAINER: "Trainer",
  CLIENT: "Client",
  PRACTITIONER: "Practitioner",
  ENTERPRISE_ADMIN: "Enterprise_Admin",
  FACILITY_ADMIN: "Facility_Admin",
  HEALTH_COACH: "Health_Coach",
};

const TIER = {
  FREE: "Coaching Solution",
  PRIVATE: "Private Practice Solution",
  ENTERPRISE: "Enterprise Solution",
};

const INTERVAL = {
  FREE: "free",
  MONTH: "month",
  YEAR: "year",
};

const ROLES_LABEL_MAP = {
  Enterprise_Admin: "Enterprise Admin",
  Facility_Admin: "Facility Admin",
  Health_Coach: "Health Coach",
};

const COLLECTIONS = {
  EXERCISES: "exercises",
  WORKOUT_PROGRAM: "programs",
  WORKOUT_DAYS: "workoutDays",
  DRAFT_WORKOUT_DAYS: "draftWorkoutDays",
  USER_DATA: "users",
  EQUIPMENTS: "equipments",
  EQUIPMENT_CATEGORIES: "equipmentCategories",
  MOVEMENT_CATEGORIES: "movementCategories",
  MOVEMENTS: "movements",
  MUSCLES: "muscles",
  MUSCLE_GROUPS: "musclegroups",
  FUNCTIONS: "functions",
  FUNCTION_CATEGORIES: "functionCategories",
  WORKOUT_SUMMARY: "workoutSummary",
  ANALYTICS: "analytics",
  PROGRAM_HISTORY: "programHistory",
  INVITES: "invites",
  MESSAGE: "messages",
  FEEDBACK: "feedback",
  REQUESTS: "requests",
  TRAINERS: "trainers",
  ENTERPRISE: "enterprises",
  ENTERPRISE_INVITES: "enterpriseInvites",
  FORMS: "forms",
  NOTE_ARCHIVES: "noteArchives",
  SUBMISSIONS: "submissions",
  NOTES: "notes",
  ASSESSMENTS: "assessments",
  SUBMISSION_VERSIONS: "submissionVersions",
  ASSESSMENT_ARCHIVES: "assessmentArchives",
  FORM_IMAGES: "formImages",
  BLOGS: "blogs",
};

const STORAGE_PATHS = {
  FORM_IMAGES: "formImages/",
};

const EQUIPMENT_TYPES = {
  FREEWEIGHT: "freeweight",
  RESISTANCE: "resistance",
  SUPPORT: "support",
};

const RESISTANCE_LEVELS = {
  EXTRA_LIGHT: "EXTRA_LIGHT",
  LIGHT: "LIGHT",
  MEDIUM: "MEDIUM",
  HEAVY: "HEAVY",
  EXTRA_HEAVY: "EXTRA_HEAVY",
};

const RESET_PASSWORD_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  VALID_CODE: "valid",
  RESET_COMPLETE: "complete",
};

const EMAIL_VERIFICATION_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  EMAIL_VERIFIED: "complete",
};

const WELCOME_STATES = {
  LOADING: "loading",
  EMAIL_SENDING_FAILED: "invalid",
  EMAIL_SENT: "complete",
};

const ACCOUNT_ACTIONS = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};

const WALKTHROUGH = {
  DASHBOARD: "dashboard",
  DESIGNER: "designer",
  DESIGNER_EXERCISE: "designerExercise",
  CLIENT_EDITOR: "clientEditor",
  CLIENT_DETAIL: "clientDetail",
  WORKOUT_ASSIGNER: "workoutAssigner",
  WORKOUT_SUMMARY: "workoutSummary",
  WORKOUT_ASSIGNER_CARD: "workoutAssignerCard",
  SHARE_WORKOUT: "shareWorkout",
  MESSAGE_CENTER: "messageCenter",
};

const INITIALIZE_WALKTHROUGH = {
  dashboard: true,
  designer: true,
  designerExercise: true,
  clientEditor: true,
  clientDetail: true,
  workoutAssigner: true,
  workoutAssignerCard: true,
  workoutSummary: true,
  shareWorkout: true,
  messageCenter: true,
};

const getWalkthrough = (tier) => {
  console.log(
    "*********************  getWalkthrough ************************ "
  );
  console.log("input data", tier);
  let walkthrough = null;

  if (tier === TIER.ENTERPRISE || tier === TIER.PRIVATE) {
    walkthrough = {
      dashboard: true,
      designer: true,
      designerExercise: true,
      clientEditor: true,
      clientDetail: true,
      workoutAssigner: true,
      workoutAssignerCard: true,
      workoutSummary: true,
      shareWorkout: true,
      messageCenter: true,
    };
  } else if (tier === TIER.FREE) {
    walkthrough = {
      dashboard: false,
      designer: false,
      designerExercise: false,
      clientEditor: false,
      clientDetail: true,
      workoutAssigner: false,
      workoutAssignerCard: true,
      workoutSummary: true,
      shareWorkout: false,
      messageCenter: false,
    };
  }
  console.log("walkthrough return ====", walkthrough);
  return walkthrough;
};

const WORKOUT_TYPE = {
  INVALID: 0,
  CLIENT_WORKOUT: 1,
  TRAINER: 2,
  ALL: 3,
  CHALLENGE_WORKOUT: 4,
  DRAFT: 5,
};

const SUPPORTING_ENTITIES = {
  EQUIPMENT: "equipment",
  MUSCLE: "muscle",
  FUNCTION: "function",
  MOVEMENT: "movement",
};

const CONTINUE_ROUTES = {
  DASHBOARD: "login",
  APP: "client-email-verified",
};

const WEB_APP_URL = [
  "https://appstaging.fortisforma.com/",
  "https://app.fortisforma.com/",
];

const FILE_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
};

const ANALYTIC_EVENTS = {
  LOGIN: "login",
  SIGNUP: "sign_up",
  NEW_CLIENT: "add_new_client",
  NEW_PROGRAM: "add_new_program",
  PROGRAM_ASSIGN: "program_assign",
};

const WORKOUT_SECTIONS_NAMES = {
  WARMUP: "Warmup",
  CIRCUIT: "Circuit",
  COOLDOWN: "Cooldown",
};

const LEVEL_UPDATE_TYPES = {
  ADD: "add",
  DELETE: "delete",
};

const CONTINUE_URL = process.env.REACT_APP_HOST_URL + "#/login";

const EDUCATION_TYPE_MAP = {
  1: "High School",
  2: "Graduation",
  3: "Post Graduation",
};

const PROGRAM_TYPES = {
  CHALLENGE: "challenge",
  PROGRAM: "program",
};

const WORKOUT_SHARE_URL = process.env.REACT_APP_SHARE_LINK;

const INVALID_LOGIN_REDIRECT_URL =
  process.env.NODE_ENV === "development"
    ? "https://appstaging.fortisforma.com/#/login"
    : "https://app.fortisforma.com/#/login";

const INPUT_FIELD_KEYS = {
  TIME: "time",
};

const ENTERPRISE_CARD_MODE = {
  CREATE: "create",
  EDIT: "edit",
};

const COLLECTION_TYPES = {
  INVALID: 0,
  USER_DATA: 1,
  ENTERPRISE_INVITES: 2,
};

const FORM_TYPES = {
  REGISTRATION: 0,
  ASSESSMENTS: 1,
  GENERAL_INFO: 2,
  MISC: 3,
  NOTES: 4,
};

const CLIENT_DETAIL_TABS = {
  FORMS: 0,
  NOTES: 1,
  ASSESSMENT: 2,
  WORKOUTS: 3,
  WORKOUT_LOGS: 4,
  MESSAGE_CENTER: 5,
};

const ARROW_ACTIONS = {
  UP: "up",
  DOWN: "down",
};

const FORM_TYPES_LABEL = {
  REGISTRATION: "Registration Forms",
  ASSESSMENTS: "Assessment Forms",
  GENERAL_INFO: "General Info Forms",
  MISC: "Miscellaneous Forms",
  NOTES: "Notes",
};

const PDF_TEMPLATES = {
  WORKOUT: "workout",
  NOTE: "note",
  NOTE_VERSIONS: "noteVersions",
  ASSESSMENT: "assessment",
  ASSESSMENT_VERSIONS: "assessmentVersions",
  FORM: "form",
};

const BULK_UPLOAD_ERRORS = {
  NAME: "Invalid Name",
  EMAIL_FORMAT: "Invalid Email",
  EMAIL_EXISTS: "Skipping since the user already exists",
};

const WORKOUT_SESSION_ROUTE = "/client-workout";

const CLIENT_FORMS_PORTAL_LINK = process.env.REACT_APP_CLIENT_FORMS_PORTAL_LINK;

const EXERCISE_CONFIG_UNITS = {
  WEIGHT: "lbs",
  TIME: "sec",
};

const CONFIG_TYPES = {
  SETS: "Sets",
  TIME: "Time",
  RESISTANCE: "Resistance",
  REPS: "Reps",
  WEIGHT: "Weight",
};

const RESISTANCE_DROPDOWN_DATA = [
  {
    value: "EXTRA_LIGHT",
    label: "Extra Light",
  },
  {
    value: "LIGHT",
    label: "Light",
  },
  {
    value: "MEDIUM",
    label: "Medium",
  },
  {
    value: "HEAVY",
    label: "Heavy",
  },
  {
    value: "EXTRA_HEAVY",
    label: "Extra Heavy",
  },
];

const NOTE_TYPE = {
  CLINICAL_NOTE: "Clinical",
  WORKOUT_NOTE: "Workout",
};

const PDF_DATE_FORMAT = "YYYY/MM/DD hh:mm a";

const SHARE_WORKOUT_OPTIONS = {
  VIA_LINK: "Share via Link",
  VIA_EMAIL: "Share via Email",
  DOWNLOAD_PDF: "Download PDF",
};

const UPLOADED_IMAGE_TYPE = {
  IMAGE_EDITOR: 0,
  IMAGE_SELECTOR: 1,
};

const FORM_WIDGET_CONFIRMATION_TEXT = {
  UNSAVED_DATA: "We found unsaved data, are you sure you want to close?",
  CHANGE_IMAGE:
    "You will lose all unsaved marked data. Are you sure you want to proceed?",
};
const MONTHS = [
  {
    num: 1,
    str: "January",
  },
  { num: 2, str: "February" },
  { num: 3, str: "March" },
  { num: 4, str: "April" },
  { num: 5, str: "May" },
  { num: 6, str: "June" },
  { num: 7, str: "July" },
  { num: 8, str: "August" },
  { num: 9, str: "September" },
  { num: 10, str: "October" },
  { num: 11, str: "November" },
  { num: 12, str: "December" },
];

const FORM_VERSION_EXPORT_LINK = process.env.REACT_APP_FORM_VERSION_EXPORT_LINK;

const GET_ENT_ID = (data) => {
  let enterpriseId;
  if (data.user.role === ROLES.TRAINER && data.user.tier !== TIER.FREE) {
    enterpriseId = data.ppEnterprise && data.ppEnterprise.id;
  } else {
    enterpriseId = data.user.enterpriseId;
  }
  return enterpriseId;
};

const GET_USER_ID = (data) => {
  let userId;
  if (
    data.user.role === ROLES.HEALTH_COACH ||
    data.user.role === ROLES.FACILITY_ADMIN
  ) {
    userId = data.ppEnterprise && data.ppEnterprise.id;
  } else {
    userId = data.user.id;
  }
  return userId;
};

module.exports = {
  ROLES_LABEL_MAP,
  ENTERPRISE_CARD_MODE,
  INPUT_FIELD_KEYS,
  INVALID_LOGIN_REDIRECT_URL,
  DB_KEYS: DB_KEYS,
  ERROR_CODES: ERROR_CODES,
  LOCAL_STORAGE_KEYS: LOCAL_STORAGE_KEYS,
  ROLES: ROLES,
  COLLECTIONS: COLLECTIONS,
  EQUIPMENT_TYPES: EQUIPMENT_TYPES,
  RESISTANCE_LEVELS: RESISTANCE_LEVELS,
  ACTION_STATES: ACTION_STATES,
  USER_STATES: USER_STATES,
  RESET_PASSWORD_STATES: RESET_PASSWORD_STATES,
  EMAIL_VERIFICATION_STATES: EMAIL_VERIFICATION_STATES,
  WELCOME_STATES: WELCOME_STATES,
  ACCOUNT_ACTIONS,
  WALKTHROUGH,
  INITIALIZE_WALKTHROUGH,
  WORKOUT_TYPE,
  CONTINUE_ROUTES,
  CONTINUE_URL,
  FILE_TYPE,
  SUPPORTING_ENTITIES,
  ANALYTIC_EVENTS,
  WORKOUT_SECTIONS_NAMES,
  LEVEL_UPDATE_TYPES,
  WEB_APP_URL,
  EDUCATION_TYPE_MAP,
  WORKOUT_SHARE_URL,
  PROGRAM_TYPES,
  COLLECTION_TYPES,
  FORM_TYPES,
  CLIENT_DETAIL_TABS,
  ARROW_ACTIONS,
  FORM_TYPES_LABEL,
  DATE_FORMAT: "YYYY/MM/DD",
  CLIENT_FORMS_PORTAL_LINK,
  PDF_TEMPLATES,
  BULK_UPLOAD_ERRORS,
  WORKOUT_SESSION_ROUTE,
  RESET_DAY_INTERVAL: 3,
  EXERCISE_CONFIG_UNITS,
  CONFIG_TYPES,
  RESISTANCE_DROPDOWN_DATA,
  NOTE_TYPE,
  PDF_DATE_FORMAT,
  FORM_VERSION_EXPORT_LINK,
  SHARE_WORKOUT_OPTIONS,
  UPLOADED_IMAGE_TYPE,
  FORM_WIDGET_CONFIRMATION_TEXT,
  STORAGE_PATHS,
  TIER,
  INTERVAL,
  MONTHS,
  GET_ENT_ID,
  GET_USER_ID,
  getWalkthrough,
};
