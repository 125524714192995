import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { ERROR_CODES } from "../../constants";

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: (props.value && props.value.address) || "",
      streetNumber: "",
      streetName: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      errorMessage: "",
      latitude: null,
      longitude: null,
      isGeocoding: false,
      locationRawData: "",
      provinceCode: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.address) {
      return {
        address: (props.value && props.value.address) || "",
      };
    } else {
      return null;
    }
  }

  handleChange = (address) => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: "",
    });

    this.props.onChange &&
      this.props.onChange({
        address,
      });
  };

  getLatLng = (res) => {
    this.setState({
      locationRawData: res[0],
    });
    return getLatLng(res[0]);
  };

  handleSelect = (selected) => {
    this.setState({ isGeocoding: true, address: selected, status: "" });
    geocodeByAddress(selected)
      .then((res) => this.getLatLng(res))
      .then(({ lat, lng }) => {
        if (!lat || !lng) {
          throw ERROR_CODES.MISSING_INFO;
        }
        let streetNumber = "",
          streetName = "",
          city = "",
          province = "",
          country = "",
          postalCode = "";
        let provinceCode = "";
        let addressComponents = this.state.locationRawData.address_components;
        addressComponents.map((item) => {
          if (item.types.includes("street_number")) {
            streetNumber = item.long_name;
          } else if (item.types.includes("route")) {
            streetName = item.long_name;
          } else if (item.types.includes("locality")) {
            city = item.long_name;
          } else if (item.types.includes("administrative_area_level_1")) {
            province = item.long_name;
            provinceCode = item.short_name;
          } else if (item.types.includes("country")) {
            country = item.long_name;
          } else if (item.types.includes("postal_code")) {
            postalCode = item.long_name;
          }
          return null;
        });

        this.setState({
          latitude: lat,
          longitude: lng,
          streetNumber: streetNumber,
          streetName: streetName,
          city: city,
          province: province,
          country: country,
          postalCode: postalCode,
          isGeocoding: false,
          provinceCode: provinceCode,
        });
        this.props.onChange &&
          this.props.onChange(
            {
              address: selected,
              latitude: lat,
              longitude: lng,
              streetNumber: streetNumber,
              streetName: streetName,
              city: city,
              province: province,
              country: country,
              postalCode: postalCode,
              provinceCode: provinceCode,
            },
            {
              locationRawData: this.state.locationRawData,
            }
          );
        return true;
      })
      .catch((error) => {
        if (error === ERROR_CODES.MISSING_INFO) {
          window.NotificationUtils.showError("Something went wrong");
          this.setState({
            isGeocoding: false,
            address: "",
            status: "",
          });
        }
        this.setState({ isGeocoding: false });
        console.error("error", error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: "",
      latitude: null,
      longitude: null,
      errorMessage: "",
      locationRawData: "",
      status: "",
    });
    this.props.onChange && this.props.onChange(null);
  };

  handleError = (status, clearSuggestions) => {
    console.error("Error from Google Maps API", status); // eslint-disable-line no-console
    if (status === "ZERO_RESULTS") {
      status = "No Results Found";
    }
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  render() {
    const { address, errorMessage } = this.state;

    return (
      <div>
        <PlacesAutocomplete
          searchOptions={{
            componentRestrictions: {
              country: "ca",
            },
          }}
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            let inputProps = getInputProps({});
            return (
              <div className="CUSTOM__search-bar-container">
                <div className="CUSTOM__search-input-container">
                  <TextField
                    error={this.props.error}
                    className={this.props.className}
                    id="palceSearch"
                    label={(!this.props.hidelabel && "Address") || undefined}
                    variant="outlined"
                    fullWidth={true}
                    {...this.props}
                    value={this.state.eventName}
                    required
                    InputProps={{
                      ...inputProps,
                      endAdornment: this.state.address ? (
                        <InputAdornment tabIndex="-1" position="end">
                          <IconButton
                            size="small"
                            aria-label="search places"
                            onClick={this.handleCloseClick}
                          >
                            <CancelIcon size="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    onChange={this.onChangeEventName}
                  />
                </div>
                {suggestions.length > 0 && (
                  <div className="CUSTOM__autocomplete-container">
                    {suggestions.map((suggestion, index) => {
                      const className = clsx("CUSTOM__suggestion-item", {
                        "CUSTOM__suggestion-item--active": suggestion.active,
                      });

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                          key={suggestion + index}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{" "}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="CUSTOM__error-message">{this.state.errorMessage}</div>
        )}
      </div>
    );
  }
}
