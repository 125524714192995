import { Button, Dialog, Typography } from "@material-ui/core";
import React from "react";
import AssessmentEditor from "../../components/noteAssessments/noteAssessmentEditor/assessmentEditor";
import AssessmentsList from "../../components/noteAssessments/noteAssessmentList/assessmentList";
import {
  COLLECTIONS,
  FORM_TYPES,
  PDF_DATE_FORMAT,
  PDF_TEMPLATES,
  ROLES,
} from "../../constants";
import NoteAssessmentCommon, {
  getClientFormsdata,
  updateClientForms,
} from "./noteAssessmentCommon";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

export default class Assessments extends NoteAssessmentCommon {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  filterClientForms = (forms) => {
    return forms[FORM_TYPES.ASSESSMENTS] || [];
  };

  deletePendingForm = async (formId) => {
    const updatedData = getClientFormsdata(
      this.state.clientForms,
      this.props.clientId,
      FORM_TYPES.ASSESSMENTS,
      this.props.clientEmail
    );
    try {
      await Promise.all([
        window.FortisForma.database.updateClientFormsData(updatedData),
        window.FortisForma.database.removeForm(formId),
      ]);
    } catch (e) {
      throw e;
    }
  };

  onSave = async (assessmentData) => {
    try {
      let submissionData = { ...assessmentData.submissionData } || null;
      const result = await window.FortisForma.database.saveAssessment(
        assessmentData
      );
      if (assessmentData.formId && submissionData && submissionData.formData) {
        await updateClientForms(
          submissionData,
          result,
          this.state.clientForms,
          FORM_TYPES.ASSESSMENTS
        );
      }
      if (this.state.selectedItem && this.state.selectedItem.id) {
        this.onItemSaved(result, this.state.selectedItem);
      } else {
        this.onNewAssessmentSaved(result);
      }
      this.scrollToTop();
    } catch (e) {
      throw e;
    }
  };

  onNewAssessmentSaved = (result) => {
    let assessmentData = Object.assign([], this.state.data);
    if (!this.state.endDate || this.state.endDate >= window.moment()) {
      assessmentData.unshift(result);
    }
    this.setState(
      {
        data: assessmentData,
        selectedItem: {},
        lastFetched: assessmentData[assessmentData.length - 1],
      },
      this.fetchClientForms
    );
  };

  fetchData = async (loadMore = false) => {
    let assessmentsDataCopy = [];
    if (loadMore) {
      assessmentsDataCopy = Object.assign([], this.state.data);
      this.setState({ loadingMore: true });
    } else {
      this.setState({
        loading: true,
        lastFetched: null,
      });
    }
    try {
      let assessmentData = {
        clientId: this.props.clientId,
      };
      if (loadMore) {
        assessmentData.lastFetched = this.state.lastFetched.id;
      }
      let result = await window.FortisForma.database.fetchAssessments(
        assessmentData
      );
      if (result.length > 0) {
        let lastFetched = result[result.length - 1];
        assessmentsDataCopy = assessmentsDataCopy.concat(result);
        this.setState({
          data: assessmentsDataCopy,
          loading: false,
          loadingMore: false,
          lastFetched,
          disableLoadMore: result.length < 20,
        });
      } else {
        this.setState({
          disableLoadMore: true,
          loadingMore: false,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        loadingMore: false,
      });
      console.error(e);
    }
  };

  handleSavePDF = async (data, exportAllVersions = false) => {
    try {
      const archives = await this.fetchNoteAssessmentArchives(
        data,
        exportAllVersions,
        COLLECTIONS.ASSESSMENT_ARCHIVES
      );
      const pdfData = await this.getPDFData(archives, exportAllVersions);
      const documentName = `assessment_${window.getTimestampSeconds(
        data.updatedTime
      )}`;
      const templateId = exportAllVersions
        ? PDF_TEMPLATES.ASSESSMENT_VERSIONS
        : PDF_TEMPLATES.ASSESSMENT;
      const results = await window.FortisForma.database.getPDF(
        templateId,
        pdfData,
        documentName
      );
      if (results.saved) {
        window.NotificationUtils.showSuccess("Downloading PDF");
      } else {
        window.NotificationUtils.showError("Error fetching PDF");
      }
    } catch (e) {
      window.NotificationUtils.showError("Error fetching PDF");
      throw e;
    }
  };

  formatData = (assessmentsData, exportAllVersions = false) => {
    let data = {};
    data.summary = assessmentsData.summary && assessmentsData.summary;
    data.date = moment
      .unix(window.getTimestampSeconds(assessmentsData.updatedTime))
      .format(PDF_DATE_FORMAT);
    data.attachments = {};
    if (assessmentsData.attachments) {
      data.attachments.documents = assessmentsData.attachments.filter((p) =>
        window.isPDF(p.url) ? true : false
      );
      data.attachments.images = assessmentsData.attachments.filter((p) =>
        !window.isPDF(p.url) ? true : false
      );
    }
    if (assessmentsData.creator) {
      data.createdBy = assessmentsData.creator;
    }

    if (exportAllVersions && assessmentsData.formId) {
      data.form = this.getFormVersionURL(
        assessmentsData.formId,
        assessmentsData.submissionId,
        assessmentsData.creatorId
      );
    }

    return data;
  };

  getEditorTitle = () => {
    if (this.state.selectedItem && this.state.selectedItem.id) {
      return "Edit Assessment";
    }
    return "Add Assessment";
  };

  getSidePanelTitle = () => {
    return "Assessment Versions";
  };

  disableDropdown = () => {
    if (
      this.props.user.role === ROLES.FACILITY_ADMIN ||
      this.props.user.role === ROLES.HEALTH_COACH
    ) {
      if (this.props.user.enterprise.active) return false;
    } else {
      if (this.props.user.active) return false;
    }

    return true;
  };

  renderAddButton = () => {
    return (
      <>
        <Button
          className="addNoteAssessmentButton"
          color="primary"
          variant="contained"
          onClick={this.openEditor}
          startIcon={<AddIcon />}
          // disabled={this.disableDropdown()}
        >
          Add Assessment
        </Button>
      </>
    );
  };

  renderItemsList = () => {
    return (
      <AssessmentsList
        data={this.state.data}
        fetchData={this.fetchData}
        loader={this.renderLoading}
        loading={this.state.loading}
        onEdit={this.onClickEdit}
        onExport={this.handleSavePDF}
        onClickPreview={this.onClickPreview}
        clientForms={this.state.clientForms}
        renderEmptyPlaceholder={this.renderEmptyPlaceholder}
      />
    );
  };

  renderEmptyPlaceholder = () => {
    return (
      <Typography variant="body1" className="assessmentEmptyPlaceholder">
        There are no assessments available.
      </Typography>
    );
  };

  renderExtras = () => {
    return null;
  };

  renderEditor() {
    return (
      <>
        <Dialog
          id="noteEditorDialog"
          maxWidth="md"
          fullWidth={true}
          open={this.state.openEditor}
          onClose={this.onCloseEditor}
        >
          <AssessmentEditor
            ref={this.editorRef}
            onClose={this.onCloseEditor}
            onSave={this.onSave}
            selectedItem={this.state.selectedItem && this.state.selectedItem}
            enterpriseId={this.props.user.enterpriseId || ""}
            openSidePanel={this.openSidePanel}
            openFormsModal={this.openFormsModal}
            deletePendingForm={this.deletePendingForm}
            {...this.props}
          />
        </Dialog>
      </>
    );
  }
}
