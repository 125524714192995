import React from "react";
import Joyride from "react-joyride";
import { Col, Collapse, Row } from "reactstrap";
import WalkthroughEventDispatcher from "../../components/walkthroughEventDispatcher/walkthroughEventDispatcher";
import { WALKTHROUGH } from "../../constants";
import { WORKOUT_CARD_WALKTHROUGH } from "../../modules/walkThrough/walkThrough";
import WorkoutExerciseCard from "../workoutSummary/workoutExerciseCard";
import classNames from "classnames";
import SectionedWorkout from "../../components/sectionedWorkout/sectionedWorkout";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import ShareWorkoutButton, {
  sourcePropTypes,
} from "../../components/shareWorkoutButton/shareWorkoutButton";

export default class WorkoutCard extends WalkthroughEventDispatcher {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.isSelected,
      steps: WORKOUT_CARD_WALKTHROUGH.steps,
      anchorEl: null,
    };
    this.onSelect = this.onSelect.bind(this);
    this.assignToAll = this.assignToAll.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.globalWalkthrough = {};
    this.walkthroughKey = WALKTHROUGH.WORKOUT_ASSIGNER_CARD;
  }

  componentWillReceiveProps(nextProps) {
    this.showSelectedWorkout(nextProps);
  }

  showSelectedWorkout(props) {
    if (props.isSelected) {
      if (!this.state.collapse) {
        this.setState({ collapse: true });
      }
    } else {
      this.setState({ collapse: false });
    }
  }

  onSelect(e) {
    e && e.stopPropagation && e.stopPropagation();

    this.props.onSelect(this.props.data);
    this.handleMenuClose();
  }

  assignToAll(e) {
    e && e.stopPropagation && e.stopPropagation();
    this.props.assignToAll(this.props.data);
    this.handleMenuClose();
  }

  editWorkout = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    this.props.editWorkout(this.props.data);
    this.handleMenuClose();
  };

  toggleCollapse(e) {
    if (e && e.target) {
      let classList = e.target && e.target.classList;
      if (e.target.tagName === "VIDEO") {
        return;
      }
      if (classList.contains("react-player__preview")) {
        return;
      }
      if (classList.contains("videoReplayButton")) {
        return;
      }
      if (classList.contains("react-player__shadow")) {
        return;
      }
      if (classList.contains("videReplayIcon")) {
        return;
      }
      if (classList.contains("react-player__play-icon")) {
        return;
      }
    }
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  getExerciseCount() {
    if (this.props.data.exercises) {
      return this.props.data.exercises.length;
    }
    let count = 0;
    if (this.props.data.workoutSections) {
      for (let section of this.props.data.workoutSections) {
        if (section.exercises && section.exercises.length > 0) {
          for (let exercise of section.exercises) {
            if (exercise) {
              count++;
            }
          }
        }
      }
      return count;
    }
    return 0;
  }

  renderJoyride = () => {
    // Self explanatory
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          run={false}
          steps={this.state.steps}
          continuous={true}
          styles={{
            options: {
              zIndex: 2147483642,
              primaryColor: "#812520",
            },
          }}
          showSkipButton={true}
        />
      </div>
    );
  };

  handleMenuOpenClick = (event) => {
    event && event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = (event) => {
    event && event.stopPropagation();
    this.setState({
      anchorEl: null,
    });
  };

  handleCopyWorkout = (event) => {
    event && event.stopPropagation();
    console.log(this.props.data, "this.props.data");
    this.props.copyWorkout(this.props.data);
    this.handleMenuClose();
  };

  handleShareWorkout = (shareOption) => {
    this.props.shareWorkout(this.props.data, shareOption);
    this.handleMenuClose();
  };

  handleDeleteWorkout = (event) => {
    event && event.stopPropagation();
    this.props.deleteWorkout(this.props.data);
    this.handleMenuClose();
  };

  render() {
    let cardStyle = {
      margin: 10,
      height: 420,
      width: 250,
    };

    var rowClass = classNames({
      workoutListCard: true,
      selected: this.props.isSelected,
      fulWidth: true,
    });
    let daySelected = Boolean(this.props.selectedDay > -1);
    return (
      <React.Fragment>
        {this.renderJoyride()}
        <Row
          className={`fullWidth toggleContainer ${
            this.state.collapse ? "" : "workoutCardCollapsedHeight"
          }`}
          onClick={this.toggleCollapse}
          style={{ margin: 0 }}
        >
          <Row className={rowClass} id={`workout${this.props.data.id}`}>
            <Col
              lg="4"
              md="4"
              sm="4"
              xs="4"
              style={{ alignItems: "center", display: "flex", paddingLeft: 24 }}
            >
              {this.props.data.name}
            </Col>
            <Col
              lg="2"
              md="2"
              sm="2"
              xs="1"
              className="toggleContainer centerAlignJustify"
              style={{ marginLeft: -18 }}
            >
              {this.getExerciseCount()}
            </Col>
            <Col
              lg="6"
              md="6"
              sm="6"
              xs="7"
              className="decFontSize incFontWeight"
            >
              <Row>
                {daySelected && (
                  <Col
                    lg="5"
                    md="5"
                    sm="5"
                    xs="5"
                    className="workout-list-button centerAlignJustify"
                    style={{ padding: 0, textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.onSelect}
                    >
                      {window.innerWidth < 768
                        ? "Assign"
                        : this.props.calenderDay
                        ? `Assign to ${this.props.calenderDay}`
                        : `Assign to day ${this.props.selectedDay + 1}`}
                    </Button>
                  </Col>
                )}
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="workout-list-button centerAlignJustify"
                  style={{ padding: 0 }}
                >
                  <IconButton onClick={this.editWorkout} size="small">
                    <Tooltip title="Edit this workout" placement={"top"} arrow>
                      <EditIcon color="primary" />
                    </Tooltip>
                  </IconButton>
                </Col>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="workout-list-button centerAlignJustify"
                  style={{ padding: 0 }}
                >
                  <ShareWorkoutButton
                    source={sourcePropTypes.WORKOUT_ASSIGNER}
                    onSelectShareOption={this.handleShareWorkout}
                  />
                </Col>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="workout-list-button centerAlignJustify"
                  style={{ padding: 0 }}
                  onClick={this.toggleCollapse}
                >
                  <div>
                    <IconButton onClick={this.handleMenuOpenClick} size="small">
                      <MoreVertIcon color="primary" />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleMenuClose}
                    >
                      <MenuItem onClick={this.assignToAll}>
                        Assign to all
                      </MenuItem>
                      <MenuItem onClick={this.handleCopyWorkout}>Copy</MenuItem>
                      <MenuItem onClick={this.handleDeleteWorkout}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </Col>
                <Col
                  lg="2"
                  md="2"
                  sm="2"
                  xs="2"
                  className="workout-list-button centerAlignJustify"
                  style={{ padding: 0 }}
                  onClick={this.toggleCollapse}
                >
                  {!this.state.collapse ? (
                    <i className="icon material-icons">arrow_drop_down</i>
                  ) : (
                    <i className="icon material-icons">arrow_drop_up</i>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={this.state.collapse ? "collapseContainer" : "hidden"}>
            <Collapse
              // timeout="auto"
              isOpen={this.state.collapse}
              style={{ width: "100%" }}
            >
              {this.props.data &&
                Object.keys(this.props.data).length > 0 &&
                (this.props.data.exercises ? (
                  <div className="exercisesRowScroll">
                    {this.props.data.exercises.map((value, i) => {
                      return (
                        <WorkoutExerciseCard
                          key={i}
                          videoWidth={250}
                          videoHeight={250}
                          cardStyle={cardStyle}
                          data={value}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="sectionedExercisesRowScroll">
                    <SectionedWorkout
                      workoutSections={this.props.data.workoutSections}
                    />
                  </div>
                ))}
            </Collapse>
          </Row>
        </Row>
      </React.Fragment>
    );
  }
}
