import React from "react";
import FeedbackSection from "./feedbackSection";
export default class SectionedFeedback extends React.Component {
  render() {
    let workout = this.props.workoutSections;

    return (
      <div className="sectionedWorkout">
        {workout.length > 0 &&
          workout.map((section, index) => {
            return (
              <FeedbackSection
                key={index}
                section={section}
                listIndex={index}
                firstSection={workout[0]}
                size={this.props.size}
                workoutType={this.props.workoutType}
                loggedWorkoutId={this.props.loggedWorkoutId}
                loggedData={this.props.loggedData}
              />
            );
          })}
      </div>
    );
  }
}
