import React, { Component } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default class RedirectPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="wrongPlacePageCntainer">
          <ErrorOutlineIcon
            style={{ fontSize: "50px", marginBottom: "16px" }}
          />
          <h2>Please open the link in your mobile phone</h2>
        </div>
      </React.Fragment>
    );
  }
}
