import React from "react";
import Creatable from "react-select/creatable";
import { Button } from "reactstrap";
import { sendRequest } from "../../modules/http/HttpHelper";
import ExerciseEditCard from "./exerciseEditCard";

export default class TrainerExerciseEditCard extends ExerciseEditCard {
  constructor(props) {
    super(props);
    this.state = {
      alternateNameOptions: [],
      selectedAlternateName: "",
      newCreatedAlternateNames: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.createDropDownOptions();
  }

  createDropDownOptions = () => {
    let exerciseData = this.props.data.alternateNames;
    if (!exerciseData || !exerciseData.length) {
      this.setState({
        alternateNameOptions: [],
      });
    } else {
      let options = [];
      exerciseData.map((item, index) => {
        let optionData = {};
        optionData.value = item;
        optionData.label = item;
        return options.push(optionData);
      });
      this.setState({
        alternateNameOptions: options,
      });
    }
  };

  onChangeAlternateName = (option) => {
    if (option.__isNew__) {
      let newCreatedCopy = this.state.newCreatedAlternateNames;
      newCreatedCopy.push(option);
      let alternateNameOptionsCopy = this.state.alternateNameOptions;
      delete option.__isNew__;
      alternateNameOptionsCopy.push(option);

      this.setState({
        alternateNameOptions: alternateNameOptionsCopy,
        newCreatedAlternateNames: newCreatedCopy,
        selectedAlternateName: option,
      });
    } else {
      this.setState({
        selectedAlternateName: option,
      });
    }
  };

  mapToArray = (data) => {
    return data.map((item) => {
      return item.value;
    });
  };

  onSave = async () => {
    if (!this.state.newCreatedAlternateNames.length) {
      if (this.state.selectedAlternateName !== "") {
        return window.NotificationUtils.showError("Already Added!");
      } else {
        return window.NotificationUtils.showError("No name added");
      }
    }
    let query = {
      data: {
        alternateNames: this.mapToArray(this.state.newCreatedAlternateNames),
        exerciseId: this.props.data.id,
      },
    };
    if (this.props.user.enterpriseId) {
      query.data.enterpriseId = this.props.user.enterpriseId;
    } else {
      query.data.trainerId = this.props.user.id;
    }
    window.NotificationUtils.showConfirm("Saving updates");
    try {
      await new Promise((resolve, reject) =>
        sendRequest("updateExercise", "post", query, resolve, {
          errorCallback: reject,
        })
      );
      window.NotificationUtils.showSuccess("Alternate name added");
      this.props.onConfirm(query.data.alternateNames);
    } catch (e) {
      console.error(e);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  render() {
    return (
      <React.Fragment>
        <Creatable
          formatCreateLabel={(input) => {
            return (
              <span className="dropdownAddLabel">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ marginRight: 4 }}
                >
                  Add
                </Button>{" "}
                {input}
              </span>
            );
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "rgba(189, 0, 0, 0.3)",
              primary: "#800520",
            },
          })}
          placeholder="Alternate name"
          id="alternateName"
          inputId="alternateName"
          value={this.state.selectedAlternateName}
          classNamePrefix="fortisFormaDropDownSelect"
          onChange={this.onChangeAlternateName}
          options={this.state.alternateNameOptions}
        />

        <div className="modal-footer nopadding" style={{ marginTop: 16 }}>
          <Button
            className="btn btn-outline-secondary"
            onClick={this.props.onClickCancel}
          >
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
