import { Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { CLIENT_DETAIL_TABS, LOCAL_STORAGE_KEYS } from "../../constants";
import Forms from "./forms";
import NotesMain from "../../views/enterpriseClientDetails/notesMain";
import Assessments from "./assessments";
import WorkoutAssigner, {
  assignWorkoutToUser,
} from "../workoutAssigner/workoutAssigner";
import EnterpriseWorkoutSummary from "../workoutSummary/enterpriseWorkoutSummary";
import ChatRoom from "../../components/chatRoom/ChatRoom";

export async function showClientDetailsToUser(user, history, tab = 0) {
  var details = {};
  details.clientName = user.name;
  details.clientEmail = user.email;
  details.clientId = user.id;
  details.clientLoginPending = user.pendingLogin;
  details.clientDOB = user.dob;
  details.role = user.role;
  let chatTrainerArr = [];
  if (
    typeof user.chatTrainer === "object" &&
    !Array.isArray(user.chatTrainer)
  ) {
    chatTrainerArr.push(user.chatTrainer);
    details.chatTrainer = chatTrainerArr;
  } else {
    details.chatTrainer = user.chatTrainer;
  }

  details = JSON.stringify(details);
  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.WORKOUT_SUMMARY_DETAILS,
      details
    );
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.CLIENT_INFO_DETAILS,
      details
    );

    history && history.push(`/client-details?tab=${tab}`);
  } catch (e) {
    console.error(e);
    window.NotificationUtils.showError("Unable to access local storage");
  }
}

export default class ClientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "",
      clientEmail: "",
      clientId: "",
      clientDOB: "",
      step: CLIENT_DETAIL_TABS.FORMS,
      shouldOpenEditor: false,
      buttonStatus: false,
      role: "",
      chatTrainer: [],
    };
  }

  componentDidMount() {
    this.restoreStateFromLocalStorage(this.getURLRoute);
  }

  getURLRoute = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const tab = urlParams.get("tab");
    this.handleTabChange({}, Number(tab));
  };

  restoreStateFromLocalStorage = async (callback) => {
    const storedState = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_INFO_DETAILS)
    );

    let assignmentDetails = window.localStorage.getItem(
      LOCAL_STORAGE_KEYS.WORKOUT_ASSIGNMENT_DETAILS
    );
    if (assignmentDetails) {
      assignmentDetails = JSON.parse(assignmentDetails);

      this.setState({
        buttonStatus: assignmentDetails.buttonStatus,
      });
      assignmentDetails = this.getWorkoutDay(assignmentDetails);
    }

    if (!storedState || !storedState.clientId) {
      this.props.history.push("/dashboard");
    } else {
      if (storedState.chatTrainer) {
        let chatTrainerArr = [];
        if (
          typeof storedState.chatTrainer === "object" &&
          !Array.isArray(storedState.chatTrainer)
        ) {
          chatTrainerArr.push(storedState.chatTrainer);
          this.setState({ chatTrainer: chatTrainerArr });
        } else {
          this.setState({ chatTrainer: storedState.chatTrainer });
        }
      } else {
        this.setState({ chatTrainer: [] });
      }
    }

    this.setState(
      {
        ...storedState,
        workoutDay: assignmentDetails || {},
      },
      () => {
        callback && callback();
      }
    );
  };

  getWorkoutDay = (assignmentDetails) => {
    let workoutDay = {};
    if (
      assignmentDetails &&
      assignmentDetails.workoutToAssign &&
      assignmentDetails.workoutTypeValue
    ) {
      workoutDay = {
        id: assignmentDetails.workoutToAssign,
        type: assignmentDetails.workoutTypeValue,
      };
    }
    return workoutDay;
  };

  handleTabChange = (e, value, redirect = false) => {
    this.setState({
      step: value,
      shouldOpenEditor: redirect,
    });

    if (value === CLIENT_DETAIL_TABS.WORKOUTS) {
      assignWorkoutToUser(
        {
          name: this.state.clientName,
          email: this.state.clientEmail,
          id: this.state.clientId,
          pendingLogin: this.state.clientLoginPending,
          dob: this.state.clientDOB,
          chatTrainer: this.state.chatTrainer,
        },
        this.props.history,
        this.state.workoutDay,
        this.props.user.enterpriseId,
        this.props.user
      );
      this.setState({ workoutDay: {} });
    }
  };

  shouldAddBottomMargin = () => {
    return this.state.step === CLIENT_DETAIL_TABS.FORMS;
  };

  renderTitle = () => {
    return (
      <div className="enterpriseClientDetailHeaderContainer">
        <div className="enterpriseClientDetailTitleContainer">
          <Typography
            className="boldText textTransformInitial"
            style={{ fontSize: "1.2em", marginRight: 8 }}
          >
            {this.state.clientName}
          </Typography>
        </div>
      </div>
    );
  };

  renderTabContent = () => {
    let isEnt = !!this.props.user.enterpriseId;
    switch (this.state.step) {
      case CLIENT_DETAIL_TABS.FORMS:
        return (
          <div className="tabContentContainer">
            <div className="formsContentContainer fulltabform">
              <Forms
                clientId={this.state.clientId}
                clientLoginPending={this.state.clientLoginPending}
                clientName={this.state.clientName}
                clientEmail={this.state.clientEmail}
                clientDOB={this.state.clientDOB}
                handleTabChange={this.handleTabChange}
                {...this.props}
              />
            </div>
          </div>
        );
      case CLIENT_DETAIL_TABS.NOTES:
        return (
          <div className="tabContentContainer">
            <div className="innerContentContainer innerContentContainerHeightAdjusted">
              <NotesMain
                clientId={this.state.clientId}
                clientLoginPending={this.state.clientLoginPending}
                clientName={this.state.clientName}
                clientEmail={this.state.clientEmail}
                clientDOB={this.state.clientDOB}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                shouldOpenEditor={this.state.shouldOpenEditor}
                {...this.props}
              />
            </div>
          </div>
        );
      case CLIENT_DETAIL_TABS.ASSESSMENT:
        return (
          <div className="tabContentAssessmentContainer">
            <Assessments
              clientId={this.state.clientId}
              clientLoginPending={this.state.clientLoginPending}
              clientName={this.state.clientName}
              clientEmail={this.state.clientEmail}
              clientDOB={this.state.clientDOB}
              shouldOpenEditor={this.state.shouldOpenEditor}
              {...this.props}
            />
          </div>
        );

      case CLIENT_DETAIL_TABS.WORKOUTS:
        return (
          <div className="tabContentWorkoutAssigner">
            <WorkoutAssigner
              clientId={this.state.clientId}
              clientLoginPending={this.state.clientLoginPending}
              clientName={this.state.clientName}
              clientEmail={this.state.clientEmail}
              chatTrainer={this.state.chatTrainer}
              isEnterpriseClient={isEnt}
              {...this.props}
            />
          </div>
        );
      case CLIENT_DETAIL_TABS.WORKOUT_LOGS:
        return (
          <div className="tabContentWorkoutSummary">
            <EnterpriseWorkoutSummary
              clientId={this.state.clientId}
              clientLoginPending={this.state.clientLoginPending}
              clientName={this.state.clientName}
              clientEmail={this.state.clientEmail}
              isEnterpriseClient={isEnt}
              {...this.props}
            />
          </div>
        );
      case CLIENT_DETAIL_TABS.MESSAGE_CENTER:
        return (
          <div className="MessageCentertab">
            <ChatRoom
              selectedClient={{
                name: this.state.clientName,
                email: this.state.clientEmail,
                id: this.state.clientId,
                role: this.state.role,
              }}
              currentUser={this.props.user}
            />
          </div>
        );
      default:
        return <> Forms Container</>;
    }
  };
  checkTrainersInClients = () => {
    return (
      this.state.chatTrainer.some((item) => item.id === this.props.user.id) &&
      !this.state.clientLoginPending
    );
  };
  renderTabs = () => {
    if (!this.state.clientId) {
      return <></>;
    }
    return (
      <div>
        <div className="documentEditor">
          <div className="TabsContainer">
            <Tabs
              value={this.state.step}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab className="enterpriseClientDetailTabLabel" label="Forms" />
              <Tab className="enterpriseClientDetailTabLabel" label="Notes" />
              <Tab
                className="enterpriseClientDetailTabLabel"
                label="Assessments"
              />

              <Tab
                className="enterpriseClientDetailTabLabel"
                label="Exercise Prescription"
              />
              <Tab
                className="enterpriseClientDetailTabLabel"
                label="Exercise Logs"
              />
              {this.checkTrainersInClients() && (
                <Tab
                  className="enterpriseClientDetailTabLabel"
                  label="Messages"
                />
              )}
            </Tabs>
          </div>

          {this.renderTabContent()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="enterpriseClientDetailMainContainer">
          {this.renderTitle()}
          {this.renderTabs()}
        </div>
      </React.Fragment>
    );
  }
}
