import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { WORKOUT_TYPE } from "../../constants";
import Assigner from "../assigner/assigner";
import { Checkbox } from "@material-ui/core";

export default class WorkoutTypeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workoutTypeTrainer: WORKOUT_TYPE.TRAINER,
      workoutTypeClient: 0,
      selectedClient: null,
    };
    this.assignerRef = null;
  }

  onWorkoutValueChange = (e, type) => {
    console.log(type, "onWorkoutValueChange", e.target.value);
    if (type === "my_collection") {
      if (e.target.checked) {
        this.setState({ workoutTypeTrainer: Number(e.target.value) });
      } else {
        this.setState({ workoutTypeTrainer: 0 });
      }
    } else if (type === "client_collection") {
      if (e.target.checked) {
        this.setState({ workoutTypeClient: Number(e.target.value) });
      } else {
        this.setState({ workoutTypeClient: 0 });
      }
    }
  };

  onConfirm = async () => {
    if (
      this.state.workoutTypeClient !== 0 &&
      this.state.workoutTypeTrainer !== 0
    ) {
      setTimeout(async () => {
        await this.props.onClickConfirm(
          this.state.workoutTypeTrainer,
          this.assignerRef
        );
      }, 2000);
      await this.props.onClickConfirm(
        this.state.workoutTypeClient,
        this.assignerRef
      );
    } else if (this.state.workoutTypeTrainer !== 0) {
      this.props.onClickConfirm(
        this.state.workoutTypeTrainer,
        this.assignerRef
      );
    } else if (this.state.workoutTypeClient !== 0) {
      this.props.onClickConfirm(this.state.workoutTypeClient, this.assignerRef);
    }
  };

  onSelectClient = (selectedClient) => {
    this.setState({
      selectedClient: selectedClient,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen}>
          <ModalHeader>Select the type of workout</ModalHeader>
          <ModalBody>
            <div className="form-check text-left">
              <label>
                <Checkbox
                  checked={
                    this.state.workoutTypeClient === WORKOUT_TYPE.CLIENT_WORKOUT
                  }
                  value={WORKOUT_TYPE.CLIENT_WORKOUT}
                  onChange={(e) =>
                    this.onWorkoutValueChange(e, "client_collection")
                  }
                  color="primary"
                />
                {this.props.type === "draft"
                  ? "Save to client drafts"
                  : "This workout is for a client"}
              </label>
            </div>
            {this.state.workoutTypeClient === WORKOUT_TYPE.CLIENT_WORKOUT ? (
              <Assigner
                {...this.props}
                ref={(c) => (this.assignerRef = c)}
                currentUser={this.props.user}
                history={this.props.history}
                onSelectClient={this.onSelectClient}
              />
            ) : null}
            <div className="form-check text-left">
              <label>
                <Checkbox
                  checked={
                    this.state.workoutTypeTrainer === WORKOUT_TYPE.TRAINER
                  }
                  onChange={(e) =>
                    this.onWorkoutValueChange(e, "my_collection")
                  }
                  value={WORKOUT_TYPE.TRAINER}
                  color="primary"
                />
                {this.props.type === "draft"
                  ? "Save to drafts"
                  : "This workout is for my collection"}
              </label>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginTop: "14px" }}>
            <Button
              className="btn btn-outline-secondary btn btn-secondary"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={this.onConfirm}
              disabled={
                this.state.workoutTypeClient === WORKOUT_TYPE.CLIENT_WORKOUT &&
                !this.state.selectedClient
              }
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
