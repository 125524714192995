import React from "react";
import FortisFormaSelect from "../autoSuggest/dropdownSelect";
import UseTemplates, { DROPDOWN_FORM_TYPES } from "./useTemplates";

export default class UseGlobalTemplates extends UseTemplates {
  constructor(props) {
    super(props);
    this.state = {
      forms: this.props.globalForms || [],
      ...this.state,
    };
  }

  handleTabChange = (selectedOption) => {
    this.setState(
      {
        selectedTab: selectedOption,
      },
      this.setSequenceNumber
    );
  };

  fetchForms = async () => {
    try {
      if (this.state.forms.length === 0 && !this.props.hasFetchedForms) {
        this.setState({
          loading: true,
        });
        var results = await this.props.fetchGlobalForms();
        this.setState({
          forms: results,
          loading: false,
        });
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error fetching the templates");
      this.setState({
        loading: false,
      });
    }
  };

  renderFormTypeDropdown = () => {
    return (
      <FortisFormaSelect
        id="useTemplatesFormTypeSelect"
        placeholder="Forms"
        value={this.state.selectedTab}
        classNamePrefix="fortisFormaDropDownSelect"
        onChange={this.handleTabChange}
        options={DROPDOWN_FORM_TYPES}
        isClearable={false}
        isDisabled={this.state.loading}
      />
    );
  };
}
