import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';


export default class ClientEmailVerified extends Component {

    render() {
        return (
            <Container className="loginBody">
                <div className="welcomeMessage">
                    <Row style={{ paddingBottom: 80 }}>
                        <Col className="center">
                            <img alt='FortisForma Logo' className="logo" src="/assets/img/logo.png"/>
                        </Col>
                    </Row>
                    <h3>Email Verified Successfully!</h3>
                    <p>
                        You can now access the app on your mobile device.
                    </p>
                </div>
            </Container>
        )
    }
}
