import React from "react";

import InfiniteList from "../../components/infiniteList/list";
import StaffMemberCard from "./staffMemberCard";

export default class StaffList extends InfiniteList {
  loadNextPage() {
    if (!this.props.loadData) {
      return this.onDataLoaded();
    }

    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.props.loadData();
        this.onDataLoaded(data);
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  rowRenderer({ key, index, isScrolling, style }) {
    let data = this.props.data[index];
    return (
      <div style={style} key={key}>
        <StaffMemberCard {...this.props} data={data} />
      </div>
    );
  }
}
