import React from "react";
import { Button, Container } from "reactstrap";
import XLSX from "xlsx";
import GenericFilters from "../../components/filters/genericFilters";
import { ROLES } from "../../constants";

export default class TrainerExerciseEditorPanel extends React.Component {
  constructor(props) {
    super(props);
    this.FilterClass = GenericFilters;
    this.bulkUploadEnabled = true;
    this.state = {
      filters: "",
      data: [],
      selected: null,
      filtered: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onBulkFileSelect = this.onBulkFileSelect.bind(this);
    this.save = this.save.bind(this);
    this.sort = true;
    this.originals = null;
    this.filtersRef = React.createRef();
  }

  setState(update, callback) {
    if (
      this.sort &&
      update.data &&
      update.data.length !== this.state.data.length
    ) {
      update.data.sort((a, b) => {
        if (!a.name || !b.name) {
          return 0;
        }
        var nameA = a.name.toLowerCase();
        var nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    if (!this.originals && update.data && update.data.length) {
      this.originals = update.data;
    }
    super.setState(update, callback);
  }

  onSelect(item) {
    this.setState({
      selected: Object.assign({}, item),
    });
  }

  onClickCancel() {
    this.setState({
      selected: null,
    });
  }

  onDelete = (id) => {
    let data = this.state.data.filter((item) => {
      return item.id !== id;
    });
    this.setState({ data: data, selected: null });
  };

  async fetchData() {}

  async save(item) {
    throw new Error("UnImplemented");
  }

  onBulkFileSelect(event) {
    let file = event.target.files[0];
    if (!file) {
      return;
    }

    this.readFile(file)
      .then((fileData) => {
        try {
          const wb = XLSX.read(fileData, {
            type: "array",
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);

          this.onBulkFileReady(data);
        } catch (e) {
          window.NotificationUtils.showError("Invalid data in file");
        }

        document.querySelector(`#bulkFilePicker`).value = null;
      })
      .catch((error) => {
        throw error;
      });
  }

  async onBulkFileReady(data) {
    try {
      let documents = data.map((row, index) => {
        return this.mapRowToDocument(this.trim(row), index);
      });
      try {
        window.NotificationUtils.showConfirm(
          `Uploading ${documents.length} row ...`
        );
        await this.bulkSave(documents);
        window.NotificationUtils.showSuccess(
          `Uploaded ${documents.length} rows`
        );
      } catch (e) {
        window.NotificationUtils.showError(
          "Something went wrong during upload"
        );
        console.error(e);
      }
    } catch (e) {
      window.NotificationUtils.showError(e.message);
      console.error(e);
    }
  }

  trim(row) {
    for (let key of Object.keys(row)) {
      let value = row[key];
      if (value && typeof value === "string") {
        row[key] = value.trim();
      }
    }

    return row;
  }

  mapRowToDocument(row) {
    throw new Error("UnImplemented");
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onload = (evt) => {
        resolve(evt.target.result);
      };
      reader.onerror = (evt) => {
        throw evt;
      };
      reader.readAsArrayBuffer(file);
    });
  }

  applyFilters = async (filterState) => {
    if (!filterState) {
      return;
    }
    if (filterState.didApply) {
      let filteredData = await this.filterData(filterState.filters);
      this.setState({ data: filteredData, filtered: true });
    } else {
      this.setState({ data: this.originals, filtered: false });
    }
  };

  // We use async so that if someone need to call remote for filters we dont need to change ourselves
  async filterData(filters) {
    if (!filters.text && Object.keys(filters).length === 1) {
      return Promise.resolve(this.originals);
    }
    let newData = this.originals.filter((item) => {
      return this._filter(item, filters);
    });

    return Promise.resolve(newData);
  }

  _filter(item, filters) {
    return item.name.toLowerCase().includes(filters.text.trim().toLowerCase());
  }

  async bulkSave(data) {
    throw new Error("Unimplemented");
  }

  renderExtras() {
    let filterProps = this.filterProps || {};
    return this.originals ? (
      <this.FilterClass
        ref={this.filtersRef}
        applyFilters={this.applyFilters}
        {...filterProps}
      />
    ) : null;
  }

  clearFilters = () => {
    this.filtersRef && this.filtersRef.current.clearFilters(true);
  };

  emptyPlaceholder = () => {
    return (
      <div className="emptyPlaceHolder">
        <div>No values matching your criteria</div>

        {this.state.filtered ? (
          <div className="filtersSearch internal">
            <Button
              className="clearFiltersButton"
              color="primary"
              onClick={this.clearFilters}
            >
              x
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <Container fluid className={this.rootClassName}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 0px 0px 16px",
          }}
        >
          <div style={{ margin: 0 }} className="title">
            {this.state.title}
          </div>

          <div className="editorPanelTopRow">
            {this.renderExtras()}

            {this.bulkUploadEnabled && this.props.user.role === ROLES.ADMIN ? (
              <div className="bulkUploadButtonContainer">
                <Button id="bulkPickerButton">Bulk Upload</Button>
                <input
                  id="bulkFilePicker"
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    width: 170,
                  }}
                  accept=".xlsx"
                  onChange={this.onBulkFileSelect}
                ></input>
              </div>
            ) : null}

            {this.props.user.role === ROLES.ADMIN ? (
              <Button
                onClick={() => {
                  this.setState({ selected: {} });
                }}
              >
                Add New
              </Button>
            ) : null}

            {this.state.createExercise ? (
              <Button
                onClick={() => {
                  this.setState({ newExerciseModal: true });
                }}
              >
                Add New
              </Button>
            ) : null}
          </div>
        </div>

        {this.renderContent()}
      </Container>
    );
  }
}
