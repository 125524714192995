import React, { Component } from "react";
import AddImage from "./addImageComponent";
import UploadingImage from "./uploadingImage";

export default class ImageUploaderSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesData: null,
    };
  }

  componentDidMount() {
    this.props.selectedImages && this.arrayToObjectOfImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedImages !== this.props.selectedImages) {
      this.setState({ imagesData: this.props.selectedImages || [] });
    }
  }

  getImages = () => {
    let imagesObject;
    imagesObject = this.state.imagesData && this.state.imagesData;
    if (!imagesObject) {
      imagesObject = {};
    }
    return imagesObject;
  };

  showNewUploadInCollection = (imageObject) => {
    let imagesDataCopy = [...this.state.imagesData];
    if (!imagesDataCopy) {
      imagesDataCopy = [];
    }
    imagesDataCopy.push(imageObject);
    this.setState(
      {
        imagesData: imagesDataCopy,
      },
      () => this.props.onDone(this.state.imagesData)
    );
  };

  arrayToObjectOfImages = () => {
    let selectedImages = this.props.selectedImages;
    this.setState({
      imagesData: selectedImages,
    });
  };

  renderContent = () => {
    return (
      <>
        <div className="uploaderSectionImageContainer">
          <AddImage
            onFileChange={this.props.onFileChange}
            extensions={this.props.extensions && this.props.extensions}
          />
          {this.props.selectedFileForUpload &&
            this.props.selectedFileForUpload.map((imageData, index) => {
              return (
                <UploadingImage
                  key={index}
                  showNewUploadInCollection={this.showNewUploadInCollection}
                  onUploadComplete={this.props.onUploadComplete}
                  imageData={imageData}
                  pathToStore={this.props.pathToStore}
                />
              );
            })}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className="imageUploaderSection">
          <div>{this.renderContent()}</div>
        </div>
      </>
    );
  }
}
